import React, { Component } from 'react';
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import Spinner from 'react-spinner-material';
import Modal from "react-awesome-modal";
import closemodal from "../../images/closemodal.png";
import editmodal from "../../images/edit.png";
import { WithContext as ReactTags } from 'react-tag-input';
import { API } from "../../_redux/helper"
import axios from 'axios';
import { moment } from 'moment';
import { Formik, useFormik } from 'formik';
import { TextField, Autocomplete, Button, Card, CardBody } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import dro from "../../images/icons/icon_dropdown.svg"
import { FileUploader } from "react-drag-drop-files";
import { connect } from "react-redux";
import { token, height_ ,numberOnly} from "../../_redux/function"
import DateFnsUtils from '@date-io/date-fns';
import * as Yup from 'yup';
import CKEditor from 'ckeditor4-react';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import {
    phone, mail, cv, cv_green, people, salary, skill, work_exp, education, certificate, project, industry,
    art_certification, art_personal, art_education, art_project, art_salary, art_skills, art_work_exp, pen, cv1, icon_delete, industry_icon, function_icon
} from "../../_redux/svg"
import {postCertification,putCertification,deleteCertification } from "../../_actions/certification";
import { postWork, putWork,deleteWork} from "../../_actions/work";
import { postEducation, putEducation, deleteEducation } from "../../_actions/education";
import { putProfileId, POST_USER_PROJECT, getOnlyCity, updateUserProject, postSkill } from "../../_actions/user";
import { postExp, getExpList, deleteExp, getBenefitList } from "../../_actions/expected";
import {  postLast, getLastList, deleteLast } from "../../_actions/last";
import { deleteDocument } from "../../_actions/document";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from 'react-select'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';


class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: "",
            jobs: ["Designer", "Software Developer", "System Analyst", "Softw   are Developer"],
            education_level: [],
            tagsLB: [],
            tagsEBAdd: [],
            tagsLBAdd: [],
            tagsEBDel: [],
            tagsLBDel: [],
            tagsEB: [],
            tags: [],
            expectedSalaryError: false,
            latestSalaryError: false,
            deleteItem:false,
            submitCv: {},
            submit1Cv: "",
            latestSalary: "",
            expectedSalary: "",
            tagsAdd: "",
            name:"",
            birth_date:null,
            document_name: [],
            doc_list: [],
            latest_benefit: [{ id: '1', text: "mirav" }, { id: '2', text: "Insurance" }, { id: '3', text: "jasoliya" }],
            expected_benefit: [{ id: '1', text: "benefit A" }, { id: '2', text: "benefit B" }],
            benefit_suggestions: ["THR"],
            cv_name: "",
            job_function_name: "",
            score: [2, 3, 4, 5, 6, 7, 8, 9],
            data_city:[],
            //   Certification 
            province: false,
            certification_name: "",
            month: "",
            year: "",
            yearError: false,
            monthError: false,
            name: "",
            monthNameP: "",
            yearNamePError: false,
            monthNamePError: false,
            yearNameP: "",
            nameErro: false,
            certification_nameError: false,
            project_list: [],
            //   Education 
            education_start: new Date(),
            education_end: new Date(),
            education_level_id: "",
            show_only: "",
            edu_id: 0,
            ipk: "",
            institution_name: "",
            //   Interest
            job_function_id: [],
            industry_id: [],
            education_level_idError: false,
            show_onlyError: false,
            ipkError: false,
            institution_nameError: false,
            // Work Experience 
            work_period_start: new Date(),
            work_period_end: new Date(),
            job_title: "",
            job_level_id: "",
            company_name: "",
            job_description: "",
            job_function: "",
            industry: "",
            job_titleError: false,
            job_level_idError: false,
            company_nameError: false,
            job_descriptionError: false,
            job_functionError: false,
            industryError: false,
            editEdu: {},
            updateEB: true,
            updateLB: true,
            updateCV: true,
            updateDOC: true,
            update1Skill: true,
            updateSal: true,
            status_doc:false,
            showCV:"",
            keys:"",
            ll:"",
            dataEdit:[],
            dataToDelete:[],
            work_id:0,
            certificate_id:0,
            project_id:0,
            sug_option:{},
            selectedOption: [],
            latestBenefit:[],
            expectedBenefit:[],
            inputLB:"",
            inputEB:"",
            ff:"",
            ff_EB:"",
            err_size:"",
            still_work:false
        };
    }



    closeMO=(e)=>{
        const element = document.getElementById("mol")
    }

    componentDidMount(){
        this.props.getBenefitList()
    }
    componentDidUpdate(prevProps, prevState) {
        const { profile, expectedList, latestList, skillList, document1list, document2list } = this.props
        const { tagsEB, tagsLB, tags, latestSalary, updateSal, expectedSalary, update1Skill, cv_name, submit1Cv, updateEB, updateLB, updateDOC, updateCV, document_name, doc_list } = this.state

        if (tagsEB.length == 0 && expectedList.length > 0 && updateEB == true) {
            let n =[]
            expectedList.map((item,i)=>n.push({value:item.id, label:item.expected_benefit}))
            this.setState({ tagsEBAdd: expectedList , expectedBenefit:n})
            const expList = this.updateEBList(expectedList)
            this.setState({ tagsEB: expList })
            this.setState({ updateEB: false })
        }
        if (tagsLB.length == 0 && latestList.length > 0 && updateLB == true) {
            let n =[]
            latestList.map((item,i)=>n.push({value:item.id, label:item.latest_benefit}))
            this.setState({ tagsLBAdd: latestList, latestBenefit:n})
            const lastList = this.updateLBList(latestList)
            this.setState({ tagsLB: lastList })
            this.setState({ updateLB: false })
        }
        if (tags.length == 0 && skillList.length > 0 && update1Skill == true) {
            const skilL = this.updateSkillList(skillList)
            let n =[]
            skillList.map((item,i)=>n.push({value:i, label:item}))
            this.setState({ tags: skilL })
            this.setState({ update1Skill: false, selectedOption:n})
        }
        if (cv_name == "" && document1list.length > 0 && submit1Cv == "" && updateCV == true) {
            this.setState({ cv_name: document1list[0].title })
            this.setState({ submit1Cv: document1list[0].title , showCV:document1list[0].file})
            this.setState({ updateCV: false })
        }
        if (document_name.length == 0 && document2list.length > 0 && doc_list.length == 0 && updateDOC == true) {
            const listD = this.updateDocumentlist(document2list)
            this.setState({ doc_list: document2list })
            this.setState({ document_name: listD })
            this.setState({ updateDOC: false })
        }
        if (updateSal == true && latestSalary == "" && expectedSalary == "" && !!profile.lastest_monthly_salary && !!profile.expected_monthly_salary) {
            this.setState({ latestSalary: profile.lastest_monthly_salary })
            this.setState({ expectedSalary: profile.expected_monthly_salary })
            this.setState({ updateSal: false })
        }
        if (prevState.user_id !== prevProps.profile.id) {
            this.setState({ user_id: prevProps.profile.id })
            this.setState({ project_list: profile.project_award })

            this.setState({ tagsEBAdd: expectedList })
            // const skilL = this.updateSkillList(skillList)
            // this.setState({ tags: skilL })
            this.props.getOnlyCity()
            this.props.getExpList()
            this.props.getLastList()
           
        }
    }



    addTags = (e) => {
        //this.state.tags.push(e)
    }

    updateSuggestionsSkillList = (data) => {
        const listSkill = [];
        for (var i = 0; i < data.length; i++) {
            if (!!data[i].skill_name) {
                const object = {
                    id: `${i + 1}`,
                    text: data[i].skill_name
                }
                listSkill.push(object)
            }
        }
        return listSkill
    }


    commaFormate = (number) => {
        if (!!number) {
            var newN = number.split('.')
            return newN[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return ""
        }
    }

    updateSuggestionsBenefitList = (data) => {
        const listSkill = [];
        for (var i = 0; i < data.length; i++) {
            if (!!data[i].benefit_name) {
                const object = {
                    id: `${i + 1}`,
                    text: data[i].benefit_name
                }
                listSkill.push(object)
            }
        }
        return listSkill
    }
    updateDocumentlist = (data) => {
        const listDocu = []
        for (var i = 0; i < data.length; i++) {
            listDocu.push(data[i].title)
        }
        return listDocu;
    }

    updateSkillList = (data) => {
        const listSkill = [];
        for (var i = 0; i < data.length; i++) {
            if (!!data[i]) {
                const object = {
                    id: `${i + 1}`,
                    text: data[i]
                }
                listSkill.push(object)
            }
        }
        return listSkill
    }


    updateEBList = (data) => {
        const listEB = [];
        for (var i = 0; i < data.length; i++) {
            if (!!data[i].expected_benefit) {
                const object = {
                    id: `${i + 1}`,
                    text: data[i].expected_benefit
                }
                listEB.push(object)
            }
        }
        return listEB
    }

    updateStillWork=(e)=>{
        this.setState({work_period_end:new Date(), still_work:!this.state.still_work})
    }
    updateLBList = (data) => {
        const listLB = [];
        for (var i = 0; i < data.length; i++) {
            if (!!data[i].latest_benefit) {
                const object = {
                    id: `${i + 1}`,
                    text: data[i].latest_benefit
                }
                listLB.push(object)
            }
        }
        return listLB
    }

    handleDeleteLB = (i) => {
        const { tagsLB, tagsLBAdd } = this.state
        const findId = tagsLBAdd.find(o => o.latest_benefit == tagsLB[i].text)
        this.setState({ tagsLB: tagsLB.filter((tag, index) => index !== i) })
        if (!!findId) {
            this.props.deleteLast1(findId.id)
        } else {
            const findNewId = tagsLB.find(o => o.text == tagsLB[i].text)
            this.props.deleteLast1(findNewId.id)
        }

    };

    handleAdditionLB = tag => {
        const { tagsLB } = this.state
        const { profile } = this.props
        const fondLB = tagsLB.find(o => o.text == tag.text)
        if (fondLB == null) {
            const newtages = [...tagsLB, tag];
            this.setState({ tagsLB: newtages })
            const user = {
                user_id: `${profile.id}`,
                latest_benefit: tag.text
            }
            this.props.postLast1(user)
        }
    };

    handleDragLB = (tag, currPos, newPos) => {
        const { tagsLB } = this.state
        const newTags = tagsLB.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        this.setState({ tagsLB: newTags })
    };

    handleTagClickLB = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };



    handleDeleteEB = (i) => {
        const { tagsEB, tagsEBAdd } = this.state
        this.setState({ tagsEB: tagsEB.filter((tag, index) => index !== i) })
        const findId = tagsEBAdd.find(o => o.expected_benefit == tagsEB[i].text)
        if (!!findId) {
            this.props.deleteExp1(findId.id)
        } else {
            const findNewId = tagsEB.find(o => o.text == tagsEB[i].text)
            this.props.deleteExp1(findNewId.id)
        }
    };

    handleAdditionEB = tag => {
        const { tagsEB } = this.state
        const { profile } = this.props
        const fondEB = tagsEB.find(o => o.text == tag.text)
        if (fondEB == null) {
            const newtages = [...tagsEB, tag];
            this.setState({ tagsEB: newtages })
            const user = {
                user_id: `${profile.id}`,
                expected_benefit: tag.text
            }
            this.props.postExp1(user)
        }
    };

    handleDragEB = (tag, currPos, newPos) => {
        const { tagsEB, } = this.state
        const newTags = tagsEB.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        this.setState({ tagsEB: newTags })
    };

    handleTagClickEB = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };


    handleDelete = (i) => {
        const { tags } = this.state
        this.setState({ tags: tags.filter((tag, index) => index !== i) })
    };

    handleAddition = tag => {
        const { skill_list } = this.props
        const { tags } = this.state
        const findT = skill_list.find(o => o.skill_name == tag.text)
        if (!!findT) {
            const findS = tags.find(o => o.text == tag.text)
            if (findS == null) {
                const newtages = [...tags, tag];
                this.setState({ tags: newtages })
            }
        }
    };

    handleDrag = (tag, currPos, newPos) => {
        const { tags } = this.state
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        this.setState({ tags: newTags })
    };

    handleTagClick = index => {
        const { tags } = this.state
    };
    uploadCV = async (client_logo) => {
        this.setState({ submitCv: client_logo })
        this.setState({ cv_name: client_logo.name });
        this.props.changePopup("upload_cv1", "upload_cv2")
    }

    deleteDoc = (e) => {
        const { doc_list, document_name } = this.state
        const findId = doc_list.find(o => o.title == e)
        if (!!findId) {
            this.props.deleteDocument(findId.id)
            var indexOfDoc = document_name.indexOf(e)
            document_name.splice(indexOfDoc, 1)
            this.setState({ document_name: document_name });
        }
    }




    submitCV = async (id) => {
        const { submitCv } = this.state
        let formData = new FormData();
        if (submitCv) formData.append("files", submitCv);
        this.setState({ cv_filename: submitCv.name });
        axios
            .post(`${API}/api/user_document/${id}?keterangan=CV&document_type_id=1`, formData, {
                headers: {
                    authorization: token,
                }
            }).then(res => {
                this.setState({ submit1Cv: submitCv.name });
                this.props.getDoc()
                this.props.handlePopup("upload_cv2")
            }).catch(err => {
                console.log(err, "error");
            })
    }


    updateSkill = () => {
        const { selectedOption } = this.state
        const { profile } = this.props
        let m = []
        if (!!selectedOption && selectedOption.length > 0) {
            selectedOption.map(item=>m.push(item.label))
            var newTag = m.toString()
            const skill = {
                user_id: profile.id,
                skill: newTag
            }
            
            setTimeout(() => {
                this.props.postSkill(skill)
                this.props.getSalaryBenefit()
                this.scrollToTop("skill1")
                this.props.handlePopup("skills_")
            }, 500);
            
            
        }

    }


    updatePersonalInfo = (id, userData) => {
        const {profile} = this.props
        const {birth_date,name} = this.state
        var dataUser = {
            name:name===""?profile.name:name,
            birth_date: birth_date===null?profile.birth_date:birth_date,
            address: userData.address,
            gender_id: userData.gender,
            country_id: userData.country,
            state_id: userData.state,
            city_id: userData.city,
            professional_summary: userData.professional_summary,
            phone_number: userData.phoneNumber_1,
            phone_number2: userData.phoneNumber_2,
            phone_number3: userData.phoneNumber_3,
            linkedin_link: userData.linkedIn_link,
            work_preference_id: userData.work_preference,
            work_status_id: userData.work_status
        }
        axios
            .put(`${API}/api/user/${id}`, dataUser, {
                headers: {
                    authorization: token,
                }
            }).then(res => {
                this.props.getSalaryBenefit()
                // this.props.getCity()
                setTimeout(() => {
                    this.props.getCity()
                this.props.handlePopup("personal_info")
                this.scrollToTop("personal1")
                },500);
                
                // window.location.reload()
            }).catch(err => {
                console.log(err, "error");
            })
    }


    updateSalaryAndBenefit = (id, userData) => {
        const { profile,expectedList, latestList} = this.props
        const { expectedSalary, latestSalary,expectedBenefit, latestBenefit } = this.state
        const a = []
        const b =[]
        expectedBenefit.map(item=>b.push({user_id:profile.id, expected_benefit:item.label}))
        latestBenefit.map(item=>a.push({user_id:profile.id, latest_benefit:item.label}))
        
        var removePointES = expectedSalary
        var removePointLS = latestSalary
        if (expectedSalary.indexOf('.') != -1 && latestSalary.indexOf('.') != -1) {
            removePointES = expectedSalary.split('.')[0]
            removePointLS = latestSalary.split('.')[0]
        }
        const newExpected = removePointES.replaceAll(/,/g, '');
        const newLatest = removePointLS.replaceAll(/,/g, '');
        var dataArr = [expectedSalary, latestSalary];
        var dataArrErr = ['expectedSalaryError', 'latestSalaryError'];
        var validError = true
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            if (element.length == 0) {
                validError = false
                this.setState({ [dataArrErr[index]]: true });
            }
        }
        if (validError == true) {
            var dataUser = {
                expected_monthly_salary: newExpected,
                lastest_monthly_salary: newLatest
            }
            let list_expected_benefit = []
            let list_latest_benefit = []
            let list_expected_benefitId = []
            let list_latest_benefitId = []
                expectedBenefit.map(x=>{
                    if (expectedList.findIndex(d=>d.expected_benefit==x.label)===-1){
                        list_expected_benefit.push({user_id:profile.id,expected_benefit:x.label})
                        // this.props.postExp1({user_id:profile.id,expected_benefit:x.label})
                    }
                })
                latestBenefit.map(x=>{
                    if (latestList.findIndex(d=>d.latest_benefit==x.label)===-1){
                        list_latest_benefit.push({user_id:profile.id,latest_benefit:x.label})
                        // this.props.postLast1({user_id:profile.id,latest_benefit:x.label})
                    }
                })
                latestList.map(x=>{
                    if (latestBenefit.findIndex(d=>d.value==x.id)===-1){
                        list_latest_benefitId.push(x.id)
                        // this.props.deleteLast1(x.id)
                    }
                })
                expectedList.map(x=>{
                    if (expectedBenefit.findIndex(d=>d.value==x.id)===-1){
                        list_expected_benefitId.push(x.id)
                        // this.props.deleteExp1(x.id)
                    }
                })
                this.props.postExp1(list_expected_benefit)
                this.props.postLast1(list_latest_benefit)
                this.props.deleteExp1(list_expected_benefitId)
                this.props.deleteLast1(list_latest_benefitId)
            axios
                .put(`${API}/api/user/${profile.id}`, dataUser, {
                    headers: {
                        authorization: token,
                    }
                }).then(res => {
                    setTimeout(() => {
                        this.props.getSalaryBenefit()
                        this.props.handlePopup("salary_")
                        this.scrollToTop("sal1")
                    }, 850);
                }).catch(err => {
                    console.log(err, "error");
                })
        } else {
            console.log("error");
        }
    }

    handleChangeCV = (file) => {
        this.uploadCV(file)
    };

    closeDocument=(e)=>{
        this.setState({err_size:""})
        this.props.handlePopup("add_documents")
        this.scrollToTop("cv1")
    }

    handleChangeDoc = (client_logo) => {
        const sz = Math.ceil(parseInt(client_logo.size)/1024)
        const { profile } = this.props
        const { document_name, doc_list } = this.state
        const findDoc = document_name.indexOf(client_logo.name)
        if (findDoc == -1) {
            let formData = new FormData();
            if (client_logo) formData.append("files", client_logo);
            const newName = [...document_name, client_logo.name];
            this.setState({ document_name: newName });
            if (sz > 10240){
                this.setState({err_size:"The file is too large. Allowed maximum size is 10 MB."})
            }else{
                this.setState({status_doc:true})
            axios
                .post(`${API}/api/user_document/${profile.id}?document_type_id=2`, formData, {
                    headers: {
                        authorization: token,
                    }
                }).then(res => {
                    this.setState({ document_name: newName });
                    const newDoc = [...doc_list, res.data]
                    this.setState({ doc_list: newDoc, status_doc:false, err_size:"" });
                    this.props.getDoc(profile.id)
                }).catch(err => {
                    console.log(err, "error");
                })
        }}
        // setTimeout(() => {
        //     this.setState({err_size:""})
        // }, 3000);

    };

    saveDocument = (id) => {
        const { profile } = this.props
        const { document_name, doc_list } = this.state

        doc_list.map(item=>{
            let formData = new FormData();
            if (item) formData.append("files", item);
            const newName = [...document_name, item.name];
            this.setState({ document_name: newName });
            axios
                .post(`${API}/api/user_document/${id}?document_type_id=2`, formData, {
                    headers: {
                        authorization: token,
                    }
                }).then(res => {
                    this.setState({ document_name: newName });
                    const newDoc = [...doc_list, res.data]
                    this.setState({ doc_list: newDoc });
                    this.props.getDoc(id)
                }).catch(err => {
                    console.log(err, "error");
                })
            })
    };

    updateCountry = (e) => {
        this.setState({ province: true })
        this.props.handleProvince(e)
    }

    getCityID=(e)=>{
        axios.get(`${API}/api/city?state_id=${e}`).then((res)=>(
            this.setState({data_city:res.data})
        ))
    }

    handleState = (e, y) => {
        const newE = e + 'Error'
        var regex1 = /^[0-4]\.\d{2}$/;
        if (y.length == 0) {
            this.setState({ [newE]: true })
        } else {
            this.setState({ [newE]: false })
        }
        if (e=="ipk"){
            if (y === 0 || y ==="" || y ==="0"){
                this.setState({ [e]: y })
                } else if( regex1.test(y)===false || y > 4 || y <0){
                    this.setState({ [newE]: true })
                }else{
                    this.setState({ [newE]: false })
                }
        }
        this.setState({ [e]: y })
        if (e==="work_period_end"){
            this.setState({still_work:false})
        }
    }

    setDate = (e, y) => {
        this.setState({ [y]: e })
    }

    deleteCertificate = (id) => {
        this.props.deleteCertification1(id);
        setTimeout(() => {
            this.props.getCertification()
            this.setState({certificate_id:0})
        }, 250);
      };
    
    updateCertificate=(e)=>{
        this.setState({
            certification_name : e.title, 
            month: e.month, 
            year : e.year, 
            certificate_id : e.id
        })
    }

    addCertification = (e) => {
        const { certification_name, month, year, certificate_id } = this.state
        var dataArr = [certification_name, month, year];
        var dataArrErr = ['certification_nameError', 'monthError', 'yearError',];
        var validError = true
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            if (element.length == 0) {
                validError = false
                this.setState({ [dataArrErr[index]]: true });
            }
        }
        if (validError == true) {
            const n = this.state
            const data = {
                user_id: e,
                title: n.certification_name,
                institution_name: n.certification_name,
                month: n.month,
                year: n.year,
            }
            if (certificate_id == 0) {
                this.props.postCertificate1(data);
            } else {
                data.id = certificate_id
                this.props.updateCertificate1(data);
            }
            setTimeout(() => {
                this.props.getCertification()
                this.props.getSalaryBenefit()
                this.setState({certificate_id:0})
                this.cancelCer()
                this.scrollToTop("cer1")
                this.props.changePopup("certification_", "add_certification")
            }, 500); 
        }
    }

    dateFormate = (date) => {
        const day1 = new Date(date)
        return day1.toLocaleString('en-us', { day: 'numeric' }) + " " + day1.toLocaleString('en-us', { year: 'numeric', month: 'long' })
    }

    updateProject=(e)=>{
        this.setState({
            name:e.name,
            monthNameP:e.month,
            yearNameP:e.year,
            project_id:e.id
        })
    }

    deleteProject=(e)=>{
    axios.delete(`${API}/api/user_project/${e}`, {
                headers: {
                    authorization: token,
                }
            })
    }

    addProject = (e) => {
        const { name, monthNameP, yearNameP, project_id} = this.state
        const { list_month } = this.props
        var dataArr = [name, monthNameP, yearNameP];
        var dataArrErr = ['nameError', 'monthNamePError', 'yearNamePError',];
        var validError = true
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            if (element.length == 0) {
                validError = false
                this.setState({ [dataArrErr[index]]: true });
            }
        }
        if (validError == true) {
            const n = this.state
            const monthName = list_month[n.monthNameP - 1]
            const date = {
                month: monthNameP,
                year: n.yearNameP,
            }
            const data = {
                user_id: e,
                name: n.name,
                month:date.month,
                year:date.year
            }
            if (project_id == 0) {
                this.props.updateUserProject1(data)
            } else {
                data.id = project_id
                // this.props.updateCertificate1(data);
                axios
            .put(`${API}/api/user_project/${data.id}`, data, {
                headers: {
                    authorization: token,
                }
            })
            }
            setTimeout(() => {
                this.setState({project_id:0})
                this.props.getSalaryBenefit()
                this.cancelProject()
                this.scrollToTop("project1")
                this.props.changePopup("add_project", "project_")
            }, 500);
            

        }
    }
    cancelSkill = () => {
        this.setState({ tags: [] })
        this.setState({ update1Skill: true });
    }

    cancelProject = () => {
        this.setState({ name: "" })
        this.setState({ monthNameP: "" });
        this.setState({ yearNameP: "" })
    }

    cancelEdu = () => {
        this.setState({ education_level_id: "" });
        this.setState({ institution_name: "" });
        this.setState({ show_only: "" });
        this.setState({ ipk: "" });
        this.setState({ education_start: new Date() });
        this.setState({ education_end: new Date() });
        this.setState({ edu_id: 0 })
    }
    cancelWork = () => {
        this.setState({ job_title: "" });
        this.setState({ job_level_id: "" });
        this.setState({ company_name: "" });
        this.setState({ job_description: "" });
        this.setState({ job_function: "" });
        this.setState({ industry: "" });
        this.setState({ work_period_start: new Date() })
        this.setState({ work_period_end: new Date() })
    }

    cancekSalary = () => {
        this.setState({ latestSalary: "" })
        this.setState({ expectedSalary: "" })
        this.setState({ updateSal: true })
    }

    cancelCer = () => {
        this.setState({ certification_name: "", });
        this.setState({ month: "" });
        this.setState({ year: "" });
    }

    updateEdu = (item) => {
        this.setState({ education_level_id: item.education_level_id });
        this.setState({ institution_name: item.institution_name });
        this.setState({ show_only: item.show_only });
        this.setState({ ipk: item.ipk });
        this.setState({ education_start: item.work_period_start });
        this.setState({ education_end: item.work_period_end });
        this.setState({ edu_id: item.id })
    }

    deleteEducation =(e)=>{
        this.props.deleteEdu1(e);
        setTimeout(() => {
            this.props.getEducation();
        }, 250);
        
    }

    addEducation = (e) => {
        const { education_level_id, institution_name, show_only, ipk, edu_id } = this.state
        var dataArr = [education_level_id, institution_name, show_only, ipk];
        var dataArrErr = ['education_level_idError', 'institution_nameError', 'show_onlyError', 'ipkError'];
        var validError = true
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            if (element.length == 0) {
                validError = false
                this.setState({ [dataArrErr[index]]: true });
            }
        }
        if (validError == true) {
            const n = this.state
            var data = {
                user_id: `${e}`,
                education_level_id: n.education_level_id,
                institution_name: n.institution_name,
                show_only: n.show_only,
                ipk: n.ipk,
                work_period_start: n.education_start,
                work_period_end: n.education_end
            }
            if (edu_id == 0) {
                this.props.postedu1(data);
            } else {
                data.id = edu_id
                this.props.putEducation1(data);
            }
            
            setTimeout(() => {
                this.cancelEdu()
                this.props.getEducation();
                this.props.changePopup("add_edu", "education_");
                this.scrollToTop("add_edu1")
                this.setState({edu_id:0})
            }, 500);
            
        }
    }

updateWork=(e)=>{
    let a 
    if (e.work_period_start===null && e.work_period_end ===null){
        a = null
    }else if (e.work_period_start!=null && e.work_period_end ===null){
        a = new Date()
        this.setState({still_work:true})
    }else{
        a = e.work_period_end
    }
    this.setState({
        work_id:e.id,
        job_title:e.job_title, 
        job_level_id :e.job_level_id,
        company_name:e.company_name, 
        job_function: e.job_function_id, 
        industry:e.industry_id, 
        job_description:e.job_description,
        work_period_start:e.work_period_start,
        work_period_end:a
    })
}
deleteWork = (id_work) => {
    this.props.deleteWork1(id_work);
    setTimeout(() => {
        this.props.getWork()
        this.setState({work_id:0})
    }, 300);
}

handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

handleChangeEB = expectedBenefit => {
this.setState({ expectedBenefit });
};
handleChangeLB = latestBenefit => {
    this.setState({ latestBenefit });
    };
addField=(e,y,z)=>{
    if (e.findIndex(c=>c.label===y.label)==-1){
    const n = [...e]
    n.push(y)
   this.setState({[z]:n})}
}

addOption=(e)=>{
    this.setState({sug_option:{value:1,label:e}})
    if (e==""){
        console.log("");
    }else if (e==undefined){
        console.log("");
    }else{
        this.setState({ ff:e})
    }
}

addOptionEB=(e)=>{
    this.setState({sug_option:{value:1,label:e}})
    if (e==""){
        console.log("");
    }else if (e==undefined){
        console.log("");
    }else{
        this.setState({ ff_EB:e})
    }
}

onKeyDown=(e,y,z,x)=>{
    if (e.key === 'Enter' && this.state.sug_option.label !=="") {
        const n = [...y]
        n.push(this.state.sug_option)
        this.setState({keys:e.key,ll: e.code,inputLB:""})
       this.setState({[z]:n})
       setTimeout(() => {
        document.querySelector(x).value = '';
       }, 100);
      }
}

onKeyDown2=(r,e)=>{
    const {latestBenefit, inputLB,ff}= this.state
    if (ff !== ""){
    const n = [...latestBenefit]
    n.push({value:90,label:ff})
    this.setState({["latestBenefit"]:n,inputLB:""})
    setTimeout(() => {
        this.setState({ff:""})
    }, 100);
}}

onKeyDownExp=(r,e)=>{
    const {expectedBenefit, inputEB,ff_EB}= this.state
    if (ff_EB !== ""){
    const n = [...expectedBenefit]
    n.push({value:90,label:ff_EB})
    this.setState({["expectedBenefit"]:n,inputEB:""})
    setTimeout(() => {
        this.setState({ff_EB:""})
    }, 100);
}}

focus=(e)=>{
    console.log(e,"ini masuk fokus", e.target);
}
    addWorkExperience = (e) => {
        const { job_title, job_level_id, company_name, job_function, industry, job_description, work_id, still_work } = this.state
        var dataArr = [job_title, job_level_id, company_name, job_function, industry, job_description];
        var dataArrErr = ['job_titleError', 'job_level_idError', 'company_nameError', 'job_functionError', 'industryError', 'job_descriptionError'];
        var validError = true
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            if (element.length == 0) {
                validError = false
                this.setState({ [dataArrErr[index]]: true });
            }
        }
        const n = this.state
        if (validError == true) {
            const n = this.state
            const data = {
                user_id: e,
                job_title: n.job_title,
                job_level_id: n.job_level_id,
                company_name: n.company_name,
                job_function_id: n.job_function,
                industry_id: n.industry,
                work_period_start: n.work_period_start,
                work_period_end: still_work?null: n.work_period_end,
                job_description: n.job_description,
            };
            if (work_id == 0) {
                this.props.work1(data);
            } else {
                data.id = work_id
                this.props.workupdate1(data);
            }
            
            setTimeout(() => {
                this.props.getWork(e)
                this.props.getSalaryBenefit()
                this.scrollToTop("work1")
                this.cancelWork()
                this.setState({work_id:0})
                this.props.changePopup("add_work", "work_experience")
            }, 900);
           
        }
    }


    changeJob = (event, values) => {
        let job_function_id
        if (event === undefined || event === "" || event === null || event === this.state.job_function_id) {
            job_function_id = ""
        } else if (event === this.state.job_function_id && this.state.job_function_id.length) {
            job_function_id = ""
        } else {
            job_function_id = event
        }
        const cek1 = [...this.state.job_function_id]
        const index = cek1.indexOf(job_function_id);
        if (this.state.job_function_id.length < 5) {
            if (index === -1) {
                cek1.push(job_function_id)
            } else {
                cek1.splice(index, 1)
            }
            this.setState({ job_function_id: cek1 })
            const job = {
                user_id: this.state.user_id,
                job_function_id: cek1
            }
            this.props.jobFunction11(job)
        } else if ((event === this.state.job_function_id[0]) || (event === this.state.job_function_id[1]) || (event === this.state.job_function_id[2])
            || (event === this.state.job_function_id[3]) || (event === this.state.job_function_id[4])) {
            cek1.splice(index, 1)
            this.setState({ job_function_id: cek1 })
            const job = {
                user_id: this.state.user_id,
                job_function_id: cek1
            }
            this.props.jobFunction11(job)
        }
    };

    changeIndustry = (event, values) => {
        let industry_id
        if (event === undefined || event === "" || event === null || event === this.state.industry_id) {
            industry_id = ""
        } else if (event === this.state.industry_id && this.state.industry_id.length) {
            industry_id = ""
        } else {
            industry_id = event
        }
        const cek1 = [...this.state.industry_id]
        const index = cek1.indexOf(industry_id);
        if (this.state.industry_id.length < 5) {
            if (index === -1) {
                cek1.push(industry_id)
            } else {
                cek1.splice(index, 1)
            }
            this.setState({ industry_id: cek1 })
            const industry = {
                user_id: this.state.user_id,
                industry_id: cek1
            }
            this.props.Industry11(industry)
        } else if ((event === this.state.industry_id[0]) || (event === this.state.industry_id[1]) || (event === this.state.industry_id[2])
            || (event === this.state.industry_id[3]) || (event === this.state.industry_id[4])) {
            cek1.splice(index, 1)
            this.setState({ industry_id: cek1 })
            const industry = {
                user_id: this.state.user_id,
                industry_id: cek1
            }
            this.props.Industry11(industry)
        }
    };

    closeInterest = (e) => {
        this.props.getSalaryBenefit()
        this.props.handlePopup("interest_")
        this.scrollToTop("interest1")
    }

    deleteDocument=(e,y)=>{
        this.props.deleteDocument(e)
        setTimeout(() => {
            this.props.getDoc(y)
            this.props.getSalaryBenefit()
        },100);
        
    }

    scrollToTop=(e)=>{
        document.getElementById(e).scrollIntoView(0,0)
    }

    setData=(e)=>{
        this.setState({dataEdit:e})
      }
    
    confirmData=(e,y)=>{
        this.setState({deleteItem:true, dataToDelete:[e,y]})
    }
    deleteField=(e)=>{
     const {dataToDelete} = this.state
     const id = dataToDelete[0]
     const name = dataToDelete[1]
     const data_list = ["add_documents","work_experience","education_","certification_","project_"]
     const function_list = [this.deleteDocument(id), this.deleteWork(id), this.deleteEducation(id),this.deleteCertificate(id), this.deleteProject(id)]
     const n = data_list.indexOf(name)
     return function_list[n]
     
    }

    render() {
        const { work_pre, name, err_size, yearNamePError, monthNamePError, monthNameP, yearNameP, showCV, document_name, submit1Cv, tagsEBAdd, latestSalary, nameError, data_city,deleteItem,status_doc,
            expectedSalary, education_level_id, institution_name, show_only, ipk, tagsAdd, tagsLBAdd, education_level, doc_list, job_title, tagsLBDel, tagsEBDel, tagsLB, tagsEB, tags, score,birth_date,
             cv_name, expected_benefit, latest_benefit, province, education_start, certification_name, yearError, monthError, certification_nameError,selectedOption,latestBenefit,expectedBenefit,
            education_end, job_description, benefit_suggestions, work_period_start, work_period_end, expectedSalaryError, job_level_id, company_name, job_function, industry, latestSalaryError,still_work,
             job_titleError, job_level_idError, company_nameError, job_descriptionError, job_functionError, industryError, education_level_idError, institution_nameError, show_onlyError, ipkError, month, year } = this.state
        const { add_certification, add_documents, add_edu, add_project, add_work, upload_cv1, upload_cv2, job_desc,expectedList, latestList,
            personal_info, salary_, skills_, work_experience, education_, certification_, educationId, formatDate,document1list,document2list,
            project_, interest_, promoter_score, feedback, profile, dataIndustri, dataJobFunction, dataJobLevel,dataProject,
            dataCountry, onlyCity1, onCity, listExp, listLast, skill_list, listBenefit, dataProvince, workPre, workSta, dataCity, list_month, years, certificationId,
             month_year, listEdu, workId, job_description_id,skillList,  } = this.props
        const benefitSuggestions = this.updateSuggestionsBenefitList(listBenefit.benefitList)
        const skilSuggestions = this.updateSuggestionsSkillList(skill_list)
        var projectList = []
        if (!!profile.project_award) {
            projectList = JSON.parse(profile.project_award)
        }
        const id_skill=[1,3,5,6,54,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81]
        const id_skill_mobile=[57,58,62,64,65,69,70,76,77,80]
        let sug_skills =[]
        let sug_skills_mobile =[]
         skill_list.map((item)=>{
            if (id_skill.indexOf(item.id)!== -1){
                sug_skills.push(item)
            }
            if (id_skill_mobile.indexOf(item.id)!== -1){
                sug_skills_mobile.push(item)
            }

        })


       let list_cityID =[]
       const y = dataCity.findIndex(n=>n.id!=="")
       if (y===-1){
        list_cityID = data_city
       }else{
        list_cityID = dataCity
       }

        const defaultMaskOptions = {
            prefix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 10, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
        }

        const img_dro = <img src={dro} className="flex wd-14 hg-8 img-dro" />

        const currencyMask = createNumberMask({
            ...defaultMaskOptions,
        })

        const fileTypes = ["PDF"];
        const cityList = dataCity.length != 0 ? dataCity : onlyCity1.onlyCity
        const KeyCodes = {
            comma: 188,
            enter: 13
        };
        const delimiters = [KeyCodes.comma, KeyCodes.enter];

        const options2 = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
          ]

        const rC=(e)=>{
        let r =[]
        if (e.length>0){
            e.map((item)=>r.push(item.label))
        }
        return r
        }

        const setOption=(e,y,z,r)=>{
            let n =[]
            e.map((item,i)=>{
                if (r.indexOf(item[z])===-1){
                n.push({value:item[y],label:item[z]})}
        })
            return n
        }

        let cbirth_date = null
        if (birth_date===null){
            cbirth_date=profile.birth_date
        }else{
            cbirth_date=birth_date
        }
        let opt_benefit = []
        let opt_benefit_ex =[]
        let opt_benefit_sug =[]
        listBenefit.benefitList.map((item)=>{
            opt_benefit.push({value:item.id,label:item.benefit_name})
            if (expectedBenefit.findIndex(x=>x.label==item.benefit_name)===-1){
            opt_benefit_ex.push({value:item.id,label:item.benefit_name}) }
            if (latestBenefit.findIndex(x=>x.label==item.benefit_name)===-1){
            opt_benefit_sug.push({value:item.id,label:item.benefit_name}) }
    })

    let end_date
    const set_endDate =(a,b)=>{
     if (a!=null && b===null){
        end_date = "Present"
     }else{
        end_date = this.dateFormate(b)
     }
     return end_date
    }

    const set_endDate_tab =(a,b)=>{
        if (a!=null && b===null){
           end_date = "Present"
        }else if (a===null && b===null){
            end_date = "-"
        } else{
           end_date = formatDate(b)
        }
        return end_date
       }

        return (
            <div>
                <div className='flex column width-100 popup-myprofile'>
                    
                    <Modal visible={personal_info} width={'80%'} height="85%">
                        <div className='flex wd-1000 column bg-f9 br-10'>
                            <Formik initialValues={{
                                name: !!profile.name ? profile.name : '',
                                birth_date: !!cbirth_date ? cbirth_date : '',
                                gender: !!profile.gender_id ? profile.gender_id : '',
                                phoneNumber_1: !!profile.phone_number ? profile.phone_number : '',
                                phoneNumber_2: !!profile.phone_number2 ? profile.phone_number2 : '',
                                phoneNumber_3: !!profile.phone_number3 ? profile.phone_number3 : '',
                                linkedIn_link: !!profile.linkedin_link ? profile.linkedin_link : '',
                                country: !!profile.country_id ? profile.country_id : '',
                                state: !!profile.state_id ? profile.state_id : '',
                                city: !!profile.city_id ? profile.city_id : '',
                                address: !!profile.address ? profile.address : '',
                                work_preference: !!profile.work_preference_id ? profile.work_preference_id : '',
                                work_status: !!profile.work_status_id ? profile.work_status_id : '',
                                professional_summary: !!profile.professional_summary ? profile.professional_summary : ''
                            }}
                                enableReinitialize={true}

                                validationSchema={Yup.object({
                                    name: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'Full Name is required'),
                                    birth_date: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'Birth_date is required'),
                                    gender: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'Gender is required'),
                                    phoneNumber_1: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'PhoneNumber 1 is required'),
                                    // linkedIn_link: Yup
                                    //     .string()
                                    //     .max(255)
                                    //     .required(
                                    //         'LinkedIn link is required'),
                                    country: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'Country is required'),
                                    state: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'State is required'),
                                    city: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'City is required'),
                                    // address: Yup
                                    //     .string()
                                    //     .max(255)
                                    //     .required(
                                    //         'Address is required'),
                                    work_preference: Yup
                                        .string()
                                        .max(255)
                                        .required(
                                            'Work_preference'),
                                    work_status: Yup
                                        .string()
                                        .max(255)
                                        .required('Work status is required'),
                                    // professional_summary: Yup
                                    //     .string()
                                    //     // .max(255)
                                    //     .required(
                                    //         'Professional summary  is required'),
                                })}
                                onSubmit={(userData) => this.updatePersonalInfo(profile.id, userData)}
                            >
                                {props => (
                                    <form  id="personal_info" onSubmit={props.handleSubmit} className="info_">

                                        <div className='flex width-100 bg-ff filter-15 br-10-0' id='mol'>
                                            <div className=' m-2432 flex wd-webkit space-between'>
                                                <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                                <div className='flex'>
                                                    <div className='flex m-auto0'> {people}</div>
                                                    <p className='DM-Sans s-24 c-29 ml-14'>Personal Information</p>
                                                </div>
                                                <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => { 
                                                    props.resetForm()
                                                    this.props.handlePopup("personal_info")
                                                    this.scrollToTop("personal1")} }/>
                                                    
                                            </div>
                                        </div>
                                        {/* <style>

                            </style> */}
                                        <div className='p-24 p-height overflow'>

                                            <Box className="inputBox">
                                                <div className='flex column wd-302 mb-14' id='personal1'>
                                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Name</p>
                                                    <input type='text' className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans ' value={name===""?profile.name:name}  onChange={(e) => this.handleState("name", e.target.value)}/>
                                                    {props.touched.name && props.errors.name ? <span style={{ color: 'red' }}>{props.errors.name}</span> : null}
                                                </div>

                                                <div className='flex column wd-302 mb-14 dt'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Birth Date</p>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{backgroundColor:"white"}}>
                                                        <KeyboardDatePicker
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            format="dd/MM/yyyy"
                                                            value={birth_date===null?profile.birth_date:birth_date}
                                                            onChange={(e) => this.handleState("birth_date", e)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    {props.touched.birth_date && props.errors.birth_date ? <span style={{ color: 'red' }}>{props.errors.birth_date}</span> : null}
                                                </div>
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Gender</p>
                                                    <select type='text' name="gender" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' onChange={props.handleChange} value={props.values.gender} >
                                                        <option style={{ display: "none" }}>SELECT GENDER</option>
                                                        <option value={1} >Male</option>
                                                        <option value={2}>Female</option>
                                                    </select>
                                                    {img_dro}
                                                    {props.touched.gender && props.errors.gender ? <span style={{ color: 'red' }}>{props.errors.gender}</span> : null}
                                                </div>

                                            </Box>
                                            <Box className="inputBox">
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Phone Number 1</p>
                                                    <input type='tel' name="phoneNumber_1" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.phoneNumber_1} onChange={props.handleChange} />
                                                    {props.touched.phoneNumber_1 && props.errors.phoneNumber_1 ? <span style={{ color: 'red' }}>{props.errors.phoneNumber_1}</span> : null}
                                                </div>
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Phone Number 2 (optional)</p>
                                                    <input type='tel' name="phoneNumber_2" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.phoneNumber_2} onChange={props.handleChange} />
                                                </div>
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Phone Number 3 (optional)</p>
                                                    <input type='tel' name="phoneNumber_3" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.phoneNumber_3} onChange={props.handleChange} />
                                                </div>
                                            </Box>
                                            <Box className="inputBox">
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Email</p>
                                                    <input type='email' name="email" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans backG' value={profile.email} readOnly={true} />
                                                </div>
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>LinkedIn Link</p>
                                                    <input type='text' name="linkedIn_link" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.linkedIn_link} onChange={props.handleChange} />
                                                    {/* {props.touched.linkedIn_link && props.errors.linkedIn_link ? <span style={{ color: 'red' }}>{props.errors.linkedIn_link}</span> : null}
                                                 */}
                                                </div>
                                            </Box>

                                            <Box className="inputBox">
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Country</p>
                                                    <select type='text' name="country" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.country}
                                                        onChange={(e) => {
                                                            props.handleChange(e);
                                                            this.updateCountry(e.target.value);
                                                        }}>
                                                        <option style={{ display: "none" }}>SELECT COUNTRY</option>
                                                        {dataCountry.map((item, i) => (
                                                            <option key={i} value={item.id} >{item.country_name} </option>))}
                                                    </select>{img_dro}
                                                    {props.touched.country && props.errors.country ? <span style={{ color: 'red' }}>{props.errors.country}</span> : null}
                                                </div>
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>State</p>
                                                    <select type='text' name="state" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' 
                                                        onChange={(e) => {
                                                            props.handleChange(e);
                                                            this.props.handleCity(e.target.value)
                                                        }}>
                                                        <option style={{ display: "none" }}>SELECT STATE</option>
                                                        {dataProvince.map((item, i) => (
                                                            <option key={i} value={item.id}  selected={parseInt(item.id)===parseInt(profile.state_id)?"selected":false}>{item.state_name} </option>))}
                                                    </select>{img_dro}
                                                    {props.touched.state && props.errors.state ? <span style={{ color: 'red' }}>{props.errors.state}</span> : null}
                                                </div>
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>City</p>
                                                    <select type='text' name="city" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans'  
                                                    onChange={props.handleChange}
                                                    >
                                                        <option  style={{ display: "none" }}>SELECT CITY</option>
                                                        {list_cityID.map((item, i) => (
                                                            <option key={i} value={item.id} selected={parseInt(item.id)===parseInt(profile.city_id)?"selected":false}  >{item.city_name} </option>))}
                                                    </select>{img_dro}
                                                    {props.touched.city && props.errors.city ? <span style={{ color: 'red' }}>{props.errors.city}</span> : null}
                                                </div>

                                            </Box>
                                            <Box className="inputBox">
                                                <div className='flex column width-100 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Address</p>
                                                    <input type='text' name="address" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.address} onChange={props.handleChange} />
                                                    {/* {props.touched.address && props.errors.address ? <span style={{ color: 'red' }}>{props.errors.address}</span> : null}
                                                 */}
                                                </div>

                                            </Box>
                                            <Box className="inputBox">
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Work Preference</p>
                                                    <select type='text' name="work_preference" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.work_preference} onChange={props.handleChange} >
                                                        <option style={{ display: "none" }}>SELECT WORK PREFERENCE</option>
                                                        {workPre.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.work_preference_name} </option>))}
                                                    </select>{img_dro}
                                                    {props.touched.work_preference && props.errors.work_preference ? <span style={{ color: 'red' }}>{props.errors.work_preference}</span> : null}
                                                </div>
                                                <div className='flex column wd-302 mb-14'>
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420' id="moi">Work Status</p>
                                                    <select type='text' name="work_status" className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={props.values.work_status} onChange={props.handleChange} >
                                                        <option style={{ display: "none" }}>SELECT WORK STATUS</option>
                                                        {workSta.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.work_status_name} </option>))}
                                                    </select>{img_dro}
                                                    {props.touched.work_status && props.errors.work_status ? <span style={{ color: 'red' }}>{props.errors.work_status}</span> : null}
                                                </div>
                                            </Box>
                                            <Box className="inputBox">
                                                <div className='flex column width-100 mb-14' >
                                                    <p className='DM-Sans-Medium c-72 mb4 s-1420' onClick={(e)=>this.closeMO(e)}>Professional Summary</p>
                                                    <textarea name="professional_summary" className='width-100 bg-ff flex shd-br-10 hg-108 out-none bd-unset s-1420 DM-Sans' onChange={props.handleChange} value={props.values.professional_summary}>{props.values.professional_summary}</textarea>
                                                    {/* <input type='text' name="professional_summary" className='width-100 bg-ff flex shd-br-10 hg-108 out-none bd-unset s-1420 DM-Sans' value={props.values.professional_summary} onChange={props.handleChange} />  */}
                                                    {/* {props.touched.professional_summary && props.errors.professional_summary ? <span style={{ color: 'red' }}>{props.errors.professional_summary}</span> : null} 
                                                 */}
                                                </div>
                                            </Box>
                                            <div className='flex flex-end'>
                                                <div className='flex wd-fit-content bg-36 br-25 p-1024 pointer' onClick={() => {
                                                    props.submitForm()
                                                
                                                }} >
                                                    <p className='c-ff s-1420 DM-Sans ' >Save Changes</p>
                                                </div>
                                            </div>

                                        </div>
                                    </form>)}
                            </Formik>
                        </div>
                    </Modal>
                    <Modal visible={add_work} height={'80%'} width="670">
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Add Work Experience</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {
                                        this.cancelWork()
                                        this.props.handlePopup("add_work")
                                    }} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex column width-100 mb-14' id='add-w1'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Job Title</p>
                                    <input type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={job_title} onChange={(e) => this.handleState("job_title", e.target.value)} />
                                    {job_titleError == true ? <span style={{ color: 'red' }}>Job Title is required </span> : null}
                                </div>
                                <div className='flex column width-100 mb-14'>
                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Job Function</p>
                                    <select type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={job_function} onChange={(e) => this.handleState("job_function", e.target.value)}  >
                                        <option style={{ display: "none" }}>SELECT JOB FUNCTION</option>
                                        {dataJobFunction.map(item => (
                                            <option value={item.id}>{item.job_function_name} </option>))}
                                    </select>{img_dro}
                                    {job_functionError == true ? <span style={{ color: 'red' }}>Job Function is required </span> : null}
                                </div>
                                <div className='flex column width-100 mb-14'>
                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Job Level</p>
                                    <select type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={job_level_id} onChange={(e) => this.handleState("job_level_id", e.target.value)}  >
                                        <option style={{ display: "none" }}>SELECT JOB LEVEL</option>
                                        {dataJobLevel.map(item => (
                                            <option value={item.id}>{item.job_level_name} </option>))}
                                    </select> {img_dro}
                                    {job_level_idError == true ? <span style={{ color: 'red' }}>Job Level is required </span> : null}
                                </div>
                                <div className='flex column width-100 mb-14'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Company Name</p>
                                    <input type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={company_name} onChange={(e) => this.handleState("company_name", e.target.value)} />
                                    {company_nameError == true ? <span style={{ color: 'red' }}>Company Name is required </span> : null}
                                </div>
                                <div className='flex column width-100 mb-14'>
                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Industry</p>
                                    <select type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={industry} onChange={(e) => this.handleState("industry", e.target.value)}  >
                                        <option style={{ display: "none" }}>SELECT INDUSTRY</option>
                                        {dataIndustri.map(item => (
                                            <option value={item.id}>{item.industry_name} </option>))}
                                    </select> {img_dro}
                                    {industryError == true ? <span style={{ color: 'red' }}>Industry is required </span> : null}
                                </div>
                                <div className='flex flexbox_gap20 equ_part space-between width-100 mb4 work-'>
                                    <div className='flex column wd-302 sm-m-2'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Work Period Start</p>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="dd/MM/yyyy"
                                                value={work_period_start}
                                                maxDate={new Date()}
                                                onChange={(e) => this.handleState("work_period_start", e)}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {/* <input type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans'  /> */}
                                    </div>
                                    <div className='flex column wd-302 '>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Work Period End</p>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="dd/MM/yyyy"
                                                maxDate={new Date()}
                                                value={work_period_end}
                                                onChange={(e) => this.handleState("work_period_end", e)}
                                                disabled={still_work}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {/* <input type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans'  /> */}
                                    </div>
                                </div>
                                <div className='flex width-100 mb-14'>
                                            <input type='checkbox' 
                                            value={still_work} checked={still_work}
                                            onChange={(e)=>this.updateStillWork(e)}
                                            className='mr-10 pointer' />
                                            <p className='DM-Sans-Medium c-72 s-1420' onClick={(e)=>this.updateStillWork(e)}>I still work here</p>
                                        </div>
                                <div className='flex column width-100 mb-48'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Job Description</p>
                                    {/* <textarea className='wd-webkit p-1024 bg-ff flex shd-br-10 hg-108 out-none bd-unset s-1420 DM-Sans' value={job_description} onChange={(e) => this.handleState("job_description", e.target.value)}>{job_description}</textarea> */}
                                    <CKEditor  className="form-control"
                                      aria-label="With textarea"
                                      rows="12"
                                      name="job_description"
                                      id="job_description"
                                      data={job_description}
                                      onChange={(e) => this.handleState("job_description", e.editor.getData())} />
                                    {job_descriptionError === true ? <span style={{ color: 'red' }}>Job Description is required </span> : null}
                                </div>
                                <div className='flex width-100 flex-end'>
                                    <div className='flex wd-fit-content bg-36 br-25 p-1024 pointer' onClick={(e) => this.addWorkExperience(profile.id)}>
                                        <p className='c-ff s-1420 DM-Sans '>Save</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={add_edu} height={'80%'} width="670">
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Add Education</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {
                                        this.cancelEdu()
                                        this.props.handlePopup("add_edu")
                                        this.scrollToTop("add_edu1")
                                    }} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex column width-100 mb-14' id='add_edu1'>
                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Education Level</p>
                                    <select type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={education_level_id} onChange={(e) => this.handleState("education_level_id", e.target.value)}>
                                        <option style={{ display: "none" }}>SELECT EDUCATIONAL STAGE</option>
                                        {listEdu.map(item => (
                                            <option value={item.id} >{item.education_name} </option>
                                        ))}
                                    </select> {img_dro}
                                    {education_level_idError == true ? <span style={{ color: 'red' }}>Education Level is required </span> : null}
                                </div>
                                <div className='flex column width-100 mb-14'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Institution Name</p>
                                    <input type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={institution_name} onChange={(e) => this.handleState("institution_name", e.target.value)} />
                                    {institution_nameError == true ? <span style={{ color: 'red' }}>Institution Name is required </span> : null}
                                </div>
                                <div className='flex column width-100 mb-14'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Degree</p>
                                    <input type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={show_only} onChange={(e) => this.handleState("show_only", e.target.value)} />
                                    {show_onlyError == true ? <span style={{ color: 'red' }}>Degree is required </span> : null}
                                </div>
                                <div className='flex column width-100 mb-14'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>GPA/IPK</p>
                                    <input type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={ipk} onChange={(e) => this.handleState("ipk",e.target.value)} />
                                    {ipkError == true ? <span style={{ color: 'red' }}>IPK/GPA is not valid, example : 3.50 </span> : null}
                                </div>
                                {/* <div className='none column width-100 mb-14'>
                                    <p className='DM-Sans-Medium c-72 mb4 s-1420'>Industry</p>
                                    <select type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans'  >
                                        <option>aa</option>
                                        <option>ab</option>
                                    </select>
                                </div> */}
                                <div className='flex flexbox_gap20 equ_part space-between width-100 '>
                                    <div className='flex column wd-302 mb-24'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Education Period Start</p>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="dd/MM/yyyy"
                                                value={education_start}
                                                onChange={(e) => this.handleState("education_start", e)}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {/* <input type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans'  /> */}
                                    </div>
                                    <div className='flex column wd-302 mb-48'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Education Period End</p>
                                        {/* <input type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans'  /> */}
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="dd/MM/yyyy"
                                                value={education_end} onChange={(e) => this.handleState("education_end", e)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className='flex width-100 flex-end'>
                                    <div className='flex wd-fit-content bg-36 br-25 p-1024 pointer' onClick={(e) => this.addEducation(profile.id)}>
                                        <p className='c-ff s-1420 DM-Sans ' >Save</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={add_certification} width="670" height="75%" className="add">
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Add Certification/Trainings</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {
                                        this.cancelCer()
                                        this.props.handlePopup("add_certification")
                                    }} />
                                </div>
                            </div>
                            <div className='p-24 '>
                                <div className='flex column width-100 mb-14'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Certification/Training Name</p>
                                    <input type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={certification_name} onChange={(e) => this.handleState("certification_name", e.target.value)} />
                                    {certification_nameError == true ? <span style={{ color: 'red' }}>Certification/Training Name is required </span> : null}
                                </div>
                                <div className='flex space-between width-100 flexbox_gap20 equ_part '>
                                    <div className='flex column wd-302 mb-24'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Month</p>
                                        <select type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={month} onChange={(e) => this.handleState("month", e.target.value)}>
                                            <option style={{ display: "none" }}>Month</option>
                                            {list_month.map((item, i) => (
                                                <option key={i} value={i + 1}>{item} </option>))}
                                        </select>{img_dro}
                                        {monthError == true ? <span style={{ color: 'red' }}>Month is required </span> : null}
                                    </div>
                                    <div className='flex column wd-302 mb-48'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Year</p>
                                        <select type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={year} onChange={(e) => this.handleState("year", e.target.value)} >
                                            <option style={{ display: "none" }}>Years </option>
                                            {years.map(item => (
                                                <option value={item}>{item} </option>))}
                                        </select>{img_dro}
                                        {yearError == true ? <span style={{ color: 'red' }}>Year is required </span> : null}
                                    </div>
                                </div>
                                <div className='flex width-100 flex-end'>
                                    <div className='flex wd-fit-content bg-36 br-25 p-1024 pointer' onClick={(e) => this.addCertification(profile.id)}>
                                        <p className='c-ff s-1420 DM-Sans ' >Save</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={add_project} height={'80%'} width="670" >
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Add Project {"&"} Awards</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {
                                        this.cancelProject()
                                        this.props.handlePopup("add_project")
                                        this.scrollToTop("project1")
                                    }} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex column width-100 mb-14'>
                                    <p className='c-72 mb4 s-1420 DM-Sans-Medium' id='project1'>Project {"&"} Awards Name</p>
                                    <input type='text' className='wd-webkit p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={name} onChange={(e) => this.handleState("name", e.target.value)} />
                                    {nameError == true ? <span style={{ color: 'red' }}>Project {"&"} Awards Name is required </span> : null}
                                </div>
                                <div className='flex flexbox_gap20 equ_part space-between width-100 '>
                                    <div className='flex column wd-302 mb-24'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Month</p>
                                        <select type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={monthNameP} onChange={(e) => {
                                            //const monthName = list_month[e.target.value]
                                            this.handleState("monthNameP", e.target.value)
                                        }}>
                                            <option style={{ display: "none" }}>Month</option>
                                            {list_month.map((item, i) => (
                                                <option key={i} value={item}  selected={i+1===parseInt(monthNameP)?true:false}>{item} </option>))}
                                        </select>{img_dro}
                                        {monthNamePError == true ? <span style={{ color: 'red' }}>Month  is required</span> : null}
                                    </div>
                                    <div className='flex column wd-302 mb-48'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Year</p>
                                        <select type='text' className='width-100 p-014 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 DM-Sans' value={yearNameP} onChange={(e) => this.handleState("yearNameP", e.target.value)} >
                                            <option style={{ display: "none" }}>Years </option>
                                            {years.map(item => (
                                                <option value={item}>{item} </option>))}
                                        </select>{img_dro}
                                        {yearNamePError == true ? <span style={{ color: 'red' }}>Years is required</span> : null}
                                    </div>
                                </div>
                                <div className='flex width-100 flex-end'>
                                    <div className='flex wd-fit-content bg-36 br-25 p-1024 pointer' onClick={(e) => this.addProject(profile.id)} >
                                        <p className='c-ff s-1420 DM-Sans ' >Save</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={upload_cv1} height={'80%'} width="670" >
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Upload CV</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => this.props.handlePopup("upload_cv1")} />
                                </div>
                            </div>

                            <div className='p-24 ' style={{ maxHeight: `${parseInt(height_) - 160} px` }}>
                                <FileUploader handleChange={this.handleChangeCV} name="file" types={fileTypes}>
                                    <div className='flex column center width-100 bg-15 br-10 dashed-36'>
                                        <div className='flex mt-113 mb-24'>
                                            {cv1}
                                        </div>
                                        {status_doc===false &&
                                        <p className='DM-Sans-Medium c-29 m-m-0 mb-48 s-2026' >Drag and Drop your CV here</p>}
                                         {status_doc===false &&
                                        <p className='DM-Sans-Medium c-72 m-m-0 mb-48 s-2026' >or</p>}
                                            {status_doc===false &&
                                        <label for='inputFile_job'>
                                            <div className='flex wd-fit-content bg-36 br-25 p-1024 pointer m-m-0 mb-59'>
                                                <p className='c-ff s-1420 DM-Sans ' >Browse File</p>
                                            </div>
                                        </label>}
                                        {status_doc===true &&
                                        <div style={{marginBottom:"50px"}}>
                                            <Spinner radius={40} color={"#3B6C98"} stroke={3} visible={true} /> </div>}
                                        <input type='file' id="inputFile_job" className='none' onChange={(e) => this.uploadCV(e.target.files[0], profile.id)} accept=".pdf" />
                                        <p className='s-14 c-29 DM-Sans m-m-0 mb-12'>Supported file: PDF</p>
                                    </div>
                                </FileUploader>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={upload_cv2} height={'80%'} width="670" >
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Upload CV</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => this.props.handlePopup("upload_cv2")}
                                    />
                                </div>
                            </div>
                            <div className='p-24 p-height'>
                                <div className='wd-webkit p-14 bg-ff flex shd-br-10 out-none bd-unset s-1420 DM-Sans flex mb-24'>
                                    <div className='flex m-auto2-0'>
                                        <div className='flex hg-24 wd-24 mr-14' >{cv1}</div>
                                        <p className='m-auto DM-Sans c-29 s-1624'>{cv_name} </p>
                                    </div>
                                    <div className='flex m-auto0'>
                                        <p className='flex DM-Sans s-1420 c-29 mr8'>100% Uploaded</p>
                                        <div className='flex bd-36 bg-36 hg-10 br-5 wd-128 m-auto' >
                                            {/* <div className='br-5 bg-36 wd-0 hg-100 wd-0 trans-width' style={{width:upload_cv2?"100%":""}}/> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex width-100 space-between'>
                                    <div className='bd-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => this.props.changePopup("upload_cv2", "upload_cv1")}>
                                        <p className='DM-Sans s-14 c-36'>Change CV</p>
                                    </div>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => this.submitCV(profile.id)}>
                                        <p className='DM-Sans s-14 c-ff'>Submit CV</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={add_documents} height={'80%'} width="670">
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Documents</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => this.closeDocument(e)} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <p className='DM-Sans-Medium s-1624 c-29 mb-14' id='cv1'>My CV</p>
                                <div className='wd-webkit p-14 bg-ff flex shd-br-10 out-none bd-unset s-1420 DM-Sans flex mb-24'>
                                    <a href={ `${API}/File/Document/${showCV}`} target="_blank" rel="noopener noreferrer" className='m-auto2-0 DM-Sans c-29 s-1624'>{submit1Cv}</a>
                                    <div className='flex m-auto0 pointer p-414 br-5 bd-36' onClick={(e) => this.props.changePopup("add_documents", "upload_cv1")}>
                                        <p className='flex DM-Sans s-1420 c-36'>Change CV</p>
                                    </div>
                                </div>
                                <div className='flex width-100 mb-24 hg-0 bd-ea' />
                                <p className='DM-Sans-Medium s-1624 c-29 mb-14'>Other Documents</p>
                                {document2list.length > 0 ? document2list.map((item,i) => (
                                    <div key={i} className='wd-webkit p-14 bg-ff flex shd-br-10 out-none bd-unset s-1420 DM-Sans flex mb-14' >
                                        <a href={ `${API}/File/Document/${item.file}`} target="_blank" rel="noopener noreferrer" className='m-auto2-0 DM-Sans c-29 s-1624'>{item.title}</a>
                                        <p className='pointer flex' onClick={(e)=>{this.confirmData(item.id,"add_documents")}} >{icon_delete}</p>
                                    </div>
                                ))
                                    : ""}
                                <div className='wd-webkit bg-15 p-14  shd-br-10 out-none bd-19 s-1420 DM-Sans  mb-100'>
                                    {/* <FileUploader handleChange={this.handleChangeDoc} name="file" types={fileTypes} maxSize={1}> */}
                                    <FileUploader handleChange={this.handleChangeDoc} name="file" types={fileTypes} >
                                        <div className="flexbox_gap20 equ_part" style={{ display: 'flex' }}>
                                            {status_doc===true &&
                                            <Spinner radius={20} color={"#3B6C98"} stroke={1} visible={true} />}
                                              {status_doc===false &&
                                            <p className='m-auto2-0 DM-Sans c-36 s-1624 pointer'>Select File</p>}
                                            <p className=' DM-Sans c-29 s-1624 pointer'>(extentions, max.10MB)</p>
                                        </div>
                                    </FileUploader>
                                </div>

                                <p className='c-red s-12 trans-3 mb-14' style={{height:"12px"}}>{err_size} </p>
                                <div className='flex width-100 flex-end'>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => this.closeDocument(e)}>
                                        <p className='DM-Sans s-14 c-ff' >Save Documents</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={job_desc} height={'80%'} width="670" >
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Job Description</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => this.props.handlePopup("job_desc")} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow '>
                                <div className='flex column wd-webkit p-14 br-10 bg-ff bd-d4'>
                                    <div className='flex width-100 mb4'>
                                        <p style={{ marginRight: '4px' }} className='DM-Sans-Bold c-29 s-1624'>{workId.length > 0 ? workId[job_description_id].job_title : ""}</p>
                                        {(workId.length > 0 && workId[job_description_id].work_period_start !== null) && 
                                        <p className='ml4 c-36 s-1420 DM-Sans m-auto2-0'>
                                            ({workId.length > 0 ? this.dateFormate(workId[job_description_id].work_period_start) + " - " +  set_endDate(workId[job_description_id].work_period_start,workId[job_description_id].work_period_end) : ""})
                                        </p>}
                                    </div>
                                    <p className='c-29 DM-Sans mb6 s-1420 mb4'>{workId.length > 0 ? dataJobFunction.map((item2) => { if (item2.id === parseInt(workId[job_description_id].job_function_id)) { return (item2.job_function_name) } }) : ""}</p>
                                    <div className='flex mb4'>
                                        {industry_icon}
                                        <p className='flex DM-Sans s-1420 c-29 ml-10'>{workId.length > 0 ? dataJobLevel.map((item2) => { if (item2.id === parseInt(workId[job_description_id].job_level_id)) { return (item2.job_level_name) } }) : ""}</p>
                                    </div>
                                    <div className='flex mb-14'>
                                        {function_icon}
                                        <p className='flex DM-Sans s-1420 c-54 ml-10'>{workId.length > 0 ? workId[job_description_id].company_name : ""}</p>
                                    </div>
                                    <div className='flex width-100 mb-14 hg-0 bd-d4' />
                                    <p className='DM-Sans-Bold c-29 mb4 s-1220'>JOB DESCRIPTION</p>
                                    <p style={{ wordBreak: 'break-all' }} className='DM-Sans s-1420  c-29'  
                                    dangerouslySetInnerHTML={{ __html: workId.length > 0 ? workId[job_description_id].job_description : ""} }/>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={work_experience} height={'80%'} width={'90%'} >
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <div className='wd-24 hg-24 flex m-auto-00'>
                                            {work_exp}
                                        </div>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Work Experience</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {document.getElementById('work1').scrollIntoView(0,0)
                                        this.props.handlePopup("work_experience")}} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex column wd-webkit  br-10 bg-ff filter-210 mb-24' >

                                    <TableContainer className="m-hide" component={Paper} >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="DM-Sans-Medium" id='work1'>Job Title</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Job Function</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Job Level</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Company Name</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Industry</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Work Period Start</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Work Period End</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Job Description</TableCell>
                                                    <TableCell className="DM-Sans-Medium" align="">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {workId.map((item, i) => {
                                                    return (
                                                        <TableRow
                                                            key={i} style={{backgroundColor:i%2? "#FBFBFB":"#FFFFFF"}}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">{item.job_title}</TableCell>
                                                            <TableCell align="">{dataJobFunction.map((item2) => { if (item2.id === parseInt(item.job_function_id)) { return (item2.job_function_name) } })}</TableCell>
                                                            <TableCell align="">{dataJobLevel.map((item2) => { if (item2.id === parseInt(item.job_level_id)) { return (item2.job_level_name) } })}</TableCell>
                                                            <TableCell align="">{item.company_name}</TableCell>
                                                            <TableCell align="">{dataIndustri.map((item2) => { if (item2.id === parseInt(item.industry_id)) { return (item2.industry_name) } })}</TableCell>
                                                            <TableCell >{item.work_period_start !==null ? formatDate(item.work_period_start):"-"}</TableCell>
                                                            <TableCell align="">{set_endDate_tab(item.work_period_start,item.work_period_end)}</TableCell>
                                                            <TableCell style={{ cursor: 'pointer', color: '#3B6C98' }} onClick={(e) => {
                                                                this.props.updateJobDecId(i)
                                                                this.props.changePopup("work_experience", "job_desc")
                                                            }} align="">Read more</TableCell>
                                                            <TableCell>
                                                                <div className='flex wd-60 space-between'>
                                                                    <p className='s-14 c-29 DM-Sans align-left'><img src={editmodal} className="pointer" onClick={(e) => {
                                                                        this.updateWork(item);this.scrollToTop("add-w1")
                                                                        this.props.changePopup("work_experience", "add_work")
                                                                    }} />
                                                                    </p>
                                                                <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"work_experience")}}>{icon_delete} </div>
                                                                {/* <div className='flex pointer' onClick={(e)=>this.deleteWork(item.id)}>{icon_delete} </div> */}
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div className="m-show old-bg">
                                        {workId.map((item, i) => {
                                            return (
                                                <Accordion >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="acc-title">{item.job_title}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Job Function</p>
                                                            <p className="m-desc">{dataJobFunction.map((item2) => { if (item2.id === parseInt(item.job_function_id)) { return (item2.job_function_name) } })}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Job Level</p>
                                                            <p className="m-desc">{dataJobLevel.map((item2) => { if (item2.id === parseInt(item.job_level_id)) { return (item2.job_level_name) } })}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Company Name</p>
                                                            <p className="m-desc">{item.company_name}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Industry</p>
                                                            <p className="m-desc">{dataIndustri.map((item2) => { if (item2.id === parseInt(item.industry_id)) { return (item2.industry_name) } })}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Work Period Start</p>
                                                            <p className="m-desc">{item.work_period_start !== null? formatDate(item.work_period_start):"-"}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Work Period End</p>
                                                            <p className="m-desc">{set_endDate_tab(item.work_period_start,item.work_period_end)}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label wd-60">Action</p>
                                                            <div className='flex space-between wd-60 m-desc'>
                                                            <p className="m-desc"> <img src={editmodal} className="pointer" onClick={(e) => {
                                                                this.updateWork(item);this.scrollToTop("add-w1")
                                                                this.props.changePopup("work_experience", "add_work")
                                                            }} /></p>
                                                             <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"work_experience")}}>{icon_delete} </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="card-m mb-10">
                                                        <p className="m-label">Job Function:</p>
                                                        <p className="m-desc">{dataJobFunction.map((item2) => { if (item2.id === parseInt(item.job_function_id)) { return (item2.job_function_name) } })}</p>
                                                    </div> */}
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Job Description</p>
                                                            <p className="m-desc co" onClick={(e) => {
                                                                this.props.updateJobDecId(i)
                                                                this.props.changePopup("work_experience", "job_desc")
                                                            }}
                                                            >Read more</p>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                    
                                </div>
                                <div className='flex width-100 flex-end '>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => {this.props.changePopup("work_experience", "add_work");
                                    this.scrollToTop("add-w1");this.setState({work_id:0})}}>
                                        <p className='DM-Sans s-14 c-ff'>Add Work Experience</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={education_} height={'80%'} width={'80%'}>
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <div className='wd-24 hg-24 flex m-auto-00'>
                                            {education}
                                        </div>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Education</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {this.props.handlePopup("education_")
                                this.scrollToTop("ed1")}} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex column wd-webkit br-10 bg-ff filter-210 mb-24'>
                                    <div className='flex m-hide width-100 column'>
                                        <div className='flex p-14'>
                                            <div className='flex  space-between width-100' id='ed1'>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-214'>Education Level</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-246'>Institution Name</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd_100'>Degree</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-158'>Education Period Start</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-158'>Education Period End</p>
                                                <p className='s-14 c-29 DM-Sans-Medium align-left wd-60'>Action</p>
                                            </div>
                                        </div>
                                        {educationId.map((item, i) => {
                                            return (
                                                <div className='flex p-14 wd-webkit bt-ea' style={{ backgroundColor: i % 2 ? "#FBFBFB" : "" }} >
                                                    <div className='flex  space-between width-100'>
                                                        <p className='s-14 c-29 DM-Sans w-400 wd-214 align-left'>{listEdu.map(items => { if (item.education_level_id === items.id) { return (items.education_name) } })} </p>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd-246'>{item.institution_name} </p>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd_100'>{item.show_only}</p>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd-158'>{item.work_period_start!==null? formatDate(item.work_period_start):"-"}</p>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd-158'>{item.work_period_end!==null? formatDate(item.work_period_end):"-"}</p>
                                                        <div className='flex wd-60 space-between'>
                                                        <p className='s-14 c-29 DM-Sans align-left'><img src={editmodal} className="pointer" onClick={(e) => {
                                                            this.updateEdu(item)
                                                            this.props.changePopup("education_", "add_edu")
                                                        }} />
                                                        </p>
                                                        <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"education_")}}>{icon_delete} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="m-show">
                                        {educationId.map((item, i) => {
                                            return (
                                                <Accordion >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="acc-title">{listEdu.map(items => { if (item.education_level_id === items.id) { return (items.education_name) } })}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Institution Name</p>
                                                            <p className="m-desc">{item.institution_name}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Degree</p>
                                                            <p className="m-desc">{item.show_only}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Education Period Start</p>
                                                            <p className="m-desc">{formatDate(item.work_period_start)}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Education Period End</p>
                                                            <p className="m-desc">{formatDate(item.work_period_end)}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label wd-60">Action</p>
                                                            <div className='flex space-between wd-60 m-desc'>
                                                            <p className="m-desc"> <img src={editmodal} className="pointer" onClick={(e) => {
                                                                this.updateEdu(item)
                                                                this.props.changePopup("education_", "add_edu")
                                                            }} /></p>
                                                             <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"education_")}}>{icon_delete} </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>)
                                        })}
                                    </div>
                                </div>
                                <div className='flex width-100 flex-end '>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => {this.props.changePopup("education_", "add_edu")
                                this.cancelEdu(); this.setState({edu_id:0})
                                this.scrollToTop("ed1")}}>
                                        <p className='DM-Sans s-14 c-ff'>Add Education</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={certification_} height={'80%'} width={'80%'}>
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <div className='wd-24 hg-24 flex m-auto-00'>
                                            {certificate}
                                        </div>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Certification {"&"} Trainings</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {this.props.handlePopup("certification_")
                                this.scrollToTop("cer1")}} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex column wd-webkit br-10 bg-ff filter-210 mb-24'>
                                    <div className='flex  m-hide width-100 column'>
                                        <div className='flex   p-14'>
                                            <div className='flex  space-between width-100' id='cer1'>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-664'>Certification/Training Name</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-246'>Date taken</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-60'>Action</p>
                                            </div>
                                        </div>
                                        {certificationId.map((item, i) => {
                                            return (
                                                <div className='flex p-14 wd-webkit bt-ea' style={{ backgroundColor: i % 2 ? "#FBFBFB" : "" }} >
                                                    <div className='flex  space-between width-100'>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd-664'>{item.title} </p>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd-246'>{list_month[item.month-1].substring(0,3)}  {item.year}</p>
                                                        <div className='flex space-between wd-60'>
                                                            <p className="m-desc"> <img src={editmodal} className="pointer" onClick={(e) => {
                                                                this.updateCertificate(item)
                                                                this.props.changePopup("certification_", "add_certification")
                                                            }} /></p>
                                                             <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"certification_")}}>{icon_delete} </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="m-show">
                                        {certificationId.map((item, i) => {
                                            return (
                                                <Accordion >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="acc-title">{item.title}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Date taken</p>
                                                            <p className="m-desc">{list_month[item.month-1].substring(0,3)} {item.year}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label wd-60">Action</p>
                                                            <div className='flex space-between wd-60 m-desc'>
                                                            <p className="m-desc"> <img src={editmodal} className="pointer" onClick={(e) => {
                                                                this.updateCertificate(item)
                                                                this.props.changePopup("certification_", "add_certification")
                                                            }} /></p>
                                                             <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"certification_")}}>{icon_delete} </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>)
                                        })}
                                    </div>
                                </div>
                                <div className='flex width-100 flex-end '>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => {this.props.changePopup("certification_", "add_certification");this.setState({certificate_id:0})}}>
                                        <p className='DM-Sans s-14 c-ff'>Add Certification {"&"} Training</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </Modal>
                    <Modal visible={project_} height={'80%'} width={'80%'} >
                        <div className='flex column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <div className='wd-24 hg-24 flex m-auto-00'>
                                            {project}
                                        </div>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Project {"&"} Awards</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {this.props.handlePopup("project_")
                                this.scrollToTop("pro1")}} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex column wd-webkit br-10 bg-ff filter-210 mb-24'>
                                    <div className='flex  m-hide width-100 column'>
                                        <div className='flex   p-14'>
                                            <div className='flex  space-between width-100' id='pro1'>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-664'>Project {"&"} Awards Name</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-246'>Date taken</p>
                                                <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-60'>Action</p>
                                            </div>
                                        </div>
                                        {dataProject.map((item, i) => {
                                            return (
                                                <div className='flex p-14 wd-webkit bt-ea' style={{ backgroundColor: i % 2 ? "#FBFBFB" : "" }} >
                                                    <div className='flex  space-between width-100'>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd-664'>{item.name} </p>
                                                        <p className='s-14 c-29 DM-Sans w-400 align-left wd-246'>{item.month} {item.year}</p>
                                                        <div className='flex space-between wd-60'>
                                                            <p className="m-desc"> <img src={editmodal} className="pointer" onClick={(e) => {
                                                                this.updateProject(item)
                                                                this.props.changePopup("project_", "add_project")
                                                            }} /></p>
                                                             <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"project_")}}>{icon_delete} </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="m-show">
                                        {dataProject.map((item, i) => {
                                            return (
                                                <Accordion >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="acc-title">{item.name}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label">Date taken</p>
                                                            <p className="m-desc">{item.month} {item.year}</p>
                                                        </div>
                                                        <div className="card-m mb-10">
                                                            <p className="m-label wd-60">Action</p>
                                                            <div className='flex space-between wd-60 m-desc'>
                                                            <p className="m-desc"> <img src={editmodal} className="pointer" onClick={(e) => {
                                                                this.updateProject(item)
                                                                this.props.changePopup("project_", "add_project")
                                                            }} /></p>
                                                             <div className='flex pointer' onClick={(e)=>{this.confirmData(item.id,"project_")}}>{icon_delete} </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>)
                                        })}
                                    </div>
                                </div>
                                <div className='flex width-100 flex-end '>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => {this.props.changePopup("project_", "add_project")
                                this.scrollToTop("pro1"); this.setState({project_id:0})}}>
                                        <p className='DM-Sans s-14 c-ff'>Add Project {"&"} Awards</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={salary_} height={'80%'} width={'80%'} >
                        <div className='flex column bg-f9 br-10 benf_ f_'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <div className='wd-24 hg-24 flex m-auto-00'>
                                            {salary}
                                        </div>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Salary {"&"} Benefit</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {
                                        this.cancekSalary()
                                        this.props.handlePopup("salary_")
                                        this.scrollToTop("sal1")
                                    }} />
                                </div>
                            </div>
                            <div className='p-24 p-height  overflow'>
                                <div className='flex space-between flexbox_gap20 equ_part wrap wd-webkit mb-24'>
                                    <div className='flex column  mb-14' id="sal1">
                                        <p className='c-72 mb4 s-1420 DM-Sans-Medium'>Latest Monthly Salary</p>
                                        <MaskedInput className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 p-1424 DM-Sans' value={this.commaFormate(latestSalary)} onChange={(e) => this.handleState("latestSalary", e.target.value)} mask={currencyMask} />
                                        {/* <input type='text' className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 p-1424 DM-Sans' value={this.commaFormate(latestSalary)} onChange={(e) => this.handleState("latestSalary", e.target.value)} /> */}
                                        {latestSalaryError == true ? <span style={{ color: 'red' }}>Latest Monthly Salary is required </span> : null}
                                    </div>
                                    <div className='flex column mb-14'>
                                        <p className='DM-Sans-Medium c-72 mb4 s-1420'>Expected Monthly Salary</p>
                                        <MaskedInput className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 p-1424 DM-Sans' value={this.commaFormate(expectedSalary)} onChange={(e) => this.handleState("expectedSalary", e.target.value)} mask={currencyMask} />
                                        {/* <input type='text' className='width-100 bg-ff flex shd-br-10 hg-56 out-none bd-unset s-1420 p-1424 DM-Sans' value={this.commaFormate(expectedSalary)} onChange={(e) => this.handleState("expectedSalary", e.target.value)} /> */}
                                        {expectedSalaryError == true ? <span style={{ color: 'red' }}>Expected Monthly Salary is required </span> : null}
                                    </div>
                                </div>
                                <div className='flex column width-100 mb-24'>
                                    <p className='c-29 mb4 s-1624 DM-Sans-Medium mb4'>Latest Benefits</p>
                                    <p className='c-72 mb4 s-1420 DM-Sans mb4'>Suggested Benefits</p>
                                    <div className='flex width-100 wrap mb-14'>
                                        {listBenefit.benefitList.map(item => (
                                            <div className='flex bd-36-05 p-414 mr-14 br-14 pointer sugg-skill-tag' onClick={(e) => { this.addField(latestBenefit,{value: item.id, label: item.benefit_name },"latestBenefit") }}>
                                                <p className='s-1420 c-36'>{item.benefit_name} </p>
                                            </div>))}
                                    </div>
                                    {/* <div className='flex column width-100 bg-ff shd-br-10 modal-body mb-24'>
                                        <div className='p-14 wd-webkit flex column'>
                                        <h4>Test 1</h4>
                                            <Select options={setOption(listBenefit.benefitList,"id","benefit_name",rC(latestBenefit))} isMulti  value={latestBenefit} onInputChange={this.addOption}
                                                onChange={this.handleChangeLB} onKeyDown={(e)=> this.onKeyDown(e,latestBenefit,"latestBenefit",'input[id="react-select-2-input"]')} 
                                              />
                                            <button className='mt-80 br-5 out-none bg-36 c-ff hg-33 none moX' onClick={(e)=>this.onKeyDown2(e,latestBenefit)} > Add</button>
                                        </div>
                                    </div> */}
                                    <div className='flex column width-100 bg-ff shd-br-10 modal-body mb-24'>
                                    <div className='p-14 wd-webkit flex column'>
                                            <Select options={opt_benefit_sug} isMulti  value={latestBenefit} onInputChange={this.addOption}
                                                onChange={this.handleChangeLB} onKeyDown={(e)=> this.onKeyDown(e,latestBenefit,"latestBenefit",'input[id="react-select-2-input"]')} 
                                              />
                                            <button className='mt-80 br-5 out-none bg-36 c-ff hg-33 none moX' onClick={(e)=>this.onKeyDown2(e,latestBenefit)} > Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex column width-100'>
                                    <p className='c-29 mb4 s-1624 DM-Sans-Medium mb4'>Expected Benefits</p>
                                    <p className='c-72 mb4 s-1420 DM-Sans mb4'>Suggested Benefits</p>
                                    <div className='flex width-100 wrap mb-14'>
                                        {listBenefit.benefitList.map(item => (
                                            <div className='flex bd-36-05 p-414 mr-14 br-14 pointer sugg-skill-tag' onClick={(e) => { this.addField(expectedBenefit,{value: item.id, label: item.benefit_name },"expectedBenefit") }}>
                                                <p className='s-1420 c-36'>{item.benefit_name} </p>
                                            </div>))}
                                    </div>
                                    <div className='flex column width-100 bg-ff shd-br-10 modal-body mb-24'>
                                        <div className='p-14 wd-webkit flex column'>
                                            {/* <Select options={setOption(listBenefit.benefitList,"id","benefit_name",rC(expectedBenefit))} isMulti  value={expectedBenefit} onInputChange={this.addOptionEB}
                                                    onChange={this.handleChangeEB} onKeyDown={(e)=> this.onKeyDown(e,expectedBenefit,"expectedBenefit",'input[id="react-select-3-input"]')}/> */}
                                            <Select options={opt_benefit_ex} isMulti  value={expectedBenefit} onInputChange={this.addOptionEB}
                                                onChange={this.handleChangeEB} onKeyDown={(e)=> this.onKeyDown(e,expectedBenefit,"expectedBenefit",'input[id="react-select-3-input"]')}/>
                                                <button className='mt-80 br-5 out-none bg-36 c-ff hg-33 none moX' onClick={(e)=>this.onKeyDownExp(e,expectedBenefit)} > Add</button>
                                            {/* <ReactTags tags={tagsEB} suggestions={benefitSuggestions} delimiters={delimiters} handleDelete={this.handleDeleteEB}
                                                handleDrag={this.handleDragEB} handleAddition={this.handleAdditionEB} handleTagClick={this.handleTagClickEB}
                                                inputFieldPosition="bottom" autocomplete />
                                            <Select options={setOption(skill_list,"id","skill_name")} isMulti  value={this.state.selectedOption} onInputChange={this.addOption}
                                                onChange={this.handleChange} onKeyDown={this.onKeyDown}/> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex width-100 flex-end '>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => this.updateSalaryAndBenefit()}>
                                        <p className='DM-Sans s-14 c-ff'>Save Changes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={skills_} height={'80%'} width={'80%'}>
                        <div className='flex column bg-f9 br-10 filter-210'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <div className='wd-24 hg-24 flex m-auto-00'>
                                            {skill}
                                        </div>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Skills</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {
                                        this.cancelSkill()
                                        this.props.handlePopup("skills_")
                                        this.scrollToTop("skill1")
                                    }} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <p className='c-29 mb4 s-1624 DM-Sans-Medium mb4' id='skill1'>Skills</p>
                                <p className='c-72 mb4 s-1420 DM-Sans mb4'>Suggested Skills</p>
                                <div className='flex width-100 wrap mb-14 dekstop sug_'>
                                    {sug_skills.map(item => (
                                        <div className='flex bd-36-05 p-414 sugg-skill-tag ml-14 mr-14 br-14 pointer' onClick={(e) => { this.addField(selectedOption,{value: item.id, label: item.skill_name },"selectedOption") }}>
                                            <p className='s-1420 c-36'>{item.skill_name} </p>
                                        </div>))}
                                </div>
                                <div className='flex width-100 wrap mb-14 mobile sug_'>
                                    {sug_skills_mobile.map(item => (
                                        <div className='flex bd-36-05 p-414 sugg-skill-tag ml-14 mr-14 br-14 pointer' onClick={(e) => { this.addField(selectedOption,{value: item.id, label: item.skill_name },"selectedOption") }}>
                                            <p className='s-1420 c-36'>{item.skill_name} </p>
                                        </div>))}
                                </div>
                                <div className='flex column width-100 bg-ff shd-br-10 modal-body mb-24'>
                                    <div className='p-14 wd-webkit flex column'>
                                    <Select className='pointer' options={setOption(skill_list,"id","skill_name",rC(selectedOption))} isMulti  value={this.state.selectedOption} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                {/* <div className='flex column width-100 bg-ff shd-br-10 modal-body mb-24'>
                                    <div className='p-14 wd-webkit flex column'>
                                        <ReactTags tags={tags}
                                            suggestions={skilSuggestions}
                                            delimiters={delimiters}
                                            handleDelete={this.handleDelete}
                                            handleAddition={this.handleAddition}
                                            handleDrag={this.handleDrag}
                                            handleTagClick={this.handleTagClick}
                                            onTagUpdate={this.onTagUpdate}
                                            inputFieldPosition="bottom"
                                            autocomplete
                                        />
                                    </div>
                                </div> */}
                                <div className='flex width-100 flex-end '>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => this.updateSkill()}>
                                        <p className='DM-Sans s-14 c-ff'>Save Changes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={interest_} height={'80%'} width={'80%'}>
                        <div className='flex  column bg-f9 br-10'>
                            <div className='flex width-100 bg-ff filter-15 br-10-0'>
                                <div className=' m-2432 flex wd-webkit space-between'>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto2-0 op-0" />
                                    <div className='flex'>
                                        <div className='wd-24 hg-24 flex m-auto-00'>
                                            {industry}
                                        </div>
                                        <p className='DM-Sans s-24 c-36 ml-14'>Interest</p>
                                    </div>
                                    <img src={closemodal} className="flex wd-18 hg-18 m-auto0 pointer" onClick={(e) => {this.props.handlePopup("interest_")
                                this.scrollToTop("interest1")}} />
                                </div>
                            </div>
                            <div className='p-24 p-height overflow'>
                                <div className='flex width-100 mb-14' id='interest1'>
                                    <div className='flex hg-18 wd-18 mr-14'>{industry}</div>
                                    <p className='c-29 mb4 s-1624 DM-Sans-Medium mb4'>Industry Interest</p>
                                </div>
                                <div className="buttons_jobFunction width-100 flex wrap mb-48" >
                                    {dataIndustri.map((item, i) => (
                                        <button key={i} className="job_item DM-Sans s-1420" onClick={(e) => { this.changeIndustry(item.id) }}
                                            style={{
                                                color: (((this.state.industry_id[0] === item.id) || (this.state.industry_id[1] === item.id) || (this.state.industry_id[2] === item.id) || (this.state.industry_id[3] === item.id) || (this.state.industry_id[4] === item.id)) ? "#3B6C98" : "" ||
                                                    this.state.industry_id.length >= 5 && ((this.state.industry_id[0] !== item.id) && (this.state.industry_id[1] !== item.id) && (this.state.industry_id[2] !== item.id) && (this.state.industry_id[3] !== item.id) && (this.state.industry_id[4] !== item.id)) ? "#BDBDBD" : ""),
                                                borderColor: (((this.state.industry_id[0] === item.id) || (this.state.industry_id[1] === item.id) || (this.state.industry_id[2] === item.id) || (this.state.industry_id[3] === item.id) || (this.state.industry_id[4] === item.id)) ? "#3B6C98" : "" || this.state.industry_id.length >= 5 && ((this.state.industry_id[0] !== item.id) && (this.state.industry_id[1] !== item.id) && (this.state.industry_id[2] !== item.id) && (this.state.industry_id[3] !== item.id) && (this.state.industry_id[4] !== item.id)) ? "white" : ""),
                                                backgroundColor: (((this.state.industry_id[0] === item.id) || (this.state.industry_id[1] === item.id) || (this.state.industry_id[2] === item.id) || (this.state.industry_id[3] === item.id) || (this.state.industry_id[4] === item.id)) ? "#ECF1F5" : "" || this.state.industry_id.length >= 5 && ((this.state.industry_id[0] !== item.id) && (this.state.industry_id[1] !== item.id) && (this.state.industry_id[2] !== item.id) && (this.state.industry_id[3] !== item.id) && (this.state.industry_id[4] !== item.id)) ? "white" : "")
                                            }}>
                                            <p>{item.industry_name} </p>
                                        </button>
                                    ))}
                                </div>
                                <div className='flex width-100 mb-14'>
                                    <div className='flex hg-18 wd-18 mr-14'>{work_exp}</div>
                                    <p className='c-29 mb4 s-1624 DM-Sans-Medium mb4'>Job Function Interest</p>
                                </div>
                                <div className="buttons_jobFunction width-100 flex wrap mb-48" >
                                    {dataJobFunction.map((item, i) => (
                                        <button key={i} className="job_item DM-Sans s-1420" onClick={(e) => { this.changeJob(item.id) }} value={item.id}
                                            style={{
                                                color: (((this.state.job_function_id[0] === item.id) || (this.state.job_function_id[1] === item.id) || (this.state.job_function_id[2] === item.id) || (this.state.job_function_id[3] === item.id) || (this.state.job_function_id[4] === item.id)) ? "#3B6C98" : "" ||
                                                    this.state.job_function_id.length >= 5 && ((this.state.job_function_id[0] !== item.id) && (this.state.job_function_id[1] !== item.id) && (this.state.job_function_id[2] !== item.id) && (this.state.job_function_id[3] !== item.id) && (this.state.job_function_id[4] !== item.id)) ? "#BDBDBD" : ""),
                                                borderColor: (((this.state.job_function_id[0] === item.id) || (this.state.job_function_id[1] === item.id) || (this.state.job_function_id[2] === item.id) || (this.state.job_function_id[3] === item.id) || (this.state.job_function_id[4] === item.id)) ? "#3B6C98" : "" ||
                                                    this.state.job_function_id.length >= 5 && ((this.state.job_function_id[0] !== item.id) && (this.state.job_function_id[1] !== item.id) && (this.state.job_function_id[2] !== item.id) && (this.state.job_function_id[3] !== item.id) && (this.state.job_function_id[4] !== item.id)) ? "white" : ""),
                                                backgroundColor: (((this.state.job_function_id[0] === item.id) || (this.state.job_function_id[1] === item.id) || (this.state.job_function_id[2] === item.id) || (this.state.job_function_id[3] === item.id) || (this.state.job_function_id[4] === item.id)) ? "#ECF1F5" : "" ||
                                                    this.state.job_function_id.length >= 5 && ((this.state.job_function_id[0] !== item.id) && (this.state.job_function_id[1] !== item.id) && (this.state.job_function_id[2] !== item.id) && (this.state.job_function_id[3] !== item.id) && (this.state.job_function_id[4] !== item.id)) ? "white" : "")
                                            }}>
                                            <img effect="blur" loading="lazy" alt="" src={`${API} /File/Job_Function / ${item.icon} `} style={{ opacity: this.state.job_function_id.length >= 5 && ((this.state.job_function_id[0] !== item.id) && (this.state.job_function_id[1] !== item.id) && (this.state.job_function_id[2] !== item.id) && (this.state.job_function_id[3] !== item.id) && (this.state.job_function_id[4] !== item.id)) ? "0.4" : "", filter: (((this.state.job_function_id[0] === item.id) || (this.state.job_function_id[1] === item.id) || (this.state.job_function_id[2] === item.id) || (this.state.job_function_id[3] === item.id) || (this.state.job_function_id[4] === item.id)) ? "invert(37%) sepia(91%) saturate(318%) hue-rotate(167deg) brightness(87%) contrast(90%)" : "") }} />
                                            <p className='auto'>{item.job_function_name} </p>
                                        </button>
                                    ))}
                                </div>
                                <div className='flex width-100 flex-end '>
                                    <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content' onClick={(e) => this.closeInterest(e)}>
                                        <p className='DM-Sans s-14 c-ff'>Save Changes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={promoter_score} height={'80%'} width={'80%'} >
                        <div className='none  column bg-linear-ec br-10'>
                            <div className='flex column p-16 wd-webkit'>
                                <div className='flex width-100 flex-end'>
                                    <img src={closemodal} className="flex wd-12 hg-12 pointer" onClick={(e) => this.setState({ promoter_score: !promoter_score })} />
                                </div>
                                <div className='flex width-100 center mb-14'>
                                    <p className='DM-Sans-Bold c-29 auto s-1824'>How willing are you to recommend Karya Talents to others?</p>
                                </div>
                                <div className='flex center auto bd-d4 mb-10 br-10'>
                                    <div className='flex p-1024 bg-ff bd-right-d4 br-100010'>
                                        <p className='DM-Sans-Bold c-29 s-1824'>1</p>
                                    </div>
                                    {score.map((item) => (
                                        <div className='flex p-1024 bg-ff bd-right-d4'>
                                            <p className='DM-Sans-Bold c-29 s-1824'>{item} </p>
                                        </div>))}
                                    <div className='flex p-1024 bg-ff br-010100'>
                                        <p className='DM-Sans-Bold c-29 s-1824'>10</p>
                                    </div>
                                </div>
                                <div className='flex wd-671 space-between '>
                                    <p className='DM-Sans-Medium c-29 s-1418'>Very unlikely</p>
                                    <p className='DM-Sans-Medium c-29 s-1418'>Very Likely</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex wd-718 column bg-linear-ec br-10 hg-158'>
                            <p className='DM-Sans-Bold c-29 auto s-24'>Thank you!</p>
                        </div>
                    </Modal>
                    <Modal visible={feedback} height={'80%'} width={'80%'} >
                        <div className='none column bg-ff br-10'>
                            <div className='flex column p-16 wd-webkit mb-24'>
                                <div className='flex width-100 flex-end'>
                                    <img src={closemodal} className="flex wd-12 hg-12 pointer" onClick={(e) => this.props.handlePopup("feedback")} />
                                </div>
                                <div className='flex width-100 center mb-24'>
                                    <p className='DM-Sans-Bold c-29 auto s-1824'>Share your feedback</p>
                                </div>
                                <div className='flex wd-690 auto  bd-d4 mb-10 br-10 hg-128'>
                                    <p className='DM-Sans s-14 p-514 wd-webkit'>Mi placerat vel tincidunt tortor volutpat nullam bibendum elit. Accumsan mattis id nulla odio interdum. Posuere ultrices viverra morbi augue egestas tellus mattis purus egestas. Amet vitae ac ornare purus, interdum cursus in praesent. Quis pharetra aliquam convallis venenatis, dictum viverra bibendum. </p>
                                </div>
                            </div>
                            <div className='flex width-100 center mb-24'>
                                <div className='bd-36 bg-36 br-20 p-1024 pointer wd-fit-content'>
                                    <p className='DM-Sans s-14 c-ff'>Send Feedback</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex wd-718 hg-288 column bg-ff br-10'>
                            <p className='DM-Sans-Bold c-29 auto s-24'>Thank you for the feedback</p>
                        </div>
                    </Modal>
                    <div className={deleteItem?'popup-delete':"flex"}>
                    <Modal visible={deleteItem} width="422" height="192" >
                        <div className='flex column bg-ff br-10 wd-422 center fixed z-i99'>
                        <p className='DM-Sans s-24 c-36 mb-14 mt-24'>Delete Confirmation</p>
                        <p className='DM-Sans s-1624 c-29 mb-32'>This action cannot be undone, are you sure?</p>
                        <div className='flex space-between wd-288'>
                            <div className='flex wd-128 bg-ff br-25 bd-36  p-1024 pointer center ' onClick={(e) => this.setState({deleteItem:false})}>
                                <p className='c-36 s-1420 DM-Sans m-auto'>Cancel</p>
                            </div>
                            <div className='flex wd-128 bg-e1 br-25 p-1024 pointer flex' onClick={(e) => {this.deleteField(e);this.setState({deleteItem:false})}}>
                                <p className='c-ff s-1420 DM-Sans m-auto'>Delete</p>
                            </div>
                        </div>
                        </div>
                    </Modal>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        onlyCity1: state.onlyCity,
        listExp: state.getExpectedListRed,
        listLast: state.getLastListRed,
        listBenefit: state.getBenefitListRed
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        postCertificate1: (event) => {
            dispatch(postCertification(event));
        },
        updateCertificate1: (id) => {
            dispatch(putCertification(id));
          },
        deleteCertification1: (id) => {
        dispatch(deleteCertification(id));
        },
        updateUserProject1: (event) => {
            dispatch(updateUserProject(event));
        },
        postedu1: (event) => {
            dispatch(postEducation(event));
        },
        putEducation1: (event) => {
            dispatch(putEducation(event));
        },
        deleteEdu1: (id) => {
            dispatch(deleteEducation(id));
          },
        jobFunction11: (user_id) => {
            dispatch(putProfileId(user_id))
        },
        getOnlyCity: () => {
            dispatch(getOnlyCity())
        },
        getExpList: () => {
            dispatch(getExpList())
        },
        getLastList: () => {
            dispatch(getExpList())
        },
        deleteExp1: (id) => {
            dispatch(deleteExp(id));
        },
        getLastList: () => {
            dispatch(getLastList())
        },
        getBenefitList: () => {
            dispatch(getBenefitList())
        },
        postLast1: (event) => {
            dispatch(postLast(event));
        },
        postExp1: (event) => {
            dispatch(postExp(event));
        },
        postSkill: (user) => {
            dispatch(postSkill(user));
        },
        deleteLast1: (id) => {
            dispatch(deleteLast(id));
        },
        work1: (event) => {
            dispatch(postWork(event));
        },
        workupdate1: (id) => {
            dispatch(putWork(id));
          },
        deleteWork1: (id_work) => {
        dispatch(deleteWork(id_work));
        },
        Industry11: (user_id) => {
            dispatch(putProfileId(user_id))
        },
        work1: (event) => {
            dispatch(postWork(event));
        },
        deleteDocument: (id) => {
            dispatch(deleteDocument(id))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);