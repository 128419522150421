import {GET_WORK_EXP,GET_WORK_STA, GET_WORK_EXP_ID,GET_WORK_PRE, POST_WORK_EXP, DELETE_WORK_EXP, PUT_WORK_EXP, GET_INDUSTRI, GET_JOB_LEVEL, GET_JOB_FUNCTION} from "../config/constants";
import axios from "axios";
import {API} from '../_redux/helper'

const token=  sessionStorage.getItem('token')
export const getWork  = (id_work) => {
    return {
      type: GET_WORK_EXP,
      payload: axios({
        method: "GET",
        url: `${API}/api/user_work_experience/${id_work}`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };
  
  export const getWorkId = (user_id) => {
    return {
      type: GET_WORK_EXP_ID,
      payload: axios({
        method: "GET",
        url: `${API}/api/user_work_experience?user_id=${user_id}`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

  export const postWork = (user) => {
    return {
      type: POST_WORK_EXP,
      payload: axios({
        method: "POST",
        url: `${API}/api/user_work_experience`,
        data: user,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

  export const putWork = (id) => {
    return {
      type: PUT_WORK_EXP,
      payload: axios({
        method: "PUT",
        url: `${API}/api/user_work_experience/${id.id}`,
        data: id,
        headers: {
          Authorization: ` ${token}`,
        }
      })
    };
  };

  export const deleteWork = (id_work) => {
    return {
      type: DELETE_WORK_EXP,
      payload: axios({
        method: "DELETE",
        url: `${API}/api/user_work_experience/${id_work}`,
        data: id_work,
        headers: {
          Authorization: ` ${token}`,
        }
      })
    };
  };
  export const getIndustri = (user_id) => {
    return {
      type: GET_INDUSTRI,
      payload: axios({
        method: "GET",
        url: `${API}/api/m_industry`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };
  export const getJobFunction = (user_id) => {
    return {
      type: GET_JOB_FUNCTION,
      payload: axios({
        method: "GET",
        url: `${API}/api/m_job_function`,
      })
    };
  };
  export const getJobLevel = (user_id) => {
    var token=  sessionStorage.getItem('token')
    return {
      type: GET_JOB_LEVEL,
      payload: axios({
        method: "GET",
        url:`${API}/api/m_job_level`,
      })
    };
  };

  export const getWorkPre  = () => {
    return {
      type: GET_WORK_PRE,
      payload: axios({
        method: "GET",
        url: `${API}/api/work_preference`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };
  


  export const getWorkSta  = () => {
    return {
      type: GET_WORK_STA,
      payload: axios({
        method: "GET",
        url: `${API}/api/work_status`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

