import React, { Component } from 'react'
import { connect } from "react-redux";
import { getDetail, getTraining , getJobExclude } from "../_actions/jobs";
import { withRouter } from 'react-router-dom';
import Loading from '../components/Loading'
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

class JobRecommendation extends Component {
    constructor(){
        super()
        this.state= {
          user_id:"",
          job_id:""
        }
    }

    componentDidMount() {
        var jwt = require("jsonwebtoken");
        var token1=  sessionStorage.getItem('token')
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        this.setState({user_id:user_id})
        this.props.getTraining(user_id);
        this.props.getDetail(this.props.job_id);
        this.props.getJobExclude(this.props.job_id,user_id);
        window.scrollTo(0, 0)
        ReactPixel.track(window.location.pathname);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    



    render() {
        const { data, isLoading, error } = this.props.job1;
        const { dataTraining } = this.props.training1;
        const { dataJobExclude } = this.props.jobExclude1;
        const name =
      this.props.job1.data !== null
        ? this.props.job1.data.name
        : "Undefined";

        if (error) {
          return (
            <div className="message-question-load">
              <h3>Ups... Mohon, refresh halaman ini!</h3>
            </div>
          );
        }
    
        if (isLoading) {
          return (
            <React.Fragment>
              <Loading />
             </React.Fragment>
          );
        }

        return (
          <div>
            <h1 style={{ color: "#E74267" }}>{name}</h1>
            
              {data.map((item, i) => (
                <div key={i}>
                  <div
                    detail_desc={item.detail_desc}
                   
                  />
                  <h1>{item.detail_desc}</h1>
                  <h1>{item.education_level}</h1>
                  <p>{item.kemampuan.split(',')} </p>
                  <p>{item.kemampuan.split(',')[0]} </p>
                  </div>
              ))}
           
          


                  {dataTraining.map((item, i) => {
                  return (
                  <div key={i} >
                    <h2 style={{color:"green"}} >{item.title} </h2>  
                    <p style={{color:"red"}}>{item.city_name} </p>                                  
                    </div>
                   );
                   })}
                  

                  {dataJobExclude.map((item, i) => {
                  return (
                  <div key={i} >
                    <h2 style={{color:"blue"}} >{item.original_job_title} </h2>  
                    <p style={{color:"SlateBlue"}}>{item.preferred_job_title} </p>                                  
                  </div>
                  );
                  })}
               
          </div>
        );
      
    
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      job_id: ownProps.match.params.job_id,
      job1: state.jobDetail,
      training1: state.training,
      jobExclude1: state.jobExclude
      /*untuk put*/
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getDetail: job_id => {
        dispatch(getDetail(job_id));
      },
      getTraining: user_id => {
        dispatch(getTraining(user_id));
      },
      getJobExclude: (job_id, user_id) => {
        dispatch(getJobExclude(job_id, user_id));
      },
    };
  };
  
  export default withRouter (connect(mapStateToProps, mapDispatchToProps)(JobRecommendation));
