import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import vip_icon from '../images/vip_cover_icon.svg';
import vip_bg from '../images/vip_cover_bg.png';
import logo from '../images/KaryaTalents_logo.png';
import personality_icon from '../images/vip_personality_icon.svg';
import values_icon from '../images/vip_values_icon.svg';
import interest_icon from '../images/vip_interest_icon.svg';
import work_personality from '../images/dg_1.png';
import work_interest from '../images/dg_2.png';
import work_values from '../images/dg_3.png';
import work_err from "../images/works/2.jpg"
import learn from '../images/vip_learn.svg';
import prepare from '../images/vip_prepare.svg';
import align from '../images/vip_align.svg';
import discover from '../images/vip_discover.svg';
import popular from '../images/vip_popular.svg';
import { FormatListBulletedOutlined } from '@material-ui/icons';
import v1 from '../images/vip_1.svg';
import v2 from '../images/vip_2.svg';
import v3 from '../images/vip_3.svg';
import v4 from '../images/vip_4.svg';
import ScrollToTop from '../components/ScrollToTop';
import PageTemplateFooterVip from '../components/_PageTemplateFooterVip'
import Loading from "../components/Loading";
import crown_logo from '../images/crown_logo.png';
import wavy_left from '../images/wavy_left.svg';
import wavy_right from '../images/wavy_right.svg';
import ideal from '../images/ideal_env-icon.svg';
import area from '../images/report_area.svg';
import dev from '../images/report_dev.svg';
import icon_download_white from '../images/icon_download_white.svg';
import arrLeft from '../images/report_arrLeft.svg';
import arrRight from '../images/report_arrRight.svg';
import issue from '../images/report_issue.svg';
import report_admin from '../images/report_admin.svg';
import finance from '../images/report_finance.svg';
import green from '../images/report_check_green.svg';
import dataReport from '../components/DataReport.json';
import {
  report,
  label,
  date_report,
  dataDimensionOne,
  dataDimensionTwo,
  dataDimensionThree,
  dataDimensionFour,
  nconscientious,
  ninteracting,
  nsupportive,
  ndirecting,
  def_directing,
  def_conscientious,
  def_interacting,
  def_supportive,status_data,get_data
} from '../components/ParamsReport';
import { getResult, getAllResult, getReportValues, getReportLimit } from '../_actions/quest';
import { getJob2, getJob } from '../_actions/jobs';
import { popularJob1, popularJob2, popularJob3, getJobByInterest } from '../_actions/jobs';
import { Bar } from '@reactchartjs/react-chart.js';
import { Radar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { GAevent } from '../components/TrackingEvent';
import { PDFExport} from '@progress/kendo-react-pdf';
import { API,user_dummy } from '../_redux/helper';
import axios from 'axios';
import { getProfileId, getProgress } from '../_actions/user';
import { connect } from 'react-redux';
import Modal from "react-awesome-modal";
import Rating from '@material-ui/lab/Rating';
import dlc_close from "../images/dlc_close.svg"
import {day,month,setDatePID, setDataRadar, checkFont} from "../_redux/function"
var $ = require('jquery');



class VIP_Cover extends Component {
  constructor() {
    super();
    this.state = {
      user_id: '',
      width: '',
      visible: true,
      visible2: true,
      link: JSON.parse(sessionStorage.getItem('sidebar')),
      var_report: sessionStorage.getItem('report'),
      var_confirm: JSON.parse(sessionStorage.getItem('kuesionerconfirmed')),
      visible: true,
      country_id: '',
      city_id: '',
      job_level_id: '',
      industry_id: '',
      job_function_id: '',
      job_title: '',
      job_type: '',
      salary_from: '',
      salary_to: '',
      lastUpdate: '',
      lastInt: '',
      height: '',
      value5: {
        min: 0,
        max: 100000000,
      },
      jobInterest: [],
      isDownloadPdf: false,
      popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
      popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
      popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
      popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
      popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
      ratting:0,
      desc_ratting:"",
      comment:"",
      rating:"",
      show_modal: "",
      redirectLink: "",
      urlPopup: "",
      dataPopup: {},
      keyPopup: "",
      dataLink1:[],
    };
  }

  async componentDidMount() {
    window.scrollTo(0,0)
    window.history.scrollRestoration = "manual"
    var jwt = require('jsonwebtoken');
    var token1 = sessionStorage.getItem('token');
    var decode1 = jwt.decode(token1);
    let user_id  
    if (this.props.match.params.id===undefined){
      user_id = user_dummy
    }else{
      user_id = this.props.match.params.id
    }
    this.props.getResult(user_id);
    this.props.getAllResult(user_id);
    this.props.getReportValues(user_id);
    this.props.getReportLimit(user_id);
    this.props.getJob2(user_id);
    this.props.getJob(user_id);
    this.setState({ user_id: user_id });
    this.setState({ width: window.window.outerWidth });
    this.props.getProfileId(user_id);
    this.props.getProgress(user_id);
    const res1 = await axios.get(`${API}/api/getresult_work_assessment_lastupdate/${user_id}`).then((res) => {
      this.setState({ lastUpdate: res.data.map((item) => item.last_update) });
    });

    const res2 = await axios.get(`${API}/api/getresultquestion_lastupdate/${user_id}`).then((res) => {
      this.setState({ lastInt: res.data.map((item) => item.last_update) });
    });
    const getDataLink = await axios
      .get(`${API}/api/getresultquestion2/${user_id}?limit=3`)
      .then((res) => {
        this.setState({ dataLink1: res.data });
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { profile } = this.props.profile1;
    const { data } = this.props.result1;
      let dataInterest = []
      if (data.length===0){
        dataInterest = this.state.dataLink1
      }else{
        dataInterest = data
      }

    if (prevProps.result1.data !== this.props.result1.data) {
      dataInterest.map((item, i) => {
        this.props.getJobByInterest(item.id, item.interest_name);
        /* eslint eqeqeq: 0 */
      });
    }

    if (prevState.dataLink1 !== this.state.dataLink1) {
      dataInterest.map((item, i) => {
        this.props.getJobByInterest(item.id, item.interest_name);
        /* eslint eqeqeq: 0 */
      });
    }

    setTimeout(() => {
      if (prevState.rating !== profile.ratting_vip){
        if ((profile.ratting_vip===null || profile.ratting_vip==="") && profile.status_ratting_vip == "Active"){
          sessionStorage.setItem("rating-vip",true)
        }else{
          sessionStorage.setItem("rating-vip",false)
        }
      }
    }, 1000);
    
  }

  exportPDFWithComponent = (e) => {
    this.setState({isDownloadPdf: true });
    this.pdfExportComponent.save(() => {
    this.setState({ isDownloadPdf: false });
    });
    this.setState({ visible: false });
    GAevent('VIP Report', 'Download PDF');
  };

  back = () => {
    if (JSON.parse(sessionStorage.getItem("rating-vip"))==true){
      window.location.href="#"
      this.setState({show_modal:true})
   }else{
    this.props.history.goBack();
    sessionStorage.removeItem("rating-vip")
   }
    
  };

   // POPUP RATING START 
   changeRatting=(e)=>{
    this.setState({ratting:e.target.value})
    let val
    if (e.target.value==1){
      val = "Very Bad"
    }else if (e.target.value==2){
      val = "Bad"
    }else if (e.target.value==3){
      val = "Need Improvement"
    }else if (e.target.value==4){
      val = "Good"
    }else{
      val = "Very Good"
    }
    this.setState({desc_ratting:val})
  }

  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }
 

 
    onChangeRedirect = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "", comment_interest: ""}, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting_vip: "", comment_vip: "" }]
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        show_modal: "true",
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      
    } else {
      window.location.href = '/work_assessment'
    }
  }

  submitRatingAll = () => {
    this.state.dataPopup[Object.keys(this.state.dataPopup)[0]] = this.state.ratting
    this.state.dataPopup[Object.keys(this.state.dataPopup)[1]] = this.state.comment

    axios.post(this.state.urlPopup, this.state.dataPopup)
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
          window.location.href="/Work_Assessment"
      }).catch((err)=>{
          console.log(err,"error");
      })
  }

  _onKeyDown=(e)=>{
    if (e.key === 'Enter') {
     this.submitRatingAll()
    }
  }

  closeRatingAll = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "-", comment_interest: ""}, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting_vip: "-", comment_vip: "" }]
    let data_rating = []
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        show_modal: "true",
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      data_rating.push(arr[checkIndex])
    }  

    axios.post(this.state.urlPopup,data_rating[0])
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
          window.location.href="/Work_Assessment"
      }).catch((err)=>{
          console.log(err,"error");
      })

  }


  render() {
    const { profile } = this.props.profile1;
    const { data, isLoading, error } = this.props.result1;
    const { progress } = this.props.progress1;
    const { allRes } = this.props.result2;
    const { dataReportValues } = this.props.dataReportValues1;
    const { dataLimitValues } = this.props.dataLimitValues1;
    const { dataJobByInterest } = this.props.jobInterest;
    const {isDownloadPdf, visible, dataLink1} = this.state
    const dataLimit = [];
    dataLimitValues.data != undefined && dataLimitValues.data.length > 0
      ? dataLimitValues.data.map((item) => dataLimit.push(item))
      : console.log('failed');

    const date_PID = setDatePID(date_report)
    let dataInterest = []
    let dataResultInterest =[]
    if (data.length===0){
      dataInterest = dataLink1
    }else{
      dataInterest = data
    }

    if (allRes.length===0){
      dataResultInterest = progress
    }else{
      dataResultInterest = allRes
    }

    const nama = '' + profile.name;
    const nick_name = nama.split(' ')[0];
    const nick_name2 = nick_name.charAt(0).toUpperCase() + nick_name.slice(1);

    let regText = /__first_name__/gi;
    let regProfile = /__profile__/gi;
    var dataURI = 'data:text/plain;base64,SGVsbG8gV29ybGQh';

    let text = `${report.suggestionsForManagersCopy}`;
    let suggestionsForManagers;
    if (text.indexOf('__first_name__') != -1) {
      suggestionsForManagers = text.replace(regText, nick_name2);
    } else {
      suggestionsForManagers = text.replace(regProfile, label);
    }

    let lm1;
    let lm2;
    let lm3;
    let desc1;
    let desc2;
    let desc3;

    dataLimit.map((item, i) => {
      if (i == 0) {
        lm1 = item.values.replace(/\s.*/, '');
        desc1 = item.aligning_with_organization;
      } else if (i == 1) {
        lm2 = item.values.replace(/\s.*/, '');
        desc2 = item.aligning_with_organization;
      } else { 
        lm3 = item.values.replace(/\s.*/, '');
        desc3 = item.aligning_with_organization;
      }
    });



    // DATA FOR CHART
    var options = {
      plugins: {
        datalabels: {
          display: false,
        },
      },

      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 20,
              max: 80,
              borderColor: green,
              border: 'red',
            },
            gridLines: {
              display: true,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: { display: false },
            gridLines: {
              display: true,
              drawBorder: true,
            },
          },
        ],
      },
    };

    const dataG = {
      labels: [' ', ' ', '', ' '],
      datasetIndex: ['0', '1', '2', '3', '4', '5', '6'],
      datasets: [
        {
          type: 'line',
          label: `${label}`,
          borderColor: '#828282',
          borderWidth: 2,
          fill: false,
          data: [def_directing, def_interacting, def_supportive, def_conscientious],
          beginAtZero: true,
          display: false,
        },
        {
          type: 'line',
          label: `${nick_name2}`,
          borderColor: '#3B6C98',
          borderWidth: 2,
          fill: false,
          data: [ndirecting, ninteracting, nsupportive, nconscientious],
        },
      ],
    };

    //   DATA VALUES PROFILER
    let name;
    let listOf_persen_score = []
    let listOf_label = []
    let nilai = listOf_persen_score;
    
    let list_reportValues = []
    let list_dataLimit = []
    let index_max = []

    dataLimit.map((item)=>{
        list_dataLimit.push(item.values)
  })

    dataReportValues.data != undefined && dataReportValues.data.length > 0
      ? dataReportValues.data.map((item, i) => {
          if (i == 0) {
            name = item.name;
          } 
          listOf_persen_score.push(item.persen_score * 1)
          list_reportValues.push(item.values)
          listOf_label.push(item.values.toUpperCase())
        })
      : console.log('failed');

      list_dataLimit.map((item)=>(
        index_max.push(list_reportValues.indexOf(item))
    ))


    let setLabel = listOf_persen_score
    
    // DATA FOR RADAR DIAGRAM
    function checkValue(ar) {
      let arr = [];
      if (ar.length <= 3) return ar;
      let max = [
        { value: ar[0], index: 0 },
        { value: ar[1], index: 1 },
        { value: ar[2], index: 2 },
      ];
     
      ar.map((x, index) => {
        let color = 'grey';
        index_max.map((y) => {
          if (index == y) {
            color = '#3B6C98';
          }
        });
        arr.push(color);
      });

      return arr;
    }
  

    const optionsRadar = {
      responsive: true,
      tooltips: false,
      plugins: {
        datalabels: {
          display: function (context) {
            if (nilai.length <= 3) return nilai;
           
            let index = index_max;
            // max.map((x) => index.push(x.index));
            let check = index.indexOf(context.dataIndex) != -1 ? true : false;

            return check;
          },
          opacity: 1,
          anchor: 'end',
          align: 'end',
          color: 'white',
          borderColor: '#3B6C98',
          borderWidth: 1,
          borderRadius: 5,
          font: {
            weight: 'bold',
            size: 10,
            lineHeight: 1 /* align v center */,
          },
          padding: {
            top: 5,
          },
          /* hover styling */
          backgroundColor: function (context) {
            return context.hovered ? context.dataset.borderColor : 'white';
          },
          color: function (context) {
            return 'grey';
          },
          formatter: function (value, context) {
            return Math.round(value);
          },
          listeners: {
            enter: function (context) {
              context.hovered = true;
              return true;
            },
            leave: function (context) {
              context.hovered = false;
              return true;
            },
          },
        },
      },
      legend: {
        display: false,
      },
      scale: {
        pointLabels: {
          fontStyle: 'bold',
          fontSize: checkFont(),
          fontColor: checkValue(nilai),
          callback: function (pointLabel, index, labels) {
            return pointLabel;
          },
        },
        angleLines: {
          display: FormatListBulletedOutlined,
          borderDash: [10, 10],
        },
        ticks: {
          maxTicksLimit: 6,
          stepSize: 10,
          beginAtZero: true,
          suggestedMin: 50,
          suggestedMax: 100,
          display: false,
        },
      },
    };

    const nil = listOf_persen_score
    const dataRadar = setDataRadar(nil,listOf_label)

    if (status_data===false){
      return(
        <React.Fragment>
          <h1 className='DNF shd_10'>Data Not Found</h1>
        </React.Fragment>
      )
    }

    if (get_data===false){
      return(
        <React.Fragment>
         <Loading />
        </React.Fragment>
      )
    }


    return (
      <React.Fragment>
        <Helmet>
          <body className='vip-report--mobile'></body>
        </Helmet>
        { isDownloadPdf && ( <Loading />)  }
        <div
          className='flex box header_shadow_VIP center'
          style={ isDownloadPdf ? { opacity: 0,  backgroundColor: 'white',width: '100%',position: 'fixed',zIndex: '99', } : 
            { opacity: 1, backgroundColor: 'white',
              width: '100%',
              position: 'fixed',
              zIndex: '99', 
            }
          }>
          <div>
            <img src={logo} className='logo' />
          </div>
        </div>
        <div className='box-wrapper bg-color VIP'  style={isDownloadPdf ? { opacity: 0 } : { opacity: 1 }}>
          <div className='wrapper'>
            <div className='content VIP-Report'>
              <div className='page-container  width-100' style={{ marginTop: '120px' }}>
                <div className='page-container-outer ' style={{ marginBottom: '50px' }}>
                  <div className='flex width-100 space-between'>
                    <div className='VIP_back bg-36 br-5'  onClick={() => this.state.redirectLink = 'work_assessment' } style={{ cursor: 'pointer', opacity:0 }}>
                      <p  className='op-0'>{'<'} Assessments</p>
                    </div>
                    <div
                      className='VIP_back bg-36 br-5 pointer'
                      style={{ backgroundColor: '#3B6C98'}}
                      onClick={this.exportPDFWithComponent}>
                      <img src={icon_download_white} />
                      <p style={{ color: 'white' }}>&nbsp; Download</p>
                    </div>
                  </div>
                  <PDFExport
                    pageTemplate={PageTemplateFooterVip}
                    forcePageBreak='.page-vip-report-break'
                    keepTogether='.page-vip-report'
                    fileName={`VIP Report`}
                    scale={0.42}
                    paperSize='A4'
                    ref={(component) => (this.pdfExportComponent = component)}>
                    {/* <div ref={(c) => (this.pdfExportComponent = c)}> */}
                    <div className='flex width-100 column'>
                      <div
                        className='boxShadow width-100'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='flex width-100 column center VIP_Cover'>
                          <img effect="blur" src={logo} className='VIP_logo' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                          <button>
                            <p>Full Report</p>
                          </button>
                          <img effect="blur" src={vip_icon} className='VIP_icon' />
                          <h2
                            style={{
                              textTransform: 'capitalize',
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            {profile.name}
                          </h2>
                          <p className='f-p'>{profile.email}</p>
                          <div
                            className='flex column width-100 space-between bg-bottom k-relative-bottom'
                            style={{ backgroundImage: `url(${vip_bg})` }}>
                            <p>{setDatePID(profile.updatedAt)}</p>
                            <p className='openSans' style={{ fontSize: '14px' }}>
                              Copyright 2021, Jakarta Selatan, Indonesia | www.karyatalents.com |
                              community@karyatalents.com
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='width-100 column center VIP_Jumbotron flex'>
                          <img effect="blur" src={logo} className='logo_personality' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                          <button>
                            <p
                              style={{
                                fontFamily: visible ? '' : 'sans-serif',
                                fontWeight: visible ? '' : 'Bold',
                              }}>
                              Overview
                            </p>
                          </button>
                        </div>
                        <div className='content-vip-overview flex column width-100'>
                          <div className='vip-item flex column'>
                            <div className='flex width-100 space-between'>
                              <div className='left-box'>
                                <div className='bg-icon'>
                                  <img effect="blur" src={personality_icon} />
                                </div>
                                <div>
                                  <h3
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Personality Profiler
                                  </h3>
                                  <div className='flex width-100 line' />
                                  <p>
                                    This profiler will assess your personality and behavioral style to improve work
                                    productivity and teamwork, letting you know Who you really are. It is licensed by
                                    Crown Do Well.
                                  </p>
                                </div>
                              </div>
                              <div className='right-box'>
                                <img effect="blur" src={work_personality} />
                              </div>
                            </div>
                            <div className='flex width-100 long-line' />
                          </div>
                          <div className='vip-item flex column'>
                            <div className='flex width-100 space-between'>
                              <div className='left-box'>
                                <div className='bg-icon'>
                                  <img src={interest_icon} />
                                </div>
                                <div>
                                  <h3
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Interest Profiler
                                  </h3>
                                  <div className='flex width-100 line' />
                                  <p>
                                    This profiler will assess the nature of your work interest. Based on that, you will
                                    understand more about your various interests that are suitable for you. This
                                    profiler is based on Holland code assessment developed by John L. Holland.
                                  </p>
                                </div>
                              </div>
                              <div className='right-box'>
                                <img effect="blur" src={work_interest} />
                              </div>
                            </div>
                            <div className='flex width-100 long-line' />
                          </div>
                          <div className='vip-item flex column'>
                            <div className='flex width-100 space-between'>
                              <div className='left-box'>
                                <div className='bg-icon'>
                                  <img effect="blur" src={values_icon} />
                                </div>
                                <div>
                                  <h3
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Values Profiler
                                  </h3>
                                  <div className='flex width-100 line' />
                                  <p>
                                    This profiler will assess the work values preferences of each person. It is created
                                    and adapted from the theory of Dr. Shalom Schwartz's about human basic values.
                                  </p>
                                </div>
                              </div>
                              <div className='right-box'>
                                <img effect="blur" src={work_values} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex width-100 column footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 1</p>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='width-100 column center VIP_Jumbotron flex'>
                          <img effect="blur" src={logo} className='logo_personality' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                          <button>
                            <p
                              style={{
                                fontFamily: visible ? '' : 'sans-serif',
                                fontWeight: visible ? '' : 'Bold',
                              }}>
                              How to use your Report
                            </p>
                          </button>
                        </div>
                        <div className='box-vip-framework'>
                          <div
                            className='flex width-100 first-box column center'
                            style={{
                              border: visible ? '' : '1px solid #ececec',
                            }}>
                            <h1
                              style={{
                                fontFamily: visible ? '' : 'sans-serif',
                                fontWeight: visible ? '' : 'Bold',
                              }}>
                              VIP Report Framework
                            </h1>
                            <div className='flex width-100 space-between'>
                              <div className='width-20 flex column center'>
                                <img effect="blur" src={learn} />
                                <h6
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  Learn
                                </h6>
                                <p
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  #whoyouare
                                </p>
                              </div>
                              <div className='width-20 flex column center'>
                                <img effect="blur" src={discover} />
                                <h6
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  Discover
                                </h6>
                                <p
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  #whatyoulove
                                </p>
                              </div>
                              <div className='width-20 flex column center'>
                                <img effect="blur" src={align} />
                                <h6
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  Align
                                </h6>
                                <p
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  #whyyouwork
                                </p>
                              </div>
                              <div className='width-20 flex column center'>
                                <img effect="blur" src={prepare} />
                                <h6
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  Prepare
                                </h6>
                                <p
                                  className='openSans'
                                  style={{
                                    fontFamily: visible ? '' : 'Dejavu',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  #beyourbest
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='flex width-100 center'>
                            <h1 className='title-framework'>Let’s start the journey</h1>
                          </div>
                          <div className='flex width-100 flex-numb'>
                            <img effect="blur" src={v1} />
                            <div className='flex column text-numb'>
                              <h6
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                Learn about your personality's strengths and struggles
                              </h6>
                              <p
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                #whoyouare
                              </p>
                            </div>
                          </div>
                          <div className='flex width-100 flex-numb'>
                            <img effect="blur" src={v2} />
                            <div className='flex column text-numb'>
                              <h6
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                Discover the jobs that spark your interests
                              </h6>
                              <p
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                #whatyoulove
                              </p>
                            </div>
                          </div>
                          <div className='flex width-100 flex-numb'>
                            <img effect="blur" src={v3} />
                            <div className='flex column text-numb'>
                              <h6
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                Align what's important for you in your ideal job
                              </h6>
                              <p
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                #whyyouwork
                              </p>
                            </div>
                          </div>
                          <div className='flex width-100 flex-numb'>
                            <img effect="blur" src={v4} />
                            <div className='flex column text-numb'>
                              <h6
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                Prepare to use your strengths to adapt to future of work
                              </h6>
                              <p
                                className='openSans'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                #beyourbest
                              </p>
                            </div>
                          </div>
                          <div className='flex width-100 second-box column'></div>
                        </div>
                        <div className='flex width-100 column footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 2</p>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='width-100 column center VIP_Jumbotron flex'>
                          <img effect="blur" src={logo} className='logo_personality' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                          <button>
                            <p
                              style={{
                                fontFamily: visible ? '' : 'sans-serif',
                                fontWeight: visible ? '' : 'Bold',
                              }}>
                              Personality
                            </p>
                          </button>
                        </div>
                        <div className='flex width-100 title-personality center'>
                          <h1 style={{ marginRight: '8px', color: '#DDB87A' }}>Profiler 1 - </h1>
                          <h1> Personality Profiler</h1>
                        </div>
                        <div className='flex width-100 center'>
                          <div className='boxShadow width-80 flex column'>
                            <div
                              className='box-profiler1'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100 flex-numb'>
                                <img effect="blur" src={v1} />
                                <div className='flex column text-numb'>
                                  <h6 className='openSans'>Learn about your personality's strengths and struggles</h6>
                                  <p className='openSans'>#whoyouare</p>
                                </div>
                              </div>
                              <div className='flex width-100 column content-profiler1'>
                                <p className='width-100 flex openSans'>
                                  Everybody are unique in their own way, including their personalities. In workplace,
                                  there are also some jobs that are natural for some people and not natural for others.
                                  Learning about your personality will help you to discover role or jobs that play to
                                  your personality's natural strengths. For instance, there are some jobs that requires
                                  detail-oriented and there are some people who are more natural in that. At Karya
                                  Talents, we believe that people will thrive when they work according to their natural
                                  gifting and personality.
                                </p>
                                <p className='width-100 flex openSans'>
                                  Through this profiler, you will learn how to capitalize the strengths that your
                                  personality have and learn to deal with the aspect of your personality that could
                                  become obstacles for you.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex width-100 column footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 3</p>
                          </div>
                        </div>
                      </div>

                      <div className='flex width-100 column report_Profiler'>
                        <div
                          className='canvas_Profiler page-vip-report page-vip-report-break'
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-100' style={{ marginBottom: '23px' }}>
                            <div className='width-100 column center VIP_Jumbotron flex'>
                              <img effect="blur" src={logo} className='logo_personality' />
                              <h1
                                className='leagueSpartan'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                VIP Profiler
                              </h1>
                              <button>
                                <p
                                  style={{
                                    fontFamily: visible ? '' : 'sans-serif',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  Personality
                                </p>
                              </button>
                            </div>
                          </div>
                          <div className='flex width-100 center' style={{ margin: '4px auto 14px' }}>
                            <div
                              className='under-lc'
                              style={{
                                marginTop: this.state.visible2 ? '' : '0px',
                                width: this.state.visible2 ? '' : '208px',
                                flexDirection: this.state.visible2 ? '' : 'row',
                              }}>
                              <p
                                style={{
                                  fontSize: this.state.visible2 ? '' : '12px',
                                  margin: this.state.visible2 ? '' : '17.5px auto auto',
                                }}>
                                Under License From
                              </p>
                              <img effect="blur"
                                src={crown_logo}
                                style={{
                                  width: this.state.visible2 ? '' : '70px',
                                }}
                              />
                            </div>
                          </div>
                          <div className='width-100 block'>
                            <div
                              className='box_admin_Profile'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100 height-100 space-between'>
                                <img effect="blur"
                                  src={wavy_left}
                                  style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderBottomLeftRadius: '10px',
                                  }}
                                />
                                <img effect="blur"
                                  src={wavy_right}
                                  style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderTopRightRadius: '10px',
                                  }}
                                />
                              </div>
                              <div className='flex  column textPrepare mobile-text--prepare'>
                                <p className='top'>Prepared for</p>
                                <div className='flex width-100 space-between'>
                                  <div className='flex '>
                                    <h3 style={{ textTransform: 'capitalize' }}>{profile.name}</h3>
                                    <button>
                                      <p
                                        style={{
                                          textTransform: 'uppercase',
                                          fontFamily: visible ? '' : 'Dejavu',
                                          fontWeight: visible ? '' : 'Bold',
                                        }}>
                                        {label}
                                      </p>
                                    </button>
                                  </div>
                                  {date_report && <p className='date'>{date_PID} </p>}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='width-report'>
                            <div
                              className='flex width-100 center box-Overview'
                              style={{ justifyContent: 'space-between' }}>
                              <div className='line_Report'></div>
                              <h1 className='title-line_Report'>OVERVIEW</h1>
                              <div className='line_Report'></div>
                            </div>
                            <p className='width-100 textOverview' style={{ marginBottom: '48px', height: 'auto' }}>
                              The natural personality profile for you is indicated by the blue line on the graph below.
                              Note also the gray line; it represents the Blended Profile that most nearly matches your
                              graph. Seventeen Blended Profiles are used as the baseline for interpreting the
                              combinations of four basic dimensions of personality. The information in this section is
                              derived from the {label} Blended Profile. Typically, the more closely the blue line graph
                              matches this Blended Profile, the more accurately the information will describe your
                              natural behavior style.{' '}
                            </p>
                          </div>
                          <div className='box_Admin flex center column k-custome-h-500'>
                            <div
                              className='box_Chart'
                              style={{
                                width: this.state.visible2 ? '' : '800px',
                              }}>
                              {/* <div className="box_Chart" > */}
                              <h1
                                className='width-100'
                                style={{
                                  fontSize: this.state.visible2 ? '' : '28px',
                                  lineHeight: this.state.visible2 ? '' : '38px',
                                }}>
                                {label}
                              </h1>
                              <div className='flex width-100 center'>
                                <div className='width-100  block'>
                                  <div className='flex width-100 center'>
                                    <div className='flex width-100 flexBox'>
                                      <div className='flex width-100 column sub_flexBox'>
                                        <div
                                          className='flex width-100 space-between bar_Desc'
                                          style={{ marginBottom: '32px' }}>
                                          <div className='width-20 flex column center'>
                                            <h1>Directing</h1>
                                            <p>Assertive - Decisive</p>
                                            <p>Controlling</p>
                                          </div>
                                          <div className='width-20 flex column center'>
                                            <h1>Interacting</h1>
                                            <p>Outgoing - Convincing</p>
                                            <p>Excitable</p>
                                          </div>
                                          <div className='width-20 flex column center'>
                                            <h1>Supportive</h1>
                                            <p>Harmonious - Patient</p>
                                            <p>Lenient</p>
                                          </div>
                                          <div className='width-20 flex column center'>
                                            <h1>Conscientious</h1>
                                            <p>Detailed - Precise</p>
                                            <p>Perfectionist</p>
                                          </div>
                                        </div>
                                        {/* <div className="none"> */}
                                        <Bar data={dataG} options={options} style={{ width: '100%' }} />
                                        {/* </div> */}
                                        <div
                                          className='flex width-100 space-between bar_Desc'
                                          style={{ marginTop: '32px' }}>
                                          <div className='width-20 flex column center'>
                                            <h1>Adaptive</h1>
                                            <p>Pliable - Conforming</p>
                                            <p>Passive</p>
                                          </div>
                                          <div className='width-20 flex column center'>
                                            <h1>Reserved</h1>
                                            <p>Modest - Quiet</p>
                                            <p>Distant</p>
                                          </div>
                                          <div className='width-20 flex column center'>
                                            <h1>Objective</h1>
                                            <p>Questioning - Impatient</p>
                                            <p>Critical</p>
                                          </div>
                                          <div className='width-20 flex column center'>
                                            <h1>Unconventional</h1>
                                            <p>Instinctive - Improvised</p>
                                            <p>Unorganized</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='width-report'>
                            <div className='flex width-100 report_Admin'>
                              <p className='openSans justify '>{report.intro}</p>
                            </div>
                            <div
                              className='Idea_box'
                              style={{
                                flexDirection: this.state.visible2 ? '' : 'row',
                              }}>
                              <div
                                className='blue_container'
                                style={{
                                  width: this.state.visible2 ? '' : '30%',
                                }}>
                                <div className='items width-100'>
                                  <div className='verticalLine' />
                                  <div className='flex width-100 column'>
                                    <h1
                                      className='width-100'
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontSize: this.state.visible2 ? '' : '24px',
                                        lineHeight: this.state.visible2 ? '' : '30px',
                                        marginBottom: this.state.visible2 ? '' : '28px',
                                      }}>
                                      Ideal Environment
                                    </h1>
                                    <p className='width-100 openSans'>{report.idealEnvironmentCopy} </p>
                                  </div>
                                  <img effect="blur" src={ideal} />
                                </div>
                              </div>
                              <div
                                className='blue_container'
                                style={{
                                  width: this.state.visible2 ? '' : '30%',
                                }}>
                                <div className='items  width-100'>
                                  <div className='verticalLine' />
                                  <div className='flex width-100 column'>
                                    <h1
                                      className='width-100'
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontSize: this.state.visible2 ? '' : '24px',
                                        lineHeight: this.state.visible2 ? '' : '30px',
                                        marginBottom: this.state.visible2 ? '' : '28px',
                                      }}>
                                      Areas of Strength
                                    </h1>
                                    <p className='width-100 openSans'>{report.typicalAreasOfStrengthCopy} </p>
                                  </div>
                                  <img effect="blur" src={area} />
                                </div>
                              </div>
                              <div
                                className='blue_container'
                                style={{
                                  width: this.state.visible2 ? '' : '30%',
                                }}>
                                <div className='items  width-100'>
                                  <div className='verticalLine' />
                                  <div className='flex width-100 column'>
                                    <h1
                                      className='width-100'
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontSize: this.state.visible2 ? '' : '24px',
                                        lineHeight: this.state.visible2 ? '' : '30px',
                                        marginBottom: this.state.visible2 ? '' : '28px',
                                      }}>
                                      Areas of Development
                                    </h1>
                                    <p className='width-100 openSans'>{report.typicalAreasOfStruggleCopy} </p>
                                  </div>
                                  <img effect="blur" src={dev} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 4</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className='canvas_Profiler page-vip-report'
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-report'>
                            <div className='width-100 flex' style={{ margin: '24px 0px' }} />
                            <div
                              className='width-100  block'
                              style={{
                                display: this.state.visible2 ? '' : 'flex',
                              }}>
                              <div className='flex width-100 space-between '>
                                <div className='flex width-100 column title_work'>
                                  <h1
                                    style={{
                                      textTransform: 'uppercase',
                                      color: '#3B6C98',
                                      lineHeight: '59px',
                                      fontFamily: visible ? '' : 'Dejavu',
                                    }}>
                                    {label}
                                  </h1>
                                  <h1
                                    style={{
                                      textTransform: 'uppercase',
                                      color: '#DDB87A',
                                      lineHeight: '59px',
                                      fontFamily: visible ? '' : 'Dejavu',
                                    }}>
                                    at work
                                  </h1>
                                </div>
                                <img effect="blur" src={report_admin} />
                              </div>
                            </div>

                            {/* <img effect="blur" src={bg_ellipse}  className="ellipse_report"/> */}
                            <div className=' block wdth-100'>
                              <div
                                className='flex width-100 bg_ellipse'
                                style={{
                                  flexDirection: this.state.visible2 ? '' : 'row',
                                  backgroundImage: this.state.visible2
                                    ? ''
                                    : 'url(/static/media/report_bg_ellipse.3d2064e1.svg) ',
                                }}>
                                <div
                                  className='flex box-left column'
                                  style={{
                                    width: this.state.visible2 ? '' : '48%',
                                    marginRight: this.state.visible2 ? '' : '2%',
                                  }}>
                                  <div
                                    className='box-shadow-report flex column'
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Preferred Activities
                                    </h2>
                                    <p className='openSans'>{report.preferredActivitiesCopy} </p>
                                  </div>
                                  <div
                                    className='box-shadow-report flex column'
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Leadership Style
                                    </h2>
                                    <p className='openSans'>{report.leadershipStyleCopy} </p>
                                  </div>
                                  <div
                                    className='box-shadow-report flex column'
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Communication Style
                                    </h2>
                                    <p className='openSans'>{report.communicationStyleCopy} </p>
                                  </div>
                                  <div
                                    className='box-shadow-report flex column'
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Generic Careers
                                    </h2>
                                    <p className='openSans'>{report.genericCareersCopy} </p>
                                  </div>
                                  <div
                                    className='box-shadow-report flex column'
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Famous people similar to this profile
                                    </h2>
                                    <p className='openSans'>{report.famousPeopleSimilarToThisProfileCopy} </p>
                                  </div>
                                </div>
                                <div
                                  className='flex box-right column'
                                  style={{
                                    width: this.state.visible2 ? '' : '48%',
                                    marginLeft: this.state.visible2 ? '' : '2%',
                                  }}>
                                  <div
                                    className='box-shadow-report flex column '
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Contributions to the Organization
                                    </h2>
                                    <p className='openSans'>{report.contributionsToTheOrganizationCopy}</p>
                                  </div>
                                  <div
                                    className='box-shadow-report flex column '
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Task or People Orientation
                                    </h2>
                                    <p className='openSans'>{report.taskOrPeopleOrientationCopy} </p>
                                  </div>
                                  <div
                                    className='box-shadow-report flex column '
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <h2
                                      style={{
                                        fontFamily: visible ? '' : 'Dejavu',
                                        fontWeight: visible ? '' : 'Bold',
                                      }}>
                                      Suggestions for Managers
                                    </h2>
                                    <p className='openSans suggest-p'>{suggestionsForManagers} </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 5</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className='canvas_Profiler page-vip-report page-vip-report-break'
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-report'>
                            <div className='width-100 flex' style={{ margin: '24px 0px' }} />
                            <div
                              className='width-100  block'
                              style={{
                                display: this.state.visible2 ? '' : 'flex',
                              }}>
                              <div className='flex width-100 space-between '>
                                <div className='flex width-100 column title_work'>
                                  <h1
                                    style={{
                                      textTransform: 'uppercase',
                                      color: '#3B6C98',
                                      lineHeight: '59px',
                                      fontFamily: visible ? '' : 'Dejavu',
                                    }}>
                                    {label}
                                  </h1>
                                  <h1
                                    style={{
                                      textTransform: 'uppercase',
                                      color: '#DDB87A',
                                      lineHeight: '59px',
                                      fontFamily: visible ? '' : 'Dejavu',
                                    }}>
                                    and issues
                                  </h1>
                                </div>
                                <img effect="blur" src={issue} />
                              </div>
                            </div>

                            <div className=' block width-100'>
                              <div className='width-100' style={{ marginTop: '50px' }}>
                                <div
                                  className='box-wavy-gold'
                                  style={{
                                    border: visible ? '' : '1px solid #ececec',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Stability/Flexibility Factors
                                  </h2>
                                  <p className='openSans'>{report.stabilityFlexibilityFactorsCopy} </p>
                                </div>
                                <div
                                  className='box-wavy-gold'
                                  style={{
                                    border: visible ? '' : '1px solid #ececec',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Stress Factors
                                  </h2>
                                  <p className='openSans'>{report.stressFactorsCopy} </p>
                                </div>
                                <div
                                  className='box-wavy-gold'
                                  style={{
                                    border: visible ? '' : '1px solid #ececec',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Relationship Improvement Suggestions
                                  </h2>
                                  <p className='openSans'>{report.relationshipImprovementSuggestionsCopy} </p>
                                </div>
                                <div
                                  className='box-wavy-gold'
                                  style={{
                                    border: visible ? '' : '1px solid #ececec',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Cooperation and Control Factors
                                  </h2>
                                  <p className='openSans'>{report.cooperationAndControlFactorsCopy} </p>
                                </div>
                                <div
                                  className='box-wavy-gold'
                                  style={{
                                    border: visible ? '' : '1px solid #ececec',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Underlying Concerns
                                  </h2>
                                  <p className='openSans'>{report.underlyingConcernsCopy} </p>
                                </div>
                              </div>
                            </div>

                            <div className='width-100 flex' style={{ margin: '38px 0px' }} />
                            <div
                              className='width-100  block'
                              style={{
                                display: this.state.visible2 ? '' : 'flex',
                              }}>
                              <div className='flex width-100 space-between '>
                                <div className='flex width-100 column title_work'>
                                  <h1
                                    style={{
                                      textTransform: 'uppercase',
                                      color: '#3B6C98',
                                      lineHeight: '59px',
                                      fontFamily: visible ? '' : 'Dejavu',
                                    }}>
                                    {label}
                                  </h1>
                                  <h1
                                    style={{
                                      textTransform: 'uppercase',
                                      color: '#DDB87A',
                                      lineHeight: '59px',
                                      fontFamily: visible ? '' : 'Dejavu',
                                    }}>
                                    and finances
                                  </h1>
                                </div>
                                <img effect="blur" src={finance} />
                              </div>
                            </div>

                            <div className='width-100' style={{ marginTop: '50px' }}>
                              <div
                                className='flex width-100 space-between finance_Column'
                                style={{
                                  flexDirection: this.state.visible2 ? '' : 'row',
                                }}>
                                <div
                                  className='bg_finance'
                                  style={{
                                    width: this.state.visible2 ? '' : '27%',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Financial Management
                                  </h2>
                                  <p className='openSans'>{report.financialManagementCopy} </p>
                                </div>
                                <div
                                  className='bg_finance'
                                  style={{
                                    width: this.state.visible2 ? '' : '27%',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Budget and Financial Issues
                                  </h2>
                                  <p className='openSans'>{report.budgetAndFinancialIssuesCopy} </p>
                                </div>
                                <div
                                  className='bg_finance'
                                  style={{
                                    width: this.state.visible2 ? '' : '27%',
                                  }}>
                                  <h2
                                    style={{
                                      fontFamily: visible ? '' : 'Dejavu',
                                      fontWeight: visible ? '' : 'Bold',
                                    }}>
                                    Purchasing Tendencies
                                  </h2>
                                  <p className='openSans'>{report.purchasingTendenciesCopy} </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 6</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className='canvas_Profiler page-vip-report page-vip-report-break'
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-report'>
                            <div className='width-100 flex' style={{ margin: '24px 0px' }} />
                            <div className='dimensions'>
                              <h1>dimensions of personality</h1>
                              <p className='flex width-100 center openSans personal-text'>
                                This report is based on four dimensions of personality. Each dimension has a continuum
                                of behaviours associated with it as shown below.
                              </p>
                              <div className='width-100  block'>
                                <div className='table_dimensions'>
                                  <div className='sub_table'>
                                    <p className='text1'>Adaptive</p>
                                    <div className='center-range'>
                                      <img effect="blur" src={arrLeft} className='arrLeft' />
                                      <p className='text'>Mid-range</p>
                                      <img effect="blur" src={arrRight} className='arrRight' />
                                    </div>
                                    <p className='text2'>Directing</p>
                                  </div>
                                  <div className='sub_table'>
                                    <p className='text1'>Reserved</p>
                                    <div className='center-range'>
                                      <img effect="blur" src={arrLeft} className='arrLeft' />
                                      <p className='text'>Mid-range</p>
                                      <img effect="blur" src={arrRight} className='arrRight' />
                                    </div>
                                    <p className='text2'>Interacting</p>
                                  </div>
                                  <div className='sub_table'>
                                    <p className='text1'>Objective</p>
                                    <div className='center-range'>
                                      <img effect="blur" src={arrLeft} className='arrLeft' />
                                      <p className='text'>Mid-range</p>
                                      <img effect="blur" src={arrRight} className='arrRight' />
                                    </div>
                                    <p className='text2'>Supportive</p>
                                  </div>
                                  <div className='sub_table'>
                                    <p className='text1'>Unconventional</p>
                                    <div className='center-range'>
                                      <img effect="blur" src={arrLeft} className='arrLeft' />
                                      <p className='text'>Mid-range</p>
                                      <img effect="blur" src={arrRight} className='arrRight' />
                                    </div>
                                    <p className='text2'>Conscientious</p>
                                  </div>
                                </div>
                              </div>
                              {/* <div className='width-100 mobile-flex center'>
                            
                              <div
                                className=' block width-100'
                                style={{
                                  display: this.state.visible2 ? '' : 'flex',
                                }}
                              >
                                <div className='flex width-100 center'>
                                  <div
                                    className='flex box_dimensions_personality'
                                    style={{
                                      margin: '0px 0%',
                                      display: 'flow-root',
                                      overflow: 'auto',
                                    }}
                                  >
                                    <div
                                      className='table_dimensions'
                                      style={{
                                        width: '700px',
                                        marginLeft: '0px',
                                      }}
                                    >
                                      <div className='sub_table'>
                                        <p className='text1'>Adaptive</p>
                                        <div className='center-range'>
                                          <img effect="blur"
                                            src={arrLeft}
                                            className='arrLeft'
                                          />
                                          <p className='text'>Mid-range</p>
                                          <img effect="blur"
                                            src={arrRight}
                                            className='arrRight'
                                          />
                                        </div>
                                        <p className='text2'>Directing</p>
                                      </div>
                                      <div className='sub_table'>
                                        <p className='text1'>Reserved</p>
                                        <div className='center-range'>
                                          <img effect="blur"
                                            src={arrLeft}
                                            className='arrLeft'
                                          />
                                          <p className='text'>Mid-range</p>
                                          <img effect="blur"
                                            src={arrRight}
                                            className='arrRight'
                                          />
                                        </div>
                                        <p className='text2'>Interacting</p>
                                      </div>
                                      <div className='sub_table'>
                                        <p className='text1'>Objective</p>
                                        <div className='center-range'>
                                          <img effect="blur"
                                            src={arrLeft}
                                            className='arrLeft'
                                          />
                                          <p className='text'>Mid-range</p>
                                          <img effect="blur"
                                            src={arrRight}
                                            className='arrRight'
                                          />
                                        </div>
                                        <p className='text2'>Supportive</p>
                                      </div>
                                      <div className='sub_table'>
                                        <p className='text1'>Unconventional</p>
                                        <div className='center-range'>
                                          <img effect="blur"
                                            src={arrLeft}
                                            className='arrLeft'
                                          />
                                          <p className='text'>Mid-range</p>
                                          <img effect="blur"
                                            src={arrRight}
                                            className='arrRight'
                                          />
                                        </div>
                                        <p className='text2'>Conscientious</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            </div>
                            <div className='struggle'>
                              <h1
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                Strengths and Struggles
                              </h1>
                              <p className='openSans'>
                                The strengths and struggles in this section of the report relate to your Personality
                                Profiler scores for the four individual dimensions. This information gives more depth to
                                the report and helps you as a manager to focus on the unique style of this individual.
                              </p>
                              <p className='openSans'>
                                There are many advantages to understanding a person’s unique set of talents and
                                motivations, You will enjoy operating in some environments more than in others, simply
                                because they are a better fit for this person’s natural style. A goal should be to align
                                your work as much as possivle with the personal profile strengths and minimize exposure
                                in the areas in which people with this profile typically struggle. On the other hand, a
                                manager can coach a team member to help improve weak areas. For example, a person who
                                tends to be impatient and is not naturally a good listener can improve relationships
                                through a focused effort to improve these areas of struggle. Also keep in mind that
                                strengths overdone become weaknesses.
                              </p>
                              <p className='openSans'>
                                The strengths and struggles that follow are typical for people who share a common
                                profile score. Keep in mind that every individual is unique, so some of the items may
                                not fit you. The list should be objectively reviewed to help determine whether all of
                                the descriptions apply.
                              </p>
                            </div>
                            <div className='dimension-one'>
                              <h1
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                dimension one:
                              </h1>
                              <div className='flex center column'>
                                <div className='sub_title'>
                                  <h3
                                    style={{
                                      fontSize: this.state.visible2 ? '' : '24px',
                                      lineHeight: this.state.visible2 ? '' : '30px',
                                    }}>
                                    Adaptive - Directing
                                  </h3>
                                </div>
                                <div
                                  className='width-100  block'
                                  style={{
                                    display: this.state.visible2 ? '' : 'flex',
                                  }}>
                                  <div
                                    className='box-dimension-desc'
                                    style={{
                                      width: this.state.visible2 ? '' : '100%',
                                      display: this.state.visible2 ? '' : 'flex',
                                      justifyContent: this.state.visible2 ? '' : 'space-between',
                                    }}>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Adaptive
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Pliable
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Conforming
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Passive
                                      </p>
                                    </div>
                                    <div
                                      className='box-centerRange'
                                      style={{
                                        width: this.state.visible2 ? '' : '60%',
                                        margin: this.state.visible2 ? '' : 'auto 0px',
                                      }}>
                                      <div className='range-dimension-one'>
                                        <div
                                          className='dynamic-range'
                                          style={{
                                            marginLeft: `${(ndirecting * 92) / 100}%`,
                                          }}>
                                          <p>{ndirecting} </p>
                                        </div>
                                        <div className='center-range'></div>
                                      </div>
                                      <div className='flex width-100 space-between'>
                                        <p>20</p>
                                        <p>50</p>
                                        <p>80</p>
                                      </div>
                                    </div>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Directing
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Assertive{' '}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Decisive
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Controlling
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {dataDimensionOne}
                              </div>
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 7</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className='canvas_Profiler page-vip-report page-vip-report-break'
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-report'>
                            <div className='dimension-one' style={{ marginTop: '48px' }}>
                              <h1
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                dimension two:
                              </h1>
                              <div className='flex center column'>
                                <div className='sub_title'>
                                  <h3
                                    style={{
                                      fontSize: this.state.visible2 ? '' : '24px',
                                      lineHeight: this.state.visible2 ? '' : '30px',
                                    }}>
                                    Reserved - Interacting
                                  </h3>
                                </div>
                                <div
                                  className='width-100  block'
                                  style={{
                                    display: this.state.visible2 ? '' : 'flex',
                                  }}>
                                  <div
                                    className='box-dimension-desc'
                                    style={{
                                      width: this.state.visible2 ? '' : '100%',
                                      display: this.state.visible2 ? '' : 'flex',
                                      justifyContent: this.state.visible2 ? '' : 'space-between',
                                    }}>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Reserved
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Modest
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Quiet
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Distant
                                      </p>
                                    </div>
                                    <div
                                      className='box-centerRange'
                                      style={{
                                        width: this.state.visible2 ? '' : '60%',
                                        margin: this.state.visible2 ? '' : 'auto 0px',
                                      }}>
                                      <div className='range-dimension-one'>
                                        <div
                                          className='dynamic-range'
                                          style={{
                                            marginLeft: `${(ninteracting * 92) / 100}%`,
                                          }}>
                                          <p>{ninteracting} </p>
                                        </div>
                                        <div className='center-range'></div>
                                      </div>
                                      <div className='flex width-100 space-between'>
                                        <p>20</p>
                                        <p>50</p>
                                        <p>80</p>
                                      </div>
                                    </div>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Interacting
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Outgoing
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Convincing{' '}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Excitable
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {dataDimensionTwo}
                              </div>
                            </div>
                            <div className='dimension-one'>
                              <h1
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                dimension three:
                              </h1>
                              <div className='flex center column'>
                                <div className='sub_title'>
                                  <h3
                                    style={{
                                      fontSize: this.state.visible2 ? '' : '24px',
                                      lineHeight: this.state.visible2 ? '' : '30px',
                                    }}>
                                    Objective - Supportive
                                  </h3>
                                </div>
                                <div
                                  className='width-100  block'
                                  style={{
                                    display: this.state.visible2 ? '' : 'flex',
                                  }}>
                                  <div
                                    className='box-dimension-desc'
                                    style={{
                                      width: this.state.visible2 ? '' : '100%',
                                      display: this.state.visible2 ? '' : 'flex',
                                      justifyContent: this.state.visible2 ? '' : 'space-between',
                                    }}>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Objective
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Questioning{' '}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Impatient
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Critical
                                      </p>
                                    </div>
                                    <div
                                      className='box-centerRange'
                                      style={{
                                        width: this.state.visible2 ? '' : '60%',
                                        margin: this.state.visible2 ? '' : 'auto 0px',
                                      }}>
                                      <div className='range-dimension-one'>
                                        <div
                                          className='dynamic-range'
                                          style={{
                                            marginLeft: `${(nsupportive * 92) / 100}%`,
                                          }}>
                                          <p>{nsupportive} </p>
                                        </div>
                                        <div className='center-range'></div>
                                      </div>
                                      <div className='flex width-100 space-between'>
                                        <p>20</p>
                                        <p>50</p>
                                        <p>80</p>
                                      </div>
                                    </div>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Supportive
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Harmonious
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Patient
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Lenient
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {dataDimensionThree}
                              </div>
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 8</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className='canvas_Profiler page-vip-report page-vip-report-break' 
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-report'>
                            <div className='dimension-one' style={{ marginTop: '48px' }}>
                              <h1
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                dimension four:
                              </h1>
                              <div className='flex center column'>
                                <div className='sub_title'>
                                  <h3
                                    style={{
                                      fontSize: this.state.visible2 ? '' : '24px',
                                      lineHeight: this.state.visible2 ? '' : '30px',
                                    }}>
                                    Unconventional - Conscientious
                                  </h3>
                                </div>
                                <div
                                  className='width-100  block'
                                  style={{
                                    display: this.state.visible2 ? '' : 'flex',
                                  }}>
                                  <div
                                    className='box-dimension-desc'
                                    style={{
                                      width: this.state.visible2 ? '' : '100%',
                                      display: this.state.visible2 ? '' : 'flex',
                                      justifyContent: this.state.visible2 ? '' : 'space-between',
                                    }}>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Unconventional
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Instinctive
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Improvised
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Unorganized
                                      </p>
                                    </div>
                                    <div
                                      className='box-centerRange'
                                      style={{
                                        width: this.state.visible2 ? '' : '60%',
                                        margin: this.state.visible2 ? '' : 'auto 0px',
                                      }}>
                                      <div className='range-dimension-one'>
                                        <div
                                          className='dynamic-range'
                                          style={{
                                            marginLeft: `${(nconscientious * 92) / 100}%`,
                                          }}>
                                          <p>{nconscientious} </p>
                                        </div>
                                        <div className='center-range'></div>
                                      </div>
                                      <div className='flex width-100 space-between'>
                                        <p>20</p>
                                        <p>50</p>
                                        <p>80</p>
                                      </div>
                                    </div>
                                    <div className='content'>
                                      <h3
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '24px',
                                          lineHeight: this.state.visible2 ? '' : '33px',
                                        }}>
                                        Conscientious
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Detailed
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Precise
                                      </p>
                                      <p
                                        style={{
                                          fontSize: this.state.visible2 ? '' : '14px',
                                          lineHeight: this.state.visible2 ? '' : '19px',
                                        }}>
                                        Perfectionist
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {dataDimensionFour}
                              </div>
                            </div>
                            <div
                              className='box-conclusion'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100' style={{ marginBottom: '24px' }}>
                                <div className='line-con'></div>
                                <h1>CONCLUSION</h1>
                                <div className='line-con'></div>
                              </div>
                              <p className='openSans '>
                                {dataReport.components.shared.conclusion.single.conclusionCopy}
                              </p>
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 9</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report '
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='width-100 column center VIP_Jumbotron flex'>
                          <img effect="blur" src={logo} className='logo_personality' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                          <button>
                            <p
                              style={{
                                fontFamily: visible ? '' : 'sans-serif',
                                fontWeight: visible ? '' : 'Bold',
                              }}>
                              Interest
                            </p>
                          </button>
                        </div>
                        <div className='flex width-100 title-personality center'>
                          <h1 style={{ marginRight: '8px', color: '#DDB87A' }}>Profiler 2 - </h1>
                          <h1>Interest Profiler</h1>
                        </div>
                        <div className='flex width-100 center'>
                          <div className='boxShadow width-80 flex column'>
                            <div
                              className='box-profiler1'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100 flex-numb'>
                                <img effect="blur" src={v2} />
                                <div className='flex column text-numb'>
                                  <h6 className='openSans'>Discover the jobs that spark your interests</h6>
                                  <p className='openSans'>#whatyoulove</p>
                                </div>
                              </div>
                              <div className='flex width-100 column content-profiler1'>
                                <p className='width-100 flex openSans'>
                                  As you go along in life, you might discover that there are certain professions that
                                  spark your interest. Some people called it passion. It's what makes you get up every
                                  morning. At Karya Talents, we believe that people will perform their best when they
                                  are working in jobs that fit with their passion.
                                </p>
                                <p className='width-100 flex openSans'>
                                  Through the interest profiler, you can discover what kind of job that fit with your
                                  passion. You will discover that you have many jobs fit with your interest that you can
                                  pursue, not just one. You can use this to narrow down the options of jobs that you
                                  will pursue.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex width-100 column footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 10</p>
                          </div>
                        </div>
                      </div>
                      <div className='flex width-100 column report_Profiler page-vip-report page-vip-report-break'>
                        <div
                          className='canvas_Profiler'
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-100' style={{ marginBottom: '22px' }}>
                            <div className='width-100 column center VIP_Jumbotron flex'>
                              <img effect="blur" src={logo} className='logo_personality' />
                              <h1
                                className='leagueSpartan'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                VIP Profiler
                              </h1>
                              <button>
                                <p
                                  style={{
                                    fontFamily: visible ? '' : 'sans-serif',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  Interest
                                </p>
                              </button>
                            </div>
                          </div>
                          <div className='flex width-100 center' style={{ marginBottom: '24px' }}>
                            {/* <div className="under-lc"  style={{marginTop:this.state.visible2?"":"0px", width:this.state.visible2?"":"208px", flexDirection:this.state.visible2?"":"row"}}>
                                            <p style={{fontSize:this.state.visible2?"":"12px", margin:this.state.visible2?"":"17.5px auto auto"}}>Under License From</p>
                                            <img effect="blur" src={crown_logo} style={{ width:this.state.visible2?"":"70px",}}/>
                                        </div> */}
                          </div>
                          <div className='width-100  block'>
                            <div
                              className='box_admin_Profile'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100 height-100 space-between'>
                                <img effect="blur"
                                  src={wavy_left}
                                  style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderBottomLeftRadius: '10px',
                                  }}
                                />
                                <img effect="blur"
                                  src={wavy_right}
                                  style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderTopRightRadius: '10px',
                                  }}
                                />
                              </div>
                              <div className='flex  column textPrepare mobile-text--prepare'>
                                <p className='top'>Prepared for</p>
                                <div className='flex width-100 space-between'>
                                  <div className='flex '>
                                    <div>
                                      <h3 style={{ textTransform: 'capitalize' }}>{profile.name}</h3>
                                    </div>
                                    <div className='prepare-button-group'>
                                      {data.map((item, i) => (
                                        <button key={i} style={{ marginRight: '12px' }}>
                                          <p
                                            style={{
                                              textTransform: 'uppercase',
                                              fontFamily: visible ? '' : 'Dejavu',
                                              fontWeight: visible ? '' : 'Bold',
                                            }}>
                                            {item.interest_name}
                                          </p>
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  {date_report && (
                                    <p className='date'>
                                      {day[new Date(this.state.lastInt).getDay() - 1] +
                                        ', ' +
                                        month[new Date(this.state.lastInt).getMonth()] +
                                        ' ' +
                                        new Date(this.state.lastInt).getDate() +
                                        ', ' +
                                        new Date(this.state.lastInt).getFullYear() +
                                        '  ' +
                                        (new Date(this.state.lastInt).getHours() > 12
                                          ? new Date(this.state.lastInt).getHours() +
                                            ' : ' +
                                            new Date(this.state.lastInt).getMinutes() +
                                            ' PM'
                                          : new Date(this.state.lastInt).getHours() +
                                            ' : ' +
                                            new Date(this.state.lastInt).getMinutes() +
                                            ' ' +
                                            'AM')}{' '}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='width-report'>
                            <div
                              className='flex width-100 center box-Overview'
                              style={{ justifyContent: 'space-between' }}>
                              <div className='line_Report'></div>
                              <h1 className='title-line_Report'>OVERVIEW</h1>
                              <div className='line_Report'></div>
                            </div>
                            <p className='width-100 textOverview' style={{ marginBottom: '48px', height: 'auto' }}>
                              The natural interests of you are indicated by the top 3 highest interests shown on the
                              graph below. Note that the first highest interest represents the one that you interest the
                              most according to your own passion, following your other highest interests. The Holland
                              Code theory of 6 interests is used as the baseline for interpreting career decisions.
                              Interest Profiler helps you decide the right careers you might want to explore so that you
                              can choose a job you might like and find rewarding! The more a career meets your
                              interests, the more likely it will be joyful and rewarding to you.{' '}
                            </p>
                          </div>
                          <div
                            className='box_Admin flex center column'
                            style={{
                              width: '694px',
                              padding: '10px 55px',
                              margin: 'auto',
                            }}>
                            <div
                              className='box_Chart-2'
                              style={{
                                width: this.state.visible2 ? '' : '800px',
                              }}>
                              <div className='flex width-100 column'>
                                {dataResultInterest.map((item, i) => (
                                  <div key={i} className='flex width-100 space-between box-progress'>
                                    <div className='width-30 flex flex-end'>
                                      <h3>{item.interest_name} </h3>
                                    </div>
                                    <div className='width-65 flex '>
                                      <div
                                        className='flex width-100 height-100 f-bar'
                                        style={{
                                          width: `${item.scoring * 4}%`,
                                        }}
                                      />
                                      <div className='point'>
                                        <p>{item.scoring * 4} </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className='width-report'>
                            <div className='flex width-100 title-interest-2'>
                              <h1
                                className='f-text'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                YOUR TOP 3
                              </h1>
                              <h1
                                className='s-text'
                                style={{
                                  fontWeight: 'bold',
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                &nbsp; WORK INTEREST
                              </h1>
                            </div>
                            <div
                              className='Idea_box'
                              style={{
                                flexDirection: this.state.visible2 ? '' : 'row',
                              }}>
                              {dataInterest.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className='blue_container'
                                    style={{
                                      width: this.state.visible2 ? '' : '30%',
                                    }}>
                                    <div className='items interest-2 width-100'>
                                      <div className='flex width-100 column'>
                                        <div className='subTitle-interest'>
                                          <h1
                                            className='width-100 s-20'
                                            style={{
                                              fontFamily: visible ? '' : 'Dejavu',
                                              fontSize: this.state.visible2 ? '' : '24px',
                                              lineHeight: this.state.visible2 ? '' : '30px',
                                              marginBottom: this.state.visible2 ? '' : '28px',
                                            }}>
                                            {item.interest_name}
                                          </h1>
                                        </div>
                                        <p className='width-100 openSans'>{item.interest_description} </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 11</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='flex popular-box column' style={{ width: 'auto' }}>
                          <div className='flex width-100 head-title space-between'>
                            <div className='flex width-75 column'>
                              <h1
                                className='flex width-100 text-1'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                POPULAR JOBS
                              </h1>
                              <h1
                                className='flex width-100 text-2'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                BASED ON YOUR WORK INTEREST
                              </h1>
                            </div>
                            <div className='flex width-25'>
                              <img src={popular} className='width-100' style={{ height: 'auto' }} />
                            </div>
                          </div>
                          {dataInterest.map((items,i)=>{
                              return(
                          <div className='flex width-100 column jobs-container'>
                            <div className='flex width-100 ch-title'>
                              <h4
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                Popular Jobs with high
                              </h4>
                              <h4
                                style={{
                                  color: '#DDB87A',
                                  margin: 'auto 15px',
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                {items.interest_name}
                              </h4>
                              <h4
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                {' '}
                                Interest
                              </h4>
                            </div>
                            <div className='width-100 flex space-between'>
                              {dataJobByInterest.length > 0 &&
                                dataJobByInterest
                                  .filter((x) => x.interest_id == items.id)
                                  .map((item, i) => (
                                    <div
                                      key={i}
                                      className='jobs-card flex column'
                                      style={{
                                        border: visible ? '' : '1px solid #ececec',
                                      }}>
                                      <div
                                        className='width-100 '
                                        style={{
                                          height: '150px',
                                          backgroundColor: '#D7D7D7',
                                        }}>
                                        <img effect="blur"  onError={(e)=>{e.target.src=work_err}}
                                          src={`${API}/File/Job/${item.photo}`}
                                          className='width-100 height-100'
                                          style={{ objectFit: 'cover' }}
                                        />
                                      </div>
                                      <div className='flex' style={{ padding: '14px' }}>
                                        <p className='openSans'>{item.job_title_indonesia}</p>
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </div>)
                          })}
                          <div className='flex width-100 report_Profiler'>
                            <div
                              className='box-conclusion'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100' style={{ marginBottom: '24px' }}>
                                <div className='line-con'></div>
                                <h1>CONCLUSION</h1>
                                <div className='line-con'></div>
                              </div>
                              <p className='openSans '>
                                Now you know the result of your work interests and various professions that spark your
                                interest, we recommend you to keep this result in mind when choosing what's to pursue
                                further in your career. Because we believe you can be your best when you're working in
                                jobs that you're passionate about. If now the timing is not right, don't worry. The
                                first step to success is to know yourself and you have taken this critical first step.
                                Congratulations!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='flex width-100 footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 12</p>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='width-100 column center VIP_Jumbotron flex'>
                          <img effect="blur" src={logo} className='logo_personality' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                          <button>
                            <p
                              style={{
                                fontFamily: visible ? '' : 'sans-serif',
                                fontWeight: visible ? '' : 'Bold',
                              }}>
                              Values
                            </p>
                          </button>
                        </div>
                        <div className='flex width-100 title-personality center'>
                          <h1 style={{ marginRight: '8px', color: '#DDB87A' }}>Profiler 3 - </h1>
                          <h1>Values Profiler</h1>
                        </div>
                        <div className='flex width-100 center'>
                          <div className='boxShadow width-80 flex column'>
                            <div
                              className='box-profiler1'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100 flex-numb'>
                                <img effect="blur" src={v3} />
                                <div className='flex column text-numb'>
                                  <h6 className='openSans'>Align what's important for you in your ideal job</h6>
                                  <p className='openSans'> #whyyouwork</p>
                                </div>
                              </div>
                              <div className='flex width-100 column content-profiler1'>
                                <p className='width-100 flex openSans'>
                                  After you learn about your natural personality and your various interests, you might
                                  be excited to pursue you what you perceive as your ideal job. But there's one more
                                  very important thing that you need to consider, your work values.
                                </p>
                                <p className='width-100 flex openSans'>
                                  There are plenty of people who are in the right job that fit with their interests and
                                  personality, but they still don't find fulfillment in their job. Why is that? Most of
                                  the time, it's because they haven't really identify what their value the most in their
                                  ideal job, what's actually will bring them fulfillment.
                                </p>
                                <p className='width-100 flex openSans'>
                                  When you know what you truly values, you will be able to choose your job better and
                                  even choose suitable organization that you will be happy to work for, organization
                                  that values what you truly value.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex width-100 column footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 13</p>
                          </div>
                        </div>
                      </div>

                      <div className='flex width-100 column report_Profiler page-vip-report page-vip-report-break'>
                        <div
                          className='canvas_Profiler'
                          style={{
                            border: visible ? '' : '1px solid #ececec',
                            marginBottom: '40px',
                          }}>
                          <div className='width-100' style={{ marginBottom: '22px' }}>
                            <div className='width-100 column center VIP_Jumbotron flex'>
                              <img effect="blur" src={logo} className='logo_personality' />
                              <h1
                                className='leagueSpartan'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                VIP Profiler
                              </h1>
                              <button>
                                <p
                                  style={{
                                    fontFamily: visible ? '' : 'sans-serif',
                                    fontWeight: visible ? '' : 'Bold',
                                  }}>
                                  Values
                                </p>
                              </button>
                            </div>
                          </div>
                          <div className='flex width-100 center' style={{ marginBottom: '24px' }}>
                            {/* <div className="under-lc"  style={{marginTop:this.state.visible2?"":"0px", width:this.state.visible2?"":"208px", flexDirection:this.state.visible2?"":"row"}}>
                                            <p style={{fontSize:this.state.visible2?"":"12px", margin:this.state.visible2?"":"17.5px auto auto"}}>Under License From</p>
                                            <img effect="blur" src={crown_logo} style={{ width:this.state.visible2?"":"70px",}}/>
                                        </div> */}
                          </div>
                          <div className='width-100  block'>
                            <div
                              className='box_admin_Profile'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100 height-100 space-between'>
                                <img effect="blur"
                                  src={wavy_left}
                                  style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderBottomLeftRadius: '10px',
                                  }}
                                />
                                <img effect="blur"
                                  src={wavy_right}
                                  style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderTopRightRadius: '10px',
                                  }}
                                />
                              </div>
                              <div className='flex  column textPrepare mobile-text--prepare'>
                                <p className='top'>Prepared for</p>
                                <div className='flex width-100 space-between'>
                                  <div className='flex mobile-text--prepare--name'>
                                    <div>
                                      <h3 style={{ textTransform: 'capitalize' }}>{profile.name}</h3>
                                    </div>
                                    <div className='prepare-button-group'>
                                      {dataLimit.map((item, i) => (
                                        <button key={i} style={{ marginRight: '12px' }}>
                                          <p
                                            style={{
                                              textTransform: 'uppercase',
                                              fontFamily: visible ? '' : 'Dejavu',
                                              fontWeight: visible ? '' : 'Bold',
                                            }}>
                                            {item.values.replace(/\s.*/, '')}
                                          </p>
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  {date_report && (
                                    <p className='date'>
                                      {day[new Date(this.state.lastUpdate).getDay() - 1] +
                                        ', ' +
                                        month[new Date(this.state.lastUpdate).getMonth()] +
                                        ' ' +
                                        new Date(this.state.lastUpdate).getDate() +
                                        ', ' +
                                        new Date(this.state.lastUpdate).getFullYear() +
                                        '  ' +
                                        (new Date(this.state.lastUpdate).getHours() > 12
                                          ? new Date(this.state.lastUpdate).getHours() +
                                            ' : ' +
                                            new Date(this.state.lastUpdate).getMinutes() +
                                            ' PM'
                                          : new Date(this.state.lastUpdate).getHours() +
                                            ' : ' +
                                            new Date(this.state.lastUpdate).getMinutes() +
                                            ' ' +
                                            'AM')}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='width-report'>
                            <div
                              className='flex width-100 center box-Overview'
                              style={{ justifyContent: 'space-between' }}>
                              <div className='line_Report'></div>
                              <h1 className='title-line_Report'>OVERVIEW</h1>
                              <div className='line_Report'></div>
                            </div>
                            <p className='width-100 textOverview' style={{ marginBottom: '48px', height: 'auto' }}>
                              The natural values of you are indicated by the top 3 highest values shown on the graph
                              below. Note that the lines with 3 blue dots closest to the outer side of the cobweb chart
                              are showing your top 3 highest values. Dr. Shalom Schwartz's theory regarding 10 human
                              basic values is used as the baseline for interpreting working values in your workspace.
                              Values Profiler helps you to understand whether the work you do is worth to fulfill your
                              values. When you work in an organization that matches your values, you will find the
                              purpose of work-life in what you are doing.{' '}
                            </p>

                            <div className='flex width-100 center box-Overview'>
                              <div className='flex width-radar '>
                                <div className='boxShadow width-80 flex column' style={{ margin: 'auto' }}>
                                  <div
                                    className='box-profiler1'
                                    style={{
                                      border: visible ? '' : '1px solid #ececec',
                                    }}>
                                    <Radar data={dataRadar} options={optionsRadar} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='width-report'>
                            <div className='flex width-100 title-interest-2'>
                              <h1
                                className='f-text'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                YOUR TOP 3
                              </h1>
                              <h1
                                className='s-text'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                {' '}
                                &nbsp; VALUES
                              </h1>
                            </div>
                            <div
                              className='Idea_box'
                              style={{
                                flexDirection: this.state.visible2 ? '' : 'row',
                              }}>
                              {dataLimit.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className='blue_container'
                                    style={{
                                      width: this.state.visible2 ? '' : '30%',
                                    }}>
                                    <div className='items interest-2 width-100'>
                                      <div className='flex width-100 column'>
                                        <div className='subTitle-interest'>
                                          <h1
                                            className='width-100 s-20'
                                            style={{
                                              fontFamily: visible ? '' : 'Dejavu',
                                              fontSize: this.state.visible2 ? '' : '24px',
                                              lineHeight: this.state.visible2 ? '' : '30px',
                                              marginBottom: this.state.visible2 ? '' : '28px',
                                            }}>
                                            {item.values.replace(/\s.*/, '')}
                                          </h1>
                                        </div>
                                        <p className='width-100 openSans'>{item.content_description} </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className='flex width-100 column footer-vip'>
                            <div className='flex'>
                              <p className='openSans'>VIP Profiler</p>
                              <p className='openSans'> &nbsp;| 14</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='flex popular-box column' style={{ width: 'auto' }}>
                          <div className='flex width-100 head-title space-between'>
                            <div className='flex width-75 column'>
                              <h1
                                className='flex width-100 text-1'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                }}>
                                aligning your top values
                              </h1>
                              <h1
                                className='flex width-100 text-2'
                                style={{
                                  fontFamily: visible ? '' : 'Dejavu',
                                  fontWeight: visible ? '' : 'Bold',
                                }}>
                                with organizations
                              </h1>
                            </div>
                            <div className='flex width-25'>
                              <img effect="blur" src={popular} className='width-100' style={{ height: 'auto' }} />
                            </div>
                          </div>
                          <div className='flex width-100 box-color-values'>
                            <div className='flex width-80 column'>
                              <p className='flex width-100 openSans'>
                                When you plan to join new organization, find out whether the organization has culture of{' '}
                                {lm1}. {desc1}
                              </p>
                              <p className='flex width-100 openSans'>
                                To align with your high values {lm2}, {desc2}
                              </p>
                              <p className='flex width-100 openSans'>
                                To align with your high values {lm3}, {desc3}
                              </p>
                            </div>
                          </div>

                          <div className='flex width-100 report_Profiler'>
                            <div
                              className='box-conclusion'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100' style={{ marginBottom: '24px' }}>
                                <div className='line-con'></div>
                                <h1>CONCLUSION</h1>
                                <div className='line-con'></div>
                              </div>
                              <p className='openSans '>
                                You have discovered your top 3 work values and know what you value the most in your
                                ideal job. You can use this result to evaluate your current job or when you are looking
                                for your next job. It is ideal if your top 3 values are also the values that your boss
                                and organization values. When values of individual and values of the organization
                                aligns, there will be excellent synergy and trust will be built more easily. You will
                                also feel more fulfilled in your job because you are doing what is truly most important
                                for you. Congratulations for being one step ahead in knowing yourself. We wish you great
                                success in your career and we hope you will discover Joy at Work.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='flex width-100 footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 15</p>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report page-vip-report-break'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                          marginBottom: '40px',
                        }}>
                        <div className='width-100 column center VIP_Jumbotron flex'>
                          <img effect="blur" src={logo} className='logo_personality' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                          <button>
                            <p
                              style={{
                                fontFamily: visible ? '' : 'sans-serif',
                                fontWeight: visible ? '' : 'Bold',
                              }}>
                              Next Steps
                            </p>
                          </button>
                        </div>
                        <div className='flex width-100 title-personality center'>
                          <h1 style={{ marginRight: '8px', color: '#DDB87A' }}> </h1>
                          <h1> </h1>
                        </div>
                        <div className='flex width-100 center'>
                          <div className='boxShadow width-80 flex column'>
                            <div
                              className='box-profiler1'
                              style={{
                                border: visible ? '' : '1px solid #ececec',
                              }}>
                              <div className='flex width-100 flex-numb'>
                                <img effect="blur" src={v4} />
                                <div className='flex column text-numb'>
                                  <h6 className='openSans'>
                                    Prepare to use your strengths to adapt to future of work{' '}
                                  </h6>
                                  <p className='openSans'>#whatyoulove</p>
                                </div>
                              </div>
                              <div className='flex width-100 column content-profiler1'>
                                <div className='flex width-100 column b_next'>
                                  <div style={{ padding: '14px' }}>
                                    <button className='flex'>
                                      <span
                                        style={{
                                          fontFamily: visible ? '' : 'Dejavu',
                                          fontWeight: visible ? '' : 'Bold',
                                        }}>
                                        Step 1
                                      </span>
                                    </button>
                                    <p className='width-100 flex openSans' style={{ fontSize: '16px' }}>
                                      Review your VIP profiler result and reflect on your career history. Are there any
                                      career decisions that you would make differently if you have read this report in
                                      the past? What would you do differently for your future career decisions?
                                    </p>
                                  </div>
                                </div>
                                <div className='flex width-100 column b_next'>
                                  <div style={{ padding: '14px' }}>
                                    <button className='flex'>
                                      <span
                                        style={{
                                          fontFamily: visible ? '' : 'Dejavu',
                                          fontWeight: visible ? '' : 'Bold',
                                        }}>
                                        Step 2
                                      </span>
                                    </button>
                                    <p className='width-100 flex openSans' style={{ fontSize: '16px' }}>
                                      Talk to several people who know you well (preferably people who are currently
                                      working with you or who has worked with you in the past) and ask them to validate
                                      your result and give you honest feedback
                                    </p>
                                  </div>
                                </div>
                                <div className='flex width-100 column b_next'>
                                  <div style={{ padding: '14px' }}>
                                    <button className='flex'>
                                      <span
                                        style={{
                                          fontFamily: visible ? '' : 'Dejavu',
                                          fontWeight: visible ? '' : 'Bold',
                                        }}>
                                        Step 3
                                      </span>
                                    </button>
                                    <p className='width-100 flex openSans' style={{ fontSize: '16px' }}>
                                      Remember it is best to choose careers that align with all 3 aspect of the VIP
                                      (Values, Interest, Personality) because it represents who you truly are and your
                                      greatest strength.
                                    </p>
                                  </div>
                                </div>
                                <div className='flex width-100 column b_next'>
                                  <div style={{ padding: '14px' }}>
                                    <button className='flex'>
                                      <span
                                        style={{
                                          fontFamily: visible ? '' : 'Dejavu',
                                          fontWeight: visible ? '' : 'Bold',
                                        }}>
                                        Step 4
                                      </span>
                                    </button>
                                    <p className='width-100 flex openSans' style={{ fontSize: '16px' }}>
                                      When you know what careers that you might want to pursue, you can visit
                                      www.karyatalents.com to get more resources regarding your career development. You
                                      can :
                                    </p>
                                    <p style={{ fontSize: '16px', margin: 0 }}>
                                      - Apply for available exciting jobs from our clients
                                    </p>
                                    <p style={{ fontSize: '16px', margin: 0 }}>
                                      - Take free online courses to upskill and reskill yourself
                                    </p>
                                    <p style={{ fontSize: '16px', margin: 0 }}>
                                      - Refer your friends to join Karya Talents network and get reward
                                    </p>
                                    <p style={{ fontSize: '16px', margin: 0 }}>
                                      - Learn from our various career development resources
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex width-100 column center desc-steps k-m-auto'>
                          <h5>we wish you discover</h5>
                          <h5 style={{ color: '#DDB87A' }}>#joyatwork</h5>
                        </div>
                        <div className='flex width-100 column footer-vip'>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 16</p>
                          </div>
                        </div>
                      </div>

                      <div
                        className='boxShadow width-100 page-vip-report'
                        style={{
                          border: visible ? '' : '1px solid #ececec',
                        }}>
                        <div className='width-100 column center VIP_Jumbotron flex'>
                          <img effect="blur" src={logo} className='logo_personality' />
                          <h1
                            className='leagueSpartan'
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            VIP Profiler
                          </h1>
                        </div>
                        <div className='flex width-100 closing center'>
                          <h1
                            style={{
                              fontFamily: visible ? '' : 'Dejavu',
                            }}>
                            {' '}
                            #joyatwork
                          </h1>
                        </div>
                        <div className='flex width-100 column center closing-text'>
                          <div className='width-50 flex column center'>
                            <h6 className='openSans width-100'>Disclaimer</h6>
                            <p className='openSans width-100'>
                              The VIP report that is being presented here is purely educational in nature and written as
                              a form of recommendation, thus it depends on discretion and interpretation of the
                              individual while reading the report. Karya Talents is not responsible to the application
                              and implications of individual's career decisions and development.
                            </p>
                          </div>
                        </div>
                        <div className='flex width-100 center'>
                          <img effect="blur" src={logo} />
                        </div>

                        <div className='flex width-100 column footer-vip' style={{ marginTop: '-31px' }}>
                          <div className='flex'>
                            <p className='openSans'>VIP Profiler</p>
                            <p className='openSans'> &nbsp;| 17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PDFExport>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
        <Modal  id="course-outline1" visible={this.state.show_modal} effect="fadeInUp" onClickAway={this.closeRatingAll}>
            <div className="popup_dlc  flex column"   >
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <img effect="blur" src={dlc_close} onClick={this.closeRatingAll}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown} >
                 <h2 className="openSans">Rate this assessment</h2>
                 <Rating name="pristine" value={this.state.ratting} onChange={this.changeRatting} />
                 <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1)Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4)Good</p>
                   <p className="openSans">(5)Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this assessment</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                 </div>
                 <button onClick={this.submitRatingAll} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
    result1: state.result,
    result2: state.allResult,
    dataReportValues1: state.reportValuesRed,
    dataLimitValues1: state.limitValuesRed,
    popular1: state.popular1Red,
    popular2: state.popular2Red,
    popular3: state.popular3Red,
    jobInterest: state.getJobByInterest,
    progress1: state.progressRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
    getResult: (user_id) => {
      dispatch(getResult(user_id));
    },
    getAllResult: (user_id) => {
      dispatch(getAllResult(user_id));
    },
    getJob2: (user_id) => {
      dispatch(getJob2(user_id));
    },
    getJob: (user_id) => {
      dispatch(getJob(user_id));
    },
    getReportValues: (user_id) => {
      dispatch(getReportValues(user_id));
    },
    getReportLimit: (user_id) => {
      dispatch(getReportLimit(user_id));
    },
    popularJob1: (user_id) => {
      dispatch(popularJob1(user_id));
    },
    popularJob2: (user_id) => {
      dispatch(popularJob2(user_id));
    },
    popularJob3: (user_id) => {
      dispatch(popularJob3(user_id));
    },
    getJobByInterest: (id, name) => {
      dispatch(getJobByInterest(id, name));
    },
    getProgress: (user_id) => {
      dispatch(getProgress(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VIP_Cover);
