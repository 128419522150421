import React, { Component } from "react";
import { connect } from "react-redux";
import Nav from '../components/Nav'
import Footer from "../components/Footer";
import { getAbout } from "../_actions/article";
import ScrollToTop from "../components/ScrollToTop";
import Loading from '../components/Loading'
import ReactGA from 'react-ga'; 
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import ReactPixel from 'react-facebook-pixel';


class AboutUs extends Component {
  
  componentDidMount() {
    this.props.getAbout();
    sessionStorage.setItem('setupTime', new Date().getTime())
    if(localStorage.getItem('token')) {
      window.location.href = '/NewDashboard'
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0)
  }
  render() {
    const { dataAbout, isLoading, error } = this.props.about1;

     // timeOut for session
     var timeSession = sessionStorage.getItem('setupTime')
     if (new Date().getTime() - timeSession > 24 * 60 * 60 * 1000 ){
         sessionStorage.clear()
         sessionStorage.removeItem("setupTime")
         window.location.href="/"
     }

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Mohon, refresh halaman ini!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
         </React.Fragment>
      );
    }


    return (
      <div>
        <div className="box-wrapper box-wrapper-master TnC">
          <div className="wrapper">
            <div className="content">
              <Nav />
              <div className="page-container mt-80 master-page width-100">
                {/* <div className="banner width-100">
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={About} className="width-100 img-center" alt="" />
                </div> */}
                <div className="page-container-outer">
                  <div className="box-title width-100">
                  <Zoom right cascade>
                    <h3 className=" width-100" style={{fontFamily:"League Spartan"}}>Privacy Policy</h3>
                    </Zoom>
                    <div className="line-horizontal width-100"></div>
                  </div>
                  <Fade bottom cascade>
                  <div className="companyContent">
                     <div>
                         <p>The following Privacy Policy describes how Karya Talents (“we”, “us”) collect, store, use and protect your personal information.</p>
                            <br/>
                            <div className="flex column">
                               
                                <div className="flex">
                                <h3 className="num-li">1.</h3>
                                    <h3>PERSONAL INFORMATION WE COLLECT</h3>
                                  </div>
                                <p>
                                We collect information which identifies or can be used to identify those who use Karya Talents platform. Personal Information includes name, phone number, email address, and your CV. You will be required to provide this personal information when you sign up
                                 on Karya Talents platform.</p>
                                 </div>
                                 <br/>
                                 <div className="flex column">
                                 <p>The following are types of information and Personal Data that We collect:</p>
                                 <div className="flex">
                                   <p className="num-li">a.	</p>
                                   <div className="flex column">
                                    <p><b>Information You Provide to Us:</b> We receive and store any information that
                                       you provide in connection with our services, including your name, email, phone number, academic qualifications, identifications documents given by the school/college/university/institute, resume, working experience, and/or other information associated with your resume for job applications (you can choose not to provide certain information, but you may not be able to use our services freely) when you:</p>
                                   
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>Sign up, make an account, fill out forms and/or questionnaires, and/or apply a job on our website,</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>Correspondence with us via telephone, email, and other media,</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>Send feedback,</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>Send feedback, Post a testimony, success story and/or review of our services,</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>Report a problem with our platform.</p>
                                   </div>
                                  
                                  
                                   </div>
                                 </div>
                                 <div className="flex">
                                   <p className="num-li">b.	</p>
                                   <div className="flex column">
                                    <p><b>Information Provided by Your Referrer/Your Referee: </b>We may ask you to refer a friend to use our services and we may collect the information in regards to this referral program. You must obtain consent from the parties concerned to provide their information.</p>
                                    <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>As a referrer, you may need to provide the name, email, phone number, positions title and other relevant particulars of the party you wish to refer.</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>As a referee, you may need to provide the name, email, phone number and/or positions title and other relevant particulars of the party who referred you.</p>
                                   </div>
                                  
                                   <p>Karya Talents does not allow you to provide information belonging to anyone without the permission of the party(ies) concerned. Karya Talents is not responsible for any consequences that can harm you and/or the party(ies) in question.</p>
                                   </div>
                                 </div>
                                 <div className="flex">
                                   <p className="num-li">c.	</p>
                                   <div className="flex column">
                                    <p><b>	Automatic Information: </b>We may automatically collect and store certain types of information about the use of the Service, including information about your interactions with content and services available through the Service, including IP addresses and Cookies. This information is not linked to your Personal Information and Data. We use this information to provide general statistics about website usage and to provide you with a more effective service.</p>
                                   </div>
                                 </div>
                                 <div className="flex">
                                   <p className="num-li">d.	</p>
                                   <div className="flex column">
                                    <p><b>	Information from Other Sources:  </b> We may receive information about you from other sources, which We use to improve Our records and provide better services.     </p>
                                   </div>
                                 </div>
                                 </div>
                                 <br/>
                                 <div className="flex column">
                                

                                 <div className="flex">
                                   <h3 className="num-li">2.</h3>
                                   <h3>PERSONALLY IDENTIFIABLE INFORMATION</h3>
                                 </div>
                                 <p>Personally Identifiable Information refers to any information that identifies or can be used to identify, contact, or locate the person associated with the information, including, but not limited to, name, address, telephone number, fax number and e-mail address. Personally Identifiable Information does not include information collected anonymously (without identification of an individual user) or demographic information that is not linked to an identified individual.</p>
                                </div>
                                <br/>
                                 <div className="flex column">
                                 <div className="flex">
                                   <h3 className="num-li">3.</h3>
                                   <h3>	THE USE OF PERSONAL INFORMATION WE COLLECT</h3>
                                 </div>
                                 <p>We use your personal information to help us identify our user demographic and analyze the current job market, job skill demand, most sought job position, and to stay connected; to adjust marketing, to share newsletters, to improve services, to ensure data security and prevent fraud, to comply with applicable laws, to ask for feedback, to post testimonials, to provide support and any other analysis. We will also contact the user through the given data when we find a job that matches with the job seeker’s interest.</p>
                                 </div>
                                 <br/>
                                 <div className="flex column">
                                 <div className="flex">
                                   <h3 className="num-li">4.</h3>
                                   <h3>	COOKIES {"&"} USAGE TRACKING</h3>
                                 </div>
                                 <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                 </div>
                                 <br/>
                                 <div className="flex column">
                                 <div className="flex">
                                   <h3 className="num-li">5.</h3>
                                   <h3>	DATA RETENTION / REMOVAL</h3>
                                 </div>
                                 <p>Your personal data will be held throughout the necessary period to fulfill the data collection purposes. We will review our database from time to time and make decisions to remove or retain the data collected in our system based on our internal evaluation.</p>
                                 </div>
                                 <br/>
                                 <div className="flex column">
                                 <div className="flex">
                                   <h3 className="num-li">6.</h3>
                                   <h3>	DATA DISCLOSURE</h3>
                                 </div>
                                 <p>We may disclose your personal data for the following purposes :</p>
                                <div className="flex column list-min">
                                  <div className="flex">
                                    <p className="num-li">a.</p>
                                    <p>To assess and match your details with the job vacancies</p>
                                  </div>
                                  <div className="flex">
                                    <p className="num-li">b.</p>
                                    <p>To inform you about the industry development</p>
                                  </div>
                                  <div className="flex">
                                    <p className="num-li">c.</p>
                                    <p>To enable you to submit your CV in Karya Talents platform</p>
                                  </div>
                                  <div className="flex">
                                    <p className="num-li">d.</p>
                                    <p>To answer your questions and other purposes between you and Karya Talents</p>
                                  </div>
                                </div>
                                </div>
                                <br/>
                                <p>We may share your information with employers as Karya Talents’ client and third parties that we use to provide and support our services.</p>
                                 <br/>
                                 <div className="flex column">
                                 <p>We may also disclose your information to third parties:</p>
                                 <div className="flex column list-min">
                                  <div className="flex">
                                    <p className="num-li">a.</p>
                                    <p>where required by law or regulatory requirement, court order or other judicial authorization;</p>
                                  </div>
                                  <div className="flex">
                                    <p className="num-li">b.</p>
                                    <p>	in response to legitimate requests from public authorities, including for purposes of meeting national security and law enforcement requirements;</p>
                                  </div>
                                  <div className="flex">
                                    <p className="num-li">c.</p>
                                    <p>in connection with the sale, transfer, merger, bankruptcy, restructuring or other reorganization of a business;</p>
                                  </div>
                                  <div className="flex">
                                    <p className="num-li">d.</p>
                                    <p>	to protect or defend our rights, interests, or property, or those of third parties;</p>
                                  </div>
                                  <div className="flex">
                                    <p className="num-li">e.</p>
                                    <p className="num-li">to investigate any errors in connection with Our products and services;</p>
                                  </div>
                                </div>
                                </div>
                                <br/>
                                <div className="flex column">
                                <div className="li_7 flex column">
                                 <h3 className="num-li ">7.	SECURITY</h3>
                                 <div className="flex ">
                                   <h3 className="num-li">a. </h3>
                                   <h3 className="u-line">Cloud Provider Security </h3>
                                 </div>
                                 <p>We use Amazon Web Services (AWS) which all have certain measures in place to secure and protect the security of their infrastructure.</p>
                                 <div className="flex">
                                   <h3 className="num-li">b.</h3>
                                   <h3 className="u-line">	Software as a Service (SaaS) Security</h3>
                                 </div>
                                 <p>The services we provide are protected by Web Application Firewall (WAF) and distributed denial-of-service (DDoS) protection services.</p>
                                 <div className="flex">
                                   <h3 className="num-li">c.</h3>
                                   <h3 className="u-line">	Transport Security</h3>
                                 </div>
                                 <p>The transport is encrypted using SHA256 digital certificate</p>
                                 <div className="flex">
                                   <h3 className="num-li">d.</h3>
                                   <h3 className="u-line">	Application and Code Security</h3>
                                 </div>
                                 <p>We implement best practices in development, and every piece of programming and modules is reviewed and screened before publication.
All Application Programming Interfaces (APIs) are accessed through secure and encrypted channels.
</p>
                                 <div className="flex">
                                   <h3 className="num-li">e.</h3>
                                   <h3 className="u-line">	User Security</h3>
                                 </div>
                                 <p>All users are granted access and a unique password, all of which are encrypted.</p>
                                 <div className="flex">
                                   <h3 className="num-li">f.</h3>
                                   <h3 className="u-line">Data Security</h3>
                                 </div>
                                 <p>We protect our data by performing backups at all levels: server, operating system (OS), database and code.</p>
                                 <div className="flex">
                                   <h3 className="num-li">g.</h3>
                                   <h3 className="u-line">	Risk and Threat Prevention</h3>
                                 </div>
                                 <p>Our developed applications are constantly tested for quality assurance. We also run Vulnerability and Penetration Test to ensure the security of our platform(s).</p>
                                 </div>
                                 </div>
                                 <br/>
                                 <div className="flex column">
                                <div className="flex">
                                  <h3 className="num-li">8.</h3>
                                  <h3>	DATA CORRECTION</h3>
                                </div>
                                <p>Subject to the applicable law, you may request us for access to and/or the correction of your Personal Information in our possession and control, by contacting us at the details provided below.</p>
                                </div>
                                <br/>
                                <div className="flex column">
                                <p>We reserve the right to refuse your requests for access to, or to correct, some or all of your Personal Information in our possession or control if permitted or required under any applicable law. This may include circumstances where the Personal Information may contain references to other individuals or where the request for access or request to correct is for reasons which we reasonably consider to be trivial, frivolous or vexatious.</p>
                                </div>
                                <br/>
                                <div className="flex column">
                                <div className="flex">
                                  <h3 className="num-li">9.</h3>
                                  <h3>	CHANGES TO THIS PRIVACY POLICY</h3>
                                </div>
                                <p>We may review and amend this Privacy Policy at our sole discretion from time to time, to ensure that it is consistent with our future developments, and/or changes in legal or regulatory requirements. If we decide to amend this Privacy Policy, we will notify you of any such amendments by means of a general notice published on Karya Talents website, or otherwise to your e- mail address set out in your Karya Talents account. It is your responsibility to review this Privacy Policy regularly for the latest information on our data processing and data protection practices.</p>
                                </div>
                                <br/>
                                <div className="flex column">
                                <div className="flex">
                                  <h3 className="num-li">10.</h3>
                                  <h3>	YOUR RIGHTS</h3>
                                </div>
                                <p>Under the laws and regulations governing personal data protection, you have the right to access your information and data portability. In the event of we are proven to have violated these Terms of Service and Privacy Policy, you reserve these following rights:</p>
                                <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>The right to erasure</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>The right to restrict processing</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>The right to object processing</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>The right to complain to supervisory authority</p>
                                   </div>
                                   <div className="flex">
                                     <p className="num-li">●	</p>
                                     <p>The right to withdraw consent.</p>
                                   </div>
                                   </div>
                                <br/>
                                <div className="flex column">
                                <div className="flex">
                                  <h3 className="num-li">11.</h3>
                                  <h3>	ACKNOWLEDGMENT AND CONSENT</h3>
                                </div>
                                <p>By accepting the Privacy Policy, you acknowledge that you have read and understood this Privacy Policy and you accept all of its terms. In particular, you agree and consent to us collecting, using, disclosing, storing and processing your Personal Information in accordance with this Privacy Policy.</p>
                                </div>
                                <br/>
                                <div className="flex column">
                                <div className="flex">
                                  <h3 className="num-li" >12.</h3>
                                  <h3>CHANGES TO PRIVACY POLICY</h3>
                                </div>
                                <p>We will notify our users about changes to our Privacy Policy by posting such changes and notification on our website. However, if we change our Privacy Policy in a way that may result in the disclosure of Personally Identifiable Information held by Users previously requested not to be disclosed, We will contact such users to allow them to prevent such disclosure</p>
                           
                            
                            </div>
                       
                            
                           
                     </div>
                  </div>
                  </Fade>
                </div>
              </div>
             
              <ScrollToTop />
              <Footer />
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    about1: state.about,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAbout:() => {
      dispatch(getAbout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
