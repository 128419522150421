import React, { Component } from 'react'
import kt_white from "../images/kt_white.svg"
import axios from 'axios'
import {API} from '../_redux/helper'
import {required} from "../_redux/function"
import apply_success from "../images/apply_success.svg"
import Modal from "react-awesome-modal"



export default class Footer_Homepage extends Component {
    constructor(){
        super()
        this.state={
            email:"",
            emailErr:"",
            success:false
        }
    }
    directLink=(e)=>{
        window.location.href=e
    }

    handleEmail=(e)=>{
        const regEmail =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let err_email = ""
        err_email=required(e)
        if (err_email !== ""  || String(e).toLowerCase().match(regEmail) === null){
            err_email= "invalid email address"
        }else{
            err_email=""
        }
        this.setState({email:e, emailErr:err_email})
    }

    postEmail=(e)=>{
        const {email,emailErr}= this.state
        if (emailErr !== ""){
            console.log("error");
        }else{
        const data ={ email:email}
        axios.post(`${API}/api/user/subscribe`,data)
        .then(res => {
            console.log(res.data);
            this.setState({success:true})
        }).catch(err => {
            console.log(err);
        })
    }
}
closeModal=(e)=>{
    this.setState({email:"", success:false})
}

    render() {
        let {email, emailErr} = this.state
        return (
            <React.Fragment>
                <footer className="footer bg-09">
                    <div className="footer-container wd-content ">
                        <div className="flex-mob tab_ width-100 space-between  mt-48 mb-48">
                            <div className='flex-mob tab-mb-48'>
                                <div className='flex column wd-292 mr-24'>
                                    <img className='flex mb-48 wd-250' src={kt_white} onClick={()=>this.directLink("/")}/>
                                    <h5 className='Kanit w-600 s-14 mb4 c-ff'>PT. Bina Sumberdaya Indonesia</h5>
                                    <p className='Kanit-400 s-14 c-ff let-02'>AXA Tower 37th Floor</p>
                                    <p className='Kanit-400 s-14 c-ff let-02'>Jl. Dr. Satrio Kav 18</p>
                                    <p className='Kanit-400 s-14 c-ff let-02'>Kuningan, Jakarta Selatan 12940</p>
                                    <p className='Kanit-400 s-14 c-ff let-02 '>021-30056125</p>
                                    <p className='Kanit-400 s-14 c-ff let-02'>021-30056124 ext 222 </p>
                                    <p className='Kanit-400 s-14 c-ff let-02'>sales.inquiry@karyatalents.com</p>
                                </div>
                                <div className='flex'>
                                    <div className='flex column wd-134 mr-24'>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/Job-Opportunity-Public")}>Jobs</p>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/Talents")}>Talent</p>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/Employer")}>Employer</p>
                                        {/* <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/")}>KT 300</p> */}
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/Article")}>Resources</p>
                                    </div>
                                    <div className='flex column'>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/About")}>About Us</p>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/FAQ")}>FAQ</p>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/ContactUs")}>Contact Us</p>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/Privacy")}>Privacy Policy</p>
                                        <p className='mb-10 let-02 Kanit-400 c-ff s-14 pointer' onClick={()=>this.directLink("/Terms-and-Conditions")}>Terms of Service</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex column wd-450'>
                                <h5 className='Kanit s-16 c-ff mb-15'>Sign Up for our Newsletter</h5>
                                <div className='br-21 flex-end bg-69 hg-42 100% flex'>
                                    <div className='flex height-auto width-64 p-1014 out-none s-16' dangerouslySetInnerHTML={{ __html: email }}
                                     contentEditable onBlur={(e)=>this.handleEmail(e.currentTarget.textContent)}/>
                                    <div className='width-33 hg-100 flex bg-87 br-21 m-auto0 pointer' onClick={(e)=>this.postEmail(e)}>
                                        <p className='c-09 s-16 auto Kanit w-600'>Sign Up FREE</p>
                                    </div>
                                </div>
                                <p className='c-ff s-12 mt-5'>{emailErr}</p>
                                <div className='flex mt-80'>
                                    <div className='br-100 bg-ff'>
                                    </div>
                                    <a href="https://www.linkedin.com/company/karya-talents"   rel="noopener noreferrer">
                                    <svg className='mr-14' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM8.2 13.3V24H11.6V13.3H8.2ZM8 9.9C8 11 8.8 11.8 9.9 11.8C11 11.8 11.8 11 11.8 9.9C11.8 8.8 11 8 9.9 8C8.9 8 8 8.8 8 9.9ZM20.6 24H23.8V17.4C23.8 14.1 21.8 13 19.9 13C18.2 13 17 14.1 16.7 14.8V13.3H13.5V24H16.9V18.3C16.9 16.8 17.9 16 18.9 16C19.9 16 20.6 16.5 20.6 18.2V24Z" fill="white"/>
                                    </svg>
                                    </a>
                                    <a href="https://instagram.com/karyatalents?igshid=odlswb4q0a06"   rel="noopener noreferrer">
                                    <svg className='mr-14' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4 9.2H12.6C11.8 9.3 11.4 9.4 11.1 9.5C10.7 9.6 10.4 9.8 10.1 10.1C9.86261 10.3374 9.75045 10.5748 9.61489 10.8617C9.57916 10.9373 9.5417 11.0166 9.5 11.1C9.48453 11.1464 9.46667 11.1952 9.44752 11.2475C9.34291 11.5333 9.2 11.9238 9.2 12.6V19.4C9.3 20.2 9.4 20.6 9.5 20.9C9.6 21.3 9.8 21.6 10.1 21.9C10.3374 22.1374 10.5748 22.2495 10.8617 22.3851C10.9374 22.4209 11.0165 22.4583 11.1 22.5C11.1464 22.5155 11.1952 22.5333 11.2475 22.5525C11.5333 22.6571 11.9238 22.8 12.6 22.8H19.4C20.2 22.7 20.6 22.6 20.9 22.5C21.3 22.4 21.6 22.2 21.9 21.9C22.1374 21.6626 22.2495 21.4252 22.3851 21.1383C22.4209 21.0626 22.4583 20.9835 22.5 20.9C22.5155 20.8536 22.5333 20.8048 22.5525 20.7525C22.6571 20.4667 22.8 20.0762 22.8 19.4V12.6C22.7 11.8 22.6 11.4 22.5 11.1C22.4 10.7 22.2 10.4 21.9 10.1C21.6626 9.86261 21.4252 9.75045 21.1383 9.61488C21.0627 9.57918 20.9833 9.54167 20.9 9.5C20.8536 9.48453 20.8048 9.46666 20.7525 9.44752C20.4667 9.3429 20.0762 9.2 19.4 9.2ZM16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM21.4 11.6C21.4 12.1523 20.9523 12.6 20.4 12.6C19.8477 12.6 19.4 12.1523 19.4 11.6C19.4 11.0477 19.8477 10.6 20.4 10.6C20.9523 10.6 21.4 11.0477 21.4 11.6Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM12.6 7.7H19.4C20.3 7.8 20.9 7.9 21.4 8.1C22 8.4 22.4 8.6 22.9 9.1C23.4 9.6 23.7 10.1 23.9 10.6C24.1 11.1 24.3 11.7 24.3 12.6V19.4C24.2 20.3 24.1 20.9 23.9 21.4C23.6 22 23.4 22.4 22.9 22.9C22.4 23.4 21.9 23.7 21.4 23.9C20.9 24.1 20.3 24.3 19.4 24.3H12.6C11.7 24.2 11.1 24.1 10.6 23.9C10 23.6 9.6 23.4 9.1 22.9C8.6 22.4 8.3 21.9 8.1 21.4C7.9 20.9 7.7 20.3 7.7 19.4V12.6C7.8 11.7 7.9 11.1 8.1 10.6C8.4 10 8.6 9.6 9.1 9.1C9.6 8.6 10.1 8.3 10.6 8.1C11.1 7.9 11.7 7.7 12.6 7.7Z" fill="white"/>
                                    </svg>
                                    </a>
                                    <a href='https://web.facebook.com/KT300CLUB/?_rdc=1&_rdr'    rel="noopener noreferrer">
                                    <svg className='mr-14' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16 8C20.4 8 24 11.6 24 16C24 20 21.1 23.4 17.1 24V18.3H19L19.4 16H17.2V14.5C17.2 13.9 17.5 13.3 18.5 13.3H19.5V11.3C19.5 11.3 18.6 11.1 17.7 11.1C15.9 11.1 14.7 12.2 14.7 14.2V16H12.7V18.3H14.7V23.9C10.9 23.3 8 20 8 16C8 11.6 11.6 8 16 8Z" fill="white"/>
                                    </svg>
                                    </a>
                                    <a href='https://www.youtube.com/channel/UCAXhjK2OivKsLeYPVNMsLHA'    rel="noopener noreferrer">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.6 16L14.4 13.6V18.4L18.6 16Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM22.2 10.7C22.9 10.9 23.4 11.4 23.6 12.1C24 13.4 24 16 24 16C24 16 24 18.6 23.7 19.9C23.5 20.6 23 21.1 22.3 21.3C21 21.6 16 21.6 16 21.6C16 21.6 10.9 21.6 9.7 21.3C9 21.1 8.5 20.6 8.3 19.9C8 18.6 8 16 8 16C8 16 8 13.4 8.2 12.1C8.4 11.4 8.90001 10.9 9.60001 10.7C10.9 10.4 15.9 10.4 15.9 10.4C15.9 10.4 21 10.4 22.2 10.7Z" fill="white"/>
                                    </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
                <Modal id="success" visible={this.state.success} effect="fadeInUp">
                <div  className="modal_apply">
                    <div className="width-100 flex center">
                    <img loading="lazy" alt="" src={apply_success} style={{marginTop:"24px", marginBottom:"24px"}}/>
                    </div>
                    <h5>Subscribe Succsessfully!</h5>
                    <p  style={{width:"90%", marginLeft:"5%"}}>Thank you for signing up for our newsletter!</p>
                    <div className="width-100 flex center">
                        <button onClick={(e)=>{ this.closeModal(e)}} style={{marginBottom:"20px"}}>
                            <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                        </button>
                    </div>
                </div>
            </Modal>
            </React.Fragment>
        )
    }
}
