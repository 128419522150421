import React, { Component } from "react";
import logo from "../images/KaryaTalents_logo.png";
import { connect } from "react-redux";
import SidebarMiddle from "../components/SidebarMiddle";
import bell from "../images/bell.png";
import v from "../images/v.png";
import help from "../images/help-icon.png";
import setting from "../images/setting-icon.png";
import logout from "../images/logout-icon.png";
import { API } from "../_redux/helper";
import {getProfileId} from "../_actions/user";
import {  fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import iOpenSidebar from "../images/iOpenSidebar.png";
import iDashboard from "../images/iDashboard.png";
import iVIP from "../images/iVIP.svg";
import iCareer from "../images/iCareer.png";
import iReferral from "../images/iReferral.png";
import iWork from "../images/iWork.png";
import iMyProfile from "../images/iMyprofile.png";
import iLearning from "../images/iLearning.png";
import iTraining from "../images/iTraining.png";
import closeSidebar from "../images/closeSidebar.svg"
import Zoom from 'react-reveal/Zoom';
import _JobOpportunities from "../components/_ValuesProfiler"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Session from "../components/Session"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';

const styles = {
  fadeInDown: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
    marginTop: "-86px",
  },
};

class NewHeader extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
      var_report: sessionStorage.getItem("report"),
      visible: true,
      country_id:"",
      city_id:"",
      job_level_id:"",
      industry_id:"",
      job_function_id:"",
      job_title:"",
      job_type:"",
      salary_from:"",
      salary_to:"",
      height:"",
      value5: {
          min: 0,
          max: 100000000,
        },
    };
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.setState({height:window.innerHeight})
    // this.props.jobByTitle(user_id, this.state.job_title, this.state.country_id, this.state.city_id, this.state.job_function_id, this.state.industry_id, this.state.job_level_id,
    //   this.state.salary_from,this.state.salary_to) 
    window.scrollTo(0, 0)
  }

  changeVisible = () => {
    this.setState({ visible: !this.state.visible });
  };
  away = () => {
    this.setState({ visible: true });
  };

  logout=()=>{
    Session.removeSessionLogout()
  }
  
  changeBackground = () => {
    // this.setState({
    //   link: !this.state.link,
    // });
    // sessionStorage.setItem("sidebar",!this.state.link)
    this.props.history.action = this.state.link
   
  };


  render() {
    const { profile  } = this.props.profile1;
    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }
  
    const nama = '' + nickname
    const name = nama.split(" ")[0]

   
    
  

    return (
      <div>
        <div className="block HC-pages" style={{top:"0px"}}>
          <div className="NewHeader" style={{position:"unset", top:"0px"}}>
            <div className="flex column">
              <div className="flex box header_shadow HC space-between" style={{backgroundColor:"white", width:"100%", position:"fixed", zIndex:"99"}}>
                <div>{/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={number1} /> */}</div>
                <div>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="logo" />
                </div>
                <div className="flex">
                  <div style={{display:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bell} className="bell" />
                    <div className="notif">
                      <p>90</p>
                    </div>
                  </div>
                  <div className="avatar">
                  {profile.photo &&
                    <LazyLoadImage effect="blur"
                      src={`${API}/File/Profile/${profile.photo}`}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "100%",
                        cursor:"pointer"
                      }} onClick={this.changeVisible}
                    />}
                  </div>
                  <p className="name" style={{cursor:"pointer"}} onClick={this.changeVisible}>{name}</p>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={v} className="popup" onClick={this.changeVisible} style={{cursor:"pointer"}}/>
                </div>
              </div>
              <StyleRoot>
                <div
                  className="popup_settings"
                  style={{ display: this.state.visible ? "none" : "flex" }}
                >
                  <div className="mini_box" style={styles.fadeInDown}>
                    <div className="flex column">
                      <div className="flex pad_box" onClick={this.away} style={{display:"none"}}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} />
                        <p>Settings</p>
                      </div>
                      <div className="flex pad_box" onClick={this.away} style={{display:"none"}}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={help} />
                        <p>Help</p>
                      </div>
                      <div className="flex pad_box" onClick={this.logout}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logout} />
                        <p style={{ color: "red" }}>Logout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </StyleRoot>
            </div>
            <div className="flex">
            <div style={{ marginTop: "0px" }}>

            <div>
            <div style={{ height: "87px", width: "100%" , backgroundColor:"red",marginTop:"100px", display:"none"}}  onClick={this.changeBackground}>

                  <LazyLoadImage effect="blur"
                    src={iOpenSidebar}
                    style={{
                      width: "24px",
                      height: "18px",
                      margin: "34px auto",
                      display : this.state.link? "none":"block"
                    }}
                    onClick={this.changeBackground}
                  />
                
                </div>
        <StyleRoot>
          <div className="flex" style={styles.zoomIn}>
            {/* <div className="bigSidebar" style={{width: this.state.link ? "296px": "unset"}}> */}
            {/* <Zoom  when={this.state.link}> */}
            <div
              className="bigSidebar HC"
              style={{ width: this.state.link ? "255px" : "76px",height:"100%" }}
            >
              <div className="flex column">
                <div style={{ height: "87px", width: "100%" }}>
                  <LazyLoadImage effect="blur"
                    src={iOpenSidebar} className="flex a_28"
                    style={{
                      width: "24px",
                      height: "18px",
                      margin: "34px auto",
                      display : this.state.link? "none":"block"
                    }}
                    onClick={this.changeBackground}
                  />
                  <LazyLoadImage effect="blur"
                    src={closeSidebar} className="none"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "34px",
                      marginLeft: "32px",
                      display : this.state.link? "block":"none"
                    }}
                    onClick={this.changeBackground}
                  />
                </div>
                <Link to="/NewDashboard">
                <div className="flex hov" title={this.state.link ?"":"Dashboard"}>
                <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iDashboard} className="bullet"  />
                  </Zoom>
                  <Zoom >
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    Dashboard
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link to="/MyProfile">
                <div className="flex hov" title={this.state.link ?"":"My Proflile"}>
                <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iMyProfile} className="bullet" />
                  </Zoom>
                  <Zoom  >
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    My Profile
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link to="/JobOpportunities">
                <div className="flex hov"  title={this.state.link ?"":"Job Opportunities"}>
                <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iCareer} className="bullet" />
                  </Zoom>
                  <Zoom >
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Job Opportunities
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link to="/Referral">
                <div className="flex hov"  title={this.state.link ?"":"Referral"} >
                <Zoom >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iReferral} className="bullet" />
                </Zoom>
                <Zoom>
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    Referral
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link to="/work_assessment">
                  <div className="flex hov" title={this.state.link ?"":"Assessments"}>
                    <Zoom >
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="bullet" />
                    </Zoom>
                   
                      <div className="flex column">
                        <div className="new-ntf a" style={{ display: this.state.link ? "none" : "flex" }}> <span className="openSans-Bold" >New</span></div>
                        <Zoom>
                      <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                        Assessments
                      </p>
                      </Zoom>
                      <Zoom>
                      <div className="new-ntf b" style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >New</span></div>
                      </Zoom>
                      </div>
                  </div>
                </Link>
                <Link to="/DigitalLearningPlatform" >
                  <div className="flex hov" title={this.state.link ?"":"Digital Learning Platform"}>
                  <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="bullet" />
                  </Zoom>
                    <div className="flex column">
                      <div className="new-ntf a" style={{ display: this.state.link ? "none" : "flex" }}> <span className="openSans-Bold" >BETA</span></div>
                      <Zoom>
                    <p style={{ display: this.state.link ? "block" : "none" }}>
                      Digital Learning Platform
                    </p>
                    </Zoom>
                    <Zoom>
                    <div className="new-ntf b" style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >BETA</span></div>
                    </Zoom>
                    </div>
                  </div>
                </Link>
                <Link to="/CareerCenter">
                <div className="flex hov" title={this.state.link ?"":"Career Center"}>
                <Zoom >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iWork} className="bullet" />
                </Zoom>
                <Zoom>
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    Career Center
                  </p>
                  </Zoom>
                </div>
                </Link>
                <div className="flex hov" style={{display:"none"}}>
                <Zoom >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iTraining} className="bullet" />
                </Zoom>
                <Zoom>
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Training Marketplace
                  </p>
                  </Zoom>
                </div>
              </div>
            </div>
            {/* </Slide> */}
            {/* <div className="rightSidebar" style={{ display: this.state.link ? "block": "none"}}>
            <div></div>
          </div> */}
          </div>
          </StyleRoot>
        </div>
            </div>
            {/* CONTENT */}
            <div className="cubo width-100" >
              <div className="block">
              < SidebarMiddle />
              </div>
              <_JobOpportunities/>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
