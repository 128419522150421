import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, PDFDownloadLink } from '@react-pdf/renderer';
import conv from "../../images/conv1.png"
import real from "../../images/real1.png"
import inves from "../../images/inves1.png"


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    // backgroundColor: '#f2f2f2',
    backgroundColor: '#ffffff',
    paddingTop:"30px",
    height:"1200px",
    maxHeight:"800px",
    paddingBottom:"0px",
    marginBottom:"0px"
  },
  section: {
    marginBottom: "14px",
  },
  section3: {
    width:"100%",
    display:"flex",
    justifyContent:"center",
    paddingLeft:"14px",
    paddingRight:"14px"
  },
  section2: {
    backgroundColor: 'white',
    border:"1px solid #bdbdbd",
    borderRadius:"10px",
    padding:"5px",
    width:"100%",
    margin:"auto"
  },
   title:{
    color:"rgb(59, 108, 152)",
    fontWeight:"Bold",
    fontSize:"30",
    marginBottom:"25px",
    marginTop:"10px",
    marginLeft:"14px"
  },
});
;

// Create Document Component
const  MyDocument = (props) => {
    const a= props.data
    const b= props.result
    const images = [conv, inves,real]
   
    return(
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>My Assessment</Text>
      </View>
      <View style={styles.section3}>
        <View style={styles.section2}>
          <Text style={{color:"#3b6c98", fontSize:"20px", fontWeight:"extrabold", width:"100%", borderBottom:"1px solid #e0e0e0", padding:"12px 0px 12px 0px"}}>My Work Interest Assessment Result</Text>
          <View style={{display:"flex", width:"100%", justifyContent:"space-between",marginTop:"20px", flexDirection:"row"}}>
            <View style={{display:"flex", flexDirection:"column", width:"43%"}}>
                {a.map((item,i)=>{if (1==0){return(
            <Text style={{fontSize:"18px",color:"#292929"}}>{item.name}</Text>)}})}
            <View style={{display:"flex", marginTop:"10px", width:"100%", flexDirection:"row"}}>
                {a.map((item,i)=>(
              <Text key={i} style={{padding:"5px",fontSize:"10.5px",color:"white", backgroundColor:`${item.color}` ,border:`1px solid ${item.color}`,borderRadius:"5px",display:"flex", width:"80px", justifyContent:"center",textAlign:"center", marginRight:"5px"}}>
                {item.interest_name}</Text>))}
            </View>
            </View>
            <View style={{display:"flex", flexDirection:"column",width:"52%", marginBottom:"50px"}}>
                {b.map((item,i)=>(
              <View key={i} style={{display:"flex", justifyContent:"flex-end", flexDirection:"row", margin:"8.5px 0px"}}>
                <Text style={{color:"#292929", marginRight:"5px", fontSize:"11px"}}>{item.interest_name} </Text>
                <View style={{height:"12px", width:"215px", backgroundColor:"#e9ecef",borderRadius:"8px"}}>
                  <View style={{height:"100%", width:`${parseInt(item.scoring)*4}%`,backgroundColor:`${item.color}`, borderRadius:"8px"}}>
                    <View style={{width:"26px", height:"26px", backgroundColor:`${item.color}`,right:"-5px",borderRadius:"100%", textAlign:"center",alignItems:"center", border:"3px solid #ffffff", position:"absolute", marginTop:"-7px",display:"flex",justifyContent:"center"}}>
                      <Text style={{color:"white", fontSize:"11px", margin:"auto",alignItems:"center"}}>{`${parseInt(item.scoring)*4}`}</Text>
                    </View>
                  </View>
                </View>
              </View>))}

            </View>
          </View>
          <View style={{width:"100%", display:"flex", flexDirection:"row",justifyContent:"space-between", marginTop:"70px"}}>
            {a.map((item,i)=>(
            <View key={i} style={{display:"flex", flexDirection:"column", width:"32%", border:"1px solid #e0e0e0", backgroundColor:"white", borderRadius:"10px"}}>
                <View style={{width:"50px",height:"50px", borderRadius:"100%", backgroundColor:`${item.color}`, border:`1px solid ${item.color}`,
                 display:"flex",justifyContent:"center", position:"absolute", marginLeft:"14px",marginTop:"-30px"}}>
                  <Image src={images[i]} style={{width:"25px",height:"25px",margin:"auto", display:"flex"}} />
                </View>
                <View style={{paddingLeft:"14px",borderBottom:"1px solid #e0e0e0", paddingBottom:"8px"}}>
               <Text style={{borderRadius:"10px", marginTop:"20px", fontSize:"20px", color:`${item.color}`, marginBottom:"8px"}}>{item.interest_name} </Text>
               <Text style={{fontSize:"12px", color:`${item.color}`}}>{item.interest_type} </Text>
               </View>
               <Text style={{fontSize:"12px", color:"#292929", padding:"14px", backgroundColor:"#f8f8f8", borderBottomRightRadius:"10px", borderBottomLeftRadius:"10px", minHeight:"180px"}}>
                {item.interest_description}
               </Text>
            </View>))}
          </View>
        </View>
      </View>
	  <View>
	  </View>
    </Page>
  </Document>
)}

export default MyDocument;