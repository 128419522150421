import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import bell from "../images/bell.png";
import v from "../images/v.png";
import help from "../images/help-icon.png";
import setting from "../images/setting-icon.png";
import logout from "../images/logout-icon.png";
import { API } from "../_redux/helper";
import logo from "../images/KaryaTalents_logo.png";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import Session from "../components/Session"
import profile_static from "../images/ProfilePicture.png";


const styles = {
    fadeInDown: {
      animation: "x .5s",
      animationName: Radium.keyframes(fadeIn, "fadeIn"),
      marginTop: "-86px",
    },
  };
class Account extends Component {
    constructor() {
        super();
        this.state = {
          user_id: "",
          link: JSON.parse(sessionStorage.getItem("sidebar")),
          var_report: sessionStorage.getItem("report"),
          var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
          visible: true,
          height:"",
          ratting:0,
        };
      }

    changeVisible = () => {
        this.setState({ visible: !this.state.visible });
      };  
      away = () => {
        this.setState({ visible: true });
      };
    
      logout=()=>{
        Session.removeSessionLogout()
      }

    render() {
        const profile = this.props.profile;
        let nickname
        if (profile.name==="" || profile.name===undefined){
          nickname=""
        }else{
          nickname=profile.name
        }
        const nama = '' + nickname
        const name = nama.split(" ")[0]
        
        return (
            <div>
                 <div className="flex column">
              <div className="flex box header_shadow HC space-between" style={{backgroundColor:"white", width:"100%", position:"fixed", zIndex:"99"}}>
                <div>{/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={number1} /> */}</div>
                <div>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="logo" />
                </div>
                <div className="flex">
                  <div style={{display:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bell} className="bell" />
                    <div className="notif">
                      <p>90</p>
                    </div>
                  </div>
                  <div className="avatar">
                  {profile.photo &&
                    <LazyLoadImage effect="blur" className='flex br-100 hg-100 wd-100 pointer cover'
                      src={`${API}/File/Profile/${profile.photo}`}
                      onError={(e)=>{e.target.src = profile_static}} onClick={this.changeVisible}
                    />}
                  </div>
                  <p className="name" style={{cursor:"pointer"}} onClick={this.changeVisible}>{name}</p>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={v} className="popup" onClick={this.changeVisible} style={{cursor:"pointer"}}/>
                </div>
              </div>
              <StyleRoot>
                <div
                  className="popup_settings"
                  style={{ display: this.state.visible ? "none" : "flex" }}
                >
                  <div className="mini_box" style={styles.fadeInDown}>
                    <div className="flex column">
                      <div className="flex pad_box" onClick={this.away} style={{display:"none"}}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} />
                        <p>Settings</p>
                      </div>
                      <div className="flex pad_box" onClick={this.away} style={{display:"none"}}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={help} />
                        <p>Help</p>
                      </div>
                      <div className="flex pad_box" onClick={this.logout}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logout} />
                        <p style={{ color: "red" }}>Logout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </StyleRoot>
            </div>
            </div>
        );
    }
}

export default Account;