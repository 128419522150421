import React, { Component } from 'react';
import report_bg from "../images/report_bg.svg"
import logo from "../images/KaryaTalents_logo.svg"
import crown_logo from "../images/crown_logo.png"
import wavy_left from "../images/wavy_left.svg"
import wavy_right from "../images/wavy_right.svg"
import ideal from "../images/ideal_env-icon.svg"
import area from "../images/report_area.svg"
import dev from "../images/report_dev.svg"
import icon_download from "../images/icon_download_report.svg"
import arrLeft from "../images/report_arrLeft.svg"
import arrRight from "../images/report_arrRight.svg"
import issue from "../images/report_issue.svg"
import report_admin from "../images/report_admin.svg"                                                                                            
import finance from "../images/report_finance.svg"
import green from "../images/report_check_green.svg"
import vip_mobile from "../images/vip_mobile.png"
import dataReport from "../components/DataReport.json"
import nhp_vip from "../images/std-profiler.png";
import icon_download_white from '../images/icon_download_white.svg';
import ScrollToTop from "../components/ScrollToTop";
import { Bar } from '@reactchartjs/react-chart.js'
import {GAevent} from '../components/TrackingEvent'
import { PDFExport } from "@progress/kendo-react-pdf";
import {getProfileId} from "../_actions/user";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import Modal from "react-awesome-modal";
import closemodal from "../images/closemodal.png";
import Rating from '@material-ui/lab/Rating';
import dlc_close from "../images/dlc_close.svg"
import Bullet from "../images/Bullet.png"
import Clipboard from 'react-clipboard.js';
// FOR PARAMS
import report_typical from "../images/report_typical.svg"
import report_struggle from "../images/report_struggle.svg"
import axios from "axios";
import {API, url_copy} from '../_redux/helper'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';





class componentName extends Component {
    constructor(){
        super()
        this.state={
            user_id:"",
            width:"",
            res:"",
            name:"",
            nconscientious:"",
            ndirecting:"",
            ninteracting:"",
            nsupportive:"",
            ncategory:"",
            def_directing:"",
            def_conscientious:"",
            def_interacting:"",
            def_supportive:"",
            label:"",
            date_report:"",
            report:"",
            name:"",
            visible:true,
            visible2:true,
            login: false,
            register: false,
            forgotPassword: false,
            copied:0,
            show_modal:false,
            ratting:0,
            desc_ratting:"",
            comment:"",
            status_personality:"",
            status:"",
            rating:"",
            rate:0,
            desc:"",
            link:"",
            urlCopy:"http://kerjakarya.careeya.net/getStarted_Stand_Alone",
        }
    }

    async componentDidMount() {
        ReactPixel.track(window.location.pathname);
        await axios
        .get(`${API}/api/v1/survey/findsubmission/${sessionStorage.getItem("id-std")}`)
        .then(response => {
            sessionStorage.setItem("report", 17);
        this.setState({name:response.data.name})
        this.setState({res:response.data.analysis})
        this.setState({name:response.data.name})
        this.setState({nconscientious:response.data.analysis.metrics.conscientious})
        this.setState({ndirecting:response.data.analysis.metrics.directing})
        this.setState({ninteracting:response.data.analysis.metrics.interacting})
        this.setState({nsupportive:response.data.analysis.metrics.supportive})
        this.setState({nsupportive:response.data.analysis.metrics.supportive})
        this.setState({ncategory:response.data.analysis.modules.PIDProfile.match})
        this.setState({def_directing:response.data.analysis.modules.PIDProfile.profile.metrics.directing})
        this.setState({def_conscientious:response.data.analysis.modules.PIDProfile.profile.metrics.conscientious})
        this.setState({def_interacting:response.data.analysis.modules.PIDProfile.profile.metrics.interacting})
        this.setState({def_supportive:response.data.analysis.modules.PIDProfile.profile.metrics.supportive})
        this.setState({label:response.data.analysis.modules.PIDProfile.profile.label})
        this.setState({date_report:response.data.updatedAt})
        this.setState({report:this.state.ncategory ? dataReport.profile[this.state.ncategory].single : ""})
        })
        this.setState({width:window.window.outerWidth})
      }

    // exportPDFWithComponent = () => {
    //     this.pdfExportComponent.save();
    //     GAevent('Report PID','Download PDF') 
    //   };

      exportPDFWithComponent = () => {
        this.pdfExportComponent.save();
        GAevent('Report PID','Download PDF') 
        this.setState({visible:false})
        setTimeout(() => {
            window.location.reload()
        }, 2500);
      };

      exportPDFWithComponentMobile = () => {
        this.pdfExportComponent.save();
        GAevent('Report PID','Download PDF') 
        this.setState({visible2:false})
        setTimeout(() => {
            window.location.reload()
        }, 2500);
      };


      back = () => {
        if (JSON.parse(sessionStorage.getItem("personality-std"))===true){
            this.setState({show_modal:true})
        }else{
            window.location.href="/"
        }
      };

      backToHomepage=()=>{
        window.location.href="/"
    }

      copyLink=(e)=>{
        //   const path = window.location.pathname
        //   const url = window.location.href
        //   const replace_path = url.replace(path,"/Karya_Personality_Profiler")
        //   navigator.clipboard.writeText(this.state.urlCopy)
          this.setState({copied:1})
      }
      closeCopied=()=>{
        this.setState({copied:0})
      }

       // RATING PERSONALITY PROFILER 
  closePopupPersonality() {
    const data={
      ratting: "-",
      comment: ""
  }
      axios.post(`${API}/api/survey/updateSubmissionRatting/${sessionStorage.getItem("id-std")}`, data).then((res)=>{
        this.setState({show_modal:false})
        sessionStorage.setItem("personality-std",false)
        sessionStorage.removeItem("personality-std")
        window.location.href="/"
    }).catch((err)=>{
        console.log(err,"error");
    })
  }


  submitRatingPersonality=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.rate,
      comment: this.state.comment
  }
    axios.post(`${API}/api/survey/updateSubmissionRatting/${sessionStorage.getItem("id-std")}`, data).then((res)=>{
        this.setState({show_modal:false})
        sessionStorage.setItem("personality-std",false)
        sessionStorage.removeItem("personality-std")
        window.location.href="/"
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  _onKeyDown=(e)=>{
    if (e.key === 'Enter') {
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.rate,
      comment: this.state.comment
  }
    axios.post(`${API}/api/survey/updateSubmissionRatting/${sessionStorage.getItem("id-std")}`, data).then((res)=>{
        this.setState({show_modal:false})
        sessionStorage.setItem("personality-std",false)
        sessionStorage.removeItem("personality-std")
        window.location.href="/"
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}
}

   // POPUP RATING START 
   changeRatting=(e, vals)=>{
    this.setState({ratting:Number((e.target.textContent).split('')[0])})
    let val
    if ((e.target.textContent).split('')[0]==1){
      val = "Very Bad"
    }else if ((e.target.textContent).split('')[0]==2){
      val = "Bad"
    }else if ((e.target.textContent).split('')[0]==3){
      val = "Need Improvement"
    }else if ((e.target.textContent).split('')[0]==4){
      val = "Good"
    }else{
      val = "Very Good"
    }
    this.setState({desc_ratting:val})
    this.state.rate = this.state.ratting
    this.state.desc = this.state.desc_ratting
  }

  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }
    
  handleCallback = (childData) =>{
    this.setState({link: childData})
}

    render() {
    // PARAMS
        const numb = 2
        const res=[]


     
        let qweasd 
        var testing
        let label

        let date_report
        let dataDimensionOne
        let dataDimensionTwo
        let dataDimensionThree
        let dataDimensionFour

   
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);

        let user_id_params
        if (decode1=="" || decode1== null || decode1==undefined){
            user_id_params=""
        }else{
            user_id_params=decode1["user_id"];
        }

  
            if (this.state.ndirecting < 40){
                dataDimensionOne = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleDirecting.low.desc}</h5>
                                            <div className="flex width-100 columnMobile">
                                                <div className="dimension-three-left">
                                                    <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                        <h6>{dataReport.components.metrics.singleDirecting.low.strengths.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.strengths[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.strengths[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.strengths[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.strengths[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.strengths[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.strengths[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.strengths[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dimension-three-right">
                                                <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                        <h6>{dataReport.components.metrics.singleDirecting.low.struggles.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.struggles[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.struggles[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.struggles[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.struggles[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.struggles[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.struggles[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleDirecting.low.struggles[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
            }else if (this.state.ndirecting >= 40 && this.state.ndirecting <= 60){
                dataDimensionOne = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleDirecting.medium[1]}</h5>
                                        <div className="width-100 dekstop">
                                            <div className="flex width-100">
                                                <div className="width-48" style={{marginRight:"2%"}}>
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleDirecting.medium[2]}</p>
                                                </div>
                                                <div className="width-48" style={{marginLeft:"2%"}}>
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleDirecting.medium[3]}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="width-100 mobile">
                                            <div className="flex width-100 column">
                                                <div className="width-100" >
                                                    <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleDirecting.medium[2]}</p>
                                                </div>
                                                <div className="width-100" >
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleDirecting.medium[3]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            }else{
                dataDimensionOne = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleDirecting.high.desc}</h5>
                                        <div className="flex width-100 columnMobile">
                                            <div className="dimension-three-left">
                                                <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                    <h6>{dataReport.components.metrics.singleDirecting.high.strengths.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.strengths[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.strengths[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.strengths[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.strengths[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.strengths[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.strengths[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.strengths[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dimension-three-right">
                                            <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                    <h6>{dataReport.components.metrics.singleDirecting.high.struggles.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.struggles[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.struggles[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.struggles[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.struggles[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.struggles[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.struggles[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleDirecting.high.struggles[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
            }
            if (this.state.ninteracting < 40){
                dataDimensionTwo = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleInteracting.low.desc}</h5>
                                            <div className="flex width-100 columnMobile">
                                                <div className="dimension-three-left">
                                                    <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                        <h6>{dataReport.components.metrics.singleInteracting.low.strengths.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.strengths[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.strengths[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.strengths[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.strengths[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.strengths[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.strengths[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.strengths[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dimension-three-right">
                                                <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                        <h6>{dataReport.components.metrics.singleInteracting.low.struggles.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.struggles[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.struggles[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.struggles[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.struggles[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.struggles[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.struggles[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleInteracting.low.struggles[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
            }else if (this.state.ninteracting >= 40 && this.state.ninteracting <= 60){
                dataDimensionTwo = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleInteracting.medium[1]}</h5>
                                        <div className="width-100 dekstop">
                                            <div className="flex width-100">
                                                <div className="width-48 " style={{marginRight:"2%"}}>
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleInteracting.medium[2]}</p>
                                                </div>
                                                <div className="width-48" style={{marginLeft:"2%"}}>
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleInteracting.medium[3]}</p>
                                                </div>
                                            </div>
                                            </div>
                                        <div className="width-100 mobile">
                                            <div className="flex width-100 column">
                                                <div className="width-100" >
                                                    <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleInteracting.medium[2]}</p>
                                                </div>
                                                <div className="width-100" >
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleInteracting.medium[3]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            }else{
                dataDimensionTwo = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleInteracting.high.desc}</h5>
                                        <div className="flex width-100 columnMobile">
                                            <div className="dimension-three-left">
                                                <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                    <h6>{dataReport.components.metrics.singleInteracting.high.strengths.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.strengths[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.strengths[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.strengths[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.strengths[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.strengths[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.strengths[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.strengths[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dimension-three-right">
                                            <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                    <h6>{dataReport.components.metrics.singleInteracting.high.struggles.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.struggles[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.struggles[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.struggles[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.struggles[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.struggles[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.struggles[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleInteracting.high.struggles[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
            }


            if (this.state.nsupportive < 40){
                dataDimensionThree = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleSupportive.low.desc}</h5>
                                            <div className="flex width-100 columnMobile">
                                                <div className="dimension-three-left">
                                                    <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                        <h6>{dataReport.components.metrics.singleSupportive.low.strengths.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.strengths[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.strengths[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.strengths[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.strengths[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.strengths[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.strengths[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.strengths[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dimension-three-right">
                                                <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                        <h6>{dataReport.components.metrics.singleSupportive.low.struggles.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.struggles[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.struggles[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.struggles[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.struggles[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.struggles[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.struggles[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleSupportive.low.struggles[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
            }else if (this.state.nsupportive >= 40 && this.state.nsupportive <= 60){
                dataDimensionThree = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleSupportive.medium[1]}</h5>
                                        <div className="width-100 dekstop">
                                        <div className="flex width-100">
                                            <div className="width-48 flex column" style={{marginRight:"2%"}}>
                                                <p className="openSans align-left">{dataReport.components.metrics.singleSupportive.medium[2]}</p>
                                            </div>
                                            <div className="width-48" style={{marginLeft:"2%"}}>
                                                <p className="openSans align-left">{dataReport.components.metrics.singleSupportive.medium[3]}</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="width-100 mobile">
                                            <div className="flex width-100 column">
                                                <div className="width-100" >
                                                    <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleSupportive.medium[2]}</p>
                                                </div>
                                                <div className="width-100" >
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleSupportive.medium[3]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            }else{
                dataDimensionThree = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleSupportive.high.desc}</h5>
                                        <div className="flex width-100 columnMobile">
                                            <div className="dimension-three-left">
                                                <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                    <h6>{dataReport.components.metrics.singleSupportive.high.strengths.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.strengths[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.strengths[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.strengths[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.strengths[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.strengths[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.strengths[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.strengths[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dimension-three-right">
                                            <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                    <h6>{dataReport.components.metrics.singleSupportive.high.struggles.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.struggles[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.struggles[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.struggles[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.struggles[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.struggles[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.struggles[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleSupportive.high.struggles[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
            }


            if (this.state.nconscientious < 40){
                dataDimensionFour = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleConscientious.low.desc}</h5>
                                            <div className="flex width-100 columnMobile">
                                                <div className="dimension-three-left">
                                                    <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                        <h6>{dataReport.components.metrics.singleConscientious.low.strengths.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.strengths[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.strengths[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.strengths[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.strengths[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.strengths[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.strengths[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.strengths[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dimension-three-right">
                                                <div className="flex width-100">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                        <h6>{dataReport.components.metrics.singleConscientious.low.struggles.title}</h6>
                                                    </div>
                                                    <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.struggles[1]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.struggles[2]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.struggles[3]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.struggles[4]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.struggles[5]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.struggles[6]}</p>
                                                        </div>
                                                        <div className="flexCheck">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                            <p>{dataReport.components.metrics.singleConscientious.low.struggles[7]}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
            }else if (this.state.nconscientious >= 40 && this.state.nconscientious <= 60){
                dataDimensionFour = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleConscientious.medium[1]}</h5>
                                        <div className="width-100 dekstop">
                                        <div className="flex width-100">
                                            <div className="width-48 flex column" style={{marginRight:"2%"}}>
                                                <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[2]}</p>
                                                <p className="openSans align-left">{dataReport.components.metrics.singleConscientious.medium[3]}</p>
                                            </div>
                                            <div className="width-48 flex column" style={{marginLeft:"2%"}}>
                                                <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[4]}</p>
                                                <p className="openSans align-left">{dataReport.components.metrics.singleConscientious.medium[5]}</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="width-100 mobile">
                                            <div className="flex width-100 column">
                                                <div className="width-100" >
                                                    <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[2]}</p>
                                                </div>
                                                <div className="width-100" >
                                                    <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[3]}</p>
                                                </div>
                                                <div className="width-100" >
                                                    <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[4]}</p>
                                                </div>
                                                <div className="width-100" >
                                                    <p className="openSans align-left">{dataReport.components.metrics.singleConscientious.medium[5]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            }else{
                dataDimensionFour = <div className="width-100 flex column">
                                        <h5 className="openSans">{dataReport.components.metrics.singleConscientious.high.desc}</h5>
                                        <div className="flex width-100 columnMobile">
                                            <div className="dimension-three-left">
                                                <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                    <h6>{dataReport.components.metrics.singleConscientious.high.strengths.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.strengths[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.strengths[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.strengths[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.strengths[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.strengths[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.strengths[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.strengths[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dimension-three-right">
                                            <div className="flex width-100">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                    <h6>{dataReport.components.metrics.singleConscientious.high.struggles.title}</h6>
                                                </div>
                                                <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.struggles[1]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.struggles[2]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.struggles[3]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.struggles[4]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.struggles[5]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.struggles[6]}</p>
                                                    </div>
                                                    <div className="flexCheck">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                        <p>{dataReport.components.metrics.singleConscientious.high.struggles[7]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
            }


    const val_day = new Date(this.state.date_report).getDay()
    const val_date = new Date(this.state.date_report).getDate()
    const val_month = new Date(this.state.date_report).getMonth()
    const val_year= new Date(this.state.date_report).getFullYear()
    const val_Hours= new Date(this.state.date_report).getHours()
    const val_Minutes= new Date(this.state.date_report).getMinutes()

    const day = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    let Hour 
    if (val_Hours >= 13){
        Hour = ((val_Hours - 12) +":"+ val_Minutes +" PM")
    }else{
        Hour = (val_Hours  +":"+ val_Minutes + " AM")
    }

    const date_PID = (day[val_day] +", "+ month[val_month]  +" "+ val_date+", "+ val_year +"  "+ Hour )

    const format1 = new Date(`${this.state.date_report}`).getDay()
    const { profile } = this.props.profile1;
    const nama = '' + this.state.name
    const nick_name = nama.split(" ")[0]
    const nick_name2 = nick_name.charAt(0).toUpperCase() + nick_name.slice(1)
    const nameReport = this.state.name.split(" ")[0]

    let regText = /__first_name__/gi
    let regProfile = /__profile__/gi
    let text = `${this.state.report.suggestionsForManagersCopy}`
    let suggestionsForManagers
    if (text.indexOf('__first_name__')!= -1){
        suggestionsForManagers = text.replace(regText,nameReport)
    }else{
        suggestionsForManagers = text.replace(regProfile, this.state.label)
    }


    // DATA FOR CHART
    var options = {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 20,
              max: 80,
              borderColor:green,
              border: 'red',
            },
            gridLines: {
                display: true,
                drawBorder: false,
            }   
          }],
          xAxes: [{
            ticks: { display: true },
            gridLines: {
                display: true,
                drawBorder: true
            }
        }]
        }
      };
      
      const data = {
        labels: [' ', ' ', '', ' '],
        datasetIndex:['0','1','2','3','4','5','6'],
        datasets: [
          {
            type: 'line',
            label: `${this.state.label}`,
            borderColor: '#828282',
            borderWidth: 2,
            fill: false,
            data: [this.state.def_directing, this.state.def_interacting, this.state.def_supportive,this.state.def_conscientious  ],
            beginAtZero:true,
            display:false,
          },
          {
            type: 'line',
            label: `${nameReport}`,
            borderColor: '#3B6C98',
            borderWidth: 2,
            fill: false,
            data: [this.state.ndirecting, this.state.ninteracting,this.state.nsupportive,this.state.nconscientious ],
          },
          
        ],
      }

        return (
            <div>
                <div className='flex box header_shadow_VIP center' style={{backgroundColor: 'white',width: '100%',position: 'fixed',zIndex: '9999',top:"0px"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className='logo' onClick={this.backToHomepage}/>
                </div>
                <div className="flex width-100 center profiler-std" style={{marginTop:"80px",backgroundColor: 'white',width: '100%'}}>
                    <div className="flex width-80 space-between">
                        <div className="dekstop width-100 wd-img">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={nhp_vip} className="img-std-pid"  />
                        </div>
                        <div className="none width-100">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={vip_mobile} className="img-std-pid"  />
                        </div>
                        <div className="flex column auto-std wd-ct"  >
                            <div className="flex width-100 column col-std">
                                <p className="text">If you enjoy our Personality Profiler, take our free VIP  Profiler to get more comprehensive insights about you and your career</p>
                                <button onClick={() => window.location.href="/signup"}>
                                    <p>Sign Up</p>
                                </button>
                            </div>
                            <div className="line-std flex width-100" />
                            <div className="flex width-100 column col-std">
                                <p className="text">Invite your friends to experience the journey of self-awareness through knowing your personality</p>
                                <div className="flex width-100 col-std-copied">
                                <Clipboard data-clipboard-text={`${url_copy}`}  onClick={this.copyLink}>
                                        {/* <button   onClick={this.copyLink}> */}
                                            <p style={{color:"#FFFFFF"}} >{"Copy Invite Link"}</p>
                                        {/* </button> */}
                                    </Clipboard>
                                    <div  className="none copied" style={{display:this.state.copied===1?"flex":"none"}} >
                                        <LazyLoadImage effect="blur" src={Bullet} className="checked" />
                                        <p >{"Copied to clipboard"}</p>
                                        <LazyLoadImage effect="blur" src={closemodal} className="closeModal"  onClick={this.closeCopied} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               

                <div className="page-container report_Profiler static std-pp width-100" style={{marginTop:"48px"}}>
                    <div className="page-container-outer">
                        <div className="width-100 dekstop">
                        <div className='flex width-100 space-between'>
                            <div className='VIP_back' onClick={this.back} style={{ cursor: 'pointer'}} >
                            <p >{'<'} Back to Homepage</p>
                            </div>
                            <div className='VIP_back' style={{ backgroundColor: '#3B6C98', cursor: 'pointer' }}
                            onClick={this.exportPDFWithComponent}>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download_white}  />
                            <p style={{ color: 'white' }}>&nbsp; Download</p>
                            </div>
                        </div>
                        <div className="flex width-100 space-between title_Profiler" style={{display:"none"}}>
                            <h1>Personality Profiler</h1>
                            <Link to="/PID_Questions"  style={{width:"0px"}}>
                            <button style={{display:"none"}}>
                                <p style={{display:"none"}}>Take New Assessment</p>
                            </button>
                            </Link>
                        </div>
                        </div>
                        <div className="width-100 mobile" >
                            <div className='VIP_back' onClick={this.back} style={{ cursor: 'pointer'}} >
                            <p >{'<'} Back to Homepage</p>
                            </div>
                            <div className="flex width-100  title_Profiler column" style={{marginTop:"15px",display:"none"}}>
                                <h1 className="width-100 flex"style={{display:"none"}}> Personality Profiler</h1>
                                <Link to="/PID_Questions" style={{display:"none"}}>
                                <button>
                                    <p>Take New Assessment</p>
                                </button>
                                </Link>
                            </div>
                        </div>
                        <PDFExport ref={component => (this.pdfExportComponent = component)}  fileName={`Personality ${nameReport}`} margin={20} className="width-100"  style={{width:"100% !important"}}>
                        <div className="canvas_Profiler">
                            <div className="width-100" style={{marginBottom:"18px"}}>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_bg} className="flex width-100 std-bg std-prf" style={{height:"192px", objectFit:"cover"}} />
                                <div className="content_bg_report std-r">
                                    <div className="width-100 dekstop">
                                        <div className="flex width-100 space-between header_bg " style={{justifyContent:this.state.visible? "space-between":"space-between"}}>
                                            <div className="box_under_lc">
                                                <button  onClick={this.exportPDFWithComponent} style={{display:"none", width:"0px"}}>
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download}  style={{display:"none"}}/>
                                                    <p style={{display:"none"}}>Download PDF</p>
                                                </button>
                                            </div>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo}  className="logo_bg" />
                                            <div className="under-lc"  style={{marginTop:this.state.visible2?"":"0px"}}>
                                                {/* <p >Under License From</p>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={crown_logo} style={{height:"53px", width:"auto", display:"flex"}}/> */}
                                            </div>
                                            {/* <p  style={{display:this.state.visible?"flex":"none",width:"156px"}}>  </p> */}
                                        </div>
                                    </div>
                                    <div className="width-100 mobile">
                                        <div className="flex width-100 space-between column header_bg center">
                                            {/* <button style={{marginBottom:"20px"}} onClick={this.exportPDFWithComponent}>Download PDF</button> */}
                                            <button  onClick={this.exportPDFWithComponentMobile} style={{display:"none", marginBottom:"20px", width:"0px"}}>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download}  style={{display:"none"}} />
                                                <p style={{display:"none"}}>Download PDF</p>
                                            </button>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo}  className="logo_bg" style={{height:"24px"}} />
                                        </div>
                                    </div>
                                    <div className="flex width-100 center">
                                        <h1 className="title_bg_canvas" style={{paddingLeft:this.state.visible?"":"15%"}}>Personality Profile</h1>
                                    </div>
                                    <div className="flex width-100 center btn_Idv">
                                        <button>
                                            <p>Individual Report</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex width-100 center" style={{marginBottom:"24px"}}>
                                <div className="under-lc"  style={{marginTop:this.state.visible2?"":"0px", width:this.state.visible2?"":"188px", flexDirection:this.state.visible2?"":"row"}}>
                                    <p style={{fontSize:this.state.visible2?"":"12px", margin:this.state.visible2?"":"20px auto auto"}}>Under License From</p>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={crown_logo} style={{ width:this.state.visible2?"":"70px",}}/>
                                </div>
                            </div>
                            <div className="width-100 dekstop">
                                <div className="box_admin_Profile">
                                    <div className="flex width-100 height-100 space-between">
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_left} style={{height:"100%",objectFit:"cover", borderBottomLeftRadius:"10px"}} />
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_right} style={{height:"100%",objectFit:"cover", borderTopRightRadius:"10px"}} />
                                    </div>
                                    <div className="flex  column textPrepare">
                                        <p className="top">Prepared for</p>
                                        <div className="flex width-100 space-between">
                                            <div className="flex">
                                                <h3 style={{textTransform:"capitalize"}}>{this.state.name} </h3>
                                                <button>
                                                    <p style={{textTransform:"uppercase"}}>{this.state.label}</p>
                                                </button>
                                            </div>
                                            {this.state.date_report &&<p className="date">{date_PID} </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width-100 mobile">
                                <div style={{padding:"14px"}}>
                                    <div className="box_admin_Profile" style={{height:"150px", width:"100%", marginLeft:"0%"}}>
                                        <div className="flex width-100 height-100 space-between icon-bg-personality">
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_left} style={{objectFit:"cover", borderBottomLeftRadius:"0px"}} />
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_right} style={{objectFit:"cover", borderTopRightRadius:"0px", marginTop:"auto"}} />
                                        </div>
                                        <div className="flex  column textPrepare width-100">
                                            <div className="width-100 flex column" style={{padding:"14px"}}>
                                            <p className="top">Prepared for</p>
                                            <h3 style={{textTransform:"capitalize"}}>{this.state.name} </h3>
                                            <div className="flex width-100 space-between column">
                                                {/* <div className="flex column width-100"> */}
                                                        <button style={{width:"94%", marginLeft:"0px"}}>
                                                            <p style={{textTransform:"uppercase"}}>{this.state.label}</p>
                                                        </button>
                                                {/* </div> */}
                                                {this.state.date_report && <p className="date">{date_PID}</p>}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width-report">
                                <div className="flex width-100 center box-Overview" style={{justifyContent:"space-between"}}>
                                    <div className="line_Report"></div>
                                    <h1 className="title-line_Report">OVERVIEW</h1>
                                    <div className="line_Report"></div>
                                </div>
                                <p className="width-100 textOverview" style={{marginBottom:"48px"}}>
                                The natural personality profile for you is indicated by the blue line on the graph below. Note also the gray line; it represents the Blended Profile that most nearly matches your graph. Seventeen Blended Profiles are used as the baseline for interpreting the combinations of four basic dimensions of personality. The information in this section is derived from the {this.state.label} Blended Profile. Typically, the more closely the blue line graph matches this Blended Profile, the more accurately the information will describe your natural behavior style. </p>
                            </div>
                            <div className="box_Admin flex center column" >
                                <div className="box_Chart"  >
                                    <h1 className="width-100" >{this.state.label}</h1>
                                    <div className="flex width-100 center">
                                    <div className="width-100 dekstop">
                                        <div className="flex width-100 center">
                                            <div className="flex width-100 flexBox">
                                                <div className="flex width-100 column sub_flexBox" >
                                                    <div className="flex width-100 space-between bar_Desc" style={{marginBottom:"32px"}}>
                                                        <div className="width-20 flex column center">
                                                            <h1>Directing</h1>
                                                            <p>Assertive - Decisive</p>
                                                            <p>Controlling</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Interacting</h1>
                                                            <p>Outgoing - Convincing</p>
                                                            <p>Excitable</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Supportive</h1>
                                                            <p>Harmonious - Patient</p>
                                                            <p>Lenient</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Conscientious</h1>
                                                            <p>Detailed - Precise</p>
                                                            <p>Perfectionist</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="none"> */}
                                                    <Bar data={data} options={options} style={{width:"100%"}}/>
                                                    {/* </div> */}
                                                    <div className="flex width-100 space-between bar_Desc" style={{marginTop:"32px"}}>
                                                        <div className="width-20 flex column center">
                                                            <h1>Adaptive</h1>
                                                            <p>Pliable - Conforming</p>
                                                            <p>Passive</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Reserved</h1>
                                                            <p>Modest - Quiet</p>
                                                            <p>Distant</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Objective</h1>
                                                            <p>Questioning - Impatient</p>
                                                            <p>Critical</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Unconventional</h1>
                                                            <p>Instinctive - Improvised</p>
                                                            <p>Unorganized</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="width-100 mobile">
                                        <div className="flex width-100 center">
                                            <div className="flex width-100 flexBox" style={{width:`${this.state.width * 0.70}px`}}>
                                                <div className="flex width-100 column sub_flexBox" >
                                                    <div className="flex width-100 space-between bar_Desc" style={{marginBottom:"32px"}}>
                                                        <div className="width-20 flex column center">
                                                            <h1>Directing</h1>
                                                            <p>Assertive - Decisive</p>
                                                            <p>Controlling</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Interacting</h1>
                                                            <p>Outgoing - Convincing</p>
                                                            <p>Excitable</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Supportive</h1>
                                                            <p>Harmonious - Patient</p>
                                                            <p>Lenient</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Conscientious</h1>
                                                            <p>Detailed - Precise</p>
                                                            <p>Perfectionist</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="none"> */}
                                                    <Bar data={data} options={options} style={{width:"100%"}}/>
                                                    {/* </div> */}
                                                    <div className="flex width-100 space-between bar_Desc" style={{marginTop:"32px"}}>
                                                        <div className="width-20 flex column center">
                                                            <h1>Adaptive</h1>
                                                            <p>Pliable - Conforming</p>
                                                            <p>Passive</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Reserved</h1>
                                                            <p>Modest - Quiet</p>
                                                            <p>Distant</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Objective</h1>
                                                            <p>Questioning - Impatient</p>
                                                            <p>Critical</p>
                                                        </div>
                                                        <div className="width-20 flex column center">
                                                            <h1>Unconventional</h1>
                                                            <p>Instinctive - Improvised</p>
                                                            <p>Unorganized</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    </div>
                                </div>
                            </div>
                            <div className="width-report">
                                <div className="flex width-100 report_Admin">
                                    <p className="openSans justify ">{this.state.report.intro}</p>
                                </div>
                                <div className="Idea_box">
                                    <div className="blue_container" >
                                        <div className="items width-100" >
                                            <div className="verticalLine"/>
                                            <div className="flex width-100 column">
                                                <h1 className="width-100">Ideal Environment</h1>
                                                <p className="width-100 openSans">{this.state.report.idealEnvironmentCopy} </p>
                                            </div>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={ideal} />
                                        </div>
                                    </div>
                                    <div className="blue_container">
                                        <div className="items  width-100">
                                            <div className="verticalLine"/>
                                            <div className="flex width-100 column">
                                                <h1 className="width-100">Areas of Strength</h1>
                                                <p className="width-100 openSans">{this.state.report.typicalAreasOfStrengthCopy} </p>
                                            </div>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={area} />
                                        </div>
                                    </div>
                                    <div className="blue_container">
                                        <div className="items  width-100">
                                            <div className="verticalLine"/>
                                            <div className="flex width-100 column">
                                                <h1 className="width-100">Areas of Development</h1>
                                                <p className="width-100 openSans">{this.state.report.typicalAreasOfStruggleCopy} </p>
                                            </div>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dev} />
                                        </div>
                                    </div>
                                </div>
                                <div className="width-100 flex" style={{border:"1px solid #E0E0E0", margin:"50px 0px"}} />
                                <div className="width-100 dekstop">
                                <div className="flex width-100 space-between ">
                                    <div className="flex width-100 column title_work">
                                        <h1 style={{textTransform:"uppercase", color:"#3B6C98", lineHeight:"59px"}}>{this.state.label}</h1>
                                        <h1 style={{textTransform:"uppercase", color:"#DDB87A", lineHeight:"59px"}}>at work</h1>
                                    </div>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_admin} />
                                </div>
                                </div>
                                <div className="width-100 mobile">
                                <div className="flex width-100 space-between column title_Report_Admin">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_admin} />
                                    <div className="flex width-100 column " style={{marginTop:"24.93px"}}>
                                        <h1 style={{textTransform:"uppercase", color:"#3B6C98"}}>{this.state.label}</h1>
                                        <h1 style={{textTransform:"uppercase", color:"#DDB87A"}}>at work</h1>
                                    </div>
                                </div>
                                </div>
                                {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={bg_ellipse}  className="ellipse_report"/> */}
                                <div className="flex width-100 bg_ellipse" >
                                   
                                    <div className="flex box-left column">
                                        <div className="box-shadow-report flex column">
                                            <h2>Preferred Activities</h2>
                                            <p className="openSans">{this.state.report.preferredActivitiesCopy} </p>
                                        </div>
                                        <div className="box-shadow-report flex column">
                                            <h2>Leadership Style</h2>
                                            <p className="openSans">{this.state.report.leadershipStyleCopy} </p>
                                        </div>
                                        <div className="box-shadow-report flex column">
                                            <h2>Communication Style</h2>
                                            <p className="openSans">{this.state.report.communicationStyleCopy} </p>
                                        </div>
                                        <div className="box-shadow-report flex column">
                                            <h2>Generic Careers</h2>
                                            <p className="openSans">{this.state.report.genericCareersCopy} </p>
                                        </div>
                                        <div className="box-shadow-report flex column">
                                            <h2>Famous people similar to this profile</h2>
                                            <p className="openSans">{this.state.report.famousPeopleSimilarToThisProfileCopy} </p>
                                        </div>
                                       
                                    </div>
                                    <div className="flex box-right column">
                                        <div className="box-shadow-report flex column ">
                                            <h2>Contributions to the Organization</h2>
                                            <p className="openSans">{this.state.report.contributionsToTheOrganizationCopy}</p>
                                        </div>
                                        <div className="box-shadow-report flex column ">
                                            <h2>Task or People Orientation</h2>
                                            <p className="openSans">{this.state.report.taskOrPeopleOrientationCopy} </p>
                                        </div>
                                        <div className="box-shadow-report flex column ">
                                            <h2>Suggestions for Managers</h2>
                                            <p className="openSans">{suggestionsForManagers} </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="width-100 flex" style={{border:"1px solid #E0E0E0", margin:"50px 0px"}} />
                                <div className="width-100 dekstop">
                                <div className="flex width-100 space-between ">
                                    <div className="flex width-100 column title_work">
                                        <h1 style={{textTransform:"uppercase", color:"#3B6C98", lineHeight:"59px"}}>{this.state.label}</h1>
                                        <h1 style={{textTransform:"uppercase", color:"#DDB87A", lineHeight:"59px"}}>and issues</h1>
                                    </div>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={issue} />
                                </div>
                                </div>
                                <div className="width-100 mobile">
                                <div className="flex width-100 space-between column title_Report_Admin">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={issue} />
                                    <div className="flex width-100 column" style={{marginTop:"24.93px"}}>
                                        <h1 style={{textTransform:"uppercase", color:"#3B6C98"}}>{this.state.label}</h1>
                                        <h1 style={{textTransform:"uppercase", color:"#DDB87A"}}>and issues</h1>
                                    </div>
                                </div>
                                </div>
                                <div className="width-100" style={{marginTop:"50px"}}>
                                    <div className="box-wavy-gold">
                                        <h2>Stability/Flexibility Factors</h2>
                                        <p className="openSans">{this.state.report.stabilityFlexibilityFactorsCopy} </p>
                                    </div>
                                    <div className="box-wavy-gold">
                                        <h2>Stress Factors</h2>
                                        <p className="openSans">{this.state.report.stressFactorsCopy} </p>
                                    </div>
                                    <div className="box-wavy-gold">
                                        <h2>Relationship Improvement Suggestions</h2>
                                        <p className="openSans">{this.state.report.relationshipImprovementSuggestionsCopy} </p>
                                    </div>
                                    <div className="box-wavy-gold">
                                        <h2>Cooperation and Control Factors</h2>
                                        <p className="openSans">{this.state.report.cooperationAndControlFactorsCopy} </p>
                                    </div>
                                    <div className="box-wavy-gold">
                                        <h2>Underlying Concerns</h2>
                                        <p className="openSans">{this.state.report.underlyingConcernsCopy} </p>
                                    </div>
                                </div>
                                <div className="width-100 flex" style={{border:"1px solid #E0E0E0", margin:"50px 0px"}} />
                                <div className="width-100 dekstop">
                                <div className="flex width-100 space-between ">
                                    <div className="flex width-100 column title_work">
                                        <h1 style={{textTransform:"uppercase", color:"#3B6C98", lineHeight:"59px"}}>{this.state.label}</h1>
                                        <h1 style={{textTransform:"uppercase", color:"#DDB87A", lineHeight:"59px"}}>and finances</h1>
                                    </div>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={finance} />
                                </div>
                                </div>
                                <div className="width-100 mobile">
                                <div className="flex width-100 space-between column title_Report_Admin">
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={finance} />
                                    <div className="flex width-100 column" style={{marginTop:"24.93px"}}>
                                        <h1 style={{textTransform:"uppercase", color:"#3B6C98"}}>{this.state.label}</h1>
                                        <h1 style={{textTransform:"uppercase", color:"#DDB87A"}}>and finances</h1>
                                    </div>
                                    
                                </div>
                                </div>
                                <div className="width-100" style={{marginTop:"50px"}}>
                                    <div className="flex width-100 space-between finance_Column">
                                        <div className="bg_finance">
                                            <h2>Financial Management</h2>
                                            <p className="openSans">{this.state.report.financialManagementCopy} </p>
                                        </div>
                                        <div className="bg_finance">
                                            <h2>Budget and Financial Issues</h2>
                                            <p className="openSans">{this.state.report.budgetAndFinancialIssuesCopy} </p>
                                        </div>
                                        <div className="bg_finance">
                                            <h2>Purchasing Tendencies</h2>
                                            <p className="openSans">{this.state.report.purchasingTendenciesCopy} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="width-100 flex" style={{border:"1px solid #E0E0E0", margin:"50px 0px"}} />
                                <div className="dimensions">
                                    <h1>dimensions of personality</h1>
                                    <p className="flex width-100 center openSans">This report is based on four dimensions of personality. Each dimension has a continuum of behaviours associated with it as shown below.</p>
                                    <div className="width-100 dekstop">
                                    <div className="table_dimensions">
                                        <div className="sub_table">
                                            <p className="text1">Adaptive</p>
                                            <div className="center-range">
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                <p className="text">Mid-range</p>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                            </div>
                                            <p className="text2">Directing</p>
                                        </div>
                                        <div className="sub_table">
                                            <p className="text1">Reserved</p>
                                            <div className="center-range">
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                <p className="text">Mid-range</p>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                            </div>
                                            <p className="text2">Interacting</p>
                                        </div>
                                        <div className="sub_table">
                                            <p className="text1">Objective</p>
                                            <div className="center-range">
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                <p className="text">Mid-range</p>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                            </div>
                                            <p className="text2">Supportive</p>
                                        </div>
                                        <div className="sub_table">
                                            <p className="text1">Unconventional</p>
                                            <div className="center-range">
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                <p className="text">Mid-range</p>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                            </div>
                                            <p className="text2">Conscientious</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="width-100 mobile-flex center" >
                                        {/* <div className=" flex width-100 center"> */}
                                        <div className="dekstop width-100">
                                        <div className="flex width-100 center">
                                        <div className="flex box_dimensions_personality" style={{margin:"0px 0%", display:"flow-root", overflow:"auto"}}>
                                            <div className="table_dimensions" style={{width:"700px", marginLeft:"0px"}}>
                                                <div className="sub_table">
                                                    <p className="text1">Adaptive</p>
                                                    <div className="center-range">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                        <p className="text">Mid-range</p>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                    </div>
                                                    <p className="text2">Directing</p>
                                                </div>
                                                <div className="sub_table">
                                                    <p className="text1">Reserved</p>
                                                    <div className="center-range">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                        <p className="text">Mid-range</p>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                    </div>
                                                    <p className="text2">Interacting</p>
                                                </div>
                                                <div className="sub_table">
                                                    <p className="text1">Objective</p>
                                                    <div className="center-range">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                        <p className="text">Mid-range</p>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                    </div>
                                                    <p className="text2">Supportive</p>
                                                </div>
                                                <div className="sub_table">
                                                    <p className="text1">Unconventional</p>
                                                    <div className="center-range">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                        <p className="text">Mid-range</p>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                    </div>
                                                    <p className="text2">Conscientious</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="width-100 mobile">
                                            <div className="width-100 flex center">
                                            <div className="flex box_dimensions_personality" style={{margin:"0px 0%", display:"flex", overflow:"auto", width:`${this.state.width * 0.70}px`}}>
                                                <div className="table_dimensions" style={{width:"700px", marginLeft:"0px"}}>
                                                    <div className="sub_table">
                                                        <p className="text1">Adaptive</p>
                                                        <div className="center-range">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                            <p className="text">Mid-range</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                        </div>
                                                        <p className="text2">Directing</p>
                                                    </div>
                                                    <div className="sub_table">
                                                        <p className="text1">Reserved</p>
                                                        <div className="center-range">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                            <p className="text">Mid-range</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                        </div>
                                                        <p className="text2">Interacting</p>
                                                    </div>
                                                    <div className="sub_table">
                                                        <p className="text1">Objective</p>
                                                        <div className="center-range">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                            <p className="text">Mid-range</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                        </div>
                                                        <p className="text2">Supportive</p>
                                                    </div>
                                                    <div className="sub_table">
                                                        <p className="text1">Unconventional</p>
                                                        <div className="center-range">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft}  className="arrLeft" />
                                                            <p className="text">Mid-range</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className="arrRight"/>
                                                        </div>
                                                        <p className="text2">Conscientious</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="struggle">
                                    <h1>Strengths and Struggles</h1>
                                    <p className="openSans">The strengths and struggles in this section of the report relate to your Personality Profiler scores for the four individual dimensions. This information gives more depth to the report and helps you as a manager to focus on the unique style of this individual.</p>
                                    <p className="openSans">There are many advantages to understanding a person’s unique set of talents and motivations, You will enjoy operating in some environments more than in others, simply because they are a better fit for this person’s natural style. A goal should be to align your work as much as possivle with the personal profile strengths and minimize exposure in the areas in which people with this profile typically struggle. On the other hand, a manager can coach a team member to help improve weak areas. For example, a person who tends to be impatient and is not naturally a good listener can improve relationships through a focused effort to improve these areas of struggle. Also keep in mind that strengths overdone become weaknesses.</p>
                                    <p className="openSans">The strengths and struggles that follow are typical for people who share a common profile score. Keep in mind that every individual is unique, so some of the items may not fit you. The list should be objectively reviewed to help determine whether all of the descriptions apply.</p>
                                </div>
                                <div className="dimension-one">
                                    <h1>dimension one:</h1>
                                    <div className="flex center column">
                                        <div className="sub_title">
                                                <h3>Adaptive - Directing</h3>
                                        </div>
                                        <div className="width-100 dekstop">
                                        <div className="box-dimension-desc">
                                            <div className="content">
                                                <h3>Adaptive</h3>
                                                <p>Pliable</p>
                                                <p>Conforming</p>
                                                <p>Passive</p>
                                            </div>
                                            <div className="box-centerRange">
                                                <div className="range-dimension-one">
                                                    <div className="dynamic-range" style={{marginLeft:`${this.state.ndirecting * 92 / 100}%`}}>
                                                        <p>{this.state.ndirecting} </p>
                                                    </div>
                                                    <div className="center-range"></div>
                                                </div>
                                                <div className="flex width-100 space-between">
                                                    <p>20</p>
                                                    <p>50</p>
                                                    <p>80</p>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Directing</h3>
                                                <p>Assertive </p>
                                                <p>Decisive</p>
                                                <p>Controlling</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="mobile width-100 " style={{flexDirection:"column"}}>
                                            <div className="box-dimension-desc flex column">
                                                <div className="width-100 flex space-between">
                                                <div className="content align-left">
                                                    <h3>Adaptive</h3>
                                                    <p>Pliable</p>
                                                    <p>Conforming</p>
                                                    <p>Passive</p>
                                                </div>
                                            
                                                <div className="content align-right">
                                                    <h3>Directing</h3>
                                                    <p>Assertive </p>
                                                    <p>Decisive</p>
                                                    <p>Controlling</p>
                                                </div>
                                                </div>
                                                <div className="box-centerRange">
                                                    <div className="range-dimension-one">
                                                        <div className="dynamic-range" style={{marginLeft:`${this.state.ndirecting * 92 / 100}%`}}>
                                                            <p>{this.state.ndirecting} </p>
                                                        </div>
                                                        <div className="center-range"></div>
                                                    </div>
                                                    <div className="flex width-100 space-between">
                                                        <p>20</p>
                                                        <p>50</p>
                                                        <p>80</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       {dataDimensionOne}
                                   </div>
                                </div>
                                <div className="dimension-one">
                                    <h1>dimension two:</h1>
                                    <div className="flex center column">
                                        <div className="sub_title">
                                                <h3>Reserved - Interacting</h3>
                                        </div>
                                        <div className="width-100 dekstop">
                                        <div className="box-dimension-desc">
                                            <div className="content">
                                                <h3>Reserved</h3>
                                                <p>Modest</p>
                                                <p>Quiet</p>
                                                <p>Distant</p>
                                            </div>
                                            <div className="box-centerRange">
                                                <div className="range-dimension-one">
                                                    <div className="dynamic-range" style={{marginLeft:`${this.state.ninteracting * 92 / 100}%`}}>
                                                        <p>{this.state.ninteracting} </p>
                                                    </div>
                                                    <div className="center-range"></div>
                                                </div>
                                                <div className="flex width-100 space-between">
                                                    <p>20</p>
                                                    <p>50</p>
                                                    <p>80</p>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Interacting</h3>
                                                <p>Outgoing</p>
                                                <p>Convincing </p>
                                                <p>Excitable</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="mobile width-100 " style={{flexDirection:"column"}}>
                                            <div className="box-dimension-desc flex column">
                                                <div className="width-100 flex space-between">
                                                <div className="content align-left">
                                                    <h3>Reserved</h3>
                                                    <p>Modest</p>
                                                    <p>Quiet</p>
                                                    <p>Distant</p>
                                                </div>
                                            
                                                <div className="content align-right">
                                                    <h3>Interacting</h3>
                                                    <p>Outgoing</p>
                                                    <p>Convincing </p>
                                                    <p>Excitable</p>
                                                </div>
                                                </div>
                                                <div className="box-centerRange">
                                                    <div className="range-dimension-one">
                                                        <div className="dynamic-range" style={{marginLeft:`${this.state.ninteracting * 92 / 100}%`}}>
                                                            <p>{this.state.ninteracting} </p>
                                                        </div>
                                                        <div className="center-range"></div>
                                                    </div>
                                                    <div className="flex width-100 space-between">
                                                        <p>20</p>
                                                        <p>50</p>
                                                        <p>80</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dataDimensionTwo}
                                   </div>
                                </div>
                                <div className="dimension-one">
                                    <h1>dimension three:</h1>
                                    <div className="flex center column">
                                        <div className="sub_title">
                                                <h3>Objective - Supportive</h3>
                                        </div>
                                        <div className="width-100 dekstop">
                                        <div className="box-dimension-desc">
                                            <div className="content">
                                                <h3>Objective</h3>
                                                <p>Questioning </p>
                                                <p>Impatient</p>
                                                <p>Critical</p>
                                            </div>
                                            <div className="box-centerRange">
                                                <div className="range-dimension-one">
                                                    <div className="dynamic-range" style={{marginLeft:`${this.state.nsupportive * 92 / 100}%`}}>
                                                        <p>{this.state.nsupportive} </p>
                                                    </div>
                                                    <div className="center-range"></div>
                                                </div>
                                                <div className="flex width-100 space-between">
                                                    <p>20</p>
                                                    <p>50</p>
                                                    <p>80</p>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Supportive</h3>
                                                <p>Harmonious</p>
                                                <p>Patient</p>
                                                <p>Lenient</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="mobile width-100 " style={{flexDirection:"column"}}>
                                            <div className="box-dimension-desc flex column">
                                                <div className="width-100 flex space-between">
                                                <div className="content align-left">
                                                    <h3>Objective</h3>
                                                    <p>Questioning </p>
                                                    <p>Impatient</p>
                                                    <p>Critical</p>
                                                </div>
                                            
                                                <div className="content align-right">
                                                    <h3>Supportive</h3>
                                                    <p>Harmonious</p>
                                                    <p>Patient</p>
                                                    <p>Lenient</p>
                                                </div>
                                                </div>
                                                <div className="box-centerRange">
                                                    <div className="range-dimension-one">
                                                        <div className="dynamic-range" style={{marginLeft:`${this.state.nsupportive * 92 / 100}%`}}>
                                                            <p>{this.state.nsupportive} </p>
                                                        </div>
                                                        <div className="center-range"></div>
                                                    </div>
                                                    <div className="flex width-100 space-between">
                                                        <p>20</p>
                                                        <p>50</p>
                                                        <p>80</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dataDimensionThree}
                                   </div>
                                </div>
                                <div className="dimension-one">
                                    <h1>dimension four:</h1>
                                    <div className="flex center column">
                                        <div className="sub_title">
                                                <h3>Unconventional - Conscientious</h3>
                                        </div>
                                        <div className="width-100 dekstop">
                                        <div className="box-dimension-desc">
                                            <div className="content">
                                                <h3>Unconventional</h3>
                                                <p>Instinctive</p>
                                                <p>Improvised</p>
                                                <p>Unorganized</p>
                                            </div>
                                            <div className="box-centerRange">
                                                <div className="range-dimension-one">
                                                    <div className="dynamic-range" style={{marginLeft:`${this.state.nconscientious * 92 / 100}%`}}>
                                                        <p>{this.state.nconscientious} </p>
                                                    </div>
                                                    <div className="center-range"></div>
                                                </div>
                                                <div className="flex width-100 space-between">
                                                    <p>20</p>
                                                    <p>50</p>
                                                    <p>80</p>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Conscientious</h3>
                                                <p>Detailed</p>
                                                <p>Precise</p>
                                                <p>Perfectionist</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="mobile width-100 " style={{flexDirection:"column"}}>
                                            <div className="box-dimension-desc flex column">
                                                <div className="width-100 flex space-between">
                                                <div className="content align-left">
                                                    <h3>Unconventional</h3>
                                                    <p>Instinctive</p>
                                                    <p>Improvised</p>
                                                    <p>Unorganized</p>
                                                </div>
                                            
                                                <div className="content align-right">
                                                    <h3>Conscientious</h3>
                                                    <p>Detailed</p>
                                                    <p>Precise</p>
                                                    <p>Perfectionist</p>
                                                </div>
                                                </div>
                                                <div className="box-centerRange">
                                                    <div className="range-dimension-one">
                                                        <div className="dynamic-range" style={{marginLeft:`${this.state.nconscientious * 92 / 100}%`}}>
                                                            <p>{this.state.nconscientious} </p>
                                                        </div>
                                                        <div className="center-range"></div>
                                                    </div>
                                                    <div className="flex width-100 space-between">
                                                        <p>20</p>
                                                        <p>50</p>
                                                        <p>80</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dataDimensionFour}
                                   </div>
                                </div>
                                <div className="box-conclusion">
                                    <div className="flex width-100" style={{marginBottom:"24px"}}>
                                        <div className="line-con"></div>
                                            <h1>CONCLUSION</h1>
                                        <div className="line-con"></div>
                                    </div>
                                    <p className="openSans ">
                                    {dataReport.components.shared.conclusion.single.conclusionCopy}
                                    </p>
                                </div>
                                <div className="flex width-100 center" style={{marginBottom:"59px"}}>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} />
                                </div>

                            </div>
                            
                        </div>
                        </PDFExport>
                        <ScrollToTop />
                    </div>
                  
                    <Modal  id="course-outline1" visible={this.state.show_modal} effect="fadeInUp" onClickAway={this.closePopupPersonality}>
                    <div className="popup_dlc  flex column">
                    <div className="box_popup_dlc" >
                        <div className="flex width-100 flex-end" >
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={this.closePopupPersonality}/>
                        </div>
                    <div className="flex width-100 center column" onKeyDown={this._onKeyDown} >
                        <h2 className="openSans">Rate this Personality Profiler</h2>
                        <Rating name="pristine" value={this.state.rate}  onClick={this.changeRatting} onChange={this.changeRatting} />
                        <h3 className="openSans flex">{this.state.desc} </h3>
                        <div className="flex width-100 space-between exp-dlc">
                        <p className="openSans">(1) Very Bad</p>
                        <p className="openSans">(2) Bad</p>
                        <p className="openSans">(3) Need Improvement</p>
                        <p className="openSans">(4) Good</p>
                        <p className="openSans">(5) Very Good</p>
                        </div>
                        <h2 className="openSans">Give us your thought about this Personality Profiler</h2>
                        <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                        </div>
                        <button onClick={this.submitRatingPersonality} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                        <p>Submit Rating</p>
                        </button>
                    </div>
                    </div>
                </div>
                </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      profile1: state.profileId,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(componentName));
  