import React, { Component } from 'react';
import {phone,mail,cv,cv_green, people, salary, skill, work_exp, education, certificate, project,industry,
    art_certification,art_personal, art_education,art_project,art_salary,art_skills,art_work_exp, pen} from "../../_redux/svg"
import {stringToArray} from "../../_redux/function"


class Industry_And_Job extends Component {
    constructor(){
        super()
        this.state={
            industry_:["Food & Beverages","Automotive","Statistics","Communication","Information Technology"],
            job_function_:["Customer Service","Education & Training","Information Techonology & Software","Manufacturing"]
        }
    }


    render() {
        const {industry_,job_function_} = this.state
        const {profile, workId, dataIndustri, dataJobFunction} = this.props


        return (
            <div  className="h-100 bg-ff br-10 shd-input mb-24">
                <div className='flex column '>
                    <div className='flex p-1814 bb-ea space-between'>
                        <div className='flex '>
                        <div className='flex m-auto-00'>
                            {industry}
                        </div>
                        <p className='ml-14 DM-Sans c-29 s-20 '>Industry Interest</p>
                        </div>
                        <div className='flex pointer m-auto0' onClick={(e)=>this.props.handlePopup("interest_")}>{pen} 
                        <p className='DM-Sans s-14 c-36 ml6'>Edit</p></div>
                        
                    </div>
                    <div className='flex column p-14'>
                    {profile.industry_id !== null &&
                    <div className='flex wrap '>
                        {stringToArray(profile.industry_id).map((item)=>(
                        <div className='flex p-414 bg-87 br-14 mr-14 mb-14 wd-fit-content'>
                            <p className='s-14 c-ff DM-Sans'>{dataIndustri.map((item2)=>{if (parseInt(item2.id)===parseInt(item)){return(item2.industry_name)}})}</p>
                        </div>
                        ))}
                    </div>}
                    <div className='flex width-100 bb-ea mt-10'/>
                    </div>
                    <div className='flex p-01814 bb-ea space-between'>
                        <div className='flex mt-10'>
                        <div className='flex m-auto-00'>
                            {work_exp}
                        </div>
                        <p className='ml-14 DM-Sans c-29 s-20 '>Job Function Interest</p>
                        </div>
                    </div>
                    <div className='flex column p-1410'>
                    <div className='flex wrap '>
                        {stringToArray(profile.job_function_id).map((item)=>(
                        <div className='flex p-414 bg-87 br-14 mr-14 mb-14 wd-fit-content'>
                            <p className='s-14 c-ff DM-Sans'>{dataJobFunction.map((item2)=>{if (parseInt(item2.id)===parseInt(item)){return(item2.job_function_name)}})}</p>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Industry_And_Job;