import React, { Component } from "react";
import karya from "../images/logo-KT-bw.png";
import karya1 from "../images/logo-KT-color.png";
import person from "../images/icons/person.svg";
import personDark from "../images/icons/person-dark.svg";
import Navbar from "../components/NavBar";
import { BrowserRouter as Router, NavLink } from "react-router-dom";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      talents: false,
      resources: false,
      path: ["/", "/jo"],
    };
  }

  handleCallback = (childData) => {
    // this.setState({link: childData})
    // window.location.href=`/${childData}`
  };

  setLink = (x, y) => {
    if (y === "talents") {
      this.setState({ talents: false });
    } else if (y === "resources") {
      this.setState({ resources: false });
    }
    // window.location.href=(x)
  };

  showMenu = (x, y) => {
    if (y === "talents") {
      this.setState({ talents: x });
    } else {
      this.setState({ resources: x });
    }
  };

  searchJobOpportunity = (e) => {
    const m = {
      city_id: "",
      country_id: "",
      industry_id: [],
      job_function_id: "",
      job_level_id: [],
      job_title: "",
      job_type: [],
      salary_from: 0,
      salary_to: 100000000,
      user_id: 1,
    };
    sessionStorage.setItem("jobOpportunitySearch", JSON.stringify(m));
    window.location.href = "/Job-Opportunity-Public";
  };

  render() {
    const { link, talents, resources, path } = this.state;
    const checkPath = (e) => {
      let n = false;
      if (path.indexOf(e) != -1) {
        n = true;
      }
      return n;
    };
    const { status } = this.props;

    const icon_dropdown = (
      <svg
        className="m-auto08"
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.34467 0.21967C0.637563 -0.0732233 1.11244 -0.0732233 1.40533 0.21967L5.375 4.18934L9.34467 0.21967C9.63756 -0.0732233 10.1124 -0.0732233 10.4053 0.21967C10.6982 0.512563 10.6982 0.987437 10.4053 1.28033L5.90533 5.78033C5.61244 6.07322 5.13756 6.07322 4.84467 5.78033L0.34467 1.28033C0.0517767 0.987437 0.0517767 0.512563 0.34467 0.21967Z"
          fill={status ? "#545454" : "#FFFFFF"}
        />
      </svg>
    );

    const dropdown_item = (
      <svg
        className="auto-0 transf-90"
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.34467 0.21967C0.637563 -0.0732233 1.11244 -0.0732233 1.40533 0.21967L5.375 4.18934L9.34467 0.21967C9.63756 -0.0732233 10.1124 -0.0732233 10.4053 0.21967C10.6982 0.512563 10.6982 0.987437 10.4053 1.28033L5.90533 5.78033C5.61244 6.07322 5.13756 6.07322 4.84467 5.78033L0.34467 1.28033C0.0517767 0.987437 0.0517767 0.512563 0.34467 0.21967Z"
          fill="#545454"
        />
      </svg>
    );
    const line = <div className="flex hg-24 wd-2 auto bg-d4" />;
    return (
      <div>
        <div className="flex width-100 center fixed z-i99">
          <div
            className={`dekstop nt_ width-100 fs-JL Nav_ ${
              status ? "bg-ff fsh_" : "bg-unset"
            } `}
            style={{ top: "0px" }}
          >
            <div className="flex space-between wd-content hg-100 m-auto">
              <NavLink
                to="/"
                rel="noopener noreferrer"
                className="flex auto-00"
              >
                <img
                  src={status ? karya1 : karya}
                  className=" hg-40 contain pointer"
                  onClick={() => this.setLink("/", "")}
                  alt="Karya Talent"
                />
              </NavLink>
              <div className="flex auto-0">
                {/* <NavLink to="/"  rel="noopener noreferrer" className={`${status?"c-54":"c-ff"} Kanit s-16 w-600 let-04 text-up m-auto14 pointer`} onClick={()=>this.setLink("/","")}>Home</NavLink> */}
                <NavLink
                  to="/About"
                  rel="noopener noreferrer"
                  className={`${
                    status ? "c-54" : "c-ff"
                  } Kanit s-16 w-600 let-04 text-up m-auto14 pointer`}
                  onClick={() => this.setLink("/About", "")}
                >
                  ABOUT
                </NavLink>
                {line}
                <div className="flex column li- relative">
                  <NavLink
                    to="/#"
                    rel="noopener noreferrer"
                    className={`${
                      status ? "c-54" : "c-ff"
                    } Kanit s-16 let-04 w-600 text-up m-auto14 pointer flex `}
                    onClick={() => this.setLink("/Acquisition", "")}
                  >
                    ACQUISITION
                    {icon_dropdown}
                  </NavLink>
                  <div
                    className={`flex column shd-15 bg-ff align-left absolute mt-50 br-10 p-14 ${
                      talents ? "op-1" : "op-0"
                    }`}
                  >
                    <NavLink
                      to="/About-Acquisition"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mb-10 pointer"
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        } captz  Kanit  w-600 s-16 let-04`}
                      >
                        About Acquisition{" "}
                      </p>
                    </NavLink>
                    <div className="flex width-100 hg-1 bg-eb" />
                    <NavLink
                      to="/Job-Opportunity-Public"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mb-10 mt5 pointer"
                      onClick={() => this.searchJobOpportunity()}
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        } captz  Kanit  w-600 s-16 let-04`}
                      >
                        Jobs{" "}
                      </p>
                    </NavLink>
                    <div className="flex width-100 hg-1 bg-eb" />
                    <NavLink
                      to="/Talents"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mb-10 mt-10 pointer"
                      onClick={() => this.setLink("/Talents", "talents")}
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        } captz  Kanit  w-600 s-16 let-04`}
                      >
                        Talents
                      </p>
                    </NavLink>
                    <div className="flex width-100 hg-1 bg-eb" />
                    <NavLink
                      to="/Employer"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mb-10 mt-10 pointer"
                      onClick={() => this.setLink("/Employer", "")}
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        } captz Kanit  w-600 s-16 let-04 `}
                      >
                        Employer{" "}
                      </p>
                    </NavLink>
                    <div className="flex width-100 hg-1 bg-eb" />
                    <NavLink
                      to="/data-science-rockstar"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mb-10 mt-10 pointer"
                      onClick={() =>
                        this.setLink("/data-science-rockstar", "talents")
                      }
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        } captz Kanit  w-600 s-16 let-04 `}
                      >
                        Data Science Rockstar{" "}
                      </p>
                    </NavLink>
                    {/* <div className='flex width-100 hg-1 bg-eb' />
                                    <a href="/Employer"   rel="noopener noreferrer" className='flex space-between width-100 mt-14 pointer' onClick={()=>this.setLink("/Employer","talents")}>
                                        <p className={`${status?"c-54":"c-03"}  Kanit  w-600 let-04 s-16`}>KT300 Club </p>
                                    </a> */}
                  </div>
                </div>
                {line}
                <NavLink
                  to="/Advisory"
                  rel="noopener noreferrer"
                  className={`${
                    status ? "c-54" : "c-ff"
                  } Kanit s-16 w-600 let-04 text-up m-auto14 pointer`}
                  onClick={() => this.setLink("/Advisory", "")}
                >
                  ADVISORY
                </NavLink>
                {line}
                <NavLink
                  to="/Academy"
                  rel="noopener noreferrer"
                  className={`${
                    status ? "c-54" : "c-ff"
                  } Kanit s-16 let-04 w-600 text-up m-auto14 pointer`}
                  onClick={() => this.setLink("/Academy", "")}
                >
                  ACADEMY
                </NavLink>
                {line}
                <NavLink
                  to="/Assessment"
                  rel="noopener noreferrer"
                  className={`${
                    status ? "c-54" : "c-ff"
                  } Kanit s-16 let-04 w-600 text-up m-auto14 pointer`}
                  onClick={() => this.setLink("/Assessment", "")}
                >
                  ASSESSMENT
                </NavLink>
                {line}
                <div className="flex column li- relative">
                  <a
                    className={`${
                      status ? "c-54" : "c-ff"
                    } Kanit s-16 let-04 w-600 text-up m-auto14 pointer flex`}
                    onClick={() => this.showMenu(!resources, "resources")}
                  >
                    resources
                    {icon_dropdown}
                  </a>
                  <div
                    className={`flex column shd-15 bg-ff align-left absolute mt-50 br-10 p-14 ${
                      resources ? "op-1" : "op-0"
                    }`}
                  >
                    <NavLink
                      to="/career-resources"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mb-14 pointer"
                      onClick={() => {
                        this.setLink("/career-resources", "resources");
                        sessionStorage.setItem("slug", "career");
                      }}
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        }   Kanit  w-600 let-04 s-16`}
                      >
                        Career Resources{" "}
                      </p>
                    </NavLink>
                    <div className="flex width-100 hg-1 bg-eb" />
                    <NavLink
                      to="/hiring-resources"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mt-14 pointer"
                      onClick={() => {
                        this.setLink("/hiring-resources", "resources");
                        sessionStorage.setItem("slug", "hiring");
                      }}
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        }  Kanit let-04  w-600 s-16`}
                      >
                        Hiring Resources{" "}
                      </p>
                    </NavLink>
                  </div>
                </div>
                {line}
                <NavLink
                  to="/ContactUs"
                  rel="noopener noreferrer"
                  className={`${
                    status ? "c-54" : "c-ff"
                  } Kanit s-16 let-04 w-600 text-up m-auto14 pointer`}
                  onClick={() => this.setLink("/ContactUs", "")}
                >
                  contact Us
                </NavLink>

                <div className="flex column li- relative">
                  <NavLink
                    to="/#"
                    rel="noopener noreferrer"
                    className={`${
                      status ? "c-54" : "c-ff"
                    } Kanit s-16 let-04 w-600 text-up m-auto14 pointer flex `}
                  >
                    <img
                      src={status ? personDark : person}
                      className="flex flex-start hg-40 hg-icon pointer"
                      alt="person"
                    />
                    {icon_dropdown}
                  </NavLink>

                  <div
                    className={`flex column shd-15 bg-ff align-left absolute mt-50 br-10 p-14 ${
                      resources ? "op-1" : "op-0"
                    }`}
                  >
                    <NavLink
                      to="/Login"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mb-14 pointer"
                      onClick={() => {
                        this.setLink("/Login", "");
                      }}
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        }   Kanit  w-600 let-04 s-16`}
                      >
                        Login{" "}
                      </p>
                    </NavLink>
                    <div className="flex width-100 hg-1 bg-eb" />
                    <NavLink
                      to="/Signup"
                      rel="noopener noreferrer"
                      className="flex space-between width-100 mt-14 pointer"
                      onClick={() => {
                        this.setLink("/Signup", "");
                      }}
                    >
                      <p
                        className={`${
                          status ? "c-54" : "c-03"
                        }  Kanit let-04  w-600 s-16`}
                      >
                        Sign Up{" "}
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mobile nt_ width-100"
            style={{
              top: checkPath(window.location.pathname) ? "30px" : "0px",
            }}
          >
            <Navbar checkPath={checkPath(window.location.pathname)} />
          </div>
        </div>
      </div>
    );
  }
}

export default Nav;
