import React, { Component } from 'react';
import bg_pid from "../images/bg_values_profiler.png"
import bg_art from "../images/values-icon.png"
import times from "../images/values_time_icon.svg"
import bgMobile from "../images/bg_val_mobile.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';


class componentName extends Component {


    render() {
        return (
            <div>
                <div className="box-wrapper bg-color values_profiler" >
                    <div className="wrapper intro">
                        <div className="content">
                            <div className="page-container  width-100" style={{marginTop:"80px"}}>
                                <div className="page-container-outer job-summary">
                                    <div className="box-logo width-100">
                                    <h1 className="titleSpartan">Values Profiler</h1>
                                    </div>
                                    {/* <div className="dekstop-only"> */}
                                    <div className="block width-100">
                                        <div className="page-container-inner  width-100">
                                            <div className="box-edu-container width-100">
                                                <div className="box_PID">
                                                    <div className="flex col-vp width-100 height-100">
                                                        <div className="width-100 mobile-tablet height-100" >
                                                            <div className="width-100 bg-vp flex height-100" style={{background :`url(${bgMobile})`, backgroundSize:"cover"}}>
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={bg_art} className="art_PID" />
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="flex column width-60 space-between">
                                                            <div className="content_PID">
                                                            <h1 className="openSans-Bold">Welcome to Karya Work Values Profiler</h1>
                                                                <div className="flex width-100 flex-start">
                                                                    <button className="flex times"> 
                                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={times} />
                                                                        <p>10 Minutes</p>
                                                                    </button>
                                                               </div>
                                                               <p>There are plenty of people who are in the right job that fits with their interests and personality, but they still don't find fulfillment in their job. Why is that? Most of the time, it's because they haven't really identified what their value the most in their ideal job, what's actually will bring them fulfillment. </p>
                                                                <p>When you know what you truly values, you will be able to choose your job better and even choose a suitable organization that you will be happy to work for, an organization that values what you truly value. </p>
                                                                <p>This profiler will assess the work values preferences of you. All the content in this profiler is adapted from the theories of Dr. Shalom Schwartz regarding 10 basic values of human.</p>
                                                            </div>
                                                            <div className="pid_getStarted">
                                                            <a href="/Values_GetStarted">
                                                                <button>
                                                                    <p>Get Started</p>
                                                                </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="width-40 dekstop-only height-100" >
                                                            <div className="width-100 flex height-100" style={{background :`url(${bg_pid})`, backgroundSize:"cover"}}>
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={bg_art} className="art_PID" />
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mobile-tablet">
                                    <div className="page-container-inner  width-100">
                                        <div className="box-edu-container width-100">
                                            <div className="box_PID" style={{height:"fit-content"}}>
                                                <div className="flex width-100 height-100 column">
                                                    <div className="width-100 height-100" >
                                                        <div className="width-100 height-100" >
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={bgMobile} className="width-100 height-100" />
                                                        </div>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={bg_art} className="art_PID" />
                                                    </div>
                                                    <div className="flex column width-100 space-between">
                                                        <div className="content_PID">
                                                            <h1>Welcome to Karya Personality Profiler</h1>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Complete the 16 questions assessment</p>
                                                            </div>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Discover your personality profile </p>
                                                            </div>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Boost awareness of your strength and areas of development</p>
                                                            </div>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Know how to be your best at work and life</p>
                                                            </div>
                                                            <div className="flex width-100 list_PID" style={{display:"none"}}>
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Boost awareness of your strengths and weaknesses</p>
                                                            </div>
                                                        </div>
                                                        <div className="pid_getStarted">
                                                        <a href="/Personality_GetStarted">
                                                            <button>
                                                                <p>Get Started</p>
                                                            </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default componentName;