import {
  GET_JOB,
  JOB_DETAIL,
  JOB_TRAINING,
  JOB_EXCLUDE,
  JOB_OPPORTUNITY,
  GET_PENGETAHUAN,
  GET_KETERAMPILAN,
  GET_KEMAMPUAN,
  GET_JOB_TYPE,
  GET_KARAKTER,
  GET_NILAI,
  DETAIL_JOB,
  GET_ALL_CITY,
  DETAIL_JOB_OPPORTUNITY,
  GET_JOB_BY_TITLE,
  GET_JOB_OPPORTUNITY_PUBLIC,
  APPLY_JOB,
  GET_JOB_DETAIL_PUBLIC,
  GET_JOB_CAREER_CENTER,
  GET_PROGRAM,
  GET_POPULAR1,
  GET_POPULAR2,
  GET_POPULAR3,
  GET_USER_COURSE_DLP,
  GET_USER_PROGRAM_DLP,
  GET_DETAIL_CONTENT_DLP,
  GET_DETAIL_PROGRAM_DLP,
  GET_COURSE_BY_CONTENT_DLP,
  GET_COURSE_DETAIL_DLP,
  GET_DROPDOWN_REGISTRATION_DLP,
  GET_ALLPROGRAM_DLP,
  GET_ABOUT_DLP,
  GET_KNOW_PROGRAM,
  GET_JOB_BY_INTEREST,
} from '../config/constants';
import axios from 'axios';
import { API } from '../_redux/helper';


const token=  sessionStorage.getItem('token')
export const getJob = (user_id) => {
  return {
    type: GET_JOB,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjob/${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};
export const getJob2 = (user_id) => {
  return {
    type: GET_JOB_CAREER_CENTER,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjob2/${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getDetail = (job_id) => {
  return {
    type: JOB_DETAIL,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjobdetail/${job_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};


export const getTraining = (user_id) => {
  return {
    type: JOB_TRAINING,
    payload: axios({
      method: 'GET',
      url: `${API}/api/gettrainingjobdetail/${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getJobExclude = (job_id, user_id) => {
  return {
    type: JOB_EXCLUDE,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjobexclude/${user_id}?job_id=${job_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getPengetahuan = (pengetahuan_id) => {
  return {
    type: GET_PENGETAHUAN,
    payload: axios({
      method: 'GET',
      url: `${API}/api/pengetahuan?pengetahuan_id=${pengetahuan_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};
export const getKeterampilan = (keterampilan_id) => {
  return {
    type: GET_KETERAMPILAN,
    payload: axios({
      method: 'GET',
      url: `${API}/api/keterampilan?keterampilan_id=${keterampilan_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};
export const getKemampuan = (kemampuan_id) => {
  return {
    type: GET_KEMAMPUAN,
    payload: axios({
      method: 'GET',
      url: `${API}/api/kemampuan?kemampuan_id=${kemampuan_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};
export const getKarakter = (karakter_kerja_id) => {
  return {
    type: GET_KARAKTER,
    payload: axios({
      method: 'GET',
      url: `${API}/api/karakter_kerja?karakter_kerja_id=${karakter_kerja_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};
export const getNilai = (nilai_kerja_id) => {
  return {
    type: GET_NILAI,
    payload: axios({
      method: 'GET',
      url: `${API}/api/nilai_kerja?nilai_kerja_id=${nilai_kerja_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getDetailJob = (job_id) => {
  return {
    type: DETAIL_JOB,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjobtask/${job_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getJobOpportunity = (user_id) => {
  return {
    type: JOB_OPPORTUNITY,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjob_opportunity_recommended/${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getDetailOpportunity = (job_id, user_id) => {
  return {
    type: DETAIL_JOB_OPPORTUNITY,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjob_opportunity_detail/${job_id}?user_id=${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getAllCity = (country_id) => {
  return {
    type: GET_ALL_CITY,
    payload: axios({
      method: 'GET',
      url: `${API}/api/city?country_id=${country_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getJobType = (job_type) => {
  return {
    type: GET_JOB_TYPE,
    payload: axios({
      method: 'GET',
      url: `${API}/api/m_job_type`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const jobByTitle = (
  user_id,
  job_title,
  country_id,
  city_id,
  job_function_id,
  industry_id,
  job_level_id,
  salary_from,
  salary_to,
  job_type
) => {
  return {
    type: GET_JOB_BY_TITLE,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjob_opportunity/${user_id}?job_title=${job_title}&country_id=${country_id}&city_id=${city_id}&job_function_id=${job_function_id}&industry_id=${industry_id}&job_level_id=${job_level_id}&salary_from=${salary_from}&salary_to=${salary_to}&job_type_id=${job_type}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getJobOpportunityPublic = () => {
  return {
    type: GET_JOB_OPPORTUNITY_PUBLIC,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjob_opportunity_public`,
    }),
  };
};

export const getJobDetailPublic = (job_opportunity_id) => {
  return {
    type: GET_JOB_DETAIL_PUBLIC,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getjob_opportunity_detail_public/${job_opportunity_id}`,
    }),
  };
};

export const applyJob = (user) => {
  return {
    type: APPLY_JOB,
    payload: axios({
      method: 'POST',
      url: `${API}/api/user_apply_job`,
      data: user,
      headers: {
        Authorization: ` ${token}`,
      },
    }).then((res) => {
      window.location.reload(true);
    }),
  };
};

export const getProgram = (user) => {
  return {
    type: GET_PROGRAM,
    payload: axios({
      method: 'GET',
      url: `${API}/api/program`,
      // headers: {
      //   Authorization: ` ${token}`,
      // },
    }),
  };
};

export const popularJob1 = (user_id) => {
  return {
    type: GET_POPULAR1,
    payload: axios({
      method: 'GET',
      url: `${API}/api/get_popularjob/${user_id}?position=1`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const popularJob2 = (user_id) => {
  return {
    type: GET_POPULAR2,
    payload: axios({
      method: 'GET',
      url: `${API}/api/get_popularjob/${user_id}?position=2`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const popularJob3 = (user_id) => {
  return {
    type: GET_POPULAR3,
    payload: axios({
      method: 'GET',
      url: `${API}/api/get_popularjob/${user_id}?position=3`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getUserCouseDLP = (user_id) => {
  return {
    type: GET_USER_COURSE_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/course/usercourse/${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getUserProgramDLP = (user_id) => {
  return {
    type: GET_USER_PROGRAM_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/program/user/${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getAllProgramDLP = (user_id) => {
  return {
    type: GET_ALLPROGRAM_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/program`,
    }),
  };
};

export const getDetailProgramDLP = (program_id) => {
  return {
    type: GET_DETAIL_PROGRAM_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getprogramlearn_by_program/${program_id}`,
    }),
  };
};

export const getDetailContentDLP = (program_id) => {
  return {
    type: GET_DETAIL_CONTENT_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getcontent_by_program/${program_id}`,
    }),
  };
};

export const getAboutDLP = (program_id) => {
  return {
    type: GET_ABOUT_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/program/${program_id}`,
    }),
  };
};

export const getCourseContentDLP = (content_id) => {
  return {
    type: GET_COURSE_BY_CONTENT_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getcourse_by_content/${content_id}`,
    }),
  };
};
export const getCourseDetailDLP = (course_id) => {
  return {
    type: GET_COURSE_DETAIL_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/getcoursedetail_by_course/${course_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    }),
  };
};

export const getDropdownReg = (course_id) => {
  return {
    type: GET_DROPDOWN_REGISTRATION_DLP,
    payload: axios({
      method: 'GET',
      url: `${API}/api/dlp/dropdown`,
    }),
  };
};

export const getKnowProgram = (program_id) => {
  return {
    type: GET_KNOW_PROGRAM,
    payload: axios({
      method: 'GET',
      url: `${API}/api/know_program`,
    }),
  };
};

export const getJobByInterest = (id, name) => {
  return {
    type: GET_JOB_BY_INTEREST,
    payload: axios({
      method: 'GET',
      url: `${API}/api/get_popularjob_by_interest/${id}`,
    }),
  };
};
