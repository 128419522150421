import React, { Component } from "react";
import Navbar_Homepage from "../components/Navbar_Homepage";
import { connect } from "react-redux";
import Footer from "../components/Footer";
import { getContact, postMessage } from "../_actions/article";
import mail from "../images/icons/mail.svg";
import Loading from "../components/Loading";
import phone from "../images/icons/phone.svg";
import address from "../images/icons/address.svg";
import mailgo from "mailgo";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import ScrollToTop from "../components/ScrollToTop";
import ReactGA from "react-ga";
import { GAevent } from "../components/TrackingEvent";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPixel from "react-facebook-pixel";

class Hubungi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email_from: "",
        phone: "",
        nama: "",
        subject: "",
        pesan: "",
      },
      errors: {},
      disabled: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    mailgo();
    this.props.getContact();
    sessionStorage.setItem("setupTime", new Date().getTime());
    if (localStorage.getItem("token")) {
      window.location.href = "/NewDashboard";
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname + window.location.search);
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["nama"]) {
      formIsValid = false;
      errors["nama"] = "Full Name cannot be empty";
    }

    if (typeof fields["nama"] !== "undefined" && !fields["nama"] === false) {
      if (!fields["nama"].match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
        formIsValid = false;
        errors["nama"] = "Only letters";
      }
    }
    // if(!fields["phone"]){
    //     formIsValid = false;
    //     errors["phone"] = "Phone number cannot be empty";
    //  }

    //  if(typeof fields["phone"] !== "undefined" && !fields["phone"] === false){
    //     if(!fields["phone"].match(/^[0-9]*$/)){
    //        formIsValid = false;
    //        errors["phone"] = "Only Number";
    //     }
    //  }

    if (!fields["email_from"]) {
      formIsValid = false;
      errors["email_from"] = "Email cannot be empty";
    }

    if (
      typeof fields["email_from"] !== "undefined" &&
      !fields["email_from"] === false
    ) {
      let lastAtPos = fields["email_from"].lastIndexOf("@");
      let lastDotPos = fields["email_from"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email_from"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email_from"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email_from"] = "Email is not valid";
      }
    }

    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "Subject cannot be empty";
    }

    if (
      typeof fields["subject"] !== "undefined" &&
      !fields["subject"] === false
    ) {
      if (!fields["subject"].match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
        formIsValid = false;
        errors["subject"] = "Only letters";
      }
    }

    if (!fields["pesan"]) {
      formIsValid = false;
      errors["pesan"] = "Message cannot be empty";
    }

    if (typeof fields["pesan"] !== "undefined" && !fields["pesan"] === false) {
      if (!fields["pesan"].match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
        formIsValid = false;
        errors["pesan"] = "Only letters";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  createNotification = (type) => {
    return () => {
      switch (type) {
        case "info":
          NotificationManager.info(
            "Kamu belum melakukan verifikasi email",
            "Verifikasi Email",
            9000,
            () => {
              alert("callback");
            }
          );
          break;
        case "success":
          NotificationManager.success("Pesan sudah terkirim");
          break;
        case "warning":
          NotificationManager.warning(
            "Kamu belum melakukan verifikasi email",
            "Verifikasi Email",
            9000,
            () => {
              alert("callback");
            }
          );
          break;
        case "error":
          NotificationManager.error(
            "Email/Password yang Anda masukan salah",
            "Ups.. Maaf",
            5000
          );
          break;
      }
    };
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };
  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  };
  onChangePhone = (event) => {
    this.setState({ phone_number: event.target.value });
  };
  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  messages = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      const dataMessage = {
        nama: this.state.fields["nama"],
        subject: this.state.fields["subject"],
        email_from: this.state.fields["email_from"],
        phone: this.state.fields["phone"],
        pesan: this.state.fields["pesan"],
      };
      this.props.message1(dataMessage);
    } else {
    }

    const success = this.createNotification("success");
    const nama1 = this.state.fields["nama"];
    const subject1 = this.state.fields["subject"];
    const email_from1 = this.state.fields["email_from"];
    const pesan1 = this.state.fields["pesan"];

    if (
      nama1 !== "" &&
      subject1 !== "" &&
      email_from1 !== "" &&
      pesan1 !== ""
    ) {
      success();
      window.location.href = "/";
    }
    GAevent("ContactUs", "Send Message");
  };

  render() {
    const { data, isLoading, error } = this.props.contact1;

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Mohon, refresh halaman ini!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <div>
        <Navbar_Homepage status="bg-ff fsh_" />
        <div
          className="box-wrapper box-wrapper-master width-100"
          style={{ position: "relative" }}
        >
          <div className="wrapper flex">
            <div className="content flex column">
              <div className="page-container mt-80 master-page contact-us width-100">
                <div className="page-container-outer spaceContact">
                  <div className="box-title width-100">
                    <h3
                      className=" width-100"
                      style={{ fontFamily: "League Spartan" }}
                    >
                      CONTACT US
                    </h3>
                    <div className="line-horizontal width-100"></div>
                  </div>
                  <div className="box-contact-us width-100">
                    <div className="contact-us-label width-50">
                      <div
                        className="list-article width-100"
                        style={{ display: "none" }}
                      >
                        <div className="list-article-img-thumbnail">
                          <LazyLoadImage
                            effect="blur"
                            loading="lazy"
                            src={phone}
                            alt=""
                          />
                        </div>
                        <div className="list-article-content">
                          <div className="article-info width-100">
                            <h6 className="article-category">Phone</h6>
                          </div>
                          <h3 className="width-100">{data.phone} </h3>
                        </div>
                      </div>
                      <div className="list-article width-100">
                        <div className="list-article-img-thumbnail">
                          <LazyLoadImage
                            effect="blur"
                            loading="lazy"
                            alt=""
                            src={mail}
                          />
                        </div>
                        <div className="list-article-content">
                          <div className="article-info width-100">
                            <h6 className="article-category">Email</h6>
                          </div>
                          <a
                            className="App-link"
                            href={`mailto:${data.email}`}
                            rel="noopener noreferrer"
                          >
                            <h3 className="width-100">{data.email} </h3>
                          </a>
                        </div>
                      </div>
                      <div className="list-article width-100">
                        <div className="list-article-img-thumbnail">
                          <LazyLoadImage
                            effect="blur"
                            loading="lazy"
                            src={address}
                            alt=""
                          />
                        </div>
                        <div className="list-article-content">
                          <div className="article-info width-100">
                            <h6 className="article-category">Office</h6>
                          </div>
                          <h3
                            className="width-100"
                            dangerouslySetInnerHTML={{ __html: data.address }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="contact-us-label width-50">
                      <h4>Leave Message</h4>
                      {/* <Fade right> */}
                      <form onSubmit={this.messages} id="message">
                        <div className="box-label-input width-100">
                          <div className="box-label-group width-100">
                            <p className="label-input">Full Name</p>
                            <p className="red-star">*</p>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="nama"
                            id="nama"
                            pattern="[A-Za-z- ]{3,30}"
                            minLength="4"
                            required=""
                            refs="nama"
                            onChange={this.handleChange.bind(this, "nama")}
                            value={this.state.fields["nama"]}
                          />
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {this.state.errors["nama"]}
                          </span>
                          {/* {resName} */}
                        </div>
                        <div className="box-label-input width-100">
                          <div className="box-label-group width-100">
                            <p className="label-input">Email</p>
                            <p className="red-star">*</p>
                          </div>
                          <input
                            type="email"
                            name="email_from"
                            id="email_from"
                            refs="email_from"
                            className="form-control width-50"
                            onChange={this.handleChange.bind(
                              this,
                              "email_from"
                            )}
                            value={this.state.fields["email_from"]}
                            aria-describedby="emailHelp"
                          />
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {this.state.errors["email_from"]}
                          </span>
                        </div>
                        <div className="box-label-input width-100">
                          <div className="box-label-group width-100">
                            <p className="label-input">Phone Number</p>
                            {/* <p className="red-star">*</p> */}
                          </div>
                          <input
                            type="text"
                            className="form-control width-50"
                            refs="phone"
                            onChange={this.handleChange.bind(this, "phone")}
                            value={this.state.fields["phone"]}
                            name="phone"
                            id="phone"
                          />
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {this.state.errors["phone"]}
                          </span>
                        </div>
                        <div className="box-label-input width-100">
                          <div className="box-label-group width-100">
                            <p className="label-input">Subject</p>
                            <p className="red-star">*</p>
                          </div>
                          <input
                            type="text"
                            className="form-control width-50"
                            onChange={this.handleChange.bind(this, "subject")}
                            value={this.state.fields["subject"]}
                            name="subject"
                            id="subject"
                            refs="subject"
                          />
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {this.state.errors["subject"]}
                          </span>
                        </div>
                        <div className="box-label-input width-100">
                          <div className="box-label-group width-100">
                            <p className="label-input">Message</p>
                            <p className="red-star">*</p>
                          </div>
                          <textarea
                            className="form-control"
                            aria-label="With textarea"
                            rows="5"
                            refs="pesan"
                            onChange={this.handleChange.bind(this, "pesan")}
                            value={this.state.fields["pesan"]}
                            name="pesan"
                            id="pesan"
                          ></textarea>
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {this.state.errors["pesan"]}
                          </span>
                        </div>
                        <div className="hubungi">
                          <div className="box-label-input group-btn-label width-100">
                            <div className="box-label-group group-btn-label  width-100">
                              <p className="red-star">*</p>
                              <p className="label-input">is required</p>
                            </div>
                            <button
                              className="btn btn-primary width-100"
                              id="button"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                      {/* </Fade> */}
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ScrollToTop />
        {/* <NotificationContainer/> */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    contact1: state.contact,
    dataMessage: state.message,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContact: () => {
      dispatch(getContact());
    },
    message1: (event) => {
      dispatch(postMessage(event));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hubungi);
