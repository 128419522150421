import React, { Component } from 'react';
import checkbox from "../images/whitecheckbox.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';

class componentName extends Component {

    render() {
        return (
            <div>
                <div className="job_applied">
                    <div className="flex column">
                        <div className="circle">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={checkbox} />
                        </div>
                        <p className="title">Thankyou</p>
                        <p className="text">You have successfully applied this job</p>
                        <button>Close</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default componentName;