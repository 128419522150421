import React, { Component } from "react";
import { connect } from "react-redux";
import karya from "../images/logoBaru.png";
import Report from "../images/Report.png";
import ire11 from "../images/icons/conventional.svg";
import bag from "../images/icons/job.svg";
import checklist from "../images/icons/checklist.svg";
import print from "../images/Cetak.svg";
import download from "../images/Unduh.svg";
import share from "../images/Bagikan.svg";
import Loading from '../components/Loading'
import nav_top from "../images/icons/nav-top.svg";
import Modal from 'react-awesome-modal';
import Share from '../components/Share'
import { getResult, getAllResult } from "../_actions/quest";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ExpandCollapse from "react-expand-collapse";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import {conv,inves,real} from "../images/svg/svg"
import conv from "../images/Conventional.svg"
import real from "../images/Realistic.svg"
import inves from "../images/Investigate.svg"
import ReactDOM from "react-dom";
import Scrollbar from 'react-scrollbars-custom';
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';



class AssesmentReport extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      list_img :[conv, inves,real]
    };
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getResult(user_id);
    this.props.getAllResult(user_id);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0)
  }

  openModal(e) {
    if (e === 'link') {
        this.setState({
            link: true
        });
    }
}

closeModal() {
    this.setState({
        link: false,
    });
}

  goBack() {
    this.props.history.goBack();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  print() {
    window.print("/")
    GAevent('AssessmentReport','Print') 
};

  
exportPDFWithComponent = () => {
  this.pdfExportComponent.save();
  this.setState({ showStore: true });
  this.setState({ sizeStore: true });
  GAevent('AssessmentReport','Download') 
  setTimeout(() => {
    window.location.reload()
  }, 2000);
}



  render() {
    const { data, isLoading, error } = this.props.result1;
    const { allRes } = this.props.result2;
    
    const white_ck = <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 9.42385L9.03114 16L24 2" stroke="white" stroke-width="3"/>
    </svg>
    
    const bag_ = <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3595 2.57829C21.3582 2.57829 21.3568 2.57812 21.3555 2.57812H15.5117V1.93359C15.5117 0.867432 14.6443 0 13.5781 0H8.42188C7.35571 0 6.48828 0.867432 6.48828 1.93359V2.57812H0.644531C0.285675 2.57812 0 2.87219 0 3.22266V17.4023C0 18.4685 0.867432 19.3359 1.93359 19.3359H20.0664C21.1326 19.3359 22 18.4685 22 17.4023V3.23625C22 3.23541 22 3.23457 22 3.23373C21.9753 2.81042 21.715 2.58064 21.3595 2.57829ZM7.77734 1.93359C7.77734 1.57826 8.06654 1.28906 8.42188 1.28906H13.5781C13.9335 1.28906 14.2227 1.57826 14.2227 1.93359V2.57812H7.77734V1.93359ZM20.4612 3.86719L18.4596 9.87173C18.3718 10.1354 18.1261 10.3125 17.8483 10.3125H14.2227V9.66797C14.2227 9.31197 13.9341 9.02344 13.5781 9.02344H8.42188C8.06587 9.02344 7.77734 9.31197 7.77734 9.66797V10.3125H4.15169C3.8739 10.3125 3.62817 10.1354 3.54039 9.87173L1.53882 3.86719H20.4612ZM12.9336 10.3125V11.6016H9.06641V10.3125H12.9336ZM20.7109 17.4023C20.7109 17.7577 20.4217 18.0469 20.0664 18.0469H1.93359C1.57826 18.0469 1.28906 17.7577 1.28906 17.4023V7.19441L2.31746 10.2794C2.58098 11.0703 3.31816 11.6016 4.15169 11.6016H7.77734V12.2461C7.77734 12.6021 8.06587 12.8906 8.42188 12.8906H13.5781C13.9341 12.8906 14.2227 12.6021 14.2227 12.2461V11.6016H17.8483C18.6818 11.6016 19.419 11.0703 19.6825 10.2794L20.7109 7.19441V17.4023Z" fill="#E0E0E0"/>
    </svg>
    
  const ref = React.createRef();
    // {data.map((item, i) => {
      if (error) {
        return (
          <div className="message-question-load">
            <h3>Ups... Mohon, refresh halaman ini!</h3>
          </div>
        );
      }
  
      if (isLoading) {
        return (
          <React.Fragment>
            <Loading />
           </React.Fragment>
        );
      }


    return (
      <div>
        <div className="edu ar_">
        <PDFExport ref={component => (this.pdfExportComponent = component)} className="asReport" fileName={`Assessment Report`} >
        <div className="box-wrapper orange" id="bgk">
          <div className="wrapper">
            <div className="content">
              <div className="page-container assessment-result width-100">
                <div className="background-top width-100 flex" id="bgk"></div>

                <div className="page-container-outer">
                  <div className="box-logo width-100 dekstop" >
                    {/* <Link to="/"> */}
                    <div className="box-logo-img" style={{display:"flex", zIndex:"100"}}>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya}  />
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="mobile-information width-100">
                  <div className="box-logo width-100" >
                    <div className="box-logo-img" style={{display:"flex", zIndex:"100"}}>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya}  />
                    </div>
                  </div>
                  </div>
                  <div className="page-container-inner width-100" id="botttomAr" ref={ref} style={{display:"flex",zIndex:"99",position:"relative"}}>
                    <div className="box-edu-container width-100" id="boxReport" >
                      <div className="box-edu-description mobile expandmobile" id="no-print">
                        <ExpandCollapse
                          previewHeight="126px"
                          expandText="wew"
                          ellipsisText="" id="no-print"
                        >
                          <div className="edu-desc-title width-100" id="no-print">
                            <div className="box-img-inner ">
                              <div className="box-img done flex center">
                                {/* <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist" src={checklist}  /> */}
                                {white_ck}
                              </div>
                            </div>
                            <div className="box-img-inner active">
                              <div className="box-img done flex center">
                                {/* <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist" src={checklist} /> */}
                                {white_ck}
                              </div>
                            </div>
                            <div className="box-img-inner">
                              <div className="box-img flex center" style={{border: "1px solid #E0E0E0"}}>
                                {/* <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist" src={bag} /> */}
                                {bag_}
                              </div>
                            </div>
                          </div>
                          <div className="edu-desc-content width-100">
                            <div className="box-image">
                              <div className="background-img"></div>
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={Report}  />
                            </div>

                            <h5>My Work Interest Assessment Result </h5>
                            <p>
                            Your assessment result is here! The graph on the top right of the page shows the score of your preferred work interests. The higher the score, the more you prefer the activities on those particular interests
                            </p>
                          </div>

                          <div className="report">
                            <div className="edu-desc-footer rekom">
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={this.goBack}
                              >
                                Back
                              </button>
                              <Link to="/JobRecommendation">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  style={{ left: "0px" }}
                                >
                                 Job Recommendation
                                </button>
                              </Link>
                            </div>
                          </div>

                          <div className="edu-desc-nav width-100">
                            <div className="edu-desc-nav-img">
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={nav_top}  />
                            </div>
                          </div>
                        </ExpandCollapse>
                      </div>

                      <div className="box-edu-description questions-desktop" id="no-print">
                        <div className="edu-desc-title width-100">
                          <div className="box-img-inner ">
                            <div className="box-img done flex center">
                              {white_ck}
                            </div>
                          </div>
                          <div className="box-img-inner active">
                            <div className="box-img done flex center">
                            {white_ck}
                              {/* <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist" src={checklist} /> */}
                            </div>
                          </div>
                          <div className="box-img-inner">
                            <div className="box-img flex center" style={{border: "1px solid #E0E0E0"}}>
                              {/* <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist" src={bag} /> */}
                              {bag_}
                            </div>
                          </div>
                        </div>
                        <div className="edu-desc-content width-100">
                          <div className="box-image">
                            <div className="background-img"></div>
                            <LazyLoadImage effect="blur" loading="lazy"  src={Report} alt="" />
                          </div>

                          <h5>My Work Interest Assessment Result </h5>
                          <p>
                          Your assessment result is here! The graph on the top right of the page shows the score of your preferred work interests. The higher the score, the more you prefer the activities on those particular interests
                          </p>
                        </div>
                        <div className="edu-desc-footer rekom">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={this.goBack}
                          >
                            Back
                          </button>
                          <Link to="/JobRecommendation">
                            <button type="button" className="btn btn-primary">
                            Job Recommendation
                            </button>
                          </Link>
                        </div>

                        <div className="edu-desc-nav width-100">
                          <div className="edu-desc-nav-img">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={nav_top}  />
                          </div>
                        </div>
                      </div>
                      <div className="assessment-rpt">
                     
                        <div className="box-edu-content " id="width-106">
                          <div className="box-assessment-title width-100">
                            <h4 className=" width-100">
                            My Work Interest Assessment Result 
                            </h4>
                            <div className="assessment-btn" id="no-print" style={{display:this.state.showStore?"none":""}}>
                                <button type="button" className="btn btn-outline-secondary" onClick={this.print}>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={print} />Print
                                </button>
                                <button type="button" className="btn btn-outline-secondary" onClick={this.exportPDFWithComponent}>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={download} />Download
                                </button>
                                <button type="button" className="btn btn-outline-secondary" onClick={() => this.openModal('link')} style={{display:"none"}} >
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} />Bagikan
                                </button>
                            </div>
                          </div>
                          <div className="box-horizontal-line width-100">
                            <div className="horizontal-line width-100"></div>
                          </div>
                          <div className="box-assessment-result width-100">
                            <div className="assessment-result-profile width-50">
                              {data.map((item, i) => {
                                if (i === 0)
                                  return (
                                    <h4 key={i} className=" width-100" style={{textTransform:"capitalize"}}>
                                      {item.name}
                                    </h4>
                                  );
                              })}
                              {/* NURI INDAYANI */}

                              <div className="box-result-profile-label width-100">
                                {data.map((item, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="result-profile-label conventional "
                                      style={{
                                        background: `${item.color}`,
                                        border: `1px solid ${item.color}`,
                                      }}
                                    >
                                      <p style={{ padding: 0, margin: 0 }}>
                                        {item.interest_name}{" "}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="assessment-result-grafik width-50">
                              {allRes.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="box-result-grafik width-100"
                                  >
                                    <div className="result-grafik-label">
                                      <p>{item.interest_name} </p>
                                    </div>
                                    <div className="result-grafik-value">
                                      <div className="progress">
                                        <div
                                          className="progress-bar bg-realistic"
                                          role="progressbar"
                                          style={{
                                            width: `${item.scoring * 4}%`,
                                            backgroundColor: `${item.color}`,
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          <div
                                            className="progress-value realistic"
                                            style={{
                                              backgroundColor: `${item.color}`,
                                            }}
                                          >
                                            {item.scoring * 4}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="box-assessment-description width-100" id="box-assessment-print">
                            {data.map((item, i) => {
                              return (
                                <div key={i} className="assessment-description">
                                  <div className="assessment-description-inner width-100" id="box-description-inner">
                                    <div className="assessment-description-title width-100">
                                      <div className="box-description-title-img  width-100">
                                        <div
                                          className="description-title-img conventional flex center"
                                          style={{
                                            backgroundColor: `${item.color}`,
                                            border: `1px solid ${item.color}`,
                                          }}
                                        >
                                          <LazyLoadImage effect="blur"
                                            className="icon-map"
                                            src={this.state.list_img[i]} style={{ height:"60%", width: "60%", padding:"0px" }}
                                          />
                                        </div>
                                      </div>
                                      <h4
                                        className="conventional-h4 width-100"
                                        style={{ color: `${item.color}` }}
                                      >
                                        {item.interest_name}{" "}
                                      </h4>
                                      <h5
                                        className="conventional-h5 width-100"
                                        style={{ color: `${item.color}` }}
                                      >
                                        {item.interest_type}{" "}
                                      </h5>
                                    </div>
                                    <div className="assessment-description-content width-100" id="box-description-content">
                                    <Scrollbar style={{height:"100%"}} id="height-100">
                                      <p className=" width-95"  >
                                        {item.interest_description}
                                      </p>
                                      </Scrollbar>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className="edu-desc-footer width-100">
                            <Link to="/JobRecommendation">
                              <button type="button" className="btn btn-primary">
                              Job Recommendation
                              </button>
                            </Link>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <Modal className="modal-link" id="link" visible={this.state.link} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div id="modal-header" className="modal-header-sign">
                        <a href="javascript:void(0);" onClick={() => this.closeModal()}></a>
                    </div>
                    <Share/>
                </Modal>
          </div>
          </PDFExport>
        </div>
      
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    result1: state.result,
    result2: state.allResult,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResult: (user_id) => {
      dispatch(getResult(user_id));
    },
    getAllResult: (user_id) => {
      dispatch(getAllResult(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssesmentReport);