import React from 'react'

const _PageTemplateFooterVip = (props) => {

    if(props.pageNum > 1){
        
        return (<div style={{ position: 'absolute', bottom: '0px' }} className='flex width-100 column footer-vip-template'>
                <div className='flex'>
                <p className='openSans'>VIP Profiler</p>
                <p className='openSans'> &nbsp;| {props.pageNum - 1}</p>
                </div>
            </div>)

    }
    return <></>
         
}

export default _PageTemplateFooterVip
