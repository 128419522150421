import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./Loading";
import {getProfileId} from "../_actions/user";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams } from "react-router-dom";
import { API } from "../_redux/helper";
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import bookIcon from "../images/icons/icons/book-icon.svg"
import videoIcon from "../images/icons/icons/video-icon.svg"
import curvedVector from "../images/curved-vector.svg"
import graph from "../images/graph.svg"
import graph1 from "../images/graph-1.svg"
import graph2 from "../images/graph-2.svg"
import frame from "../images/frame.svg"
import logo from "../images/KaryaTalents_logo.svg"
import badge from "../images/badge.svg"
import { PDFExport } from "@progress/kendo-react-pdf";
import { API_DLP } from "../_redux/helper"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// material ui
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// icon
import lock from "../images/lock.svg";
import dlc_certificate from "../images/dlc_certificate.svg";
import dlc_check from "../images/dlc_check.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from '@material-ui/icons/Check';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { LazyLoadImage } from 'react-lazy-load-image-component';


class DlcCourseOutline extends Component {
  constructor() {
    super();
    this.state = {
        list_courses: [],
        course_detail: [],
        user_dlp_profile: [],
        desc_content:[],
        user_id: sessionStorage.getItem("i-dlp"),
        error: false,
        loading: true,
        visiblePrint: false,
        end_date_course: "",
        visible:true,
        phase:"",
        list_id_video:[],
        list_phase:[],
        expanded:"panel1",
        list_acc:[],
        status:[],
        index_program:"",
        char:true,
        interest:"",
        vip:"",
        completed_date:"2021-06-10T17:00:00.000Z"
    };
  }


  async componentDidMount() {
    const { ownProps } = this.props.ownProps
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.props.getProfileId(user_id);

    axios            
    .get(`${API}/api/program/user/${sessionStorage.getItem("i-dlp")}`)
    .then((data) => {
      data.data.map((item,i)=>{
          /* eslint eqeqeq: 0 */
          if (item.program_id ==ownProps.match.params.program_id  && item.completed_date!==null){
              this.setState({completed_date : item.completed_date})
          }
      })
    })

    this.setState({
        loading: true
      })
      let arr = []
      let content_id = []
      let content_courses = []
      await Promise.all(
        [
            axios
            .get(`${API_DLP}/api/getcontent_by_program/${ownProps.match.params.program_id}`)
            .then((courseModel) => {
                this.setState({
                    list_courses : courseModel.data
                })
                let list_phase = []
                courseModel.data.map((item,i)=>{
                    list_phase.push({content_id:item.program_content_id, name:item.program_content_name})
                    content_id.push(item.program_content_id)
                    this.state.list_phase.push(item.program_content_name)
                    sessionStorage.setItem("list-phase", this.state.list_phase)
                })
                this.setState({phase:list_phase})
                sessionStorage.setItem("content_id", content_id)
                courseModel.data.forEach(el => {
                    arr.push(
                        new Promise((resolve, reject) => {
                            axios
                            .get(`${API_DLP}/api/getusercourse_by_content/${this.state.user_id}/${el.program_content_id}`)
                            .then((courses) => {
                                el["content_courses"] = courses.data
                                resolve(courses.data)
                                courses.data.map((item,i)=>{
                                   if (i==0){
                                       this.setState({index_program:item.course_id})
                                       axios
                                       .get(`${API_DLP}/api/getusercoursedetail_by_course/${this.state.user_id}/${item.course_id}`)
                                       .then((courseDetails) => {
                                           this.setState({
                                               list_acc : courseDetails.data
                                           })
                                       })
                                   }
                                    if (item.course_type_name=="Video"){
                                        sessionStorage.setItem("course_type_id",item.course_id)
                                        this.state.list_id_video.push(item.course_id)
                                        sessionStorage.setItem("list-vd-id", this.state.list_id_video)
                                    }
                                    
                                    const z= parseInt(item.status.split('/')[0])/parseInt(item.status.split('/')[1])
                                    this.state.status.push(parseInt(z))
                                })
                                content_courses.push({data:parseInt(courses.data.length)})
                            })
                        })
                    )
                });
            })
        ],
        [
            axios
                .get(`${API_DLP}/api/dlp/find/id/${this.state.user_id}`)
                .then(({data}) => {
                    this.setState({
                        user_dlp_profile : data
                    })
                })
        ],
        [
            axios
                .get(`${API_DLP}/api/program/${ownProps.match.params.program_id}`)
                .then(({data}) => {
                    this.setState({
                    desc_content: data[0]
                    })
                })
        ],
        [
            axios
                .get(`${API_DLP}/api/program/user/${this.state.user_id}`)
                .then(({data}) => {
                    data.forEach(el => {
                        if (el.program_id == ownProps.match.params.program_id) {
                            this.setState({
                                end_date_course: `${el.end_date.split("T")[0].split("-").reverse().join("-")}, 23:59 WIB`
                            })
                        }
                    });

                })
        ],
        [
            axios
            .get(`${API}/api/getresultquestion/${user_id}`, {headers: {
                Authorization: `${token1}`,
            }}).then(({data}) => {
                this.setState({interest:data})
            })
        ],
        [
            axios.get(`${API}/api/survey/findsubmission/${user_id}`)
            .then(response => {
                this.setState({vip:response})
            })
        ]
       
        )
      
        await Promise.all(arr).then((values)=>{
        if (values.length !== 0) {
          this.setState({
            loading: false
          })
        }
      })
  }

  componentDidUpdate(prevProps,prevState){
      if (this.state.status !== prevState.status){
        const status_length = this.state.status.length
        const status_reduce = this.state.status.reduce((a, b) => a + b, 0)
        if (status_length==status_reduce){
            window.location.reload()
        }
      }
  }

  getCourseContent = async (course_id) =>{
    let arr = []
    this.state.list_courses.forEach(el => {
        arr.push(
            new Promise((resolve, reject) => {
                axios
                .get(`${API_DLP}/api/getusercourse_by_content/${this.state.user_id}/${el.program_content_id}`)
                .then((courses) => {
                    el["content_courses"] = courses.data

                    resolve(courses.data)
                    let xc=[]
                    courses.data.map((item,i)=>{
                         const z= parseInt(item.status.split('/')[0])/parseInt(item.status.split('/')[1])
                        //  this.state.status.push(parseInt(z))
                        xc.push(parseInt(z))

                        
                     })
                  
                     if (xc.length !== 0){
                            const status_length = xc.length
                            const status_reduce = xc.reduce((a, b) => a + b, 0)
                            if (status_length==status_reduce){
                                window.location.reload()
                       } 

                     }
                })
            })
        )
    });
    await Promise.all(arr).then((values)=>{
        if (values.length !== 0) {
        this.getCourseDetail(course_id)
        }
    })
  }

  getCourseDetail = (id) => {
    axios
    .get(`${API_DLP}/api/getusercoursedetail_by_course/${this.state.user_id}/${id}`)
    .then((courseDetails) => {
        let listCourses = this.state.list_courses
        listCourses.forEach(listCourse => {
            listCourse.content_courses.forEach(contentCourse => {
                if (contentCourse.course_id == id) {
                    contentCourse["course_details"] = courseDetails.data 
                }
            });
        });
        this.setState({
            list_courses : listCourses
        })
    })
  }

  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
    // GAevent('Report PID','Download PDF') 
    this.setState({char:false})
    this.setState({visiblePrint:true})
    setTimeout(() => {
        window.location.reload()
    }, 2500);
  };

  updateProgres = (id, course_id, course_value) =>{
    axios.get(`${API_DLP}/api/course/getImage/${course_value}`)
    axios
    .get(`${API_DLP}/api/updateuser_progress/${id}`)
    .then(({data}) => {
        this.getCourseContent(course_id)
    })
  }

  setView=()=>{
    this.setState({view:true, retake:false})
    sessionStorage.setItem("view",true)
   }
 
   setRetake=()=>{
     this.setState({view:false, retake:true})
     sessionStorage.setItem("view",false)
 }
 handleChange = (panel) => (event, isExpanded) => {
      this.setState({expanded : (isExpanded ? panel : false)});
  };


  render() {
    const { profile } = this.props.profile1;
    const { ownProps } = this.props.ownProps
    const status_length = this.state.status.length
    const status_reduce = this.state.status.reduce((a, b) => a + b, 0)
    const val_a = this.state.completed_date.toString().slice(8,10)

    // const val_day = new Date(this.state.completed_date.toString().split(`${val_a}`).join(`${val_b}`)).getDay();
    const val_day = new Date(this.state.completed_date).getDay();
    const val_date = this.state.completed_date.toString().slice(8,10);
    const val_month = new Date(this.state.completed_date).getMonth();
    const val_year = new Date(this.state.completed_date).getFullYear();
   

    const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
   
    const updatedAt = day[val_day] + ', ' + val_date + ' ' + month[val_month] + '  ' + val_year ;

    let arrTitle = []
    let arrValue = []
    let allArr = []
    let score = []
    let test = []
    let check = true 
  
    this.state.list_courses != undefined && this.state.list_courses.length > 0 ?
    this.state.list_courses.map(x => {
        // dataReportValues.data != undefined && dataReportValues.data.length > 0 ?
        if(x.content_courses != undefined && x.content_courses.length > 0){
    //   if(x.content_courses.length != 0){
        arrTitle.push(x.program_content_name) 
        x.content_courses.map(y => {
          score.push(parseFloat(y.status)*1)
          test.push(y.status.split('/')[0] / y.status.split('/')[1])
          if(((y.status.split('/')[0] === y.status.split('/')[1]) && y.passing == "Passed!")
          || ((y.status.split('/')[0] === y.status.split('/')[1]) && (y.course_type_name=="Video" || y.course_type_name=="Book"))) {
            check = 1
          }else if(((y.status.split('/')[0] === y.status.split('/')[1]) && (y.passing == "Fail"))
                  || ((y.status.split('/')[0] !== y.status.split('/')[1]) && (y.status.split('/')[0] > 0) )
                  || ( y.course_type_id===2 && y.passing === "Fail")
                  || ((y.status.split('/')[0] > 0))) {
            check = 2
          } else {
            check = 3
          }
        })
        arrValue.push(check)
        allArr.push({name :x.program_content_name, status: check })
      } else {
        arrTitle.push(x.program_content_name)
        allArr.push({name :x.program_content_name, status: check })
      }
  
    // sum_stepper =  test.reduce((result,number)=> result+number);
    }):console.log("[err]");
    
    let val_interest 
    if (this.state.interest != undefined && this.state.interest.length > 0 ){
        this.state.interest.map((item,i)=>{
        if (i==0){
            val_interest = item.scoring
        }
    })}


    let vip_assessment 

    if (this.state.interest != undefined && this.state.interest.length > 0 ){
    if ((profile.is_work_assessment == true) && (val_interest > 0) && (this.state.vip !== "Data Not Found")){
    vip_assessment = 1
    }else if ((profile.is_work_assessment == true) || (val_interest > 0) || (this.state.vip !== "Data Not Found")){
    vip_assessment = 2
    }else{
    vip_assessment = 3
    }
    }



    if (this.state.error) {
        return (
          <div className="message-question-load">
            <h3>Ups... Please, refresh this page!</h3>
          </div>
        );
      }
  
      if (this.state.loading) {
        return (
          <React.Fragment>
            <Loading />
          </React.Fragment>
        );
      }

      const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        arrow: false,
        slidesToScroll: 1,
        // autoplay: true,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1310,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1074,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 373,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };



    return (
        <React.Fragment>
        <div className="flex width-100 column bg-header-stepper" style={{backgroundColor:"#F9F9F9"}}>
            <h2 className="HeaderTitleOutline">{this.state.desc_content.program_name}</h2>
            <p className="paragraphHeaderOutline">{this.state.desc_content.course_type}</p>
            {/* <DlcStepper list_courses={this.state.list_courses}/> */}
            <div className="flex width-100 column">
                <div className="dekstop width-100" style={{marginTop:"38px"}}>
                <div className="flex width-100 center" style={{marginTop:"20px"}}>
                    <div className="width-100 stepper-line-dlc"></div>
                </div>
                <div className="flex width-100 space-between col-list-stepper" style={{marginBottom:"26px"}}>
                    {allArr.map((item,i)=>{
                    return(
                    <div className="flex  column box-stepper-dlc">
                    <div className="step-dlc-outline">
                        {((i>0) && item.status== 3 && item.name.indexOf('Final Quiz')== -1)?
                        <div className="circ-stepper-progress">
                        </div>:""}
                        {((i==0) && item.status== 3 && item.name.indexOf('Final Quiz')== -1)?
                        <div className="circ-stepper-progress">
                        <div className="on-progress-dlc"/>
                    </div>:""}
                        {item.status== 2 ?
                        <div className="circ-stepper-progress">
                        <div className="on-progress-dlc"/>
                        </div>:""}
                        {item.status== 1 ?
                        <div className="circ-stepper-complete">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_check} className="auto" />
                        </div>:""}
                        {(item.status== 3 && item.name.indexOf('Final Quiz')!== -1) ?
                        <div className="circ-stepper-start">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lock} className="auto" />
                        </div>:""}
                        </div> 
                    <p className="openSans" style={{marginTop:"15px", color: item.status== 1 ?" #333333":""}}>{item.name} </p>
                    </div>
                    )})}
                    <div className="flex column box-stepper-dlc">
                    {(vip_assessment !== undefined) &&
                    <div className="step-dlc-outline">
                        {(vip_assessment== 3 )?
                        <div className="circ-stepper-progress">
                        </div>:""}
                        {vip_assessment== 2 ?
                        <div className="circ-stepper-progress">
                        <div className="on-progress-dlc"/>
                        </div>:""}
                        {vip_assessment== 1 ?
                        <div className="circ-stepper-complete">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_check} className="auto" />
                        </div>:""}
                        </div> }
                        {(vip_assessment == undefined) &&
                    <div className="step-dlc-outline">
                        <div className="circ-stepper-progress">
                        </div>
                        </div> }
                    <p className="openSans" style={{marginTop:"15px", color: vip_assessment== 1 ?" #333333":""}}>VIP Assessment </p>
                    </div>
                    <div className="flex  column box-stepper-dlc">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_certificate} className="img-certficate" onClick={(status_length==status_reduce)?this.exportPDFWithComponent:""} style={{cursor:"pointer"}}/>
                    <p  className="openSans" style={{marginTop:"15px",cursor:"pointer",color:(status_length==status_reduce)?" #333333":""}} onClick={(status_length==status_reduce)?this.exportPDFWithComponent:""}>Get Certificate</p>
                    </div>
                </div>
                </div>
                <div className="mobile width-100">
                    <div className="flex width-100 sm-dlc">
                    <div className="mobile height-100 box-line-dlc">
                        <div className="flex width-100 center height-100" style={{marginTop:"20px"}}>
                        <div className="width-100 stepper-line-dlc"></div>
                        </div>
                    </div>
                    <div className="mobile mobile-stepper">
                    {allArr.map((item,i)=>(
                        <div className="flex  column box-stepper-dlc">
                            <div className="step-dlc-outline">
                            {((i>0) && item.status== 3 && item.name.indexOf('Final Quiz')== -1)?
                            <div className="circ-stepper-progress">
                            </div>:""}
                            {((i==0) && item.status== 3 && item.name.indexOf('Final Quiz')== -1)?
                            <div className="circ-stepper-progress">
                            <div className="on-progress-dlc"/>
                            </div>:""}
                            {item.status== 2 ?
                            <div className="circ-stepper-progress">
                            <div className="on-progress-dlc"/>
                            </div>:""}
                            {item.status== 1 ?
                            <div className="circ-stepper-complete">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_check} className="auto" />
                            </div>:""}
                            {(item.status== 3 && item.name.indexOf('Final Quiz')!== -1) ?
                            <div className="circ-stepper-start">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={lock} className="auto" />
                            </div>:""}
                            </div> 
                        <p className="openSans" style={{marginTop:"15px", color: item.status== 1 ?" #333333":""}}>{item.name} </p>
                        </div>
                        ))}
                        <div className="flex column box-stepper-dlc">
                        {(vip_assessment !== undefined) &&
                        <div className="step-dlc-outline">
                            {(vip_assessment== 3 )?
                            <div className="circ-stepper-progress">
                            </div>:""}
                            {vip_assessment== 2 ?
                            <div className="circ-stepper-progress">
                            <div className="on-progress-dlc"/>
                            </div>:""}
                            {vip_assessment== 1 ?
                            <div className="circ-stepper-complete">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_check} className="auto" />
                            </div>:""}
                            </div> }
                            {(vip_assessment == undefined) &&
                        <div className="step-dlc-outline">
                            <div className="circ-stepper-progress">
                            </div>
                            </div> }
                        <p className="openSans" style={{marginTop:"15px", color: vip_assessment== 1 ?" #333333":""}}>VIP Assessment </p>
                        </div>
                        <div className="flex  column box-stepper-dlc">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_certificate} className="img-certficate" onClick={(status_length==status_reduce)?this.exportPDFWithComponent:""} style={{cursor:"pointer"}}/>
                        <p  className="openSans" style={{marginTop:"15px", cursor:"pointer",color:(status_length==status_reduce)?" #333333":""}} onClick={(status_length==status_reduce)?this.exportPDFWithComponent:""}>Get Certificate</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {
            this.state.list_courses.map((list_course)=>{
                return(
                <div key={list_course.program_content_name} className="course-core-skill-dlc course-outline-dlc">
                    {
                        !list_course.content_courses ?
                        "" 
                        :
                        list_course.content_courses.length === 0 ?
                        ""
                        :
                        list_course.program_content_name.includes("Core") ?
                            <>
                                <h2 className="title-content-courseOutline dekstop">{list_course.program_content_name.split(":")[0]}: <span>{list_course.program_content_name.split(":")[1]}</span></h2>
                                <h2 className="title-content-courseOutline-mobile mobile">{list_course.program_content_name.split(":")[0]}:<span>{list_course.program_content_name.split(":")[1]}</span></h2>
                            </>
                            :
                            <>
                                <h2 className="title-content-courseOutline dekstop">{list_course.program_content_name}</h2>
                                <h2 className="title-content-courseOutline-mobile mobile">{list_course.program_content_name}</h2>
                            </>
                    }
                    {
                        !list_course.content_courses ?
                        "" 
                        :
                        list_course.content_courses.length === 0 ?
                        ""
                        :
                        list_course.content_courses.map((content_course, i) => {
                            if (i==0){
                            return(
                            <React.Fragment key={content_course.id}>
                                <Accordion onClick={()=>this.getCourseDetail(content_course.course_id)} style={{padding: "10px"}} expanded={i==0?(this.state.expanded === 'panel1'):""} onChange={i==0?(this.handleChange('panel1')):""}>
                                {/* <Accordion onClick={()=>this.getCourseDetail(content_course.course_id)} style={{padding: "10px"}}  onChange={this.handleChange('panel1')}> */}
                                    <AccordionSummary
                                    style={{paddingLeft: "0px"}}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        {/* DEKSTOP */}
                                    <div className="dekstop container-title-accordion">
                                        <Typography className="title-content-course">{content_course.course_name}</Typography>
                                        <Typography className="title-content-time">{content_course.duration}</Typography>
                                        {
                                            content_course.course_type_name.includes("Quiz") ?
                                            !content_course.passing ? "" :
                                            <div className={content_course.passing == "Passed!" ? "status-quis-passed":  content_course.passing == "On-Going" ? "status-quis-dlc" : "status-quis-failed"}>  
                                                <p className={content_course.passing == "Passed!" ? "text-quis-passed": content_course.passing == "On-Going" ? "text-quis-dlc" : "text-quis-failed"}>{content_course.passing == "Passed!" ? "Passed!" : content_course.passing == "On-Going" ? "Submit Your Homework" : "Failed"}</p>
                                            </div>
                                            :
                                            content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.status.split("/")[1] != "0" ?
                                                <Typography className="title-content-dlc-course-done">{content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.course_type_name.includes("Book") ? "All Read!":"All Watched!"}</Typography>
                                                :
                                                <Typography className="title-content-dlc-course">({content_course.status} {content_course.course_type_name.includes("Book")?"Read":"Watched"})</Typography>
                                        }
                                    </div>
                                    {/* END DEKSTOP */}
                                    {/* MOBILE */}
                                    <div className="mobile container-title-accordion-mobile">
                                        <Typography className="title-content-course-mobile">{content_course.course_name}</Typography>
                                        <div style={{display:"flex", marginBottom:"15px", marginTop:"10px"}}>
                                            <Typography className="title-content-time-mobile">{content_course.duration}</Typography>
                                            {
                                                content_course.course_type_name.includes("Quiz") ?
                                                !content_course.passing ? "" :
                                                <div className={content_course.passing == "Passed!" ? "status-quis-passed":  content_course.passing == "On-Going" ? "status-quis-dlc" : "status-quis-failed"}>  
                                                    <p className={content_course.passing == "Passed!" ? "text-quis-passed": content_course.passing == "On-Going" ? "text-quis-dlc" : "text-quis-failed"}>{content_course.passing == "Passed!" ? "Passed!" : content_course.passing == "On-Going" ? "Submit Your Homework" : "Failed"}</p>
                                                </div>
                                                :
                                                content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.status.split("/")[1] != "0"?
                                                <Typography className="title-content-dlc-course-done-mobile">{content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.course_type_name.includes("Book") ? "All Read!":"All Watched!"}</Typography>
                                                :
                                                <Typography className="title-content-dlc-course-mobile">({content_course.status} {content_course.course_type_name.includes("Book")?"Read":"Watched"})</Typography>
                                            }
                                        </div>
                                    </div>
                                    {/* END MOBILE */}
                                    </AccordionSummary>
                                <AccordionDetails>
                                        {
                                
                                          this.state.list_acc.map((course, index) =>{
                                                return(
                                                    <React.Fragment key={course.id}>
                                                        {/* DEKSTOP */}
                                                        {
                                                            course.course_type === "Book" ?
                                                            <React.Fragment>
                                                                <a href={ `${API}/File/DLP/Book/${course.course_value}` }    rel="noopener noreferrer">
                                                                <List style={{cursor:"pointer"}} onClick={()=>{this.updateProgres(course.id, content_course.course_id, course.course_value)}} className={course.predesesor ? "list-item-course-done desktop" : "list-item-course desktop"} component="div" disablePadding>
                                                                    <ListItem style={{padding:0}}>
                                                                        <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={bookIcon} />
                                                                        <p className="time-video-dlc">{course.duration}</p>
                                                                        <p className="judul-video-dlc">{course.course_title}</p>
                                                                        <PlayCircleFilledIcon className="playButton"/>
                                                                    </ListItem>
                                                                </List>
                                                                </a>
                                                                <a href={`${API}/File/DLP/Book/${course.course_value}`}    rel="noopener noreferrer">
                                                                <List style={{cursor:"pointer"}} className={course.predesesor ? "list-item-course-done-mobile mobile" : "list-item-course-mobile mobile"} component="div" disablePadding>
                                                                    <ListItem style={{padding:0}}>
                                                                        <p className="judul-video-dlc-mobile">{course.course_title}</p>
                                                                    </ListItem>
                                                                    <ListItem style={{padding:0}}>
                                                                        <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={bookIcon} />
                                                                        <p className="time-video-dlc-mobile">{course.duration}</p>
                                                                        <PlayCircleFilledIcon onClick={()=>{this.updateProgres(course.id, content_course.course_id, course.course_value)}} className="playButton"/>
                                                                    </ListItem>
                                                                </List>
                                                                </a>
                                                            </React.Fragment>
                                                            :
                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index} onClick={()=>{sessionStorage.setItem("program-content",list_course.program_content_name)}}>
                                                                <List className={course.predesesor ? "list-item-course-done desktop" : "list-item-course desktop"} component="div" disablePadding>
                                                                    <ListItem style={{padding:0}}>
                                                                        <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={videoIcon} />
                                                                        <p className="time-video-dlc">{course.duration}</p>
                                                                        <p className="judul-video-dlc">{course.course_title}</p>
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index} >
                                                                        <PlayCircleFilledIcon className="playButton"/>
                                                                        </Link>
                                                                    </ListItem>
                                                                </List>

                                                                <List className={course.predesesor ? "list-item-course-done-mobile mobile" : "list-item-course-mobile mobile"} component="div" disablePadding>
                                                                        <ListItem style={{padding:0}}>
                                                                            <p className="judul-video-dlc-mobile">{course.course_title}</p>
                                                                        </ListItem>
                                                                        <ListItem style={{padding:0}}>
                                                                            <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={videoIcon} />
                                                                            <p className="time-video-dlc-mobile">{course.duration}</p>
                                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index}>
                                                                                <PlayCircleFilledIcon className="playButton"/>
                                                                            </Link>
                                                                        </ListItem>
                                                                    </List>
                                                            </Link>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                             </AccordionDetails>
                             {(i>0)&&  <AccordionDetails>
                                    {
                                        !content_course.course_details ?
                                        ""
                                        :
                                        !content_course.course_type_name.includes("Quiz") ?
                                            content_course.course_details.map((course, index) =>{
                                                return(
                                                    <React.Fragment key={course.id}>
                                                        {/* DEKSTOP */}
                                                        {
                                                            course.course_type === "Book" ?
                                                            <React.Fragment>
                                                                <a href={ `${API}/File/DLP/Book/${course.course_value}` }    rel="noopener noreferrer">
                                                                <List style={{cursor:"pointer"}} onClick={()=>{this.updateProgres(course.id, content_course.course_id, course.course_value)}} className={course.predesesor ? "list-item-course-done desktop" : "list-item-course desktop"} component="div" disablePadding>
                                                                    <ListItem style={{padding:0}}>
                                                                        <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={bookIcon} />
                                                                        <p className="time-video-dlc">{course.duration}</p>
                                                                        <p className="judul-video-dlc">{course.course_title}</p>
                                                                        <PlayCircleFilledIcon className="playButton"/>
                                                                    </ListItem>
                                                                </List>
                                                                </a>
                                                                <a href={`${API}/File/DLP/Book/${course.course_value}`}    rel="noopener noreferrer">
                                                                <List style={{cursor:"pointer"}} className={course.predesesor ? "list-item-course-done-mobile mobile" : "list-item-course-mobile mobile"} component="div" disablePadding>
                                                                    <ListItem style={{padding:0}}>
                                                                        <p className="judul-video-dlc-mobile">{course.course_title}</p>
                                                                    </ListItem>
                                                                    <ListItem style={{padding:0}}>
                                                                        <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={bookIcon} />
                                                                        <p className="time-video-dlc-mobile">{course.duration}</p>
                                                                        <PlayCircleFilledIcon onClick={()=>{this.updateProgres(course.id, content_course.course_id, course.course_value)}} className="playButton"/>
                                                                    </ListItem>
                                                                </List>
                                                                </a>
                                                            </React.Fragment>
                                                            :
                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index} onClick={()=>{sessionStorage.setItem("program-content",list_course.program_content_name)}}>
                                                                <List className={course.predesesor ? "list-item-course-done desktop" : "list-item-course desktop"} component="div" disablePadding>
                                                                    <ListItem style={{padding:0}}>
                                                                        <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={videoIcon} />
                                                                        <p className="time-video-dlc">{course.duration}</p>
                                                                        <p className="judul-video-dlc">{course.course_title}</p>
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index} >
                                                                        <PlayCircleFilledIcon className="playButton"/>
                                                                        </Link>
                                                                    </ListItem>
                                                                </List>

                                                                <List className={course.predesesor ? "list-item-course-done-mobile mobile" : "list-item-course-mobile mobile"} component="div" disablePadding>
                                                                        <ListItem style={{padding:0}}>
                                                                            <p className="judul-video-dlc-mobile">{course.course_title}</p>
                                                                        </ListItem>
                                                                        <ListItem style={{padding:0}}>
                                                                            <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={videoIcon} />
                                                                            <p className="time-video-dlc-mobile">{course.duration}</p>
                                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index}>
                                                                                <PlayCircleFilledIcon className="playButton"/>
                                                                            </Link>
                                                                        </ListItem>
                                                                    </List>
                                                            </Link>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <React.Fragment key={content_course.id}>
                                                <div className="dekstop" >
                                                    <Grid container>
                                                        <Grid item xs={12} sm={5}>
                                                        <p className="wording-duedate-course-outline">Due date: {this.state.end_date_course}</p>
                                                            <div className="notif-quis">
                                                                <div className="flex space-between height-100">
                                                                    <Fade top cascade>
                                                                        <div className="flex width-80">
                                                                            <p className="quis-progres-text">Quiz Progress : <span>{!content_course.result ? "0": "100"}%</span></p>
                                                                        </div>
                                                                    </Fade>
                                                                    <div className="flex column barQuis">
                                                                        <div className="progressQuis">
                                                                            {
                                                                                !content_course.result ?
                                                                                <div style={{width: "2%", backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                                :
                                                                                <div style={{width: `100%`,backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} style={{display: "flex"}}>
                                                            {/* DEKSTOP */}
                                                            <div className="container-box-grade">
                                                                <p>Passing Grade</p>
                                                                <h2 className="value-grade">{!content_course.score_minimum ? "80" : Number(content_course.score_minimum)}</h2>
                                                            </div>
                                                            <div className="container-box-grade">
                                                                <p>Grade</p>
                                                                <h2 className={!content_course.result ? "value-grade" : Number(content_course.result) >= 0 && Number(content_course.result) < Number(content_course.score_minimum) ? "value-grade-failed" : "value-grade-passed"}>{!content_course.result ? "-" : Number(content_course.result)}</h2>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3} style={{marginBottom:"14px"}}>
                                                            {
                                                                !content_course.result && content_course.result != 0 ? 
                                                                    <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                        <Button className="btn-view-quis" variant="contained">
                                                                            <span>Start Quiz</span>
                                                                        </Button>
                                                                    </Link>
                                                                    :
                                                                    <React.Fragment>
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setView}>
                                                                            <Button className="btn-view-quis" variant="contained">
                                                                                <span>View</span>
                                                                            </Button>
                                                                        </Link>
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                            <Button className="btn-retake-quis" variant="contained">
                                                                                <span>Retake</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </React.Fragment>
                                                                // :
                                                                // <Button disabled className="btn-view-quis-not-allowed" variant="contained">
                                                                //     <span>Start Quiz</span>
                                                                // </Button>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="mobile">
                                                <Grid container>
                                                        <Grid item xs={12} sm={12}>
                                                        <p className="wording-duedate-course-outline-mobile">Due date: {this.state.end_date_course}</p>
                                                            <div className="notif-quis-mobile">
                                                                <div className="flex space-between height-100">
                                                                    <div className="flex column barQuis-mobile">
                                                                        <p className="quis-progres-text-mobile">Quiz Progress : <span>{!content_course.result ? "0": "100"}%</span></p>
                                                                        <div className="progressQuis-mobile">
                                                                        {
                                                                            !content_course.result ?
                                                                            <div style={{width: "2%", backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                            :
                                                                            <div style={{width: `100%`,backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} style={{display: "flex"}}>
                                                            <div className="container-box-grade-mobile">
                                                                <p>Passing Grade</p>
                                                                <h2 className="value-grade">{!content_course.score_minimum ? "80" : Number(content_course.score_minimum)}</h2>
                                                            </div>
                                                            <div className="container-box-grade-mobile">
                                                                <p>Grade</p>
                                                                <h2 className={!content_course.result ? "value-grade" : Number(content_course.result) >= 0 && Number(content_course.result) < Number(content_course.score_minimum) ? "value-grade-failed" : "value-grade-passed"}>{!content_course.result ? "-" : Number(content_course.result)}</h2>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} style={{marginBottom:"14px"}}>
                                                            {
                                                                !content_course.result && content_course.result != 0 ?
                                                                    <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                        <Button className="btn-view-quis" variant="contained">
                                                                            <span>Start Quiz</span>
                                                                        </Button>
                                                                    </Link>
                                                                    :
                                                                    <React.Fragment>
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setView}>
                                                                            <Button className="btn-view-quis" variant="contained">
                                                                                <span>View</span>
                                                                            </Button>
                                                                        </Link>
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                            <Button className="btn-retake-quis" variant="contained">
                                                                                <span>Retake</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </React.Fragment>
                                                                // :
                                                                // <Button disabled className="btn-view-quis-not-allowed" variant="contained">
                                                                //     <span>Start Quiz</span>
                                                                // </Button>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </AccordionDetails>}

                                </Accordion>

                            </React.Fragment>
                            )}
                            if (i>0){
                                return(
                                <React.Fragment key={content_course.id}>
                                    <Accordion onClick={()=>this.getCourseDetail(content_course.course_id)} style={{padding: "10px"}}  onChange={this.handleChange('panel1')}>
                                        <AccordionSummary
                                        style={{paddingLeft: "0px"}}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                            {/* DEKSTOP */}
                                        <div className="dekstop container-title-accordion">
                                            <Typography className="title-content-course">{content_course.course_name}</Typography>
                                            <Typography className="title-content-time">{content_course.duration}</Typography>
                                            {
                                                content_course.course_type_name.includes("Quiz") ?
                                                !content_course.passing ? "" :
                                                <div className={content_course.passing == "Passed!" ? "status-quis-passed":  content_course.passing == "On-Going" ? "status-quis-dlc" : "status-quis-failed"}>  
                                                    <p className={content_course.passing == "Passed!" ? "text-quis-passed": content_course.passing == "On-Going" ? "text-quis-dlc" : "text-quis-failed"}>{content_course.passing == "Passed!" ? "Passed!" : content_course.passing == "On-Going" ? "Submit Your Homework" : "Failed"}</p>
                                                </div>
                                                :
                                                content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.status.split("/")[1] != "0" ?
                                                    <Typography className="title-content-dlc-course-done">{content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.course_type_name.includes("Book") ? "All Read!":"All Watched!"}</Typography>
                                                    :
                                                    <Typography className="title-content-dlc-course">({content_course.status} {content_course.course_type_name.includes("Book")?"Read":"Watched"})</Typography>
                                            }
                                        </div>
                                        {/* END DEKSTOP */}
                                        {/* MOBILE */}
                                        <div className="mobile container-title-accordion-mobile">
                                            <Typography className="title-content-course-mobile">{content_course.course_name}</Typography>
                                            <div style={{display:"flex", marginBottom:"15px", marginTop:"10px"}}>
                                                <Typography className="title-content-time-mobile">{content_course.duration}</Typography>
                                                {
                                                    content_course.course_type_name.includes("Quiz") ?
                                                    !content_course.passing ? "" :
                                                    <div className={content_course.passing == "Passed!" ? "status-quis-passed":  content_course.passing == "On-Going" ? "status-quis-dlc" : "status-quis-failed"}>  
                                                        <p className={content_course.passing == "Passed!" ? "text-quis-passed": content_course.passing == "On-Going" ? "text-quis-dlc" : "text-quis-failed"}>{content_course.passing == "Passed!" ? "Passed!" : content_course.passing == "On-Going" ? "Submit Your Homework" : "Failed"}</p>
                                                    </div>
                                                    :
                                                    content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.status.split("/")[1] != "0"?
                                                    <Typography className="title-content-dlc-course-done-mobile">{content_course.status.split("/")[0] == content_course.status.split("/")[1] && content_course.course_type_name.includes("Book") ? "All Read!":"All Watched!"}</Typography>
                                                    :
                                                    <Typography className="title-content-dlc-course-mobile">({content_course.status} {content_course.course_type_name.includes("Book")?"Read":"Watched"})</Typography>
                                                }
                                            </div>
                                        </div>
                                        {/* END MOBILE */}
                                        </AccordionSummary>
                                 <AccordionDetails>
                                        {
                                            !content_course.course_details ?
                                            ""
                                            :
                                            !content_course.course_type_name.includes("Quiz") ?
                                                content_course.course_details.map((course, index) =>{
                                                    return(
                                                        <React.Fragment key={course.id}>
                                                            {/* DEKSTOP */}
                                                            {
                                                                course.course_type === "Book" ?
                                                                <React.Fragment>
                                                                    <a href={ `${API}/File/DLP/Book/${course.course_value}` }    rel="noopener noreferrer">
                                                                    <List style={{cursor:"pointer"}} onClick={()=>{this.updateProgres(course.id, content_course.course_id, course.course_value)}} className={course.predesesor ? "list-item-course-done desktop" : "list-item-course desktop"} component="div" disablePadding>
                                                                        <ListItem style={{padding:0}}>
                                                                            <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={bookIcon} />
                                                                            <p className="time-video-dlc">{course.duration}</p>
                                                                            <p className="judul-video-dlc">{course.course_title}</p>
                                                                            <PlayCircleFilledIcon className="playButton"/>
                                                                        </ListItem>
                                                                    </List>
                                                                    </a>
                                                                    <a href={`${API}/File/DLP/Book/${course.course_value}`}    rel="noopener noreferrer">
                                                                    <List style={{cursor:"pointer"}} className={course.predesesor ? "list-item-course-done-mobile mobile" : "list-item-course-mobile mobile"} component="div" disablePadding>
                                                                        <ListItem style={{padding:0}}>
                                                                            <p className="judul-video-dlc-mobile">{course.course_title}</p>
                                                                        </ListItem>
                                                                        <ListItem style={{padding:0}}>
                                                                            <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={bookIcon} />
                                                                            <p className="time-video-dlc-mobile">{course.duration}</p>
                                                                            <PlayCircleFilledIcon onClick={()=>{this.updateProgres(course.id, content_course.course_id, course.course_value)}} className="playButton"/>
                                                                        </ListItem>
                                                                    </List>
                                                                    </a>
                                                                </React.Fragment>
                                                                :
                                                                <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index} onClick={()=>{sessionStorage.setItem("program-content",list_course.program_content_name)}}>
                                                                    <List className={course.predesesor ? "list-item-course-done desktop" : "list-item-course desktop"} component="div" disablePadding>
                                                                        <ListItem style={{padding:0}}>
                                                                            <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={videoIcon} />
                                                                            <p className="time-video-dlc">{course.duration}</p>
                                                                            <p className="judul-video-dlc">{course.course_title}</p>
                                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index} >
                                                                            <PlayCircleFilledIcon className="playButton"/>
                                                                            </Link>
                                                                        </ListItem>
                                                                    </List>
    
                                                                    <List className={course.predesesor ? "list-item-course-done-mobile mobile" : "list-item-course-mobile mobile"} component="div" disablePadding>
                                                                            <ListItem style={{padding:0}}>
                                                                                <p className="judul-video-dlc-mobile">{course.course_title}</p>
                                                                            </ListItem>
                                                                            <ListItem style={{padding:0}}>
                                                                                <CheckIcon className={course.predesesor ? "checkDoneCourseDlc" : "checkCourseDlc"}/>
                                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-video-dlc" src={videoIcon} />
                                                                                <p className="time-video-dlc-mobile">{course.duration}</p>
                                                                                <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name+"/"+index}>
                                                                                    <PlayCircleFilledIcon className="playButton"/>
                                                                                </Link>
                                                                            </ListItem>
                                                                        </List>
                                                                </Link>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                                :
                                                <React.Fragment key={content_course.id}>
                                                    <div className="dekstop" >
                                                        <Grid container>
                                                            <Grid item xs={12} sm={5}>
                                                            <p className="wording-duedate-course-outline">Due date: {this.state.end_date_course}</p>
                                                                <div className="notif-quis">
                                                                    <div className="flex space-between height-100">
                                                                        <Fade top cascade>
                                                                            <div className="flex width-80">
                                                                                <p className="quis-progres-text">Quiz Progress : <span>{!content_course.result ? "0": "100"}%</span></p>
                                                                            </div>
                                                                        </Fade>
                                                                        <div className="flex column barQuis">
                                                                            <div className="progressQuis">
                                                                                {
                                                                                    !content_course.result ?
                                                                                    <div style={{width: "2%", backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                                    :
                                                                                    <div style={{width: `100%`,backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4} style={{display: "flex"}}>
                                                                {/* DEKSTOP */}
                                                                <div className="container-box-grade">
                                                                    <p>Passing Grade</p>
                                                                    <h2 className="value-grade">{!content_course.score_minimum ? "80" : Number(content_course.score_minimum)}</h2>
                                                                </div>
                                                                <div className="container-box-grade">
                                                                    <p>Grade</p>
                                                                    <h2 className={!content_course.result ? "value-grade" : Number(content_course.result) >= 0 && Number(content_course.result) < Number(content_course.score_minimum) ? "value-grade-failed" : "value-grade-passed"}>{!content_course.result ? "-" : Number(content_course.result)}</h2>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={3} style={{marginBottom:"14px"}}>
                                                                {
                                                                    !content_course.result && content_course.result != 0 ? 
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                            <Button className="btn-view-quis" variant="contained">
                                                                                <span>Start Quiz</span>
                                                                            </Button>
                                                                        </Link>
                                                                        :
                                                                        <React.Fragment>
                                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setView}>
                                                                                <Button className="btn-view-quis" variant="contained">
                                                                                    <span>View</span>
                                                                                </Button>
                                                                            </Link>
                                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                                <Button className="btn-retake-quis" variant="contained">
                                                                                    <span>Retake</span>
                                                                                </Button>
                                                                            </Link>
                                                                        </React.Fragment>
                                                                    // :
                                                                    // <Button disabled className="btn-view-quis-not-allowed" variant="contained">
                                                                    //     <span>Start Quiz</span>
                                                                    // </Button>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="mobile">
                                                    <Grid container>
                                                            <Grid item xs={12} sm={12}>
                                                            <p className="wording-duedate-course-outline-mobile">Due date: {this.state.end_date_course}</p>
                                                                <div className="notif-quis-mobile">
                                                                    <div className="flex space-between height-100">
                                                                        <div className="flex column barQuis-mobile">
                                                                            <p className="quis-progres-text-mobile">Quiz Progress : <span>{!content_course.result ? "0": "100"}%</span></p>
                                                                            <div className="progressQuis-mobile">
                                                                            {
                                                                                !content_course.result ?
                                                                                <div style={{width: "2%", backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                                :
                                                                                <div style={{width: `100%`,backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} style={{display: "flex"}}>
                                                                <div className="container-box-grade-mobile">
                                                                    <p>Passing Grade</p>
                                                                    <h2 className="value-grade">{!content_course.score_minimum ? "80" : Number(content_course.score_minimum)}</h2>
                                                                </div>
                                                                <div className="container-box-grade-mobile">
                                                                    <p>Grade</p>
                                                                    <h2 className={!content_course.result ? "value-grade" : Number(content_course.result) >= 0 && Number(content_course.result) < Number(content_course.score_minimum) ? "value-grade-failed" : "value-grade-passed"}>{!content_course.result ? "-" : Number(content_course.result)}</h2>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} style={{marginBottom:"14px"}}>
                                                                {
                                                                    !content_course.result && content_course.result != 0 ?
                                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                            <Button className="btn-view-quis" variant="contained">
                                                                                <span>Start Quiz</span>
                                                                            </Button>
                                                                        </Link>
                                                                        :
                                                                        <React.Fragment>
                                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setView}>
                                                                                <Button className="btn-view-quis" variant="contained">
                                                                                    <span>View</span>
                                                                                </Button>
                                                                            </Link>
                                                                            <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/"+list_course.program_content_name+"/"+content_course.course_name} onClick={this.setRetake}>
                                                                                <Button className="btn-retake-quis" variant="contained">
                                                                                    <span>Retake</span>
                                                                                </Button>
                                                                            </Link>
                                                                        </React.Fragment>
                                                                    // :
                                                                    // <Button disabled className="btn-view-quis-not-allowed" variant="contained">
                                                                    //     <span>Start Quiz</span>
                                                                    // </Button>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </React.Fragment>
                                )}
                        })
                    }
                </div>
                )
            })
        }
        <div className="course-core-skill-dlc course-outline-dlc block">
            <h2 className="title-content-courseOutline">VIP Assessment</h2>
            <div className="box-assessment-dlp">
                <div style={{display:'flex', justifyContent:"center"}}>
                <Slider {...settings} className="width-100 block-i center">
                    <div className="box-content-assessment-dlp">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="curved-vector-dlp width-100" src={curvedVector} />
                        <div className="flex width-100 center title-assessment-container-dlp"><div className="title-assessment-content-dlp">Personality Profiler</div></div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="curved-vector-dlp-graph width-100" src={graph} />
                        <p className="text-desc-assessment-dlp" style={{textAlign:"left"}}>This profiler will assess your personality and behavioral style to improve work productivity and teamwork, letting you know Who you really are. It is licensed by Crown Do Well.</p>
                    </div>
                    <div className="box-content-assessment-dlp">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="curved-vector-dlp width-100" src={curvedVector} />
                        <div className="flex width-100 center title-assessment-container-dlp"><div className="title-assessment-content-dlp">Interest Profiler</div></div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="curved-vector-dlp-graph width-100" src={graph1} />
                        <p className="text-desc-assessment-dlp" style={{textAlign:"left"}}>This profiler will assess the nature of your work interest. Based on that, you will understand more about your various interests that are suitable for you. This profiler is based on Holland code assessment developed by John L. Holland.</p>
                    </div>
                    <div className="box-content-assessment-dlp">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="curved-vector-dlp width-100" src={curvedVector} />
                        <div className="flex width-100 center title-assessment-container-dlp"><div className="title-assessment-content-dlp">Values Profiler</div></div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="curved-vector-dlp-graph width-100" src={graph2} />
                        <p className="text-desc-assessment-dlp" style={{textAlign:"left"}}>This profiler will assess the work values preferences of each person. It is created and adapted from the theory of Dr. Shalom Schwartz's about human basic values.</p>
                    </div>
                </Slider>
                </div>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <Link to="/work_assessment">
                        <Button className="btn-assessment-dlp" variant="contained">
                            <span>Start Assessment</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        {/* certificate */}
            {/* {
                !this.state.user_dlp_profile.certification || this.state.user_dlp_profile.certification == 0? "" : */}
                    <div className="course-core-skill-dlc course-outline-dlc " id="dlc-certificate" style={{display:(status_length==status_reduce)?"block":"none"}} >
                        <h2 className="title-content-courseOutline">Certificate</h2>
                        <div className="box-assessment-dlp" style={{position:"relative"}}>
                            <PDFExport ref={component => (this.pdfExportComponent = component)} fileName={`DLP  ${profile.name}`} margin={20} className="width-100"  style={{width:"100% !important"}}>
                                <div style={{position:"relative"}} className="crf-dlp">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" className="frame-certificate" src={frame} />
                                    <div className="content-certificate-dlp cn-certificate-dlp">
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="cr-logo"/>
                                    </div>
                                    <div className="content-certificate-dlp container-certificate-dlp-text">
                                        <p className="title-head-certificate-dlp" style={{fontFamily: this.state.char ? '' : 'Dejavu',fontWeight: this.state.char ? '' : 'Bold',
                                      }}>CERTIFICATE OF COMPLETION</p>
                                        <p className="text-certificate-2">This certificate is given to</p>
                                        <p className="name-user-certificate"style={{fontFamily: this.state.char ? '' : 'Dejavu',fontWeight: this.state.char ? '' : 'Bold',
                                      }}>{profile.name}</p>
                                        {/* {   
                                            this.state.visiblePrint ?
                                            :
                                            <p className="program-name-certificate">For completing the <b>{this.state.desc_content.program_name} program</b> by Karya Talents</p>
                                        } */}
                                        <div className="box-dlco">
                                            <p className="program-name-certificate" style={{margin:"0 !important"}}>For completing the</p>
                                            <p  className="program-name-certificate-B" style={{fontSize:"14px", fontFamily: this.state.char ? '' : 'Dejavu',fontWeight: this.state.char ? '900' : 'Bold'}}>&nbsp;{this.state.desc_content.program_name}&nbsp;</p>
                                            <p className="program-name-certificate" style={{margin:"0 !important"}}>by Karya Talents</p>
                                        </div>
                                        <div className="box-dlco sc">
                                            <p  className="program-name-certificate-B" style={{fontSize:"14px",fontFamily: this.state.char ? '' : 'Dejavu',fontWeight: this.state.char ? '900' : 'Bold'}}>on {updatedAt}&nbsp;</p>
                                        </div>
                                    </div>
                                    <div className="content-certificate-dlp mt-85px">
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={badge} className="logo-badge-dlp" />
                                    </div>
                                    {/* <div className="box-chairmain">
                                        <p className="name-responsible-person">Suparno Adijanto</p>
                                        <p className="text-position-certificate">Chairmain</p>
                                    </div>
                                    <div className="box-director">
                                        <p className="name-responsible-person">jonathan adijanto</p>
                                        <p className="text-position-certificate">Director</p>
                                    </div> */}
                                </div>
                            </PDFExport>
                            <div style={{display:'flex', justifyContent:'center'}} className="dlp-t65">
                                <Button onClick={this.exportPDFWithComponent} className="btn-assessment-dlp" variant="contained">
                                    <span>Get Certificate</span>
                                </Button>
                            </div>
                        </div>
                    </div>
            {/* } */}
        {/* MOBILE */}
        <div className="course-core-skill-dlc course-outline-dlc none">
            <h2 className="title-content-courseOutline">VIP Assessment</h2>
            <div className="box-assessment-dlp">
                <div style={{display:'flex', justifyContent:'center'}}>
                <Link to="/work_assessment">
                    <Button className="btn-assessment-dlp-mobile" variant="contained">
                        <span>Start Assessment</span>
                    </Button>
                </Link>
                </div>
            </div>
        </div>
        {
            !this.state.user_dlp_profile.certification || this.state.user_dlp_profile.certification == 0? "" :
            <div className="course-core-skill-dlc course-outline-dlc none">
                <h2 className="title-content-courseOutline">Certificate</h2>
                <div className="box-assessment-dlp">
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Button className="btn-assessment-dlp-mobile" variant="contained">
                            <span>Get Certificate</span>
                        </Button>
                    </div>
                </div>
            </div>
        }
       
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    // allProgress1: state.progressProfileRed,
    user_id: state.user_id,
    profile1: state.profileId,
    ownProps: ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DlcCourseOutline);