import React, { Component } from 'react';
import { connect } from "react-redux";
import dlp_home_bg from "../images/dlp_home_bg.png"
import dlp_slide from "../images/dlp_slide.svg"
import dlp_puzzles from "../images/dlp_puzzles.svg"
import dlp_mobile from "../images/dlp_mobile.png"
import D_Home from "./D_Home"
import D_Course from "./D_Course"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API } from "../_redux/helper";
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {findEmail} from "../_actions/dlp";


class _DLP_Home extends Component {
  constructor(){
    super();
    this.state={
      home:true,
      course:false,
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let email = sessionStorage.getItem("email")
    if(!email) {
        await axios.get(`${API}/api/dlp/findtoken/${localStorage.getItem('token')}`)
            .then((res) => {
                email = res.data.email
            })
            .catch((err) => {
                console.log(err.message)
            })
    }
    this.props.findEmail(localStorage.getItem("email"));
      /* eslint eqeqeq: 0 */
      if(localStorage.getItem("getMyCOurse") == "My Courses"){
        localStorage.removeItem("getMyCOurse");
        this.setState({course: true,
          home:false})
      }
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.findEmail1.dataEmail.length===0){
      const res = this.props.findEmail1.dataEmail
      sessionStorage.setItem("i-dlp",res.id)
      localStorage.setItem("i-dlp",res.id)
    }
  }
    

  changeHome=()=>{
    this.setState({home: true,
    course:false})
    localStorage.removeItem("getMyCOurse");
  }
  changeCourse=()=>{
    this.setState({course: true,
    home:false})
    localStorage.setItem("getMyCOurse", "My Courses");
  }
    render() {

        const settingImage = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            // autoplay: true,
            responsive: [
              {
                breakpoint: 1440,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          };

        return (
            <div>
                <div className="box-wrapper bg-color DLP_Home" >
                    <div className="wrapper">
                        <div className="content">
                            <div className="page-container  width-100" style={{marginTop:"120px", position:"absolute"}}>
                                <div className="page-container-outer " style={{marginBottom:"50px"}}>
                                    <div className="boxShadow width-100">
                                        <div className="dekstop width-100">
                                            <div className="flex width-100 space-between header-DLP" style={{backgroundImage:`url(${dlp_puzzles})`, border:"1px solid #ECECEC"}}>
                                                <div className="flex height-100 width-60" >
                                                    <div style={{padding:"24px 0px 24px 48px"}}>
                                                        <div className="width-100 flex height-100">
                                                            <div className="flex width-100 height-100 column space-between f-box">
                                                               <div className="new">
                                                                    <p>NEW</p>
                                                                </div>
                                                                <div className="flex column width-100">
                                                                    <h5 className="HK-Grotesk-Bold">Welcome to</h5>
                                                                    <h1 className="HK-Grotesk-Bold">Karya Learning Platform</h1>
                                                                </div>
                                                                <div> </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="flex height-100 container-Slide width-40" style={{ backgroundImage:`url(${dlp_home_bg})`, backgroundSize:"cover"}}>
                                                    <div className="flex center width-100">
                                                        {/* <Slider {...settingImage}  className="flex center width-100" style={{width:"80%", height:"auto", marginLeft:"10%"}}>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_slide} style={{width:"70%", height:"auto"}} />
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_slide} style={{width:"70%", height:"auto"}} />
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_slide} style={{width:"70%", height:"auto"}} />
                                                        </Slider> */}
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="mobile width-100">
                                            <div className="flex width-100 space-between header-DLP column" >
                                               
                                                <div className="flex height-100 container-Slide width-100" style={{ backgroundImage:`url(${dlp_mobile})`, backgroundSize:"cover"}}>
                                                    <div className="flex center width-100">
                                                        {/* <Slider {...settingImage}  className="flex center width-100" style={{width:"80%", height:"auto"}}>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_slide} style={{width:"70%", height:"auto"}} />
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_slide} style={{width:"70%", height:"auto"}} />
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_slide} style={{width:"70%", height:"auto"}} />
                                                        </Slider> */}
                                                    </div>
                                                </div>
                                                <div className="flex height-100 width-100 puzz" 
                                                // style={{backgroundImage:`url(${mobile_puzzles})`}}
                                                >
                                                    <div style={{padding:"23px 5% 67px ", width:"90%"}}>
                                                        <div className="width-100 flex height-100">
                                                            <div className="flex width-100 height-100 column f-box">
                                                                <div className="flex width-100 space-between">
                                                                <h5 className="HK-Grotesk-Bold">Welcome to</h5>
                                                                <div className="new width-20">
                                                                    <p>NEW</p>
                                                                </div>
                                                                </div>
                                                                <div className="flex column width-100">
                                                                    <h1 className="HK-Grotesk-Bold">Karya Learning Platform</h1>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="flex width-100 center">
                                                <div className="flex space-between whislist">
                                                    <p onClick={this.changeHome} style={{borderBottom:this.state.home?"2px solid #3B6C98":"", padding:this.state.home?"18px 18px 17px 18px":"", color: this.state.home?"#3B6C98":""}}>Home</p>
                                                    <p onClick={this.changeCourse} style={{borderBottom:this.state.course?"2px solid #3B6C98":"", padding:this.state.course?"18px 18px 17px 18px":"", color: this.state.course?"#3B6C98":""}} >My Courses</p>
                                                    <p style={{display:"none"}}>Wishlist</p>
                                                </div>
                                            </div>
                                            {this.state.home &&
                                            <div className="flex column width-100">
                                                <D_Home/>
                                            </div>}
                                            {this.state.course && 
                                            <div className="flex column width-100">
                                              <D_Course /> 
                                              </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    findEmail1: state.findEmailRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    findEmail: (email) =>{
      dispatch(findEmail(email))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(_DLP_Home);