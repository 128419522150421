import {GET_EDUCATION, GET_EDUCATION_ID, PUT_EDUCATION, DELETE_EDUCATION, POST_EDUCATION, LIST_EDUCATION } from "../config/constants";
  const initialState = {
    education:[],
    educationId:[],
    deleteEducation:[],
    postEducation:[],
    putEducation:[],
    listEdu:[],
    isLoading: false,
    error: false,
  };
  
  export const getEducationRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_EDUCATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_EDUCATION}_FULFILLED`:
        return {
          ...state,
          education: action.payload.data,
          isLoading: false,
        };
      case `${GET_EDUCATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getEducationIdRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_EDUCATION_ID}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_EDUCATION_ID}_FULFILLED`:
        return {
          ...state,
          educationId: action.payload.data,
          isLoading: false,
        };
      case `${GET_EDUCATION_ID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const postEducationRed = (state = initialState, action) => {
    switch (action.type) {
      case `${POST_EDUCATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${POST_EDUCATION}_FULFILLED`:
        return {
          ...state,
          postEducation: action.payload.data,
          isLoading: false,
        };
      case `${POST_EDUCATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const putEducationRed = (state = initialState, action) => {
    switch (action.type) {
      case `${PUT_EDUCATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${PUT_EDUCATION}_FULFILLED`:
        return {
          ...state,
          putEducation: action.payload.data,
          isLoading: false,
        };
      case `${PUT_EDUCATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const delEducationRed = (state = initialState, action) => {
    switch (action.type) {
      case `${DELETE_EDUCATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${DELETE_EDUCATION}_FULFILLED`:
        return {
          ...state,
          deleteEducation: action.payload.data,
          isLoading: false,
        };
      case `${DELETE_EDUCATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const listEducationRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${LIST_EDUCATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${LIST_EDUCATION}_FULFILLED`:
        return {
          ...state,
          listEdu: action.payload.data,
          isLoading: false,
        };
      case `${LIST_EDUCATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  
  