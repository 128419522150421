import React, { Component } from 'react';
import report_bg from "../images/report_bg.svg"
import logo from "../images/KaryaTalents_logo.svg"
import wavy_left from "../images/wavy_left.svg"
import wavy_right from "../images/wavy_right.svg"
import icon_download from "../images/icon_download_report.svg"
import { report, label,date_report} from "../components/ParamsReport"
import { API , user_dummy} from "../_redux/helper";
import axios from 'axios';
import Nav from "../components/Nav";
import { FormatListBulletedOutlined } from '@material-ui/icons';
import {Radar} from 'react-chartjs-2';
import {GAevent} from '../components/TrackingEvent'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReactDOM from "react-dom";
import {getReportValues, getReportLimit} from "../_actions/quest"
import {getProfileId} from "../_actions/user";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import 'chartjs-plugin-datalabels'
var $ = require('jquery');






class componentName extends Component {
    constructor(){
        super()
        this.state={
            user_id:"",
            width:"",
            visible:true,
            visible2:true,
            lastUpdate:"",
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0)
        ReactPixel.track(window.location.pathname);
        var user_id = user_dummy;
        this.setState({ user_id: user_id });
        this.setState({width:window.window.outerWidth})
        this.props.getReportValues(user_id)
        this.props.getReportLimit(user_id)
        await axios.get(`${API}/api/getresult_work_assessment_lastupdate/${user_id}`)
      .then(res => {
          this.setState({lastUpdate:res.data.map((item)=>item.last_update)})
      })
      }


 //PDF TERBARU
  exportPDFWithMethod = () => {
    savePDF(ReactDOM.findDOMNode(this.container), {
        paperSize: "auto",
        margin: 40,
        fileName: `Report for ${new Date().getFullYear()}`
        });
        this.setState({visible:false})
        setTimeout(() => {
            window.location.href="/Report"
        }, 1500);
        GAevent('MyAssessment','Download') 
  };

    

    exportPDFWithComponent = () => {
        this.pdfExportComponent.save({
                toolbar: ["pdf"],
            pdf: {
                forceProxy: true,
                proxyURL: "/proxy"
            },
            dataSource: {
                type: "odata",
                transport: {
                    read: "https://demos.telerik.com/kendo-ui/service/Northwind.svc/Products"
                },
                pageSize: 7
            },
            pageable: true,
            columns: [
                { width: 300, field: "ProductName", title: "Product Name" },
                { field: "UnitsOnOrder", title: "Units On Order" },
                { field: "UnitsInStock", title: "Units In Stock" }
            ]

        });
        GAevent('Report PID','Download PDF') 
        this.setState({visible:false})
        setTimeout(() => {
            window.location.href="/Report"
        }, 2500);
      };

      exportPDFWithComponentMobile = () => {
        this.pdfExportComponent.save();
        GAevent('Report PID','Download PDF') 
        this.setState({visible2:false})
        setTimeout(() => {
            window.location.href="/Report"
        }, 4500);
      };

      

    render() {
    const {dataReportValues} = this.props.dataReportValues1
    const {dataLimitValues} = this.props.dataLimitValues1
    
    const dataLimit = []
    dataLimitValues.data != undefined && dataLimitValues.data.length > 0 ?dataLimitValues.data.map((item)=>(dataLimit.push(item))):console.log("failed");


    // setTimeout(() => {
        let nil1
        let nil2
        let nil3
        let nil4
        let nil5
        let nil6
        let nil7
        let nil8
        let nil9
        let nil10
        let val1
        let val2
        let val3
        let val4
        let val5
        let val6
        let val7
        let val8
        let val9
        let val10
        let name
      dataReportValues.data != undefined && dataReportValues.data.length > 0 ?
        dataReportValues.data.map((item,i)=>{
            if (i==0){
               nil1= item.persen_score*1
               val1 = item.values.toUpperCase()
               name = item.name
            }else if (i==1){
                nil2= item.persen_score*1
                val2 = item.values.toUpperCase()
             }else if  (i==2){
                nil3= item.persen_score*1
                val3 = item.values.toUpperCase()
             }else if  (i==3){
                nil4= item.persen_score*1
                val4 = item.values.toUpperCase()
             }else if  (i==4){
                nil5= item.persen_score*1
                val5 = item.values.toUpperCase()
             }else if  (i==5){
                nil6= item.persen_score*1
                val6 = item.values.toUpperCase()
             }else if  (i==6){
                nil7= item.persen_score*1
                val7 = item.values.toUpperCase()
             }else if  (i==7){
                nil8= item.persen_score*1
                val8 = item.values.toUpperCase()
             }else if  (i==8){
                nil9= item.persen_score*1
                val9 = item.values.toUpperCase()
             }else  {
                nil10= item.persen_score*1
                val10 = item.values.toUpperCase()
             }

        }):console.log("failed")
    // }, 3000);
    
   
    const val_day = new Date(this.state.lastUpdate).getDay()
    const val_date = new Date(this.state.lastUpdate).getDate()
    const val_month = new Date(this.state.lastUpdate).getMonth()
    const val_year= new Date(this.state.lastUpdate).getFullYear()
    const val_Hours= new Date(this.state.lastUpdate).getHours()
    const val_Minutes= new Date(this.state.lastUpdate).getMinutes()


    const day = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    let Hour 
    if (val_Hours >= 13){
        Hour = ((val_Hours - 12) +":"+ val_Minutes +" PM")
    }else{
        Hour = (val_Hours  +":"+ val_Minutes + " AM")
    }

    const date_PID = (day[val_day] +", "+ month[val_month]  +" "+ val_date+", "+ val_year +"  "+ Hour )

    const format1 = new Date(`${date_report}`).getDay()
    const { profile } = this.props.profile1;
    const nama = '' + profile.name
    const nick_name = nama.split(" ")[0]
    const nick_name2 = nick_name.charAt(0).toUpperCase() + nick_name.slice(1)


    let regText = /__first_name__/gi
    let regProfile = /__profile__/gi
    var dataURI = "data:text/plain;base64,SGVsbG8gV29ybGQh";
   
    let text = `${report.suggestionsForManagersCopy}`
    let suggestionsForManagers
    if (text.indexOf('__first_name__')!= -1){
        suggestionsForManagers = text.replace(regText,nick_name2)
    }else{
        suggestionsForManagers = text.replace(regProfile, label)
    }

    let nilai = [nil1, nil2, nil3, nil4, nil5, nil6, nil7, nil8, nil9, nil10]
    function checkValue(ar){
        let arr = []
        if (ar.length <= 3) return ar;
        let max = [{value:ar[0],index:0},
                  {value:ar[1],index:1},
                  {value:ar[2],index:2}];
        max.sort((a,b)=>a.value-b.value);
            
        for (let i = 3;i<ar.length;i++){
            if (ar[i] > max[0].value){
              max[0] = {value:ar[i],index:i};
              max.sort((a,b)=>a.value-b.value);
            }
        }
        ar.map((x, index) => {
          let color = 'grey'
          max.map(y => {
            if(index == y.index) {
              color = '#3B6C98'
            }
          })
          arr.push(color)
        })
        
        return arr
  
      }
      function checkFont() {
          let check = window.innerWidth > 500 ? 12 : 8
          return check
      }
    // DATA FOR CHART
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: false,
        plugins: {
            datalabels: {
                display: function(context) {
                    if (nilai.length <= 3) return nilai;
                    let max = [{value:nilai[0],index:0},
                            {value:nilai[1],index:1},
                            {value:nilai[2],index:2}];
                    max.sort((a,b)=>a.value-b.value);
                        
                    for (let i = 3;i<nilai.length;i++){
                        if (nilai[i] > max[0].value){
                        max[0] = {value:nilai[i],index:i};
                        max.sort((a,b)=>a.value-b.value);
                        }
                    }
                    let index = []
                    max.map(x => index.push(x.index))
                    let check = (index.indexOf(context.dataIndex) != -1) ? true : false
                
                    return check
                },
                opacity: 1,
                anchor: 'end',
                align: 'end',
                color: 'white',
                borderColor: '#3B6C98',
                borderWidth: 1,
                borderRadius: 5,
                font: {
                  weight: 'bold',
                  size: 10,
                  lineHeight: 1 /* align v center */
                },
                padding: {
                  top: 5
                },
                /* hover styling */
                backgroundColor: function(context) {
                  return context.hovered ? context.dataset.borderColor : 'white';
                },
                color: function(context) {
                  return 'grey';
                },
                formatter: function(value, context) {
                    return Math.round(value);
                },
                listeners: {
                  enter: function(context) {
                    context.hovered = true;
                    return true;
                  },
                  leave: function(context) {
                    context.hovered = false;
                    return true;
                  }
                }
              }
        },
        legend: {
            display: false
        },
        scale: {
            pointLabels:{
                fontStyle:"bold",
                fontSize: checkFont(),
                fontColor: checkValue(nilai),
                callback: function(pointLabel, index, labels) {
                  return pointLabel;
                }
            },
            angleLines: {
                display: FormatListBulletedOutlined,
                borderDash: [10, 10]
            },
            ticks: {
                maxTicksLimit: 6,
                stepSize: 10,
                beginAtZero: true,
                suggestedMin: 50,
                suggestedMax: 100,
                display: false
            }
        }
    };
    
    const data= {
        labels: [`${val1}`, `${val2}`, `${val3}`, `${val4}`,`${val5}`,`${val6}`,`${val7}`,`${val8}`,`${val9}`,`${val10}`],
        datasets: [{
            data: [nil1, nil2, null, null,null,null,null,null,null,null],
            label: `ACHIEVEMENT`,
            borderColor: 'rgba(215, 87, 87, 0.5)',
            backgroundColor: 'rgba(215, 87, 87, 0.6)',
            borderWidth: 2,
            pointRadius: 0
        },
            {data: [null, null, nil3, nil4,nil5,null,null,null,null,null],
            label: `FUN`,
            borderColor: 'rgba(226, 181, 129, 0.5)',
            backgroundColor: 'rgba(226, 181, 129, 0.6)',
            borderWidth: 2,
            pointRadius: 0
        },
          {data: [null, null, null, null,null,nil6,nil7,null,null,null],
            label: `HARMONY`,
            borderColor: 'rgba(91, 184, 139, 0.5)',
            backgroundColor: 'rgba(91, 184, 139, 0.6)',
            borderWidth: 2,
            pointRadius: 0
        },
        {data: [null, null, null, null,null,null,null,nil8,nil9,nil10],
          label: `COMPLIANCE`,
          borderColor: 'rgba(91, 128, 184, 0.5)',
          backgroundColor: 'rgba(91, 128, 184, 0.6)',
          borderWidth: 2,
          pointRadius: 0
      }]
    }
      
      

       
        return (
            <div>
                <Nav />
                <div className="page-container report_Profiler mt-80" style={{backgroundColor:"#F2F2F2",width:this.state.visible2?"100%":"1200px"}}>
                {/* <div className="page-container report_Profiler" style={{marginTop:"80px",backgroundColor:"#F2F2F2"}}> */}
                    <div className="page-container-outer" style={{ boxShadow:"none", width:"1200px"}}>
                        <div className="width-100 dekstop">
                        <div className="flex width-100 space-between title_Profiler">
                            <h1 >Values Profiler</h1>
                            <Link to="/values_getstarted">
                            <button style={{display:"none"}}>
                                <p>Take New Assessment</p>
                            </button>
                            </Link>
                        </div>
                        </div>
                        <div className="width-100 mobile" style={{backgroundColor:"#F2F2F2"}}>
                            <div className="flex width-100  title_Profiler column">
                                <h1 className="width-100 flex" style={{marginTop:"15px"}}>Values Profiler</h1>
                                <Link to="/values_getstarted" style={{width:"221px", height:"58px"}}>
                                <button>
                                    <p>Take New Assessment</p>
                                </button>
                                </Link>
                            </div>
                        </div>
                        <PDFExport ref={component => (this.pdfExportComponent = component)} width="1000" height="1000"  dataURI={dataURI} proxyURL= "/proxy"  
                         fileName={`Personality ${nick_name2}`} paperSize="auto"  className="width-100" margin={20} style={{width:"100% !important"}}>
                        <div className="canvas_Profiler" ref={container => (this.container = container)}>
                            <div className="width-100" style={{marginBottom:"18px"}}>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_bg} className="flex width-100 bg_top_profiler"  style={{height:this.state.visible2?"":"199px"}}/>
                                <div className="content_bg_report" style={{height:this.state.visible2?"":"199px"}}>
                                    <div className="width-100 dekstop" style={{display:this.state.visible2?"":"flex"}}>
                                        <div className="flex width-100  header_bg " style={{justifyContent:"center"}}>
                                            <div className="box_under_lc" style={{display:"none"}}>
                                                <div className="flex box_under_lc " style={{display:"none"}} >
                                                    <button  onClick={this.exportPDFWithComponent} style={{display:"none"}}>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download}  />
                                                        <p>Download PDF</p>
                                                    </button>
                                                </div>
                                            </div>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo}  className="logo_bg" />
                                           
                                        </div>
                                    </div>
                                    <div className="width-100 mobile" style={{display:this.state.visible2?"":"none"}}>
                                        <div className="flex width-100 space-between column header_bg center">
                                            {/* <button style={{marginBottom:"20px"}} onClick={this.exportPDFWithComponent}>Download PDF</button> */}
                                            <button  onClick={this.exportPDFWithComponentMobile} style={{display:"none", marginBottom:"20px"}}>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download}  />
                                                <p>Download PDF</p>
                                            </button>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo}  className="logo_bg" style={{height:"24px"}} />
                                        </div>
                                    </div>
                                    <div className="dekstop width-100" >
                                        <div className="flex width-100 center" >
                                            {/* <p style={{fontSize:"45px",marginTop:"34px", fontWeight:"bold",backgroundColor:"red", marginLeft:"10%"}}>Personality Profiler</p> */}
                                            <div className="title_bg_canvas" style={{ paddingLeft:this.state.visible?"":"16%"}}>Values Profiler</div>
                                        </div>
                                    </div>
                                    <div className="mobile width-100">
                                        <div className="flex width-100 center" >
                                            {/* <p style={{fontSize:"45px",marginTop:"34px", fontWeight:"bold",backgroundColor:"red", marginLeft:"10%"}}>Personality Profiler</p> */}
                                            <div className="title_bg_canvas" style={{marginTop:"34px", padding:this.state.visible2?"":"17px 5% 0px 15%", fontSize:this.state.visible2?"":"40px",lineHeight:this.state.visible2?"":"60px",marginTop:this.state.visible2?"":"17px",marginBottom:this.state.visible2?"":"15px", width:this.state.visible2?"":"600px"}}>Values Profiler</div>
                                        </div>
                                    </div>
                                    <div className="flex width-100 center btn_Idv">
                                        <button>
                                            <p>Individual Report</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="width-100 dekstop" >
                                <div className="box_admin_Profile" style={{border:this.state.visible?"":"1px solid #ececec"}}>
                                <div className="flex width-100 height-100 space-between">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_left} style={{height:"100%",objectFit:"cover", borderBottomLeftRadius:"10px"}} />
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_right} style={{height:"100%",objectFit:"cover", borderTopRightRadius:"10px"}} />
                                </div>
                                <div className="flex  column textPrepare">
                                    <p className="top">Prepared for</p>
                                    <div className="flex width-100 space-between">
                                        <div className="flex ">
                                                <h3 style={{textTransform:"capitalize"}}>{name} </h3>
                                                {dataLimit.map((item,i)=>(
                                                <button  key={i} style={{marginRight:"12px"}}>
                                                    <p style={{textTransform:"uppercase"}}>{item.values.replace(/\s.*/,'')}</p>
                                                </button>
                                                ))}
                                        </div>
                                        {date_report &&<p className="date" style={{margin:"auto 0px"}}>{date_PID} </p>}
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="width-100 mobile">
                                <div style={{padding:"14px"}}>
                                <div className="box_admin_Profile" style={{height:"170px", width:"100%", marginLeft:"0%", border:this.state.visible2?"":"1px solid #ececec"}}>
                                <div className="flex width-100 height-100 space-between icon-bg-personality">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_left} style={{objectFit:"cover", borderBottomLeftRadius:"0px"}} />
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_right} style={{objectFit:"cover", borderTopRightRadius:"0px", marginTop:"auto"}} />
                                </div>
                                <div className="flex  column textPrepare width-100" style={{marginLeft:this.state.visible2?"":"4%"}}>
                                    <div className="width-100 flex column" style={{padding:"14px"}}>
                                    <p className="top">Prepared for</p>
                                    <h3 style={{textTransform:"capitalize"}}>{name} </h3>
                                    <div className="flex width-100 space-between column">
                                        {dataLimit.map((item,i)=>(
                                            <button  key={i} style={{width:"94%", marginLeft:"0px"}}>
                                                <p style={{textTransform:"uppercase"}}>{item.values.replace(/\s.*/,'')}</p>
                                            </button>
                                        ))}
                                        {date_report && <p className="date">{date_PID}</p>}
                                    </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div className="width-report">
                                <div className="flex width-100 center box-Overview" >
                                <div className="flex width-radar " >
                                    <Radar data={data} options={options} />
                                </div>
                                </div>
                              
                            </div>
                            <div className="flex width-report top3">
                                <h2 style={{fontFamily:"Montserrat-Bold"}}>Your TOP 3</h2>
                                <h2 style={{color:"#DDB87A", fontWeight:"normal"}}>VALUES</h2>
                            </div>
                            <div className="width-report">
                               
                                <div className="box-ValReport">
                                <div className="Idea_box" style={{flexDirection:this.state.visible2?"":"row"}}>
                                    {dataLimit.map((item,i)=>{ 
                                        return(
                                    <div key={i} className="blue_container" style={{width:this.state.visible2?"":"30%"}}>
                                        <div className="items width-100" >
                                            <div className="valuesBox">
                                                <p>{item.values.replace(/\s.*/,'')} </p>
                                                </div>
                                            <div className="flex width-100 column">
                                                {/* <h1 className="width-100" style={{fontSize:this.state.visible2?"":"24px",lineHeight:this.state.visible2?"":"30px",marginBottom:this.state.visible2?"":"28px"}}>Ideal Environment</h1> */}
                                                <p className="width-100 openSans" style={{marginTop:"24px"}}>
                                                {item.content_description}
                                                </p>
                                            </div>
                                            {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={ideal} /> */}
                                        </div>
                                    </div>
                                    )})}
                                    </div>
                                </div>
                                {/* <div className="width-100 flex" style={{border:"1px solid #E0E0E0", margin:"50px 0px"}} /> */}
                               
                               
                                <div className="flex width-100 center" style={{margin:"110px auto 59px auto"}}>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} />
                                </div>

                            </div>
                            
                        </div>
                        </PDFExport>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      profile1: state.profileId,
      dataReportValues1 : state.reportValuesRed,
      dataLimitValues1 : state.limitValuesRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
      getReportValues: (user_id) => {
        dispatch(getReportValues(user_id));
      },
      getReportLimit: (user_id) => {
        dispatch(getReportLimit(user_id));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(componentName);
  