import React, { Component } from 'react';
import bg_pid from "../images/pid_background.png"
import bg_art from "../images/pid_art.png"
import checklist from "../images/pid_checklist.svg"
import bgMobile from "../images/PID_Profile_Mobile.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


class componentName extends Component {



    render() {
        return (
            <div>
                <div className="box-wrapper bg-color _Personality" >
                    <div className="wrapper">
                        <div className="content">
                            <div className="page-container  width-100" style={{marginTop:"80px"}}>
                                <div className="page-container-outer job-summary">
                                    <div className="box-logo width-100">
                                    <h1 className="titleSpartan">Personality Profiler</h1>
                                    </div>
                                    <div className="dekstop-only">
                                        <div className="page-container-inner  width-100">
                                            <div className="box-edu-container width-100">
                                                <div className="box_PID">
                                                    <div className="flex width-100 height-100">
                                                        <div className="flex column width-50 space-between">
                                                            <div className="content_PID">
                                                                <h1>Welcome to Karya Personality Profiler</h1>
                                                                <div className="flex width-100 list_PID">
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                    <p>Complete the 16 questions assessment</p>
                                                                </div>
                                                                <div className="flex width-100 list_PID">
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                    <p>Discover your personality profile </p>
                                                                </div>
                                                                <div className="flex width-100 list_PID">
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                    <p>Boost awareness of your strength and areas of development</p>
                                                                </div>
                                                                <div className="flex width-100 list_PID">
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                    <p>Know how to be your best at work and life </p>
                                                                </div>
                                                                <div className="flex width-100 list_PID" style={{display:"none"}}>
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                    <p>Boost awareness of your strengths and weaknesses</p>
                                                                </div>
                                                            </div>
                                                            <div className="pid_getStarted">
                                                            <a href="/Personality_GetStarted">
                                                                <button>
                                                                    <p>Get Started</p>
                                                                </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="width-50 height-100" >
                                                            {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={personality_bg} />   */}
                                                            <div className="width-100 height-100" style={{background :`url(${bg_pid})`, backgroundSize:"cover"}}>
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={bg_art} className="art_PID" />
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobile-tablet">
                                    <div className="page-container-inner  width-100">
                                        <div className="box-edu-container width-100">
                                            <div className="box_PID" style={{height:"fit-content"}}>
                                                <div className="flex width-100 height-100 column">
                                                    <div className="width-100 height-100" >
                                                        <div className="width-100 height-100" >
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={bgMobile} className="width-100 height-100" />
                                                        </div>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={bg_art} className="art_PID" />
                                                    </div>
                                                    <div className="flex column width-100 space-between">
                                                        <div className="content_PID">
                                                            <h1>Welcome to Karya Personality Profiler</h1>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Complete the 16 questions assessment</p>
                                                            </div>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Discover your personality profile </p>
                                                            </div>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Boost awareness of your strength and areas of development</p>
                                                            </div>
                                                            <div className="flex width-100 list_PID">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Know how to be your best at work and life</p>
                                                            </div>
                                                            <div className="flex width-100 list_PID" style={{display:"none"}}>
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist} />
                                                                <p>Boost awareness of your strengths and weaknesses</p>
                                                            </div>
                                                        </div>
                                                        <div className="pid_getStarted">
                                                        <a href="/Personality_GetStarted">
                                                            <button>
                                                                <p>Get Started</p>
                                                            </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default componentName;