import React, { Component } from "react";
import { connect } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import Loading from "../components/Loading";
import { API } from "../_redux/helper";
import { getJob2, getJob} from "../_actions/jobs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowNext.svg";
import ScrollToTop from "../components/ScrollToTop";
import ReactTooltip from "react-tooltip";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import nhp_vip from "../images/About.jpg";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      token: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    this.setState({ token: token1 });
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getJob2(user_id);
    this.props.getJob(user_id);
  }




  render() {
    const { data2, isLoading } = this.props.job2;
    const { data } = this.props.job;

    let data_job 
    if (data.length>0){
      data_job= data
    }else{
      data_job=data2
    }

 /* eslint eqeqeq: 0 */
    if (sessionStorage.getItem("kuesionerconfirmed")==false || data_job.length===0){
      return(
      <div className="message-question-load" >
          <h3  style={{color:"#3B6C98", fontFamily:"Open Sans", width:"90%", textAlign:"center"}}>Take the interest assessment to get job recommendation based on your interest</h3>
    </div>
      )
    }
   

    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} />,
      prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 769, 
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay:false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:false,
          },
        },
      ],
    };


    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <div>
        <div  className="box-wrapper bg-color careerCenter" >
          <div className="wrapper">
            <div className="content Dashbrd">
              <div className="page-container   width-100" style={{marginTop:"80px"}} >
                <div className="page-container-outer job-summary dashboard" >
                  <div className="page-container-inner width-100">
                    <div className="box-edu-container width-100">
                      <div className="box-profile-detail">
                      </div>
                      {data_job &&
                      <div className="box-other-recomended-container width-100">
                        <div className="other-recomended-container-inner width-100">
                            <h3>Your Career Recommendation based on Work Interest Assessment</h3>
                          <div className="box-recomended-list-work width-100">
                            {/* desktop display */}
                            <div className="recomended-list-work-inner width-100">
                              {data_job.map((item, i) => {
                                  return (
                                    <div key={i} className="box-list-work">
                                      <div className="box-list-work-inner width-100">
                                        <div className="list-work-image width-100">
                                          <LazyLoadImage effect="blur"
                                            src={`${API}/File/Job/${item.photo}`}
                                            alt=""
                                            title={item.link_photo}
                                            data-for="svgTooltip2"
                                            cx="10"
                                            cy="25"
                                            r="22"
                                            fill="#fff"
                                            stroke="#000"
                                            strokeWidth="4"
                                            onError={(e)=>{e.target.src = nhp_vip}}
                                          />
                                          <ReactTooltip
                                            id="svgTooltip2"
                                            place="bottom"
                                            type="light"
                                          />
                                          <div
                                            className="middle"
                                            style={{ display: "none" }}
                                          >
                                            <a
                                              href={item.link_photo}
                                                 rel="noopener noreferrer"
                                            >
                                              <div className="textImage">
                                                {item.link_photo}
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="list-work-description width-100">
                                          <div className="width-100 flex text_height" >
                                            <h5>{item.original_job_title.slice(0, 40)}</h5>
                                          </div>
                                          <div className="list-work-trend width-100">
                                            <Rating name="read-only" value={item.star} readOnly/>
                                          </div>
                                          <div className="box-link width-100">
                                              <a href={`/DetailSummary/${item.job_id}`}>
                                                See job detail
                                              </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                              })}
                            </div>
                          
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />

        {/* <Footer /> */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
    job2: state.job2,
    job: state.job,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJob2: (user_id) => {
      dispatch(getJob2(user_id));
    },
    getJob: (user_id) => {
      dispatch(getJob(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);