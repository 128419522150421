import React, { Component } from "react";
import Navbar_Homepage from "../components/Navbar_Homepage";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ReactGA from "react-ga";
import { GAevent } from "../components/TrackingEvent";
import axios from "axios";
import { API } from "../_redux/helper";
import tlk_wave from "../images/tlk_wave.png";
import tlk_bg_3rd from "../images/tlk-bg-3rd.png";
import emy_bg_input from "../images/emy_bg_input.png";
import patterns from "../images/patterns.svg";
import Fade from "react-reveal/Fade";
import Pulse from "react-reveal/Pulse";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import { TextField } from "@material-ui/core";
import Modal from "react-awesome-modal";
import apply_success from "../images/apply_success.svg";
import Loading from "../components/Loading";
import epy_match from "../images/epy_match.svg";
import epy_assess from "../images/epy_assess.svg";
import epy_advise from "../images/epy_advise.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

class New_Employer extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      phone_number: "",
      name: "",
      message: "",
      company: "",
      job_title: "",
      emailErr: "",
      nameErr: "",
      phone_numberErr: "",
      companyErr: "",
      job_titleErr: "",
      messageErr: "",
      data: "",
      success: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    if (localStorage.getItem("token")) {
      window.location.href = "/NewDashboard";
    }
    axios.get(`${API}/api/employer_dynamic`).then((res) => {
      this.setState({ data: res.data });
    });
  }

  talkTo = () => {
    window.location.href = "/talk-to-us";
  };

  changeName = (event) => {
    const reg = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
    const n = reg.test(event.target.value);
    if (n === true) {
      this.setState({ name: event.target.value });
    }
  };

  changePhone = (event) => {
    const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    const n = reg.test(event.target.value);
    /* eslint eqeqeq: 0 */
    if (n == true) {
      this.setState({ phone_number: event.target.value });
    }
  };
  onKeyDown = (e) => {
    if (e.keyCode === 8 && this.state.phone_number.length <= 1) {
      this.setState({ phone_number: "" });
    }
  };
  changeEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  changeCompany = (e) => {
    this.setState({ company: e.target.value });
  };
  changeJob = (e) => {
    this.setState({ job_title: e.target.value });
  };
  changeMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  onSubmit = () => {
    const { name, phone_number, job_title, email, company, message } =
      this.state;
    const regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const err = "This field is required";

    if (
      name == "" ||
      phone_number === "" ||
      phone_number.length < 10 ||
      email == "" ||
      regEmail.test(email) == false ||
      job_title == "" ||
      company == "" ||
      message == ""
    ) {
      const fields = [name, job_title, company, message];
      const err_fields = [
        "nameErr",
        "job_titleErr",
        "companyErr",
        "messageErr",
      ];

      fields.map((item, i) => {
        if (item === "") {
          this.setState({ [err_fields[i]]: err });
        } else {
          this.setState({ [err_fields[i]]: "" });
        }
      });

      if (email == "" || regEmail.test(email) == false) {
        this.setState({ emailErr: err });
      } else {
        this.setState({ emailErr: "" });
      }
      if (phone_number == "" || phone_number.length < 10) {
        this.setState({
          phone_numberErr: "Phone number must be at least 10 digits",
        });
      } else {
        this.setState({ phone_numberErr: "" });
      }
    } else {
      const data = {
        name,
        email,
        company,
        job_title,
        message,
        phone: this.state.phone_number,
      };
      axios
        .post(`${API}/api/employer/create`, data)
        .then((response) => {
          this.setState({ success: true });
          GAevent("new-employer", "Submit Form");
        })
        .catch((err) => {
          console.log("failed");
        });
    }
  };

  render() {
    const { data, success } = this.state;
    const status = data !== undefined && data.length > 0;
    let data_employer = [];
    if (data !== undefined && data.length > 0) {
      data.map((item) => {
        if (item.title == "Banner_Employer") {
          return data_employer.push(item);
        }
      });
    }

    if (data == "" || data_employer[0].img === "") {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <body className="employer-22"></body>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          {/* <meta name="keywords" id="index_keywords"
                content="Looking for the best senior professionals? Our dedicated recruitment consultant can source and match the right fit talents for your companies."/> */}
        </Helmet>
        <div className="flex column width-100 Hp-22 _s bg-ff">
          <Navbar_Homepage status="bg-ff fsh_" />
          <div className="content emy-container">
            <div
              className="page-container mt-80 master-page width-100"
              style={{ backgroundColor: "#F9F9F9" }}
            >
              <div
                className="flex width-100 column banner"
                style={{
                  backgroundImage: `url(${API}/api/employer_dynamic/image/${data_employer[0].img})`,
                }}
              >
                <Zoom>
                  <div className="bx-hiring flex column">
                    <span className="poppins-Extra-Bold c-ff">
                      {data_employer[0].subtitle}
                    </span>
                    <h1
                      className="DM-Sans c-ff wd-416 mb-74 s-2436"
                      dangerouslySetInnerHTML={{
                        __html: data_employer[0].description,
                      }}
                    />
                    <button onClick={this.talkTo} className="bg-12 bd-12">
                      <p>Hire with us</p>
                    </button>
                  </div>
                </Zoom>
              </div>

              {/* <LazyLoadImage effect="blur" src={emy_banner_mobile} className="none width-100 eM-i"  /> */}
              <div className="flex width-100 center ct-2 column">
                <Fade bottom>
                  <div className="flex column center mt-100 mb-64">
                    <h2 className=" Kanit w-600 s-48 c-21">
                      How We are Different from Other{" "}
                    </h2>
                    <h2 className="Kanit-400 s-48 c-21">
                      {" "}
                      Headhunter Companies
                    </h2>
                  </div>
                </Fade>
                <div className="flex column tr-wd">
                  <div
                    className="flex column tr-p1"
                    style={{ backgroundImage: `url(${patterns})` }}
                  />
                  <div
                    className="flex column tr-col"
                    style={{ backgroundImage: "none" }}
                  >
                    <div className="flex tr-box ">
                      {status
                        ? data.map((item, i) => {
                            if (item.sub_category == "Curated Candidates") {
                              return (
                                <Slide bottom>
                                  <div className="ct-5-col flex column">
                                    <div className="img-rad">
                                      <LazyLoadImage
                                        effect="blur"
                                        src={`${API}/api/employer_dynamic/image/${item.img}`}
                                        className="icon-rad"
                                      />
                                    </div>
                                    <h3 className="poppins">
                                      {item.subtitle}{" "}
                                    </h3>
                                    <p
                                      className="poppins"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </div>
                                </Slide>
                              );
                            }
                          })
                        : ""}
                      {status
                        ? this.state.data.map((item, i) => {
                            if (item.sub_category == "Competitive Rate") {
                              return (
                                <Slide bottom>
                                  <div className="ct-5-col flex column">
                                    <div className="img-rad">
                                      <LazyLoadImage
                                        effect="blur"
                                        src={`${API}/api/employer_dynamic/image/${item.img}`}
                                        className="icon-rad"
                                      />
                                    </div>
                                    <h3 className="poppins">
                                      {item.subtitle}{" "}
                                    </h3>
                                    <p
                                      className="poppins"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </div>
                                </Slide>
                              );
                            }
                          })
                        : ""}
                      {status
                        ? this.state.data.map((item, i) => {
                            if (
                              item.sub_category == "Candidate Service Guarantee"
                            ) {
                              return (
                                <Slide bottom>
                                  <div className="ct-5-col flex column n-tf1 top-d">
                                    <div className="img-rad">
                                      <LazyLoadImage
                                        effect="blur"
                                        src={`${API}/api/employer_dynamic/image/${item.img}`}
                                        className="icon-rad"
                                      />
                                    </div>
                                    <h3 className="poppins">
                                      {item.subtitle}{" "}
                                    </h3>
                                    <p
                                      className="poppins"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </div>
                                </Slide>
                              );
                            }
                          })
                        : ""}
                    </div>
                    <div className="flex tr-box ">
                      {status
                        ? this.state.data.map((item, i) => {
                            if (
                              item.sub_category == "Candidate Service Guarantee"
                            ) {
                              return (
                                <Slide bottom>
                                  <div className="ct-5-col flex column n-tf2 ">
                                    <div className="img-rad">
                                      <LazyLoadImage
                                        effect="blur"
                                        src={`${API}/api/employer_dynamic/image/${item.img}`}
                                        className="icon-rad"
                                      />
                                    </div>
                                    <h3 className="poppins">
                                      {item.subtitle}{" "}
                                    </h3>
                                    <p
                                      className="poppins"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </div>
                                </Slide>
                              );
                            }
                          })
                        : ""}
                      {status
                        ? this.state.data.map((item, i) => {
                            if (
                              item.sub_category == "Personalized Consulting"
                            ) {
                              return (
                                <Slide bottom>
                                  <div className="ct-5-col flex column consl">
                                    <div className="img-rad">
                                      <LazyLoadImage
                                        effect="blur"
                                        src={`${API}/api/employer_dynamic/image/${item.img}`}
                                        className="icon-rad"
                                      />
                                    </div>
                                    <h3 className="poppins">
                                      {item.subtitle}{" "}
                                    </h3>
                                    <p
                                      className="poppins"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </div>
                                </Slide>
                              );
                            }
                          })
                        : ""}
                      {status
                        ? this.state.data.map((item, i) => {
                            if (item.sub_category == "Post-Placement Service") {
                              return (
                                <Slide bottom>
                                  <div className="ct-5-col flex column n-tf4">
                                    <div className="img-rad">
                                      <LazyLoadImage
                                        effect="blur"
                                        src={`${API}/api/employer_dynamic/image/${item.img}`}
                                        className="icon-rad"
                                      />
                                    </div>
                                    <h3 className="poppins">
                                      {item.subtitle}{" "}
                                    </h3>
                                    <p
                                      className="poppins"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </div>
                                </Slide>
                              );
                            }
                          })
                        : ""}
                    </div>
                    <div className="flex tr-box center">
                      {status
                        ? this.state.data.map((item, i) => {
                            if (item.sub_category == "Post-Placement Service") {
                              return (
                                <Slide bottom>
                                  <div className="ct-5-col flex column n-tf3">
                                    <div className="img-rad">
                                      <LazyLoadImage
                                        effect="blur"
                                        src={`${API}/api/employer_dynamic/image/${item.img}`}
                                        className="icon-rad"
                                      />
                                    </div>
                                    <h3 className="poppins">
                                      {item.subtitle}{" "}
                                    </h3>
                                    <p
                                      className="poppins"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </div>
                                </Slide>
                              );
                            }
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex width-100 column wave-box"
                style={{ backgroundImage: `url(${tlk_wave})` }}
              >
                <div className="flex column content auto">
                  {status
                    ? this.state.data.map((item, i) => {
                        if (
                          item.title == "How it Works" &&
                          item.category == "subtitle_employer"
                        ) {
                          return (
                            <Pulse>
                              <div className="ct-1st center">
                                {/* <h2 className="HK-Grotesk">{item.title} </h2> */}
                                <div className="flex center wrap">
                                  <h2 className="Kanit-400">Our </h2>
                                  <h2 className="Kanit w-600">
                                    &nbsp;End-to-end&nbsp;
                                  </h2>
                                  <h2 className="Kanit-400">
                                    Recruitment Process
                                  </h2>
                                </div>
                                <p
                                  className="HK-Grotesk-Light"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                />
                              </div>
                            </Pulse>
                          );
                        }
                      })
                    : ""}
                  <div className="flex cr-m1 column">
                    <div className="flex column cr-m2 auto">
                      <div className="dekstop width-100">
                        <div
                          className="bg-wave"
                          style={{ backgroundImage: `url(${tlk_bg_3rd})` }}
                        >
                          <div className="pad-icon flex width-100 space-between">
                            {status
                              ? this.state.data.map((item, i) => {
                                  if (
                                    item.title == "How it Works" &&
                                    item.category == "content_employer"
                                  ) {
                                    return (
                                      <div className="rad-epy flex center">
                                        <LazyLoadImage
                                          effect="blur"
                                          src={
                                            `${API}/api/employer_dynamic/image/${item.img}` !=
                                              undefined ||
                                            `${API}/api/employer_dynamic/image/${item.img}` !=
                                              ""
                                              ? `${API}/api/employer_dynamic/image/${item.img}`
                                              : (item.subtitle == "Match"
                                                  ? epy_match
                                                  : epy_match) ||
                                                (item.subtitle == "Advise"
                                                  ? epy_advise
                                                  : epy_advise) ||
                                                (item.subtitle == "Assess"
                                                  ? epy_assess
                                                  : epy_assess)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="flex width-100 space-between cn-tC">
                        {status
                          ? this.state.data.map((item, i) => {
                              if (
                                item.title == "How it Works" &&
                                item.category == "content_employer"
                              ) {
                                return (
                                  <Slide bottom>
                                    <div className="wave-item flex column center">
                                      <div className="mobile">
                                        <div className="rad-epy flex center">
                                          <LazyLoadImage
                                            effect="blur"
                                            src={
                                              `${API}/api/employer_dynamic/image/${item.img}` !=
                                                undefined ||
                                              `${API}/api/employer_dynamic/image/${item.img}` !=
                                                ""
                                                ? `${API}/api/employer_dynamic/image/${item.img}`
                                                : (item.subtitle == "Match"
                                                    ? epy_match
                                                    : epy_match) ||
                                                  (item.subtitle == "Advise"
                                                    ? epy_advise
                                                    : epy_advise) ||
                                                  (item.subtitle == "Assess"
                                                    ? epy_assess
                                                    : epy_assess)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <h3 className="poppins">
                                        {item.subtitle}{" "}
                                      </h3>
                                      <p
                                        className="poppins"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      />
                                    </div>
                                  </Slide>
                                );
                              }
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="emy-bg-input flex column center width-100"
                style={{ backgroundImage: `url(${emy_bg_input})` }}
              >
                <div className="flex column center width-100 emy-auto">
                  <div className="flex column center auto emy-form">
                    <h1 className="HK-Grotesk-Bold">
                      Let us know your hiring needs
                    </h1>
                    <div className="flex space-between width-100 box">
                      <div className="wd-input">
                        <div className="flex width-100 column col-ft">
                          <TextField
                            value={this.state.name}
                            onChange={this.changeName}
                            className="ft-txt width-100 flex"
                            label="Name"
                            variant="filled"
                            type="text"
                            name="Name"
                          />
                          <p>{this.state.nameErr} </p>
                        </div>
                        <div className="flex width-100 column col-ft">
                          <TextField
                            value={this.state.email}
                            onChange={this.changeEmail}
                            className="ft-txt width-100 flex"
                            label="Email"
                            variant="filled"
                            type="text"
                            name="Email"
                          />
                          <p>{this.state.emailErr} </p>
                        </div>
                        <div className="flex width-100 column col-ft">
                          <TextField
                            value={this.state.phone_number}
                            onChange={this.changePhone}
                            onKeyDown={this.onKeyDown}
                            className="ft-txt width-100 flex"
                            label="Phone"
                            variant="filled"
                            type="text"
                            name="Phone"
                          />
                          <p>{this.state.phone_numberErr} </p>
                        </div>
                        <div className="flex width-100 column col-ft">
                          <TextField
                            value={this.state.job_title}
                            onChange={this.changeJob}
                            className="ft-txt width-100 flex"
                            label="Job Title"
                            variant="filled"
                            type="text"
                            name="Job Title"
                          />
                          <p>{this.state.job_titleErr} </p>
                        </div>
                      </div>
                      <div className="wd-input">
                        <div className="flex width-100 column col-ft">
                          <TextField
                            value={this.state.company}
                            onChange={this.changeCompany}
                            className="ft-txt width-100 flex"
                            label="Company"
                            variant="filled"
                            type="text"
                            name="Company"
                          />
                          <p>{this.state.companyErr} </p>
                        </div>
                        <div className="flex width-100 column col-ft">
                          <textarea
                            type="text"
                            value={this.state.message}
                            onChange={this.changeMessage}
                            className="ft-txt area width-100 flex"
                            label="Message"
                            variant="outlined"
                            placeholder="Message"
                            name="Message"
                          ></textarea>
                          <p>{this.state.messageErr} </p>
                        </div>
                      </div>
                    </div>
                    <button onClick={this.onSubmit}>
                      <p>SUBMIT</p>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="flex column width-100"
                style={{ backgroundColor: "white" }}
              >
                <div className="flex column center epy-gro">
                  {status
                    ? this.state.data.map((item, i) => {
                        if (
                          item.sub_category == "Let's grow together" &&
                          item.category == "content_employer"
                        ) {
                          return (
                            <Fade bottom>
                              <div className="sm-center flex column center auto">
                                <h2 className="DM-Sans">{item.title} </h2>
                                <p
                                  className="poppins"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                />
                                <button onClick={this.talkTo}>
                                  <p>Talk to Us</p>
                                </button>
                              </div>
                            </Fade>
                          );
                        }
                      })
                    : ""}
                </div>
              </div>
            </div>
            <WhatsAppWidget
              phoneNumber="6287717888800"
              companyName="Karya Talents"
            />
          </div>
          <Modal id="success" visible={success} effect="fadeInUp">
            <div className="modal_apply">
              <div className="width-100 flex center">
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={apply_success}
                  style={{ marginTop: "24px", marginBottom: "24px" }}
                />
              </div>
              <h5>Success!</h5>
              <p style={{ width: "90%", marginLeft: "5%" }}>
                Your inquiry has been submitted!
              </p>
              <div className="width-100 flex center">
                <button
                  onClick={() => {
                    this.setState({ success: false });
                  }}
                  style={{ marginBottom: "20px" }}
                >
                  <p style={{ margin: "12px", color: "#4F4F4F" }}>Close</p>
                </button>
              </div>
            </div>
          </Modal>
          <ScrollToTop />
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default New_Employer;
