import {FIND_EMAIL, USER_COURSE_DETAIL } from "../config/constants";
   const initialState = {
     dataEmail: [],
     dataUser:[],
     isLoading: false,
     error: false,
   };
   
   export const findEmailRed = (state = initialState, action) => {
     switch (action.type) {
       case `${FIND_EMAIL}_PENDING`:
         return {
           ...state,
           isLoading: true,
         };
       case `${FIND_EMAIL}_FULFILLED`:
         return {
           ...state,
           dataEmail: action.payload.data,
           isLoading: false,
         };
       case `${FIND_EMAIL}_REJECTED`:
         return {
           ...state,
           error: true,
           isLoading: false,
         };
       default:
         return state;
     }
   };

   export const userCourseDetailRed = (state = initialState, action) => {
    switch (action.type) {
      case `${USER_COURSE_DETAIL}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${USER_COURSE_DETAIL}_FULFILLED`:
        return {
          ...state,
          dataUser: action.payload.data,
          isLoading: false,
        };
      case `${USER_COURSE_DETAIL}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };