import React, { Component } from "react";
import { connect } from "react-redux";
import date from "../images/iconDate.png";
import { getProfileId, setInformation , getProvince, getCity, getCountry , getOnCity, getOnState, getOnCountry} from "../_actions/user";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from "../components/ScrollToTop";
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import ReactPixel from 'react-facebook-pixel';
import { LazyLoadImage } from 'react-lazy-load-image-component';



const styles = theme => ({
  textField: {
    width: "100%",
  }
})


class MyInformation extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      id: "",
      id_state:"",
      token1: "",
      birth_date: "",
      address: "",
      phone_number: "",
      name: "",
      city_id:"",
      province_id:"",
      country_id:"",
      gender_id:"",
      phone_number_Err:"",
      gender_idErr:"",
      city_idErr:"",
      country_Err:"",
      province_idErr:"",
      birth_date_Err:"",
      address_Err:"",
      textCountry:"  --SELECT COUNTRY--",
      textCity:"  --SELECT CITY--",
      textProvince:"  --SELECT PROVINCE--",
      cityInput:false,
      startDate: new Date(),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    this.setState({ token1: token1 });
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.props.getProfileId(user_id);
    this.props.getCountry();
    var id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.setState({ id: id });
    sessionStorage.setItem('setupTime', new Date().getTime())
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { profile } = this.props.profile1;
    const country_id = this.state.country_id
    let new_city_id 
    let new_state_id

    if (this.state.city_id ==="" || this.state.city_id ===undefined){
      new_city_id = profile.city_id
    }else if (this.state.city_id !== 0 || this.state.city_id !== "0"){
      new_city_id = this.state.city_id
    }

    if (this.state.province_id==="" || this.state.province_id=== undefined || this.state.province_id=== null){
      new_state_id = profile.state_id
    }else{
      new_state_id = this.state.province_id
    }
    
    //UNTUK MENAMPILKAN PROVINSI DAN KOTA KETIKA DIKLIK

    if ((this.state.country_id !== "") && (this.state.country_id !== undefined) && (this.state.country_id !== prevState.country_id)){
      this.props.getProvince(country_id) 
    }
    const province_id = this.state.province_id
    if ((this.state.province_id !== "") && (this.state.province_id !== undefined) && (this.state.province_id !== prevState.province_id)){
      this.props.getCity(province_id) 

    }
    //UNTUK GET PROVINSI
    if (this.props.profile1.profile !== "") {
      if (this.props.profile1.profile !== prevProps.profile1.profile) {
        this.props.getOnState(this.props.profile1.profile.state_id);
      }
    }
    //UNTUK GET CITY
    if (this.props.profile1.profile !== "") {
      if (this.props.profile1.profile !== prevProps.profile1.profile) {
        this.props.getOnCity(new_state_id,this.props.profile1.profile.country_id, new_city_id);
      }
    }
    //UNTUK GET GENDER
    if (this.props.profile1.profile !== "") {
      if (this.props.profile1.profile !== prevProps.profile1.profile) {
        this.setState({gender_id:profile.gender_id})
      }
    }

     //UNTUK GET COUNTRY
     if (this.props.profile1.profile !== "") {
      if (this.props.profile1.profile !== prevProps.profile1.profile) {
        this.props.getOnCountry(this.props.profile1.profile.country_id);
      }
    }
  }


  handleChange = (date) => {
    this.setState({
      birth_date: date,
    });
  };

  handleToken = () => {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
  };
  onChangeName = (event) => {
    this.setState({ name: event.target.value });
    sessionStorage.setItem('setupTime', new Date().getTime())
  };
  onChangePhone = (event) => {
    this.setState({ phone_number: event.target.value });
    sessionStorage.setItem('setupTime', new Date().getTime())
  };
  onChangeBirth = (birth_date) => {
    this.setState({ birth_date });
    sessionStorage.setItem('setupTime', new Date().getTime())
  };
  onChangeAddress = (event) => {
    this.setState({ address: event.target.value });
    sessionStorage.setItem('setupTime', new Date().getTime())
  };
  changeCountry = (event) => {
    this.setState({ country_id: event.target.value });
    sessionStorage.setItem('setupTime', new Date().getTime())
  };
  changeProvince = (event) => {
    this.setState({ province_id: event.target.value });
    sessionStorage.setItem('setupTime', new Date().getTime())
  };
  changeCity = (event) => {
    this.setState({ city_id: event.target.value });
    sessionStorage.setItem('setupTime', new Date().getTime())
  };

  resetAll= (e)=>{
   window.location.href="/MyProfile"
   ReactGA.pageview(window.location.pathname + window.location.search)
  }

  information = (e) => {
    e.preventDefault();
    const { profile } = this.props.profile1;
    let phone_number
    let birth_date
    let gender_id
    let city_id
    let state_id
    let country_id
    let address
    let formIsValid = true;
    const msg1 = "Phone Number cannot be empty"
    const msg2 = "Birth Date cannot be empty"
    const msg3 = "Gender cannot be empty"
    const msg4 = "City cannot be empty"
    const msg5 = "Province cannot be empty"
    const msg6 = "Country cannot be empty"
    const msg7=""
    const msg8="Address cannot be empty"

    if (this.state.phone_number==="" || this.state.phone_number===null || this.state.phone_number===undefined){
      phone_number = profile.phone_number
    }else{
      phone_number= this.state.phone_number
    }

    if (this.state.birth_date==="" || this.state.birth_date===null || this.state.birth_date===new Date() || this.state.birth_date===undefined){
      birth_date = profile.birth_date
    }else{
      birth_date= this.state.birth_date
    }

    if (this.state.gender_id==="" || this.state.birth_date===null){
      gender_id = profile.gender_id
    }else{
      gender_id= this.state.gender_id
    }

    if (this.state.city_id==="" || this.state.city_id===null){
      city_id = profile.city_id
    }else{
      city_id= this.state.city_id
    }

    if (this.state.province_id==="" || this.state.province_id===null){
      state_id = profile.state_id
    }else{
      state_id= this.state.province_id
    }

    if (this.state.country_id==="" || this.state.country_id===null){
      country_id = profile.country_id
    }else{
      country_id= this.state.country_id
    }

    if (this.state.address==="" || this.state.address===null){
      address = profile.address
    }else{
      address= this.state.address
    }



    if (phone_number==="NaN" || phone_number==="" || phone_number===null || phone_number===undefined){
      formIsValid = false
      this.setState({phone_number_Err:msg1})
    }else{
      this.setState({phone_number_Err:msg7})
    }

    if (birth_date==="" || birth_date === new Date() || birth_date===null || birth_date===undefined){
      formIsValid = false
      this.setState({birth_date_Err:msg2})
    }else{
      this.setState({birth_date_Err:msg7})
    }

    if (gender_id==="" || gender_id===null || gender_id===undefined){
      formIsValid=false
      this.setState({gender_idErr:msg3})
    }else{
      this.setState({gender_idErr:msg7})
    }

    if (country_id===""||country_id===null){
      formIsValid=false
      this.setState({country_Err:msg6})
    }else{
      this.setState({country_Err:msg7})
    }
  
/* eslint eqeqeq: 0 */
    if ((city_id==="" || city_id===null) || (country_id != 102 && (city_id <= 100000 )) || (country_id == 102 && ((parseInt(city_id) <= 1000000 ))==false)){
      formIsValid=false
      this.setState({city_idErr:msg4})
    }else{
      this.setState({city_idErr:msg7})
    }



  
    if ((state_id==="" || state_id===null) || (country_id != 102 && (state_id <= 100000 )) || (country_id == 102 && ((parseInt(state_id) <= 1000000 ))==false)){
      formIsValid=false
      this.setState({province_idErr:msg5})
    }else{
      this.setState({province_idErr:msg7})
    }

  

    if (formIsValid===false){
      window.location.href="#"
    }else{
    const data = {
      phone_number,
      address,
      birth_date,
      gender_id,
      city_id,
      state_id,
      country_id,
      id: this.state.id,
      token1: this.state.token1,
    };
    this.props.info1(data);
    GAevent(`Update Data - ${window.location.pathname}`,'Update Data') 
    ReactPixel.track(`Update Data - ${window.location.pathname}`,'Update Data'); 
  }
  };

  render() {
    const { profile } = this.props.profile1;
    const { dataCity } = this.props.city1;
    const { dataProvince } = this.props.province1;
    const { dataCountry } = this.props.country1;
    const { dataCountryNew } = this.props.country2;
    const {onState } = this.props.onState1;
    const {onCity } = this.props.onCity1;
  console.log(dataCity, dataProvince,  dataCountry,"data inni");
  console.log(onState, onCity, dataCountryNew,"wekend");

    
    //UNTUK MENAMPILKAN KOTA, PROVINSI DAN NEGARA
    const coun = dataCountryNew.map((item,i)=>(
      item.country_name
    ));
   
    const city = onCity.map((item,i)=>(
      item.city_name
    ));
    const state = onState.map((item,i)=>(
      item.state_name
    ));


    const startDate = new Date(profile.birth_date)
    var tanggal = startDate.getDate();
    var bulan = (startDate.getMonth() + 1);
    var tahun = startDate.getFullYear();
    var tanggalLahir = tanggal+" / "+bulan+" / "+tahun
    
    //UNTUK MENAMPILKAN GENDER
    const selected=this.state.gender_id;

    //UNTUK MENAMPILKAN KOTA DAN PROVINSI YANG SUDAH DIPILIH
    let ketState 
    let ketState2
    let ketState3
    let ketCity
    let ketCity2
    if (profile.state_id === "" || profile.state_id===null){
      ketState = this.state.textProvince
    }else{
      ketState = state
    }

    if (profile.state_id >= 0){
      ketState2=""
    }else{
      ketState2=profile.state_id
    }

    if (profile.city_id === "" || profile.city_id===null){
      ketCity = this.state.textCity
    }else{
      ketCity = city
    }

    if (profile.city_id >=0 ){
      ketCity2=""
    }else{
      ketCity2=profile.city_id
    }

    //MENAMPILKAN NAMA COUNTRY
   
    let countryName 
    if (coun[0] === "" || coun[0] == undefined ){
      countryName = this.state.textCountry
    } else{
      countryName = coun[0]
    }

    //MENAMPILKAN NAMA PROVINSI
    let stateName
    let country_id
    if (this.state.country_id==="" || this.state.country_id===null){
      country_id = profile.country_id
    }else{
      country_id= this.state.country_id
    }
   
    if (country_id === this.state.country_id){
      stateName = ketState
    }else if (country_id !== "" && (this.state.country_id==="" || this.state.country_id===null || this.state.country_id===undefined)){
      stateName = ketState
    }

    if (profile.country_id !== this.state.country_id && this.state.country_id !==""){
      stateName = this.state.textProvince
    }

    //MENAMPILKAN NAMA KOTA
    let cityName
    let state_id
    if (this.state.province_id==="" || this.state.province_id===null){
      state_id = profile.state_id
    }else{
      state_id= this.state.province_id
    }
   
    if (state_id === this.state.province_id){
      cityName = ketCity
    }else if (country_id !== "" && (this.state.country_id==="" || this.state.country_id===null || this.state.country_id===undefined)){
      cityName = ketCity
    }

    if ((profile.state_id !== this.state.province_id && (this.state.province_id !=="" ))|| profile.country_id !== this.state.country_id && this.state.country_id !==""){
      cityName = this.state.textCity
    }

    //UNTUK MENAMPILKAN TEXTFIELD CITY JIKA NEGARA YANG DIPILIH ADALAH SELAIN INDONESIA
    let country_id_input
    if (this.state.country_id==="" || this.state.country_id===undefined){
      country_id_input = profile.country_id
    }else{
      country_id_input = this.state.country_id
    }

    let inputProvince
    let inputCity
    if (country_id_input != 102 || country_id_input != "102"){
      inputCity = <input
      type="text"
      placeholder="City"
      onChange={this.changeCity}
      defaultValue={ketCity2}
      ref={this.input}
      name="city"
      id="city"
    ></input>
    }else{
      inputCity = <select  required
      className="form-control myinfo_field"
      onChange={this.changeCity}
      name="education_level_id"
      id="education_level_id"
      style={{ height: "45px", marginTop:"15px" }}
      disabled={
        (  this.state.province_id==="" ?  "disabled" :false) 
      }
    >
      <option disabled selected value={0} style={{color:"#BDBDBD"}} label={`${cityName}`} >
    {cityName}
      </option>
      {dataCity.map((item, i) => (
        <option
          key={i}
          value={item.id}
          onChange={this.changeCity}
          onClick={()=>{this.setState({city_id: item.id})}}
          style={{height:"100px"}}
        >
          {item.city_name}{" "}
        </option>
      ))}
    </select>
    }

    //UNTUK MENAMPILKAN TEXTFIELD PROVINCE JIKA NEGARA YANG DIPILIH ADALAH SELAIN INDONESIA
    if (country_id_input != "102"){
      inputProvince = <input
      type="text"
      placeholder="Province"
      onChange={this.changeProvince}
      defaultValue={ketState2}
      ref={this.input}
      name="Province"
      id="Province"
    ></input>
    }else{
      inputProvince =  <select  required
      className="form-control myinfo_field"
      onChange={this.changeProvince}
      name="education_level_id"
      id="education_level_id"
      style={{ height: "45px", marginTop:"15px" }}
      disabled={
        ( this.state.country_id === ""  ?  "disabled" :false) 
      }
    >
      <option disabled selected value={0}  label={`${stateName}`} style={{color:"#BDBDBD"}}>
     {stateName}
    
      </option>
      {dataProvince.map((item, i) => (
        <option
          key={i}
          value={item.id}
          onChange={this.changeProvince}
          onClick={()=>{this.setState({province_id: item.id})}}
          style={{height:"100px"}}
        >
          {item.state_name}{" "}
        </option>
      ))}
    </select>
    }




    


    return (
      <div>
        {/* <NavBar /> */}
        <div className="box-wrapper bg-color">
          <div className="wrapper boxInformation">
            <form onSubmit={this.information} id="information">
              <div className="content">
                <div className="page-container  width-100" style={{marginTop:"80px"}} >
                  <div className="page-container-outer job-summary dashboard" style={{padding:"0px 0px 20px 0px"}}>
                    <div className="box-logo width-100 h1-info">
                      <h1 className="titleSpartan" style={{display:"block"}}>My Information</h1>
                    </div>
                    <p className="mobile back-Mf" onClick={()=>{window.location.href="/MyProfile"}}>{"<"} My Profile </p>
                    <div className="page-container-inner width-100 myinfo-dekstop">
                      <div className="box-edu-container width-100">
                        {/* <div className="box-job-desc-container width-100"> */}
                        <div className="box-other-recomended-container width-100">
                          <div
                            className="other-recomended-container-inner width-100"
                            style={{
                              padding: "30px 20px 30px 20px !important",
                            }}
                          >

                            <div className="box-myinformation">
                              <div className="item-myinformation">
                                {/* {datePick} */}
                                <div className="box">
                                  <p style={{ color: "gray" }}>Full Name</p>
                                  <input
                                    type="text"
                                    placeholder={profile.name}
                                    disabled="disabled"
                                  >
                                  </input>
                                </div>
                                <div className="box">
                                  <p style={{ color: "gray" }}>Email</p>
                                  <input
                                    type="text"
                                    placeholder={profile.email}
                                    disabled="disabled"
                                  ></input>
                                </div>
                               
                                <div className="box">
                                  <p>Gender</p>
                                  <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="gender1" >
                                    <FormControlLabel value="1" checked={(selected === 1 || selected === "1") } control={<Radio />} label="Male" onClick={()=> {this.setState({gender_id:1})}} />
                                    <FormControlLabel value="2" checked={selected === 2 || selected === "2"} control={<Radio />} label="Female" onClick={()=> {this.setState({gender_id:2})}}/>
                                </RadioGroup>
                                </FormControl>
                                <span style={{color: "red", fontSize:"12px"}}>{this.state.gender_idErr} </span>
                                </div>
                                <div className="box bot26">
                                  <div className="datepick">
                                    <p>Birth Date</p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker  
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      name="birth_date"
                                      id="date-picker-inline"
                                      maxDate={new Date()}
                                      value={ this.state.birth_date !== "" ? this.state.birth_date : profile.birth_date}
                                      onChange={this.onChangeBirth} style={{marginLeft:"0px"}} />
                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={date} className="iconDateInformation" />
                                    {/* <KeyboardDatePicker 
                                      disableToolbar
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      name="birth_date"
                                      id="date-picker-inline"
                                      value={ this.state.birth_date !== "" ? this.state.birth_date : profile.birth_date}
                                      onChange={this.onChangeBirth}
                                    /> */}
                                    </MuiPickersUtilsProvider>
                                  </div>
                                  <span style={{color: "red", fontSize:"12px"}}>{this.state.birth_date_Err} </span>
                                </div>
                                <div className="box">
                                 
                                  <p>Phone Number</p>
                                  {/* <div className="width-100"> */}
                                  <input
                                    type="number"
                                    placeholder="Phone Number"
                                    onChange={this.onChangePhone}
                                    label={profile.phone_number}
                                    defaultValue={profile.phone_number}
                                    ref={this.input}
                                    name="phone_number"
                                    id="phone_number"
                                  ></input>
                                  {/* </div> */}
                                  <div className="width-100"><span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.phone_number_Err} </span></div>
                                  
                                </div>
                                <div className="flex width-100">
                                <button onClick={this.information}
                                  className="btn-myinformation"
                                  id="button"
                                  type="submit"
                                  style={{ color: "white", width: "fit-content" }}
                                >
                                 Save all changes &nbsp;
                                </button>
                                <button
                                  className="btn-myinformation"
                                  id="button"
                                  type="reset"
                                  onClick={this.resetAll}
                                  style={{ color: "#3B6C98", width: "fit-content", backgroundColor:"white", borderColor:"white" }}
                                >
                                 Cancel &nbsp;
                                </button>
                                </div>
                              </div>






                              <div className="item-myinformation">
                              <div className="box ">
                                  <p className="bot5">Address</p>
                                  {/* <div className="width-100"> */}
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    onChange={this.onChangeAddress}
                                    defaultValue={profile.address}
                                    ref={this.input}
                                    name="address"
                                    id="address"
                                  ></input>
                                  {/* </div> */}
                                  <span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.address_Err} </span>
                                </div>
                                <div className="box ">
                                  <p className="bot5">Country</p>
                                  {/* <input
                                    type="text"
                                    placeholder="Country"
                                    onChange={this.onChangeAddress}
                                    defaultValue={profile.address}
                                    ref={this.input}
                                    name="address"
                                    id="address"
                                  ></input> */}
                                  <select required
                                      className="form-control"
                                      onChange={this.changeCountry}
                                      name="education_level_id"
                                      id="education_level_id"
                                      style={{ height: "45px", width:"93%", marginTop:"15px" }}
                                    >
                                      <option disabled selected   label={countryName} style={{color:"#BDBDBD"}}>
                                      {/* {
                                                    ( coun !== "" ? coun : coun) ||
                                                    ( coun === "" ? coun : this.state.textCountry) 
                                                    } */}
                                      {countryName}
                                      </option>
                                      {dataCountry.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.id}
                                          onChange={this.changeCountry}
                                          onClick={()=>{this.setState({country_id: item.id})}}
                                          style={{height:"100px"}}
                                        >
                                          {item.country_name}{" "}
                                        </option>
                                      ))}
                                    </select>
                                    <span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.country_Err} </span>

                                </div>
                                <div className="box ">
                                  <p className="bot5">Province</p>
                                  {inputProvince}
                                    <span style={{color: "red", fontSize:"12px",width:"100%", display:"flex"}}>{this.state.province_idErr} </span>
                                </div>
                                <div className="box ">
                                  <p className="bot5">City</p>
                                    {inputCity}
                                    <span style={{color: "red", fontSize:"12px",width:"100%", display:"flex"}}>{this.state.city_idErr} </span>
                                </div>
                             

                                
                                <div className="box gantiPwdInfo">
                                  <p className="bot5">Password</p>
                                  <Link to="/ChangePassword">
                                    <button>Change Password</button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mobile-information">
                    <div className="page-container-inner width-100">
                      <div className="box-edu-container width-100">
                        {/* <div className="box-job-desc-container width-100"> */}
                        <div className="box-other-recomended-container width-100">
                          <div
                            className="other-recomended-container-inner width-100 m-if"
                            style={{
                              padding: "0px 20px 30px 20px !important",
                            }}
                          >
                              <div className="item-myinformation">
                                {/* {datePick} */}
                                <div className="box">
                                  <p style={{ color: "gray" }}>Full Name</p>
                                  <input
                                    type="text"
                                    placeholder={profile.name}
                                    disabled="disabled"
                                  >
                                  </input>
                                </div>
                                </div>
                                <div className="item-myinformation">
                                <div className="box">
                                  <p style={{ color: "gray" }}>Email</p>
                                  <input
                                    type="text"
                                    placeholder={profile.email}
                                    disabled="disabled"
                                  ></input>
                                </div>
                                </div>
                                <div className="item-myinformation">
                                <div className="box gender">
                                  <p>Gender</p>
                                  <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="gender1" >
                                <FormControlLabel value="1" checked={(selected === 1 || selected === "1") } control={<Radio />} label="Male" onClick={()=> {this.setState({gender_id:1})}} />
                                    <FormControlLabel value="2" checked={selected === 2 || selected === "2"} control={<Radio />} label="Female" onClick={()=> {this.setState({gender_id:2})}}/>
                                </RadioGroup>
                                </FormControl>
                                <span style={{color: "red", fontSize:"12px"}}>{this.state.gender_idErr} </span>
                                {/* <span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.gender_idErr} </span> */}
                                </div>
                                
                                </div>
                               
                                
                                <div className="item-myinformation">
                                <div className="box">
                                  <div className="datepick">
                                    <p>Birth Date</p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                   
                                     <DatePicker  
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      name="birth_date"
                                      id="date-picker-inline"
                                      maxDate={new Date()}
                                      value={ this.state.birth_date !== "" ? this.state.birth_date : profile.birth_date}
                                      onChange={this.onChangeBirth} />
                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={date} className="iconDateInformation" />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                  <span style={{color: "red", fontSize:"12px"}}>{this.state.birth_date_Err} </span>
                                  {/* <span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.birth_date_Err} </span> */}
                                </div>
                               
                                </div>
                                
                                <div className="item-myinformation">
                                <div className="box">
                                  <p>Phone Number</p>
                                  <input
                                    type="text"
                                    placeholder="Phone Number"
                                    onChange={this.onChangePhone}
                                    label={profile.phone_number}
                                    defaultValue={profile.phone_number}
                                    ref={this.input}
                                    name="phone_number"
                                    id="phone_number"
                                  ></input>
                                  <span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.phone_number_Err} </span>
                                </div>
                                
                                </div>
                                <div className="item-myinformation">
                                <div className="box">
                                  <p>Address</p>
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    onChange={this.onChangeAddress}
                                    defaultValue={profile.address}
                                    ref={this.input}
                                    name="address"
                                    id="address"
                                  ></input>
                                  <span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.address_Err} </span>
                                </div>
                                
                                <div className="box">
                                  <p>Country</p>
                                  <select required
                                      className="form-control"
                                      onChange={this.changeCountry}
                                      name="education_level_id"
                                      id="education_level_id"
                                      style={{ height: "45px", width:"100%", marginTop:"15px" }}
                                    >
                                      <option disabled selected value={0}  label={`${countryName}`} style={{color:"#BDBDBD"}}>
                                      {countryName}
                                      </option>
                                      {dataCountry.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.id}
                                          onChange={this.changeCountry}
                                          onClick={()=>{this.setState({country_id: item.id})}}
                                          style={{height:"100px"}}
                                        >
                                          {item.country_name}{" "}
                                        </option>
                                      ))}
                                    </select>
                                    <span style={{color: "red", fontSize:"12px",width:"100%"}}>{this.state.country_Err} </span>
                                </div>
                                
                                <div className="box">
                                  <p>Province</p>
                                  {/* <select
                                      className="form-control"
                                      onChange={this.changeProvince}
                                      name="education_level_id"
                                      id="education_level_id"
                                      style={{ height: "45px", width:"100%", marginTop:"15px" }}
                                      disabled={
                                        ( this.state.country_id === ""  ?  "disabled" :false) 
                                      }
                                    >
                                      <option disabled selected value={0} style={{display:"none"}} >
                                     {ketState}
                                    
                                      </option>
                                      {dataProvince.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.id}
                                          onChange={this.changeProvince}
                                          onClick={()=>{this.setState({country_id: item.id})}}
                                          style={{height:"100px"}}
                                        >
                                          {item.state_name}{" "}
                                        </option>
                                      ))}
                                    </select> */}
                                      {inputProvince}
                                    <span style={{color: "red", fontSize:"12px",width:"100%",  display:"flex"}}>{this.state.province_idErr} </span>
                                </div>
                                
                                <div className="box">
                                  <p>City</p>
                                  {inputCity}
                                  {/* <select
                                      className="form-control"
                                      onChange={this.changeCity}
                                      name="education_level_id"
                                      id="education_level_id"
                                      style={{ height: "45px", width:"100%", marginTop:"15px" }}
                                      disabled={
                                        (  this.state.province_id==="" ?  "disabled" :false) 
                                      }
                                    >
                                      <option disabled selected value={0} style={{display:"none"}} >
                                     {ketCity}
                                      </option>
                                      {dataCity.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.id}
                                          onChange={this.changeCity}
                                          onClick={()=>{this.setState({country_id: item.id})}}
                                          style={{height:"100px"}}
                                        >
                                          {item.city_name}{" "}
                                        </option>
                                      ))}
                                    </select> */}
                                    <span style={{color: "red", fontSize:"12px",width:"100%",  display:"flex"}}>{this.state.city_idErr} </span>
                                </div>
                                
                                </div>
                                <div className="item-myinformation">
                                <div className="box gantiPwdInfo" style={{marginBottom:"32px"}}>
                                  <p>Password</p>
                                  <Link to="/ChangePassword">
                                    <button >Change Password</button>
                                  </Link>
                                </div>
                              </div>
                                <button
                                  className="btn-myinformation"
                                  id="button" onClick={this.information}
                                  type="submit"
                                  style={{ color: "white", marginBottom:"15px" , width:"100%", height:"44px"}}
                                >
                                  Save all changes &nbsp;
                                </button>
                                <button
                                  className="btn-myinformation"
                                  id="button"
                                  type="button"
                                  onClick={this.resetAll}
                                  style={{ color: "#3B6C98", width: "fit-content", backgroundColor:"white", borderColor:"white", width:"100%", height:"44px", marginBottom:"27px"}}
                                >
                                  Cancel &nbsp;
                                </button>
                             
                             
                            </div>
                          
                        </div>
                      </div>
                    </div>
                    </div>


                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ScrollToTop/>
        {/* <Footer/> */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
    onState1 : state.onStateRed,
    onCity1 : state.onCityRed,
    city1:state.cityRed,
    country1 : state.countryRed,
    country2 : state.countryNewRed,
    province1 : state.provinceRed,
    id: state.id,
    city_id:state.city_id,
    country_id:state.country_id,
    token1: state.token1,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
    info1: (id) => {
      dispatch(setInformation(id));
    },
    getCity: (province_id) => {
      dispatch(getCity(province_id));
    },
    getOnState: (state_id) => {
      dispatch(getOnState(state_id));
    },
    getOnCity: (state_id,country_id, city_id) => {
      dispatch(getOnCity(state_id, country_id, city_id));
    },
    getProvince: (country_id) => {
      dispatch(getProvince(country_id));
    },
    getCountry: () => {
      dispatch(getCountry());
    },
    getOnCountry: (country_id) => {
      dispatch(getOnCountry(country_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyInformation));
