import {REGISTER, LOGIN,GET_SKILL_LIST,POST_SKILL, PROFILE, UPDATE_PROFILE, GET_PROFILE_ID, UPDATE_PASSWORD, CHANGE_PASSWORD, UPDATE_INFORMATION, AUTOLOGIN,POST_REFERRAL,GET_REFERRAL,
        LINK_TO_SHARE, ALL_PROGRESS, PROGRESS_PROFILE, VERIFIKASI_EMAIL, GET_GENDER, GET_PROVINCE, GET_CITY, GET_COUNTRY, CITY, STATE, GET_COUNTRY_NEW,
        CHECK_USER,GET_PROJECT, PASSWORD_EMAIL,POST_USER_PROJECT, PUT_PROFILE_ID,GET_ONLY_CITY, EDIT_USER_PROJECT} from '../config/constants';
const initialState = {
  profile: [],
  data: [],
  update: [],
  dataLink:[],
  progress:[],
  allProgress:[],
  dataProvince:[],
  dataCity:[],
  skill:[],
  updateSkill :[],
  dataGender:[],
  dataCountry:[],
  dataCountryNew:[],
  dataReferral:[],
  invitation:[],
  updateProject :[],
  onCity:[],
  onlyCity:[],
  project :[],
  onState:[],
  isLoading: false,
  error: false,
};

export const signUp = (state = initialState, action) => {
  switch (action.type) {
    case `${REGISTER}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${REGISTER}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
      };
    case `${REGISTER}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${LOGIN}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${LOGIN}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const profile = (state = initialState, action) => {
  switch (action.type) {
    case `${PROFILE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${PROFILE}_FULFILLED`:
      return {
        ...state,
        profile: action.payload.data,
        isLoading: false,
      };
    case `${PROFILE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const update = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_PROFILE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${UPDATE_PROFILE}_FULFILLED`:
      return {
        ...state,
        updateProfile: action.payload.data,
        isLoading: false,
      };
    case `${UPDATE_PROFILE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};


export const postProject = (state = initialState, action) => {
  switch (action.type) {
    case `${POST_USER_PROJECT}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${POST_USER_PROJECT}_FULFILLED`:
      return {
        ...state,
        updateProject: action.payload.data,
        isLoading: false,
      };
    case `${POST_USER_PROJECT}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const editProjectRed = (state = initialState, action) => {
  switch (action.type) {
    case `${EDIT_USER_PROJECT}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${EDIT_USER_PROJECT}_FULFILLED`:
      return {
        ...state,
        updateProject: action.payload.data,
        isLoading: false,
      };
    case `${EDIT_USER_PROJECT}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const updateInfo = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_INFORMATION}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${UPDATE_INFORMATION}_FULFILLED`:
      return {
        ...state,
        update: action.payload.data,
        isLoading: false,
      };
    case `${UPDATE_INFORMATION}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};


export const onlyCity = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ONLY_CITY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_ONLY_CITY}_FULFILLED`:
      return {
        ...state,
        onlyCity: action.payload.data,
        isLoading: false,
      };
    case `${GET_ONLY_CITY}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const profileId = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PROFILE_ID}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_PROFILE_ID}_FULFILLED`:
      return {
        ...state,
        profile: action.payload.data,
        isLoading: false,
      };
    case `${GET_PROFILE_ID}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const updatePwd = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_PASSWORD}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${UPDATE_PASSWORD}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${UPDATE_PASSWORD}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const changePwd = (state = initialState, action) => {
  switch (action.type) {
    case `${CHANGE_PASSWORD}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${CHANGE_PASSWORD}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${CHANGE_PASSWORD}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const autoLogin = (state = initialState, action) => {
  switch (action.type) {
    case `${AUTOLOGIN}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${AUTOLOGIN}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${AUTOLOGIN}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const passwordEmail = (state = initialState, action) => {
  switch (action.type) {
    case `${PASSWORD_EMAIL}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${PASSWORD_EMAIL}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${PASSWORD_EMAIL}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const shareRed = (state = initialState, action) => {
  switch (action.type) {
    case `${LINK_TO_SHARE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${LINK_TO_SHARE}_FULFILLED`:
      return {
        ...state,
        dataLink: action.payload.data,
        isLoading: false,
      };
    case `${LINK_TO_SHARE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const progressRed = (state = initialState, action) => {
  switch (action.type) {
    case `${ALL_PROGRESS}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${ALL_PROGRESS}_FULFILLED`:
      return {
        ...state,
        progress: action.payload.data,
        isLoading: false,
      };
    case `${ALL_PROGRESS}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const progressProfileRed = (state = initialState, action) => {
  switch (action.type) {
    case `${PROGRESS_PROFILE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${PROGRESS_PROFILE}_FULFILLED`:
      return {
        ...state,
        allProgress: action.payload.data,
        isLoading: false,
      };
    case `${PROGRESS_PROFILE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const verifyRed = (state = initialState, action) => {
  switch (action.type) {
    case `${VERIFIKASI_EMAIL}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${VERIFIKASI_EMAIL}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${VERIFIKASI_EMAIL}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const genderRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_GENDER}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_GENDER}_FULFILLED`:
      return {
        ...state,
        dataGender: action.payload.data,
        isLoading: false,
      };
    case `${GET_GENDER}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const cityRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_CITY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_CITY}_FULFILLED`:
      return {
        ...state,
        dataCity: action.payload.data,
        isLoading: false,
      };
    case `${GET_CITY}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const countryRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_COUNTRY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_COUNTRY}_FULFILLED`:
      return {
        ...state,
        dataCountry: action.payload.data,
        isLoading: false,
      };
    case `${GET_COUNTRY}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const countryNewRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_COUNTRY_NEW}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_COUNTRY_NEW}_FULFILLED`:
      return {
        ...state,
        dataCountryNew: action.payload.data,
        isLoading: false,
      };
    case `${GET_COUNTRY_NEW}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const provinceRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PROVINCE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_PROVINCE}_FULFILLED`:
      return {
        ...state,
        dataProvince: action.payload.data,
        isLoading: false,
      };
    case `${GET_PROVINCE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const onCityRed = (state = initialState, action) => {
  switch (action.type) {
    case `${CITY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${CITY}_FULFILLED`:
      return {
        ...state,
        onCity: action.payload.data,
        isLoading: false,
      };
    case `${CITY}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const onStateRed = (state = initialState, action) => {
  switch (action.type) {
    case `${STATE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${STATE}_FULFILLED`:
      return {
        ...state,
        onState: action.payload.data,
        isLoading: false,
      };
    case `${STATE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const postReferralRed = (state = initialState, action) => {
  switch (action.type) {
    case `${POST_REFERRAL}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${POST_REFERRAL}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case `${POST_REFERRAL}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
}; 

export const getReferralRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_REFERRAL}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${GET_REFERRAL}_FULFILLED`:
      return {
        ...state,
        dataReferral: action.payload.data,
        isLoading: false
      };
    case `${GET_REFERRAL}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
}; 
export const getInvitationRed = (state = initialState, action) => {
  switch (action.type) {
    case `${CHECK_USER}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${CHECK_USER}_FULFILLED`:
      return {
        ...state,
        invitation: action.payload.data,
        isLoading: false
      };
    case `${CHECK_USER}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
}; 

export const getSkillRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_SKILL_LIST}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${GET_SKILL_LIST}_FULFILLED`:
      return {
        ...state,
        skill: action.payload.data,
        isLoading: false
      };
    case `${GET_SKILL_LIST}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
};
export const postSkillRed = (state = initialState, action) => {
  switch (action.type) {
    case `${POST_SKILL}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${POST_SKILL}_FULFILLED`:
      return {
        ...state,
        updateSkill: action.payload.data,
        isLoading: false
      };
      
    case `${POST_SKILL}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export const getProjectRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PROJECT}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${GET_PROJECT}_FULFILLED`:
      return {
        ...state,
        project: action.payload.data,
        isLoading: false
      };

    case `${GET_PROJECT}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export const putProfileIdRed = (state = initialState, action) => {
  switch (action.type) {
    case `${PUT_PROFILE_ID}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${PUT_PROFILE_ID}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case `${PUT_PROFILE_ID}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
}; 