import React, { Component } from 'react';
import logo from "../images/KaryaTalents_logo.png";
import checklist_gold from "../images/pid_checklist_gold.svg"
import profiler_checklist from "../images/pid_profiler_checklist.svg"
import profiler_work from "../images/pid_profiler_work.svg"
import profiler_initial from "../images/pid_profiler_initial.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";


class componentName extends Component {
    constructor() {
        super();
        this.state = {
            user_id:"",
            rating:"",
            ratting:0,
            comment:"",
            desc_ratting:"",
        }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0)
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.track(window.location.pathname);
        
      }

      changeLink=(e)=>{
        const token = sessionStorage.getItem("token");
        if (token !==""  && token !== undefined  && token !== null){
          window.location.href="/PID_Questions"
        }else{
          window.location.href="/PID-Questions-Stand-Alone"
        }
      }



    render() {
        return (
            <div>
                <div className="header_PID width-100">
                    <a href="/NewDashboard" style={{margin:"auto"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} />
                    </a>
                </div>
                <div className="box-wrapper personality_getStarted" >
                    <div className="wrapper">
                        <div className="content">
                            <div className="page-container ">
                                <div className="page-container-outer " style={{marginBottom:"50px"}}>
                                    <div className="box_PID">
                                        <h1 className="title_PID">Personality Profiler</h1>
                                        <div className="flex width-100 columnPID_Mobile columnTablet">
                                            <div className="width-50 flex column box_personality_left">
                                                <h3 >About this Profiler</h3>
                                                <div className="text_Personality">
                                                    <div className="line_personality" />
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>This is not a test. There are no right or wrong answers.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>The results will help you understand your basic personality profile and the characteristics of your design. This knowledge will enable you to make wise decisions and manage your gifts and talents.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>Reflect back on your entire life - respond in the way you would normally act without outside influences.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>Do not respond based on what you think is most acceptable to others, or how you must act in order to succeed in your job.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>Focus on your core self and respond based on your instinctive behavior, regardless of whether you consider it to be good or bad.</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="width-50 flex column box_personality_right">
                                                <h3>Guidelines</h3>
                                                <div className="text_Personality">
                                                    <div className="line_personality" />
                                                    <div className="flex width-100 List_right">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={profiler_checklist} />
                                                        <p>Choose words that best describe what YOU are really like.</p>
                                                    </div>
                                                    <div className="flex width-100 List_right">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={profiler_work} />
                                                        <p>WORK QUICKLY. Avoid agonizing over your choices.</p>
                                                    </div>
                                                    <div className="flex width-100 List_right">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={profiler_initial} />
                                                        <p>Your INITIAL CHOICE or reaction will usually be the best choice.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex width-100 center pid_getStarted" style={{margin: "0px 0px 32px"}}>
                                            {/* <a href="/PID_Questions"> */}
                                            <button onClick={this.changeLink}>
                                                <p style={{padding:"14px 48px"}}>Start</p>
                                            </button>
                                            {/* </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default componentName;