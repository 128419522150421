import React, { Component } from "react";
import Navbar_Homepage from "../components/Navbar_Homepage";
import artDSR from "../images/artDSR.png";
import bgDSR from "../images/bgDSR.png";
import accent from "../images/dsr_accent.png";
import dsr_bg2 from "../images/dsr_bg2.png";
import dsr_bg3 from "../images/dsr_bg3.png";
import dsr_bg4 from "../images/dsr_bg4.png";
import dsr_bg5 from "../images/dsr_bg5.png";
import dsr_bg6 from "../images/dsr_bg6.png";
import dsr_multi from "../images/dsr_multi.png";
import dsr_ico_bg4 from "../images/dsr_ico_bg4.png";
import dsr_checklist from "../images/dsr_checklist.svg";
import checklist_or from "../images/dsr_checklist_orange.svg";
import dsr_accClose from "../images/dsr_accClose.svg";
import dsr_cer1 from "../images/dsr_cer1.svg";
import tlk_wave from "../images/tlk_wave.png";
import tlk_bg_3rd from "../images/tlk-bg-3rd.png";
import { API } from "../_redux/helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import epy_match from "../images/epy_match.svg";
import epy_assess from "../images/epy_assess.svg";
import epy_advise from "../images/epy_advise.svg";
import ReactPixel from "react-facebook-pixel";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ReactGA from "react-ga";
import { GAevent } from "../components/TrackingEvent";
import axios from "axios";
import emy_bg_input from "../images/emy_bg_input.png";
import patterns from "../images/patterns.svg";
import { TextField } from "@material-ui/core";
import Modal from "react-awesome-modal";
import apply_success from "../images/apply_success.svg";
import Loading from "../components/Loading";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

import dsr_cer2 from "../images/dsr_cer2.svg";
import dsr_cer3 from "../images/dsr_cer3.svg";
import dsr_cer4 from "../images/dsr_cer4.svg";
import fin1 from "../images/dsr_finance1.svg";
import fin2 from "../images/dsr_finance2.svg";
import fin3 from "../images/dsr_finance3.svg";
import fin4 from "../images/dsr_finance4.svg";
import about1 from "../images/dsr_about1.svg";
import about2 from "../images/dsr_about2.svg";
import journey1 from "../images/dsr_journey1.svg";
import journey2 from "../images/dsr_journey2.svg";
import journey3 from "../images/dsr_journey3.svg";
import journey4 from "../images/dsr_journey4.svg";
import journey5 from "../images/dsr_journey5.svg";
import journey6 from "../images/dsr_journey6.svg";
import dsr_trainer1 from "../images/dsr_trainer1.svg";
import dsr_trainer2 from "../images/dsr_trainer2.svg";
import dsr_trainer3 from "../images/dsr_trainer3.svg";
import dsr_ico_grow from "../images/dsr_ico_grow.svg";
import dsr_ico_career from "../images/dsr_ico_career.svg";
import dsr_ico_salary from "../images/dsr_ico_salary.svg";
import dsr_ico_bootcamp from "../images/dsr_ico_bootcamp.svg";
import apply from "../images/dsr_apply.png";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import Pulse from "react-reveal/Pulse";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import { vi } from "date-fns/locale";

class Academy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      talents: false,
      resources: false,
      path: ["/", "/jo"],
      transform: "",
      no: 0,
      hiring: 0,
      career: 0,
      no_client: 0,
      id_slide: "",
      list_index: [1, 4, 7, 10],
      before: false,
      after: false,
      dis: false,
      candidates: [],
      clients: [],
      filter: {},
      data_dynamic: [],
      aboutUs: [],
      data_banner: [],
      height_: 0,
      n: 0,
      sal: "",
      br: 0,
      salary: 0,
      loaded: false,
      view: [1, 5],
      view_id: 1,
      bottom: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    window.addEventListener("scroll", this.handleScroll);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    if (localStorage.getItem("token")) {
      window.location.href = "/NewDashboard";
    }
    axios.get(`${API}/api/employer_dynamic`).then((res) => {
      this.setState({ data: res.data });
    });
  }

  handleScroll = (event) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (parseFloat(winScroll) < 250) {
      this.setState({ br: scrolled });
    }
    if (parseFloat(scrolled) > 0.99) {
      this.setState({ bottom: true });
    }
  };

  setAcc = (e) => {
    const cek1 = this.state.view;
    const n = cek1.findIndex((x) => x == e);
    if (n == -1) {
      cek1.push(e);
    } else {
      cek1.splice(n, 1);
    }
    this.setState({ view: cek1 });
  };

  directToMultimac = (e) => {
    window.location.href =
      "https://forms.office.com/pages/responsepage.aspx?id=3PRlozmRjkia5q6VYspEkVchnlCLsPtNntnylH1nwH1UMVZWUVBPT1pDS1gzWUJCMTBTSklHTks4Vi4u";
  };

  register = (e) => {
    window.location.href = " /registerdsr";
  };

  render() {
    const { height_, br, view, view_id, bottom } = this.state;
    let status = false;
    if (br > 0) {
      status = true;
    }

    const checkId = (e) => {
      let n = false;
      if (view.length > 0 && view.indexOf(e) !== -1) {
        n = true;
      }
      return n;
    };

    return (
      <React.Fragment>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
        </Helmet>
        <div className="flex column width-100 bg-ff alignCenter dsr">
          <div
            className="Banner-bx width-100 mb-24 flex hg-762"
            style={{ backgroundImage: `url(${bgDSR})` }}
          >
            <Navbar_Homepage status={status} />
            <div
              className="wd-content tr- flex-mob auto height-100 space-between h_"
              id="homepage22"
            >
              <div className="wd-490 flex height-100 h_">
                <div className="text-bx flex column ">
                  <Fade duration={1000} cascade right>
                    <div className="text-bx tr- flex column ">
                      {/* <p className='c-ff flex Kanit-400 s-80 mt-162'>{"Data Science"}</p> */}
                      {/* <p className='c-ff Kanit-400 s-100 w-600 mt--38 stroke_'>ACADEMY</p> */}
                      <p className="c-ff flex Kanit-400 s-100 mt-162 stroke_">
                        ACADEMY
                      </p>
                      <p className="c-ff Kanit-300 s-2436">
                        Digital Dynamic Capability Development Training
                        <p className="mt--5">
                          The Leader Starts Small and Ends Great!
                        </p>
                      </p>
                      {/* <p className='c-ff Kanit-400 s-1827 p-620 wd-fit-content br-23 bd-ff mb-14'>SELECTED • TRAINED • CERTIFIED • HIRED </p> */}
                      {/* <p className='c-ff Kanit-400 s-24 al- k'><font color="#efb41e">Digital Dynamic Capability Development Training</font></p> */}
                      {/* <p className='c-ff Kanit-400 s-24 mb-32 al-'>Conquer the Big Data Era!</p> */}
                      {/* <div className='bg-12  br-24 p-1024 flex pointer mb-10 wd-fit-content' onClick={(e)=>this.register(e)}>
                                            <span className='c-ff Kanit s-16 let-04 w-600 Montserrat text-cap'>REGISTER TODAY</span>
                                        </div> */}
                      {/* <br></br>
                                        <p className='c-ff s-12 Kanit-400' align='justify'>Lorem ipsum is simply dummy text Lorem ipsum is simply dummy text Lorem ipsum is simply dummy text Lorem ipsum is simply dummy text Lorem ipsum is simply dummy text</p> */}
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex wd-766 height-100 m-auto00 bg-contain no-repeat bg-position-bottom">
                <Zoom duration={1000}>
                  <img
                    loading="lazy"
                    src={artDSR}
                    alt=""
                    onError={(e) => {
                      e.target.src = artDSR;
                    }}
                    className="flex m-auto20 art_"
                  />
                </Zoom>
              </div>
            </div>
          </div>
          <div className="flex column width-100">
            <div className="block width-100  border-dash mb-27 mt-32" />
            <div className="flex width-100 center">
              <Fade>
                {/* <p className='s-48 Kanit-400 c-09 mt--38 bg-ff p-048'>Why Start a</p> */}
              </Fade>
            </div>
            <Fade bottom delay={250}>
              <p className="s-48 flex center width-100 c-d87 w-600 mb-48 _c a w-8">
                Our Services
              </p>
            </Fade>
          </div>

          <div className="wd-content a- flex wrap space-between mb-100">
            <Slide bottom>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_grow}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                    alt=""
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Business Agility
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Agile Product Innovation (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Mastering Agile Scrum (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Business Resilience with Agile (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Agile Culture to Win Disruptive Era (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Slide>
            <Fade bottom delay={100}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_salary}
                    alt=""
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Digital Leadership
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Digital Leadership (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Transformational Leadership (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Agile Leadership (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Building Digital Capabilities (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={200}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_career}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Digital Transformation
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      DIS: A Successful Guide to Digital Transformation (3 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Big Data in Business (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Cyber Security for Leaders (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Emerging Technology in Digital Era (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={300}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_bootcamp}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Managing People and Organization
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      OKR (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Balanced Score Card (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Building Highly Performing Team (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Problem-Solving and Creative Thinking Skills (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
          </div>
          <div className="wd-content a- flex wrap space-between mb-100">
            <Slide bottom>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_grow}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Strategy
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Strategic Management in Business (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      The Top 5 Strategic Management Frameworks (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Mapping Innovation Strategy (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Design Thinking and Lean Startup (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Slide>
            <Fade bottom delay={100}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_salary}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Project Management
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Supply Chain Practices (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Purchasing and Procurement Practices (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Distribution Practices (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Warehouse and Logistics Practices (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={200}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_career}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Risk Management
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Enterprise Risk Management (3 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Managing Risk with ISO 31000 (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Managing Risk with COSO (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Risk Control Self Assessment (RCSA) (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={300}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_bootcamp}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Supply Chain Management
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Supply Chain Practices (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Purchasing and Procurement Practices (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Distribution Practices (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Effective Warehouse and Logistics Practices (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
          </div>
          <div className="wd-content a- flex wrap space-between mb-100">
            <Slide bottom>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_grow}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Sales Management
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Becoming a Sales Champion (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Consultative Selling (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Sales Check Up (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Strategic Sales Planning and Territory Management (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Slide>
            <Fade bottom delay={100}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_salary}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Finance Management
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Corporate Finance (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Financial Risk Management (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Budget Planning and Controlling (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Finance for Non-Finance (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={200}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_career}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Customer Excellence
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Digital Customer Experience (3 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Driving Excellent Customer Service (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Service Excellence for Front Liners (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Customer-Centric Organization (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={300}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_bootcamp}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    Productivity
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Enhancing Productivity with Ms Word (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Enhancing Productivity Ms Excel (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Enhancing Productivity Ms PowerPoint (2 days)
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Presenting Data Like a Pro (2 days)
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
          </div>
          <Fade bottom cascade></Fade>

          <div className="flex width-100 Hp-22 column m">
            <img
              src={apply}
              className="fixed flex wd-145 hg-42 dsr-app pointer"
              onClick={(e) => (window.location.href = " /registerdsr")}
            />
            <WhatsAppWidget
              phoneNumber="6287717888800"
              companyName="Karya Talents"
            />
            <div className="flex">
              <div className="sticky br-30 flex column wd-fit-content p-10 space-between">
                <a
                  href="https://www.linkedin.com/company/karya-talents"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM8.2 13.3V24H11.6V13.3H8.2ZM8 9.9C8 11 8.8 11.8 9.9 11.8C11 11.8 11.8 11 11.8 9.9C11.8 8.8 11 8 9.9 8C8.9 8 8 8.8 8 9.9ZM20.6 24H23.8V17.4C23.8 14.1 21.8 13 19.9 13C18.2 13 17 14.1 16.7 14.8V13.3H13.5V24H16.9V18.3C16.9 16.8 17.9 16 18.9 16C19.9 16 20.6 16.5 20.6 18.2V24Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://instagram.com/karyatalents?igshid=odlswb4q0a06"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4 9.2H12.6C11.8 9.3 11.4 9.4 11.1 9.5C10.7 9.6 10.4 9.8 10.1 10.1C9.86261 10.3374 9.75045 10.5748 9.61489 10.8617C9.57916 10.9373 9.5417 11.0166 9.5 11.1C9.48453 11.1464 9.46667 11.1952 9.44752 11.2475C9.34291 11.5333 9.2 11.9238 9.2 12.6V19.4C9.3 20.2 9.4 20.6 9.5 20.9C9.6 21.3 9.8 21.6 10.1 21.9C10.3374 22.1374 10.5748 22.2495 10.8617 22.3851C10.9374 22.4209 11.0165 22.4583 11.1 22.5C11.1464 22.5155 11.1952 22.5333 11.2475 22.5525C11.5333 22.6571 11.9238 22.8 12.6 22.8H19.4C20.2 22.7 20.6 22.6 20.9 22.5C21.3 22.4 21.6 22.2 21.9 21.9C22.1374 21.6626 22.2495 21.4252 22.3851 21.1383C22.4209 21.0626 22.4583 20.9835 22.5 20.9C22.5155 20.8536 22.5333 20.8048 22.5525 20.7525C22.6571 20.4667 22.8 20.0762 22.8 19.4V12.6C22.7 11.8 22.6 11.4 22.5 11.1C22.4 10.7 22.2 10.4 21.9 10.1C21.6626 9.86261 21.4252 9.75045 21.1383 9.61488C21.0627 9.57918 20.9833 9.54167 20.9 9.5C20.8536 9.48453 20.8048 9.46666 20.7525 9.44752C20.4667 9.3429 20.0762 9.2 19.4 9.2ZM16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM21.4 11.6C21.4 12.1523 20.9523 12.6 20.4 12.6C19.8477 12.6 19.4 12.1523 19.4 11.6C19.4 11.0477 19.8477 10.6 20.4 10.6C20.9523 10.6 21.4 11.0477 21.4 11.6Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM12.6 7.7H19.4C20.3 7.8 20.9 7.9 21.4 8.1C22 8.4 22.4 8.6 22.9 9.1C23.4 9.6 23.7 10.1 23.9 10.6C24.1 11.1 24.3 11.7 24.3 12.6V19.4C24.2 20.3 24.1 20.9 23.9 21.4C23.6 22 23.4 22.4 22.9 22.9C22.4 23.4 21.9 23.7 21.4 23.9C20.9 24.1 20.3 24.3 19.4 24.3H12.6C11.7 24.2 11.1 24.1 10.6 23.9C10 23.6 9.6 23.4 9.1 22.9C8.6 22.4 8.3 21.9 8.1 21.4C7.9 20.9 7.7 20.3 7.7 19.4V12.6C7.8 11.7 7.9 11.1 8.1 10.6C8.4 10 8.6 9.6 9.1 9.1C9.6 8.6 10.1 8.3 10.6 8.1C11.1 7.9 11.7 7.7 12.6 7.7Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://web.facebook.com/KT300CLUB/?_rdc=1&_rdr"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16 8C20.4 8 24 11.6 24 16C24 20 21.1 23.4 17.1 24V18.3H19L19.4 16H17.2V14.5C17.2 13.9 17.5 13.3 18.5 13.3H19.5V11.3C19.5 11.3 18.6 11.1 17.7 11.1C15.9 11.1 14.7 12.2 14.7 14.2V16H12.7V18.3H14.7V23.9C10.9 23.3 8 20 8 16C8 11.6 11.6 8 16 8Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCAXhjK2OivKsLeYPVNMsLHA"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M18.6 16L14.4 13.6V18.4L18.6 16Z" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM22.2 10.7C22.9 10.9 23.4 11.4 23.6 12.1C24 13.4 24 16 24 16C24 16 24 18.6 23.7 19.9C23.5 20.6 23 21.1 22.3 21.3C21 21.6 16 21.6 16 21.6C16 21.6 10.9 21.6 9.7 21.3C9 21.1 8.5 20.6 8.3 19.9C8 18.6 8 16 8 16C8 16 8 13.4 8.2 12.1C8.4 11.4 8.90001 10.9 9.60001 10.7C10.9 10.4 15.9 10.4 15.9 10.4C15.9 10.4 21 10.4 22.2 10.7Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <ScrollToTop/> */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Academy;
