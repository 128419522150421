import { GET_LAST_ID, POST_LAST, GET_LAST_LIST, DELETE_LAST, PUT_LAST, GET_LAST } from "../config/constants";
import axios from "axios";
import { API } from '../_redux/helper'

const token = sessionStorage.getItem('token')
export const getLast = (id) => {
  return {
    type: GET_LAST,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_latest_benefit/${id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const getLastList = () => {
  return {
    type: GET_LAST_LIST,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_latest_benefit/`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const getLastId = (user_id) => {
  return {
    type: GET_LAST_ID,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_latest_benefit?user_id=${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const postLast = (user) => {
  return {
    type: POST_LAST,
    payload: axios({
      method: "POST",
      url: `${API}/api/user_latest_benefit`,
      data: user,
      headers: {
        Authorization: ` ${token}`,
      },
    }).then((res) => {
      window.location.reload(true);
    })
  };
};

export const putLast = (id) => {
  return {
    type: PUT_LAST,
    payload: axios({
      method: "PUT",
      url: `${API}/api/user_latest_benefit/${id}`,
      data: id,
      headers: {
        Authorization: ` ${token}`,
      }
    }).then((res) => {
      window.location.reload(true);
    })
  };
};

export const deleteLast = (id) => {
  return {
    type: DELETE_LAST,
    payload: axios({
      method: "DELETE",
      url: `${API}/api/user_latest_benefit/${id}`,
      data: id,
      headers: {
        Authorization: ` ${token}`,
      }
    }).then((res) => {
      window.location.reload(true);
    })
  };
};