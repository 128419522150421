import React, { Component } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { connect } from "react-redux";
import { getDetail, getArticleExclude } from "../_actions/article";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import ReactGA from 'react-ga';
import {API} from '../_redux/helper';
import {sortFunction, sortUpdate} from "../_redux/function"
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import terms from "../images/no-image.png"



var $ = require('jquery');
class DetailSummary extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
    };
  }

  componentDidMount() {
    this.props.getDetail(this.props.id);
    this.props.getArticleExclude(this.props.id);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    sessionStorage.setItem('setupTime', new Date().getTime())
    if(localStorage.getItem('token')) {
      window.location.href = '/NewDashboard'
    }
    window.scrollTo(0, 0)
  }

  refresh = (id, judul) => {
    this.props.getDetail(id);
    this.props.getArticleExclude(id);
    window.location.href = `/Career-Resources/${id}/${judul}`;
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleToken = () => {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
  };

  backToArticle=(f)=>{
    const e = parseInt(f)
    if (e===1){
      window.location.href="/career-resources"
    }else if (e===2){
      window.location.href="/hiring-resources"
    }else{
      window.location.href="/article"
    }
  }

  render() {
    const { data, isLoading } = this.props.detail1;
    const { dataArticle } = this.props.otherArticle1;
    var bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
    const name =
      this.props.detail1.data !== null ? this.props.detail1.data.name : "Undefined";

    const data1 = dataArticle.sort(sortFunction)
    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

   
    let new_data =[]
    let slug_1 = []
    let slug_2 = []
    let slug_12 =[]

    if (data1.length > 0){
      data1.map((item)=>{
      if (parseInt(item.slug)===1 || item.slug===""){
        slug_1.push(item)
      }
      if (parseInt(item.slug)===2){
        slug_2.push(item)
      }
      slug_12.push(item)
  })}


      if (parseInt(sessionStorage.getItem("slug"))==2){
        new_data = slug_2
      }else if (parseInt(sessionStorage.getItem("slug"))==1){
        new_data = slug_1
      }else{
        new_data = slug_12
      }
console.log(data.isi,"data.isi");

    return (
      <div>
        <div className="box-wrapper box-wrapper-master detail_">
          <div className="wrapper">
            <div className="content">
            <Nav/>
              <div className="page-container mt-80 master-page width-100">
                <div className="article-detail">
                  <div className="page-container-inner width-100">
                    {/* <div> */}
                    <Fade>
                      <div className="banner image-other-articles">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/Artikel/${data.photo}`} onError={(e)=>{e.target.src = terms}} className=" img-center" />
                      </div>
                      </Fade>
                      <div className="text-detail khusus-otherarticle-70">
                        <div className="link-article">
                          <a  onClick={()=>this.backToArticle(sessionStorage.getItem("slug"))}> {"<"} Back to Articles</a>
                        </div>
                        <div className="article-content-html">
                          <h1 className="leagueSpartan"> {data.judul} </h1>
                        </div>
                        <div className="article-content-html" style={{wordWrap: "break-word"}}
                          dangerouslySetInnerHTML={{ __html: data.isi }}
                        />
                      </div>
                    {/* </div> */}
                    {/* <Slide bottom cascade> */}
                    <div className="other-articles khusus-otherarticle-70">

                      <div className="other-articles-title">
                        <h3>Other Articles</h3>
                      </div>

                        <div className="other-articles-content">
                            {new_data.map((item, i) => {
                              return (
                                <div key={i} className="other-articles-data">
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/Artikel/${item.photo}`} onError={(e)=>{e.target.src = terms}} />
                                  <div className="other-articles-data-text width-100">
                                    <div>
                                      <div className="flex space-between width-100">
                                    <p className="subTitle" style={{color:"#AD8C53", marginBottom:"10px"}}>Article</p>
                                    <p className="subDate" style={{color:"#828282"}}> {new Date(item.createdAt).getDate()}  {bulan[new Date(item.createdAt).getMonth()]}  {new Date(item.createdAt).getFullYear()} </p>
                                    </div>
                                    {
                                      <h3 style={{color:"black"}}
                                        dangerouslySetInnerHTML={{ __html: item.judul }}
                                      />
                                    }
                                    </div>
                                    <Link  onClick={()=>window.location.href=`/Article-Detail/${item.id}/${item.judul.split(' ').join('-')}`} >
                                      <p className="subTitle"  style={{color:"#3B6C98",fontWeight:"600"}} >Read Article</p>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                        </div>


                      </div>
                      {/* </Slide> */}
                  </div>
                </div>
              </div>
              <ScrollToTop/>
            <Footer/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    detail1: state.articleDetail,
    otherArticle1: state.otherArticle,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
    getArticleExclude: (id) => {
      dispatch(getArticleExclude(id));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailSummary)
);