import React, { Component } from "react";
import karya from "../images/logoBaru.png";
import rec from "../images/std-bg.png"
import std from "../images/img-std.png"
import { connect } from "react-redux";
import warning_referral from "../images/warning_referral.svg"
import Modal from "react-awesome-modal";
import {getJobLevel} from "../_actions/work";
import ReactGA from 'react-ga';
import Loading from "../components/Loading";
import {API} from '../_redux/helper'
import axios from "axios"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';

class GetStarted extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      job_level:"",
      name:"",
      email:"",
      change_bg:false,
      job_count:0,
      emailErr:'',
      nameErr:"",
      job_levelErr:"",
      popup:false,
      cek_email:false,
      link:""
    }
    };

    componentDidMount(){
      this.props.getJobLevel();
      sessionStorage.setItem('setupTime', new Date().getTime())
      ReactGA.pageview(window.location.pathname + window.location.search);
      ReactPixel.track(window.location.pathname);
      window.scrollTo(0, 0)
    }


  goBack() {
    this.props.history.goBack();
  }
  handleCallback = (childData) =>{
    this.setState({link: childData, popup:false,})
}
 

 changeName=(e)=>{
   const reg = /^(?![\s.]+$)[a-zA-Z\s.]*$/
   if (reg.test(e.target.value)===true || reg.test(e.target.value)==="true"){
    this.setState({name:e.target.value})
   }

   
 }
 changeEmail=(e)=>{
  const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  this.setState({email:e.target.value})
  if (reg.test(this.state.email)===true || reg.test(this.state.email)==="true"){
    this.setState({cek_email: true})
   }else{
    this.setState({cek_email: false})
   }
}

changeJobLevel=(e)=>{
  this.setState({job_level:e.target.value})
}

postData=(e)=>{
  const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  const invalid_email = "Email is not valid"
  const empty_field = "This field must fill"
  const valid_email = ""
  const {email,name, job_level, job_levelErr,emailErr, nameErr} = this.state

  if (reg.test(email)===false || reg.test(email)==="false"){
   this.setState({emailErr: invalid_email})
  }else if (email===""){
    this.setState({emailErr:empty_field})
  }else{
    this.setState({emailErr: valid_email})
  }

  if (name===""){
    this.setState({nameErr:empty_field})
  }else{
    this.setState({nameErr:""})
  }

  if (job_level===""){
    this.setState({job_levelErr:empty_field})
  }else{
    this.setState({job_levelErr:""})
  }

  if(nameErr !== "" || job_levelErr !=="" || emailErr !==""
    || name==="" || job_level==="" || email==="" || 
    (reg.test(email)===false || reg.test(email)==="false")){

  }else{
const data={
  name:name,
  email: email,
  job_level_id: job_level
}
axios.post(`${API}/api/v1/survey/create`,data)
        .then(res => {
         sessionStorage.setItem("s-email", data.email)
         sessionStorage.setItem("s-name", data.name)
          if (res.data.message=="Email already registered"){
            this.setState({popup:true})
          }else{
            window.location.href="/Personality_Getstarted"
            sessionStorage.setItem("id-std",res.data.data.id)
            sessionStorage.setItem("personality-std",true)
          }
        })
}}

backToHomepage=()=>{
  window.location.href="/"
}

  render() {
    const {dataJobLevel, isLoading, error} = this.props.jobLevel1;


     if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }
  
    
    return (
      <div>
        <div className="std-getstarted">
          <div className="wrapper std-getStarted" >
            <div className="content">
              <div className="page-container assessment-result width-100">
                <div className="header-landing-page">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya} className="logo" onClick={this.backToHomepage} />
                </div>
                <div className="box-content-std">
                  <div className="sm-header width-100 flex center" style={{backgroundImage:`url(${rec})`}}>
                      <h1>Welcome to Personality Profiler!</h1>
                  </div>
                  <div className="std-content flex space-between">
                      <div className="width-50 flex auto column">
                        <LazyLoadImage effect="blur" src={std} className="width-80 flex" />
                      </div>
                      <div className="width-50 flex  column ">
                        <div className="std-field">
                          <h6 className="openSans">Please fill the required informations below, before start filling this assessment and get comprehensive report</h6>
                          <div className="box-field flex column">
                              <p>Name</p>
                              <input type="text" placeholder="Your name" className="width-100" value={this.state.name} onChange={this.changeName} />
                              <span style={{color: "red", fontSize:"12px"}}>{this.state.nameErr}</span>
                          </div>
                          <div className="box-field flex column">
                              <p>Email</p>
                              <input type="text" placeholder="Your email" className="width-100" value={this.state.email} onChange={this.changeEmail}/>
                              <span style={{color: "red", fontSize:"12px"}}>{this.state.emailErr}</span>
                          </div>
                          <div className="box-field flex column">
                              <p>Job Level</p>
                              <select className="width-100" value={this.state.job_level} onChange={this.changeJobLevel}>
                                 <option  selected value={0} style={{display:"none", color:"#828282", fontWeight:"500px"}}>(choose)</option>
                                {dataJobLevel.map((item,i)=>{
                                  return(
                                <option value={item.id} key={i}>{item.job_level_name} </option>
                                )})}
                              </select>
                              <span style={{color: "red", fontSize:"12px"}}>{this.state.job_levelErr}</span>
                          </div>
                          <button onClick={this.postData}>
                            <p>Get Started</p>
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <Modal id="failed" visible={this.state.popup} effect="fadeInUp">
              <div  className="modal_apply">
                  <div className="width-100 flex center">
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={warning_referral} style={{marginTop:"24px", marginBottom:"24px"}}/>
                  </div>
                  <h5>Oops!</h5>
                  <p style={{width:"90%", marginLeft:"5%", marginBottom:"0px"}}>Email already registered</p>
                  <p style={{width:"90%", marginLeft:"5%"}}>Please login to take the personality profiler or use another email</p>
                  <div className="width-100 flex center" style={{marginBottom:"20px"}}>
                      <button  onClick={() => window.location.href="/login"} className="btn-std-login reg" style={{margin:"0px 10px"}} >
                          <p style={{margin:"12px"}}>Login</p>
                      </button>
                      <button onClick={()=>{this.setState({popup:false})}} style={{margin:"0px 10px"}}>
                          <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                      </button>
                  </div>
              </div>
          </Modal>
        </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    jobLevel1 : state.jobLevelRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobLevel: () =>{
      dispatch(getJobLevel())
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);

