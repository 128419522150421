import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar_Homepage from "../components/Navbar_Homepage";
import Footer from "../components/Footer";
import { getAllArticle } from "../_actions/article";
import ScrollToTop from "../components/ScrollToTop";
import Loading from "../components/Loading";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactGA from "react-ga";
import { API } from "../_redux/helper";
import { sortFunction, sortUpdate } from "../_redux/function";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPixel from "react-facebook-pixel";
import terms from "../images/no-image.png";

class AboutUs extends Component {
  componentDidMount() {
    this.props.getAllArticle();
    sessionStorage.setItem("setupTime", new Date().getTime());
    if (localStorage.getItem("token")) {
      window.location.href = "/NewDashboard";
    }
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    if (
      window.location.pathname.toLowerCase().indexOf("hiring-resources") !== -1
    ) {
      sessionStorage.setItem("slug", 2);
    } else if (
      window.location.pathname.toLowerCase().indexOf("career-resources") !== -1
    ) {
      sessionStorage.setItem("slug", 1);
    } else {
      sessionStorage.setItem("slug", 3);
    }
  }

  render() {
    const { data, isLoading, error } = this.props.about1;
    var bulan = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Mohon, refresh halaman ini!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }
    const data1 = data.sort(sortFunction);
    let new_data = [];
    let slug_1 = [];
    let slug_2 = [];
    let slug_12 = [];

    if (data1.length > 0) {
      data1.map((item) => {
        if (parseInt(item.slug) === 1 || item.slug == "") {
          slug_1.push(item);
        }
        if (parseInt(item.slug) === 2) {
          slug_2.push(item);
        }
        slug_12.push(item);
      });
    }

    if (
      window.location.pathname.toLowerCase().indexOf("hiring-resources") !== -1
    ) {
      new_data = slug_2;
    } else if (
      window.location.pathname.toLowerCase().indexOf("career-resources") !== -1
    ) {
      new_data = slug_1;
    } else {
      new_data = slug_12;
    }

    return (
      <div>
        <div
          className="box-wrapper box-wrapper-master"
          style={{ backgroundColor: "white", height: "auto" }}
        >
          <div className="wrapper">
            <div className="content">
              <Navbar_Homepage status="bg-ff fsh_" />
              <div className="page-container mt-80 master-page width-100">
                <div className="flex-article">
                  <div className="article-left">
                    {new_data.map((item, i) => {
                      if (i <= 0) {
                        return (
                          <div key={i}>
                            <div className="banner">
                              <LazyLoadImage
                                effect="blur"
                                loading="lazy"
                                alt=""
                                src={`${API}/File/Artikel/${item.photo}`}
                                onError={(e) => {
                                  e.target.src = terms;
                                }}
                                className="width-100 img-center"
                              />
                            </div>
                            <div className="article-1-contect">
                              <div
                                className="flex space-between width-100"
                                style={{ marginBottom: "10px" }}
                              >
                                <p
                                  className="subTitle"
                                  style={{ color: "#AD8C53" }}
                                >
                                  Article
                                </p>
                                <p
                                  style={{ color: "#828282" }}
                                  className="subDate"
                                >
                                  {" "}
                                  {new Date(item.createdAt).getDate()}{" "}
                                  {bulan[new Date(item.createdAt).getMonth()]}{" "}
                                  {new Date(item.createdAt).getFullYear()}
                                </p>
                              </div>
                              <br />
                              {
                                <h3
                                  style={{ margin: "0px 0px 18px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.judul,
                                  }}
                                />
                              }
                              {
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.isi.slice(0, 500) + "...",
                                  }}
                                />
                              }
                              <Link
                                to={`/Article-Detail/${item.id}/${item.judul
                                  .split(" ")
                                  .join("-")}`}
                              >
                                <button>
                                  <a>Read Article</a>
                                </button>
                              </Link>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="article-right">
                    {new_data.map((item, i) => {
                      if (i > 0) {
                        return (
                          <div key={i}>
                            <div className="item-article-right">
                              <LazyLoadImage
                                effect="blur"
                                loading="lazy"
                                alt=""
                                src={`${API}/File/Artikel/${item.photo}`}
                                onError={(e) => {
                                  e.target.src = terms;
                                }}
                              />
                              <div className="text-article width-100">
                                <div>
                                  <div className="flex space-between width-100">
                                    <p
                                      style={{
                                        color: "#AD8C53",
                                        marginBottom: "10px",
                                      }}
                                      className="subTitle"
                                    >
                                      Article
                                    </p>
                                    <p
                                      style={{ color: "#828282" }}
                                      className="subDate"
                                    >
                                      {" "}
                                      {new Date(item.createdAt).getDate()}{" "}
                                      {
                                        bulan[
                                          new Date(item.createdAt).getMonth()
                                        ]
                                      }{" "}
                                      {new Date(item.createdAt).getFullYear()}{" "}
                                    </p>
                                  </div>
                                  {
                                    <h3
                                      style={{ color: "black" }}
                                      dangerouslySetInnerHTML={{
                                        __html: item.judul,
                                      }}
                                    />
                                  }
                                </div>

                                <Link
                                  to={`/Article-Detail/${item.id}/${item.judul
                                    .split(" ")
                                    .join("-")}`}
                                >
                                  <a className="subTitle">Read Article</a>
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    about1: state.allArticle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllArticle: () => {
      dispatch(getAllArticle());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
