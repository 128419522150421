// STAGING
// export const API =   `https://be-kerjakarya.careeya.net`
// export const API_DLP = `https://be-kerjakarya.careeya.net`
// export const url_copy = `https://kerjakarya.careeya.net/Karya_Personality_Profiler`
// export const url = `http://kerjakarya.careeya.net`
// export const user_dummy = 323;

// NEW STAGING
// export const API =   `https://karya-cms-staging.karyatalents.com`
// export const API_DLP = `https://karya-cms-staging.karyatalents.com`
// export const url_copy = ` https://karya-web-staging.karyatalents.com/Karya_Personality_Profiler`
// export const url = ` https://karya-web-staging.karyatalents.com`
// export const user_dummy = 323;


//local
// export const API = `http://127.0.0.1:4300`
// export const API_DLP = `http://127.0.0.1:4300`
// export const user_dummy = 479;



// PRODUCTION-2nd
// export const API =   `https://pentestbe.karyatalents.com`
// export const API_DLP =`https://pentestbe.karyatalents.com`
// export const url_copy = `https://pentestbe.karyatalents.com/Karya_Personality_Profiler`
// export const url = `https://pentestbe.karyatalents.com`
// export const user_dummy = 1084;


// PRODUCTION-1st
export const API =   `https://be.karyatalents.com`
export const API_DLP =`https://be.karyatalents.com`
export const url_copy = `https://be.karyatalents.com/Karya_Personality_Profiler`
export const url = `https://be.karyatalents.com`
export const user_dummy = 1084;

