import React, { Component } from 'react';
import logo from "../images/KaryaTalents_logo.png";
import checklist_gold from "../images/pid_checklist_gold.svg"
import profiler_checklist from "../images/pid_profiler_checklist.svg"
import profiler_work from "../images/pid_profiler_work.svg"
import profiler_initial from "../images/pid_profiler_initial.svg"
import { API } from "../_redux/helper";
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


class componentName extends Component {
    constructor() {
        super();
        this.state = {
            user_id:"",
            rating:"",
            ratting:0,
            comment:"",
            desc_ratting:"",
            rating_vip:"",
            rating_interest:"",
            popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
            popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
            popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
            popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
            popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
        }
    }

    componentDidMount() {
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        this.setState({ user_id: user_id });
        window.scrollTo(0, 0)
        ReactPixel.track(window.location.pathname);
      }

      // POPUP RATING START 
 changeRatting=(e)=>{
    this.setState({ratting:e.target.value})
    let val
    /* eslint eqeqeq: 0 */
    if (e.target.value==1){
      val = "Very Bad"
    }else if (e.target.value==2){
      val = "Bad"
    }else if (e.target.value==3){
      val = "Need Improvement"
    }else if (e.target.value==4){
      val = "Good"
    }else{
      val = "Very Good"
    }
    this.setState({desc_ratting:val})
  }

  // RATING INTEREST PROFILER 
  closePopupInterest() {
    const data={
      ratting_interest: "-",
      comment_interest: ""
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_interest:false})
        sessionStorage.setItem("rating-interest",false)
    }).catch((err)=>{
        console.log(err,"error");
    })
  }

  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }

  submitRatingInterest=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting_interest: this.state.ratting,
      comment_interest: this.state.comment
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_interest:false})
        sessionStorage.setItem("rating-interest",false)
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING VALUES PROFILER 
  closePopupValues() {
    const data={
      ratting: "-",
      comment: ""
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_values:false})
        sessionStorage.setItem("rating-values",false)
    }).catch((err)=>{
        console.log(err,"error");
    })
  }

  submitRatingValues=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.ratting,
      comment: this.state.comment
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_values:false})
        sessionStorage.setItem("rating-values",false)
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING PERSONALITY PROFILER 
  closePopupPersonality() {
    const data={
      ratting: "-",
      comment: ""
  }
      axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_personality:false})
        sessionStorage.setItem("rating-personality",false)
    }).catch((err)=>{
        console.log(err,"error");
    })
  }


  submitRatingPersonality=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.ratting,
      comment: this.state.comment
  }
    axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_personality:false})
        sessionStorage.setItem("rating-personality",false)
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING VIP REPORT 
  closePopupVIP() {
    const data={
      ratting_vip: "-",
      comment_vip: ""
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_vip:false})
        sessionStorage.setItem("rating-vip",false)
      
    }).catch((err)=>{
        console.log(err,"error");
    })
  }


  submitRatingVIP=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting_vip: this.state.ratting,
      comment_vip: this.state.comment
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_vip:false})
        sessionStorage.setItem("rating-vip",false)
      
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING PROGRAM 
  closePopupProgram() {
    const data={
      ratting: "-",
      comment: ""
  }
  let id_program
  if (sessionStorage.getItem("i-program")==""){
    id_program=1
  }else{
    id_program = sessionStorage.getItem("i-program")
  }
    axios.post(`${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, data).then((res)=>{
        this.setState({popup_program:false})
        sessionStorage.setItem("rating-program",false)
    }).catch((err)=>{
        console.log(err,"error");
    })

    }

  submitRatingProgram=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.ratting,
      comment: this.state.comment
  }
  let id_program
  if (sessionStorage.getItem("i-program")==""){
    id_program=1
  }else{
    id_program = sessionStorage.getItem("i-program")
  }
    axios.post(`${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, data).then((res)=>{
        this.setState({popup_program:false})
        sessionStorage.setItem("rating-program",false)
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // POPUP RATING END 

    render() {
        return (
            <div>
                <div className="header_PID width-100">
                    <a href="/NewDashboard" style={{margin:"auto"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} />
                    </a>
                </div>
                <div className="box-wrapper personality_getStarted" >
                    <div className="wrapper">
                        <div className="content">
                            <div className="page-container ">
                                <div className="page-container-outer " style={{marginBottom:"50px"}}>
                                    <div className="box_PID">
                                        <h1 className="title_PID">Values Profiler</h1>
                                        <div className="flex width-100 columnPID_Mobile columnTablet">
                                            <div className="width-50 flex column box_personality_left">
                                                <h3 className="openSans-Bold">About this Profiler</h3>
                                                <div className="text_Personality">
                                                    <div className="line_personality" />
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>This is not a test. There are no right or wrong answers.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>The results will help you to understand what are your values in terms of work, what are your purposes in doing your work. This knowledge will enable you to make wise decisions to fit right in the organization you're about to choose.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>Reflect back on your entire life - respond in the way you would normally choose without outside influences.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>Do not respond based on what you think is most acceptable to others, but answer honestly as you see yourself in the moment.</p>
                                                    </div>
                                                    <div className="flex width-100 List">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={checklist_gold} />
                                                        <p>Focus on your core self and respond based on your instinctive behavior, regardless of whether the answer you choose is morally good or not good.</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="width-50 flex column box_personality_right">
                                                <h3  className="openSans-Bold">Guidelines</h3>
                                                <div className="text_Personality">
                                                    <div className="line_personality" />
                                                    <div className="flex width-100 List_right">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={profiler_checklist} />
                                                        <p>Choose words that best describe what YOU are really like.</p>
                                                    </div>
                                                    <div className="flex width-100 List_right">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={profiler_work} />
                                                        <p>WORK QUICKLY. Avoid agonizing over your choices.</p>
                                                    </div>
                                                    <div className="flex width-100 List_right">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={profiler_initial} />
                                                        <p>Your INITIAL CHOICE or reaction will usually be the best choice.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex width-100 center pid_getStarted" style={{margin: "0px 0px 32px"}}>
                                            <a href="/Values_Question">
                                            <button>
                                                <p style={{padding:"14px 48px"}}>Start</p>
                                            </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default componentName;