import ReactGA from 'react-ga';  

export const GAevent = (categoryName, eventName) => {
    ReactGA.event({       
        category: categoryName,  // Required
        action: eventName,       // Required
        label: 'labelName',       
        value: 10,       
        nonInteraction: false     
    });   
}