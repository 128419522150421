import _DlcAssessmentQuiz from "../components/_DlcAssessmentQuiz";
import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarMiddle from "../components/SidebarMiddle";
import {getProfileId} from "../_actions/user";
import Account from "../components/Account"
import LeftBar from "../components/LeftBar";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";



class DigitalLearningCenterDetailCourse extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: JSON.parse(sessionStorage.getItem("sidebar")),
      var_report: sessionStorage.getItem("report"),
      var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
      visible: true,
      height:"",
    };
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.setState({height:window.innerHeight})
    window.scrollTo(0, 0)
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
  }

  handleCallback = (childData) =>{
    this.setState({link: !childData})
}


  render() {
    const { profile  } = this.props.profile1;
    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }
    const nama = '' + nickname
    const name = nama.split(" ")[0]


    return (
      <div>
        <div className="block HC-pages" style={{top:"0px"}}>
          <div className="NewHeader" style={{position:"unset", top:"0px"}}>
          <Account profile={profile}/>
            <div className="flex">
            <LeftBar parentCallback = {this.handleCallback}/>


            {/* CONTENT */}
            <div className="cubo width-100 HC" style={{marginLeft: this.state.link ? "255px":"80px"}} >
              <div className="block">
                <SidebarMiddle />
              </div>
              <_DlcAssessmentQuiz history={this.props.history} ownProps={this.props.ownProps}/>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps,"own");
  return {
    user_id: state.user_id,
    profile1: state.profileId,
    ownProps: ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DigitalLearningCenterDetailCourse);