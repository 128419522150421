import React, { Component } from 'react'
import karya from '../images/logoBaru.png'
import Question from '../components/Question'
import QuestionMobile from '../components/QuestionMobile'
import ExpandCollapse from 'react-expand-collapse';
import mirror_zoom from '../images/search.png'
import nav_top from '../images/icons/nav-top.svg'
import edu from '../images/icons/edu.svg'
import bag from '../images/icons/job.svg'
import get_started from '../images/icons/get-started.svg'
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


var $ = require("jquery");
export default class GetStarted extends Component {

    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); //for go back (kembali) button
        this.state={
            user_id:"",
            rating:"",
            ratting:0,
            comment:"",
            desc_ratting:"",
            rating_vip:"",
            rating_interest:"",
            popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
            popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
            popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
            popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
            popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
        }
     }
     
     componentDidMount(){
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        this.setState({ user_id: user_id });
        sessionStorage.setItem('setupTime', new Date().getTime())
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.track(window.location.pathname);
        window.scrollTo(0, 0)
     }

     goBack(){
         this.props.history.goBack();
         ReactGA.pageview(window.location.pathname + window.location.search)
     }





    render() {
        $("#boxQuest").click(function () {
            $("#boxQuest-item").hide("slow");
          });


        

        return (
            <div>
                <div className="edu">
               <div className="box-wrapper orange question -n">
                    <div className="wrapper">
                        <div className="content">
                            <div className="page-container width-100">
                                <div className="background-top width-100"></div>
                                <div className="page-container-outer">
                                    <div className="box-logo width-100 dekstop">
                                        {/* <Link to="/"> */}
                                        <div className="box-logo-img">
                                            <LazyLoadImage effect="blur" loading="lazy" src={karya} alt=""  />
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                    <div className="mobile-information width-100">
                                    <div className="box-logo width-100">
                                        <div className="box-logo-img">
                                            <LazyLoadImage effect="blur" loading="lazy"  src={karya} alt=""  />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="page-container-inner width-100">
                                        <div className="box-content">                            
                                        <div className="flex box-edu-container">
                                            <div className="box-one box-edu-description desktop">
                                                      <div className="edu-desc-title width-100">
                                                            <div className="box-img-inner active ">
                                                                <div className="box-img">
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={mirror_zoom} className="search-icon"/>
                                                                </div>
                                                            </div>
                                                            <div className="box-img-inner">
                                                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={edu} className="edu-icon" />
                                                                </div>
                                                            </div>
                                                            <div className="box-img-inner">
                                                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bag} className="job-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="edu-desc-content width-100">
                                                            <div className="box-image">
                                                                <div className="background-img"></div>
                                                                <LazyLoadImage effect="blur" loading="lazy" src={get_started} alt="" />
                                                            </div>
                                                            
                                                            <h5>Work Interest Assessment </h5>
                                                            <p>
                                                            Please select the answer according to your preferences for those specific activities
                                                            </p>
                                                        </div>
                                                        <div className="edu-desc-footer">
                                                            <button type="button" className="btn btn-link" onClick={this.goBack}>Back</button>
                                                            <button type="button" className="btn btn-primary" style={{display:"none"}} disabled={true}>Continue</button>
                                                        </div>
                                                      
                                                        <div className="edu-desc-nav width-100">
                                                            <div className="edu-desc-nav-img">
                                                                <LazyLoadImage effect="blur" loading="lazy" src={nav_top} alt="" id="boxQuest"/>
                                                            </div>
                                                        </div>
                                            </div>
                                            <div className="box-one box-edu-description mobile expandmobile">
                                            <ExpandCollapse
                                                previewHeight="126px"
                                                expandText="wew"
                                                ellipsisText=""
                                                >
                                                      <div className="edu-desc-title width-100">
                                                            <div className="box-img-inner active ">
                                                                <div className="box-img">
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={mirror_zoom} className="search-icon" />
                                                                </div>
                                                            </div>
                                                            <div className="box-img-inner">
                                                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={edu} className="edu-icon"/>
                                                                </div>
                                                            </div>
                                                            <div className="box-img-inner">
                                                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bag} className="job-icon"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="edu-desc-content width-100" id="boxQuest-item">
                                                            <div className="box-image">
                                                                <div className="background-img"></div>
                                                                <LazyLoadImage effect="blur" loading="lazy" src={get_started} alt="" />
                                                            </div>
                                                            
                                                            <h5>Work Interest Assessment</h5>
                                                            <p>
                                                            Please select the answer according to your preferences for those specific activities
                                                            </p>
                                                        </div>
                                                        <div className="edu-desc-footer">
                                                            <button type="button" className="btn btn-link" onClick={this.goBack}>Back</button>
                                                            <button type="button" className="btn btn-primary" style={{display:"none"}} disabled={true}>Continue</button>
                                                        </div>
                                                      
                                                        <div className="edu-desc-nav width-100">
                                                            <div className="edu-desc-nav-img">
                                                                &nbsp;
                                                            </div>
                                                        </div>
                                            </ExpandCollapse>
                                            </div>
                                            <div className="box-two">
                                                <div className="questions-desktop"><Question/></div> 
                                                <div className="questions-mobile"><QuestionMobile/></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        )
    }
}
