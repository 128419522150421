import React, { Component } from 'react';
import logo from "../images/KaryaTalents_logo.png";
import pid_survey from "../images/pid_survey_complete.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


class componentName extends Component {
    constructor() {
        super();
        this.state = {
          user_id: "",
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        ReactPixel.track(window.location.pathname);
      }


    showReport = () => {
       window.location.href="/Personality-Profiler-Stand-Alone"
      };
      
    backToHomepage=()=>{
        window.location.href="/"
    }

    render() {
        return (
            <div>
                <div className="header_PID width-100"  >
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="pointer" onClick={this.backToHomepage} />
                </div>
                <div className="box-wrapper personality_getStarted survey_Complete" >
                    <div className="wrapper">
                        <div className="content">
                            <div className="page-container ">
                                <div className="page-container-outer ">
                                    <div className="box_PID">
                                        <div className="width-100 dekstop">
                                        <div className="title_Complete_survey">
                                            <h1>Congratulations!   You've finished the Karya Personality Profiler</h1>
                                        </div>
                                        </div>
                                        <div className="width-100 mobile">
                                        <div className="title_Complete_survey">
                                            <h1>Congratulations!</h1>
                                            <h1 > You've finished the Karya Personality Profiler</h1>
                                        </div>
                                        </div>
                                        <div className="flex width-100 center complete_survey">
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={pid_survey} />
                                        </div>
                                        <div className="flex width-100 center pid_getStarted" style={{marginLeft:"0px"}}>
                                            {/* <a href="/Report"> */}
                                            <button onClick={this.showReport}>
                                                <p style={{padding:"14px 48px"}}>Show Report</p>
                                            </button>
                                            {/* </a> */}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default componentName;