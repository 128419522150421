import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import icon3 from '../images/iconEmail.gif'
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    window.location.href = "/";
    ReactGA.pageview(window.location.pathname + window.location.search)
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
             <div>
               <div style={{justifyContent:"center", textAlign:"center", marginTop:"20px"}}>
               <div>
                   <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon3}  style={{ height:"120px", width:"auto"}} />
               </div>
               <h1>Success!</h1>
               <p style={{margin:"5px 0px 10px"}} >Please check your email for
verification</p>
              <button className="btn-ok-arg"  onClick={handleClose} >OK</button>
              </div>
           </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}