import React, { Component } from 'react'
import arrowRight from "../images/arrowNext.svg";
import arrowLeft from "../images/arrowLeft.svg";
import how1 from '../images/step1.png'
import how2 from '../images/step2.png'
import how3 from '../images/step3.png'
import how4 from '../images/step4.png'
import how5 from '../images/step5.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
var $ = require('jquery');


class _Howtodo extends Component {
    constructor() {
        super();
        this.state = {
            cek:"",
        }
    }

    componentDidMount(){
        sessionStorage.setItem('setupTime', new Date().getTime())
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0)
    }


    render() {
         
        let nextStep
        const settings = {
            autoplay: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            // arrows: true, 
            nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} />,
            prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
            dots:true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} />,
                    prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
                    beforeChange: (current, next, dots) =>{},
                    afterChange: (current) => {
                        if(current === 2) {
                            nextStep = <Link to="GetStarted"><button type="button" className="btn btn-primary"> {nextStep}I Understand</button></Link>
                            this.setState({cek:nextStep})
                            
                        }
                    }
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} />,
                    prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
                    beforeChange: (current, next, dots) =>{},
                    afterChange: (current) => {
                        if(current === 3) {
                            nextStep = <Link to="GetStarted"><button type="button" className="btn btn-primary"> {nextStep}I Understand</button></Link>
                            this.setState({cek:nextStep})
                            
                        }
                    }
                  }
                },
                {
                    breakpoint:600,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} />,
                      prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
                      beforeChange: (current, next, dots) =>{},
                      afterChange: (current) => {
                          if(current === 4) {
                              nextStep = <Link to="GetStarted"><button type="button" className="btn btn-primary"> {nextStep}I Understand</button></Link>
                              this.setState({cek:nextStep})
                              
                          }
                      }
                    }
                  },

              ],

          };

          const list = [how1,how2,how3,how4,how5]

        return (
            <div>
                 <div className="box-wrapper  howtodo" >
                    <div className="wrapper  " >
                        <div className="page-container how-to-do width-100"  id="space0" style={{marginTop:"80px"}}>
                            <div className="page-container-outer hd-2" id="container-print" >
                                <div className="box-logo width-100 logo-btn-justify" id="no-print">
                                    <h1 className="titleSpartan" id="no-print" >Interest Profile</h1>
                                </div>
                                <div className="page-container-inner width-100" id="myAs-print" style={{top:"0px", marginBottom:"22px", border:this.state.showStore?"2px solid #e0e0e0":""}}>
                                <div className="box-edu-container width-100">
                                        <div className="box-to-do-lable to-do-header width-100">
                                            <h2 style={{fontFamily:"League Spartan"}}> Welcome to Karya Talents ! Here are the steps that you need to take</h2>
                                        </div>
                                        
                                        {/* for desktop */}
                                        <div className="dekstop width-100">
                                        <div className="box-to-do-step width-100">
                                            <div className="box-to-do-step-inner width-100">
                                                {list.map((item)=>(
                                                    <div className="box-step">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" className="box-step-inner width-100" src={item}  />
                                                </div>
                                                ))}

                                            </div>
                                        </div>
                                        </div>
                                        {/* end for desktop */}

                                        {/* for mobile */}
                                        <div className="how-to-do-mobile width-100">
                                        <Slider ref={c => (this.slider = c)} {...settings}>
                                            {list.map((item)=>(
                                                    <div className="box-step">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" className="box-step-inner width-100" src={item}  />
                                                </div>
                                                ))}
                                            
                                        </Slider>
                                        </div>
                                        {/* end for mobile */}
                                        
                                        <div className="box-to-do-lable2 to-do-footer width-100 todoMobile" >
                                        {this.state.cek}
                                        </div>
                                        <div className="box-to-do-lable to-do-footer width-100 todoDekstop" >
                                            <Link to="GetStarted"><button type="button" className="btn btn-primary todoDekstop" onClick={ReactGA.pageview(window.location.pathname + window.location.search)}> {nextStep}I Understand</button></Link>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default _Howtodo;