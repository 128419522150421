import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import store from "./_redux/store";
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { hydrate, render } from "react-dom";
// import auth from './auth.ts'; // Sample authentication provider

const trackingId = "UA-170839109-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId
  //untuk debugging di localhost
  , {
  'cookieDomain': 'auto',
  'debug': true,
  // titleCase: false,
  // gaOptions: {
  //   userId: 123
  // }
}
  //end debugging di localhost
);

// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })


// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById("root")
// );
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}><App /> </Provider>, rootElement);
} else {
  render(<Provider store={store}><App /></Provider>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
