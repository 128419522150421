import React, { Component } from 'react';
import { lighten, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const Borderpagination = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#F2F2F2', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#3B6C98',
  },
})(LinearProgress);

// Inspired by the Facebook spinners.


export default class CustomizedProgressBars extends Component {


  render(){
  return (
    <div className="try">
     
      <Borderpagination
        variant="determinate"
        color="secondary"
        value={this.props.value}
      />
      
    </div>
  );
  }
}
