import React, { Component } from "react";
import { connect } from "react-redux";
import { putProfileId} from "../_actions/user";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import ReactGA from "react-ga";
import { API } from "../_redux/helper";
import axios from 'axios';
import $ from "jquery";
import DlcQuizCourse from "./DlcQuizCourse"


class _DlcQuizCourse extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      showStore: false,
      sizeStore: false,
      next_job:"",
      visible: true,
      tooltip:"",
    };
  }



  setMyCourse = () =>{
    localStorage.setItem("getMyCOurse", "My Courses");
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    var tooltip_val = sessionStorage.getItem("tooltip");
    this.setState({ tooltip: tooltip_val });
    ReactGA.pageview(window.location.pathname + window.location.search);
    await axios.get(`${API}/api/survey/findsubmission/${user_id}`)
      .then(res => {
        if (res.data==="Data Not Found"){
          sessionStorage.setItem("report", res.data.answers);
        }else{
          sessionStorage.setItem("report", res.data.answers.length);
        }
         
      })
  }

 


  render() {
    const {ownProps} = this.props.ownProps
    // console.log(ownProps,"ini ownprops _DlcQuizCourse");

    $('.slick-list').css('width','auto');


    return (
      <div>
        <div className="box-wrapper new_Dashboard">
          {/* DEKSTOP */}
          <div className="wrapper dekstop">
            <div className="myAs new_Home" id="space0">
              <div className="content" id="download">
                <div className="page-container scrollContent width-100" id="space0">
                  <div className="page-container-outer" id="container-print" style={{position:"absolute", marginTop:"79px", width:"90%"}}>
                  <Link to="/DigitalLearningPlatform" onClick={this.setMyCourse()}>
                    <div className="box-logo width-100 logo-btn-justify btn-my-course">
                        <p>❮ My Courses</p>
                    </div>
                  </Link>
                    <div
                      className="box-training-recomended-container width-100"
                      style={{ paddingTop: "0px", marginBottom: "50px" }}
                    >
                      <div className="box-dlc-content training-recomended-container-inner width-100">
                        <div className="tabs-info-container">
                          <div className="tabs-info">
                            <NavLink exact to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseInfo"}>
                              <p>Course Info</p>
                            </NavLink>
                            <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"}>
                              <p>Course Outline</p>
                            </NavLink>
                            <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/Quiz"}>
                              <p>Quiz</p>
                            </NavLink>
                          </div>
                        </div>
                        <p className="border-bottom-tabs"></p>
                          <DlcQuizCourse ownProps={ownProps}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END DEKSTOP */}
          <div className="wrapper mobile">
            <div className="myAs new_Home" id="space0">
              <div className="content" id="download">
                <div className="box-dlc-content box-menu-mobile-dlp width-100">
                  <div className="tabs-info-container">
                    <div className="tabs-info">
                      <NavLink exact to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseInfo"}>
                        <p>Course Info</p>
                      </NavLink>
                      <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"}>
                        <p>Course Outline</p>
                      </NavLink>
                      <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/Quiz"}>
                        <p>Quiz</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="page-container scrollContent width-100" id="space0">
                  <div className="page-container-outer" id="container-print" style={{position:"absolute", marginTop:"133px", width:"1190px !important"}}>
                  <Link to="/DigitalLearningPlatform">
                    <div className="box-logo width-100 logo-btn-justify btn-my-course-mobile">
                        <p>❮ My Courses</p>
                    </div>
                  </Link>
                    <div
                      className="box-training-recomended-container width-100"
                      style={{ paddingTop: "0px", marginBottom: "50px" }}
                    >
                      <div className="box-dlc-content training-recomended-container-inner width-100">
                      
                          <DlcQuizCourse ownProps={ownProps}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    ownProps:ownProps
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    putProfile11: (user_id) =>{
        dispatch(putProfileId(user_id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(_DlcQuizCourse);
