import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import arrowRight from "../images/arrowNext.svg";
import arrowLeft from "../images/arrowLeft.svg";
import arrowNextGray from "../images/ArrowNextGray.svg";
import left_disabled from "../images/left_disabled.svg"
import iVIP from "../images/iVIP.svg";
import iLearning from "../images/iLearning.png";
import {getJobOpportunity, getTraining} from "../_actions/jobs"
import iconJob2 from "../images/free-tech1.jpg";
import Rating from "@material-ui/lab/Rating";
import Slider from "react-slick";
import soon from "../images/soon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getProfileId,putProfileId} from "../_actions/user";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import human from "../images/person.svg"
import tooltip_career from "../images/tooltip_career.svg"
import tooltip_personality from "../images/tooltip_personality.svg"
import tooltip_job from "../images/tooltip_job.svg"
import tooltip_referral from "../images/tooltip_referral.svg"
import tooltip_interest from "../images/tooltip_interest.svg"
import Fade from 'react-reveal/Fade';
import ReactGA from "react-ga";
import { API } from "../_redux/helper";
import axios from 'axios';
import place from "../images/loc.svg";
import blue_place from "../images/blue_place.svg";
import func from  "../images/y-function.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import $ from "jquery";

class AssesmentReport extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      showStore: false,
      sizeStore: false,
      next_job:"",
      visible: true,
      tooltip:"",
    };
  }

 
  async componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    var tooltip_val = sessionStorage.getItem("tooltip");
    this.setState({ tooltip: tooltip_val });
    this.props.getJobOpportunity(user_id);
    this.props.getTraining(user_id);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  openModal(e) {
    if (e == "link") {
      this.setState({
        link: true,
      });
    }
  }

  closeModal() {
    this.setState({
      link: false,
    });
  }



  tooltip1 = () => {
    this.setState({ tooltip: 2 });
  };
  tooltip2 = () => {
    this.setState({ tooltip: 3 });
  };
  tooltip3 = () => {
    this.setState({ tooltip: 4 });
  };
  tooltip4 = () => {
    this.setState({ tooltip: 5 });
  };
  tooltip5 = () => {
    this.setState({ tooltip: 6 });
  };
  tooltip6 = () => {
    const data_tooltip = {
      user_id : this.state.user_id,
      is_tooltip : 1,
    }
    this.props.putProfile11(data_tooltip);
    sessionStorage.removeItem("tooltip");
    this.setState({ tooltip: "" });
  };
  tooltip7 = () => {
    this.setState({ tooltip: 8 });
  };
  tooltip8 = (e) => {
    const data_tooltip = {
      user_id : this.state.user_id,
      is_tooltip : 1,
    }
    this.props.putProfile11(data_tooltip);
    sessionStorage.removeItem("tooltip");
    this.setState({ tooltip: "" });
  };


  skip = () => {
    const data_tooltip = {
      user_id : this.state.user_id,
      is_tooltip : 1,
    }
    this.props.putProfile11(data_tooltip);
    sessionStorage.removeItem("tooltip");
    this.setState({ tooltip: "" });
  };

  render() {
    const { dataTraining } = this.props.training1;
    const { dataOpportunity, isLoading, error } = this.props.opp1;
    const priceSplitter = (number) =>
      number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    $('.slick-list').css('width','auto');


    let notifComplete
    if (this.props.ownProps.is_profilecomplete==false || this.props.ownProps.is_profilecomplete==null || this.props.ownProps.is_profilecomplete==""){
      notifComplete = <div className="page-container-inner  width-100" id="myAs-print">
      <div className="box-edu-container width-100" id="width-104">
        <div className="box-edu-content " style={{ width: "100%" }}>
          <div className="box-assessment-title width-100">
            <h4 className=" width-100 notif-Dashboard">
              Complete Your Profile so that we can match you
              with the Right Fit Job when the opportunity is
              available!
            </h4>
          </div>

          <div
            className="box-assessment-description width-100"
            id="box-assessment-print2"
          >
            <div className="circle_box flex">
              <div>
                <Link to="/MyProfile">
                <button style={{fontWeight:"600"}}>Complete Profile
                  
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }else{
      notifComplete=""
    }

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    let nextStep2 
    const settings = {

        beforeChange: (current, next, dots) =>
        this.setState({next_job:next}),
        afterChange: (current) => {
        if(current === 4) {
            nextStep = <Link to="GetStarted"><button type="button" className="btn btn-primary"> {nextStep}I Understand</button></Link>
            this.setState({cek:nextStep})
            
        }
      },

      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,

      nextArrow: this.state.next_job < dataOpportunity.length - 4 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
      prevArrow: this.state.next_job >0 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} /> :<LazyLoadImage effect="blur" loading="lazy" alt="" src={left_disabled} />,
      responsive: [
        {
          breakpoint: 1791,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: this.state.next_job < dataOpportunity.length - 3 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
          },
        },
        {
          breakpoint: 1351,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: this.state.next_job < dataOpportunity.length - 2 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: this.state.next_job < dataOpportunity.length - 3 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: this.state.next_job < dataOpportunity.length - 3 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
          },
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: this.state.next_job < dataOpportunity.length - 2 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: this.state.next_job < dataOpportunity.length - 1 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
            dots: false,
          },
        },
        {
          breakpoint: 706,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: this.state.next_job < dataOpportunity.length - 1 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
            dots: false,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: this.state.next_job < dataOpportunity.length - 1 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: this.state.next_job < dataOpportunity.length - 1 ?  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} /> : <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowNextGray} />,
            dots:false,
          },
        },
      ],
    };

    //SLIDER UNTUK TRAINING
    let nextStep 
    const settingTraining = {

      beforeChange: (current, next, dots) => {},
      afterChange: (current) => {
      if(current === 4) {
          nextStep = <Link to="GetStarted"><button type="button" className="btn btn-primary"> {nextStep}I Understand</button></Link>
          this.setState({cek:nextStep})
          
      }
    },
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
      prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
      responsive: [
        {
          breakpoint:1791,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1351,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots:false,
          }
        },
      ],

    };


    return (
      <div>
        <div className="box-wrapper new_Dashboard">
          <div className="wrapper">
            {/* <NewHeader /> */}
            <div className="myAs  new_Home ds" id="space0">
              <div className="content" id="download">
                <div className="page-container scrollContent width-100" id="space0">
                  <div className="page-container-outer -i" id="container-print" style={{position:"absolute", marginTop:"79px"}}>
                    <div
                      className="box-logo width-100 logo-btn-justify"
                      id="no-print"
                    >
                      {/* <a href="/Report"> */}
                      <h1 className="titleSpartan" id="no-print" style={{textTransform:"capitalize"}}>
                        Hi, {this.props.ownProps.name}
                      </h1>
                      {/* </a> */}
                    </div>
                    {notifComplete}
                   
                    <div
                      className="page-container-inner width-100"
                      id="myAs-print"
                      style={{ display: "none" }}
                    >
                      <div className="box-edu-container width-100" id="width-104" >
                        <div className="box-edu-content " style={{ width: "100%" }}>
                          <div className="box-assessment-title width-100">
                            <h4 className=" width-100">
                              Let�s Join Our Talent Network to Unlock Special
                              Privileges
                            </h4>
                          </div>

                          <div
                            className="box-assessment-description width-100"
                            id="box-assessment-print2"
                          >
                            <div className="circle_box flex">
                              <div className="flex column box_item">
                                <div className="circle"></div>
                                <p>Complete Your Profile</p>
                              </div>
                              <div className="flex column box_item">
                                <div className="circle"></div>
                                <p>Take Interest Profiler</p>
                              </div>
                              <div className="flex column box_item">
                                <div className="circle"></div>
                                <p>Take Personality Profiler</p>
                              </div>
                              <div className="flex column box_item">
                                <div className="circle"></div>
                                <p>Take Career Center Profiler</p>
                              </div>
                              <div className="flex column box_item">
                                <div className="circle"></div>
                                <p>Join Our Talent Network</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="page-container-inner width-100 second_box -i"
                      id="myAs-print"
                    >
                      <div className="box-edu-container width-100" id="width-104">
                        <div className="box-edu-content " style={{ width: "100%" }}>
                          <div className="box-assessment-title width-100 ">
                            <h4
                              className=" width-100 re"
                              style={{ textAlign: "left"}}
                            >
                             Recommended Job Opportunities for You
                            </h4>
                          </div>
                          <div
                            className="box-assessment-description box2 width-100"
                            id="box-assessment-print2"
                          >
                            <div className="slide_box flex-test">
                              <Slider {...settings} className="slide_box">
                                {dataOpportunity.map((item,i)=>(
                                <div key={i} className="box">
                                  <div className="width-100 height_text">
                                  <div className="flex space-between location" style={{height:"unset", marginBottom:"10px"}}>
                                    <p className="time">{item.company}</p>
                                   
                                  </div>
                                  <div className="job_name">
                                    <p style={{color:"#3B6C98"}}>{item.job_title} </p>
                                  </div>
                                  <div className="width-100 flex column">
                                    {item.job_level &&
                                  <div className="flex width-100">
                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={func} style={{objectFit:"contain", margin:"5px 10px 0px 0px"}} />
                                      <p className="company" style={{color:"#4F4F4F"}}>{item.job_level}</p>
                                  </div>}
                                  {item.city &&
                                  <div className="flex place width-100" style={{marginTop:"10px"}}>
                                      <LazyLoadImage effect="blur"
                                        src={place}
                                        style={{
                                          height: "12.5px",
                                          width: "10px",
                                          marginTop: "0px",
                                          marginRight:"10px",
                                          paddingLeft:"2px",
                                        }}
                                      />
                                      <p>{item.city}</p>
                                    </div>}
                                    </div>
                                    </div>
                                  <div className="referral flex flex-end width-100" style={{marginTop:"20px"}}>
                                    <a href="/Referral">
                                  {/* <Link to="/Referral" > */}
                                    <p>Refer </p>
                                    {/* </Link> */}
                                    </a>
                                    <a href={`/JobDetail/${item.job_opportunity_id}/${item.job_title}`}>
                                    <button>
                                      <p className="apply">View Job</p>
                                    </button>
                                    </a>
                                  </div>
                                </div>
                                ))}

                              </Slider>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    
                    <div
                      className="box-training-recomended-container width-100 noNe"
                      style={{ paddingTop: "0px", marginBottom: "50px" }}
                    >
                      <div className="training-recomended-container-inner width-100">
                        <h3>Curated Trainings just for You</h3>
                        <div className="box-training-recomended width-100">
                          <div
                            className="boxComing"
                          >
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={soon} />
                            {/* <h5 className="titleComing">Coming Soon</h5> */}
                          </div>
                          <div
                            className="training-recomended-inner width-100"
                            style={{ display: "none" }}
                          >
                            <div className="training-recomended-slide width-100">
                              <div>
                                <Slider
                                  {...settingTraining}
                                  className="slider traning-slide"
                                >
                                  {/* <div className="slider traning-slide"> */}
                                  
                                  {dataTraining.map((item, i) => {
                                    if (i <= 3){
                                    return (
                                      <div
                                        key={i}
                                      >
                                        <div className="training-recomended-list">
                                          <div className="training-recomended-list-inner">
                                            <div className="recomended-list-img width-100">
                                              <LazyLoadImage effect="blur"
                                                src={iconJob2}
                                                className=" img-center width-100"
                                                alt=""
                                              />
                                            </div>
                                            <div className="recomended-list-description width-100">
                                              <div className="recomended-list-description-inner width-100">
                                                <h4>{item.title}</h4>
                                                <div className="recomended-list-location width-100">
                                                  <div className="list-company-name">
                                                    <p>CodeUnlimited</p>
                                                  </div>
                                                  <div className="list-company-location flex">
                                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={blue_place} style={{margin:"auto", padding:"0px 4px 3px 0px"}}/>
                                                    <p>{item.city_name} </p>
                                                  </div>
                                                </div>
                                                <h4 style={{fontFamily:"Montserrat-Bold"}}>
                                                    Rp{" "}
                                                    {priceSplitter(
                                                      item.price -
                                                        item.price * 0.1
                                                    )}{" "}
                                                  </h4>
                                                <div className="recomended-list-rating width-100" >
                                                  <Rating
                                                    value={parseInt(item.star)}
                                                    readOnly
                                                  />
                                                  <h6>{item.star}/5</h6>
                                                  <p>(789 Ratings)</p>
                                                </div>
                                                <div className="recomended-list-career-point width-100" style={{display:"none"}}>
                                                  <div className="list-career-point-label">
                                                    <h6>
                                                      Hemat{" "}
                                                      {priceSplitter(
                                                        item.discount * 100
                                                      )}
                                                      %
                                                    </h6>
                                                  </div>
                                                  <p>Dapat 50 Career Points</p>
                                                </div>
                                                <div className="list-career-price width-100">
                                                  <h6 style={{display:"none"}}>
                                                    Rp{" "}
                                                    {priceSplitter(
                                                      item.price * 1
                                                    )}
                                                  </h6>
                                                  <h4 style={{display:"none"}}>
                                                    Rp{" "}
                                                    {priceSplitter(
                                                      item.price -
                                                        item.price * 0.1
                                                    )}{" "}
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                  >
                                                    Register
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  })}
                                
                                </Slider>
                              </div>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.tooltip &&
                      <div className="tooltip_mobile"
                        id="forgotPassword"
                        visible={this.state.visible}
                        effect="fadeInUp" >
                        <h1 className="title_tooltip_mobile ">What you can do</h1>
                            <div className="tooltip_dashboard" >
                            <Fade cascade>
                            <div className="tooltip_dashboard "  style={{display: this.state.tooltip === 1 || this.state.tooltip==="1"? "block":"none"}}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={human} />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>My Profile</h1>
                                        <p>In your profile, you can put many information about you such as Education, Experience, Compensation, etc. We encourage you to complete your profile so that we can inform you when there's suitable job opportunity for you.</p>
                                       
                                    </div>
                                    <div className="button_tooltip">
                                    <button className="btn_skip" onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip1}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            <Fade cascade>
                            <div className="tooltip_dashboard " style={{display: this.state.tooltip === 2 || this.state.tooltip==="2"? "block":"none",
                                }}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_job} />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>Job Opportunities</h1>
                                        <p>Explore all the available job opportunities that might be suitable for you to apply. You  would also be able to refer the jobs to your friends and share the jobs to your social media</p>
                                    </div>
                                    <div className="button_tooltip">
                                    <button className="btn_skip"  onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip2}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            <Fade cascade>
                            <div className="tooltip_dashboard " style={{display: this.state.tooltip === 3 || this.state.tooltip==="3"? "block":"none"}}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_referral} />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>Referral</h1>
                                        <p>Help your friends to get their dream job by referring them to the available job opportunities. By being our referrer, you will be able to help your friends and get the benefit as well!</p>
                                    </div>
                                    <div className="button_tooltip">
                                    <button className="btn_skip"  onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip3}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            <Fade cascade>
                            <div className="tooltip_dashboard " style={{display: this.state.tooltip === 7 || this.state.tooltip==="7"? "block":"none"}}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_interest} />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>Interest Profiler</h1>
                                        <p>Interest Profiler will guide you and provide essential information about the suitable jobs for you based on your natural interest. Here, we will give some job recommendations based on the result of the assessment you will take </p>
                                    </div>
                                    <div className="button_tooltip">
                                    <button className="btn_skip"  onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip4}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            <Fade cascade>
                            <div className="tooltip_dashboard " style={{display: this.state.tooltip === 6 || this.state.tooltip==="6"? "block":"none"}}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_career} />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>Career Center</h1>
                                        <p>Here you can view detailed information about each jobs such as key responsibilities of the job, knowledge, skill and abilities required for the job as well as recommended training for the respective jobs</p>
                                    </div>
                                    <div className="button_tooltip"  >
                                    <button className="btn_skip" onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip6}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            <Fade cascade>
                            <div className="tooltip_dashboard " style={{display: this.state.tooltip === 8 || this.state.tooltip==="8"? "block":"none"}}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_personality} />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>Personality Profiler</h1>
                                        <p>With personality assessment, you are able to know yourself more in terms of how you work best according to your unique self. Based on this, you will figure out which type of person are you if you are in an organization</p>
                                    </div>
                                    <div className="button_tooltip" >
                                    <button className="btn_skip"  onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip5}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            <Fade cascade>
                            <div className="tooltip_dashboard " style={{display: this.state.tooltip === 5 || this.state.tooltip==="5"? "block":"none"}}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="height-100" />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>Digital Learning Platform</h1>
                                        <p>Here, you can learn so many things about how to improve yourself in soft skills, Such as Communication Skills, Leadership Skills, Social Skills, Productivity, Creative, and any other 21st Century Skills that are needed in this era.</p>
                                    </div>
                                    <div className="button_tooltip" >
                                    <button className="btn_skip"  onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip5}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            <Fade cascade>
                            <div className="tooltip_dashboard " style={{display: this.state.tooltip === 4 || this.state.tooltip==="4"? "block":"none"}}>
                                <div className="box_tooltip_dashboard">
                                <div className="bg_colors_tooltip">
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="height-100" />
                                </div>
                                <div className="content_tooltip">
                                    <div className="text_tooltip">
                                        <h1>Assessment</h1>
                                        <p>With our Assessments, you would be able to learn depper about yourself so that you can increase your Self-Awareness to adapt well in your workplace and your social life.</p>
                                    </div>
                                    <div className="button_tooltip" >
                                    <button className="btn_skip"  onClick={this.skip}>
                                        <p>Skip</p>
                                    </button>
                                    <button className="btn_next" onClick={this.tooltip4}>
                                        <p>Next</p>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Fade>
                            </div>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="no-print">
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    training1: state.training,
    opp1: state.JobOpportunity,
    data_tooltip: state.putProfileIdRed,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobOpportunity: (user_id) => {
      dispatch(getJobOpportunity(user_id));
    },
    getTraining: (user_id) => {
      dispatch(getTraining(user_id));
    },
    putProfile11: (user_id) =>{
      dispatch(putProfileId(user_id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssesmentReport);
