import React from 'react';

export default function(ComposedClass) {
    return class AuthLayout extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          warningTime: 1000 * 60 * 25,
          signoutTime: 1000 * 60 * 30,
        };
      }
  
      componentDidMount() {
        this.events = [
          'load',
          'mousemove',
          'mousedown',
          'click',
          'scroll',
          'keypress'
        ];
  
        for (var i in this.events) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }
  
        this.setTimeout();
      }
  
      clearTimeoutFunc = () => {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
      };
  
      setTimeout = () => {
        this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
        this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
      };
  
      resetTimeout = () => {
        this.clearTimeoutFunc();
        this.setTimeout();
        sessionStorage.setItem("setupTime", new Date().getTime());
        localStorage.setItem("setupTime", new Date().getTime());
      };
  
      warn = () => {
        console.log('You will be logged out automatically in 5 minute.');
      };
  
      logout = () => {
        this.destroy();
      };
  
      destroy = () => {
       //clear the session
        
        localStorage.clear()
        sessionStorage.clear()
        window.location.href = '/';
      };
  
      render() {
  
        return (
          <div>
            <ComposedClass {...this.props} />
          </div>
        );
      }
    }
  }
