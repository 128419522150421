import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import icon3 from "../images/00.PNG";
import ReactGA from 'react-ga';
import closemodal from "../images/closemodal.png";
import apply_success from "../images/apply_success.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getProfileId} from "../_actions/user";
import Modal from "react-awesome-modal"
import { param } from "jquery";

class Autologin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "true",
      setOpen: "true",
      user_id:"",
      success:true
    };
  }

  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
    var jwt = require("jsonwebtoken");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get('token');
    var decode1 = jwt.decode(foo);
    var user_id = decode1["user_id"];
    this.setState({user_id:user_id})
    this.props.getProfileId(user_id);
  }

  autologin = (e) => {
    e.preventDefault();
    const { profile } = this.props.profile1;
    var now = new Date().getTime();
    var jwt = require("jsonwebtoken");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get('token');
    var decode1 = jwt.decode(foo);
    var email = decode1["email"];
    sessionStorage.setItem("email", email);
    localStorage.setItem("email", email);
    sessionStorage.setItem('setupTime', now)
    sessionStorage.setItem('token',foo)
    sessionStorage.setItem('tooltip', 1)
    localStorage.setItem("token", foo);
  
    if (profile.is_new_journey===true){
      window.location.href = `/JobDetail/${params.get("job_id")}/${params.get("job_title")}`
      sessionStorage.setItem("sidebar",true)
      sessionStorage.setItem("on_board",true)
    }else{
    window.location.href = "/Welcome-to-KaryaTalents";
    sessionStorage.setItem("sidebar",false)
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
  };

  handleClose = (open) => {
    this.setState({ open: false });
  };

  render() {

    const search = window.location.search;
    const { profile } = this.props.profile1;



    return (
      <div>
        <Modal id="success" visible={this.state.open} effect="fadeInUp">
          <div className="flex column">
        <div id="modal-header" className="modal-header-sign">
            <a href="javascript:void(0);" onClick={(e) => this.autologin(e)}>
              <img effect="blur" loading="lazy" alt="" src={closemodal} />
            </a>
          </div>
                <div  className="modal_apply ">
                    <div className="width-100 flex center">
                    {/* <img loading="lazy" alt="" src={apply_success} style={{marginTop:"24px", marginBottom:"24px"}}/> */}
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop:"24px", marginBottom:"24px"}}>
                    <circle cx="32" cy="32" r="32" fill="#25c83b"/>
                    <path d="M20 32.4239L27.0311 39L42 25" stroke="white" stroke-width="3"/>
                    </svg>

                    </div>
                    <h5>Success!</h5>
                    {profile.is_new_journey!==true &&
                    <p  className="auto">Your email has been successfully verified</p>}
                    {profile.is_new_journey===true &&
                    <p  className="auto width-90">Your email is verified and the job is applied</p>}
                </div>
                </div>
            </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile1: state.profileId,
    user_id:state.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)((Autologin));
