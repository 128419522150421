import React, { Component } from 'react';
import prev from "../images/lp-icon-prev.svg"
import next from "../images/lp-icon-next.svg"
import prev_m from "../images/ar_left_mobile.svg"
import next_m from "../images/ar_right_mobile.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {  API} from "../_redux/helper";
import { connect } from "react-redux";
import {getAllProgramDLP} from "../_actions/jobs"
import { LazyLoadImage } from 'react-lazy-load-image-component';


class D_Home extends Component {

    componentDidMount() {
        this.props.getAllProgramDLP()
      } 

      learnMore=(id, name, vd)=>{ 
        sessionStorage.setItem("pr-1", id)
        sessionStorage.setItem("pic", name)
        sessionStorage.setItem("vd", vd)
        window.location.href=`/ProgramDetail/${id}`
    }

    render() {
        const {dataAllProgramDLP} = this.props.dataAllProgramDLP1;
        const new_date = Number(new Date())

    
        const settings = {
            infinite: false,
            slidesToShow: 2,
            arrow: true,
            slidesToScroll: 1,
            prevArrow:<LazyLoadImage effect="blur" loading="lazy" alt="" src={prev}  />,
            nextArrow:<LazyLoadImage effect="blur" loading="lazy" alt="" src={next} />,
            responsive: [
              {
                breakpoint: 1440,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                  prevArrow:<LazyLoadImage effect="blur" loading="lazy" alt="" src={prev_m}  />,
                  nextArrow:<LazyLoadImage effect="blur" loading="lazy" alt="" src={next_m} />,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                  prevArrow:<LazyLoadImage effect="blur" loading="lazy" alt="" src={prev_m}  />,
                  nextArrow:<LazyLoadImage effect="blur" loading="lazy" alt="" src={next_m} />,
                },
              },
            ],
          };


         


        return (
            <div className="width-100 flex D_Home">
                <div className="width-100 flex column">
                    <div className="flex column pad" >
                        <h1 className="width-100 flex title" style={{marginBottom:"0px"}}>Our Specialized Programs</h1>
                    </div>
                    <div className="width-100 dekstop">
                    <div className="flex width-100 column desc-col">
                        {/* <div className="width-100 flex space-between"> */}
                        <Slider {...settings} className="width-100 flex center">
                        {dataAllProgramDLP.map((item,i)=>{
                          /* eslint eqeqeq: 0 */
                            if ((i==0) && (Number(new Date(item.end_date)) > Number(new Date())) ){
                            return(
                            <div key={i} className="flex  space-between D-item" >
                                <div className="flex column" style={{width:"60%"}}>
                                    <div className="flex height-100" style={{padding:"14px"}}>
                                        <div className="height-100 flex column space-between width-100 D-Slide">
                                          <div className="flex width-100 column">
                                              <h3>{item.program_name}</h3>
                                              <p className="dsc">{item.program_desc.slice(0,160)+"..."}</p>
                                           </div> 
                                            <button onClick={()=>{this.learnMore(item.program_id, item.photo , item.video_free)}}>
                                                <p>Learn More</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/DLP/Program/${item.photo}`}  style={{width:"40%", height:"298px"}} />
                            </div>
                            )}})}
                            {dataAllProgramDLP.map((item,i)=>{
                            if ((i> 0) && (Number(new Date(item.end_date)) > new_date)){
                            return(
                            <div key={i} className="flex  space-between D-item" >
                                <div className="flex column" style={{width:"60%"}}>
                                    <div className="flex height-100" style={{padding:"14px"}}>
                                        <div className="height-100 flex column space-between width-100 D-Slide">
                                          <div className="flex width-100 column">
                                              <h3>{item.program_name}</h3>
                                              <p className="dsc">{item.program_desc.slice(0,160)+"..."}</p>
                                           </div> 
                                            <button onClick={()=>{this.learnMore(item.program_id, item.photo, item.video_free)}}>
                                                <p>Learn More</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/DLP/Program/${item.photo}`}  style={{width:"40%", height:"298px"}} />
                            </div>
                            )}})}
   
                            </Slider>
                        {/* </div> */}
                    </div>
                    </div>
                    <div className="width-100 mobile">
                    <div className="flex width-100 column">
                        {/* <div className="width-100 flex space-between"> */}
                        <Slider {...settings} className="width-100 flex center">
                        {dataAllProgramDLP.map((item,i)=>{
                            if((i==0) && (Number(new Date(item.end_date)) > Number(new Date()) )){
                            return(
                            <div key={i} className="flex  space-between D-item" >
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/DLP/Program/${item.photo}`} className="img-slide" style={{width:"100%"}} />
                                <div className="flex column width-100" >
                                    <div className="flex height-100" style={{padding:"14px"}}>
                                        <div className="height-100 flex column space-between width-100 D-Slide">
                                          <div className="flex width-100 column">
                                              <h3>{item.program_name}</h3>
                                              <p className="dsc">{item.program_desc.slice(0,100)+"..."}</p>
                                           </div> 
                                            <button onClick={()=>{this.learnMore(item.program_id,  item.photo , item.video_free)}}>
                                                <p>Learn More</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            )}})}
                             {dataAllProgramDLP.map((item,i)=>{
                            if((i> 0) && (Number(new Date(item.end_date)) > new_date)){
                            return(
                            <div key={i} className="flex  space-between D-item" >
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/DLP/Program/${item.photo}`} className="img-slide" style={{width:"100%"}} />
                                <div className="flex column width-100" >
                                    <div className="flex height-100" style={{padding:"14px"}}>
                                        <div className="height-100 flex column space-between width-100 D-Slide">
                                          <div className="flex width-100 column">
                                              <h3>{item.program_name}</h3>
                                              <p className="dsc">{item.program_desc.slice(0,100)+"..."}</p>
                                           </div> 
                                            <button onClick={()=>{this.learnMore(item.program_id,  item.photo, item.video_free)}}>
                                                <p>Learn More</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            )}})}
                            </Slider>
                        {/* </div> */}
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dataAllProgramDLP1 : state.allProgramDLPRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getAllProgramDLP: () => {
        dispatch(getAllProgramDLP());
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(D_Home);