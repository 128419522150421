import React, { Component } from "react";
import logo from "../images/KaryaTalents_logo.png";
import { connect } from "react-redux";
import menu from "../images/Burger-icon.png";
import Session from "./Session"
import bell from "../images/bell.png";
import v from "../images/v.png";
import iOpenSidebar from "../images/iOpenSidebar.png";
import iDashboard from "../images/iDashboard.png";
import iClose from "../images/iClose.png";
import iCareer from "../images/iCareer.png";
import iReferral from "../images/iReferral.png";
import logoMobile from "../images/logoPutih.png"
import iWork from "../images/iWork.png";
import iMyProfile from "../images/iMyprofile.png";
import iLearning from "../images/iLearning.png";
import iTraining from "../images/iTraining.png";  
import help from "../images/help-icon.png";
import setting from "../images/setting-icon.png";
import logout from "../images/logout-icon.png";
import { API } from "../_redux/helper";
import iVIP from "../images/iVIP.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  getProfileId
} from "../_actions/user";
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from "react-awesome-modal";
import Rating from '@material-ui/lab/Rating';
import dlc_close from "../images/dlc_close.svg"

// var $ = require("jquery");
class NewHeader extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      visible: true,
      showStore: false,
      sizeStore: false,
      height:"",
      var_confirm: JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
      var_report: sessionStorage.getItem("report"),
      rating:"",
      ratting:0,
      comment:"",
      desc_ratting:"",
      rating_vip:"",
      rating_interest:"",
      redirectLink: "",
      popup_interest:false,
      popup_personality:false,
      popup_vip:false,
      popup_values:false,
      popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
      rate:"",
      show_modal: "",
      // popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
      // popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
      // popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
      // popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
      // `${window.location.pathname}`
    };
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.setState({height:window.innerHeight})
    window.scrollTo(0, 0)
  }

  changeBackground = () => {
    this.setState({link: !this.state.link});
  //   if (window.innerWidth< 769 ){
  //   let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
  //     obj[key] = sessionStorage.getItem(key);
  //     return obj
  //   }, {});
    
  //   const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
  //   let finalObj = {}

  //   allowed.map(x => {
  //     if(x in raw) {
  //       finalObj[x] = raw[x]
  //     }
  //   })
  //   let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
  //   if(getKeyTrue) {
  //     let checkIndex = allowed.indexOf(getKeyTrue)
  //     this.onChangeRedirect()
  //   }else{
  //     this.setState({link: !this.state.link});
  //   }
  // }else{
  //   this.setState({link: !this.state.link});
  // }
  };


  redirectLink=(e)=>{
    if (JSON.parse(sessionStorage.getItem("rating-personality"))==true){
       window.location.href="#"
       this.setState({popup_personality:"true"})
    }else if (JSON.parse(sessionStorage.getItem("rating-interest"))==true){
      window.location.href="#"
      this.setState({popup_interest:"true"})
    }else if (JSON.parse(sessionStorage.getItem("rating-vip"))==true){
      window.location.href="#"
      this.setState({popup_vip:"true"})
    }else if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
      window.location.href="#"
      this.setState({popup_values:"true"})
    }else if (JSON.parse(sessionStorage.getItem("rating-program"))==true){
      window.location.href="#"
      this.setState({popup_program:"true"})
    }else{
        window.location=`/${e}`
      }
    // this.state.redirectLink = `${e}`
  }

  changePopup=()=>{
      this.setState({ visible: true});
      this.setState({ link: !this.state.link });
  }

  changeVisible = () => {
    this.setState({ visible: !this.state.visible });
  };
  
  logout=()=>{
    Session.removeSessionLogout()
  }

  close =()=>{
    this.setState({ visible: !this.state.visible });
  }

    // POPUP RATING START 
   changeRatting=(e)=>{
    this.setState({ratting:e.target.value})
    let val
    if (e.target.value==1){
      val = "Very Bad"
    }else if (e.target.value==2){
      val = "Bad"
    }else if (e.target.value==3){
      val = "Need Improvement"
    }else if (e.target.value==4){
      val = "Good"
    }else{
      val = "Very Good"
    }
    this.setState({desc_ratting:val})
  }

  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }
 

 
    onChangeRedirect = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "", comment_interest: ""}, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting_vip: "", comment_vip: "" }]
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        show_modal: "true",
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      
    } else {
      if (this.state.redirectLink!==""){
      window.location.href = `/${this.state.redirectLink}`
      }
    }
  }

  submitRatingAll = () => {
    this.state.dataPopup[Object.keys(this.state.dataPopup)[0]] = this.state.ratting
    this.state.dataPopup[Object.keys(this.state.dataPopup)[1]] = this.state.comment

    axios.post(this.state.urlPopup, this.state.dataPopup)
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
          window.location.href=`/${this.state.redirectLink}`
      }).catch((err)=>{
          console.log(err,"error");
      })
  }

  _onKeyDown=(e)=>{
    if (e.key === 'Enter') {
     this.submitRatingAll()
    }
  }

  closeRatingAll = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "-", comment_interest: ""}, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting_vip: "-", comment_vip: "" }]
    let data_rating = []
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        show_modal: "true",
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      data_rating.push(arr[checkIndex])
    }  

    axios.post(this.state.urlPopup,data_rating[0])
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
      }).catch((err)=>{
          console.log(err,"error");
      })
  }


  render() {
    const { profile } = this.props.profile1;
    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }

    const nama = '' + nickname
    const name = nama.split(" ")[0]

   

    return (
      <div>
        <div className="NewHeader header-dashboard-tablet" style={{top:"0px"}} id="width-100">
          <div className="flex box space-between header_shadow width-100" >
            <div onClick={this.changeBackground}>
              <LazyLoadImage effect="blur" loading="lazy" alt="" src={menu} className="iconMenu" />
            </div>
            <div>
              <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="logo" />
            </div>
            <div className="flex">
              <div style={{display:"none"}}>
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={bell} className="bell" />
                <div className="notif">
                  <p>90</p>
                </div>
              </div>
              <div className="avatar">
                {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={number1} style={{height:"100%", width:"100%"}} /> */}
                {profile.photo &&
                <LazyLoadImage effect="blur" className="pointer cover br-100"
                  src={`${API}/File/Profile/${profile.photo}`}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  onClick={this.changeVisible}
                />}
              </div>
              <p className="name"  onClick={this.changeVisible} style={{cursor:"pointer"}}>{name}</p>
              <LazyLoadImage effect="blur" loading="lazy" alt="" src={v} className="popup" onClick={this.changeVisible} style={{cursor:"pointer"}}/>
            </div>
          </div>
          <div
            className="popup_settings"
            style={{ display: this.state.visible ? "none" : "flex" }}
          >
            <div className="mini_box" >
              <div className="flex column">
                <div className="flex pad_box" onClick={this.close} style={{display:"none"}}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} />
                  <p>Settings</p>
                </div>
                <div className="flex pad_box" onClick={this.close} style={{display:"none"}}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={help} />
                  <p>Help</p>
                </div>
                <div className="none pad_box" onClick={this.close}>
                  <Link to="/ChangePassword" style={{display: 'flex'}}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} />
                  <p>Change Password</p>
                  </Link>
                </div>
                <div className="flex pad_box" onClick={this.logout}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={logout} />
                  <p style={{ color: "red" }}>Logout</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{marginTop:"-79px"}}>
        <Sidebar/>
        </div> */}
        </div>
        
        <div className={this.state.link?"block md-header":"none"}>
        {/* <Slide left opposite collapse when={this.state.link} > */}
          <div className="flex" style={{transition:"width 2s"}}>
            {/* <div className="bigSidebar" style={{width: this.state.link ? "296px": "unset"}}> */}
            <div className="bigSidebar" onClick={this.onChangeRedirect}
              style={{ width: this.state.link ? "260px" : "0px", transition:"width 2s",height:"100%", top:"0px"}}
              >
              <div className="flex column">
                <div style={{ height: "87px", width: "100%" }}>
                  <LazyLoadImage effect="blur"
                    src={iOpenSidebar} className="flex a_"
                    style={{
                      width: "24px",
                      height: "18px",
                      marginTop: "34px",
                      marginLeft: "30px",
                      display: this.state.link ? "none" : "block",
                    }}
                    onClick={this.changeBackground}
                  />
                  <LazyLoadImage effect="blur"
                    src={iClose} className="none b_"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "34px",
                      marginLeft: "30px",
                      display: this.state.link ? "block" : "none",
                    }}
                    onClick={this.changeBackground}
                  />
                </div>

                <div className="flex hov" onClick={() => this.state.redirectLink = 'NewDashboard'}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iDashboard} className="bullet" />
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Dashboard
                  </p>
                </div>

                <div className="flex hov" onClick={() => this.state.redirectLink = 'MyProfile' }>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iMyProfile} className="bullet" />
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    My Profile
                  </p>
                </div>

                <div className="flex hov" onClick={() => this.state.redirectLink = 'JobOpportunities' }>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iCareer} className="bullet" />
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Job Opportunities
                  </p>
                </div>

                <div className="flex hov" onClick={() => this.state.redirectLink = 'Referral' }>
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iReferral} className="bullet" />
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Referral
                  </p>
                </div>

                <div className="flex hov"  onClick={() => this.state.redirectLink = 'work_assessment'}>
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="bullet" />
                <div className="flex column">
                      <p style={{ display: this.state.link ? "block" : "none" }}>
                        Assessment
                      </p>
                      <div className="new-ntf" style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >New</span></div>
                  </div>
                </div>

                <div className="flex hov" onClick={() => this.state.redirectLink = 'DigitalLearningPlatform' } >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="bullet" />
                <div className="flex column">
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Digital Learning Platform
                  </p>
                  <div className="new-ntf " style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >BETA</span></div>
                  </div>
                </div>

                <div className="flex hov" onClick={() => this.state.redirectLink = 'CareerCenter' } >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iWork} className="bullet" />
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Career Center
                  </p>
                </div>

                <div className="flex hov" style={{display:"none"}}>
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iTraining} className="bullet" />
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Training Marketplace
                  </p>
                </div>
              </div>
            </div>

          </div>
          {/* </Slide> */}
        </div>
        <div className="burger-dashboard width-100" onClick={this.onChangeRedirect}
        style={{transform: this.state.link ? "scaleY(1)":"scaleY(0)", transition:"width 0.3s",width: this.state.link ? "100%":"0px"}}>
            <div className="menu-sidebar flex column width-100">
                <div className="flex space-between">
                <LazyLoadImage effect="blur" loading="lazy" alt="" className="logoMobile" src={logoMobile}  id="no-print" onClick={this.changePopup} />
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iClose} className="closeMobile"  onClick={this.changeBackground}/>
            </div>
            {/* <Link to="/NewDashboard"> */}
                {/* <a href="/NewDashboard"> */}
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'NewDashboard'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iDashboard} />
                        <p> Dashboard</p>
                    </div>
                </div>
                {/* </a> */}
                {/* </Link> */}
                {/* <Link to="/MyProfile"> */}
                {/* <a href="/MyProfile"> */}
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'MyProfile'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iMyProfile} />
                        <p> My Profile</p>
                    </div>
                </div>
                {/* </a> */}
                {/* </Link> */}
                {/* <Link to="/JobOpportunities"> */}
                {/* <a href="/JobOpportunities"> */}
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'JobOpportunities'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iCareer} />
                        <p>Job Opportunities</p>
                    </div>
                </div>
                {/* </a> */}
                
                {/* <a href="/Referral"> */}
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'Referral'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iReferral} />
                        <p>Referral</p>
                    </div>
                </div>
                {/* </a> */}
                {/* </Link> */}
                {/* <a href="/work_assessment"> */}
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'work_assessment'}>
                  <div className="flex width-100 space-between">
                    <div className="flex ">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="bullet" />
                     <p> Assessments </p>
                    </div>
                    <div className="new-ntf" style={{ display: "flex", margin:"auto 0px auto 14px" }}> <span className="openSans-Bold" >New</span></div>
                    </div>
                </div>
                {/* </a> */}
                {/* <a href="/DigitalLearningPlatform" > */}
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'DigitalLearningPlatform'}>
                <div className="flex width-100 space-between">
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} />
                        <p>Digital Learning Platform</p>
                    </div>
                    <div className="new-ntf" style={{ display: "flex", margin:"auto 0px auto 14px" }}> <span className="openSans-Bold" >BETA</span></div>
                </div>
                </div>
                {/* </a> */}
                {/* <a href="/CareerCenter"> */}
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'CareerCenter'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iWork} />
                        <p>Career Center</p>
                    </div>
                </div>
                {/* </a> */}
                
                <div className="item-dashboard" style={{display:"none"}}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iTraining} />
                        <p>Training Marketplace</p>
                    </div>
                </div>
               
                <div className="width-100" style={{backgroundColor:"#376186", display:"none"}}>
                <div className="item-dashboard" style={{padding:"15px 0px"}}>
                    <div className="flex">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={help} style={{height:"22px",marginLeft:"7px", marginRight:"32px"}}/>
                        <p>Help</p>
                    </div>
                </div>
                <div className="item-dashboard" style={{padding:"15px 0px"}}>
                    <div className="flex">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} style={{height:"22px",marginLeft:"7px", marginRight:"32px"}} />
                        <p>Setting</p>
                    </div>
                </div>
                <div className="item-dashboard"  style={{padding:"15px 0px"}} onClick={this.logout}>
                    <div className="flex">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logout} style={{height:"22px",marginLeft:"7px", marginRight:"32px"}}/>
                        <p>Logout</p>
                    </div>
                </div>
                </div>
                
            </div>
        </div>
        <Modal  id="course-outline1" visible={this.state.show_modal} effect="fadeInUp" onClickAway={this.closeRatingAll}>
            <div className="popup_dlc  flex column"   >
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" src={dlc_close} onClick={this.closeRatingAll}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown} >
                 <h2 className="openSans">Rate this assessment</h2>
                 <Rating name="pristine" value={this.state.ratting} onChange={this.changeRatting} />
                 <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1)Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4)Good</p>
                   <p className="openSans">(5)Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this assessment</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                 </div>
                 <button onClick={this.submitRatingAll} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
      
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
