import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, useLocation, generatePath, useParams } from "react-router-dom";
import iconVideo from "../images/icons/icons/video-icon.png"
import { API_DLP } from "../_redux/helper"
import axios from 'axios';
import {GAevent} from './TrackingEvent'
import ReactPixel from 'react-facebook-pixel';
// material ui
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DlcPlaylistVideo from "./DlcPlaylistVideo"
// icon
import CheckIcon from '@material-ui/icons/Check';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { LazyLoadImage } from 'react-lazy-load-image-component';

/* eslint eqeqeq: 0 */
export default function DlcVideoPlaylist (props){
    const [list_videos, setListVideos] = useState([])
    const [index_video, setIndexVideo] = useState("")
    const [course_type_id, setCourseTypeId] = useState(0) 
    const [sources, setSources] = useState([])
    const [speedC, setSpeedC] = useState(2)
    const [show_speed, setShowSpeed] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const courseParams = Number(params.course_id)
    const indexParams = Number(params.index);
    const program_id = Number(params.program_id)
    const nameCourse = params.nameCourse
    const nameTitleCourse = params.nameTitleCourse
    const video_id = Number(index_video)
    const max_id = Number(sessionStorage.getItem("course_type_id"))
    const list_video_id = sessionStorage.getItem("list-vd-id").split(',')
    const sort_video_id = list_video_id.sort()
    let routeDynamic = location.pathname.split("/")
    routeDynamic.pop()
    routeDynamic.push(":index")
    let dynamicRoute = routeDynamic.join("/")
    const index_id_video = sort_video_id.indexOf(`${courseParams}`)
    const id_next_video = sort_video_id[index_id_video+1]
    const max_video_id = sort_video_id[index_id_video]
    const min_video_id = sort_video_id[0]
    const id_prev_video = sort_video_id[index_id_video-1]


  const clickPlayVideo = (index, video_id) => {
    // list_videos[indexParams].status = "done"
    list_videos[index].status = "playing"
    // updateProgres(video_id)
    props.parentCallback(list_videos[index].name)
    history.replace(generatePath(dynamicRoute, { index: index }));
    GAevent(`Button Play Video - ${window.location.pathname}`,'Button Play Video') 
    ReactPixel.track(`Button Play Video - ${window.location.pathname}`,'Button Play Video'); 
  }

  const chageVideoPrevios = () => {
    if (indexParams-1 >= 0) {
        // updateProgres(list_videos[indexParams-1].id)
        // const listVideos = list_videos
        // listVideos[indexParams].status = "done"
        props.parentCallback(list_videos[indexParams-1].name)
        list_videos[indexParams-1].status = "playing"
        history.replace(generatePath(dynamicRoute, { index: indexParams-1 }));
    }
    else{
        if (Number(max_video_id) <= Number(min_video_id)){
            window.location.href="/DigitalLearningCenter/"+ program_id +"/CourseOutline"
        }else{
            window.location.href="/DigitalLearningCenter/"+ program_id +"/"+ (id_prev_video) +"/CourseOutline/"+nameTitleCourse+"/"+nameCourse+"/"+ (video_id-1)
    } 
    }
    GAevent(`Button Previous Video - ${window.location.pathname}`,'Button Previous Video') 
    ReactPixel.track(`Button Previous Video - ${window.location.pathname}`,'Button Previous Video');
  }

  const chageVideoNext = () => {
    if (indexParams+1 < list_videos.length) {
        // updateProgres(list_videos[indexParams+1].id)
        // const listVideos = list_videos
        // listVideos[indexParams].status = "done"
        list_videos[indexParams+1].status = "playing"
        history.replace(generatePath(dynamicRoute, { index: indexParams+1 }));
        props.parentCallback(list_videos[indexParams+1].name)
    }
    else{
        if (Number(max_video_id) >= Number(max_id)){
            window.location.href="/DigitalLearningCenter/"+ program_id +"/CourseOutline"
        }else{
        window.location.href="/DigitalLearningCenter/"+ program_id +"/"+ (id_next_video) +"/CourseOutline/"+nameTitleCourse+"/"+nameCourse+"/"+ "0"
    }}
    GAevent(`Button Next Video - ${window.location.pathname}`,'Button Next Video') 
    ReactPixel.track(`Button Next Video - ${window.location.pathname}`,'Button Next Video');
  }

  useEffect(() => {
    getAllVideo()
  }, [])

  const getAllVideo = () =>{
    var user_id = sessionStorage.getItem("i-dlp")
    return new Promise (resolve => {

        axios
        .get(`${API_DLP}/api/getusercoursedetail_by_course/${user_id}/${params.course_id}`)
        .then(({data}) => {
            data.map((x,i)=>{
                if (i==params.index){
                    setSources(x.course_value)
                }
            })
            let result = []
            data.forEach(el => {
                sessionStorage.setItem("phase_id",el.course_type_id)
                setCourseTypeId(el.course_type_id)
                result.push({
                    "id" : el.id,
                    "predesesor" : el.predesesor,
                    "name" : el.course_title,
                    "time" : !el.duration ? 0 : el.duration,
                    "sources": 
                    typeof(el.course_value) == "string" ? 
                    [
                        {
                            src: `${API_DLP}/api/course/getVideo/${el.course_value}`,
                            type: 'video/mp4',
                            label: '240P',
                            res:240,
                            selected: true,

                          },
                        ]:
                        [
                              {
                                src: `${API_DLP}/api/course/getVideo/${el.course_value[(el.course_value.findIndex(element => element.includes("720p")))]}`,
                                type: 'video/mp4',
                                label: '720P',
                                res:720,
                              },
                              {
                                src: `${API_DLP}/api/course/getVideo/${el.course_value[(el.course_value.findIndex(element => element.includes("480p")))]}`,
                                type: 'video/mp4',
                                label: '480P',
                                res:480,
                              },
                              {
                                src: `${API_DLP}/api/course/getVideo/${el.course_value[(el.course_value.findIndex(element => element.includes("360p")))]}`,
                                type: 'video/mp4',
                                label: '360P',
                                res:360,
                                selected: true,
                              },
                              {
                                src: `${API_DLP}/api/course/getVideo/${el.course_value[(el.course_value.findIndex(element => element.includes("240p")))]}`,
                                type: 'video/mp4',
                                label: '240P',
                                res:240,
                              },
                            ]
                })
                setCourseTypeId(el.course_type_id)
                sessionStorage.setItem("phase_id",el.course_type_id)
            });
            setListVideos(result)
            resolve(result)
            })
    
        axios
        .get(`${API_DLP}/api/getusercoursedetail_by_course/${user_id}/${id_prev_video}`)
            .then((courses) => {
                courses.data.map((item,i)=>{
                    if (item.course_type=="Video"){
                        setIndexVideo(courses.data.length)
                    }
                })
            })
    })
  }

  const updateProgres = (id) => {
    axios
    .get(`${API_DLP}/api/updateuser_progress/${id}`)
    .then(({data}) => {
        getAllVideo()
    })
  }

  const callBack = (name)=>{
      props.parentCallback(name)
  }

 
    return (
        <div style={{display:"flex", flexWrap: "wrap"}}>
            {/* DEKSTOP */}
            <div className="block vd-playlist-DK" style={{padding:"10px", width: "751px", flexBasis: "751px", maxWidth: "751px"}}>
                <div style={{ marginBottom: "10px"}} className="dekstop-flex">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp" src={iconVideo} />
                    {
                    list_videos.map((item, index)=>{
                        return(
                            <React.Fragment key={index}>
                                {
                                     indexParams === index ?
                                        <p className="title-video-course">{item.name}</p>
                                    :
                                    ""
                                }
                            </React.Fragment>
                        )
                    })
                    }
                </div>
                <div>
                {
                    list_videos.map((item, index)=>{
                            return(
                                <React.Fragment key={index}>
                                    { indexParams === index ?
                                    <DlcPlaylistVideo id_video={list_videos[indexParams].id} updateProgres={updateProgres} playlist={list_videos} getAllVideo={getAllVideo} list_videos={list_videos} course_type_id={course_type_id} sources={sources} playBack={speedC} callBack={callBack} />
                                    : ""}
                                </React.Fragment>
                            )
                        })
                }
                {/* <button className="btn-speed-control-video" onClick={changeSpeed} >
                    <p>{speedC}</p>
                </button>
                <div className="flex column menu-speed-control" style={{ display:(show_speed==true)?"":"none" }}>
                    <p onClick={()=>{selectSpeed(0.5)}} >0.5</p>
                    <p onClick={()=>{selectSpeed(1)}}>1</p>
                    <p onClick={()=>{selectSpeed(1.5)}}>1.5</p>
                    <p onClick={()=>{selectSpeed(2)}}>2</p>
                </div> */}
                </div>
                
            </div>
            {/* MOBILE */}
            <div className="mobile mobile-tablet" style={{width:"100%"}}>
                <div style={{display: "flex", marginBottom: "10px"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp-mobile" src={iconVideo} />
                    {
                    list_videos.map((item, index)=>{
                        return(
                            <React.Fragment key={index}>
                                {
                                     indexParams === index ?
                                    <p className="title-video-course-mobile">{item.name}</p>
                                    :
                                    ""
                                }
                            </React.Fragment>
                        )
                    })
                    }
                </div>
                <div> 
                {/* {
                    list_videos.map((item, index)=>{
                            return(
                                <React.Fragment key={index}>
                                    { indexParams === index ?
                                    <DlcPlaylistVideo id_video={list_videos[indexParams].id} updateProgres={updateProgres} playlist={list_videos} getAllVideo={getAllVideo}  list_videos={list_videos} course_type_id={course_type_id} />
                                    : ""}
                                </React.Fragment>
                            )
                        })
                } */}
                </div>
            </div>
            {/* DEKSTOP */}
            <div className="dekstop-only" style={{padding:"10px", width: "460px", flexBasis: "460px", maxWidth: "460px"}}>
                <div className="container-button-prev-next">
                    <Button onClick={()=>chageVideoPrevios()} variant="contained" color="primary">
                        ❮ Previous
                    </Button>
                    <Button onClick={()=>chageVideoNext()} variant="contained" color="primary">
                        Next ❯
                    </Button>
                </div>
                    {
                        list_videos.map((list_video, index)=>{
                            return(
                                <List key={list_video.id} className={indexParams === index ? "list-item-course-videoplaylist-playing" : list_video.predesesor === 1 ? "list-item-course-videoplaylist-done": "list-item-course-videoplaylist"} component="div" disablePadding onClick={()=>{clickPlayVideo(index, list_video.id)}}>
                                    <ListItem style={{padding:0}}>
                                    <Grid container>
                                        <Grid item xs={12} sm={1} style={{padding:"5px", position: "relative"}}>
                                            <CheckIcon className={list_video.predesesor === 1 ?"checkVPDoneCourse":"checkVPCourse"}/>
                                        </Grid>
                                        <Grid item xs={12} sm={8} style={{padding:"5px"}}>
                                            <p className="name-video-dlp">{list_video.name}</p>
                                            <p className="time-video-dlp">{list_video.time}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={3} style={{padding:"5px", position: "relative"}}>
                                            {
                                                indexParams === index ?
                                                <p className="now-playing-text">Now Playing</p>
                                                :
                                                <PlayCircleFilledIcon onClick={()=>{clickPlayVideo(index, list_video.id)}} className="play-icon-VP"/>
                                            }
                                        </Grid>
                                    </Grid>
                                    </ListItem>
                                </List>
                            )
                        })
                    }
            </div>
            {/* MOBILE */}
            <div className="mobile mobile-tablet" style={{width: "100%", marginTop:"20px"}}>
                <div className="container-button-prev-next">
                    <Button onClick={()=>chageVideoPrevios()} variant="contained" color="primary">
                        ❮ Previous
                    </Button>
                    <Button onClick={()=>chageVideoNext()} variant="contained" color="primary">
                        Next ❯
                    </Button>
                </div>
                    {
                        list_videos.map((list_video, index)=>{
                            return(
                                <List key={list_video.id} className={indexParams === index ? "list-item-course-videoplaylist-playing" : list_video.predesesor === 1 ? "list-item-course-videoplaylist-done": "list-item-course-videoplaylist"} component="div" disablePadding onClick={()=>{clickPlayVideo(index, list_video.id)}}>
                                    <ListItem style={{padding:0}}>
                                        <div>
                                            <CheckIcon className={list_video.predesesor === 1?"checkVPDoneCourse":"checkVPCourse"}/>
                                        </div>
                                        <div style={{marginLeft: "30px", maxWidth: "180px", minWidth: "180px"}}>
                                            <p className="name-video-dlp-mobile">{list_video.name}</p>
                                            <p className="time-video-dlp">{list_video.time}</p>
                                        </div>
                                        <div>
                                            {
                                                 indexParams === index ?
                                                <p className="now-playing-text">Now Playing</p>
                                                :
                                                <PlayCircleFilledIcon className="play-icon-VP"/>
                                            }
                                        </div>
                                    </ListItem>
                                </List>
                            )
                        })
                    }
            </div>
      </div>
    );
}
