import {GET_CERTIFICATION, GET_CERTIFICATION_ID, PUT_CERTIFICATION, DELETE_CERTIFICATION, POST_CERTIFICATION } from "../config/constants";
  const initialState = {
    certification:[],
    certificationId:[],
    deleteCertification:[],
    postCertification:[],
    putCertification:[],
    isLoading: false,
    error: false,
  };
  
  export const getCertificationRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_CERTIFICATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_CERTIFICATION}_FULFILLED`:
        return {
          ...state,
          certification: action.payload.data,
          isLoading: false,
        };
      case `${GET_CERTIFICATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getCertificationIdRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_CERTIFICATION_ID}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_CERTIFICATION_ID}_FULFILLED`:
        return {
          ...state,
          certificationId: action.payload.data,
          isLoading: false,
        };
      case `${GET_CERTIFICATION_ID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const postCertificationRed = (state = initialState, action) => {
    switch (action.type) {
      case `${POST_CERTIFICATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${POST_CERTIFICATION}_FULFILLED`:
        return {
          ...state,
          postCertification: action.payload.data,
          isLoading: false,
        };
      case `${POST_CERTIFICATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const putCertificationRed = (state = initialState, action) => {
    switch (action.type) {
      case `${PUT_CERTIFICATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${PUT_CERTIFICATION}_FULFILLED`:
        return {
          ...state,
          putCertification: action.payload.data,
          isLoading: false,
        };
      case `${PUT_CERTIFICATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const delCertificationRed = (state = initialState, action) => {
    switch (action.type) {
      case `${DELETE_CERTIFICATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${DELETE_CERTIFICATION}_FULFILLED`:
        return {
          ...state,
          deleteCertification: action.payload.data,
          isLoading: false,
        };
      case `${DELETE_CERTIFICATION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  
  