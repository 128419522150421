import React, { useState, useEffect } from "react";
import Nav from '../components/Nav';
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import imgfaq1 from '../images/FAQ.jpg';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import plus from '../images/icons/plus.svg'
import minus from '../images/icons/minus.svg'
import Loading from "../components/Loading";
import ReactGA from 'react-ga';
import {API} from '../_redux/helper'
import Fade from 'react-reveal/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';



var $ = require('jquery');
const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {

  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#0D19A3',
    borderRadius: '5px',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
}))(MuiExpansionPanelDetails);

const imagesPath = {
  minus: minus,
  plus: plus
}
const imagesPath2 = {
    minus: minus,
    plus: plus
  }



export default function Pertanyaan() {

  $("#infoToggler").click(function() {
    $(this).find('img').toggle();
  })
  


  
  const [faq, setFaq] = useState([])
  const [employer, setEmployer] = useState([])
  const [expanded, setExpanded] = useState()
  const [expanded2, setExpanded2] = useState()
  const [expanded3, setExpanded3] = useState()
  const [expanded4, setExpanded4] = useState()
  const [expanded5, setExpanded5] = useState()
  const [expanded6, setExpanded6] = useState()
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(true)
  const [open2, setOpen2] = useState(true)
  const [open3, setOpen3] = useState(true)
  const [open4, setOpen4] = useState(true)
  const [open5, setOpen5] = useState(true)
  const [open6, setOpen6] = useState(true)

  const toggle = () => setShow((currentState) => ({show: !currentState.show}));
  const toggleImage = () => {
    setOpen(useState => ( !open ))
  }
  const toggleImage2 = () => {
    setOpen2(useState => ( !open2 ))
  }

  const getImageName = () => open ? 'plus' : 'minus'
  const imageName = getImageName();

  const getImageName2 = () => open2 ? 'plus' : 'minus'
  const imageName2 = getImageName2();

  const getImageName3 = () => open3 ? 'plus' : 'minus'
  const imageName3 = getImageName3();

  const getImageName4 = () => open4 ? 'plus' : 'minus'
  const imageName4 = getImageName4();

  const getImageName5 = () => open5 ? 'plus' : 'minus'
  const imageName5= getImageName5();

  const getImageName6 = () => open6 ? 'plus' : 'minus'
  const imageName6 = getImageName6();



  useEffect(() => {
    window.scrollTo(0, 0);
    axios
        .get(`${API}/api/faq`)
        .then(res => {
            setFaq(res.data)
        })
        .catch(err => {
        })
  }, [])

  useEffect(() => {
    axios
        .get(`${API}/api/faq_employer`)
        .then(res => {
            setEmployer(res.data)
        })
        .catch(err => {
        })
  }, [])

  const handleChange = (panel) => (event, newExpanded, id) => {
    setExpanded(newExpanded ? panel : false);
     setOpen(id ? open : !open)
  }
  const handleChange2 = (panel2) => (event, newExpanded2, id) => {
    setExpanded2(newExpanded2 ? panel2 : false);
     setOpen2(id ? open2 : !open2)
  }
  const handleChange3 = (panel3) => (event, newExpanded3, id) => {
    setExpanded3(newExpanded3 ? panel3 : false);
     setOpen3(id ? open3 : !open3)
  }
  const handleChange4 = (panel4) => (event, newExpanded4, id) => {
    setExpanded4(newExpanded4 ? panel4 : false);
     setOpen4(id ? open4 : !open4)
  }
  const handleChange5 = (panel5) => (event, newExpanded5, id) => {
    setExpanded5(newExpanded5 ? panel5 : false);
     setOpen5(id ? open5 : !open5)
  }
  const handleChange6 = (panel6) => (event, newExpanded6, id) => {
    setExpanded6(newExpanded6 ? panel6 : false);
     setOpen6(id ? open6 : !open6)
  }

   // timeOut for session
  //  sessionStorage.setItem('setupTime', new Date().getTime())
  //  var timeSession = sessionStorage.getItem('setupTime')
  //  if (new Date().getTime() - timeSession > 24 * 60 * 60 * 100 ){
  //      sessionStorage.clear()
  //      sessionStorage.removeItem("setupTime")
  //      window.location.href="/"
  //  }
  if(localStorage.getItem('token')) {
    window.location.href = '/NewDashboard'
  }
   ReactGA.pageview(window.location.pathname + window.location.search);
   ReactPixel.track(window.location.pathname);
  /* eslint eqeqeq: 0 */
  if (faq=="" || faq.length==0) {
    return (
      <React.Fragment>
        <Loading />
      </React.Fragment>
    );
  }

  return (
      <div>
        <Nav />
        <div className="box-wrapper box-wrapper-master faq">
          <div className="wrapper">
            <div className="content">
              <div className="page-container mt-80 master-page width-100">
                <div className="banner width-100">
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={imgfaq1} className="width-100 img-center" alt="" />
                </div>
                <div className="page-container-outer">
                  <div className="box-title width-100">
                  <Fade right cascade>
                    <h3 className=" width-100" style={{fontFamily:"League Spartan"}}>TALENTS</h3>
                    </Fade>
                    <div className="line-horizontal width-100"></div>
                  </div>
                </div>
                <div className="page-container-outer">
                  <div className="box-description width-100">
                    
                    {faq.map((faq, i) => {
                        if (i==0){
                            return(
                        <div key={i}>
                          <Fade bottom cascade>
                            <ExpansionPanel square expanded={expanded === 'panel'+faq.id}  onChange={handleChange('panel'+faq.id)}>
                                <ExpansionPanelSummary style={{backgroundColor: '#3B6C98'}}  className="expSum" ria-controls={"panel"+faq.id+"d-content"} id={"panel"+faq.id+"d-header"}>
                                <Typography style={{color:'white', fontFamily:'Montserrat', display:'flex', width:"100%"}}>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" style={{maxWidth: '50px', marginRight:"10px"}} src={imagesPath[imageName]} name={'foto'+faq.id} onClick={(id)=> toggleImage('panel'+faq.id)} id={faq.id} />
                                    {faq.question}
                                </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: '#EFF0FF', borderRadius: '5px', fontFamily:'Montserrat'}}>
                                <Typography>
                                    {faq.answer}
                                </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </Fade>
                          <br/>
                        </div>
                    )}})}  
                    {faq.map((faq, i) => {
                        if (i==1){
                            return(
                        <div key={i}>
                          <Fade bottom cascade>
                            <ExpansionPanel square expanded={expanded2 === 'panel2'+faq.id}  onChange={handleChange2('panel2'+faq.id)}>
                                <ExpansionPanelSummary style={{backgroundColor: '#3B6C98'}}  className="expSum" ria-controls={"panel2"+faq.id+"d-content"} id={"panel2"+faq.id+"d-header"}>
                                <Typography style={{color:'white', fontFamily:'Montserrat', display:'flex', width:"100%"}}>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" style={{maxWidth: '50px', marginRight:"10px"}} src={imagesPath2[imageName2]} name={'foto'+faq.id} onClick={(id)=> toggleImage2('panel2'+faq.id)} id={faq.id} />
                                    {faq.question}
                                </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: '#EFF0FF', borderRadius: '5px', fontFamily:'Montserrat'}}>
                                <Typography>
                                    {faq.answer}
                                </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </Fade>
                          <br/>
                        </div>
                    )}})}  
                     {faq.map((faq, i) => {
                        if (i==2){
                            return(
                        <div key={i}>
                          <Fade bottom cascade>
                            <ExpansionPanel square expanded={expanded3 === 'panel3'+faq.id}  onChange={handleChange3('panel3'+faq.id)}>
                                <ExpansionPanelSummary style={{backgroundColor: '#3B6C98'}}  className="expSum" ria-controls={"panel3"+faq.id+"d-content"} id={"panel3"+faq.id+"d-header"}>
                                <Typography style={{color:'white', fontFamily:'Montserrat', display:'flex', width:"100%"}}>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" style={{maxWidth: '50px', marginRight:"10px"}} src={imagesPath[imageName3]} name={'foto'+faq.id} onClick={(id)=> toggleImage2('panel3'+faq.id)} id={faq.id} />
                                    {faq.question}
                                </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: '#EFF0FF', borderRadius: '5px', fontFamily:'Montserrat'}}>
                                <Typography>
                                    {faq.answer}
                                </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </Fade>
                          <br/>
                        </div>
                    )}})}  
                        
                  </div>
                 
                </div>
              </div>

              <div className="page-container master-page width-100">
                <div className="page-container-outer">
                  <div className="box-title width-100">
                  <Fade right cascade>
                    <h3 className=" width-100" style={{fontFamily:"League Spartan"}}>EMPLOYER</h3>
                    </Fade>
                    <div className="line-horizontal width-100"></div>
                  </div>
                </div>
                <div className="page-container-outer">
                  <div className="box-description width-100">
                    
                    {employer.map((item, i) => {
                        if (i==0){
                            return( 
                        <div key={i}>
                          <Fade bottom cascade>
                            <ExpansionPanel square expanded={expanded5 === 'panel5'+ i}  onChange={handleChange5('panel5' + i)}>
                                <ExpansionPanelSummary style={{backgroundColor: '#3B6C98'}}  className="expSum" ria-controls={"panel5"+item.id+"d-content"} id={"panel5"+item.id+"d-header"}>
                                <Typography style={{color:'white', fontFamily:'Montserrat', display:'flex', width:"100%"}}>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" style={{maxWidth: '50px', marginRight:"10px"}} src={imagesPath[imageName5]} name={item} onClick={(id)=> toggleImage(item.id)} />
                                    {/* <AddCircleOutlineIcon style={{paddingRight:'20px', alignSelf:'center'}}/> */}
                                    {item.question}
                                </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: '#EFF0FF', borderRadius: '5px', fontFamily:'Montserrat'}}>
                                <Typography>
                                    {item.answer}
                                </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </Fade>
                          <br/>
                        </div>
                     )}})}  

                     {employer.map((item, i) => {
                        if (i==1){
                            return( 
                        <div key={i}>
                          <Fade bottom cascade>
                            <ExpansionPanel square expanded={expanded6 === 'panel6'+ i}  onChange={handleChange6('panel6' + i)}>
                                <ExpansionPanelSummary style={{backgroundColor: '#3B6C98'}}  className="expSum" ria-controls={"panel6"+item.id+"d-content"} id={"panel6"+item.id+"d-header"}>
                                <Typography style={{color:'white', fontFamily:'Montserrat', display:'flex', width:"100%"}}>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" style={{maxWidth: '50px', marginRight:"10px"}} src={imagesPath[imageName6]} name={item} onClick={(id)=> toggleImage(item.id)} />
                                    {/* <AddCircleOutlineIcon style={{paddingRight:'20px', alignSelf:'center'}}/> */}
                                    {item.question}
                                </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: '#EFF0FF', borderRadius: '5px', fontFamily:'Montserrat'}}>
                                <Typography>
                                    {item.answer}
                                </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </Fade>
                          <br/>
                        </div>
                     )}})}  
                        
                  </div>
                 
                </div>
              </div>
              <ScrollToTop />
              <Footer />
            </div>
          </div>
        </div>
        
      </div>
    );
} 