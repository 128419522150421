
import React, { Component } from "react";
import { connect } from "react-redux";
import { getQuest, answerQuest } from "../_actions/quest";
import emot1 from '../images/emot1m.png'
import emot2 from '../images/emot2m.png'
import emot3 from '../images/emot3m.png'
import emot4 from '../images/emot4m.png'
import emot5 from '../images/emot5m.png'
import Loading from './Loading.js'
import PageProgressbar from './PageProgressbar'
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import {GAevent} from './TrackingEvent'
import { withStyles } from '@material-ui/core/styles';


var $ = require('jquery');
const styles = theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon1: {
    borderRadius: '5px',
    width: 50,
    height: 50,
    border: "1px solid #f2f2f2",
    backgroundColor: '#ffffff',
    backgroundImage: `url(${emot1})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  },
  icon2: {
    borderRadius: '5px',
    width: 50,
    height: 50,
    border: "1px solid #f2f2f2",
    backgroundColor: '#ffffff',
    backgroundImage: `url(${emot2})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  },
  icon3: {
    borderRadius: '5px',
    width: 50,
    height: 50,
    border: "1px solid #f2f2f2",
    backgroundColor: '#ffffff',
    backgroundImage: `url(${emot3})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  },
  icon4: {
    borderRadius: '5px',
    width: 50,
    height: 50,
    border: "1px solid #f2f2f2",
    backgroundColor: '#ffffff',
    backgroundImage: `url(${emot4})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  },
  icon5: {
    borderRadius: '5px',
    width: 50,
    height: 50,
    border: "1px solid #f2f2f2",
    backgroundColor: '#ffffff',
    backgroundImage: `url(${emot5})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  },
  checkedIcon1: {
    backgroundColor: '#FF7575',
    backgroundImage: `url(${emot1})`,
    'input:hover ~ &': {
      backgroundColor: '#FF7575',
    },
  },
  checkedIcon2: {
    backgroundColor: '#FFA775',
    backgroundImage: `url(${emot2})`,
    'input:hover ~ &': {
      backgroundColor: '#FFA775',
    },
  },
  checkedIcon3: {
    backgroundColor: '#F7E43F',
    backgroundImage: `url(${emot3})`,
    'input:hover ~ &': {
      backgroundColor: '#F7E43F',
    },
  },
  checkedIcon4: {
    backgroundColor: '#AEE05D',
    backgroundImage: `url(${emot4})`,
    'input:hover ~ &': {
      backgroundColor: '#AEE05D',
    },
  },
  checkedIcon5: {
    backgroundColor: '#61C877',
    backgroundImage: `url(${emot5})`,
    'input:hover ~ &': {
      backgroundColor: '#61C877',
    },
  },
})

class Quest extends Component {
  constructor() {
    super()
    this.state = {
      todos: {
        id: 0,
        radio: [],
        scoring: 0,
        user_id: "",
        profile_question_id: " ",
      },
      checked1: "",
      checked2: "",
      checked3: "",
      checked4: "",
      checked5: "",
      selectedValue: [],
      user_id: "",
      currentPage: 1,
      todosPerPage: 1,
      upperPageBound: 1,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: 'disabled',
      pageBound: 1
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem('token')
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id })
    this.props.getQuest(user_id);
  }

  handleToken = () => {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem('token')
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
  }

  handleChange = (profile_question_id) => (e) => {
    let value = e.target.value
    let dataRadio = { profile_question_id, value };
    const { todosPerPage, selectedValue, currentPage } = this.state;
    const filter = this.state.selectedValue.findIndex((selectedValue) => selectedValue.profile_question_id === profile_question_id)
    if (filter === -1) {
      this.state.selectedValue.push({ profile_question_id, value })
      this.setState({ selectedValue: this.state.selectedValue })
    } else {
      this.state.selectedValue[filter] = { profile_question_id, value }
      this.setState({ selectedValue: this.state.selectedValue })
    }
    const areAllRadiosChecked = selectedValue.length / currentPage >= todosPerPage;
    if (areAllRadiosChecked) {
      this.setState({ isNextBtnActive: '' });
    }
  }


  answer = (id, scoringRadio, e) => {
    let listid = this.state.currentPage + 1 ;
    if (listid < 31) {
    const token = sessionStorage.getItem("token")
    const scoring = { scoring: scoringRadio }
    this.props.answerQuest1(token, id, scoring)

    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
   
    this.setState({ currentPage: listid });
  }else if (listid == 31){
    if (scoringRadio==="" || scoringRadio===undefined){
      window.location.href = "#"
    }else{
    const token = sessionStorage.getItem("token")
    const scoring = { scoring: scoringRadio }
    this.props.answerQuest1(token, id, scoring)
     window.location.href = "/Education-and-Work-Experience";
    }
    GAevent('TableQuestion','Submit Question') 
  } 
  };

  next = ()=>{
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
        this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
      }
      let listid = this.state.currentPage + 1;
      this.setState({ currentPage: listid });
      this.setPrevAndNextBtnClass(listid);
      
  }

  componentDidUpdate() {
    $("ul li.active").removeClass('active');
    $('ul li#' + this.state.currentPage).addClass('active');
  }
  handleClick(event) {
    event.preventDefault();
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
    $("ul li.active").removeClass('active');
    $('ul li#' + listid).addClass('active');
    this.setPrevAndNextBtnClass(listid);
  }
  setPrevAndNextBtnClass(listid) {
    let { todos, todosPerPage, selectedValue } = this.state;
    let totalPage = Math.ceil(todos.length / todosPerPage);
    let areAllRadiosChecked = selectedValue.length / listid >= todosPerPage;
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    }
    else if (areAllRadiosChecked) {
      this.setState({ isNextBtnActive: '' });
    }
  }
  btnIncrementClick(e) {
    e.preventDefault()
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick(e) {
    e.preventDefault()
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick(e) {
    e.preventDefault()
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnNextClick(e) {
    e.preventDefault()
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }

  render() {
    //   const currentPage= this.state.currentPage
   
    const { data, isLoading, error } = this.props.quest1;
    const { currentPage, todosPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive, selectedValue } = this.state;

    const todos = data.map((item, i) => {
      const select = this.state.selectedValue.findIndex((selectedValue) => selectedValue.profile_question_id === item.profile_question_id);
      const selectedProfile = this.state.selectedValue[select] ? this.state.selectedValue[select].profile_question_id : ""
      const selected = this.state.selectedValue[select] ? this.state.selectedValue[select].value : ""
      const { classes } = this.props;
      return (
          <div key={i} className="questions-mobile-container" id="test">
            <div className="questions-mobile-question">
              <h5>Questions</h5>
              <p>{item.question}</p>
            </div>
            <div className="questions-mobile-answer">
              <div className="box-quest-mobile">
                <h5>Your Answer</h5>
                <div className="questions-mobile-answer-icon">
                  <div className="flex column">
                <div className="flex width-100 space-between">
                <Radio className={`${classes.root} --ico`} disableRipple color="default"
                  checked={selectedProfile === item.profile_question_id && selected === "1"} 
                  onChange={this.handleChange(item.profile_question_id)} 
                  type="radio" name={"pilih[" + item.profile_question_id + "]"} value={1} 
                  checkedIcon={<span className={clsx(classes.icon1, classes.checkedIcon1)} />}
                  icon={<span className={classes.icon1} />} 
                  onClick={() => { 
                    setTimeout(() => {
                      this.answer(item.id, 1)
                    }, 500); }}/>
                <Radio className={`${classes.root} --ico`} disableRipple color="default"
                  checked={selectedProfile === item.profile_question_id && selected === "2"} 
                  onChange={this.handleChange(item.profile_question_id)} 
                  type="radio" name={"pilih[" + item.profile_question_id + "]"} value={2}
                  checkedIcon={<span className={clsx(classes.icon2, classes.checkedIcon2)} />}
                  icon={<span className={classes.icon2} />}
                  onClick={() => { 
                    setTimeout(() => {
                      this.answer(item.id, 2)
                    }, 500);
                  }} />
                <Radio className={`${classes.root} --ico`} disableRipple color="default"
                  checked={selectedProfile === item.profile_question_id && selected === "3"} 
                  onChange={this.handleChange(item.profile_question_id)} 
                  type="radio" name={"pilih[" + item.profile_question_id + "]"} value={3} 
                  checkedIcon={<span className={clsx(classes.icon3, classes.checkedIcon3)} />}
                  icon={<span className={classes.icon3} />} 
                  onClick={() => { setTimeout(() => {
                    this.answer(item.id, 3)
                  }, 500); }} />
                <Radio className={`${classes.root} --ico`} disableRipple color="default"
                  checked={selectedProfile === item.profile_question_id && selected === "4"} 
                  onChange={this.handleChange(item.profile_question_id)} 
                  type="radio" name={"pilih[" + item.profile_question_id + "]"} value={4} 
                  checkedIcon={<span className={clsx(classes.icon4, classes.checkedIcon4)} />}
                  icon={<span className={classes.icon4} />} 
                  onClick={() => { setTimeout(() => {
                    this.answer(item.id, 4)
                  }, 500); }} />
                <Radio className={`${classes.root} --ico`} disableRipple color="default"
                  checked={selectedProfile === item.profile_question_id && selected === "5"} 
                  onChange={this.handleChange(item.profile_question_id)} 
                  type="radio" name={"pilih[" + item.profile_question_id + "]"} value={5} 
                  checkedIcon={<span className={clsx(classes.icon5, classes.checkedIcon5)} />}
                  icon={<span className={classes.icon5} />}
                  onClick={() => { setTimeout(() => {
                    this.answer(item.id, 5)
                  }, 500); }} />
                  </div>
                  <div className="flex space-between desc-ic">
                    <p>Strongly Dislike</p>
                    <p>Strongly Like</p>
                  </div>
                </div>

                </div>
              </div>
            </div>
          </div>
      );
    })

    // Button class
    const areAllRadiosChecked = selectedValue.length / currentPage >= todosPerPage;
    const classNextActive = areAllRadiosChecked ? 'active-quest-btn' : 'inactive-quest-btn';
    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className='active' id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
        )
      }
      else if ((number < upperPageBound + 1) && number > lowerPageBound) {
        return (
          <li key={number} id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
        )
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip;</a></li>
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
    }

    let renderNextBtn = null;
    if (currentPage >= 1) {
      renderNextBtn = <button className={classNextActive} style={{backgroundColor:"white", color:"#3B6C98"}} onClick={this.btnNextClick} disabled={isNextBtnActive}><a href='#' id="btnNext"> Next </a></button>
    }
    if (currentPage >= 30) {
      renderNextBtn = <button className={classNextActive}  style={{backgroundColor:"white", color:"#3B6C98"}} disabled={isNextBtnActive}><a href='Education-and-Work-Experience' id="btnNext"> Next </a></button>
    }

    let renderPrevBtn = null;
    if (currentPage <= 1) {
      renderPrevBtn = <button className="inactive-quest-btn" style={{backgroundColor:"white", color:"rgb(224, 224, 224)", borderColor:"rgb(224, 224, 224)"}} id="btnPrev" disabled={isPrevBtnActive}> Previous </button>
    }
    if (currentPage > 1) {
      renderPrevBtn = <button className="active-quest-btn" style={{backgroundColor:"white", color:"#3B6C98", borderColor:"#3B6C98"}} onClick={this.btnPrevClick}><a href='#' id="btnPrev" > Previous </a></button>
    }

    let ketPage = null;
    ketPage = <div> {currentPage} /30</div>


    let ProgressBar = null;
    const nilai = currentPage * 100 /30


      ProgressBar = <div className="progress-bar-quest-mobile">
        <PageProgressbar value={nilai}/>
        <div className="MQpage">
          {renderPrevBtn}
          <p className="progress-page-title-mobile"> {currentPage}/30 </p>
          <p style={{color:"transparent", width:"117px"}}>aaa</p>
        </div>
        <br/>
        </div>

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="message-question-load">
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <div className="question-bar">
          {ProgressBar}
          
          {/* <div className="box-quest">
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot1} /></div>
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot2} /></div>
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot3} /></div>
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot4} /></div>
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot5} /></div>
          </div> */}
        </div>
        <div className="table-questions-mobile">
          {renderTodos}
          <div className="pagination">
            <div className="flex group-quest">
              {/* {renderPrevBtn} */}
              <p>
                {/* {ketPage} */}
              </p>
              {/* {pageDecrementBtn} */}
              {/* {renderPageNumbers} */}
              {/* {pageIncrementBtn} */}
             {/* <button onClick={this.next}>Next</button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    quest1: state.quest
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuest: user_id => {
      dispatch(getQuest(user_id));
    },
    answerQuest1: (token, id, scoring) => {
      dispatch(answerQuest(token, id, scoring));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Quest));
