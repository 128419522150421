import { GET_EXP, POST_EXP, GET_BENEFIT_LIST, GET_EXP_LIST, DELETE_EXP, PUT_EXP, GET_EXP_ID } from "../config/constants";
import axios from "axios";
import { API } from '../_redux/helper'

const token = sessionStorage.getItem('token')
export const getExp = (id) => {
  return {
    type: GET_EXP,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_expected_benefit/${id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const getBenefitList = () => {
  return {
    type: GET_BENEFIT_LIST,
    payload: axios({
      method: "GET",
      url: `${API}/api/benefit`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};
export const getExpList = () => {
  return {
    type: GET_EXP_LIST,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_expected_benefit`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const getExpId = (user_id) => {
  return {
    type: GET_EXP_ID,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_expected_benefit?user_id=${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const postExp = (user) => {
  return {
    type: POST_EXP,
    payload: axios({
      method: "POST",
      url: `${API}/api/user_expected_benefit`,
      data: user,
      headers: {
        Authorization: ` ${token}`,
      },
    }).then((res) => {
      window.location.reload(true);
    })
  };
};

export const putExp = (id) => {
  return {
    type: PUT_EXP,
    payload: axios({
      method: "PUT",
      url: `${API}/api/user_expected_benefit/${id}`,
      data: id,
      headers: {
        Authorization: ` ${token}`,
      }
    }).then((res) => {
      window.location.reload(true);
    })
  };
};

export const deleteExp = (id) => {
  return {
    type: DELETE_EXP,
    payload: axios({
      method: "DELETE",
      url: `${API}/api/user_expected_benefit/${id}`,
      data: id,
      headers: {
        Authorization: ` ${token}`,
      }
    }).then((res) => {
      window.location.reload(true);
    })
  };
};