import React, { Component } from 'react';
import location from "../images/gold_place.svg"
import gold_industry from "../images/gold_industry.svg"
import vip_profiler from "../images/vip-profiler-jd.svg"
import permanent from "../images/gold_function.svg"
import fulltime from "../images/gold_time.svg"
import wallet from "../images/gold_salary.svg"
import {getJobDetailPublic, jobByTitle} from "../_actions/jobs"
import {getBannerById} from "../_actions/article"
import {priceSplitter,icon_job, icon_share,sortFunction} from "../_redux/function"
import {FacebookShareButton, WhatsappShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import Modal from "react-awesome-modal"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Nav from "../components/Nav";
import Footer from "../components/Footer"
import {API, url} from '../_redux/helper'
import {setDate} from '../_redux/function'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";
import { Helmet } from 'react-helmet';
import Job_Apply from '../components/Job_Apply';
import Loading from "../components/Loading";
import _ from "lodash"


class componentName extends Component {
    constructor() {
        super();
        this.state = {
          job_id: "",
          login: false,
          register: false,
          forgotPassword: false,
          visible:false,
          link:"",
          user_id:"",
          job_apply:false,
          dataJob:[],
          load_state:true
        };
      }
    
      componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getJobDetailPublic(this.props.job_id);
        this.props.getBannerById(1)
        this.props.jobByTitle("1","","","","","","","","","");
        ReactPixel.track(window.location.pathname);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }


      handleCallback = (childData) =>{
        // this.setState({link: childData})
        window.location.href=`/${childData}`
    }
    closeModal=(e)=>{
        this.setState({job_apply:e})
        sessionStorage.setItem("apply_job",false)
    }
    applyJob=(e)=>{
        this.setState({job_apply:true})
        sessionStorage.setItem("apply_job",true)
    }

    render() {
        const { dataJobDetailPublic, isLoading } = this.props.job1;
        let n = ""
        dataJobDetailPublic.map((x)=> n = x.description_detail)
        const {dataBannerById, error} = this.props.bannerId1;
        const {dataJobByTitle } = this.props.jobByTitle1;
        const {job_id} = this.props
        const {job_apply} = this.state
        const job_function =dataJobDetailPublic.length !==0?dataJobDetailPublic.map((item)=>(item.job_function)):""
        const new_dataJob = dataJobByTitle.sort(sortFunction)
        let data_job =[]
        new_dataJob!==undefined && new_dataJob.length>0?
        new_dataJob.map((item)=>{
            if (parseInt(item.job_opportunity_id) !== parseInt(job_id) && (item.job_function===job_function[0])){
                data_job.push(item)
            }
        }):console.log("err");


        if (isLoading || dataJobByTitle.length===0 || dataJobDetailPublic==="" || n ==="" ) {
            return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            </Helmet>
                 <div className="box-wrapper bg-color job_detail">
                    <div className="wrapper public_">
                        <div className="content Dashbrd">
                        <Nav />
                            <div className="page-container mt-80 jo_ width-100" >
                                <div className="page-container-outer job-summary dashboard" >
                                    <div className="flex wd-webkit mt-21 mb-28 br-10 bg-ff p-4" >
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/Banner/${dataBannerById.photo}`} style={{width:"100%", height:"114px", objectFit:"cover",
                                         borderRadius:"6px", filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))"}}/>
                                    </div>
                                    <p className='s-16 c-36 mb-24 pointer' onClick={()=>window.location.href="/Job-Opportunity-Public"}>{"<"} Jobs</p>
                                    {!isLoading ?(
                                    <div className="page-container-inner width-100">
                                        {dataJobByTitle.map((item,i)=>{
                                            if (item.job_opportunity_id === parseInt(job_id)){
                                                return(
                                        <div key={i} className="box-edu-container --c width-100"> 
                                            <div className="canvas block">
                                            {/* <div className="dekstop"> */}
                                            <div className='flex-mob space-between width-100'>
                                                <div className='flex column wd-734'>
                                                <div className="flex-mob width-100 mb-52">
                                                    <div className="company_pic">
                                                    {item.photo &&
                                                        <LazyLoadImage className='flex width-100 height-100' effect="blur" loading="lazy" alt="" src={`${API}/File/Job_Opportunity/${item.photo}`} />}
                                                    </div>
                                                    <div className="flex width-100 column">
                                                        <div className="flex space-between width-100 referral">
                                                            <p className="company_name">{item.company===null?item.alias_text:item.company}</p>
                                                        </div>
                                                        <p className="job_name mb8">{item.job_title} </p>
                                                        <p className='flex s-14 OpenSans w-400 c-87'>{setDate(item.createdAt)}{parseInt(setDate(item.createdAt))>1?"s":""} {Number.isFinite(parseInt(setDate(item.createdAt)))>0?"ago":""}</p>
                                                        <div className="flex referral width-100" style={{marginTop:"20px"}}>
                                                            <div className="flex width-100 center_mob">
                                                            <div className="apply_job">
                                                                <button onClick={(e)=>this.applyJob(e)}>
                                                                    <p style={{padding:"0px"}}>Apply</p>
                                                                </button>
                                                                <button className='none' style={{backgroundColor:"white", marginLeft:"15px"}}>
                                                                    <p style={{padding:"0px", color:"#3B6C98", fontWeight:"normal"}}>Refer</p>
                                                                </button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className="flex width-100 ">
                                                    <div className="flex column share">
                                                        <p>Share to:</p>
                                                        {icon_share(item.job_opportunity_id,item.job_title)}
                                                    </div>
                                                </div>
                                                <div className="flex width-100 salary">
                                                    {icon_job(item.industry,gold_industry)}
                                                    {icon_job(item.job_level,permanent)}
                                                    <div className="flex">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={wallet} />
                                                        <p>IDR {priceSplitter(item.salary_from *1)} - {priceSplitter(item.salary_to * 1)} </p>
                                                    </div>
                                                    {icon_job(item.job_type,fulltime)}
                                                    {icon_job(item.city,location)}
                                                </div>
                                                <div className="job_desc">
                                                    <div className="flex column width-100">
                                                        <h5>Summary</h5>
                                                        <p  style={{marginBottom:"24px"}}>{item.description} </p>
                                                    </div>
                                                </div>
                                                <div  dangerouslySetInnerHTML={{ __html: item.description_detail }}></div>
                                                </div>
                                                <div className='wd-323 flex column'>
                                                    <div className='flex column shd_ p-14 bg-ff br-5 mb-24'>
                                                        <img src={vip_profiler} className="width-100 flex mb-14" />
                                                        <p className='c-36 w-600 auto s-1822'>Karya VIP Profiler</p>
                                                        <p className='c-29 mt-14 s-1419 mb-24 openSans'>Learn deeper about yourself including how to leverage your strengths and be aware of your blindspot to achieve thriving and fulfilling career.</p>
                                                        <a href="/Karya_Personality_Profiler" className='auto'>
                                                        <button className='bg-36 bd-36 p-818 br-16 wd-max-content auto'>
                                                            <p className='c-ff w-bold s-14'>Take VIP Profiler</p>
                                                        </button>
                                                        </a>
                                                    </div>
                                                    {data_job.length>0 &&
                                                    <div className='flex  column p-2414 bg-f1 br-5'>
                                                        <p className='w-600 c-33 mb-24'>Other jobs you might like</p>
                                                         {data_job.map((item2,i)=>{
                                                        if( i<4  ){
                                                            // && item2.job_function===item.job_function
                                                            return(  
                                                        <div key={i} className='flex column p-15 br-5 bg-ff mb-14'>
                                                            <p className='c-4f s-14 w-400 mb-10'>{item2.company===null?item2.alias_text:item2.company}</p>
                                                            <p className='s-18 w-600 c-36 mb-12'>{item2.job_title} </p>
                                                            <div className='flex mb-14'>
                                                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_22414_4384)">
                                                                    <path d="M3.2709 3.33871L8.85203 1.8153L8.55647 1.21788C8.3631 0.829562 7.89145 0.669203 7.50313 0.862577L2.50684 3.33871H3.2709Z" fill="#DDB87A"/>
                                                                    <path d="M10.5855 1.85938C10.5163 1.85938 10.4471 1.86881 10.3779 1.88767L9.06678 2.24612L5.0625 3.33877H9.60758H11.5885L11.3432 2.4395C11.2489 2.08891 10.9313 1.85938 10.5855 1.85938Z" fill="#DDB87A"/>
                                                                    <path d="M12.4074 3.88892H12.2282H11.9845H11.7408H9.88097H3.04683H2.1507H1.39607H1.25615H0.787647C0.539248 3.88892 0.317574 4.00368 0.172937 4.18448C0.106906 4.2678 0.0565974 4.36371 0.0282987 4.46904C0.0110051 4.53507 0 4.60425 0 4.67499V4.76932V5.66545V13.4335C0 13.8674 0.352162 14.2195 0.786075 14.2195H12.4058C12.8398 14.2195 13.1919 13.8674 13.1919 13.4335V11.2403H8.52734C7.79001 11.2403 7.19102 10.6413 7.19102 9.90397V9.18392V8.94024V8.69656V8.15574C7.19102 7.79414 7.33564 7.46558 7.5699 7.22504C7.77743 7.01121 8.0557 6.86657 8.36699 6.83041C8.41887 6.82414 8.47232 6.82098 8.52577 6.82098H12.5379H12.7816H13.0253H13.1919V4.67499C13.1935 4.24108 12.8413 3.88892 12.4074 3.88892Z" fill="#DDB87A"/>
                                                                    <path d="M13.7437 7.57264C13.6651 7.50032 13.5723 7.44529 13.4686 7.40913C13.3884 7.38242 13.3035 7.3667 13.2139 7.3667H13.1934H13.1777H12.934H12.0552H8.52729C8.09336 7.3667 7.74121 7.71885 7.74121 8.15277V8.54422V8.78791V9.03159V9.90256C7.74121 10.3365 8.09336 10.6886 8.52729 10.6886H13.1934H13.2139C13.3035 10.6886 13.3884 10.6729 13.4686 10.6462C13.5723 10.6116 13.6651 10.555 13.7437 10.4827C13.9009 10.3396 14 10.1321 14 9.90258V8.15277C14 7.92322 13.9009 7.71569 13.7437 7.57264ZM10.1686 9.18409C10.1686 9.40105 9.99253 9.57713 9.77558 9.57713H9.5146C9.29764 9.57713 9.12156 9.40105 9.12156 9.18409V8.92311C9.12156 8.79734 9.17973 8.6857 9.27249 8.61496C9.34009 8.56307 9.42343 8.53008 9.5146 8.53008H9.58063H9.77558C9.99253 8.53008 10.1686 8.70614 10.1686 8.92311V9.18409Z" fill="#DDB87A"/>
                                                                    </g>
                                                                    <defs>
                                                                    <clipPath id="clip0_22414_4384">
                                                                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
                                                                    </clipPath>
                                                                    </defs>
                                                                </svg>
                                                                <p className='s-14 ml6 c-4f'>IDR {priceSplitter(item2.salary_from*1)} - {priceSplitter(item2.salary_to*1)}</p>
                                                            </div>
                                                            <div className='flex mb-27'>
                                                            {item2.city &&
                                                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.48615 12.8074C2.70234 7.84976 2 7.34096 2 5.51895C2 3.0232 4.23857 1 7 1C9.76143 1 12 3.0232 12 5.51895C12 7.34096 11.2977 7.84976 7.51385 12.8074C7.26555 13.1316 6.73443 13.1316 6.48615 12.8074ZM7 7.40185C8.1506 7.40185 9.08333 6.55885 9.08333 5.51895C9.08333 4.47905 8.1506 3.63605 7 3.63605C5.8494 3.63605 4.91667 4.47905 4.91667 5.51895C4.91667 6.55885 5.8494 7.40185 7 7.40185Z" fill="#DDB87A"/>
                                                                </svg>}
                                                                <p className='s-14 ml6 c-4f'>{item2.city} </p>
                                                            </div>
                                                            <div className='flex width-100 space-between'>
                                                                <p className='s-14 openSans c-87 auto-00'>{setDate(item2.createdAt)}{parseInt(setDate(item2.createdAt))>1?"s":""} {Number.isFinite(parseInt(setDate(item2.createdAt)))>0?"ago":""} </p>
                                                                <a href={`/JobDetail_Public/${item2.job_opportunity_id}/${item2.job_title.split(' ').join('-')}`}>
                                                                <div className='pointer bg-36 flex br-16 p-818' >
                                                                    <p className='c-ff s-14 bold '>View Job</p>
                                                                </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                             )
                                                            }
                                                        })} 

                                                        {/* )} */}
                                                    </div>}
                                                </div>
                                            </div>
                                            </div>
                                            <div className="none canvas">
                                                
                                                    <div className="flex width-100 space-between">
                                                        <div className="company_pic">
                                                        {item.photo &&
                                                        <LazyLoadImage className='height-100 width-100 flex' effect="blur" loading="lazy" alt="" src={`${API}/File/Job_Opportunity/${item.photo}`} />}
                                                        </div>
                                                    </div>
                                                    <div className="flex width-100">
                                                    <div className="flex width-100 column">
                                                    <p className="company_name">{item.company}</p>
                                                        <p className="job_name">{item.job_title} </p>
                                                    </div>
                                                </div> 
                                                <div className="flex column width-100 salary">
                                                {item.industry &&
                                                <div className="flex">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={gold_industry} />
                                                        <p>{item.industry}</p>
                                                    </div>}
                                                    {item.job_level &&
                                                    <div className="flex">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={permanent} />
                                                        <p>{item.job_level}</p>
                                                    </div>}
                                                    <div className="flex">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={wallet} />
                                                        <p>IDR {priceSplitter(item.salary_from *1)} - {priceSplitter(item.salary_to * 1)} </p>
                                                    </div>
                                                    {item.job_type &&
                                                    <div className="flex">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={fulltime} />
                                                        <p>{item.job_type} </p>
                                                    </div>}
                                                    {item.city &&
                                                    <div className="flex">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={location} />
                                                        <p>{item.city} </p>
                                                    </div>}
                                                </div>
                                                <div className="flex width-100 ">
                                                    <div className="flec column share">
                                                        <p>Share to:</p>
                                                        {icon_share(item.job_opportunity_id,item.job_title)}
                                                    </div>
                                                </div>
                                                <div className="job_desc" >
                                                    <div className="flex column width-100">
                                                        <h5>Summary</h5>
                                                        <p style={{marginBottom:"15px"}}>{item.description}</p>
                                                    </div>
                                                </div>
                                                <div  dangerouslySetInnerHTML={{ __html: item.description_detail }} />
                                                </div>
                                        </div>
                                        )}})}
                                    </div>
																		
                                    ):(
                                        <Loading/>
                                    )}
                                </div>

                            </div>
                        </div>
                        <Modal visible={job_apply}  className="br-5">
                            <Job_Apply dataJobDetailPublic={dataJobDetailPublic} closeModal={this.closeModal} visible={job_apply} />
                        </Modal>
                        <Footer/>
                    </div>
                </div>

            </React.Fragment>
            
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      job_id: ownProps.match.params.job_id,
      job1 : state.jobDetailPublicRed,
      job : state.applyJobRed,
      jobByTitle1 : state.jobByTitleRed,
      bannerId1 : state.bannerByIdRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getJobDetailPublic: (job_id) => {
        dispatch(getJobDetailPublic(job_id));
      },
      jobByTitle: (user_id, job_title, country_id,city_id,job_function_id,industry_id,job_level_id,salary_from,salary_to,job_type) => {
        dispatch(jobByTitle(user_id, job_title, country_id,city_id,job_function_id,industry_id,job_level_id,salary_from,salary_to,job_type))
    },
    getBannerById: (banner_id) =>{
        dispatch(getBannerById(banner_id))
      },
    };
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(componentName)
  );