import React, { Component } from 'react';
import axios from 'axios';
import {API, url} from '../_redux/helper'

class Generate_Sitemap extends Component {
  constructor(){
    super()
    this.state = {
      title:["","Homepage","DSR","signup","login","FAQ","registerdsr","Talents","data-science-rockstar","accept_invitation",
    "About","Password","ContactUs","Employer","New-Employer","Talk-to-Us","Article","Hiring-Resources","Career-Resources",
     "AfterRegister","Autologin","Privacy","Terms-and-Conditions","Landing_Page","Registration","Job-Opportunity-Public","PID-Report-Static",
    "Karya_Personality_Profiler","Personality-Profiler-Stand-Alone","Survey-Complete-Stand-Alone","PID-Questions-Stand-Alone","VIP-Report-Static",
  "Values-Report-Stand-Alone","Personality_GetStarted"],
// title:["","Homepage","DSR","signup","login","FAQ","registerdsr","Talents","data-science-rockstar","accept_invitation",
// "About","Password","ContactUs","Employer","New-Employer","Talk-to-Us","Article","Hiring-Resources","Career-Resources",
//  "AfterRegister","Autologin","Privacy","Terms-and-Conditions","Landing_Page","Registration","Job-Opportunity-Public","PID-Report-Static",
// "Karya_Personality_Profiler","Personality-Profiler-Stand-Alone","Survey-Complete-Stand-Alone","PID-Questions-Stand-Alone","VIP-Report-Static",
// "Values-Report-Stand-Alone","ForgotPassword","Personality_GetStarted","MyProfile","Welcome-to-KaryaTalents","VIP_Report","Industry","JobLevel",
// "TableQuestion","JobRecommendation","QuestionMobile","ChangePassword","MyAssessment","GetStarted","AssesmentReport","MyInformation","CareerCenter",
// "PageEdu","Help","Report","Values-Profiler","JobFunction","HowToDo","Survey_Complete","Values_Complete","PID_Questions","Personality_Profiler","Values_Profiler",
// "Values_Question","Values_GetStarted","Referral","JobOpportunities","NewDashboard","DigitalLearningPlatform","Work_Assessment"]
    }
  }

  componentDidMount(){
    this.postData()
    this.getJob()
    this.getJobFunction()
    this.getArticle()
  }

  getArticle=(e)=>{
    setTimeout(() => {
      const a = []
      axios.get(`${API}/api/artikel`).then(res=>{
        res.data.map(item=>{
        this.setState({title: [...this.state.title, `Article-Detail/${item.id}/${item.judul.split(' ').join('-')}`]})
        if (item.slug==="" || item.slug==="1"){
          this.setState({title: [...this.state.title, `Career-Resources/${item.id}/${item.judul.split(' ').join('-')}`]})
        }else{ this.setState({title: [...this.state.title, `Hiring-Resources/${item.id}/${item.judul.split(' ').join('-')}`]})}
      })
      })
    }, 100);
  }

  getJob=(e)=>{
    setTimeout(() => {
      axios.get(`${API}/api/getjob_opportunity/1?job_title=&country_id=&city_id=&job_function_id=&industry_id=&job_level_id=&salary_from=&salary_to=&job_type_id=`).then(res=>{
        res.data.map(item=>{
        this.setState({title: [...this.state.title, `JobDetail_Public/${item.job_opportunity_id}/${item.job_title.split(' ').join('-')}`]})
        // this.setState({title: [...this.state.title, `JobDetail/${item.job_opportunity_id}/${item.job_title.split(' ').join('-')}`]})
        // this.setState({title: [...this.state.title, `DetailSummary/${item.job_opportunity_id}`]})
        // this.setState({title: [...this.state.title, `DetailJob/${item.job_opportunity_id}`]})
      })})
    }, 100);
  }

  getJobFunction=(e)=>{
    setTimeout(() => {
    axios.get(`${API}/api/m_job_function`).then(res=>{
      res.data.map(item=>{
        if (item.status==1){
          this.setState({title: [...this.state.title, `Job-Opportunity-Public/${item.job_function_name.split(' ').join('-')}`]})
        }
      })
    })
  },100)
  }

  postData = (e) => {
    setTimeout(() => {
      const {title} = this.state
      let a = []
      title.map((item,i)=>
      a.push({
         userId: 1,
         id: i+1,
         title:item,
         body:item
      }))
      var params = new URLSearchParams();
      params.append('json', JSON.stringify(a));
      axios.post(`${API}/api/content/json`,params)
      .then(res => {
         console.log(res,"ini res success");
      })
    }, 10000);
    
   
  };
  render() {
    const {title} = this.state
    let a = []
    title.map((item,i)=>
    a.push({
       userId: 1,
       id: i+1,
       title:item,
       body:item
    }))

    return (
      <div>
{/* <ReactJson src={a} /> */}
{JSON.stringify(a)}
</div>
    );
  }
}

export default Generate_Sitemap;


