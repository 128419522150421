import React, { Component } from "react";
import emot1 from "../images/emot1.png";
import emot2 from "../images/emot2.png";
import emot3 from "../images/emot3.png";
import emot4 from "../images/emot4.png";
import emot5 from "../images/emot5.png";
import karya from "../images/logoBaru.png";
import ExpandCollapse from "react-expand-collapse";
import mirror_zoom from "../images/search.png";
import edu from "../images/icons/edu.svg";
import bag from "../images/icons/job.svg";
import get_started from "../images/icons/get-started.svg";
import nav_top from "../images/icons/nav-top.svg";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';

export default class GetStarted extends Component {
  constructor(){
    super()
    this.state={
      emoticon_list:[{img:emot1,name:"Strongly Dislike"},{img:emot2,name:"Dislike"},{img:emot3,name:"Not Sure"},
    {img:emot4,name:"Like"},{img:emot5,name:"Strongly Like"}]
    }
  }


  goBack() {
    this.props.history.goBack();
  }
  componentDidMount(){
    sessionStorage.setItem('setupTime', new Date().getTime())
    ReactPixel.track(window.location.pathname);
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0)
  }

  render() {

    
    
    return (
      <div>
        <div className="edu">
        <div className="box-wrapper orange getStarted g_">
          <div className="wrapper" style={{marginBottom:"100px"}}>
            <div className="content">
              <div className="page-container assessment-result width-100">
                <div className="background-top width-100"></div>
                <div className="page-container-outer">
                  <div className="box-logo width-100 dekstop">
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya}  />
                    </div>
                  </div>
                  <div className="mobile-information width-100">
                  <div className="box-logo width-100">
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya} />
                    </div>
                  </div>
                  </div>
                  <div className="page-container-inner width-100">
                    <div className="box-content">
                      <div className="flex box-edu-container">
                        <div className="box-one box-edu-description mobile expandmobile">
                          <ExpandCollapse
                            previewHeight="126px"
                            expandText="wew"
                            ellipsisText=""
                          >
                            <div className="edu-desc-title width-100">
                              <div className="box-img-inner active ">
                                <div className="box-img">
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={mirror_zoom} className="small-icons" />
                                </div>
                              </div>
                              <div className="box-img-inner">
                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={edu} />
                                </div>
                              </div>
                              <div className="box-img-inner">
                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={bag} />
                                </div>
                              </div>
                            </div>
                            <div className="edu-desc-content width-100">
                              <div className="box-image">
                                <div className="background-img"></div>
                                <LazyLoadImage effect="blur" loading="lazy" src={get_started} alt="" />
                              </div>

                              <h5>Work Interest Assessment </h5>
                              <p>
                              Discover the job that fit with you right now. Follow the instructions in this page to do the assessment correctly. 
                              </p>
                            </div>
                            <div className="edu-desc-footer">
                              <Link to="/HowToDo">
                                <button type="button" className="btn btn-link">
                                  Back
                                </button>
                              </Link>
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{ display: "none" }}
                                disabled={true}
                              >
                                Next
                              </button>
                            </div>

                            <div className="edu-desc-nav width-100">
                              <div className="edu-desc-nav-img">
                                <LazyLoadImage effect="blur" loading="lazy" src={nav_top} alt="" />
                              </div>
                            </div>
                          </ExpandCollapse>
                        </div>
                      

                        <div className="box-one box-edu-description questions-desktop">
                            <div className="edu-desc-title width-100">
                              <div className="box-img-inner active ">
                                <div className="box-img">
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={mirror_zoom} className="small-icons" />
                                </div>
                              </div>
                              <div className="box-img-inner">
                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={edu} />
                                </div>
                              </div>
                              <div className="box-img-inner">
                                <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={bag} />
                                </div>
                              </div>
                            </div>
                            <div className="edu-desc-content width-100">
                              <div className="box-image">
                                <div className="background-img"></div>
                                <LazyLoadImage effect="blur" loading="lazy"  src={get_started} alt="" />
                              </div>

                              <h5>Work Interest Assessment </h5>
                              <p>
                              Discover the job that fit with you right now. Follow the instructions in this page to do the assessment correctly. 
                              </p>
                            </div>
                            <div className="edu-desc-footer">
                              <Link to="/HowToDo">
                                <button type="button" className="btn btn-link">
                                  Back
                                </button>
                              </Link>
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{ display: "none" }}
                                disabled={true}
                              >
                                Next
                              </button>
                            </div>

                            <div className="edu-desc-nav width-100">
                              <div className="edu-desc-nav-img">
                                <LazyLoadImage effect="blur" loading="lazy"  src={nav_top} alt="" />
                              </div>
                            </div>
                        </div>

                        <div className="box-two padding-box-two">
                          <div className="text-box-two">
                            <h3>
                            Welcome to Work Interest Assessment 
                            </h3>
                            <p>
                            Karya Talents uses assessment based on Holland Code Theory that is well-known for its accuracy to assess work interest. Through this assessment, you will get recommendation about the job that fit with your work interest. To get accurate result, please answer these questions by measuring whether or not you like to do the activities, not measuring whether or not you are able to do the activities. There is no right or wrong answer for this assessment. 
                            </p>
                            <p className="font-p">
                            You will need to choose the emoticon below to illustrate your preferences: 
                            </p>
                          </div>

                          <div className="emot">
                            {this.state.emoticon_list.map((item,i)=>(
                            <div className="box">
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={item.img} />
                              <p>{item.name} </p>
                            </div>))}
                          </div>
                          <div className="box-three">
                            <Link to="/TableQuestion">
                            <button onClick={ReactGA.pageview(window.location.pathname + window.location.search)}>
                              <a href="TableQuestion">Start Now!</a>
                            </button>
                            </Link>
                          </div>

                          <div  className="flex width-100">
                          <p className="linkGetstarted">These pages includes information from <p className="b">O*NET OnLine</p> by the U.S. Department of Labor, Employment and Training Administration (USDOL/ETA). Used under the <p className="b">CC BY 4.0</p> license. <p className="b">KaryaTalents</p> has modified all or some of this information. USDOL/ETA has not approved, endorsed, or tested these modifications.</p>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
