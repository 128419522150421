import { GET_QUEST, ADD_QUEST, GET_RESULT , GET_ALL_RESULT, CHECK_QUEST, GET_QUEST_PID, SUBMIT_PID,
   GET_RESULT_PID, GET_QUEST_VALUES, PUT_VALUES, GET_REPORT_VALUES, GET_LIMIT_VALUES, SUBMIT_PID_STAND_ALONE,
    GET_QUEST_PID_STAND_ALONE} from "../config/constants";
const initialState = {
  data: [],
  dataQuest:[],
  allRes:[],
  dataQuest:[],
  dataReportPID:[],
  dataValues:[],
  dataReportValues:[],
  dataLimitValues:[],
  data_SA:[],
  data_QSA:[],
  isLoading: false,
  error: false
};

export const quest = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_QUEST}_PENDING`:
      return {
        ...state,
        isLoading: true
      };
    case `${GET_QUEST}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case `${GET_QUEST}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export const answerQuest = (state = initialState, action) => {
    switch (action.type) {
      case `${ADD_QUEST}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${ADD_QUEST}_FULFILLED`:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false
        };
      case `${ADD_QUEST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const result = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_RESULT}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_RESULT}_FULFILLED`:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false
        };
      case `${GET_RESULT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const allResult = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_ALL_RESULT}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_ALL_RESULT}_FULFILLED`:
        return {
          ...state,
          allRes: action.payload.data,
          isLoading: false
        };
      case `${GET_ALL_RESULT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const checkQuestRed = (state = initialState, action) => {
    switch (action.type) {
      case `${CHECK_QUEST}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${CHECK_QUEST}_FULFILLED`:
        return {
          ...state,
          dataQuest: action.payload.data,
          isLoading: false
        };
      case `${CHECK_QUEST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const questPID = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_QUEST_PID}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_QUEST_PID}_FULFILLED`:
        return {
          ...state,
          dataQuest: action.payload.data,
          isLoading: false
        };
      case `${GET_QUEST_PID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const submitPIDRed = (state = initialState, action) => {
    switch (action.type) {
      case `${SUBMIT_PID}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${SUBMIT_PID}_FULFILLED`:
        return {
          ...state,
          data: action.payload,
          isLoading: false
        };
      case `${SUBMIT_PID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const submitPID_StandAloneRed = (state = initialState, action) => {
    switch (action.type) {
      case `${SUBMIT_PID_STAND_ALONE}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${SUBMIT_PID_STAND_ALONE}_FULFILLED`:
        return {
          ...state,
          data_SA: action.payload,
          isLoading: false
        };
      case `${SUBMIT_PID_STAND_ALONE}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const getQuestPID_StandAloneRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_QUEST_PID_STAND_ALONE}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_QUEST_PID_STAND_ALONE}_FULFILLED`:
        return {
          ...state,
          data_QSA: action.payload,
          isLoading: false
        };
      case `${GET_QUEST_PID_STAND_ALONE}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const resultPIDRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_RESULT_PID}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_RESULT_PID}_FULFILLED`:
        return {
          ...state,
          dataReportPID: action.payload,
          isLoading: false
        };
      case `${GET_RESULT_PID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const questValuesRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_QUEST_VALUES}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_QUEST_VALUES}_FULFILLED`:
        return {
          ...state,
          dataValues: action.payload,
          isLoading: false
        };
      case `${GET_QUEST_VALUES}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const putValuesRed = (state = initialState, action) => {
    switch (action.type) {
      case `${PUT_VALUES}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${PUT_VALUES}_FULFILLED`:
        return {
          ...state,
          data: action.payload,
          isLoading: false
        };
      case `${PUT_VALUES}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };
  export const reportValuesRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_REPORT_VALUES}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_REPORT_VALUES}_FULFILLED`:
        return {
          ...state,
          dataReportValues: action.payload,
          isLoading: false
        };
      case `${GET_REPORT_VALUES}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };

  export const limitValuesRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_LIMIT_VALUES}_PENDING`:
        return {
          ...state,
          isLoading: true
        };
      case `${GET_LIMIT_VALUES}_FULFILLED`:
        return {
          ...state,
          dataLimitValues: action.payload,
          isLoading: false
        };
      case `${GET_LIMIT_VALUES}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false
        };
      default:
        return state;
    }
  };