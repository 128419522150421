import React, { Component } from 'react';
import karya from "../images/logoBaru.png";
import rec from "../images/rec_New.svg"
import shadow_JobFunction from "../images/shadow_JobFunction.svg"
import bgMobile from "../images/mobile_newJourney.png"
import shadow_JobFunction_mobile from "../images/shadow_mobile_journey.png"

class Onboarding_WelcomeToKT extends Component {
    render() {
        return (
            <div>
                 <div className="edu welcome">
                    <div className="box-wrapper jobFunction">
                    <div className="wrapper " >
                        <div className="content">
                        <div className="page-container assessment-result width-100">
                            <div className="width-100 dekstop">
                            <div className="background-top width-100" style={{background:"none"}}>
                                <img effect="blur" loading="lazy" alt="" src={rec} className="width-100" />
                            </div>
                            </div>
                            <div className="width-100 mobile">
                            <div className="background-top width-100" style={{background:"none"}}>
                                <img effect="blur" loading="lazy" alt="" src={bgMobile} className="width-100" />s
                            </div>
                            </div>
                            <div className="page-container-outer">
                            <div className="box-logo width-100 dekstop" style={{marginTop:"-218px"}}>
                                <div className="box-logo-img">
                                <img effect="blur" loading="lazy" alt="" src={karya}  />
                                </div>
                            </div>
                            <div className="mobile-information width-100">
                            <div className="box-logo width-100">
                                <div className="box-logo-img">
                                <img effect="blur" loading="lazy" alt="" src={karya} />
                                </div>
                            </div>
                            </div>
                            <div className="page-container-inner width-100" style={{marginTop:"-143px"}}>
                                <div className="box-content flex column center width-100">
                                    <h4 className='s-3240 mt-32 c-29 mb-24'>Welcome to KaryaTalents, John!</h4>
                                    <p className='w-400 s-1620 c-29'>You must be so excited to develop your career.</p>
                                    <p className='w-400 s-1620 c-29 mb-41'>But first, we will show you how to get along with our platform</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Onboarding_WelcomeToKT;