import React, { Component } from 'react';
import {
    phone, mail, cv, cv_green, people, salary, skill, work_exp, education, certificate, project, industry,
    art_certification, art_personal, art_education, art_project, art_salary, art_skills, art_work_exp, pen
} from "../../_redux/svg"
import { month_year } from "../../_redux/function"


class Education extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            user_id: ""
        }
    }



    render() {
        const {  educationId } = this.props
        let visible = true
        if (educationId.length > 0){
            visible = false
        }

        return (
            <div className="h-100 bg-ff br-10 shd-input -set">
                <div className='flex column  h-100' >
                    <div className='flex p-1814 bb-ea space-between'>
                        <div className='flex'>
                            <div className='flex m-auto-00'>
                                {education}
                            </div>
                            <p className='ml-14 DM-Sans c-29 s-20 '>Education</p>
                        </div>
                        {!visible &&
                            <div className='flex pointer m-auto0' onClick={(e) => this.props.handlePopup("education_")}>
                                {pen}
                                <p className='DM-Sans s-14 c-36 ml6'>Edit</p>
                            </div>}
                    </div>
                    <div className='flex column width-100 center'>
                        {visible &&
                            <div className='flex blank-data-main width-100 h-100'>
                                <div className='flex m-2429'>
                                    {art_education}
                                </div>
                                <div className='flex column ml-29  wd-375 center mb-48 mr-10'>
                                    <p className='c-29 DM-Sans s-14 mt-48 mb-48 tx-c'>Add all the formal and informal education that you have achieved, along with any education you are currently pursuing.</p>
                                    <div className='bg-36 p-1014 sm-m-2 button-w-main br-20 wd-fit-content pointer' onClick={(e) => this.props.handlePopup("add_edu")}>
                                        <p className='s-14 c-ff DM-Sans '>Add Education</p>
                                    </div>
                                </div>
                            </div>}
                        {!visible &&
                            <div className='flex column align-left width-100 h-100'>
                                <div className='flex column m-14'>
                                    {educationId.map((item, i) => {
                                         if (i<=2){
                                            return(
                                        <div className='flex br-10 bd-d4 mb-14'>
                                            <div className='flex column p-14 width-100'>
                                                <p className='s-14 DM-Sans c-29 mb4'>{item.show_only} </p>
                                                <div className='flex m-block'>
                                                    <p className='DM-Sans c-54 s-14 '>{item.institution_name} </p>
                                                    {item.work_period_start!==null &&
                                                    <p className='DM-Sans c-36 s-14 ml4 m-w-100 s-m-0'>({month_year(item.work_period_start)} - {month_year(item.work_period_end)})</p>}
                                                </div>
                                            </div>
                                        </div>)}})}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}


export default Education;