import {GET_LAST, GET_LAST_ID,GET_LAST_LIST, PUT_LAST, DELETE_LAST, POST_LAST } from "../config/constants";
  const initialState = {
    last:[],
    lastId:[],
    deleteLast:[],
    postLast:[],
    lastList:[],
    putLast:[],
    isLoading: false,
    error: false,
  };
  
  export const getLastRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_LAST}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_LAST}_FULFILLED`:
        return {
          ...state,
          last: action.payload.data,
          isLoading: false,
        };
      case `${GET_LAST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getLastIdRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_LAST_ID}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_LAST_ID}_FULFILLED`:
        return {
          ...state,
          lastId: action.payload.data,
          isLoading: false,
        };
      case `${GET_LAST_ID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getLastListRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_LAST_LIST}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_LAST_LIST}_FULFILLED`:
        return {
          ...state,
          lastList: action.payload.data,
          isLoading: false,
        };
      case `${GET_LAST_LIST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };


  export const postLastRed = (state = initialState, action) => {
    switch (action.type) {
      case `${POST_LAST}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${POST_LAST}_FULFILLED`:
        return {
          ...state,
          postLast: action.payload.data,
          isLoading: false,
        };
      case `${POST_LAST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const putLastRed = (state = initialState, action) => {
    switch (action.type) {
      case `${PUT_LAST}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${PUT_LAST}_FULFILLED`:
        return {
          ...state,
          putLast: action.payload.data,
          isLoading: false,
        };
      case `${PUT_LAST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const delLastRed = (state = initialState, action) => {
    switch (action.type) {
      case `${DELETE_LAST}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${DELETE_LAST}_FULFILLED`:
        return {
          ...state,
          deleteLast: action.payload.data,
          isLoading: false,
        };
      case `${DELETE_LAST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  
  