import { GET_ARTICLE, ARTICLE_DETAIL, GET_FAQ, GET_ABOUT, OTHER_ARTICLE, GET_CONTACT, 
    POST_MESSAGE, GET_ALL_ARTICLE, GET_PHOTO, GET_BANNER, GET_BANNER_BY_ID } from "../config/constants";
import axios from "axios";
import {API} from '../_redux/helper'



export const getArticle = (user_id) => {
    return {
        type: GET_ARTICLE,
        payload: axios({
            method: "GET",
        url:  `${API}/api/artikel`
        })
    };
};

export const getPhoto = (photo) => {
    return {
        type: GET_PHOTO,
        payload: axios({
            method: "GET",
            url:  `${API}/File/Artikel/${photo}`
        })
    };
};

export const getDetail = (id) => {
    return {
        type: ARTICLE_DETAIL,
        payload: axios({
            method: "GET",
            url:  `${API}/api/artikel/${id}`
        })
    };
};

export const getFAQ = () => {
    return {
        type: GET_FAQ,
        payload: axios({
            method: "GET",
            url:  `${API}/api/faq`
        })
    };
};

export const getAbout = () => {
    return {
        type: GET_ABOUT,
        payload: axios({
            method: "GET",
            url:  `${API}/api/content/1`
        })
    };
};


export const getArticleExclude = (id) => {
    return {
        type: OTHER_ARTICLE,
        payload: axios({
            method: "GET",
            url:  `${API}/api/artikel?exclude_id=${id}`
        })
    };
};

export const getContact = () => {
    return {
        type: GET_CONTACT,
        payload: axios({
            method: "GET",
            url:  `${API}/api/contact/1`

        })
    };
};

export const postMessage = msg => {
    return {
        type: POST_MESSAGE,
        payload: axios({
            method: "POST",
            url:  `${API}/api/pesan`,
            data: msg,
        })
    };
};

export const getAllArticle = () => {
    return {
        type: GET_ALL_ARTICLE,
        payload: axios({
            method: "GET",
            url: `${API}/api/artikel`

        })
    };
};

export const getBanner = () => {
    var token=  sessionStorage.getItem('token')
    return {
        type: GET_BANNER,
        payload: axios({
            method: "GET",
            url: `${API}/api/banner`,
            headers: {
                Authorization: ` ${token}`,
              },
        })
    };
};

export const getBannerById = (banner_id) => {
    var token=  sessionStorage.getItem('token')
    return {
        type: GET_BANNER_BY_ID,
        payload: axios({
            method: "GET",
            url: `${API}/api/banner/${banner_id}`,
            headers: {
                Authorization: ` ${token}`,
              },
        })
    };
};