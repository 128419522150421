import React, { Component } from "react";
import karya from "../images/logoBaru.png";
import rec from "../images/rec_New.svg"
import shadow_JobFunction from "../images/shadow_JobFunction.svg"
import bgMobile from "../images/mobile_newJourney.png"
import shadow_JobFunction_mobile from "../images/shadow_mobile_journey.png"
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Session from '../components/Session'
import { getProfileId, putProfileId} from "../_actions/user";
import {getJobLevel} from "../_actions/work";
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import Loading from "../components/Loading";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


class GetStarted extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      job_level_id:"",
    }
    };


  goBack() {
    this.props.history.goBack();
  }
  componentDidMount(){
    Session.checkToken()
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    this.setState({ token: token1 });
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getJobLevel();
    this.props.getProfileId(user_id);
    sessionStorage.setItem('setupTime', new Date().getTime())
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0)
  }

  changeJobLevel = (event, values) => {
    if (this.state.job_level_id ===""){

    this.setState({job_level_id:event})
        const level = {
          user_id : this.state.user_id,
          job_level_id: event
        }
        this.props.jobLevel11(level)
        GAevent('Job Level','Post Job Level') 
        ReactPixel.track('Post Job Level - /JobLevel','Post Job Level')
      }else if (this.state.job_level_id === event){
        this.setState({job_level_id:""})
      }
    }

    
    isAgreement = (event)=>{
    //  const  user_id = this.state.user_id,
      const agree = {
        user_id : this.state.user_id,
        is_new_journey: 1
      }
      this.props.newJourney(agree)
    }
    



  render() {
    const { profile } = this.props.profile1;
    const {dataJobLevel, isLoading, error} = this.props.jobLevel1;

    let btnContinue 
    if (this.state.job_level_id==="" || this.state.job_level_id===null){
      btnContinue = <button style={{marginBottom:"24px", backgroundColor:"#BDBDBD", borderColor:"#BDBDBD"}}>
                      <p>Continue</p>
                    </button>
    }else{
      btnContinue =  <Link to="/NewDashboard">
                        <button className="pointer bg-36 br-5" style={{marginBottom:"24px"}} onClick={this.isAgreement} >
                          <p>Continue</p>
                        </button>
                    </Link>
    }

    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }

    const nama = '' + nickname
   

     // timeOut for session
     var timeSession = sessionStorage.getItem('setupTime')
     if (new Date().getTime() - timeSession > 24 * 60 * 60 * 1000 ){
         sessionStorage.clear()
         sessionStorage.removeItem("setupTime")
         window.location.href="/"
     }
     
     if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }
    
    return (
      <div>
        <div className="edu welcome">
        <div className="box-wrapper jobFunction">
          <div className="wrapper industry" >
            <div className="content jobLevel">
              <div className="page-container assessment-result width-100">
              <div className="width-100 dekstop">
                  <div className="background-top width-100" style={{background:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={rec} className="width-100" />
                  </div>
                </div>
                <div className="width-100 mobile">
                  <div className="background-top width-100" style={{background:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bgMobile} className="width-100" />
                  </div>
                </div>
                <div className="page-container-outer">
                  <div className="box-logo width-100 dekstop" style={{marginTop:"-218px"}}>
                    {/* <Link to="/"> */}
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya}  />
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="mobile-information width-100">
                  <div className="box-logo width-100">
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya}  />
                    </div>
                  </div>
                  </div>
                  <div className="page-container-inner width-100" style={{marginTop:"-143px"}}>
                    <div className="box-content flex column center width-100">
                    <div className="width-100 dekstop">
                        <LazyLoadImage effect="blur" loading="lazy" alt=""  src={shadow_JobFunction}  className="bg_func width-100"/>
                     </div>
                     <div className="width-100 mobile">
                        <LazyLoadImage effect="blur" loading="lazy" alt=""  src={shadow_JobFunction_mobile}  className="bg_func width-100"/>
                     </div>
                     <div className="content-shadow width-100">
                       <p className="first_p">JOB LEVEL</p>
                       <h1>What is your current Job Level?</h1>
                       <p className="second_p">Please select your latest job level</p>
                     </div>
                     <div className="buttons_jobFunction width-100" style={{marginTop:"24px"}}>
                       {dataJobLevel.map((item,i)=>(
                       <button className="job_item" onClick={(e)=>{this.changeJobLevel(item.id)}}  style={{color : (this.state.job_level_id===item.id? "#3B6C98":"" ||
                       (this.state.job_level_id !== "" && this.state.job_level_id !==item.id )? "#BDBDBD":""), 
                       borderColor: (this.state.job_level_id===item.id? "#3B6C98":"" ||  ((this.state.job_level_id !== "" && this.state.job_level_id !==item.id )? "#BDBDBD":"" )||
                        (this.state.job_level_id >= 1 && this.state.job_level_id !==item.id )? "white":""),
                       backgroundColor: this.state.job_level_id !=="" && (this.state.job_level_id !== item.id)? "white":""
                       }}>
                         <p>{item.job_level_name}</p>
                       </button>
                       ))}
                       {/* <button className="job_item ">
                         <p>Supervisor / Associate</p>
                       </button>
                       <button className="job_item ">
                         <p>Assistant Manager / Junior Level</p>
                       </button>
                       <button className="job_item ">
                         <p>Manager / Mid-Senior Level</p>
                       </button>
                       <button className="job_item ">
                         <p>Senior Manager / Senior Level</p>
                       </button>
                       <button className="job_item ">
                         <p>Director / Executives</p>
                       </button> */}
                      
                     </div>
                     <div className="width-100 btn_Continue">
                         {/* <Link to="/JobLevel"> */}
                       {btnContinue}
                        {/* </Link> */}
                     </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
    level : state.putProfileIdRed,
    agree : state.putProfileIdRed,
    jobLevel1 : state.jobLevelRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
    jobLevel11: (user_id) =>{
      dispatch(putProfileId(user_id))
    },
    newJourney: (user_id) =>{
      dispatch(putProfileId(user_id))
    },
    getJobLevel: () =>{
      dispatch(getJobLevel())
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);

