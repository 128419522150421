import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from 'react-ga';
import mailgo from "mailgo";


class Terms extends Component {

  componentDidMount() {
    sessionStorage.setItem('setupTime', new Date().getTime())
    if(localStorage.getItem('token')) {
      window.location.href = '/NewDashboard'
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0)
    window.history.scrollRestoration = "manual"
    mailgo();
  }

  
  render() {
  


    return (
      <div>
        <div className="box-wrapper TnC" >
          <div className="wrapper">
            <div className="content">
              <div className="page-container  master-page width-100">
                {/* <div className="banner width-100">
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={About} className="width-100 img-center" alt="" />
                </div> */}
                <div className="page-container-outer">
                  <div className="box-title iL width-100">
                    <h3 className=" width-100" style={{fontFamily:"League Spartan"}}>Terms and Conditions</h3>
                    <div className="line-horizontal width-100"></div>
                  </div>
                  <div className="companyContent">
                     <div>
                         <p>These terms and conditions outline the rules and regulations for the use of Karya Talents website.</p>
                            <br/>
                        <p>By accessing this website, Karya Talents (“we”/”us”) assume you accept these terms and conditions. Do not continue to use our online services if you do not
                           agree to take all of the terms and conditions stated on this page.</p>
                            <br/>
                        <h3>OUR SERVICE</h3>
                        <br/>
                        <p>Through Karya Talents website, we provide an online platform for employers to meet the best candidates for their companies as well as for talents  to meet their desired job position that fits right with their interest, value and personality. Our services allow you to browse information regarding career resources, 
                          digital learning and other contents relating to careers and workplaces.</p>
                        <br/>
                        <p>The services available on Karya Talents website, among others:</p>
                        <div className="flex">
                          <p className="num-li">1.</p>
                          <p>	Job Opportunities</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">2.</p>
                          <p>	VIP Assessments</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">3.</p>
                          <p>Karya Digital Learning</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">4.</p>
                          <p>Referral</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">5.</p>
                          <p>Other services that Karya Talents may add from time to time.</p>
                        </div> 
                     
                        <br/>
                        <h3>USE OF INFORMATION FROM KARYA TALENTS WEBSITE     </h3>
                        <br/>
                        <p>When accessing or using Karya Talents services, you have agreed to comply with applicable laws and obey the intellectual property rights of others. Your use of the website is governed by laws and regulations regarding copyright, trademarks, and other intellectual property rights. You agree not to upload, download, display, perform, transmit, or distribute any information or content that violates the copyright, trademark or other intellectual property or proprietary rights of any third party. You agree to comply with the laws regarding copyright and other intellectual property rights, and you will be fully responsible for any violation of the relevant law and for any violation of the rights of third parties caused by any content that you provide or transmit or otherwise provided or delivered using your Karya Talents account. In the event of a violation of this provision, you are willing to pay a
                           fine plus compensation costs in the event of material loss.</p>
                        <br/>
                        <h3>COOKIES</h3>
                        <br/>
                        <p>We employ the use of cookies. By accessing Karya Talents website,
                           you have agreed to use cookies in agreement with the Karya Talents Privacy Policy.</p>
                        <br/>
                        <h3>INTELLECTUAL PROPERTY</h3>
                        <br/>
                        <p>These provisions regarding the protection of Intellectual Property Rights apply in any region in the world:</p>
                        <div className="flex">
                          <p className="num-li">1.</p>
                          <p>Intellectual Property Rights on Karya Talents website and the materials contained therein or accessible through the website are owned by Karya Talents or its licensors. The services, the contents and other Intellectual 
                            Property Rights therein may not be copied, distributed, published, licensed, or reproduced in any way (except to the extent necessary and intended to access and use this website).</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">2.</p>
                          <p>We are the sole owner of the “Karya Talents” names, icons and logos as well as all features on the website, which are copyrights and trademarks protected by the laws of the Republic of Indonesia. It is strictly forbidden to use, modify, or post such names, icons, logos or brands without our written consent. Meanwhile, all trademarks not owned by us are the property of their respective owners, and are used with permission.</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">3.</p>
                          <p>Nothing contained in this website may be construed as granting, right, or license to use our Intellectual Property Rights for any purpose. These Intellectual Property Rights include but are not limited to: copyrights, patents, trademarks, trade names, service names, designs, databases, moral rights, knowledge, trade secrets and other confidential information.</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">4.</p>
                          <p>By uploading any of your copyright, such as profile photo, bio, and/or your CV to Karya Talents website, you give us the right to store, access and process your material for the purposes stated in Karya Talents website. For further explanation, please read our Privacy Policy.</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">5.</p>
                          <p>Karya Talents website and all contents contained therein are owned and licensed by us. It is forbidden to use this website and the contents that are owned by us without a written permission from us and/or for any reasons that are against the law.</p>
                        </div>
                        <br/>
                        <h3>LICENSE</h3>
                        <br/>
                        <p>Unless otherwise stated, Karya Talents owns the intellectual property rights for all material on Karya Talents website. All intellectual property rights are reserved. You may access Karya Talents website for your own personal use subject to restrictions set in these terms and conditions.</p>
                        <br/>
                        <h3>REGISTRATION</h3>
                        <br/>
                        <p>When you register to Karya Talents, you agree to provide us with your most accurate information such as your first and last name, phone number, email and mailing addresses, professional title, company name, employment history, education history, and personal summary. We will then review the information that you provide us during the registration process and any other information that is publicly available. If you identify or suspect any service security violations, please let us know as soon as possible.</p>
                        <br/>
                        <h3>FEEDBACK AND INQUIRIES</h3>
                        <br/>
                        <p>Should you have any questions , feedback or further inquiries regarding our terms
                            and conditions, you can reach us by sending us an e-mail at <a style={{color:"#3B6C98"}} className="tc-support" href={`mailto:support@karyatalents.com`}> support@karyatalents.com.</a></p>
                        <br/>
                        <p>We will help clarify any parts of the terms and guide you through our working process</p>
                        <br/>
                        <h3>DISCLAIMER OF THE CONTENT</h3>
                        <br/>
                        <p>We are not responsible for the accuracy of the content on this website. You assume all risks from viewing, reading, using, or relying on the information contained on Karya Talents website. At your discretion, it is strongly recommended that you make an independent assessment with respect to its accuracy, usability and/or suitability before making any decisions and beliefs regarding the content contained on the Platform. Unless there is a written agreement from us which states otherwise,
                           you are not entitled to rely on all information contained in these services as accurate information. While we try to display good and proper content, We do not guarantee the accuracy of these things.</p>
                        <br/>
                        <h3>DISCLAIMER OF ANY DAMAGE TO YOUR DEVICE OR FROM INTERACTION WITH KARYA TALENTS WEBSITE OR THE CONTENT THEREOF</h3>
                        <br/>
                        <p>We are not responsible for damage to your device(s) or software or any party that has subsequently communicated with you from damaged code and/or data that has been accidentally passed to your device(s). You may view and interact with our website, banner and/or pop-ups for the advertisements displayed at your own risk. However, we will do our best to prevent, correct, and help resolve technical problems that may arise due to code errors or entry attempts that are prohibited from other parties.</p>
                        <br/>
                        <h3>LIMITATION OF LIABILITY</h3>
                        <br/>
                        <div className="flex">
                          <p className="num-li">1.</p>
                          <p>By viewing, using, or interacting with this site, including banners, advertisements, or pop-ups, downloads, and as a condition for viewing Karya Talents website, for an unlimited period of time, you have released us from all claims for any damages based on causal factors and anything that results in possible losses, whether direct 
                             consequential losses, predictable or unforeseen, both personal and business.</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">2.</p>
                          <p>You fully agree to indemnify and defend us, our officers, directors, employees, agents and vendors, directly without being asked, from and against all claims, liabilities, damages, losses, costs and expenses, including reasonable legal fees, which arises because of a violation of the Terms and Conditions by you or other obligations arising from your use of this Platform, or use by other people
                             who access the Platform using your device and/or your personal information.</p>
                        </div>
                        <br/>
                        <h3>DISPUTES</h3>
                        <br/>
                        <div className="flex">
                          <p className="num-li">1.</p>
                          <p>As part of the website requirements to view, use, or interact with this Platform, the User agrees to use a binding litigation mechanism for any claims, disputes, or controversies in any form (whether in a contract, illegal act or other) arising out of or in connection with this purchase, this product, including request issues,
                             privacy issues, and terms of use issues.</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">2.</p>
                          <p>Litigation dispute resolution will be carried out in accordance with the prevailing laws and regulations in the country of Indonesia.</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">3.</p>
                          <p>There are no conditions under which the User has the right to use arbitration or to use a jury trial. The losing party will be responsible to the other Party for all costs related to dispute resolution, including court fees, attorney fees, billing fees, investigation fees and travel expenses. </p>
                        </div>
                        <br/>
                        <h3>JURISDICTION AND VENUE</h3>
                        <br/>
                        <p>In the event that there is a dispute related to this purchase, it will be tried in the District Court of Central Jakarta. You agree that the dispute will be tried in the District Court of the choice specified in these Terms and Conditions.</p>
                        <br/>
                        <h3>APPLICABLE LAW</h3>
                        <br/>
                        <p>The user agrees that the law that applies in all conditions is the law in force in the Republic of Indonesia, including:</p>
                        <div className="flex">
                          <p className="num-li">a.</p>
                          <p >Law Number 19 of 2016 concerning Electronic Information and Transactions (UU ITE) and its amendments.</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">b.</p>
                          <p>Government Regulation Number 80 of 2019 concerning Trading Using Electronic Systems (PP PMSE).</p>
                        </div>
                        <div className="flex">
                          <p className="num-li">c.</p>
                          <p>Other related laws and regulations.</p>
                        </div>
                       
                     </div>
                  </div>
                  {/* </Fade> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
