import { FIND_EMAIL, USER_COURSE_DETAIL} from "../config/constants";
import axios from "axios";
import {API} from '../_redux/helper'


export const findEmail = (email) => {
    return {
        type: FIND_EMAIL,
        payload: axios({
            method: "GET",
        url:  `${API}/api/dlp/find/email/${email}`
        })
    };
};

export const userCourseDetail = (data) => {
    return {
        type: USER_COURSE_DETAIL,
        payload: axios({
            method: "GET",
        url:  `${API}/api/getusercoursedetail_by_course/${data.user_id}/${data.course_id}`
        })
    };
};