import React, { Component } from 'react';
import {phone,mail,cv,cv_green, people, salary, skill, work_exp, education, certificate, project,industry,
    art_certification,art_personal, art_education,art_project,art_salary,art_skills,art_work_exp, pen} from "../../_redux/svg"
import {month_year, list_month} from "../../_redux/function"

class Certification extends Component {
    constructor(){
        super()
        this.state={
            visible:false,
            user_id:""
        }
    }



    render() {
        let visible= true
        const {certificationId} = this.props
        if (certificationId.length>0){
            visible = false
        }

        return (
            <div className="h-100 bg-ff br-10 shd-input -set">
                <div className='flex column  h-100' >
                    <div className='flex p-1814 bb-ea space-between'>
                        <div className='flex'>
                            <div className='flex m-auto-00'>
                                {certificate}
                            </div>
                            <p className='ml-14 DM-Sans c-29 s-20 '>Certification {"&"} Trainings</p>
                        </div>
                        {!visible &&
                        <div className='flex pointer m-auto0' onClick={(e)=>this.props.handlePopup("certification_")}>
                            {pen}
                            <p className='DM-Sans s-14 c-36 ml6'>Edit</p>
                        </div>}
                    </div>
                    <div className='flex column width-100 center'>
                    {visible &&
                        <div className='flex blank-data-main  width-100 hg-100'>
                            <div className='flex m-2429'>
                                {art_certification}
                            </div>
                            <div className='flex column ml-29  wd-375 center mb-48 mr-10'>
                                <p className='c-29 DM-Sans s-14 mt-48 mb-48 tx-c'>Showcase certified competency that you have achieved to support your career.</p>
                                <div className='bg-36 p-1014 sm-m-2 button-w-main br-20 wd-fit-content pointer' onClick={(e)=>this.props.handlePopup("add_certification")}>
                                    <p className='s-14 c-ff DM-Sans '>Add Certification {"&"} Trainings</p>
                                </div>
                            </div>
                        </div>}
                        {!visible &&
                        <div className='flex column align-left width-100 hg-100'>
                            <div className='flex column m-14'>
                                {certificationId.map((item,i)=>{
                                     if (i<=2){
                                        return(
                                <div className='flex br-10 bd-d4 mb-14'>
                                    <div className='flex column p-14 width-100'>
                                        <p className='s-14 DM-Sans c-29 mb4'>{item.title}  </p>
                                        <p className='DM-Sans c-36 s-14 '>{list_month[item.month-1].substring(0,3)} {item.year} </p>
                                    </div>
                                </div>
                                )}})}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}


  export default Certification;