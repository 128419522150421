import React, { Component } from "react";
import logo from "../images/KaryaTalents_logo.png";
import { connect } from "react-redux";
import SidebarMiddle from "../components/SidebarMiddle";
import bell from "../images/bell.png";
import v from "../images/v.png";
import help from "../images/help-icon.png";
import setting from "../images/setting-icon.png";
import logout from "../images/logout-icon.png";
import { API } from "../_redux/helper";
import axios from 'axios';
import {getProfileId,putProfileId} from "../_actions/user";
import {getResultPID} from "../_actions/quest"
import {  fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import iOpenSidebar from "../images/iOpenSidebar.png";
import iDashboard from "../images/iDashboard.png";
import iVIP from "../images/iVIP.svg";
import iCareer from "../images/iCareer.png";
import iReferral from "../images/iReferral.png";
import iWork from "../images/iWork.png";
import iMyProfile from "../images/iMyprofile.png";
import iLearning from "../images/iLearning.png";
import iTraining from "../images/iTraining.png";
import closeSidebar from "../images/closeSidebar.svg"
import human from "../images/person.svg"
import bg_tooltip_bottom from "../images/tooltip_bg_bottom.svg"
import tooltip_career from "../images/tooltip_career.svg"
import tooltip_job from "../images/tooltip_job.svg"
import tooltip_referral from "../images/tooltip_referral.svg"
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import _NewDashboard from "../components/_NewDashboard"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Session from '../components/Session'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";
import Account from "../components/Account"
import LeftBar from "../components/LeftBar";
import Tooltip from "../components/Dashboard/Tooltip";

const styles = {
  fadeInDown: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
    marginTop: "-86px",
  },
};

class NewHeader extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: JSON.parse(sessionStorage.getItem("sidebar")),
      var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
      visible: true,
      tooltip_bottom :false,
      tooltip_top :false,
      height:"",
      tooltip:"",
      var_report: sessionStorage.getItem("report"),
      rating:"",
      ratting:0,
      comment:"",
      desc_ratting:"",
      rating_vip:"",
      rating_interest:"",
      var_report2:"",
      link: JSON.parse(sessionStorage.getItem("sidebar")),
    };
  }

  async componentDidMount() {
    Session.checkToken()
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    if(token1) {
      localStorage.setItem('token', token1)
      localStorage.setItem('logout', false)
    }
    var tooltip_val = sessionStorage.getItem("tooltip");
    this.setState({ tooltip: tooltip_val });
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.props.getResultPID(user_id)
    window.scrollTo(0, 0)
    this.setState({height:window.innerHeight})
    ReactPixel.track(window.location.pathname);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if (window.innerWidth < 1440 && (prevState.tooltip_bottom !== true)){
      this.setState({tooltip_bottom:true})
    }
    if (window.innerWidth > 1440 && (prevState.tooltip_top !== true)){
      this.setState({tooltip_top:true})
    }
    if (this.props.data_pid.dataReportPID.data !== undefined &&  (this.state.var_report2== "")){
      const res = this.props.data_pid.dataReportPID.data
      if (res==="Data Not Found"){
        sessionStorage.setItem("report", res.answers);
        this.setState({var_report2 : res.answers})
      }else{
        sessionStorage.setItem("report", res.answers.length);
        this.setState({var_report2 : res.answers.length})
      }}
  }

  changeVisible = () => {
    this.setState({ visible: !this.state.visible });
  };
  away = () => {
    this.setState({ visible: true });
  };

  logout=()=>{
    Session.removeSessionLogout()
  }
  tooltip1 = () => {
    this.setState({ tooltip: 2 });
  };
  tooltip2 = () => {
    this.setState({ tooltip: 3 });
  };
  tooltip3 = () => {
    this.setState({ tooltip: 4 });
  };
  tooltip4 = () => {
    this.setState({ tooltip: 5 });
  };
  tooltip5 = () => {
    this.setState({ tooltip: 6 });
  };
  tooltip6 = () => {
    const data_tooltip = {
      user_id : this.state.user_id,
      is_tooltip : 1,
    }
    this.props.putProfile11(data_tooltip);
    sessionStorage.removeItem("tooltip");
    this.setState({ tooltip: "" });
  };
  tooltip7 = () => {
    this.setState({ tooltip: 8 });
  };
  tooltip8 = (e) => {
    const data_tooltip = {
      user_id : this.state.user_id,
      is_tooltip : 1,
    }
    this.props.putProfile11(data_tooltip);
    sessionStorage.removeItem("tooltip");
    this.setState({ tooltip: "" });
  };


  skip = () => {
    const data_tooltip = {
      user_id : this.state.user_id,
      is_tooltip : 1,
    }
    this.props.putProfile11(data_tooltip);
    sessionStorage.removeItem("tooltip");
    this.setState({ tooltip: "" });
  };

  
  changeBackground = () => {
    this.setState({
      link: !this.state.link,
    });
    sessionStorage.setItem("sidebar",!this.state.link)
  };


  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }

  handleCallback = (childData) =>{
    this.setState({link: !childData})
}

 

  render() {
    const { profile } = this.props.profile1;

    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }

    const nama = '' + nickname
    const name = nama.split(" ")[0]


    return (
      <div>
        <div className="block HC-pages" style={{top:"0px"}}>
          <div className="NewHeader" style={{position:"unset", top:"0px"}}>
          <Account profile={profile}/>
            <div className="flex">
            <LeftBar parentCallback = {this.handleCallback}/>
             <Tooltip profile={profile} user_id={profile.user_id} />

            {/* CONTENT */}
            <div className="cubo width-100"  >
              <div className="block">
                <SidebarMiddle />
              </div>
                <_NewDashboard ownProps={profile} />
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
    data_tooltip: state.putProfileIdRed,
    data_pid: state.resultPIDRed
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
    putProfile11: (user_id) =>{
      dispatch(putProfileId(user_id))
    },
    getResultPID: (user_id) =>{
      dispatch(getResultPID(user_id))
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
