import React, { Component } from "react";
import "./css/fonts.css";
import "./css/dekstop.css";
import "./css/tablet.css";
import "./css/style-desktop.css";
import "./css/style-tablet.css";
import "./css/style-mobile.css";
// import "./css/animation.scss"
import HomepageNew from "./pages/Talents";
import Home from "./pages/Homepage_DSR";
import DSRForm from "./pages/Registration_Form_DSR";
import Question from "./components/Question";
import QuestionMobile from "./components/QuestionMobile";
import GetStarted from "./pages/GetStarted";
import FAQ from "./pages/FAQ";
import MyAssessment from "./pages/MyAssessment";
import MyProfile from "./pages/MyProfile";
import Autologin from "./components/Autologin";
import DetailJob from "./pages/DetailJob";
import DetailSummary from "./pages/DetailSummary";
import JobRecommendation from "./pages/JobRecommendation";
import AssesmentReport from "./pages/AssesmentReport";
import PageEdu from "./pages/PageEdu";
import Article from "./pages/Article";
import OtherArticle from "./pages/OtherArticle";
import About from "./pages/About";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import ContactUs from "./pages/ContactUs";
import TableQuestion from "./pages/TableQuestion";
import AfterRegister from "./components/AfterRegister";
import Password from "./components/Password";
import ChangePassword from "./pages/ChangePassword";
import Share from "./components/Share";
import Sticky from "./components/Sticky";
import Privacy from "./pages/Privacy";
import Signin_By_Google from "./components/Login_And_Register/Signin_By_Google";
import Terms from "./pages/Terms";
import Registration from "./pages/Registration";
import Generate_Sitemap from "./components/Generate_Sitemap";
import Welcome from "./pages/Welcome";
import New_Employer from "./pages/New_Employer";
import Advisory from "./pages/Advisory";
import Academy from "./pages/Academy";
import Assessment from "./pages/Assessment";
import About_Acquisition from "./pages/About_Acquisition";
import LinkToShare from "./components/LinkToShare";
import VIP_Report from "./components/VIP_Report";
import Testradio from "./components/Testradio";
import Notif_Job_Applied from "./components/Notif_Job_Applied";
import BurgerDashboard from "./components/BurgerDashboard";
import ForgotPassword from "./components/ForgotPassword";
import D_Course from "./components/D_Course";
import NewDashboard from "./pages/NewDashboard";
import CareerCenter from "./pages/CareerCenter";
import DigitalLearningCenter from "./pages/DigitalLearningCenter";
import DigitalLearningCenterDetailCourse from "./pages/DigitalLearningCenterDetailCourse";
import DigitalLearningCenterQuizCourse from "./pages/DigitalLearningCenterQuizCourse";
import DigitalLearningCenterAssessmentQuiz from "./pages/DigitalLearningCenterAssessmentQuiz";
import JobDetail from "./pages/JobDetail";
import JobFunction from "./pages/JobFunction";
import Industry from "./pages/Industry";
import JobLevel from "./pages/JobLevel";
import Landing_Page from "./pages/Landing_Page";
import JobDetail_Public from "./pages/JobDetail_Public";
import JobOpportunity_Public from "./pages/JobOpportunity_Public";
import Report from "./pages/Report";
import ReportValues from "./pages/ReportValues";
import PID_Questions from "./pages/PID_Questions";
import GetStarted_Stand_Alone from "./pages/GetStarted_Stand_Alone";
import PID_Question_Stand_Alone from "./pages/PID_Question_Stand_Alone";
import Personality_GetStarted from "./pages/Personality_GetStarted";
import Values_Profiler from "./pages/Values_Profiler";
import Values_Question from "./pages/Values_Question";
import Values_GetStarted from "./pages/Values_GetStarted";
import Survery_Complete from "./pages/Survey_Complete";
import Maintenance from "./pages/Maintenance";
import Survey_Complete_Stand_Alone from "./pages/Survey_Complete_Stand_Alone";
import Values_Complete from "./pages/Values_Complete";
import Karya_Learning_Program from "./pages/Karya_Learning_Program";
import ProgramDetail from "./pages/ProgramDetail";
import TalkToUs from "./pages/TalkToUs";
import Values_R from "./pages/Values_Report";
import VIP_Report_ID from "./pages/VIP_Report_ID";
import PID_Report_Dummy from "./pages/PID_Report_Dummy";
import PID_Report_Stand_Alone from "./pages/PID_Report_Stand_Alone";
import Values_Report_Static from "./pages/Values_Report_Static";
import Homepage from "./pages/Homepage";
import _Report from "./components/_Report";
import _Report_Team from "./components/_Report_Team";
import Scroll from "./components/Scroll";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import AuthLayout from "./components/AuthLayout";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import Onboarding_WelcomeToKT from "./pages/Onboarding_WelcomeToKT";
import { Helmet, HelmetProvider } from "react-helmet-async";

// const disabled = !user.allowsThirdPartyCookies();
// LinkedInTag.init(partnerId, 'dc', disabled);
// LinkedInTag.track(conversionId);
LinkedInTag.init("3282308", "dc");

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const advancedMatching = { em: "nuriindayani44@gmail.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true,
  debug: false, // enable logs
};

ReactPixel.init("3984021548373007", advancedMatching, options);
window.snapSaveState = () => {
  document.querySelector("#app").setAttribute("data-server-rendered", "true");
};

class App extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <HelmetProvider>
        <div className="App"></div>
        <React.Fragment>
          <Router history={history}>
            <div>
              <Switch>
                <Route exact path="/" component={Homepage} />
                <Route path="/Homepage" component={Homepage} />
                <Route path="/DSR" component={Home} />
                <Route path="/SignUp" component={SignUp} />
                <Route path="/Login" component={Login} />
                <Route path="/Generate" component={Generate_Sitemap} />
                <Route path="/registerdsr" component={DSRForm} />
                <Route path="/data-science-rockstar" component={Home} />
                <Route path="/Talents" component={HomepageNew} />
                <Route path="/Homepage1" component={Homepage} />
                <Route path="/accept_invitation" component={HomepageNew} />
                <Route path="/new-homepage" component={HomepageNew} />
                <Route path="/FAQ" component={FAQ} />
                <Route path="/About" component={About} />
                <Route path="/Maintenance" component={Maintenance} />
                <Route path="/Password" component={Password} />
                <Route path="/ContactUs" component={ContactUs} />
                <Route path="/Employer" component={New_Employer} />
                <Route path="/New-Employer" component={New_Employer} />
                <Route path="/Advisory" component={Advisory} />
                <Route path="/Academy" component={Academy} />
                <Route path="/Assessment" component={Assessment} />
                <Route path="/Talk-to-Us" component={TalkToUs} />
                <Route path="/Article" component={Article} />
                <Route path="/Hiring-Resources" component={Article} />
                <Route path="/Career-Resources" component={Article} />
                <Route path="/LinkToShare/:user_id" component={LinkToShare} />
                <Route
                  path="/Interest_Profiler2/:user_id"
                  component={LinkToShare}
                />
                <Route path="/Share" component={Share} />
                <Route
                  path="/Career-Resources/:id/:name"
                  component={OtherArticle}
                />
                <Route
                  path="/Article-Detail/:id/:name"
                  component={OtherArticle}
                />
                <Route path="/AfterRegister" component={AfterRegister} />
                <Route path="/Autologin" component={Autologin} />
                <Route path="/Testradio" component={Testradio} />
                <Route path="/Sticky" component={Sticky} />
                <Route path="/Privacy" component={Privacy} />
                <Route path="/Terms-and-Conditions" component={Terms} />
                <Route path="/Landing_Page" component={Landing_Page} />
                <Route path="/Registration" component={Registration} />
                <Route
                  path="/Karya_Learning_Program/:id"
                  component={Karya_Learning_Program}
                />
                <Route path="/ProgramDetail/:id" component={ProgramDetail} />
                <Route path="/_Report_Team/:id" component={_Report_Team} />
                <Route path="/_Report_Team2/:id" component={_Report_Team} />
                <Route path="/Values_Report/:id" component={Values_R} />
                <Route path="/Values_Report2/:id" component={Values_R} />
                <Route
                  path="/Job-Opportunity-Public"
                  component={JobOpportunity_Public}
                />
                <Route
                  path="/About-Acquisition"
                  component={About_Acquisition}
                />
                <Route
                  path="/Job-Opportunity-Public/:job_function"
                  component={JobOpportunity_Public}
                />
                {/* <Route path="/VIP_Report/:id" component={VIP_Report_ID} /> */}
                <Route path="/VIP_Report/:id" component={VIP_Report_ID} />
                <Route path="/VIP_Report2/:id" component={VIP_Report_ID} />
                <Route path="/PID-Report-Static" component={PID_Report_Dummy} />
                <Route
                  path="/Karya_Personality_Profiler"
                  component={GetStarted_Stand_Alone}
                />
                <Route
                  path="/Personality-Profiler-Stand-Alone"
                  component={PID_Report_Stand_Alone}
                />
                <Route
                  path="/Survey-Complete-Stand-Alone"
                  component={Survey_Complete_Stand_Alone}
                />
                <Route
                  path="/PID-Questions-Stand-Alone"
                  component={PID_Question_Stand_Alone}
                />
                <Route path="/VIP-Report-Static" component={VIP_Report_ID} />
                <Route
                  path="/Values-Report-Stand-Alone"
                  component={Values_Report_Static}
                />
                <Route path="/ForgotPassword" component={ForgotPassword} />
                <Route
                  path="/JobDetail_Public/:job_id/:job_title"
                  component={JobDetail_Public}
                />
                <Route
                  path="/Personality_GetStarted"
                  component={Personality_GetStarted}
                />
                <Route
                  path="/About_Acquisition"
                  component={About_Acquisition}
                />
                <ProtectedRoute
                  path="/MyProfile"
                  component={AuthLayout(MyProfile)}
                />
                <ProtectedRoute path="/new" component={AuthLayout(MyProfile)} />
                <ProtectedRoute
                  path="/Welcome-to-KaryaTalents"
                  component={AuthLayout(Welcome)}
                />
                <ProtectedRoute
                  path="/D_Course"
                  component={AuthLayout(D_Course)}
                />
                <ProtectedRoute
                  path="/VIP_Report"
                  component={AuthLayout(VIP_Report)}
                />
                <ProtectedRoute
                  path="/Industry"
                  component={AuthLayout(Industry)}
                />
                <ProtectedRoute
                  path="/JobLevel"
                  component={AuthLayout(JobLevel)}
                />
                <ProtectedRoute
                  path="/TableQuestion"
                  component={AuthLayout(TableQuestion)}
                />
                <ProtectedRoute
                  path="/JobRecommendation"
                  component={AuthLayout(JobRecommendation)}
                />
                <ProtectedRoute
                  path="/QuestionMobile"
                  component={AuthLayout(QuestionMobile)}
                />
                <ProtectedRoute
                  path="/ChangePassword"
                  component={ChangePassword}
                />
                <ProtectedRoute
                  path="/DetailSummary/:job_id"
                  component={AuthLayout(DetailSummary)}
                />
                <ProtectedRoute
                  path="/DetailJob/:job_id"
                  component={AuthLayout(DetailJob)}
                />
                <ProtectedRoute
                  path="/MyAssessment"
                  component={AuthLayout(MyAssessment)}
                />
                <ProtectedRoute
                  path="/GetStarted"
                  component={AuthLayout(GetStarted)}
                />
                <ProtectedRoute
                  path="/AssesmentReport"
                  component={AuthLayout(AssesmentReport)}
                />
                <ProtectedRoute
                  path="/MyInformation"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute
                  path="/CareerCenter"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute
                  exact
                  path="/DigitalLearningCenter/:program_id/CourseOutline/AssessmentQuiz"
                  component={AuthLayout(DigitalLearningCenterAssessmentQuiz)}
                />
                <ProtectedRoute
                  exact
                  path="/DigitalLearningCenter/:program_id/:tabs"
                  component={AuthLayout(DigitalLearningCenter)}
                />
                <ProtectedRoute
                  exact
                  path="/DigitalLearningCenter/:program_id/:course_id/CourseOutline/:nameTitleCourse/:nameCourse"
                  component={AuthLayout(DigitalLearningCenterQuizCourse)}
                />
                <ProtectedRoute
                  exact
                  path="/DigitalLearningCenter/:program_id/:course_id/CourseOutline/:nameTitleCourse/:nameCourse/:index"
                  component={AuthLayout(DigitalLearningCenterDetailCourse)}
                />
                <ProtectedRoute
                  path="/PageEdu"
                  component={AuthLayout(PageEdu)}
                />
                <ProtectedRoute
                  path="/Education-and-Work-Experience"
                  component={AuthLayout(PageEdu)}
                />
                <ProtectedRoute
                  path="/Help"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute path="/Report" component={AuthLayout(Report)} />
                <ProtectedRoute
                  path="/Values-Profiler"
                  component={AuthLayout(ReportValues)}
                />
                <ProtectedRoute
                  path="/JobFunction"
                  component={AuthLayout(JobFunction)}
                />
                <ProtectedRoute
                  path="/HowToDo"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute
                  path="/Survey_Complete"
                  component={Survery_Complete}
                />
                <ProtectedRoute
                  path="/Values_Complete"
                  component={Values_Complete}
                />
                <ProtectedRoute
                  path="/PID_Questions"
                  component={AuthLayout(PID_Questions)}
                />
                <ProtectedRoute
                  path="/Personality_Profiler"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute
                  path="/Values_Profiler"
                  component={AuthLayout(Values_Profiler)}
                />
                <ProtectedRoute
                  path="/Values_Question"
                  component={AuthLayout(Values_Question)}
                />
                <ProtectedRoute
                  path="/Values_GetStarted"
                  component={AuthLayout(Values_GetStarted)}
                />
                <ProtectedRoute path="/Referral" component={CareerCenter} />
                <ProtectedRoute
                  path="/JobOpportunities"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute
                  path="/NewDashboard"
                  component={AuthLayout(NewDashboard)}
                />
                <ProtectedRoute
                  path="/BurgerDashboard"
                  component={AuthLayout(BurgerDashboard)}
                />
                <ProtectedRoute
                  path="/DigitalLearningPlatform"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute
                  path="/Work_Assessment"
                  component={AuthLayout(CareerCenter)}
                />
                <ProtectedRoute
                  path="/_Report"
                  component={AuthLayout(_Report)}
                />
                <ProtectedRoute
                  path="/JobDetail/:job_id/:job_title"
                  component={JobDetail}
                />
              </Switch>
            </div>
            {/* </Scroll> */}
          </Router>
        </React.Fragment>
      </HelmetProvider>
    );
  }
}

export default App;
