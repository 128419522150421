import React, { Component } from "react";
import { connect } from "react-redux";
import ire11 from "../images/ire11.png";
import download from "../images/Unduh.svg";
import print from "../images/Cetak.svg";
import share from "../images/Bagikan.svg";
import Loading from "../components/Loading";
import { getResult, getAllResult, getReportValues } from "../_actions/quest";
import Share from '../components/Share'
import { Link } from "react-router-dom";
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import Scrollbar from 'react-scrollbars-custom';
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReactDOM from "react-dom";
import axios from 'axios';
import Modal from "react-awesome-modal";
import {conv,inves,real} from "../images/svg/svg"
import Rating from '@material-ui/lab/Rating';
import dlc_close from "../images/dlc_close.svg"
import { API } from '../_redux/helper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from "react-facebook-pixel"



class AssesmentReport extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      showStore:false,
      sizeStore:false,
      popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
      popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
      popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
      popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
      popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
      ratting:0,
      desc_ratting:"",
      comment:"",
      rating:"",
      show_modal: "",
      redirectLink: "",
      urlPopup: "",
      dataPopup: {},
      keyPopup: "",
      rate:"",
      list_img :[conv, inves,real],
      desc:"",
      resVIP: '',
    };
  }

  openModal(e) {
    /* eslint eqeqeq: 0 */
    if (e == 'link') {
        this.setState({
            link: true
        });
    }
}

closeModal() {
    this.setState({
        link: false,
    });
}

  componentDidMount() {
    window.scrollTo(0, 0)
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getResult(user_id);
    this.props.getAllResult(user_id);
    this.props.getReportValues(user_id)
    axios.get(`${API}/api/survey/findsubmission/${user_id}`)
      .then(response => {
          this.setState({resVIP:response.data})
      })
    ReactGA.pageview(window.location.pathname + window.location.search);
    sessionStorage.setItem('kuesionerconfirmed', true)
    // sessionStorage.setItem('setupTime', new Date().getTime())
  }

  download = () => {
    const link = document.createElement("a");
    link.href = `/Testradio.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  print() {
    window.print("/");
    GAevent('MyAssessment','Print') 
    ReactPixel.track('Print - MyAssessment','Submit Data'); 
  }

  
  printDocument= () => {   
    html2canvas(document.querySelector("#container-print")).then(canvas => {
      document.body.appendChild(canvas);  // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save("download.pdf"); 
  });
  GAevent('MyAssessment','Print') 
  }  

  //PDF TERBARU
  exportPDFWithMethod = () => {
    savePDF(ReactDOM.findDOMNode(this.container), {
        paperSize: "auto",
        margin: 40,
        fileName: `Report for ${new Date().getFullYear()}`
    });
    GAevent('MyAssessment','Download') 
  };

  
  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
    this.setState({ showStore: true });
    this.setState({ sizeStore: true });
    GAevent('MyAssessment','Download') 
    ReactPixel.track('Download PDF - MyAssessment','Personality Profiler'); 
    setTimeout(() => {
      window.location.reload()
    }, 2000);
   
  };

  changeRatting=(e)=>{
    this.setState({ratting:Number((e.target.textContent).split('')[0])})
    let val
    if ((e.target.textContent).split('')[0]==1){
      val = "Very Bad"
    }else if ((e.target.textContent).split('')[0]==2){
      val = "Bad"
    }else if ((e.target.textContent).split('')[0]==3){
      val = "Need Improvement"
    }else if ((e.target.textContent).split('')[0]==4){
      val = "Good"
    }else{
      val = "Very Good"
    }

    this.setState({desc_ratting:val})
    this.state.rate = this.state.ratting
    this.state.desc = this.state.desc_ratting
  }

  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }



  takeAssessment=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-interest"))==true){
      window.location.href="#"
      this.setState({popup_interest:"true"})
   }else{
      window.location.href="/TableQuestion"
  }
  }

  onChangeRedirect = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "", comment_interest: ""}, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting_vip: "", comment_vip: "" }]
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        show_modal: "true",
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      
    } else {
      window.location.href = `/${this.state.redirectLink}`
    }
    
  }
  submitRatingAll = () => {
    this.state.dataPopup[Object.keys(this.state.dataPopup)[0]] = this.state.rate
    this.state.dataPopup[Object.keys(this.state.dataPopup)[1]] = this.state.comment

    axios.post(this.state.urlPopup, this.state.dataPopup)
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
          window.location.reload()
      }).catch((err)=>{
          console.log(err,"error");
      })

  }
  _onKeyDown=(e)=>{
    if (e.key === 'Enter') {
      this.submitRatingAll()
    }
  }

  closeRatingAll = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "-", comment_interest: ""}, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting_vip: "-", comment_vip: "" }]
    let data_rating = []
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        show_modal: "true",
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      data_rating.push(arr[checkIndex])
    }  

    axios.post(this.state.urlPopup,data_rating[0])
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
          window.location.reload()
      }).catch((err)=>{
          console.log(err,"error");
      })

  }

  takeNewAssessment=()=>{
    GAevent('MyAssessment','Take New Assessment')
    ReactPixel.track('Take New Assessment - MyAssessment'); 
  }


  render() {
    const { data, isLoading, error } = this.props.result1;
    const { allRes } = this.props.result2;
    
    
    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }


    const  profile1  = this.props.ownProps;
        const {dataReportValues} = this.props.dataReportValues1
        let n_val
        dataReportValues.data != undefined && dataReportValues.data.length > 0 ?
        dataReportValues.data.map((item,i)=>{
            if (i==0){
                n_val = item.persen_score
            }
        }):console.log("error");


       let val_interest 
       allRes.map((item,i)=>{
           if (i==0){
               val_interest = item.scoring
           }
       })

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

       let bt_next
        if (!profile1.is_work_assessment){
            
          bt_next = <Link onClick={() => this.state.redirectLink = 'values_profiler' }>
                            <button className='flex ret center bg-47' >
                                <p>Take Next Assessment</p>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.03033 3.96969C7.81583 3.75519 7.49324 3.69103 7.21299 3.80711C6.93273 3.9232 6.75 4.19668 6.75 4.50002V13.5C6.75 13.8034 6.93273 14.0768 7.21299 14.1929C7.49324 14.309 7.81583 14.2449 8.03033 14.0304L12.5303 9.53035C12.8232 9.23746 12.8232 8.76258 12.5303 8.46969L8.03033 3.96969Z" fill="white"/>
                                </svg>
                            </button>
                        </Link>
        } else if(val_interest < 0) {
          bt_next = <Link onClick={() => this.state.redirectLink = 'Howtodo' }>
                            <button className='flex ret center bg-47' >
                              <p>Take Next Assessment</p>
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.03033 3.96969C7.81583 3.75519 7.49324 3.69103 7.21299 3.80711C6.93273 3.9232 6.75 4.19668 6.75 4.50002V13.5C6.75 13.8034 6.93273 14.0768 7.21299 14.1929C7.49324 14.309 7.81583 14.2449 8.03033 14.0304L12.5303 9.53035C12.8232 9.23746 12.8232 8.76258 12.5303 8.46969L8.03033 3.96969Z" fill="white"/>
                              </svg>
                            </button>
                        </Link>
        } else if(this.state.resVIP == "Data Not Found") {
          bt_next = <Link onClick={() => this.state.redirectLink = 'personality_profiler' }>
                            <button className='flex ret center bg-47' >
                              <p>Take Next Assessment</p>
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.03033 3.96969C7.81583 3.75519 7.49324 3.69103 7.21299 3.80711C6.93273 3.9232 6.75 4.19668 6.75 4.50002V13.5C6.75 13.8034 6.93273 14.0768 7.21299 14.1929C7.49324 14.309 7.81583 14.2449 8.03033 14.0304L12.5303 9.53035C12.8232 9.23746 12.8232 8.76258 12.5303 8.46969L8.03033 3.96969Z" fill="white"/>
                              </svg>
                            </button>
                        </Link>
        } else {
          bt_next = null
        }

    const ref = React.createRef();

    return (
      <div >
        <div className="box-wrapper" id="left0" >
          <div className="wrapper _M" id="top-80">
          <PDFExport ref={component => (this.pdfExportComponent = component)} margin={30} fileName={`Interest Profile`} paperSize="auto">
            {/* <NavBar  /> */}
            <div className="myAs" id="space0" >
            <div className="content" id="download" >
              <div className="page-container width-100"  id="space0" style={{marginTop:"80px"}}>
                <div className="page-container-outer" id="container-print"  >
                  <div className="box-logo width-100 logo-btn-justify" id="no-print" onClick={this.onChangeRedirect}>
                    <h1 className="titleSpartan" id="no-print" >My Assessment</h1>
                    <div id="myAssessmentbutton" style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Link  onClick={() => this.state.redirectLink = 'TableQuestion' } style={{ marginRight: '24px'}}>
                    <div className="flex" style={{display: this.state.showStore ? 'none' : '' }}>
                      <button
                        type="button"
                        className="flex ret center bg-ff bd-e1"
                        id="no-print"
                        onClick={this.takeNewAssessment}
                      >
                      {/* <button className='flex ret center bg-ff bd-e1'> */}
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69671 3.6967C5.0531 2.34029 6.92913 1.5 9 1.5C13.1421 1.5 16.5 4.85787 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C6.92913 16.5 5.0531 15.6597 3.6967 14.3033C3.40381 14.0104 3.40382 13.5355 3.69672 13.2426C3.98961 12.9497 4.46449 12.9497 4.75738 13.2426C5.84396 14.3293 7.34292 15 9 15C12.3137 15 15 12.3137 15 9C15 5.6863 12.3137 3 9 3C7.34292 3 5.84396 3.67075 4.75737 4.75736L4.75736 4.75737C4.54789 4.96685 4.24503 5.29039 3.93772 5.625H5.25C5.66421 5.625 6 5.96079 6 6.375C6 6.78921 5.66421 7.125 5.25 7.125H2.25C1.83579 7.125 1.5 6.78921 1.5 6.375V3.375C1.5 2.96079 1.83579 2.625 2.25 2.625C2.66421 2.625 3 2.96079 3 3.375V4.42911C3.25762 4.15067 3.50756 3.88585 3.69671 3.6967Z" fill="#3B6C98"/>
                        </svg>
                        <p>Retake Interest Profiler</p>
                      </button>
                      </div>
                    </Link>
                    {bt_next}
                    </div>
                  </div>
                  {/* 
          <React.Fragment> */}
           {/* <PDFExport ref={component => (this.pdfExportComponent = component)} > */}
                  <div className="page-container-inner width-100" id="myAs-print" style={{top:"0px", marginBottom:"22px", border:this.state.showStore?"2px solid #e0e0e0":""}}>
                    <div className="box-edu-container width-100" ref={ref} id="width-104" >
                      <div className="box-edu-content " style={{ width: "100%", borderRadius:"10px"}} >
                        <div className="box-assessment-title width-100">
                          <h4 className=" width-100">
                          My Work Interest Assessment Result
                          </h4>
                          {/* <button onClick={this.printDocument}>tes</button> */}
                          <div className="flex" >
                          <div className="flex" style={{display: this.state.showStore ? 'none' : '' }}>
                          <button
                            type="button"
                            className="btn btn-outline-secondary noNe"
                            onClick={this.print}
                            id="no-print"
                          >
                            <LazyLoadImage effect="blur" loading="lazy"  src={print} alt="" />
                            Print
                          </button>
                              <button
                              onClick={this.exportPDFWithComponent}
                              type="button"
                              className="btn btn-outline-secondary"
                              id="no-print"
                            >
                              <LazyLoadImage effect="blur" loading="lazy"  src={download} alt="" />
                              Download
                            </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            id="no-print"
                            onClick={() => {{this.openModal('link')} {ReactGA.pageview(window.location.pathname + window.location.search)}}}
                            style={{display:"none"}}
                          >
                            <LazyLoadImage effect="blur" loading="lazy"  src={share} alt="" />
                            Share
                          </button>
                          </div>
                          </div>
                        </div>
                        <div className="box-horizontal-line width-100">
                          <div className="horizontal-line width-100"></div>
                        </div>
                        {/* <Fade bottom cascade> */}
                        <div className="box-assessment-result width-100">
                          <div className="assessment-result-profile width-50">
                            {data.map((item, i) => {
                              if (i === 0)
                                return (
                                  <h4 key={i} className=" width-100" style={{textTransform:"capitalize"}}>
                                    {item.name}
                                  </h4>
                                );
                            })}
                            {/* NURI INDAYANI */}
                            
                            <div className="box-result-profile-label width-100">
                              {data.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="result-profile-label conventional "
                                    style={{
                                      background: `${item.color}`,
                                      border: `1px solid ${item.color}`,
                                    }}
                                  >
                                    <p style={{ padding: 0, margin: 0 }}>
                                      {item.interest_name}{" "}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="assessment-result-grafik width-50" >
                            {allRes.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="box-result-grafik width-100"
                                 
                                >
                                  <div className="result-grafik-label">
                                    <p id="name-print">{item.interest_name} </p>
                                  </div>
                                  <div className="result-grafik-value">
                                    <div className="progress">
                                      <div
                                        className="progress-bar bg-realistic"
                                        role="progressbar"
                                        style={{
                                          width: `${item.scoring * 4}%`,
                                          backgroundColor: `${item.color}`,
                                        }}
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        <div
                                          className="progress-value realistic"
                                          style={{
                                            backgroundColor: `${item.color}`,
                                          }}
                                        >
                                          {item.scoring * 4}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* </Fade> */}
                        <div className="box-assessment-description width-100" id="box-assessment-print2">
                          {data.map((item, i) => {
                            return (
                              <div key={i} className="assessment-description">
                                
                                <div className="assessment-description-inner width-100" id="box-description-inner" style={{height: this.state.showStore ? '460px' : '352' }}>
                               
                                  <div className="assessment-description-title width-100" id="space0">
                                    <div className="box-description-title-img  width-100">
                                      <div
                                        className="description-title-img conventional flex center"
                                        style={{
                                          backgroundColor: `${item.color}`,
                                          border: `1px solid ${item.color}`,
                                        }}
                                      >
                                        {this.state.list_img[i]}
                                        {/* <img
                                          className="icon-map flex m-auto"
                                          src={ire11}
                                          style={{ height:"60%", padding: "20%" }}
                                        /> */}
                                      </div>
                                    </div>
                                    <h4
                                      className="conventional-h4 width-100"
                                      style={{ color: `${item.color}` }}
                                    >
                                      {item.interest_name}{" "}
                                    </h4>
                                    <h5
                                      className="conventional-h5 width-100"
                                      style={{ color: `${item.color}` }}
                                    >
                                      {item.interest_type}{" "}
                                    </h5>
                                  </div>
                                 
                                  <div className="assessment-description-content width-100" id="box-description-content" style={{height: this.state.showStore ? '300px' : '205' }}>
                                    <Scrollbar style={{height:"100%"}}>
                                    
                                    <p className=" width-95" id="p-print">
                                      {item.interest_description}
                                    </p>
                                    </Scrollbar>
                                  </div>
                               
                                 
                                </div>
                                
                              </div>
                            );
                          })}
                        </div>
                        </div>
                      </div>
                    </div>
                    {/* </PDFExport> */}
                    {/* </React.Fragment>
                    */}
                  </div>
                </div>
              </div>
            </div>
            <Modal className="modal-link" id="link" visible={this.state.link} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div id="modal-header" className="modal-header-sign">
                        <a href="javascript:void(0);" onClick={() => this.closeModal()}></a>
                    </div>
                    <Share/>
                </Modal>
                 </PDFExport>
          </div>
        </div>
       
        <div  id="no-print">
        {/* <Footer/> */}
        </div>
        <Modal  id="course-outline1" visible={this.state.show_modal} effect="fadeInUp" onClickAway={this.closeRatingAll}>
            <div className="popup_dlc  _myAs flex column">
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={this.closeRatingAll}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                 <h2 className="openSans">Rate this Interest Profiler</h2>
                 <Rating name="pristine" value={this.state.rate}  onClick={this.changeRatting} onChange={this.changeRatting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)}/>
                 <h3 className="openSans flex">{this.state.desc} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1) Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4) Good</p>
                   <p className="openSans">(5) Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this Interest Profiler</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                 </div>
                 <button onClick={this.submitRatingAll} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    result1: state.result,
    result2: state.allResult,
    dataReportValues1 : state.reportValuesRed,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResult: (user_id) => {
      dispatch(getResult(user_id));
    },
    getAllResult: (user_id) => {
      dispatch(getAllResult(user_id));
    },
    getReportValues: (user_id) => {
      dispatch(getReportValues(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssesmentReport);
