import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import InOutModal from "./InOutModal";
import logoMobile from "../images/logoPutih.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
var $ = require("jquery");

// @media (max-width: 768px) {
const Ul = styled.ul`
  list-style: none;
  font-family: "Montserrat";
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  z-index: 1;
  padding-inline-start: 0 !important;
  left: 0 !important;
  li {
    padding-left: 35px;
    font-size: 18px;
  }
  @media (max-width: 888px) {
    flex-flow: column nowrap;
    background-color: #8667f2;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: -16px;
    right: 0;
    height: 100%;
    width: 100vw;
    transition: transform 0.3s ease-in-out;
    li {
      padding: 20px 0;
      border-bottom: 0.25px solid;
      width: 100%;
      text-align: center;
      color: #fff;
    }
  }
`;

// menu base on login or not
let menutest;
let panggilModalMobile;
let panggilModalDesktop;
if (sessionStorage.getItem("token")) {
  menutest = <Dropdown />;
} else {
  panggilModalMobile = (
    <li className="callLoginMobile lineMenu">
      <InOutModal />
    </li>
  );
  panggilModalDesktop = <InOutModal />;
}
// menu base on login or not - END

const diretLink = (e) => {
  window.location.href = e;
};

const searchJobOpportunity = (e) => {
  const m = {
    city_id: "",
    country_id: "",
    industry_id: [],
    job_function_id: "",
    job_level_id: [],
    job_title: "",
    job_type: [],
    salary_from: 0,
    salary_to: 100000000,
    user_id: 1,
  };
  sessionStorage.setItem("jobOpportunitySearch", JSON.stringify(m));
  window.location.href = "/Job-Opportunity-Public";
};

const icon_down = (
  <svg
    className="m-auto08"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.84467 11.7803C5.13756 12.0732 5.61244 12.0732 5.90533 11.7803L9.875 7.81066L13.8447 11.7803C14.1376 12.0732 14.6124 12.0732 14.9053 11.7803C15.1982 11.4874 15.1982 11.0126 14.9053 10.7197L10.4053 6.21967C10.1124 5.92678 9.63756 5.92678 9.34467 6.21967L4.84467 10.7197C4.55178 11.0126 4.55178 11.4874 4.84467 11.7803Z"
      fill="white"
    />
  </svg>
);
const icon_close = (
  <svg
    className="m-auto08"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.34467 6.21967C4.63756 5.92678 5.11244 5.92678 5.40533 6.21967L9.375 10.1893L13.3447 6.21967C13.6376 5.92678 14.1124 5.92678 14.4053 6.21967C14.6982 6.51256 14.6982 6.98744 14.4053 7.28033L9.90533 11.7803C9.61244 12.0732 9.13756 12.0732 8.84467 11.7803L4.34467 7.28033C4.05178 6.98744 4.05178 6.51256 4.34467 6.21967Z"
      fill="white"
    />
  </svg>
);

const NavMenu = ({ open }) => {
  const [talents, setTalents] = useState(false);
  const [career, setCareer] = useState(false);
  const [link, setLink] = useState("");

  const handleCallback = (childData) => {
    // setLink(childData)
    // if (childData===''){
    //   $('#sett').show();
    //   $('#sett').css("display","flex");
    //   $('body').css('overflow', 'auto');
    //   document.getElementById("sett").style.display = "flex";
    // }else{
    //   $('#sett').hide();
    //   $('#sett').css("display","none");
    //   $('body').css('overflow', 'hidden');
    //   document.getElementById("sett").style.display = "none";
    // }
    window.location.href = `/${childData}`;
  };

  return (
    <React.Fragment>
      <div className="navMenuDisplay height-100i">
        <Ul open={open} className="colorMenu pt-62 height-100i" id="no-print">
          <div className="p-24 flex column center wd-webkit">
            <p
              className="c-ff w-600 Kanit s-16 let-04 p-180 bd-bottom-34 width-100"
              onClick={() => diretLink("/")}
            >
              HOME  
            </p>
            <p
              className="c-ff w-600 Kanit s-16 let-04 p-180 bd-bottom-34 width-100"
              onClick={() => diretLink("/about")}
            >
              ABOUT
            </p>
            <div className="flex column width-100 bd-bottom-34">
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180  auto flex"
                onClick={() => setTalents(!talents)}
              >
                ACQUISITION {talents ? icon_down : icon_close}
              </p>
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 width-100"
                onClick={() => diretLink("/About-Acquisition")}
                style={{ display: talents === true ? "" : "none" }}
              >
                ABOUT ACQUISITION
              </p>
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 width-100"
                onClick={() => searchJobOpportunity()}
                style={{ display: talents === true ? "" : "none" }}
              >
                JOBS
              </p>
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 width-100"
                onClick={() => diretLink("/Talents")}
                style={{ display: talents === true ? "" : "none" }}
              >
                TALENTS
              </p>
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 width-100"
                onClick={() => diretLink("/Employer")}
                style={{ display: talents === true ? "" : "none" }}
              >
                EMPLOYER
              </p>
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 width-100"
                onClick={() => diretLink("/data-science-rockstar")}
                style={{ display: talents === true ? "" : "none" }}
              >
                Data Science Rockstar
              </p>
              {/* <p className="c-ff w-600 Kanit s-16 let-04 p-180 width-100" onClick={()=>diretLink("/Employer")} style={{display:talents===true?"":"none"}}>KT300 Club</p> */}
            </div>
            <p
              className="c-ff w-600 Kanit s-16 let-04 p-180 bd-bottom-34 width-100"
              onClick={() => diretLink("/Advisory")}
            >
              ADVISORY
            </p>
            <p
              className="c-ff w-600 Kanit s-16 let-04 p-180 bd-bottom-34 width-100"
              onClick={() => diretLink("/Academy")}
            >
              ACADEMY
            </p>
            <p
              className="c-ff w-600 Kanit s-16 let-04 p-180 bd-bottom-34 width-100"
              onClick={() => diretLink("/Assessment")}
            >
              ASSESSMENT
            </p>
            <div className="flex column width-100 bd-bottom-34 ">
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 flex auto "
                onClick={() => setCareer(!career)}
              >
                RESOURCES {career ? icon_down : icon_close}
              </p>
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 width-100"
                onClick={() => {
                  diretLink("/career-resources");
                  sessionStorage.setItem("slug", "career");
                }}
                style={{ display: career ? "" : "none" }}
              >
                Career Resources{" "}
              </p>
              <p
                className="c-ff w-600 Kanit s-16 let-04 p-180 width-100"
                onClick={() => {
                  diretLink("/hiring-resources");
                  sessionStorage.setItem("slug", "hiring");
                }}
                style={{ display: career ? "" : "none" }}
              >
                Hiring Resources
              </p>
            </div>
            <p
              className="c-ff w-600 Kanit s-16 let-04 p-180 bd-bottom-34 mb-36 width-100"
              onClick={() => diretLink("/ContactUs")}
            >
              CONTACT US
            </p>
            <div className="flex center width-100 mb-32">
              <button
                className="p-1024 bg-09 bd-236 flex br-26 mr-24"
                onClick={() => handleCallback("login")}
              >
                <p className="c-ff w-600 Kanit s-16 let-04 p-180 contents_">
                  LOGIN
                </p>
              </button>
              <button
                className="p-1024 bg-36 bd-36 flex br-26 "
                onClick={() => handleCallback("signup")}
              >
                <p className="c-ff w-600 Kanit s-16 let-04 p-180 auto contents_">
                  SIGN UP
                </p>
              </button>
            </div>
          </div>
        </Ul>
        {/* <div className="callLoginDesktop">{panggilModalDesktop}</div> */}
      </div>

      {/* <div  className='flex fixed height-100 column width-100 bg-09'>
            <div className='flex column p-24' >
              <h1>hviviyg</h1>
              <div className='flex width-100 flex-end mb-46'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.291784 12.2886C-0.0976683 12.6802 -0.0976683 13.3151 0.291784 13.7067C0.681237 14.0983 1.31266 14.0983 1.70212 13.7067L7 8.37969L12.2979 13.7067C12.6874 14.0983 13.3188 14.0983 13.7083 13.7067C14.0977 13.3151 14.0977 12.6802 13.7083 12.2886L8.41033 6.9616L13.6319 1.71135C14.0213 1.31976 14.0213 0.684864 13.6319 0.293271C13.2424 -0.0983218 12.611 -0.0983215 12.2216 0.293271L7 5.54352L1.77849 0.293312C1.38904 -0.0982814 0.75761 -0.0982814 0.368157 0.293312C-0.0212957 0.684905 -0.0212957 1.3198 0.368157 1.7114L5.58967 6.9616L0.291784 12.2886Z" fill="white"/>
                </svg>
              </div>
              <div className='flex width-100 center'>
                <p className='Kanit w-600 auto c-ff'>home</p>
              </div>
            </div>
          </div> */}
    </React.Fragment>
  );
};

export default NavMenu;
