import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

const ProtectedRoute = ({component: Component,...rest}) => {
sessionStorage.setItem("ref",window.location.pathname)
               
  if(localStorage.getItem('token')) {
    sessionStorage.setItem('token', localStorage.getItem('token'))
  }
    return (
      <Route
        {...rest}
        render={props => {
          if (sessionStorage.getItem('token')) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        }}
      />
    );
  };
  export default withRouter(ProtectedRoute);