import {GET_ARTICLE, ARTICLE_DETAIL,GET_FAQ,GET_ABOUT,OTHER_ARTICLE,GET_CONTACT, POST_MESSAGE, GET_ALL_ARTICLE,
   GET_PHOTO, GET_BANNER, GET_BANNER_BY_ID } from "../config/constants";
  const initialState = {
    data: [],
    dataAbout: [],
    dataFaq: [],
    dataMessage:[],
    dataArticle:[],
    dataBanner:[],
    dataBannerById:[],
    photo:[],
    isLoading: false,
    error: false,
  };
  
  export const article = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_ARTICLE}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_ARTICLE}_FULFILLED`:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
        };
      case `${GET_ARTICLE}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export const articleDetail = (state = initialState, action) => {
    switch (action.type) {
      case `${ARTICLE_DETAIL}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${ARTICLE_DETAIL}_FULFILLED`:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
        };
      case `${ARTICLE_DETAIL}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export const faq = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_FAQ}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_FAQ}_FULFILLED`:
        return {
          ...state,
          dataFaq: action.payload.data,
          isLoading: false,
        };
      case `${GET_FAQ}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export const about = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_ABOUT}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_ABOUT}_FULFILLED`:
        return {
          ...state,
          dataAbout: action.payload.data,
          isLoading: false,
        };
      case `${GET_ABOUT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export const otherArticle = (state = initialState, action) => {
    switch (action.type) {
      case `${OTHER_ARTICLE}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${OTHER_ARTICLE}_FULFILLED`:
        return {
          ...state,
          dataArticle: action.payload.data,
          isLoading: false,
        };
      case `${OTHER_ARTICLE}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export const contact = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_CONTACT}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_CONTACT}_FULFILLED`:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
        };
      case `${GET_CONTACT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export const message = (state = initialState, action) => {
    switch (action.type) {
      case `${POST_MESSAGE}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${POST_MESSAGE}_FULFILLED`:
        return {
          ...state,
          dataMessage: action.payload.data,
          isLoading: false,
        };
      case `${POST_MESSAGE}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const allArticle = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_ALL_ARTICLE}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_ALL_ARTICLE}_FULFILLED`:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
        };
      case `${GET_ALL_ARTICLE}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const allPhoto = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_PHOTO}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_PHOTO}_FULFILLED`:
        return {
          ...state,
          photo: action.payload.data,
          isLoading: false,
        };
      case `${GET_PHOTO}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const bannerRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_BANNER}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_BANNER}_FULFILLED`:
        return {
          ...state,
          dataBanner: action.payload.data,
          isLoading: false,
        };
      case `${GET_BANNER}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const bannerByIdRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_BANNER_BY_ID}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_BANNER_BY_ID}_FULFILLED`:
        return {
          ...state,
          dataBannerById: action.payload.data,
          isLoading: false,
        };
      case `${GET_BANNER_BY_ID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  