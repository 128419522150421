import React, { Component } from "react";
import { connect } from 'react-redux';
import { updatePassword } from '../_actions/user';
import { TextField, Button } from "@material-ui/core";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios'
import ReactGA from 'react-ga';
import {GAevent} from './TrackingEvent'
import ReactPixel from 'react-facebook-pixel';
import {API} from '../_redux/helper'


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: ""
      },
      forgotPassword: false,
      errors: {},
      disabled: false
    }
  }

  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }


  updatePassword = (e) => {
    e.preventDefault();
  };

  changePassword = (e) => {
    e.preventDefault();
    let fields = this.state.fields;
    let errors = {}
    if(fields["email"] === ""){
         errors["email"] = "Email cannot be empty";
  }else{

        const user = {
          email: this.state.fields["email"],
        };
        const defaultResponse = this.createNotification('success')
      
        axios
        .post(`${API}/api/forgotpasswordtoken`,user)
        .then(res => {
          defaultResponse()
        })
        .catch(err => {
          this.createNotification('error')
        })
        GAevent(`Send Email - ${window.location.pathname}`,'Send Email'); 
        ReactPixel.track(`Send Email - ${window.location.pathname}`,'Send Email'); 
      }
      this.setState({errors: errors});
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  createNotification = (type) => {
    return () => {
      if (type === 'success') {
        NotificationManager.success('Click the link in the email to reset your password', 'Check your email', 9000);
      } else {
        NotificationManager.error('Something went wrong', 'Ups.. Sorry' , 5000);
      }
    };
  };

  render() {
    return (
      <div className="login">
        <form onSubmit={this.changePassword} id="changePassword">
          <div>
            <h4>
            Forgot Password
              </h4>
            <p>Fill with your registered email, we will send you verification link to create new password</p>
            <div className="box-sign">
              <TextField label="Email" variant="outlined" type="email" className="form-control" 
                name="email" id="email" refs="email" aria-describedby="emailHelp" onChange={this.handleChange.bind(this, "email")}
                value={this.state.fields["email"]} />
              <span className="err-ntf" style={{ color: "red", fontSize: "12px" }}>{this.state.errors["email"]}</span>
            </div>
            <div>
              <Button id="button" type="submit" variant="outlined" className="_reg"
                style={{ marginTop: "9px", backgroundColor: "#3B6C98", width: "100%", color: "white" }}>
                Update Password
                </Button>
            </div>
          </div>
        </form>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.updatePwd
  };
};

const mapDispatchToProps = dispatch => {
  return {
    password1: (event) => {
      dispatch(updatePassword(event));
    }
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);