export const GET_QUEST = 'GET_QUEST';
export const GET_JOB = 'GET_JOB';
export const JOB_DETAIL = 'JOB_DETAIL';
export const GET_RESULT = 'GET_RESULT';
export const GET_ALL_RESULT = 'GET_ALL_RESULT';
export const ADD_QUEST = 'ADD_QUEST';
export const PROFILE = 'PROFILE';
export const GET_PROFILE_ID = 'GET_PROFILE_ID';
export const PUT_PROFILE_ID = 'PUT_PROFILE_ID';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const GET_EXP_LIST = 'GET_EXP_LIST';
export const GET_BENEFIT_LIST = 'GET_BENEFIT_LIST';
export const GET_SKILL_LIST = 'GET_SKILL_LIST';
export const POST_SKILL = 'POST_SKILL';
export const GET_PROJECT = 'GET_PROJECT'
export const GET_LAST_LIST = 'GET_LAST_LIST';
export const POST_USER_PROJECT = 'POST_USER_PROJECT';
export const EDIT_USER_PROJECT = 'EDIT_USER_PROJECT';
export const JOB_TRAINING = 'JOB_TRAINING';
export const JOB_EXCLUDE = 'JOB_EXCLUDE';
export const JOB_OPPORTUNITY = 'JOB_OPPORTUNITY';
export const DETAIL_JOB_OPPORTUNITY = 'DETAIL_JOB_OPPORTUNITY';
export const GET_ARTICLE = 'GET_ARTICLE';
export const ARTICLE_DETAIL = 'ARTICLE_DETAIL';
export const GET_FAQ = 'GET_FAQ';
export const GET_USER_COURSE_DLP = 'GET_USER_COURSE_DLP';
export const GET_USER_PROGRAM_DLP = 'GET_USER_PROGRAM_DLP';
export const GET_ALLPROGRAM_DLP = 'GET_ALLPROGRAM_DLP';
export const GET_DETAIL_PROGRAM_DLP = 'GET_DETAIL_PROGRAM_DLP';
export const GET_DETAIL_CONTENT_DLP = 'GET_DETAIL_CONTENT_DLP';
export const GET_COURSE_BY_CONTENT_DLP = 'GET_COURSE_BY_CONTENT_DLP';
export const GET_COURSE_DETAIL_DLP = 'GET_COURSE_DETAIL_DLP';
export const GET_DROPDOWN_REGISTRATION_DLP = 'GET_DROPDOWN_REGISTRATION_DLP';
export const GET_KNOW_PROGRAM = 'GET_KNOW_PROGRAM';
export const GET_ABOUT_DLP = 'GET_ABOUT_DLP';
export const GET_ABOUT = 'GET_ABOUT';
export const GET_POPULAR1 = 'GET_POPULAR1';
export const GET_POPULAR2 = 'GET_POPULAR2';
export const GET_POPULAR3 = 'GET_POPULAR3';
export const OTHER_ARTICLE = 'OTHER_ARTICLE';
export const GET_CONTACT = 'GET_CONTACT';
export const POST_MESSAGE = 'POST_MESSAGE';
export const REGISTRATION_DLP = 'REGISTRATION_DLP';
export const LINK_TO_SHARE = 'LINK_TO_SHARE';
export const VERIFIKASI = 'VERIFIKASI';
export const VERIFIKASI_EMAIL = 'VERIFIKASI_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_ALL_ARTICLE = 'GET_ALL_ARTICLE';
export const GET_PENGETAHUAN = 'GET_PENGETAHUAN';
export const GET_KETERAMPILAN = 'GET_KETERAMPILAN';
export const GET_KEMAMPUAN = 'GET_KEMAMPUAN';
export const GET_KARAKTER = 'GET_KARAKTER';
export const GET_NILAI = 'GET_NILAI';
export const UPDATE_INFORMATION = 'UPDATE_INFORMATION';
export const AUTOLOGIN = 'AUTOLOGIN';
export const PASSWORD_EMAIL = 'PASSWORD_EMAIL';
export const DETAIL_JOB = 'DETAIL_JOB';
export const GET_PHOTO = 'GET_PHOTO';
export const ALL_PROGRESS = 'ALL_PROGRESS';
export const PROGRESS_PROFILE = 'PROGRESS_PROFILE';
export const GET_GENDER = 'GET_GENDER';
export const GET_PROVINCE = 'GET_PROVINCE';
export const GET_CITY = 'GET_CITY';
export const GET_PROGRAM = 'GET_PROGRAM';
export const GET_ALL_CITY = 'GET_ALL_CITY';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_NEW = 'GET_COUNTRY_NEW';
export const CITY = 'CITY';
export const GET_ONLY_CITY ='GET_ONLY_CITY';
export const STATE = 'STATE';
export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_BY_ID = 'GET_BANNER_BY_ID';
export const GET_JOB_BY_TITLE = 'GET_JOB_BY_TITLE';
export const GET_JOB_TYPE = 'GET_JOB_TYPE';
export const GET_JOB_OPPORTUNITY_PUBLIC = 'GET_JOB_OPPORTUNITY_PUBLIC';
export const APPLY_JOB = 'APPLY_JOB';
export const POST_REFERRAL = 'POST_REFERRAL';
export const GET_REFERRAL = 'GET_REFERRAL';
export const CHECK_USER = 'CHECK_USER';
export const POST_REFERRAL_REGISTER = 'POST_REFERRAL_REGISTER';
export const GET_REFERRER = 'GET_REFERRER';
export const GET_JOB_DETAIL_PUBLIC = 'GET_JOB_DETAIL_PUBLIC';
export const CHECK_QUEST = 'CHECK_QUEST';
export const GET_JOB_CAREER_CENTER = 'GET_JOB_CAREER_CENTER';
export const GET_QUEST_PID = 'GET_QUEST_PID';
export const GET_QUEST_VALUES = 'GET_QUEST_VALUES';
export const PUT_VALUES = 'PUT_VALUES';
export const SUBMIT_PID = 'SUBMIT_PID';
export const GET_RESULT_PID = 'GET_RESULT_PID';
export const GET_REPORT_VALUES = 'GET_REPORT_VALUES';
export const GET_LIMIT_VALUES = 'GET_LIMIT_VALUES';

export const GET_LAST = 'GET_LAST';
export const GET_LAST_ID = 'GET_LAST_ID';
export const POST_LAST = 'POST_LAST';
export const DELETE_LAST = 'DELETE_LAST';
export const PUT_LAST = 'PUT_LAST';
export const SUBMIT_PID_STAND_ALONE = "SUBMIT_PID_STAND_ALONE"
export const GET_QUEST_PID_STAND_ALONE = "GET_QUEST_PID_STAND_ALONE"

export const GET_EXP = 'GET_EXP';
export const GET_EXP_ID = 'GET_EXP_ID';
export const POST_EXP = 'POST_EXP';
export const DELETE_EXP = 'DELETE_EXP';
export const PUT_EXP = 'PUT_EXP';

export const GET_CERTIFICATION = 'GET_CERTIFICATION';
export const GET_CERTIFICATION_ID = 'GET_CERTIFICATION_ID';
export const POST_CERTIFICATION = 'POST_CERTIFICATION';
export const DELETE_CERTIFICATION = 'DELETE_CERTIFICATION';
export const PUT_CERTIFICATION = 'PUT_CERTIFICATION';

export const GET_WORK_EXP = 'GET_WORK_EXP';
export const GET_WORK_PRE = 'GET_WORK_PRE';
export const GET_WORK_STA = 'GET_WORK_STA';
export const GET_WORK_EXP_ID = 'GET_WORK_EXP_ID';
export const POST_WORK_EXP = 'POST_WORK_EXP';
export const DELETE_WORK_EXP = 'DELETE_WORK_EXP';
export const PUT_WORK_EXP = 'PUT_WORK_EXP';
export const GET_INDUSTRI = 'GET_INDUSTRI';
export const GET_JOB_LEVEL = 'GET_JOB_LEVEL';
export const GET_JOB_FUNCTION = 'GET_JOB_FUNCTION';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_ID = 'GET_DOCUMENT_ID';
export const GET_DOCUMENT_PENDUKUNG = 'GET_DOCUMENT_PENDUKUNG';
export const POST_DOCUMENT = 'POST_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const PUT_DOCUMENT = 'PUT_DOCUMENT';

export const GET_EDUCATION = 'GET_EDUCATION';
export const GET_EDUCATION_ID = 'GET_EDUCATION_ID';
export const POST_EDUCATION = 'POST_EDUCATION';
export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const PUT_EDUCATION = 'PUT_EDUCATION';
export const LIST_EDUCATION = 'LIST_EDUCATION';

export const GET_JOB_BY_INTEREST_ARTISTIC = 'GET_JOB_BY_INTEREST_ARTISTIC';
export const GET_JOB_BY_INTEREST = 'GET_JOB_BY_INTEREST';

export const FIND_EMAIL = 'FIND_EMAIL';
export const  USER_COURSE_DETAIL = ' USER_COURSE_DETAIL'
