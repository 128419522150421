import Spinner from 'react-spinner-material';
import React, { Component } from 'react';

export default class Loading extends Component {
  render() {
		return (
				<div className="message-question-load">
					<Spinner radius={100} color={"#3B6C98"} stroke={10} visible={true} />
				</div>
			);
  }
}