import React, { Component } from 'react';
import moment from 'moment'
import {
    phone, mail, cv, cv_green, people, red, green, yellow,
    art_certification, art_personal, art_education, art_project, art_salary, art_skills, art_work_exp, pen, male, female
} from "../../_redux/svg"
import ina from "../../images/ina.svg"
import {month_year} from "../../_redux/function"
import Skeleton from '@mui/material/Skeleton';

class Personal_Information extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            show_text:false
        }
    }

    handlePopup = (e) => {
        this.props.handlePopup(e)
    }


    render() {
        const { profile, onCity, country_name, onState, city_name, work_pre_name, work_status_name, getCity, dataProvince, dataCity ,cityName, stateName} = this.props
        const {show_text} = this.state
        const n = profile
        let visible = true
        const list_params = ["country_id","address","birth_date","city_id","state_id","work_preference_id","work_status_id","professional_summary"]
        let list_value = []
        list_params.map((item)=>{
            if (profile[item] !== null){
            list_value.push("nil")
            }
        })

        if (list_value.length > 0){
            visible = false
        }

        const status = stateName !=="" && cityName !==""

        return (
            <div className="h-100 bg-ff br-10 shd-input -set">
                <div className='flex column h-100' >
                    <div className='flex p-1814 bb-ea space-between'>
                        <div className='flex'>
                            <div className='flex m-auto-00'>
                                {people}
                            </div>
                            <p className='ml-14 DM-Sans c-29 s-20 '>Personal Information</p>
                        </div>
                        {!visible &&
                            <div className='flex pointer m-auto0' onClick={(e) => this.props.handlePopup("personal_info")} >
                                {pen}
                                <p className='DM-Sans s-14 c-36 ml6'>Edit</p>
                            </div>}
                    </div>
                    <div className='flex column  width-100 h-90'>
                        {visible &&
                            <div className='flex m-auto column center pt-2 pb-2'>
                                {art_personal}
                                <p className='c-29 DM-Sans s-14 mt-48 mb-48 tx-c mr-10'>Fill your personal information to keep in touch with us.</p>
                                <div className='bg-36 p-1014 br-20 pointer' onClick={(e) => this.props.handlePopup("personal_info")}>
                                    <p className='s-14 c-ff DM-Sans'>Complete Personal Information</p>
                                </div>
                            </div>}
                        {!visible &&
                            <div className='p-14'>
                                <div className='flex width-100 wrap align-left grid-2column'>
                                    <div className='flex column  mr-24 mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>Birth Date</p>
                                        {status ?
                                        <p className='DM-Sans s-14 c-29'>{profile.birth_date!==null?new Date(profile.birth_date).getDate()+" "+ month_year(profile.birth_date):"-"}</p>:
                                        <Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                    </div>
                                    <div className='flex column  mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>Gender</p>
                                        {status ?
                                        <div className='flex text-cap'>
                                            {profile.gender_id === 1 && male} {profile.gender_id === 2 && female} <p className='ml4 DM-Sans c-29 s-14'>
                                                {profile.gender_id === 1 ? "male" : profile.gender_id === 2 ? "female" : ""}
                                            </p>
                                        </div>:<Skeleton variant="text" sx={{ fontSize: '20px' }} />
                                        }
                                    </div>
                                    <div className='flex column  mr-24 mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>Country</p>
                                        {status ?
                                        <div className='flex'>
                                        {country_name==="Indonesia" &&
                                            <img src={ina} className="flex br-100 wd-fit-content" />}
                                            <p className='ml4 DM-Sans c-29 s-14'>{country_name} </p>
                                        </div>:<Skeleton variant="text" sx={{ fontSize: '20px' }} />
                                        }
                                    </div>
                                    <div className='flex column  mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>State</p>
                                        {status ?
                                            <p className='DM-Sans s-14 c-29'>{stateName} </p>
                                            :<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                    </div>
                                    <div className='flex column  mr-24 mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>City</p>
                                        {status ?
                                        <p className='DM-Sans s-14 c-29'>{cityName} </p>
                                        :<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                    </div>
                                    <div className='flex column  mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>Address</p>
                                        {status ?
                                        <p className='DM-Sans s-14 c-29'>{profile.address} </p>
                                        :<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                    </div>
                                    <div className='flex column  mr-24 mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>Work Preference</p>
                                        {status ?
                                        <p className='DM-Sans s-14 c-29'>{work_pre_name}</p>
                                        :<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                    </div>
                                    <div className='flex column  mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>Work Status</p>
                                        {status ?
                                        <div className='flex'>
                                        {work_status_name == 'Not Looking for Job' ? red : work_status_name == 'Open for Job Opportunities' ?  yellow  :  green }   <p className='ml4 DM-Sans c-29 s-14'>{work_status_name}</p> 
                                        </div>
                                        :<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                    </div>
                                    
                                </div>
                                <div>
                                    <div className='flex column width-100 mb-24'>
                                        <p className='DM-Sans s-14 c-72 mb2'>Professional Summary</p>
                                        {status ?
                                        <div className='flex wrap width-100'>
                                        {profile.professional_summary!==null && profile.professional_summary.length>200 ?
                                        show_text===false?
                                         <p className='DM-Sans s-14 c-29 break-word width-100 block' style={{display:show_text?"none":""}}> {profile.professional_summary.slice(0,200)+"..."} 
                                         <p className='DM-Sans-Bold s-14 c-87 hov-un pointer wd-fit-content contents_' onClick={(e)=>this.setState({show_text:!show_text})}>read more</p>
                                         </p>:
                                         <p className='DM-Sans s-14 c-29 break-word width-100 block' > {profile.professional_summary} 
                                         <p className='DM-Sans-Bold s-14 c-87 hov-un pointer wd-fit-content contents_' onClick={(e)=>this.setState({show_text:!show_text})}>&nbsp; show less</p>
                                         </p>
                                         :<p className='DM-Sans s-14 c-29 break-word width-100 block'>{profile.professional_summary}</p>}
                                        </div>
                                        :<Skeleton variant="text" sx={{ fontSize: '60px' }} />}
                                    </div>
                                </div>
                            </div>}
                </div>
            </div>
            </div >
        );
    }
}

export default Personal_Information;