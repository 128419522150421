import React from "react";
import {API,url,API_DLP} from "../_redux/helper"
import {FacebookShareButton, WhatsappShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import AnimatedText from 'react-animated-text-content';
import { Wave, Random } from 'react-animated-text';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'chartjs-plugin-datalabels';
import wa from "../images/wa.svg"
import fb from "../images/fb.svg"
import li from "../images/li.svg"
import tw from "../images/tw.svg"

export const token=  sessionStorage.getItem('token')

export const height_ = window.innerHeight

export const setDate=(e)=>{
    let created_
    var today = new Date();
    var diffMs = (today - new Date(e)); 
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    var diffYears = Math.round(diffDays/365)

    if ( diffMins<60 && diffDays < 1 && diffYears < 1 && diffHrs < 1 ){
        created_ = diffMins +" min"
    }else if (diffMins <60  && diffHrs >1 && diffDays <1 && diffYears < 1){
        created_ = diffHrs +" hour"
    }else if (  diffDays >= 1 && diffDays <=30 && diffYears < 1){
        created_ = Math.round(diffDays) +" day"
    }else if (  diffDays > 30 && diffYears < 1){
        created_ = "more than 30 days"
    }else {
        created_ = "more than 30 days"
    }
    return(created_)
}

export const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));

export const checkId = (x,y)=>{
    let n = false
    if (y.indexOf(x) !== -1){
        n=true
    }
    return(n)
}

export const icon_job=(e,y)=>e &&
                            <div className="flex">
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={y} />
                                <p>{e}</p>
                            </div>

export const sortFunction=(a,b)=>{  
                                    var dateA = new Date(a.createdAt).getTime();
                                    var dateB = new Date(b.createdAt).getTime();
                                    return dateA < dateB ? 1 : -1;  
                                }; 

export const sortFunctionDate=(a,b)=>{  
                                    var dateA = new Date(a["year"]).getTime();
                                    var dateB = new Date(b["year"]).getTime();
                                    return dateA < dateB ? 1 : -1;  
                                }; 

export const sortByMonth=(a,b)=>{  
                                    var dateA = new Date(a["month"]).getTime();
                                    var dateB = new Date(b["month"]).getTime();
                                    return dateA < dateB ? 1 : -1;  
                                }; 

export const sortUpdate=(a,b)=>{  
                                var dateA = new Date(a.updatedAt).getTime();
                                var dateB = new Date(b.updatedAt).getTime();
                                return dateA < dateB ? 1 : -1;  
                                };                               
export const icon_share=(e,y)=><div className="flex mt-6">
                        <FacebookShareButton className='out-none' url={`${url}/JobDetail_Public/${e}/${y}`} quote={"Jobs- KaryaTalents"} hashtag="#karyaTalents" >
                            <LazyLoadImage className='mr-14' effect="blur" loading="lazy" alt="" src={fb} />
                        </FacebookShareButton>
                        <LinkedinShareButton className='out-none' url={`${url}/JobDetail_Public/${e}/${y}`} quote={"Jobs- KaryaTalents"} hashtag="#karyaTalents" >
                            <LazyLoadImage className='mr-14' effect="blur" loading="lazy" alt="" src={li} />
                        </LinkedinShareButton>
                        <TwitterShareButton   className='out-none'url={`${url}/JobDetail_Public/${e}/${y}`} quote={"Jobs- KaryaTalents"} hashtag="#karyaTalents" >
                            <LazyLoadImage  className='mr-14' effect="blur" loading="lazy" alt="" src={tw} />
                        </TwitterShareButton>
                        <WhatsappShareButton className='out-none' url={`${url}/JobDetail_Public/${e}/${y}`} quote={"Jobs- KaryaTalents"} hashtag="#karyaTalents" >
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={wa} />
                        </WhatsappShareButton>
                        </div>

export const required = (e)=>{
    let n = ""
    if (e==="" || e===undefined || e.length ===0){
        n= "This field is required"
    }
    return n
}

export const animatedText = (text,x,y,z)=>
            <AnimatedText type="chars" 
            animation={{
            x: '200px',
            y: '-20px',
            scale: 1.1,
            ease: 'ease-in-out',
            }}
            animationType={x}
            interval={0.09}
            duration={0.5}
            tag={y}
            className={z}
            includeWhiteSpaces
            threshold={0.1}
            rootMargin="20%"
        >
       {text}
        </AnimatedText>

export const animatedText_desc = (text,x,y,z)=>
            <AnimatedText type="words" 
            animation={{
            x: '200px',
            y: '-20px',
            scale: 1.1,
            ease: 'ease-in-out',
            }}
            animationType={x}
            interval={0.09}
            duration={0.5}
            tag={y}
            className={z}
            includeWhiteSpaces
            threshold={0.1}
            rootMargin="20%"
        >
       {text}
        </AnimatedText>

export const wave=(e)=>
            <Wave
            text={e}
            effect="verticalFadeIn"
            effectChange={1}
            effectDirection='right'
            iterations={1}
            />

export const formatDate = (e)=>{
        const a = new Date(e)
        const date_ = a.getDate() +"/"+ (a.getMonth() + 1)  +"/"+a.getFullYear()
        return date_
    }

export const month_year =(e)=>{
    const a = new Date(e)
    const month_ = ["Jan","Feb","Mar","Apr","Mei","Jun","Jul","Agu","Sep","Okt","Nov","Dec"]
    const yr_ = month_[a.getMonth()]+"  "+a.getFullYear()
    return yr_
}
export const stringToArray=(e)=>{
    let c 
    if (e !== undefined){
        c = e.split(",")
    }else{
        c = []
    }
    return c
}

export const list_month = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"]

export const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const month = ['January','February','March','April','May','June','July','August','September','October','November','December'];

export const setDatePID = (date_report)=>{
    const val_day = new Date(date_report).getDay();
    const val_date = new Date(date_report).getDate();
    const val_month = new Date(date_report).getMonth();
    const val_year = new Date(date_report).getFullYear();
    const val_Hours = new Date(date_report).getHours();
    const val_Minutes = new Date(date_report).getMinutes();

    let Hour;
    if (val_Hours >= 13) {
      Hour = val_Hours - 12 + ':' + val_Minutes + ' PM';
    } else {
      Hour = val_Hours + ':' + val_Minutes + ' AM';
    }

    const date_PID = day[val_day] + ', ' + month[val_month] + ' ' + val_date + ', ' + val_year + '  ' + Hour;
    return date_PID
}

export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

export const numberOnly=(e)=>{
    var regex1 = /^[0-4]\.\d{2}$/;
    let n = ""
    if (e === 0 || e ==="" || e ==="0"){
        n=e
       } else if( regex1.test(e)===true ){
        n=e
       }else{
        n=""
       }
    return n
}

// FUNCTION FOR VIP REPORT 
export  const setDataRadar = (nil,list_label) => {
    const n ={
    labels: list_label,
    datasets: [
      {
        data: [nil[0], nil[1], null, null, null, null, null, null, null, null],
        label: `ACHIEVEMENT`,
        borderColor: 'rgba(215, 87, 87, 0)',
        backgroundColor: 'rgba(215, 87, 87, 0.6)',
        borderWidth: 2,
        pointRadius: 0,
      },
      {
        data: [null, null, nil[2], nil[3], nil[4], null, null, null, null, null],
        label: `FUN`,
        borderColor: 'rgba(226, 181, 129, 0)',
        backgroundColor: 'rgba(226, 181, 129, 0.6)',
        borderWidth: 2,
        pointRadius: 0,
      },
      {
        data: [null, null, null, null, null, nil[5], nil[6], null, null, null],
        label: `HARMONY`,
        borderColor: 'rgba(91, 184, 139, 0)',
        backgroundColor: 'rgba(91, 184, 139, 0.6)',
        borderWidth: 2,
        pointRadius: 0,
      },
      {
        data: [null, null, null, null, null, null, null, nil[7], nil[8], nil[9]],
        label: `COMPLIANCE`,
        borderColor: 'rgba(91, 128, 184, 0)',
        backgroundColor: 'rgba(91, 128, 184, 0.6)',
        borderWidth: 2,
        pointRadius: 0,
      },
    ]}
    return n
  };

  export function checkFont() {
    let check = window.innerWidth > 500 ? 12 : 8;
    return check;
  }