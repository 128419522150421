import React, { Component } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import Loading from '../components/Loading'
import talkTo from "../images/talkTo-img.png"
import mailgo, { MailgoConfig } from "mailgo";
import axios from 'axios';
import { API } from '../_redux/helper';
import { TextField } from "@material-ui/core";
import Modal from "react-awesome-modal";
import apply_success from "../images/apply_success.svg"
import 'react-notifications/lib/notifications.css';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'
import ReactPixel from 'react-facebook-pixel';



export default class Hubungi extends Component {
  constructor() {
    super()
    this.state = {
          email: "",
          phone_number:"",
          name:"",
          message:"",
          company: "",
          job_title:"",
          emailErr:"",
          nameErr:"",
          phone_numberErr:"",
          companyErr:"",
          job_titleErr:"",
          messageErr:"",
          errors: {},
          data:"",
          disabled : false,
          success:false,
          failed:false,
     }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    mailgo();
    if(localStorage.getItem('token')) {
      window.location.href = '/NewDashboard'
    }
    axios.get(`${API}/api/employer_dynamic`).then((res)=>{
      this.setState({data:res.data})
    })
    ReactGA.pageview(window.location.pathname + window.location.search);
  }





handleChange(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
}

changeEmail=(e)=>{
  this.setState({email:e.target.value})
}
changeCompany=(e)=>{
  this.setState({company:e.target.value})
}
changeJob=(e)=>{
  this.setState({job_title:e.target.value})
}
changeMessage=(e)=>{
  this.setState({message:e.target.value})
}
  onChangeName = event => {
    const reg = /^(?![\s.]+$)[a-zA-Z\s.]*$/
    const n = reg.test(event.target.value)
    if (n === true){
    this.setState({ name: event.target.value });
    }
  };
  onChangePhone =(event)=>{
    const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
    const n = reg.test(event.target.value)
    /* eslint eqeqeq: 0 */
    if (n==true){
    this.setState({phone_number:event.target.value})
    }
  }
  onKeyDown=(e)=> {
    if (e.keyCode === 8 && this.state.phone_number.length <= 1) {
      this.setState({phone_number:""})
    }
}

onSubmit=()=>{
  const name=this.state.name
  const phone = this.state.phone_number
  const job = this.state.job_title
  const email=this.state.email
  const company= this.state.company
  const message = this.state.message
  const regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const err = "This field is required"

  if ((name=="") || (phone==="" || phone.length<10) || (email=="" || regEmail.test(email)==false) || (job=="") || (company=="") ||
  (message=="")){
      // window.location.href="#"
      if (name==""){
          this.setState({nameErr:err})
      }else{
          this.setState({nameErr:""})
      }
      if (email=="" || regEmail.test(email)==false){
          this.setState({emailErr:err})
      }else{
          this.setState({emailErr:""})
      }
      if (phone=="" || phone.length < 10){
          this.setState({phone_numberErr:"Phone number must be at least 10 digits"})
      }else{
          this.setState({phone_numberErr:""})
      }
      if (job==""){
          this.setState({job_titleErr:err})
      }else{
          this.setState({job_titleErr:""})
      }
      if (company==""){
          this.setState({companyErr:err})
      }else{
          this.setState({companyErr:""})
      }
      if (message==""){
          this.setState({messageErr:err})
      }else{
          this.setState({messageErr:""})
      }
  }else{
      const data ={
          name:this.state.name,
          email:this.state.email,
          company:this.state.company,
          job_title:this.state.job_title,
          message:this.state.message,
          phone:this.state.phone_number
      }
    axios.post(`${API}/api/employer/create`, data)
    .then(response => {
      ReactPixel.track('TalkToUs','Submit Form'); 
        this.setState({success:true})
        GAevent('Talk-to-Us','Submit Form') 
    }).catch((err)=>{
        console.log("failed");
    })
  }
 
}



  

  render() {

    if (this.state.data=="") {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }
  

    return (
      <div>
        <Nav />
        <div className="content talk-t2">
            <div className="page-container mt-80 master-page width-100">
              <div className="box-talk-to">
              { this.state.data !== undefined && this.state.data.length > 0 ?this.state.data.map((item,i)=>{
                if (item.title=="title_TalktoUs"){
                return(
                  <Zoom>
                <div className="flex column center width-100">
                  <h1 className="title leagueSpartan">{item.subtitle} </h1>
                  <div className="prh-box">
                      <p dangerouslySetInnerHTML={{ __html: item.description}}/>
                  </div>
                </div>
                </Zoom>
                 )}}):""}
                <div className="flex flex-end width-100 col-mobile">
                    <div className="box-img-talk">
                      <Slide bottom>
                        <LazyLoadImage effect="blur" src={talkTo}  className="img-rtl" />
                        </Slide>
                        <div className="list-IC">
                        { this.state.data !== undefined && this.state.data.length > 0 ?this.state.data.map((item,i)=>{
                          if (item.title=="email_TalktoUs"){
                          return(
                          <div className="flex width-100 bx-icon">
                            <LazyLoadImage effect="blur" src={`${API}/api/employer_dynamic/image/${item.img}`} className="ic" />
                            <div className="flex column">
                              <h5>{item.subtitle} </h5>
                              <a className="App-link" href={`mailto:sales.inquiry@karyatalents.com`}
                                rel="noopener noreferrer"  dangerouslySetInnerHTML={{ __html: item.description}}/>
                  
                              {/* <p>salesinquiry@karyatalents.com</p> */}
                            </div>
                          </div>
                           )}}):""}
                            { this.state.data !== undefined && this.state.data.length > 0 ?this.state.data.map((item,i)=>{
                          if (item.title=="LinkedIn_TalktoUs"){
                          return(
                          <div className="flex width-100 bx-icon lkn">
                            <LazyLoadImage effect="blur" src={`${API}/api/employer_dynamic/image/${item.img}`} className="ic" />
                            <div className="flex column">
                              <h5>{item.subtitle} </h5>
                              <a href="https://id.linkedin.com/company/karya-talents"   rel="noopener noreferrer"  dangerouslySetInnerHTML={{ __html: item.description}}/>
                            </div>
                          </div>
                           )}}):""}
                        </div>
                    </div>
                    <div className="flex column tK-list">
                        <div className="box-txf width-100 flex column">
                            <div className="flex width-100">
                                <p className="st">Name </p>
                                <p className="req">*</p>
                            </div>
                            <TextField  type="text" className="width-100 input-txf"  value={this.state.name} onChange={this.onChangeName} label="Name" variant="outlined"  name="Name" />
                            {/* <input type="text" className="width-100 input-txf" placeholder="Name" value={this.state.name} onChange={this.onChangeName} /> */}
                            <span style={{color: "red", fontSize:"12px"}}>{this.state.nameErr}</span>
                        </div>
                        <div className="box-txf width-100 flex column">
                            <div className="flex width-100">
                                <p className="st">Email </p>
                                <p className="req">*</p>
                            </div>
                            <TextField  type="text" className="width-100 input-txf"  value={this.state.email} onChange={this.changeEmail} label="Email" variant="outlined"  name="Email" />
                            {/* <input type="text" className="width-100 input-txf" placeholder="Email" value={this.state.email} onChange={this.changeEmail} /> */}
                            <span style={{color: "red", fontSize:"12px"}}>{this.state.emailErr}</span>
                        </div>
                        <div className="box-txf width-100 flex column">
                            <div className="flex width-100">
                                <p className="st">Phone </p>
                                <p className="req">*</p>
                            </div>
                            <TextField  type="text" className="width-100 input-txf"  value={this.state.phone_number} onKeyDown={this.onKeyDown} ref={this.input} onChange={this.onChangePhone} label="Phone" variant="outlined"  name="Phone" />
                            {/* <input type="text" className="width-100 input-txf" placeholder="Phone" value={this.state.phone_number} onKeyDown={this.onKeyDown} ref={this.input} onChange={this.onChangePhone} /> */}
                            <span style={{color: "red", fontSize:"12px"}}>{this.state.phone_numberErr}</span>
                        </div>
                        <div className="box-txf width-100 flex column">
                            <div className="flex width-100">
                                <p className="st">Company </p>
                                <p className="req">*</p>
                            </div>
                            <TextField  type="text" className="width-100 input-txf"  value={this.state.company} onChange={this.changeCompany} label="Company" variant="outlined"  name="Company" />
                            {/* <input type="text" className="width-100 input-txf" placeholder="Company" value={this.state.company} onChange={this.changeCompany} /> */}
                            <span style={{color: "red", fontSize:"12px"}}>{this.state.companyErr}</span>
                        </div>
                        <div className="box-txf width-100 flex column">
                            <div className="flex width-100">
                                <p className="st">Job Title </p>
                                <p className="req">*</p>
                            </div>
                            <TextField  type="text" className="width-100 input-txf"  value={this.state.job_title} onChange={this.changeJob} label="Job Title" variant="outlined"  name="Job Title" />
                            {/* <input type="text" className="width-100 input-txf" placeholder="Job Title" value={this.state.job_title} onChange={this.changeJob} /> */}
                            <span style={{color: "red", fontSize:"12px"}}>{this.state.job_titleErr}</span>
                        </div>
                        <div className="box-txf width-100 flex column area">
                            <div className="flex width-100">
                                <p className="st">Message </p>
                                <p className="req">*</p>
                            </div>
                            <textarea  type="text" className="width-100 input-txf"  value={this.state.message} onChange={this.changeMessage} label="Message" variant="outlined"   placeholder="Message" name="Message" ></textarea>
                            {/* <input type="textarea" className="width-100 input-txf" placeholder="Message" value={this.state.message} onChange={this.changeMessage} /> */}
                            <span style={{color: "red", fontSize:"12px"}}>{this.state.messageErr}</span>
                        </div>
                        <div className="box-txf width-100 flex bt space-between">
                            <button onClick={this.onSubmit}>
                                <p>Submit</p>
                            </button>
                            <div className="flex">
                                <p className="star">*</p>
                                <p className="text">All Fields are required</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <WhatsAppWidget phoneNumber='6287717888800' companyName="Karya Talents"/>
        </div>
        <Modal id="success" visible={this.state.success} effect="fadeInUp">
            <div  className="modal_apply">
                <div className="width-100 flex center">
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={apply_success} style={{marginTop:"24px", marginBottom:"24px"}}/>
                </div>
                <h5>Success!</h5>
                <p  style={{width:"90%", marginLeft:"5%"}}>Your inquiry has been submitted!</p>
                <div className="width-100 flex center">
                    <button onClick={()=>{ window.location.reload()}} style={{marginBottom:"20px"}}>
                        <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                    </button>
                </div>
            </div>
        </Modal>
        {/* <NotificationContainer/> */}
        <ScrollToTop/>
        <Footer />
      </div>
    );
  }
}



