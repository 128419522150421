import React, { Component } from 'react';
import lp_header from '../images/lp_header.svg';
import lp_body1 from '../images/lp_body1.svg';
import lp_skill from '../images/lp_skill.svg';
import lp_online from '../images/lp_online.svg';
import lp_self from '../images/lp_self.svg';
import lp_icon from '../images/lp_icon_list.svg';
import lp_self_discovery from '../images/lp_self_discovery.jpg';
import prev from '../images/lp-icon-prev.svg';
import next from '../images/lp-icon-next.svg';
import logo from '../images/KaryaTalents_logo.svg';
import lp_sky from '../images/lp_bg_sky.png';
import lp_key from '../images/lp_key.svg';
import lp_certification from '../images/lp_certification.svg';
import ig from '../images/ig.png';
import yt from '../images/yt.png';
import lk from '../images/in.png';
import fb from '../images/fb.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { connect } from 'react-redux';
import {  API} from "../_redux/helper";
import { getAllProgramDLP } from '../_actions/jobs';
import { Link } from 'react-router-dom';
import { GAevent } from '../components/TrackingEvent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";


class componentName extends Component {
  
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getAllProgramDLP();
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
  }

 
    learnMore=(id,name, vd)=>{
        sessionStorage.setItem("pr-1", id)
        sessionStorage.setItem("pic", name)
        sessionStorage.setItem("vd", vd)
        window.location.href=`/Karya_Learning_Program/${id}`
    }

    render() {
        const {dataAllProgramDLP} = this.props.dataAllProgramDLP1;
    

    const settings = {
      //   dots: true,
      infinite: true,
      slidesToShow: 1,
      arrow: true,
      slidesToScroll: 1,
      prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={prev} style={{ height: '42px', width: '42px' }} />,
      nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={next} />,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <div className='landing-page'>
          <Link to='/'>
            <div className='header-landing-page'>
              <LazyLoadImage effect="blur"
                src={logo}
                className='logo'
                style={{ height: '31px', margin: 'auto' }}
              />
            </div>
          </Link>
          <div className='container-landing'>
            <div className='page-container '>
              <div className='page-container-outer' style={{ marginBottom: '0px' }}>
                <div className='flex width-100  lp-top'>
                  <div className='flex width-100 space-between lp-inner-top'>
                    <div className='mobile lp-right'>
                      <div className='flex width-100 height-100'>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_header} className='auto width-100' />
                      </div>
                    </div>
                    <div className='flex column lp-left'>
                      <div className='auto text-landing-page'>
                        <h1>Transformer Program</h1>
                        <p className='text-p'>
                          Be more employable and transform into super employee
                        </p>
                        {/* <Link to='/Karya_Learning_Program/1'> */}
                          <button onClick={()=>window.location.href="/Karya_Learning_Program/1"}>
                            <p>Learn More</p>
                          </button>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className='dekstop lp-right'>
                      <div className='flex width-100 height-100'>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_header} className='auto width-100' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='page-container' style={{ backgroundColor: '#FFFFFF' }}>
              <div className='page-container-outer'>
                <div className='flex lp-learn-box width-100'>
                  <div
                    className='width-50 flex'
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <LazyLoadImage effect="blur"
                      src={lp_body1}
                      style={{ height: 'auto', width: '100%' }}
                    />
                  </div>
                  <div className='width-50 flex center'>
                    <div className='width-100 flex column mg-box-lp'>
                      <div className='box-lp'>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_skill} />
                        <div>
                          <h1 style={{ fontSize: '14px' }}>Learn new Skills</h1>
                          <p>
                            with our comprehensive learning session, you will
                            learn new skills that will impact your future.
                          </p>
                        </div>
                      </div>
                      <div className='box-lp' style={{ marginTop: '16px' }}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_online} />
                        <div>
                          <h1>100% Online Learning</h1>
                          <p>
                            It’s easy to access. Study anytime, anywhere, and
                            determine how fast you’ll study.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='width-100 center flex column box-discov'>
                  <div className='flex width-100 center'>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_self} />
                    <h1>Self Discovery</h1>
                  </div>
                  <div className='flex width-100 center'>
                    <p>
                      Get to know more about yourself. Discover your best
                      potential and develop to become the best version of you
                      through our Values, Interest, and Personality Assessments.
                    </p>
                  </div>
                </div>
                <div className='width-100 flex space-between content-discov b-175'>
                  <div className='width-30 flex column'>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_self_discovery} className='width-100' />
                    <h1 className='HK-Grotesk-Bold'>Lorem Ipsum</h1>
                    <p>
                      Quam senectus malesuada imperdiet adipiscing etiam cursus
                      tellus turpis. Libero et id volutpat nisi netus
                      consectetur lobortis senectus cursus.
                    </p>
                  </div>
                  <div className='width-30 flex column'>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_self_discovery} className='width-100' />
                    <h1 className='HK-Grotesk-Bold'>Lorem Ipsum</h1>
                    <p>
                      Quam senectus malesuada imperdiet adipiscing etiam cursus
                      tellus turpis. Libero et id volutpat nisi netus
                      consectetur lobortis senectus cursus.
                    </p>
                  </div>
                  <div className='width-30 flex column'>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_self_discovery} className='width-100' />
                    <h1 className='HK-Grotesk-Bold'>Lorem Ipsum</h1>
                    <p>
                      Quam senectus malesuada imperdiet adipiscing etiam cursus
                      tellus turpis. Libero et id volutpat nisi netus
                      consectetur lobortis senectus cursus.
                    </p>
                  </div>
                </div>
                <div className='flex width-100 box-lp-access column'>
                  <div className='flex width-100 center'>
                    <h1 className='lp-title HK-Grotesk-Bold'>
                      What You’ll Get
                    </h1>
                  </div>
                  <div className='flex width-100  b-125'>
                    {/* <div className="width-50 flex">
                                           <div className="box-card width-100 flex center column" >
                                               <div className="inner_box">
                                                    <h1>Lifetime Access</h1>
                                                    <p style={{marginTop:"14px"}}>Never have to worry about forgetting what you have learned. You can always review everything.</p>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="width-50 flex">
                                           <div className="box-card width-100 flex center column" style={{backgroundImage:`url(${lp_bg_certificate})`}}>
                                               <div className="inner_box">
                                                    <h1>Get Your Certificate</h1>
                                                    <p style={{marginTop:"14px"}}>You can download your certificate after completing the program.</p>
                                               </div>
                                           </div>
                                       </div> */}
                    <div className='flex column lp-box-card'>
                      <div className='flex width-100 lp-bullets space-between'>
                        <div className='flex ' style={{ paddingLeft: '12px' }}>
                          <div
                            className='bul-14'
                            style={{ backgroundColor: '#F14444' }}
                          />
                          <div
                            className='bul-14'
                            style={{ backgroundColor: '#5DF144' }}
                          />
                          <div
                            className='bul-14'
                            style={{ backgroundColor: '#F1EA44' }}
                          />
                        </div>
                        <h4 className='HK-Grotesk'>Karya Learning</h4>
                        <div style={{ width: '70px' }} />
                      </div>
                      <div
                        className='box-card width-100 flex space-between'
                        style={{ backgroundImage: `url(${lp_sky})` }}
                      >
                        <div className='flex center key'>
                          <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_key} />
                        </div>
                        <div className='inner_box'>
                          <h1>Lifetime Access</h1>
                          <p style={{ marginTop: '14px' }}>
                            Never have to worry about forgetting what you have
                            learned. You can always review everything.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='flex column lp-box-card'>
                      <div className='flex width-100 lp-bullets space-between'>
                        <div className='flex ' style={{ paddingLeft: '12px' }}>
                          <div
                            className='bul-14'
                            style={{ backgroundColor: '#F14444' }}
                          />
                          <div
                            className='bul-14'
                            style={{ backgroundColor: '#5DF144' }}
                          />
                          <div
                            className='bul-14'
                            style={{ backgroundColor: '#F1EA44' }}
                          />
                        </div>
                        <h4 className='HK-Grotesk'>Karya Learning</h4>
                        <div style={{ width: '70px' }} />
                      </div>
                      <div
                        className='box-card width-100 flex space-between'
                        style={{ backgroundImage: `url(${lp_sky})` }}
                      >
                        <div className='flex center key'>
                          <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_certification} />
                        </div>
                        <div className='inner_box'>
                          <h1>Get Your Certificate</h1>
                          <p style={{ marginTop: '14px' }}>
                            You can download your certificate after completing
                            the program.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex width-100 box-lp-access column b-175'>
                  <div className='flex width-100 center'>
                    <h1 className='lp-title HK-Grotesk-Bold'>
                      Our Specialized Programs
                    </h1>
                  </div>
                  <Slider {...settings} className='flex center'>
                    {dataAllProgramDLP.map((item, i) => {
                       /* eslint eqeqeq: 0 */
                      if (i == 0) {
                        return (
                          <div
                            key={i}
                            className='lp-box-program width-100 flex space-between i-flex'
                          >
                            <div className='mobile width-100'>
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '0px 10px 10px 0px',
                                }}
                              >
                                <LazyLoadImage effect="blur"
                                  className='height-100 width-100'
                                  src={`${API}/File/DLP/Program/${item.photo}`}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                            </div>
                            <div className='flex column width-55'>
                              <div style={{ padding: '24px 24px 20px 24px' }}>
                                <h1>{item.program_name}</h1>
                                <p className='text-desc'>
                                  {item.program_desc.slice(0, 200) + '...'}{' '}
                                </p>
                                <div className='box-dsc'>
                                  <p>FREE</p>
                                </div>
                                <div className='flex width-100 column'>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.course_type}</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.time_period}</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>All</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.language}</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.benefit}</p>
                                  </div>
                                  <button
                                    className='lp-btn-more'
                                    onClick={() => {
                                      this.learnMore(item.id, item.photo, item.video_free);
                                    }}
                                  >
                                    <p>Learn more</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className='dekstop' style={{ width: '45%' }}>
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '0px 10px 10px 0px',
                                }}
                              >
                                <LazyLoadImage effect="blur"
                                  className='height-100 width-100'
                                  src={`${API}/File/DLP/Program/${item.photo}`}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            className='lp-box-program width-100 flex space-between i-flex'
                          >
                            <div className='mobile width-100'>
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '0px 10px 10px 0px',
                                }}
                              >
                                <LazyLoadImage effect="blur"
                                  className='height-100 width-100'
                                  src={`${API}/File/DLP/Program/${item.photo}`}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                            </div>
                            <div className='flex column width-55'>
                              <div style={{ padding: '24px 24px 20px 24px' }}>
                                <h1>{item.program_name}</h1>
                                <p className='text-desc'>
                                  {item.program_desc.slice(0, 200) + '...'}{' '}
                                </p>
                                <div className='box-dsc'>
                                  <p>FREE</p>
                                </div>
                                <div className='flex width-100 column'>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.course_type}</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.time_period}</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>All</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.language}</p>
                                  </div>
                                  <div className='flex width-100 list_landing'>
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                    <p>{item.benefit}</p>
                                  </div>
                                  <button
                                    className='lp-btn-more'
                                    onClick={() => {
                                      this.learnMore(item.id, item.photo, item.video_free);
                                    }}
                                  >
                                    <p>Learn more</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className='dekstop' style={{ width: '45%' }}>
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '0px 10px 10px 0px',
                                }}
                              >
                                <LazyLoadImage effect="blur"
                                  className='height-100 width-100'
                                  src={`${API}/File/DLP/Program/${item.photo}`}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            <footer className='footer'>
              <div className='footer-container'>
                <div className='flex width-100 space-between dekstop'>
                  <div className='flex '>
                    <div
                      className='flex column width-80'
                      style={{ marginRight: '78px' }}
                    >
                      <p style={{ fontWeight: 'bold' }}>Our Office</p>
                      <p>Karya Talents</p>
                      <p style={{ marginBottom: '0px' }}>
                        PT. Bina Sumberdaya Indonesia,{' '}
                      </p>
                      <p style={{ marginBottom: '0px' }}>
                        AXA Tower 37th floor,
                      </p>
                      <p style={{ marginBottom: '0px' }}>
                        Jl. Dr. Satrio Kav 18 Kuningan
                      </p>
                      <p>Jakarta Selatan 12940</p>
                    </div>

                    <div className='flex column' style={{ width: '42%' }}>
                      <Link to='/About'>
                        {' '}
                        <p style={{ fontWeight: 'bold', marginBottom: '12px' }}>
                          About Us
                        </p>
                      </Link>
                      <Link to='/ContactUs'>
                        {' '}
                        <p>Contact Us</p>
                      </Link>
                      <Link to='/Privacy'>
                        <p>Privacy Policy</p>{' '}
                      </Link>
                      <Link to='/Terms-and-Conditions'>
                        {' '}
                        <p>Terms of Service</p>{' '}
                      </Link>
                    </div>
                  </div>
                  <div className='column'>
                    <p style={{ fontWeight: 'bold', marginBottom: '12px' }}>
                      Follow Us
                    </p>
                    <div className='flex iconFooter'>
                      <LazyLoadImage effect="blur"
                        src={fb}
                        onClick={() => {
                          GAevent('Follow Us', 'Fecebook');
                        }}
                      />
                      <a
                        href='https://instagram.com/karyatalents?igshid=odlswb4q0a06'
                          rel="noopener noreferrer"
                      >
                        <LazyLoadImage effect="blur"
                          src={ig}
                          onClick={() => {
                            GAevent('Follow Us', 'Instagram');
                          }}
                        />
                      </a>
                      <LazyLoadImage effect="blur"
                        src={yt}
                        onClick={() => {
                          GAevent('Follow Us', 'Youtube');
                        }}
                      />
                      <a
                        href='https://www.linkedin.com/company/karya-talents'
                          rel="noopener noreferrer"
                      >
                        <LazyLoadImage effect="blur"
                          src={lk}
                          onClick={() => {
                            GAevent('Follow Us', 'Linkedin');
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>

                {/* for mobile */}
                <div className=' width-100  mobile-information'>
                  <div
                    className='flex width-100 space-between'
                    style={{ marginBottom: '32px' }}
                  >
                    <div className='flex column width-50'>
                      <p style={{ fontWeight: 'bold' }}>Our Office</p>
                      <p>Karya Talents</p>
                      <p style={{ marginBottom: '0px' }}>
                        PT. Bina Sumberdaya Indonesia,{' '}
                      </p>
                      <p style={{ marginBottom: '0px' }}>
                        AXA Tower 37th floor,
                      </p>
                      <p style={{ marginBottom: '0px' }}>
                        Jl. Dr. Satrio Kav 18 Kuningan
                      </p>
                      <p>Jakarta Selatan 12940</p>
                    </div>
                    <div className='flex column'>
                      <Link to='/About'>
                        {' '}
                        <p style={{ fontWeight: 'bold', marginBottom: '12px' }}>
                          About Us
                        </p>
                      </Link>
                      <Link to='/ContactUs'>
                        {' '}
                        <p>Contact Us</p>
                      </Link>
                      <Link to='/Privacy'>
                        <p>Privacy Policy</p>{' '}
                      </Link>
                      <Link to='/Terms-and-Conditions'>
                        {' '}
                        <p>Terms of Service</p>{' '}
                      </Link>
                    </div>
                  </div>
                  <div className='column width-100'>
                    <p style={{ fontWeight: 'bold', marginBottom: '12px' }}>
                      Follow Us
                    </p>
                    <div className='flex iconFooter'>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={fb} />
                      <a
                        href='https://instagram.com/karyatalents?igshid=odlswb4q0a06'
                         
                        rel='noopener noreferrer'
                      >
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={ig} />
                      </a>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={yt} />
                      <a
                        href='https://www.linkedin.com/company/karya-talents'
                         
                        rel='noopener noreferrer'
                      >
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lk} />
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="copyright">
                            © 2020 KerjaKarya, All rights reserved
                        </div> */}
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dataAllProgramDLP1: state.allProgramDLPRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllProgramDLP: () => {
      dispatch(getAllProgramDLP());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(componentName);
