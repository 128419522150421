import React, { Component } from 'react';
import dlp_time from "../images/dlp_time.png";
import dlp_list from "../images/dlp_list.png";
import go_icon from "../images/go_icon.svg";
import {getUserProgramDLP } from "../_actions/jobs"
import { connect } from "react-redux";
import { API_DLP } from "../_redux/helper"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {GAevent} from './TrackingEvent'
import ReactPixel from 'react-facebook-pixel';



class D_Course extends Component {
    constructor() {
        super();
        this.state ={
          params1: "Completed",
          params2: "On-Going",
          val: "All",
      }
    } 

    componentDidMount() {
        let value = sessionStorage.getItem("i-dlp") ? sessionStorage.getItem("i-dlp") : localStorage.getItem("i-dlp")
        this.props.getUserProgramDLP(value);
      }

      setRating=(e, program, ratting_show, program_progress, completed_date)=>{
          const ratting = parseInt(ratting_show)
          const progress = parseInt(program_progress)
         
           /* eslint eqeqeq: 0 */
          if ((e==null || e=="") && (progress >= ratting)){
            sessionStorage.setItem("rating-program",true)
          }else{
            sessionStorage.setItem("rating-program",false)
          }

          setTimeout(() => {
              window.location.href=`/DigitalLearningCenter/${program}/CourseOutline`
          }, 1000);
      }

   


    filter=(e)=>{
        if (e==1){
         this.setState({params1:"On-Going",params2:"",val:"On-Going"});
        }else if (e==2){
         this.setState({params1:"Completed", params2:"", val:"Completed"});
        }else{
         this.setState({params1:"Completed", params2:"On-Going", val:"All"})
        }
        GAevent(`Filter Progress - ${window.location.pathname}`,'Filter Progress') 
        ReactPixel.track(`Filter Progress - ${window.location.pathname}`,'Filter Progress'); 
     }


    render() {
        const {dataUserProgramDLP} = this.props.detail1;


        return (
            <div>
                <div className="flex column width-100">
                    <div className="flex column" style={{padding:"15px 24px"}}>
                        <div className="flex column width-100">
                            <p>Progress</p>
                            <div className="k-box-acc width-30" style={{height:"46px", width:"149px", margin:"12px 0px 24px 0px"}}>
                              
                                <select className="form-control width-50" onChange={(e)=>{this.filter(e.target.value)}}>
                                      <option disabled selected value={0} style={{display:"none"}} className="openSans"> {this.state.val}</option>
                                       <option value={1} className="openSans">On Going</option>
                                       <option value={2} className="openSans">Completed</option>
                                       <option value={3} className="openSans">All</option>
                                    </select>
                            </div>
                        </div>
                        <div className="flex width-100 space-between line-D-course">
                            <h3>Specialized Program</h3>
                            <div  />
                        </div>
                       <div className="width-100 dekstop">
                        <div className="flex with-100 column">
                            {dataUserProgramDLP.map((item,i)=>{
                                // if ((item.status==(`${this.state.params1}`)) || (item.status==(`${this.state.params2}`)) && (Number(new Date(item.end_date)) > Number(new Date()))){
                                if ((item.status==(`${this.state.params1}`) || item.status==(`${this.state.params2}`)) && (Number(new Date(item.end_date)) > Number(new Date()))){
                                return(
                            <div key={i} className="flex width-100 list-course" style={{display:(item.status=="On-Going" && item.time_remaining=="1 Days")?"none":""}}>
                                <div style={{padding:"14px 2%", width:"76%"}} >
                                    <h1>{item.program_name} </h1>
                                    <p className="openSans" style={{color:"#757575"}} >{item.status=="Completed"?"Congratulations! You've completed the program":"Let’s go complete the program before it ends!"}</p>
                                    <div className="flex dlp-times">
                                        <button style={{borderColor:(item.status=="On-Going")?"":" #3B6C98" }}>
                                            <p className="openSans" style={{color:(item.status=="On-Going")?"":" #3B6C98" }}>{item.status} </p>
                                        </button>
                                        {(item.status=="On-Going") && <button className="flex" style={{borderColor:"#333333"}}>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_time} />
                                            <p className="openSans" style={{color:"#333333"}}>{item.time_remaining} </p>
                                        </button> }
                                    </div>
                                    <div className="dlp-progress">
                                        <div className="width-100 flex height-100">
                                            <p className="alp">Your Progress:</p>
                                            <p className="numb">{item.program_progress} </p>
                                            <div className="progressBar">
                                                <div className="flex width-100 height-100 " style={{backgroundColor:"#E2BE81", width:`${item.program_progress}`}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex column dlp_go space-between" style={{padding:"14px 2%", width:"16%"}} >
                                    <div className="flex width-100 img_go">
                                        <LazyLoadImage effect="blur" loading="lazy" src={`${API_DLP}/api/getProgram/image/${item.img}`} alt={dlp_list} className="flex width-100 " />
                                    </div>
                                    {item.status &&
                                    <div className="flex width-100 flex-end">
                                        {/* <Link to={`/DigitalLearningCenter/${item.program_id}/CourseOutline`}> */}
                                        {(item.status=="On-Going") &&  
                                        <button className="flex"  onClick={()=>{this.setRating(item.ratting, item.program_id, item.ratting_show, item.program_progress, item.completed_date)}}>
                                            <p>GO</p>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={go_icon} />
                                        </button>}
                                        {(item.status=="Completed") &&
                                        <button className="flex" style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98"}}   onClick={()=>{this.setRating(item.ratting, item.program_id, item.ratting_show, item.program_progress, item.completed_date)}}>
                                            <p>VIEW</p>
                                        </button>}
                                        {/* </Link> */}
                                    </div>}
                                </div>
                            </div>
                            )}})}
                            </div>  
                        </div>
                        <div className="width-100 mobile" style={{marginBottom:"30px", marginTop:"24px"}}>
                        <div className="flex width-100 column">
                        {dataUserProgramDLP.map((item,i)=>{
                                // if ((item.status==(`${this.state.params1}`)) || (item.status==(`${this.state.params2}`)) && (Number(new Date(item.end_date)) > Number(new Date()))){
                                if ((item.status==(`${this.state.params1}`) || item.status==(`${this.state.params2}`)) && (Number(new Date(item.end_date)) > Number(new Date()))){
                                return(
                            <div className="flex column width-100 list-course" style={{display:(item.status=="On-Going" && item.time_remaining=="1 Days")?"none":""}}>
                                <div style={{padding:"14px"}} >
                                <div className="flex width-100 img_go ">
                                <LazyLoadImage effect="blur" loading="lazy" src={`${API_DLP}/api/getProgram/image/${item.img}`} alt={dlp_list} className="flex width-100 " />
                                </div>
                                <div className="flex column" style={{padding:"14px 0%", width:"100%"}} >
                                    <h1>{item.program_name}</h1>
                                    <p className="openSans desc-course" style={{color:"#757575"}} >{item.status=="Completed"?"Congratulations! You've completed the program":"Let’s go complete the program before it ends!"}</p>
                                    <div className="flex dlp-times">
                                        <button style={{borderColor:(item.status=="On-Going")?"":" #3B6C98" }}>
                                            <p className="openSans" style={{color:(item.status=="On-Going")?"":" #3B6C98" }}>{item.status}</p>
                                        </button>
                                        {(item.status=="On-Going") &&
                                        <button className="flex" style={{borderColor:"#333333"}}>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlp_time} />
                                            <p className="openSans" style={{color:"#333333"}}>{item.time_remaining}</p>
                                        </button>}
                                    </div>
                                    <div className="dlp-progress">
                                        <div className="width-100 flex column height-100">
                                            <div className="flex width-100" style={{marginTop:"8px"}}>
                                                <p className="alp">Your Progress:</p>
                                                <p className="numb">{item.program_progress}</p>
                                            </div>  
                                            <div className="progressBar">
                                                <div className="flex width-100 height-100 " style={{backgroundColor:"#E2BE81", width:`${item.program_progress}`}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex column dlp_go flex-end" style={{padding:"14px 0% 0px", width:"97"}} >
                                     {item.status &&
                                    <div className="flex width-100 flex-end">
                                        {/* <Link to={`/DigitalLearningCenter/${item.program_id}/CourseOutline`}> */}
                                        {(item.status=="On-Going") &&  
                                        <button className="flex"  onClick={()=>{this.setRating(item.ratting, item.program_id, item.ratting_show, item.program_progress, item.completed_date)}}>
                                            <p>GO</p>
                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={go_icon} />
                                        </button>}
                                        {(item.status=="Completed") &&
                                        <button className="flex" style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98"}}  onClick={()=>{this.setRating(item.ratting, item.program_id, item.ratting_show, item.program_progress, item.completed_date)}}>
                                            <p>VIEW</p>
                                        </button>}
                                        {/* </Link> */}
                                    </div>}
                                </div>
                                </div>
                            </div>
                                )}})}
                            </div>  
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        detail1 : state.userProgramRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getUserProgramDLP: (id) => {
        dispatch(getUserProgramDLP(id));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(D_Course);
