import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarMiddle from "../components/SidebarMiddle";
import {getProfileId} from "../_actions/user";
import Account from "../components/Account"
import LeftBar from "../components/LeftBar";
import ReactGA from "react-ga";
import _MyProfile from "../components/_MyProfile";
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet"


class MyProfile extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: JSON.parse(sessionStorage.getItem("sidebar")),
      var_report: sessionStorage.getItem("report"),
      var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
      visible: true,
      height:"",
      ratting:0,
      cn:"",
    };
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.setState({height:window.innerHeight})
    window.scrollTo(0, 0)
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
  }

  

  handleCallback = (childData) =>{
    this.setState({link: !childData})
}

  getProfile=(user_id)=>{
    this.props.getProfileId(user_id);
  }


  render() {
    const { profile, isLoading  } = this.props.profile1;
    
  

    return (
      <div>
        <Helmet>
            <body className='homepage-22'></body>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            </Helmet>
        <div className="block HC-pages" style={{top:"0px"}}>
          <div className="NewHeader" style={{position:"unset", top:"0px"}}>
            <Account profile={profile}/>
            <div className="flex">
             <LeftBar parentCallback = {this.handleCallback}/>

            {/* CONTENT */}
            <div className="cubo width-100"  >
              <div className="block">
                <SidebarMiddle />
              </div>
              <_MyProfile profile={profile} getProfile={(e)=> this.getProfile(profile.id)} />
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);