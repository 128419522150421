import React, { useState, useEffect } from "react";
import {  generatePath, useParams, useHistory, useLocation } from "react-router-dom";
import { API_DLP } from "../_redux/helper"
import axios from 'axios';
import DlcPlayerVideo from "./DlcPlayerVideo";
import "videojs-plus/dist/plugins/unload";

const playerOptions = {};
// const children = 



export default function DynamicWithApi(props) {
  const [index_video, setIndexVideo] = useState("")
  const [player, setPlayer] = useState(null);
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const index = Number(params.index);
  const course_id = Number(params.course_id);
  const program_id = Number(params.program_id)
  const nameCourse = params.nameCourse
  const nameTitleCourse = params.nameTitleCourse
  const video = props.playlist[index];
  const max_id = Number(sessionStorage.getItem("course_type_id"))
  const list_video_id = sessionStorage.getItem("list-vd-id").split(',')
  const sort_video_id = list_video_id.sort()
  const index_id_video = sort_video_id.indexOf(`${course_id}`)
  const id_next_video = sort_video_id[index_id_video+1]
  const max_video_id = sort_video_id[index_id_video]

  let sources 
  props.playlist.map((x,i)=>{
      /* eslint eqeqeq: 0 */
    if (i==params.index){
      sources = x.sources
    }
  })
 

  useEffect(() => {

    if (player) {
      player.src(video.sources);
    }
  }, [video, player]);


  // auto play next
  useEffect(() => {
      let routeDynamic = location.pathname.split("/")
      routeDynamic.pop()
      routeDynamic.push(":index")
      let dynamicRoute = routeDynamic.join("/")
      getIndexVideo()

    if (player) {
      const onPlayerEnded = () => {
        props.updateProgres(props.id_video)
        axios
        .get(`${API_DLP}/api/updateuser_progress/${props.id_video}`)
        .then(({data}) => {
        props.getAllVideo()
        let nextIndex = index + 1;
        if (!props.playlist[nextIndex]) {    
          if (Number(max_video_id) >= Number(max_id)){
              window.location="/DigitalLearningCenter/"+ program_id +"/CourseOutline"
            }else{
              window.location="/DigitalLearningCenter/"+ program_id +"/"+ (id_next_video) +"/CourseOutline/"+nameTitleCourse+"/"+nameCourse+"/"+ "0"
          }
        }else{
          history.replace(generatePath(dynamicRoute, { index: nextIndex }));
          props.callBack(props.list_videos[nextIndex].name)
        }
      })
      };
      
     
      player.on("ended", onPlayerEnded);
      player.buffered(0)
      player.buffered(0).start(0)
      player.buffered(0).end(0)
 
  

      // player.playbackRate(2)


      // player.playbackRate(2)
      // player.on('ended', function(){
      //   props.updateProgres(props.id_video)
      // })
      const buf = player.buffered(0)
  

      return () => {
        player.off("ended", onPlayerEnded);
      };
    }
  }, [player, index, history]);

  const getIndexVideo = () =>{
    return new Promise (resolve => {
        axios
        .get(`${API_DLP}/api/getusercoursedetail_by_course/${sessionStorage.getItem("i-dlp")}/${params.course_id-1}`)
            .then((courses) => {
                courses.data.map((item,i)=>{
                    if (item.course_type=="Video"){
                        setIndexVideo(courses.data.length)
                    }
                })
            })
    })
  }



  return (
    <div className="dynamic">
      <DlcPlayerVideo
        playerOptions={playerOptions}
        onPlayerInit={setPlayer}
        onPlayerDispose={setPlayer}
        playBack={props.playBack}
      />
      {/* <Link to="/">Back To Home</Link>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start"
        }}
      >
        <h4 style={{ margin: "0 10px 0 0" }}>Click to switch video:</h4>
        <div className="props.playlist">
          {props.playlist.map(({ title }, index) => (
            <NavLink
              exact
              to={generatePath(ROUTES.DYNAMIC_WITH_API, { index })}
              style={{ marginRight: 5 }}
              key={title}
            >
              {index}
            </NavLink>
          ))}
        </div>
      </div> */}
    </div>
  );
}
