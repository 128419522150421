import React, { Component } from 'react'
import Login from '../components/Login'
import Register from '../components/Register'
import ForgotPassword from '../components/ForgotPassword'
// import { Terms }from './Terms'
// import { Policy } from './Policy'
import Modal from 'react-awesome-modal';
import closemodal from '../images/closemodal.png'
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
var $ = require("jquery");

export default class InOutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      register: false,
      forgotPassword: false,
      formModal:false,
      termsModal: false,
      policyModal: false,
      visible: false,
      TnC:false,
    }
  }

  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search)
    
  }

  openModal(e) {
     /* eslint eqeqeq: 0 */
    if (e == 'login') {
      this.setState({
        login: true
      });
      this.setState({
        formModal: true
      });
    }
    if (e == 'register') {
      this.setState({
        register: true
      });
      this.setState({
        formModal: true
      });
    }
    if (e == 'forgotPassword') {
      this.setState({
        forgotPassword: true
      });
    }
    if (e == 'termsModal') {
      this.setState({
        register: false
      })
      this.setState({
        termsModal: true
      });
    }
    if (e == 'policyModal') {
      this.setState({
        register: false
      })
      this.setState({
        policyModal: true
      });
    }
    if (window.innerWidth < 1000){
    $('#sett').hide();
    $('#sett').css("display","none");
    $('body').css('overflow', 'hidden');
    document.getElementById("sett").style.display = "none";
    
    }
  }

  closeModal() {
    this.setState({
      login: false,
      register: false,
      forgotPassword: false,
      termsModal: false,
      policyModal: false,
    });
    $('#sett').show();
    $('#sett').css("display","flex");
    $('body').css('overflow', 'auto');
    document.getElementById("sett").style.display = "flex";
    // document.getElementsByTagName("body")[0].style.overflow = "auto";
    
  }
  
  handleCallback = (e) =>{
    if (e!==""){
      this.setState({TnC:true})
    }else{
      this.setState({TnC:false})
    }

   
}

  render() {
  const {TnC, login,register,forgotPassword} = this.state
  const res = console.log("-_-");
  if (login || register || forgotPassword === true){
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  }else{
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  }

    return (
      <React.Fragment>
        <a value="Open"  id="test1" onClick={() => this.openModal('login')}>Login</a>
        <a value="Open"  id="test2" onClick={() => this.openModal('register')}>Sign Up →</a>
        <div id="test" style={{display: this.state.formModal ? 'block' : 'none' }} className='iO-m'>
          {/* Login modal */} 
          <Modal id="login" visible={this.state.login} effect="fadeInUp" onClickAway={TnC?res:() => {{this.closeModal()}  {this.setState({formModal:false})}}}>
          <div className="flex column reg-dummy-modal md1">
            <div id="modal-header" className="modal-header-sign">
              <a href="javascript:void(0);"onClick={() => {{this.closeModal()}  {this.setState({formModal:false})}}}><LazyLoadImage effect="blur" loading="lazy" alt="" src={closemodal} /></a>
            </div>
            <Login />
            <div className="modal-footer-signin">
              <p id="forgot-pass" style={{ textAlign: "center", color: " #3B6C98" }} value="Open" role="button"
                onClick={() => {
                  this.closeModal();
                  this.openModal('forgotPassword')
                }}
              >Forgot Password</p>
              <hr />
              <p>Don’t have an account?</p>
              <p className="get-started" style={{ color: "#3B6C98", fontWeight: "bold" }} value="Open" role="button"
                onClick={() => {
                  this.closeModal();
                  this.openModal('register')
                }}
              >Get Started</p>
            </div>
            </div>
          </Modal>
                
          {/* Register modal */}
          <Modal id="register" visible={this.state.register} effect="fadeInUp" onClickAway={TnC?res:() => {{this.closeModal()}  {this.setState({formModal:false})}
          //  {this.props.parentCallback("")}
        }}>
          <div className="flex column reg-dummy-modal md1 rG"  id="set-TnC">
            <div id="modal-header" className="modal-header-sign">
              <a href="javascript:void(0);" onClick={() => {{this.closeModal()}  {this.setState({formModal:false})}}}><LazyLoadImage effect="blur" loading="lazy" alt="" src={closemodal} /></a>
            </div>
            <Register parentCallback={this.handleCallback} />
            <div className="modal-footer-signup">
              <font style={{color: "#828282", fontSize: "16px", fontFamily: "Montserrat-regular"}}>Already have an account? </font> 
              <font style={{color: "#3B6C98", fontSize: "16px", fontWeight: "bold"}} value="Open" role="button"
                onClick={() => {
                  this.closeModal();
                  this.openModal('login')
                }}
              >Login</font>
            </div>
            </div>
          </Modal>

          {/* "Forgot Password" modal */}
          <Modal id="forgotPassword" visible={this.state.forgotPassword} effect="fadeInUp" onClickAway={TnC?res:() => {{this.closeModal()}  {this.setState({formModal:false})}}}>
          <div className="flex column reg-dummy-modal md1">
            <div id="modal-header" className="modal-header-sign">
              <a href="javascript:void(0);" onClick={() => {{this.closeModal()}  {this.setState({formModal:false})}}}><LazyLoadImage effect="blur" loading="lazy" alt="" src={closemodal} /></a>
            </div>
            <ForgotPassword />
            <div className="modal-footer-forgot-pass">
              <font style={{color: "#828282", fontSize: "16px", fontFamily: "Montserrat-regular"}}>I want try to </font>
              <font style={{ color: "#3B6C98", fontSize: "16px", fontWeight: "bold" }} value="Open" role="button"
                onClick={() => {
                  this.closeModal();
                  this.openModal('login')
                }}
              >Login</font>
            </div>
            </div>
          </Modal>

        </div>
      </React.Fragment>
    )
  }
}
