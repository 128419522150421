import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box';

function CircularAssessmentQuiz(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      textAlign: "center", 
      marginTop: "35px",
      marginBottom: "35px",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    top: {
      color: Number(props.resultQuiz.score) > Number(props.resultQuiz.minimumScore) ? "#219653" : "#F2994A",
      position: "absolute",
      left: 0
    },
    circle: {
      strokeLinecap: "round"
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={150}
          thickness={2}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          classes={{ circle: classes.circle }}
          className={classes.top}
          size={150}
          thickness={2}
          value={100}
        />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div>
          {/* {console.log(Number(props.resultQuiz.score) > Number(props.resultQuiz.minimumScore), Number(props.resultQuiz.score), Number(props.resultQuiz.minimumScore))} */}
          <p className={Number(props.resultQuiz.score) > Number(props.resultQuiz.minimumScore) ? "persentase-circular" : "persentase-circular-failed"}>{props.resultQuiz.score}</p><p className="number-of-quiz">{props.resultQuiz.passed} out of {props.resultQuiz.totalQuiz}</p>
        </div>
      </Box>
    </Box>
    </div>
  );
}

export default CircularAssessmentQuiz;