import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserProgramDLP} from "../_actions/jobs"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from "./Loading";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams } from "react-router-dom";
import { API_DLP } from "../_redux/helper"
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
var $ = require("jquery");

class DlcCourseQuiz extends Component {
  constructor() {
    super();
    this.state = {
        user_id: 0,
        list_quiz: [],
        isLoading: true,
        end_date_course: "",
        view:false,
        retake:false,
        user_prg:""
    };
  }

  async componentDidMount() {
    const {ownProps} = this.props.ownProps;
    var id = sessionStorage.getItem("i-dlp")
    this.props.getUserProgramDLP(id)
    axios.get(`${API_DLP}/api/getuserquiz/${id}/${ownProps.match.params.program_id}`)
    .then(({data}) => {
        // console.log(data,"ini adalah data quiz DlcCourseQuiz");
        this.setState({list_quiz:data})
        this.setState({isLoading : false})})
  }



  
  setView=()=>{
   this.setState({view:true, retake:false})
   sessionStorage.setItem("view",true)
  }

  setRetake=()=>{
    this.setState({view:false, retake:true})
    sessionStorage.setItem("view",false)
}

  render() {
    const {ownProps} = this.props.ownProps; 
    const {dataUserProgramDLP} = this.props.program1;

    let end_date_course
    dataUserProgramDLP.forEach(el => {
        // /* eslint eqeqeq: 0 */
        if (el.program_id == ownProps.match.params.program_id) {
            var d = new Date(el.end_date);
            end_date_course = `${el.end_date.split("T")[0].split("-").reverse().join("-")}, 23:59 WIB`
    }});

    if (this.state.isLoading) {
        return (
          <React.Fragment>
            <Loading />
          </React.Fragment>
        );
      }
    return (
        <React.Fragment>
        {/* {
            this.state.list_courses.map((list_course, index)=>{
                return( */}
                <div className="course-core-skill-dlc course-outline-dlc">
                    {
                    this.state.list_quiz.map((content_course, index) => {
                        return(
                        <React.Fragment key={index}>
                                {/* DEKSTOP */}
                                <div className="dekstop">
                                    <div className="container-title-accordion">
                                        <Typography className="title-content-course-quiz">{content_course.course_title}</Typography>
                                        <Typography className="title-content-time-quiz">{content_course.duration}</Typography>
                                            {
                                                !content_course.status ? "" :
                                                <div className={content_course.passing == "Passed!" ? "status-quis-passed":  content_course.passing == "On-Going" ? "status-quis-dlc" : "status-quis-failed"}>  
                                                    <p className={content_course.passing == "Passed!" ? "text-quis-passed": content_course.passing == "On-Going" ? "text-quis-dlc" : "text-quis-failed"}>{content_course.passing == "Passed!" ? "Passed!" : content_course.passing == "On-Going" ? "Submit Your Homework" : "Failed"}</p>
                                                </div>
                                            }
                                    </div>
                                </div>
                                {/* END DEKSTOP */}   
                                {/* MOBILE */}
                                <div className="mobile">
                                    <div className="container-title-accordion-mobile">
                                        <Typography className="title-content-course-quiz">{content_course.course_title}</Typography>
                                        <div className="row-content-quiz-mobile">
                                            <Typography className="title-content-time-mobile-dlpquiz">{content_course.duration}</Typography>
                                                { 
                                               !content_course.passing ? "" :
                                               <div className={content_course.passing == "Passed!" ? "status-quis-passed":  content_course.passing == "On-Going" ? "status-quis-dlc" : "status-quis-failed"}>  
                                                    <p className={content_course.passing == "Passed!" ? "text-quis-passed": content_course.passing == "On-Going" ? "text-quis-dlc" : "text-quis-failed"}>{content_course.passing == "Passed!" ? "Passed!" : content_course.passing == "On-Going" ? "Submit Your Homework" : "Failed"}</p>
                                                </div>
                                                }
                                        </div>
                                    </div>
                                </div>  
                                {/* END MOBILE */}
                                <div className="dekstop" > 
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                        <p className="duedate-quiz-dlp">Due date: {end_date_course}</p>
                                        {/* DEKSTOP */}
                                            <div className="notif-quiz-dlpquiz">
                                                <div className="flex space-between height-100">
                                                    <Fade top cascade>
                                                        <div className="flex width-80">
                                                            <p className="quiz-progres-text-dlpquiz">Quiz Progress : <span>{!content_course.result ? "0": "100"}%</span></p>
                                                        </div>
                                                    </Fade>
                                                    <div className="flex column barQuis">
                                                        <div className="progressQuis">
                                                            {
                                                                !content_course.result ?
                                                                <div style={{width: "2%",backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                :
                                                                <div style={{width: `100%`,backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* END DEKSTOP */}
                                        </Grid>
                                        <Grid item xs={12} sm={3} style={{display: "flex", justifyContent:"space-around"}}>
                                            <div className="container-box-grade-dlpquiz">
                                                <p>Passing Grade</p>
                                                <h2 className="value-grade">{!content_course.score_minimum ? "80" : Number(content_course.score_minimum)}</h2>
                                            </div>
                                            <div className="container-box-grade-dlpquiz">
                                                <p>Grade</p>
                                                <h2 className={!content_course.result ? "value-grade" : Number(content_course.result) >= 0 && Number(content_course.result) < Number(content_course.score_minimum) ? "value-grade-failed" : "value-grade-passed"}>{!content_course.result ? "-" : Number(content_course.result)}</h2>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {
                                                !content_course.result && content_course.result != 0 ?
                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/Quiz/"+content_course.course_title} onClick={this.setRetake}>
                                                            <Button className="btn-view-quiz" variant="contained">
                                                                <span>Start Quiz</span>
                                                            </Button>
                                                        </Link>
                                                    // :
                                                    //     <Button disabled className="btn-view-quiz-not-allowed" variant="contained">
                                                    //         <span>Start Quiz</span>
                                                    //     </Button>
                                                :
                                                <React.Fragment>
                                                    <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/Quiz/"+content_course.course_title} onClick={this.setView}>
                                                    <Button className="btn-view-quiz" variant="contained"  >
                                                        <span>View</span>
                                                    </Button>
                                                    </Link>
                                                    <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/Quiz/"+content_course.course_title} onClick={this.setRetake}>
                                                    <Button className="btn-retake-quis" variant="contained">
                                                        <span>Retake</span>
                                                    </Button>
                                                    </Link>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>

                                {/* MOBILE */}
                                <div className="mobile">
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                        <p className="duedate-quiz-dlp-mobile">Due date: {end_date_course}</p>
                                            {/* MOBILE */}
                                                <div className="notif-quiz-mobile-dlpquiz">
                                                    <div className="flex space-between height-100">
                                                        <div className="flex column barQuis-mobile">
                                                            <p className="quis-progres-text-mobile">Quiz Progress : <span>{!content_course.result ? "0": "100"}%</span></p>
                                                            <div className="progressQuis-mobile">
                                                                {
                                                                    !content_course.result ?
                                                                    <div style={{width: "2%",backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                    :
                                                                    <div style={{width: `100%`,backgroundColor: "#E2BE81", height:"100%", borderRadius:"3px"}}></div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            {/* END MOBILE */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{display: "flex", justifyContent:"space-around"}}>
                                            <div className="container-box-grade-dlpquiz">
                                                <p>Passing Grade</p>
                                                <h2 className="value-grade">{!content_course.score_minimum ? "80" : Number(content_course.score_minimum)}</h2>
                                            </div>
                                            <div className="container-box-grade-dlpquiz">
                                                <p>Grade</p>
                                                <h2 className={!content_course.result ? "value-grade" : Number(content_course.result) >= 0 && Number(content_course.result) < Number(content_course.score_minimum) ? "value-grade-failed" : "value-grade-passed"}>{!content_course.result ? "-" : Number(content_course.result)}</h2>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            {
                                                !content_course.result && content_course.result != 0 ?
                                                        <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline/Quiz/"+content_course.course_title} onClick={this.setRetake}>
                                                            <Button className="btn-view-quiz" variant="contained">
                                                                <span>Start Quiz</span>
                                                            </Button>
                                                        </Link>
                                                    // :
                                                    //     <Button disabled className="btn-view-quiz-not-allowed" variant="contained">
                                                    //         <span>Start Quiz</span>
                                                    //     </Button>
                                                :
                                                <React.Fragment>
                                                    <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/Quiz/"+content_course.course_title} onClick={this.setView}>
                                                    <Button className="btn-view-quiz" variant="contained" >
                                                        <span>View</span>
                                                    </Button>
                                                    </Link>
                                                    <Link to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/"+content_course.course_id+"/CourseOutline/Quiz/"+content_course.course_title} onClick={this.setRetake}>
                                                    <Button className="btn-retake-quis" variant="contained">
                                                        <span>Retake</span>
                                                    </Button>
                                                    </Link>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            <p className="border-bottom-quiz" style={{marginTop:"25px"}}></p>
                        </React.Fragment>
                        )
                    })
                    }
                </div>
                {/* )
            })
        } */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    // allProgress1: state.progressProfileRed,
    ownProps:ownProps,
    program1 :state.userProgramRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProgramDLP: (user_id) =>{
    dispatch(getUserProgramDLP(user_id))
    },
      
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DlcCourseQuiz);
