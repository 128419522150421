import rec_new from "../images/rec_new.png"
import React, { Component } from "react";
import karya from "../images/logoBaru.png";
import rec from "../images/rec_New.svg"
import shadow_JobFunction from "../images/shadow_JobFunction.svg"
import bgMobile from "../images/mobile_newJourney.png"
import shadow_JobFunction_mobile from "../images/shadow_mobile_journey.png"
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Session from '../components/Session'
import { getProfileId, putProfileId} from "../_actions/user";
import {getIndustri, getJobFunction,getJobLevel} from "../_actions/work";
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import Loading from "../components/Loading";
import {API} from '../_redux/helper'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';

class GetStarted extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      job_function_id:[],
      change_bg:false,
      job_count:0,
    }
    };


  goBack() {
    this.props.history.goBack();
  }
  componentDidMount(){
    Session.checkToken()
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    this.setState({ token: token1 });
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.props.getJobFunction();
    sessionStorage.setItem('setupTime', new Date().getTime())
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0)
  }

  selectJob = (e)=>{
    if (e===1){
      this.setState({change_bg:true})
    }
  }

  checkJob=(e,y)=>{
    const {job_function_id}=this.state
    let val = false
    if (job_function_id.indexOf(e) !== -1){
      val=true
    }
    return(val)
  }

  changeJob = (event, values) => {
    let job_function_id
    if (event===undefined || event==="" || event===null || event === this.state.job_function_id){
      job_function_id=""
    }else  if ( event === this.state.job_function_id && this.state.job_function_id.length){
      job_function_id=""
    }else{
      job_function_id = event
    }

    const cek1 =  [...this.state.job_function_id]
    const index = cek1.indexOf(job_function_id);
    if (this.state.job_function_id.length <5){
      if (index===-1){
        cek1.push(job_function_id)
    }else{
        cek1.splice(index,1)
    }
    this.setState({job_function_id: cek1})
    const job = {
      user_id : this.state.user_id,
      job_function_id: cek1
    }
    this.props.jobFunction11(job)
    GAevent('Job Function','Put Job Function') 
    }else if ((event === this.state.job_function_id[0])|| (event === this.state.job_function_id[1])||(event === this.state.job_function_id[2])
     ||(event === this.state.job_function_id[3])||(event === this.state.job_function_id[4])){
    cek1.splice(index,1)
    this.setState({job_function_id: cek1})
    const job = {
      user_id : this.state.user_id,
    job_function_id: cek1
    }
    this.props.jobFunction11(job)
    GAevent('Job Function','Post Job Function') 
    ReactPixel.track('Post Job Function - /JobFunction','Post Job Function') 
  }    
  };



  render() {
    const { profile } = this.props.profile1;
    const {dataJobFunction, isLoading, error} = this.props.jobFunction1;

    let btnContinue 
    if (this.state.job_function_id.length===0 || this.state.job_function_id.length===""){
      btnContinue = <button style={{marginBottom:"24px", backgroundColor:"#BDBDBD", borderColor:"#BDBDBD"}}>
                      <p>Continue</p>
                    </button>
    }else{
      btnContinue =  <Link to="/Industry">
                        <button className="bg-36 pointer br-5" style={{marginBottom:"24px"}}>
                          <p>Continue</p>
                        </button>
                    </Link>
    }


     if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }
  
    
    return (
      <div>
        <div className="edu welcome">
        <div className="box-wrapper jobFunction">
          <div className="wrapper " >
            <div className="content">
              <div className="page-container assessment-result width-100">
                <div className="width-100 dekstop">
                  <div className="background-top width-100" style={{background:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={rec} className="width-100" />
                  </div>
                </div>
                <div className="width-100 mobile">
                  <div className="background-top width-100" style={{background:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bgMobile} className="width-100" />
                  </div>
                </div>
                <div className="page-container-outer">
                  <div className="box-logo width-100 dekstop" style={{marginTop:"-218px"}}>
                    {/* <Link to="/"> */}
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya}  />
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="mobile-information width-100">
                  <div className="box-logo width-100">
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya} />
                    </div>
                  </div>
                  </div>
                  <div className="page-container-inner width-100" style={{marginTop:"-143px"}}>
                    <div className="box-content flex column center width-100">
                      <div className="width-100 dekstop">
                        <LazyLoadImage effect="blur" loading="lazy" alt=""  src={shadow_JobFunction}  className="bg_func width-100"/>
                     </div>
                     <div className="width-100 mobile">
                        <LazyLoadImage effect="blur" loading="lazy" alt=""  src={shadow_JobFunction_mobile}  className="bg_func width-100"/>
                     </div>
                     <div className="content-shadow width-100">
                       <p className="first_p">JOB FUNCTION</p>
                       <h1>Which Job Function are you interested in the most?</h1>
                       <p className="second_p">You can choose a maximum of 5 job functions</p>
                     </div>
                     <div className="buttons_jobFunction width-100" style={{marginTop:"24px"}}>
                       {dataJobFunction.map((item,i)=>(
                       item.icon && <button key={i} className="job_item"  onClick={(e)=>{this.changeJob(item.id)}} 
                            style={{
                              color: (((this.state.job_function_id[0]  === item.id )||(this.state.job_function_id[1]  === item.id ) ||(this.state.job_function_id[2]  === item.id )||(this.state.job_function_id[3]  === item.id )||(this.state.job_function_id[4]  === item.id ))? "#3B6C98":"" ||
                              this.state.job_function_id.length>=5 &&((this.state.job_function_id[0]  !== item.id )&&(this.state.job_function_id[1]  !== item.id ) &&(this.state.job_function_id[2]  !== item.id )&&(this.state.job_function_id[3]  !== item.id )&&(this.state.job_function_id[4]  !== item.id ))? "#BDBDBD":"" ),
                              borderColor: (((this.state.job_function_id[0]  === item.id )||(this.state.job_function_id[1]  === item.id ) ||(this.state.job_function_id[2]  === item.id )||(this.state.job_function_id[3]  === item.id )||(this.state.job_function_id[4]  === item.id ))? "#3B6C98":"" ||
                              this.state.job_function_id.length>=5 &&((this.state.job_function_id[0]  !== item.id )&&(this.state.job_function_id[1]  !== item.id ) &&(this.state.job_function_id[2]  !== item.id )&&(this.state.job_function_id[3]  !== item.id )&&(this.state.job_function_id[4]  !== item.id ))? "white":"" ),
                              backgroundColor: (((this.state.job_function_id[0]  === item.id )||(this.state.job_function_id[1]  === item.id ) ||(this.state.job_function_id[2]  === item.id )||(this.state.job_function_id[3]  === item.id )||(this.state.job_function_id[4]  === item.id ))? "#ECF1F5":"" ||
                              this.state.job_function_id.length>=5 &&((this.state.job_function_id[0]  !== item.id )&&(this.state.job_function_id[1]  !== item.id ) &&(this.state.job_function_id[2]  !== item.id )&&(this.state.job_function_id[3]  !== item.id )&&(this.state.job_function_id[4]  !== item.id ))? "white":"" )}}>
                         <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/Job_Function/${item.icon}`}  style={{opacity: this.state.job_function_id.length>=5 &&((this.state.job_function_id[0]  !== item.id )&&(this.state.job_function_id[1]  !== item.id ) &&(this.state.job_function_id[2]  !== item.id )&&(this.state.job_function_id[3]  !== item.id )&&(this.state.job_function_id[4]  !== item.id ))? "0.4":"", filter: (((this.state.job_function_id[0]  === item.id )||(this.state.job_function_id[1]  === item.id ) ||(this.state.job_function_id[2]  === item.id )||(this.state.job_function_id[3]  === item.id )||(this.state.job_function_id[4]  === item.id ))? "invert(37%) sepia(91%) saturate(318%) hue-rotate(167deg) brightness(87%) contrast(90%)":"")}} />
                         <p>{item.job_function_name} </p>
                       </button>
                       ))}
                     
                     </div>
                     <div className="width-100 btn_Continue">
                     {btnContinue}
                     </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
    jobFunction1 : state.jobFunctionRed,
    job: state.putProfileIdRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
    getJobFunction: () =>{
      dispatch(getJobFunction())
    },
    jobFunction11: (user_id) =>{
      dispatch(putProfileId(user_id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);

