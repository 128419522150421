import React, { Component } from "react";
import logo from "../images/KaryaTalents_logo.png";
import { connect } from "react-redux";
import SidebarMiddle from "../components/SidebarMiddle";
import bell from "../images/bell.png";
import v from "../images/v.png";
import help from "../images/help-icon.png";
import setting from "../images/setting-icon.png";
import logout from "../images/logout-icon.png";
import { API } from "../_redux/helper";
import {getProfileId} from "../_actions/user";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import iOpenSidebar from "../images/iOpenSidebar.png";
import iDashboard from "../images/iDashboard.png";
import iVIP from "../images/iVIP.svg";
import iCareer from "../images/iCareer.png";
import iReferral from "../images/iReferral.png";
import iWork from "../images/iWork.png";
import iMyProfile from "../images/iMyprofile.png";
import iLearning from "../images/iLearning.png";
import iTraining from "../images/iTraining.png";
import closeSidebar from "../images/closeSidebar.svg"
import Zoom from 'react-reveal/Zoom';
import _CareerCenter from "../components/_ReportValues";
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Session from "../components/Session"
import Modal from "react-awesome-modal";
import Rating from '@material-ui/lab/Rating';
import dlc_close from "../images/dlc_close.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
var $ = require("jquery");

const styles = {
  fadeInDown: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
    marginTop: "-86px",
  },
};

class NewHeader extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: JSON.parse(sessionStorage.getItem("sidebar")),
      var_report: sessionStorage.getItem("report"),
      var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
      visible: true,
      country_id:"",
      city_id:"",
      job_level_id:"",
      industry_id:"",
      job_function_id:"",
      job_title:"",
      job_type:"",
      salary_from:"",
      salary_to:"",
      height:"",
      value5: {
          min: 0,
          max: 100000000,
        },
      popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
      popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
      popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
      popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
      popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
      ratting:0,
      desc_ratting:"",
      comment:"",
      rating:"",
      show_modal: "",
      redirectLink: "",
      urlPopup: "",
      dataPopup: {},
      keyPopup: ""
    };
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.setState({height:window.innerHeight})
    window.scrollTo(0, 0)
    ReactPixel.track(window.location.pathname);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const { profile } = this.props.profile1;
    if (prevState.rating !== profile.ratting){
       /* eslint eqeqeq: 0 */
      if ((profile.ratting ===null || profile.ratting ==="") && profile.status_ratting=="Active"){
        sessionStorage.setItem("rating-values",true)
      }else{
        sessionStorage.setItem("rating-values",false)
      }
    }
  }


  changeVisible = () => {
    this.setState({ visible: !this.state.visible });
  };
  away = () => {
    this.setState({ visible: true });
  };

  logout=()=>{
    Session.removeSessionLogout()
  }
  
  changeBackground = () => {
    this.setState({
      link: !this.state.link,
    });
    sessionStorage.setItem("sidebar",!this.state.link)
    this.props.history.action = this.state.link
   
  };

  // POPUP MODAL START 

  // CLOSE POPUP 
  closePopup() {
    console.log("over there");
    if (this.state.popup_interest==true){
      const data={
        ratting_interest: "-",
        comment_interest: ""
      }
      axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
          this.setState({popup_interest:false})
          sessionStorage.setItem("rating-interest",false)
      }).catch((err)=>{
          console.log(err,"error");
      })
    } else if (this.state.popup_personality==true){
      const data={
        ratting: "-",
        comment: ""
      }
      axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data).then((res)=>{
          this.setState({popup_personality:false})
          sessionStorage.setItem("rating-personality",false)
      }).catch((err)=>{
          console.log(err,"error");
      })
    } else if (this.state.popup_values==true){
      const data={
        ratting: "-",
        comment: ""
      }
      axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
          this.setState({popup_values:false})
          sessionStorage.setItem("rating-values",false)
          sessionStorage.removeItem("rating-values")
          console.log(data,"data close");
      }).catch((err)=>{
          console.log(err,"error");
      })
    } else if (this.state.popup_vip==true){
      const data={
        ratting_vip: "-",
        comment_vip: ""
      }
      axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
          this.setState({popup_vip:false})
          sessionStorage.setItem("rating-vip",false)
        
      }).catch((err)=>{
          console.log(err,"error");
      })
    } else {
      const data= {
        ratting: "-",
        comment: ""
      }
      let id_program
      if (sessionStorage.getItem("i-program")==""){
        id_program=1
      }else{
        id_program = sessionStorage.getItem("i-program")
      }
      axios.post(`${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, data).then((res)=>{
          this.setState({popup_program:false})
          sessionStorage.setItem("rating-program",false)
      }).catch((err)=>{
          console.log(err,"error");
      })
    }
  }

  // SUBMIT RATING 
  submitRating=()=>{
    
    console.log('masuk sini')
    if (this.state.ratting==0){
      window.location.href="#"
    } else {
      
    if (this.state.popup_interest==true){
      const data={
        ratting_interest: this.state.ratting,
        comment_interest: this.state.comment
      }
      axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
          this.setState({popup_interest:false})
          sessionStorage.setItem("rating-interest",false)
      }).catch((err)=>{
          console.log(err,"error");
      })
    } else if (this.state.popup_vip==true){
        const data={
          ratting_vip: this.state.ratting,
          comment_vip: this.state.comment
        }
        axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data)
          .then((res)=>{
            this.setState({popup_vip:false})
            sessionStorage.setItem("rating-vip",false)
          }).catch((err)=>{
              console.log(err,"error");
          })
    } else if (this.state.popup_values==true){
        const data={
          ratting: this.state.ratting,
          comment: this.state.comment
        }
        axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data)
          .then((res)=>{
              this.setState({popup_values:false})
              sessionStorage.setItem("rating-values",false)
              sessionStorage.removeItem("rating-values")
              window.location.reload()
          }).catch((err)=>{
              console.log(err,"error");
          })
    } else if (this.state.popup_personality==true){
        const data={
          ratting: this.state.ratting,
          comment: this.state.comment
        }
        axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data)
          .then((res)=>{
              this.setState({popup_personality:false})
              sessionStorage.setItem("rating-personality",false)
          }).catch((err)=>{
              console.log(err,"error");
          })
    } else {
        const data={
          ratting: this.state.ratting,
          comment: this.state.comment
        }
        let id_program
        if (sessionStorage.getItem("i-program")=="") {
          id_program=1
        } else {
          id_program = sessionStorage.getItem("i-program")
        }
        axios.post(`${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, data)
          .then((res)=>{
              this.setState({popup_program:false})
              sessionStorage.setItem("rating-program",false)
          }).catch((err)=>{
              console.log(err,"error");
          })
    }
  }}

  // POPUP MODAL END 

  changeRatting=(e)=>{
    this.setState({ratting:Number((e.target.textContent).split('')[0])})
      let val
      if ((e.target.textContent).split('')[0]==1){
        val = "Very Bad"
      }else if ((e.target.textContent).split('')[0]==2){
        val = "Bad"
      }else if ((e.target.textContent).split('')[0]==3){
        val = "Need Improvement"
      }else if ((e.target.textContent).split('')[0]==4){
        val = "Good"
      }else{
        val = "Very Good"
      }
      this.setState({desc_ratting:val})
  }

  
  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }

  

  // LINK SIDEBAR START
  changeDashboard=()=>{
    console.log('masuk sini change dashboard')
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
       window.location.href="#"
       this.setState({popup_values:"true"})
       $("#course-outline41").hide();
       $('#course-outline41').css("display","none");
    }else{
      window.location.href="/NewDashboard"
      sessionStorage.removeItem("rating-values")
    }
  }

  changeMyProfile=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
       window.location.href="#"
       this.setState({popup_values:"true"})
    }else{
      window.location.href="/MyProfile"
      sessionStorage.removeItem("rating-values")
    }
  }

  changeJobOpportunity=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
       window.location.href="#"
       this.setState({popup_values:"true"})
    }else{
      window.location.href="/JobOpportunities"
      sessionStorage.removeItem("rating-values")
    }
  }

  changeReferral=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
       window.location.href="#"
       this.setState({popup_values:"true"})
    }else{
      window.location.href="/Referral"
      sessionStorage.removeItem("rating-values")
    }
  }

  changeAssessment=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
       window.location.href="#"
       this.setState({popup_values:"true"})
    }else{
      window.location.href="/work_assessment"
      sessionStorage.removeItem("rating-values")
    }
  }

  changeDLP=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
       window.location.href="#"
       this.setState({popup_values:"true"})
    }else{
      window.location.href="/DigitalLearningPlatform"
      sessionStorage.removeItem("rating-values")
    }
  }

  changeCareer=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
       window.location.href="#"
       this.setState({popup_values:"true"})
    }else{
      window.location.href="/CareerCenter"
      sessionStorage.removeItem("rating-values")
    }
  }


  onChangeRedirect = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "", comment_interest: ""}, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting_vip: "", comment_vip: "" }]
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        show_modal: "true",
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      
    } else {
      if (this.state.redirectLink!==""){
        window.location.href = `/${this.state.redirectLink}`
        }
    }
    
  }
  submitRatingAll = () => {
    this.state.dataPopup[Object.keys(this.state.dataPopup)[0]] = this.state.ratting
    this.state.dataPopup[Object.keys(this.state.dataPopup)[1]] = this.state.comment

    axios.post(this.state.urlPopup, this.state.dataPopup)
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
          window.location.href=`/${this.state.redirectLink}`
      }).catch((err)=>{
          console.log(err,"error");
      })
  }

  _onKeyDown=(e)=>{
    if (e.key === 'Enter') {
   this.submitRatingAll()
    }
  }

  closeRatingAll = () => {
    let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
      obj[key] = sessionStorage.getItem(key);
      return obj
    }, {});
    
    const allowed = ['rating-interest', 'rating-personality', 'rating-program', 'rating-values', 'rating-vip'];
    let finalObj = {}

    allowed.map(x => {
      if(x in raw) {
        finalObj[x] = raw[x]
      }
    })
    let id_program
    if (sessionStorage.getItem("i-program")==""){
      id_program=1
    } else {
      id_program = sessionStorage.getItem("i-program")
    }
    let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
    let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
    let arr = [{ ratting_interest: "-", comment_interest: ""}, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting_vip: "-", comment_vip: "" }]
    let data_rating = []
    if(getKeyTrue) {
      let checkIndex = allowed.indexOf(getKeyTrue)
      this.setState({ 
        urlPopup: getAllUrl[checkIndex],
        dataPopup: arr[checkIndex],
        keyPopup: getKeyTrue
      })
      data_rating.push(arr[checkIndex])
    }  

    axios.post(this.state.urlPopup,data_rating[0])
      .then((res)=>{
          this.setState({show_modal:false})
          sessionStorage.setItem(this.state.keyPopup,false)
          sessionStorage.removeItem(this.state.keyPopup)
          window.location.href=`/${this.state.redirectLink}`
      }).catch((err)=>{
          console.log(err,"error");
      })
  }
  // LINK SIDEBAR END 


  render() {
    const { profile  } = this.props.profile1;
  
    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }
  
    const nama = '' + nickname
    const name = nama.split(" ")[0]


    return (
      <div>
        <div className="block HC-pages" style={{top:"0px"}}>
          <div className="NewHeader" style={{position:"unset", top:"0px"}}>
            <div className="flex column">
              <div className="flex box header_shadow HC space-between" style={{backgroundColor:"white", width:"100%", position:"fixed", zIndex:"99"}}>
                <div>{/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={number1} /> */}</div>
                <div>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="logo" />
                </div>
                <div className="flex">
                  <div style={{display:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bell} className="bell" />
                    <div className="notif">
                      <p>90</p>
                    </div>
                  </div>
                  <div className="avatar">
                  {profile.photo &&
                    <LazyLoadImage effect="blur" className="cover"
                      src={`${API}/File/Profile/${profile.photo}`}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "100%",
                        cursor:"pointer"
                      }} onClick={this.changeVisible}
                    />}
                  </div>
                  <p className="name" style={{cursor:"pointer"}} onClick={this.changeVisible}>{name}</p>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={v} className="popup" onClick={this.changeVisible} style={{cursor:"pointer"}}/>
                </div>
              </div>
              <StyleRoot>
                <div
                  className="popup_settings"
                  style={{ display: this.state.visible ? "none" : "flex" }}
                >
                  <div className="mini_box" style={styles.fadeInDown}>
                    <div className="flex column">
                      <div className="flex pad_box" onClick={this.away} style={{display:"none"}}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} />
                        <p>Settings</p>
                      </div>
                      <div className="flex pad_box" onClick={this.away} style={{display:"none"}}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={help} />
                        <p>Help</p>
                      </div>
                      <div className="flex pad_box" onClick={this.logout}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logout} />
                        <p style={{ color: "red" }}>Logout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </StyleRoot>
            </div>
            <div className="flex">
            <div style={{ marginTop: "0px" }}>

            <div>
            <div style={{ height: "87px", width: "100%" , backgroundColor:"red",marginTop:"100px", display:"none"}}  onClick={this.changeBackground}>

                  <LazyLoadImage effect="blur"
                    src={iOpenSidebar}
                    style={{
                      width: "24px",
                      height: "18px",
                      margin: "34px auto",
                      display : this.state.link? "none":"block"
                    }}
                    onClick={this.changeBackground}
                  />
                
                </div>
        <StyleRoot>
          <div className="flex" style={styles.zoomIn}>
            {/* <div className="bigSidebar" style={{width: this.state.link ? "296px": "unset"}}> */}
            {/* <Zoom  when={this.state.link}> */}
            <div
              className="bigSidebar HC"
              style={{ width: this.state.link ? "255px" : "76px" , height:"100%"}}
              onClick={this.onChangeRedirect} 
            >
              <div className="flex column">
                <div style={{ height: "87px", width: "100%" }}>
                  <LazyLoadImage effect="blur"
                    src={iOpenSidebar} className="flex a_28"
                    style={{
                      width: "24px",
                      height: "18px",
                      margin: "34px auto",
                      display : this.state.link? "none":"block"
                    }}
                    onClick={this.changeBackground}
                  />
                  <LazyLoadImage effect="blur"
                    src={closeSidebar} className="none"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "34px",
                      marginLeft: "32px",
                      display : this.state.link? "block":"none"
                    }}
                    onClick={this.changeBackground}
                  />
                </div>
                <Link onClick={() => this.state.redirectLink = 'NewDashboard' } >
                <div className="flex hov" title={this.state.link ?"":"Dashboard"}>
                <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iDashboard} className="bullet"  />
                  </Zoom>
                  <Zoom >
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    Dashboard
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link onClick={() => this.state.redirectLink = 'MyProfile' }>
                <div className="flex hov" title={this.state.link ?"":"My Proflile"}>
                <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iMyProfile} className="bullet" />
                  </Zoom>
                  <Zoom  >
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    My Profile
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link  onClick={() => this.state.redirectLink = 'JobOpportunities' }>
                <div className="flex hov"  title={this.state.link ?"":"Job Opportunities"}>
                <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iCareer} className="bullet" />
                  </Zoom>
                  <Zoom >
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Job Opportunities
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link onClick={() => this.state.redirectLink = 'Referral' }>
                <div className="flex hov"  title={this.state.link ?"":"Referral"} >
                <Zoom >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iReferral} className="bullet" />
                </Zoom>
                <Zoom>
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    Referral
                  </p>
                  </Zoom>
                </div>
                </Link>
                <Link  onClick={() => this.state.redirectLink = 'work_assessment' }>
                  <div className="flex hov" title={this.state.link ?"":"Assessments"}>
                    <Zoom >
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="bullet" />
                    </Zoom>
                   
                      <div className="flex column">
                        <div className="new-ntf a" style={{ display: this.state.link ? "none" : "flex" }}> <span className="openSans-Bold" >New</span></div>
                        <Zoom>
                      <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                        Assessments
                      </p>
                      </Zoom>
                      <Zoom>
                      <div className="new-ntf b" style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >New</span></div>
                      </Zoom>
                      </div>
                  </div>
                </Link>
                <Link onClick={() => this.state.redirectLink = 'DigitalLearningPlatform' } >
                  <div className="flex hov" title={this.state.link ?"":"Digital Learning Platform"}>
                  <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="bullet" />
                  </Zoom>
                    <div className="flex column">
                      <div className="new-ntf a" style={{ display: this.state.link ? "none" : "flex" }}> <span className="openSans-Bold" >BETA</span></div>
                      <Zoom>
                    <p style={{ display: this.state.link ? "block" : "none" }}>
                      Digital Learning Platform
                    </p>
                    </Zoom>
                    <Zoom>
                    <div className="new-ntf b" style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >BETA</span></div>
                    </Zoom>
                    </div>
                  </div>
                </Link>
                <Link onClick={() => this.state.redirectLink = 'CareerCenter' }>
                <div className="flex hov" title={this.state.link ?"":"Career Center"}>
                <Zoom >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iWork} className="bullet" />
                </Zoom>
                <Zoom>
                  <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                    Career Center
                  </p>
                  </Zoom>
                </div>
                </Link>
                <div className="flex hov" style={{display:"none"}}>
                <Zoom >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iTraining} className="bullet" />
                </Zoom>
                <Zoom>
                  <p style={{ display: this.state.link ? "block" : "none" }}>
                    Training Marketplace
                  </p>
                  </Zoom>
                </div>
              </div>
            </div>
            {/* </Slide> */}
            {/* <div className="rightSidebar" style={{ display: this.state.link ? "block": "none"}}>
            <div></div>
          </div> */}
          </div>
          </StyleRoot>
        </div>
            </div>
            {/* CONTENT */}
            <div className="cubo width-100" style={{marginLeft: this.state.link ? "255px":"80px"}} id="no-print" >
              <div className="block">
                <SidebarMiddle />
              </div>
              <_CareerCenter ownProps={profile} />
            <Modal  id="course-outline1" visible={this.state.show_modal} effect="fadeInUp" onClickAway={this.closeRatingAll}>
            <div className="popup_dlc  flex column">
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={this.closeRatingAll}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown} >
                 <h2 className="openSans">Rate this Values Profiler</h2>
                 <Rating name="pristine" value={this.state.ratting} onClick={this.changeRatting} onChange={this.changeRatting} />
                 <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1) Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4) Good</p>
                   <p className="openSans">(5) Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this Values Profiler</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                 </div>
                 <button onClick={this.submitRatingAll} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
