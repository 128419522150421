import {GET_CERTIFICATION, GET_CERTIFICATION_ID, POST_CERTIFICATION, DELETE_CERTIFICATION, PUT_CERTIFICATION} from "../config/constants";
import axios from "axios";
import {API} from '../_redux/helper'

const token=  sessionStorage.getItem('token')
export const getCertification  = (id_cer) => {
    return {
      type: GET_CERTIFICATION,
      payload: axios({
        method: "GET",
        url: `${API}/api/user_certification/${id_cer}`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };
  
  export const getCertificationId = (user_id) => {
    return {
      type: GET_CERTIFICATION_ID,
      payload: axios({
        method: "GET",
        url: `${API}/api/user_certification?user_id=${user_id}`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

  export const postCertification = (certificate) => {
    return {
      type: POST_CERTIFICATION,
      payload: axios({
        method: "POST",
        url:`${API}/api/user_certification`,
        data: certificate,
        headers: {
          Authorization: `${token}`,
        },
      })
    };
  };

  export const putCertification = (id) => {
    return {
      type: PUT_CERTIFICATION,
      payload: axios({
        method: "PUT",
        url:`${API}/api/user_certification/${id.id}`,
        data: id,
        headers: {
          Authorization: ` ${token}`,
        }
      })
    };
  };

  export const deleteCertification = (id) => {
    return {
      type: DELETE_CERTIFICATION,
      payload: axios({
        method: "DELETE",
        url: `${API}/api/user_certification/${id}`,
        data: id,
        headers: {
          Authorization: ` ${token}`,
        }
      })
    };
  };