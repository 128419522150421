import { GET_QUEST, ADD_QUEST, GET_RESULT, GET_ALL_RESULT, CHECK_QUEST, GET_QUEST_PID, SUBMIT_PID,GET_QUEST_PID_STAND_ALONE,
         GET_RESULT_PID, GET_QUEST_VALUES, PUT_VALUES, GET_REPORT_VALUES, GET_LIMIT_VALUES, SUBMIT_PID_STAND_ALONE} from "../config/constants";
import axios from "axios";
import {API} from '../_redux/helper'

const token = sessionStorage.getItem("token");

export const getQuest = (user_id) => {
  return {
    type: GET_QUEST,
    payload: axios({
      method: "GET",
      url: `${API}/api/getprofilequestion/${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};


  export const answerQuest = (token, id, scoring) => {
    return {
      type: ADD_QUEST,
      payload: axios ({
        method: 'PUT',
        url: `${API}/api/useranswerquestionrecalculatejob/${id}`,
        data: scoring,
        headers: {
          Authorization: `${token}`,
        },
      }),
    };
  };

  export const getResult = (user_id) => {
    return {
      type: GET_RESULT,
      payload: axios({
        method: "GET",
        url: `${API}/api/getresultquestion/${user_id}?limit=3`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

  export const getAllResult = (user_id) => {
    return {
      type: GET_ALL_RESULT,
      payload: axios({
        method: "GET",
        url: `${API}/api/getresultquestion/${user_id}`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

  export const checkQuest = (user_id) => {
    return {
      type: CHECK_QUEST,
      payload: axios({
        method: "GET",
        url: `${API}/api/checkkuisoneruser/${user_id}`,  
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

  export const getQuestPID = (data) => {
    return {
      type: GET_QUEST_PID,
      payload: axios({
        method: "GET",
        url: `${API}/api/survey/findquestion`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };

  export const submitPID = (send) => {
    return {
      type: SUBMIT_PID,
      payload: axios({
        method: "POST",
        url: `${API}/api/survey/submit`,
        data: send,
        headers: {
          Authorization: ` ${token}`,
          "Content-Type": "application/json"
        },
      }).then ((res)=>{
        sessionStorage.setItem('category',res.data.analysis.modules.PIDProfile.match)
        sessionStorage.setItem('conscientious',res.data.analysis.metrics.conscientious)
        sessionStorage.setItem('directing',res.data.analysis.metrics.directing)
        sessionStorage.setItem('single','single')
        sessionStorage.setItem('interacting',res.data.analysis.metrics.interacting)
        sessionStorage.setItem('supportive',res.data.analysis.metrics.supportive)
        sessionStorage.setItem('metrics1',[res.data.analysis.metrics.conscientious,res.data.analysis.metrics.directing,res.data.analysis.metrics.interacting,res.data.analysis.metrics.supportive])
        sessionStorage.setItem('metrics2',
        [res.data.analysis.modules.PIDProfile.profile.metrics.conscientious,res.data.analysis.modules.PIDProfile.profile.metrics.directing,res.data.analysis.modules.PIDProfile.profile.metrics.interacting,res.data.analysis.modules.PIDProfile.profile.metrics.supportive])
        window.location.href="/Survey_Complete"
        // window.location.href="#"
      }).catch(err => {
        console.log(err.message,"data tidak terkirim")
      })
    };
  };

  export const getQuestPID_StandAlone = (data) => {
    return {
      type: GET_QUEST_PID_STAND_ALONE,
      payload: axios({
        method: "GET",
        url: `${API}/api/v1/survey/findquestion`,
      })
    };
  };

  export const submitPID_StandAlone = (send) => {
    return {
      type: SUBMIT_PID_STAND_ALONE,
      payload: axios({
        method: "POST",
        url: `${API}/api/v1/survey/submit`,
        data: send,
        // headers: {
        //   Authorization: ` ${token}`,
        //   "Content-Type": "application/json"
        // },
      }).then ((res)=>{
        sessionStorage.setItem('category',res.data.analysis.modules.PIDProfile.match)
        sessionStorage.setItem('conscientious',res.data.analysis.metrics.conscientious)
        sessionStorage.setItem('directing',res.data.analysis.metrics.directing)
        sessionStorage.setItem('single','single')
        sessionStorage.setItem('interacting',res.data.analysis.metrics.interacting)
        sessionStorage.setItem('supportive',res.data.analysis.metrics.supportive)
        sessionStorage.setItem('metrics1',[res.data.analysis.metrics.conscientious,res.data.analysis.metrics.directing,res.data.analysis.metrics.interacting,res.data.analysis.metrics.supportive])
        sessionStorage.setItem('metrics2',
        [res.data.analysis.modules.PIDProfile.profile.metrics.conscientious,res.data.analysis.modules.PIDProfile.profile.metrics.directing,res.data.analysis.modules.PIDProfile.profile.metrics.interacting,res.data.analysis.modules.PIDProfile.profile.metrics.supportive])
        window.location.href="/Survey-Complete-Stand-Alone"
      }).catch(err => {
        console.log(err,"data tidak terkirim")
      })
    };
  };

  export const getResultPID = (user_id) => {
    return {
      type: GET_RESULT_PID,
      payload: axios({
        method: "GET",
        url: `${API}/api/survey/findsubmission/${user_id}`,
      })
    };
  };

  export const getQuestValues = (id) => {
    return {
      type: GET_QUEST_VALUES,
      payload: axios({
        method: "GET",
        url: `${API}/api/get_work_assessment_question/${id}`,
        headers: {
          Authorization: ` ${token}`,
        },
      })
    };
  };
export let res_submitValues
  export const submitValues = (id, scoring) => {
    const data = {scoring: scoring}
    return {
      type: PUT_VALUES,
      payload: axios ({
        method: 'PUT',
        url: `${API}/api/user_work_assessment_question/${id}`,
        data: data,
        headers: {
          Authorization: `${token}`,
        },
      }).then((res)=>{
        res_submitValues = res.data
      })
    };
  };

  export const getReportValues = (id) => {
    return {
      type: GET_REPORT_VALUES,
      payload: axios({
        method: "GET",
        url: `${API}/api/getresult_work_assessment_question/${id}`,
        // headers: {
        //   Authorization: ` ${token}`,
        // },
      })
    };
  };

  export const getReportLimit = (id) => {
    return {
      type: GET_LIMIT_VALUES,
      payload: axios({
        method: "GET",
        url: `${API}/api/getresult_work_assessment_question/${id}?limit=3`,
        // headers: {
        //   Authorization: ` ${token}`,
        // },
      })
    };
  };