import React, { Component } from 'react';
import report_bg from '../images/report_bg.svg';
import logo from '../images/KaryaTalents_logo.svg';
import crown_logo from '../images/crown_logo.png';
import wavy_left from '../images/wavy_left.svg';
import wavy_right from '../images/wavy_right.svg';
import ideal from '../images/ideal_env-icon.svg';
import area from '../images/report_area.svg';
import dev from '../images/report_dev.svg';
import icon_download from '../images/icon_download_report.svg';
import arrLeft from '../images/report_arrLeft.svg';
import arrRight from '../images/report_arrRight.svg';
import issue from '../images/report_issue.svg';
import report_admin from '../images/report_admin.svg';
import finance from '../images/report_finance.svg';
import green from '../images/report_check_green.svg';
import dataReport from '../components/DataReport.json';
import { i_download } from '../images/svg/svg';
import {  getAllResult,getReportValues } from "../_actions/quest";
import Loading from "../components/Loading";
import {
  report,
  label,
  date_report,
  dataDimensionOne,
  dataDimensionTwo,
  dataDimensionThree,
  dataDimensionFour,
  nconscientious,
  ninteracting,
  nsupportive,
  ndirecting,
  def_directing,
  def_conscientious,
  def_interacting,
  def_supportive,
} from '../components/ParamsReport';
import { Bar} from '@reactchartjs/react-chart.js';
import { GAevent } from '../components/TrackingEvent';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import ReactDOM from 'react-dom';
import { API } from '../_redux/helper';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import axios from 'axios';
import Modal from "react-awesome-modal";
import Rating from '@material-ui/lab/Rating';
import dlc_close from "../images/dlc_close.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from "react-facebook-pixel"


class componentName extends Component {
  constructor() {
    super();
    this.state = {
      user_id: '',
      width: '',
      visible: true,
      visible2: true,
      rating:"",
      ratting:0,
      comment:"",
      desc_ratting:"",
      rating_vip:"",
      rating_interest:"",
      popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
      popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
      popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
      popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
      popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
      rate:"",
      desc:"",
      resVIP: '',
      br:0,
      show:false
    };
  }

  componentDidMount() {
    window.history.scrollRestoration = "manual"
    var jwt = require('jsonwebtoken');
    var token1 = sessionStorage.getItem('token');
    var decode1 = jwt.decode(token1);
    var user_id = decode1['user_id'];
    this.setState({ user_id: user_id });
    this.setState({ width: window.window.outerWidth });
    this.props.getAllResult(user_id);
    this.props.getReportValues(user_id)
    window.addEventListener('scroll', this.handleScroll);
    axios.get(`${API}/api/survey/findsubmission/${user_id}`)
      .then(response => {
          this.setState({resVIP:response.data})
      })
  }
  //PDF TERBARU
  exportPDFWithMethod = () => {
    savePDF(ReactDOM.findDOMNode(this.container), {
      paperSize: 'auto',
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
    this.setState({ visible: false });
    setTimeout(() => {
      window.location.href = '/Report';
    }, 1500);
    GAevent('MyAssessment', 'Download');
  };

  // exportPDFWithComponent = () => {
  //     this.pdfExportComponent.save();
  //     GAevent('Report PID','Download PDF')
  //   };

  exportPDFWithComponent = () => {
    this.pdfExportComponent.save({
      toolbar: ['pdf'],
      pdf: {
        forceProxy: true,
        proxyURL: '/save',
      },
    })
    .done((e)=>console.log(e,"response"))

    GAevent(`Download PDF - ${window.location.pathname}`,'Download PDF') 
    ReactPixel.track(`Download PDF - ${window.location.pathname}`,'Download PDF'); 
    this.setState({ visible: false });
    setTimeout(() => {
      window.location.href = '/Report';
    }, 2500);
  };

  exportPDFWithComponentMobile = () => {
    this.pdfExportComponent.save();
    GAevent(`Download PDF - ${window.location.pathname}`,'Download PDF') 
    ReactPixel.track(`Download PDF - ${window.location.pathname}`,'Download PDF'); 
    this.setState({ visible2: false });
    setTimeout(() => {
      window.location.href = '/Report';
    }, 4500);
  };

  changeRatting=(e)=>{
    this.setState({ratting:Number((e.target.textContent).split('')[0])})
    let val
    if ((e.target.textContent).split('')[0]==1){
      /* eslint eqeqeq: 0 */
      val = "Very Bad"
    }else if ((e.target.textContent).split('')[0]==2){
      val = "Bad"
    }else if ((e.target.textContent).split('')[0]==3){
      val = "Need Improvement"
    }else if ((e.target.textContent).split('')[0]==4){
      val = "Good"
    }else{
      val = "Very Good"
    }

    this.setState({desc_ratting:val})
    this.state.rate = this.state.ratting
    this.state.desc = this.state.desc_ratting
  }


  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }

  closePopupPersonality() {
    const data={
      ratting: "-",
      comment: ""
  }
      axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_personality:false})
        window.location.reload()
        sessionStorage.setItem("rating-personality",false)
        GAevent(`Close Rating - ${window.location.pathname}`,'Close Rating ') 
        ReactPixel.track(`Close Rating - ${window.location.pathname}`,'Close Rating '); 
    }).catch((err)=>{
        console.log(err,"error");
    })
  }


  submitRatingPersonality=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.rate,
      comment: this.state.comment
  }
    axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_personality:false})
        sessionStorage.setItem("rating-personality",false)
        window.location.reload()
        GAevent(`Submit Rating - ${window.location.pathname}`,'Submit Rating ') 
        ReactPixel.track(`Submit Rating - ${window.location.pathname}`,'Submit Rating '); 
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  _onKeyDown=(e)=>{
    if (e.key === 'Enter') {
      this.submitRatingPersonality()
    }
  }

  takeAssessment=()=>{
    GAevent(`Take New Assessment - ${window.location.pathname}`,'Take New Assessment ') 
    ReactPixel.track(`Take New Assessment - ${window.location.pathname}`,'Take New Assessment '); 

    if (JSON.parse(sessionStorage.getItem("rating-personality"))==true){
      window.location.href="#"
      this.setState({popup_personality:"true"})
   }else{
      window.location.href="/Personality_Getstarted"
  }
  }

  handleScroll=(event)=>{
    const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop
  const height =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight
  const scrolled = winScroll / height
  if (parseFloat(winScroll)<250){
        this.setState({br:scrolled})
  }
}


  render() {
    const {br, show} = this.state
    const val_day = new Date(date_report).getDay();
    const val_date = new Date(date_report).getDate();
    const val_month = new Date(date_report).getMonth();
    const val_year = new Date(date_report).getFullYear();
    const val_Hours = new Date(date_report).getHours();
    const val_Minutes = new Date(date_report).getMinutes();

    const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let Hour;
    if (val_Hours >= 13) {
      Hour = val_Hours - 12 + ':' + val_Minutes + ' PM';
    } else {
      Hour = val_Hours + ':' + val_Minutes + ' AM';
    }

    const date_PID = day[val_day - 1] + ', ' + month[val_month] + ' ' + val_date + ', ' + val_year + '  ' + Hour;

    const  profile  = this.props.ownProps;
    const nama = '' + profile.name;
    const nick_name = nama.split(' ')[0];
    const nick_name2 = nick_name.charAt(0).toUpperCase() + nick_name.slice(1);

    let regText = /__first_name__/gi;
    let regProfile = /__profile__/gi;
    var dataURI = 'data:text/plain;base64,SGVsbG8gV29ybGQh';

    let text = `${report.suggestionsForManagersCopy}`;
    let suggestionsForManagers;
    if (text.indexOf('__first_name__') != -1) {
      suggestionsForManagers = text.replace(regText, nick_name2);
    } else {
      suggestionsForManagers = text.replace(regProfile, label);
    }

    // DATA FOR CHART
    var options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 20,
              max: 80,
              borderColor: green,
              border: 'red',
            },
            gridLines: {
              display: true,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: { display: true },
            gridLines: {
              display: true,
              drawBorder: true,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
    };
   
      

    const data = {
      labels: [' ', ' ', '', ' '],
      datasetIndex: ['0', '1', '2', '3', '4', '5', '6'],
      datasets: [
        {
          type: 'line',
          label: `${label}`,
          borderColor: '#828282',
          borderWidth: 2,
          fill: false,
          data: [def_directing, def_interacting, def_supportive, def_conscientious],
          beginAtZero: true,
          display: false,
        },
        {
          type: 'line',
          label: `${nick_name2}`,
          borderColor: '#3B6C98',
          borderWidth: 2,
          fill: false,
          data: [ndirecting, ninteracting, nsupportive, nconscientious],
        },
      ],
    };

    const  profile1  = this.props.ownProps;
        const { allRes } = this.props.result2;
        const {dataReportValues, isLoading} = this.props.dataReportValues1
        let n_val
        dataReportValues.data != undefined && dataReportValues.data.length > 0 ?
        dataReportValues.data.map((item,i)=>{
            if (i==0){
                n_val = item.persen_score
            }
        }):console.log("error");


       let val_interest 
       allRes.map((item,i)=>{
           if (i==0){
               val_interest = item.scoring
           }
       })

       if (isLoading) {
        return (
            <React.Fragment>
            <Loading />
            </React.Fragment>
        );
        }
        
        console.log(allRes,"ini res",val_interest, profile1,"ini profile1");
      const btn = <div className='flex center bg-36 br-24 bd-e1 p-14 ml-14 pointer ' >
                  <p className='c-ff w-600 Montserrat s-1418 mr-14'>Take Next Assessment</p>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.03033 3.96969C7.81583 3.75519 7.49324 3.69103 7.21299 3.80711C6.93273 3.9232 6.75 4.19668 6.75 4.50002V13.5C6.75 13.8034 6.93273 14.0768 7.21299 14.1929C7.49324 14.309 7.81583 14.2449 8.03033 14.0304L12.5303 9.53035C12.8232 9.23746 12.8232 8.76258 12.5303 8.46969L8.03033 3.96969Z" fill="white"/>
                  </svg>
                  </div>

       let bt_next
        if(this.state.resVIP == "Data Not Found") {
          bt_next = <Link to="/personality_profiler">
                            {btn}
                        </Link>
        } else if(parseInt(val_interest) <= 0 || val_interest== undefined) {
          bt_next = <Link to="/Howtodo">
                            {btn}
                        </Link>
        } else if (!profile1.is_work_assessment){
          bt_next = <Link to="/values_profiler">
                            {btn}
                        </Link>
        } else {
          bt_next = null
        }

        const hg = parseInt(window.innerHeight) - 130
        let st_dis = false
        if (br===0){
            st_dis=false
        }else{
            st_dis=true
        }

    return (
      <div>
        <div className='flex width-100 fixed z-9 bg-ff hg-50 center shd-01 ml--39 trans-3 mt--1' style={{visibility:st_dis? "visible":"hidden", opacity:st_dis ?"1":"0" }}  >
            <div className='flex space-between page-container-outer alignCenter flex-mob'>
              <div className='flex dw-mobile'>
              <p className='c-36 s-3032 leagueSpartan'>Personality Profiler</p> 
              <div className={`none ${show?"i":""}` } onClick={(e)=>this.setState({show:!show})}>{i_download}</div>
              </div>
              <div className={`flex mt-12 mb-12 flex-mob ${show?"":"i"}`}>
                <div className='flex center bg-ff br-24 bd-e1 p-14 pointer' onClick={this.exportPDFWithMethod}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                    <p className='c-36 w-600 Montserrat s-1418 ml8'>Download</p>
                </div>
                <div className='flex center bg-ff br-24 bd-e1 p-14 ml-14 pointer' onClick={this.takeAssessment}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69671 3.6967C5.0531 2.34029 6.92913 1.5 9 1.5C13.1421 1.5 16.5 4.85787 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C6.92913 16.5 5.0531 15.6597 3.6967 14.3033C3.40381 14.0104 3.40382 13.5355 3.69672 13.2426C3.98961 12.9497 4.46449 12.9497 4.75738 13.2426C5.84396 14.3293 7.34292 15 9 15C12.3137 15 15 12.3137 15 9C15 5.6863 12.3137 3 9 3C7.34292 3 5.84396 3.67075 4.75737 4.75736L4.75736 4.75737C4.54789 4.96685 4.24503 5.29039 3.93772 5.625H5.25C5.66421 5.625 6 5.96079 6 6.375C6 6.78921 5.66421 7.125 5.25 7.125H2.25C1.83579 7.125 1.5 6.78921 1.5 6.375V3.375C1.5 2.96079 1.83579 2.625 2.25 2.625C2.66421 2.625 3 2.96079 3 3.375V4.42911C3.25762 4.15067 3.50756 3.88585 3.69671 3.6967Z" fill="#3B6C98"/>
                    </svg>
                    <p className='c-36 w-600 Montserrat s-1418 ml-14'>Retake Personality Profiler</p>
                </div>
                {bt_next}
                </div>
            </div>
        </div>
        <div
          className='page-container report_Profiler personality--profiler'
          style={{ marginTop: '80px', backgroundColor: '#F2F2F2', width: this.state.visible2 ? '100%' : '1200px' }}>
          <div className='page-container-outer personality-profiler' style={{ boxShadow: 'none' }}>
            <div className='width-100 dekstop'>
              <div className='flex width-100 space-between title_Profiler'>
                <h1>Personality Profiler</h1>
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Link onClick={this.takeAssessment} style={{ marginRight: '24px'}}>
                  <button className='flex ret center bg-ff bd-e1'>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69671 3.6967C5.0531 2.34029 6.92913 1.5 9 1.5C13.1421 1.5 16.5 4.85787 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C6.92913 16.5 5.0531 15.6597 3.6967 14.3033C3.40381 14.0104 3.40382 13.5355 3.69672 13.2426C3.98961 12.9497 4.46449 12.9497 4.75738 13.2426C5.84396 14.3293 7.34292 15 9 15C12.3137 15 15 12.3137 15 9C15 5.6863 12.3137 3 9 3C7.34292 3 5.84396 3.67075 4.75737 4.75736L4.75736 4.75737C4.54789 4.96685 4.24503 5.29039 3.93772 5.625H5.25C5.66421 5.625 6 5.96079 6 6.375C6 6.78921 5.66421 7.125 5.25 7.125H2.25C1.83579 7.125 1.5 6.78921 1.5 6.375V3.375C1.5 2.96079 1.83579 2.625 2.25 2.625C2.66421 2.625 3 2.96079 3 3.375V4.42911C3.25762 4.15067 3.50756 3.88585 3.69671 3.6967Z" fill="#3B6C98"/>
                    </svg>
                    <p>Retake Personality Profiler</p>
                  </button>
                </Link>
                {bt_next}
                </div>
              </div>

              <div className='box_under_lc' style={{ width: this.state.visible2 ? '' : '230px', marginBottom:"14px" }}>
                <div className='flex box_under_lc ' style={{ display: this.state.visible2 ? '' : 'none' }}>
                  <button className='xbr a'
                    onClick={this.exportPDFWithMethod}
                    style={{ display: this.state.visible ? 'flex' : 'none' }}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                    <p>Download PDF</p>
                  </button>
                </div>
              </div>
            </div>

            <div className='width-100 mobile' style={{ backgroundColor: '#F2F2F2' }}>
              <div className='flex width-100  title_Profiler Mob_ column'>
                <h1 className='width-100 flex' style={{ marginTop: '15px' }}>
                  Personality Profiler
                </h1>
                <Link onClick={this.takeAssessment}>
                  <button className='flex ret center bg-ff bd-e1'>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69671 3.6967C5.0531 2.34029 6.92913 1.5 9 1.5C13.1421 1.5 16.5 4.85787 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C6.92913 16.5 5.0531 15.6597 3.6967 14.3033C3.40381 14.0104 3.40382 13.5355 3.69672 13.2426C3.98961 12.9497 4.46449 12.9497 4.75738 13.2426C5.84396 14.3293 7.34292 15 9 15C12.3137 15 15 12.3137 15 9C15 5.6863 12.3137 3 9 3C7.34292 3 5.84396 3.67075 4.75737 4.75736L4.75736 4.75737C4.54789 4.96685 4.24503 5.29039 3.93772 5.625H5.25C5.66421 5.625 6 5.96079 6 6.375C6 6.78921 5.66421 7.125 5.25 7.125H2.25C1.83579 7.125 1.5 6.78921 1.5 6.375V3.375C1.5 2.96079 1.83579 2.625 2.25 2.625C2.66421 2.625 3 2.96079 3 3.375V4.42911C3.25762 4.15067 3.50756 3.88585 3.69671 3.6967Z" fill="#3B6C98"/>
                    </svg>
                    <p>Retake Personality Profiler</p>
                  </button>
                </Link>
                {bt_next}
              </div>
            </div>
            <PDFExport
              ref={(component) => (this.pdfExportComponent = component)}
              width='1000'
              height='1000'
              dataURI={dataURI}
              proxyURL='/proxy'
              fileName={`Personality ${nick_name2}`}
              paperSize='auto'
              className='width-100'
              margin={20}
              style={{ width: '100% !important' }}>
              <div className='canvas_Profiler' ref={(container) => (this.container = container)}  >
                <div className='width-100' style={{ marginBottom: '18px' }}>
                  <LazyLoadImage effect="blur"
                    src={report_bg}
                    className='flex width-100 bg_top_profiler'
                    style={{ height: this.state.visible2 ? '' : '199px' }}
                  />
                  <div className='content_bg_report' style={{ height: this.state.visible2 ? '' : '199px' }}>
                    <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                      <div
                        className='flex width-100  header_bg '
                        style={{ justifyContent: this.state.visible ? 'space-between' : 'space-between' }}>
                        <div className='box_under_lc' style={{ width: this.state.visible2 ? '' : '230px' }}>
                          <div className='flex box_under_lc ' style={{ display: this.state.visible2 ? '' : 'none', visibility:"hidden" }}>
                            <button className='xbr'
                              // onClick={this.exportPDFWithComponent}
                              style={{ display: this.state.visible ? 'flex' : 'none' }}>
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                              <p>Download PDF</p>
                            </button>
                          </div>
                        </div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className='logo_bg' />
                        <div
                          className='under-lc'
                          style={{
                            marginTop: this.state.visible2 ? '' : '0px',
                            width: this.state.visible2 ? '' : '230px',
                            flexDirection: this.state.visible2 ? '' : 'row',
                          }}>
                          {/* <p style={{fontSize:this.state.visible2?"":"12px", marginBottom:this.state.visible2?"":"auto"}}>Under License From</p>
                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={crown_logo} style={{height:"53px", width:"auto", display:"flex"}}/> */}
                        </div>
                      </div>
                    </div>
                    <div className='width-100 mobile' style={{ display: this.state.visible2 ? '' : 'none' }}>
                      <div className='flex width-100 space-between column header_bg center'>
                        {/* <button style={{marginBottom:"20px"}} onClick={this.exportPDFWithComponent}>Download PDF</button> */}
                        <button
                          onClick={this.exportPDFWithMethod}
                          style={{ display: this.state.visible2 ? 'flex' : 'none', marginBottom: '20px' }}>
                          <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                          <p>Download PDF</p>
                        </button>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className='logo_bg' style={{ height: '24px' }} />
                      </div>
                    </div>
                    <div className='dekstop width-100'>
                      <div className='flex width-100 center'>
                        {/* <p style={{fontSize:"45px",marginTop:"34px", fontWeight:"bold",backgroundColor:"red", marginLeft:"10%"}}>Personality Profiler</p> */}
                        <div className='title_bg_canvas' style={{ paddingLeft: this.state.visible ? '' : '16%' }}>
                          Personality Profiler
                        </div>
                      </div>
                    </div>
                    <div className='mobile width-100'>
                      <div className='flex width-100 center'>
                        {/* <p style={{fontSize:"45px",marginTop:"34px", fontWeight:"bold",backgroundColor:"red", marginLeft:"10%"}}>Personality Profiler</p> */}
                        <div
                          className='title_bg_canvas'
                          style={{
                            marginTop: '34px',
                            padding: this.state.visible2 ? '' : '17px 5% 0px 15%',
                            fontSize: this.state.visible2 ? '' : '40px',
                            lineHeight: this.state.visible2 ? '' : '60px',
                            marginTop: this.state.visible2 ? '' : '17px',
                            marginBottom: this.state.visible2 ? '' : '15px',
                            width: this.state.visible2 ? '' : '600px',
                          }}>
                          Personality Profiler
                        </div>
                      </div>
                    </div>
                    <div className='flex width-100 center btn_Idv'>
                      <button>
                        <p>Individual Report</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='flex width-100 center' style={{ marginBottom: '24px' }}>
                  <div
                    className='under-lc'
                    style={{
                      marginTop: this.state.visible2 ? '' : '0px',
                      width: this.state.visible2 ? '' : '208px',
                      flexDirection: this.state.visible2 ? '' : 'row',
                    }}>
                    <p
                      style={{
                        fontSize: this.state.visible2 ? '' : '12px',
                        margin: this.state.visible2 ? '' : '17.5px auto auto',
                      }}>
                      Under License From
                    </p>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={crown_logo} style={{ width: this.state.visible2 ? '' : '70px' }} />
                  </div>
                </div>
                <div className='width-100 dekstop'>
                  <div className='box_admin_Profile' style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                    <div className='flex width-100 height-100 space-between'>
                      <LazyLoadImage effect="blur"
                        src={wavy_left}
                        style={{ height: '100%', objectFit: 'cover', borderBottomLeftRadius: '10px' }}
                      />
                      <LazyLoadImage effect="blur"
                        src={wavy_right}
                        style={{ height: '100%', objectFit: 'cover', borderTopRightRadius: '10px' }}
                      />
                    </div>
                    <div className='flex  column textPrepare'>
                      <p className='top'>Prepared for</p>
                      <div className='flex width-100 space-between'>
                        <div className='flex '>
                          <h3 style={{ textTransform: 'capitalize' }}>{profile.name}</h3>
                          <button>
                            <p style={{ textTransform: 'uppercase' }}>{label}</p>
                          </button>
                        </div>
                        {date_report && <p className='date'>{date_PID} </p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='width-100 mobile'>
                  <div style={{ padding: '14px' }}>
                    <div
                      className='box_admin_Profile'
                      style={{
                        height: '170px',
                        width: '100%',
                        marginLeft: '0%',
                        border: this.state.visible2 ? '' : '1px solid #ececec',
                      }}>
                      <div className='flex width-100 height-100 space-between icon-bg-personality'>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_left} style={{ objectFit: 'cover', borderBottomLeftRadius: '0px' }} />
                        <LazyLoadImage effect="blur"
                          src={wavy_right}
                          style={{ objectFit: 'cover', borderTopRightRadius: '0px', marginTop: 'auto' }}
                        />
                      </div>
                      <div
                        className='flex  column textPrepare width-100'
                        style={{ marginLeft: this.state.visible2 ? '' : '4%' }}>
                        <div className='width-100 flex column' style={{ padding: '14px' }}>
                          <p className='top'>Prepared for</p>
                          <h3 style={{ textTransform: 'capitalize' }}>{profile.name} </h3>
                          <div className='flex width-100 space-between column'>
                            {/* <div className="flex column width-100"> */}
                            <button style={{ width: '94%', marginLeft: '0px' }}>
                              <p style={{ textTransform: 'uppercase' }}>{label}</p>
                            </button>
                            {/* </div> */}
                            {date_report && <p className='date'>{date_PID}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='width-report'>
                  <div className='flex width-100 center box-Overview' style={{ justifyContent: 'space-between' }}>
                    <div className='line_Report'></div>
                    <h1 className='title-line_Report'>OVERVIEW</h1>
                    <div className='line_Report'></div>
                  </div>
                  <p className='width-100 textOverview' style={{ marginBottom: '48px', height: 'auto' }}>
                    The natural personality profile for you is indicated by the blue line on the graph below. Note also
                    the gray line; it represents the Blended Profile that most nearly matches your graph. Seventeen
                    Blended Profiles are used as the baseline for interpreting the combinations of four basic dimensions
                    of personality. The information in this section is derived from the {label} Blended Profile.
                    Typically, the more closely the blue line graph matches this Blended Profile, the more accurately
                    the information will describe your natural behavior style.{' '}
                  </p>
                </div>
                <div className='box_Admin flex center column'>
                  <div className='box_Chart' style={{ width: this.state.visible2 ? '' : '800px' }}>
                    {/* <div className="box_Chart" > */}
                    <h1
                      className='width-100'
                      style={{
                        fontSize: this.state.visible2 ? '' : '28px',
                        lineHeight: this.state.visible2 ? '' : '38px',
                      }}>
                      {label}
                    </h1>
                    <div className='flex width-100 center'>
                      <div className='width-100 dekstop'>
                        <div className='flex width-100 center'>
                          <div className='flex width-100 flexBox'>
                            <div className='flex width-100 column sub_flexBox'>
                              <div className='flex width-100 space-between bar_Desc' style={{ marginBottom: '32px' }}>
                                <div className='width-20 flex column center'>
                                  <h1>Directing</h1>
                                  <p>Assertive - Decisive</p>
                                  <p>Controlling</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Interacting</h1>
                                  <p>Outgoing - Convincing</p>
                                  <p>Excitable</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Supportive</h1>
                                  <p>Harmonious - Patient</p>
                                  <p>Lenient</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Conscientious</h1>
                                  <p>Detailed - Precise</p>
                                  <p>Perfectionist</p>
                                </div>
                              </div>
                              {/* <div className="none"> */}
                              <Bar data={data} options={options} style={{ width: '100%' }} />
                              {/* </div> */}
                              <div className='flex width-100 space-between bar_Desc' style={{ marginTop: '32px' }}>
                                <div className='width-20 flex column center'>
                                  <h1>Adaptive</h1>
                                  <p>Pliable - Conforming</p>
                                  <p>Passive</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Reserved</h1>
                                  <p>Modest - Quiet</p>
                                  <p>Distant</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Objective</h1>
                                  <p>Questioning - Impatient</p>
                                  <p>Critical</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Unconventional</h1>
                                  <p>Instinctive - Improvised</p>
                                  <p>Unorganized</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='width-100 mobile'>
                        <div className='flex width-100 center'>
                          {/* <div className="flex width-100 flexBox" style={{width:`${this.state.width * 0.70}px`}}>
                                                <div className="flex width-100 column sub_flexBox" style={{width: `${this.state.width * 1.4}px`}}> */}
                          <div
                            className='flex width-100 flexBox'
                            style={{ width: this.state.visible2 ? `${this.state.width * 0.7}px` : '700px' }}>
                            <div
                              className='flex width-100 column sub_flexBox'
                              style={{ width: this.state.visible2 ? `${this.state.width * 1.4}px` : '100%' }}>
                              <div className='flex width-100 space-between bar_Desc' style={{ marginBottom: '32px' }}>
                                <div className='width-20 flex column center'>
                                  <h1>Directing</h1>
                                  <p>Assertive - Decisive</p>
                                  <p>Controlling</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Interacting</h1>
                                  <p>Outgoing - Convincing</p>
                                  <p>Excitable</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Supportive</h1>
                                  <p>Harmonious - Patient</p>
                                  <p>Lenient</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Conscientious</h1>
                                  <p>Detailed - Precise</p>
                                  <p>Perfectionist</p>
                                </div>
                              </div>
                              {/* <div className="none"> */}
                              <Bar data={data} options={options} style={{ width: '100%' }} />
                              {/* </div> */}
                              <div className='flex width-100 space-between bar_Desc' style={{ marginTop: '32px' }}>
                                <div className='width-20 flex column center'>
                                  <h1>Adaptive</h1>
                                  <p>Pliable - Conforming</p>
                                  <p>Passive</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Reserved</h1>
                                  <p>Modest - Quiet</p>
                                  <p>Distant</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Objective</h1>
                                  <p>Questioning - Impatient</p>
                                  <p>Critical</p>
                                </div>
                                <div className='width-20 flex column center'>
                                  <h1>Unconventional</h1>
                                  <p>Instinctive - Improvised</p>
                                  <p>Unorganized</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='width-report'>
                  <div className='flex width-100 report_Admin'>
                    <p className='openSans justify '>{report.intro}</p>
                  </div>
                  <div className='Idea_box' style={{ flexDirection: this.state.visible2 ? '' : 'row' }}>
                    <div className='blue_container' style={{ width: this.state.visible2 ? '' : '30%' }}>
                      <div className='items width-100'>
                        <div className='verticalLine' />
                        <div className='flex width-100 column'>
                          <h1
                            className='width-100'
                            style={{
                              fontSize: this.state.visible2 ? '' : '24px',
                              lineHeight: this.state.visible2 ? '' : '30px',
                              marginBottom: this.state.visible2 ? '' : '28px',
                            }}>
                            Ideal Environment
                          </h1>
                          <p className='width-100 openSans'>{report.idealEnvironmentCopy} </p>
                        </div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={ideal} />
                      </div>
                    </div>
                    <div className='blue_container' style={{ width: this.state.visible2 ? '' : '30%' }}>
                      <div className='items  width-100'>
                        <div className='verticalLine' />
                        <div className='flex width-100 column'>
                          <h1
                            className='width-100'
                            style={{
                              fontSize: this.state.visible2 ? '' : '24px',
                              lineHeight: this.state.visible2 ? '' : '30px',
                              marginBottom: this.state.visible2 ? '' : '28px',
                            }}>
                            Areas of Strength
                          </h1>
                          <p className='width-100 openSans'>{report.typicalAreasOfStrengthCopy} </p>
                        </div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={area} />
                      </div>
                    </div>
                    <div className='blue_container' style={{ width: this.state.visible2 ? '' : '30%' }}>
                      <div className='items  width-100'>
                        <div className='verticalLine' />
                        <div className='flex width-100 column'>
                          <h1
                            className='width-100'
                            style={{
                              fontSize: this.state.visible2 ? '' : '24px',
                              lineHeight: this.state.visible2 ? '' : '30px',
                              marginBottom: this.state.visible2 ? '' : '28px',
                            }}>
                            Areas of Development
                          </h1>
                          <p className='width-100 openSans'>{report.typicalAreasOfStruggleCopy} </p>
                        </div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={dev} />
                      </div>
                    </div>
                  </div>
                  <div className='width-100 flex' style={{ border: '1px solid #E0E0E0', margin: '50px 0px' }} />
                  <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                    <div className='flex width-100 space-between '>
                      <div className='flex width-100 column title_work'>
                        <h1 style={{ textTransform: 'uppercase', color: '#3B6C98', lineHeight: '59px' }}>{label}</h1>
                        <h1 style={{ textTransform: 'uppercase', color: '#DDB87A', lineHeight: '59px' }}>at work</h1>
                      </div>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_admin} />
                    </div>
                  </div>
                  <div className='width-100 mobile' style={{ display: this.state.visible2 ? '' : 'none' }}>
                    <div className='flex width-100 space-between column title_Report_Admin'>
                      <LazyLoadImage effect="blur"
                        src={report_admin}
                        style={{
                          width: this.state.visible2 ? 'auto' : '26%',
                          marginLeft: this.state.visible2 ? '' : '37%',
                        }}
                      />
                      <div className='flex width-100 column ' style={{ marginTop: '24.93px' }}>
                        <h1 style={{ textTransform: 'uppercase', color: '#3B6C98' }}>{label}</h1>
                        <h1 style={{ textTransform: 'uppercase', color: '#DDB87A' }}>at work</h1>
                      </div>
                    </div>
                  </div>
                  {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={bg_ellipse}  className="ellipse_report"/> */}
                  <div className='dekstop wdth-100'>
                    <div
                      className='flex width-100 bg_ellipse'
                      style={{
                        flexDirection: this.state.visible2 ? '' : 'row',
                        backgroundImage: this.state.visible2
                          ? ''
                          : 'url(/static/media/report_bg_ellipse.3d2064e1.svg) ',
                      }}>
                      <div
                        className='flex box-left column'
                        style={{
                          width: this.state.visible2 ? '' : '48%',
                          marginRight: this.state.visible2 ? '' : '2%',
                        }}>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Preferred Activities</h2>
                          <p className='openSans'>{report.preferredActivitiesCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Leadership Style</h2>
                          <p className='openSans'>{report.leadershipStyleCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Communication Style</h2>
                          <p className='openSans'>{report.communicationStyleCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Generic Careers</h2>
                          <p className='openSans'>{report.genericCareersCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Famous people similar to this profile</h2>
                          <p className='openSans'>{report.famousPeopleSimilarToThisProfileCopy} </p>
                        </div>
                      </div>
                      <div
                        className='flex box-right column'
                        style={{
                          width: this.state.visible2 ? '' : '48%',
                          marginLeft: this.state.visible2 ? '' : '2%',
                        }}>
                        <div
                          className='box-shadow-report flex column '
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Contributions to the Organization</h2>
                          <p className='openSans'>{report.contributionsToTheOrganizationCopy}</p>
                        </div>
                        <div
                          className='box-shadow-report flex column '
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Task or People Orientation</h2>
                          <p className='openSans'>{report.taskOrPeopleOrientationCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column '
                          style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                          <h2>Suggestions for Managers</h2>
                          <p className='openSans suggest-p'>{suggestionsForManagers} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mobile wdth-100'>
                    <div
                      className='flex width-100 bg_ellipse'
                      style={{
                        flexDirection: this.state.visible2 ? '' : 'row',
                        backgroundImage: this.state.visible2
                          ? ''
                          : 'url(/static/media/report_bg_ellipse.3d2064e1.svg) ',
                      }}>
                      <div
                        className='flex box-left column'
                        style={{
                          width: this.state.visible2 ? '' : '48%',
                          marginRight: this.state.visible2 ? '' : '2%',
                        }}>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Preferred Activities</h2>
                          <p className='openSans'>{report.preferredActivitiesCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Leadership Style</h2>
                          <p className='openSans'>{report.leadershipStyleCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Communication Style</h2>
                          <p className='openSans'>{report.communicationStyleCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Generic Careers</h2>
                          <p className='openSans'>{report.genericCareersCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column'
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Famous people similar to this profile</h2>
                          <p className='openSans'>{report.famousPeopleSimilarToThisProfileCopy} </p>
                        </div>
                      </div>
                      <div
                        className='flex box-right column'
                        style={{
                          width: this.state.visible2 ? '' : '48%',
                          marginLeft: this.state.visible2 ? '' : '2%',
                        }}>
                        <div
                          className='box-shadow-report flex column '
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Contributions to the Organization</h2>
                          <p className='openSans'>{report.contributionsToTheOrganizationCopy}</p>
                        </div>
                        <div
                          className='box-shadow-report flex column '
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Task or People Orientation</h2>
                          <p className='openSans'>{report.taskOrPeopleOrientationCopy} </p>
                        </div>
                        <div
                          className='box-shadow-report flex column '
                          style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                          <h2>Suggestions for Managers</h2>
                          <p className='openSans suggest-p'>{suggestionsForManagers} </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='width-100 flex' style={{ border: '1px solid #E0E0E0', margin: '50px 0px' }} />
                  <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                    <div className='flex width-100 space-between '>
                      <div className='flex width-100 column title_work'>
                        <h1 style={{ textTransform: 'uppercase', color: '#3B6C98', lineHeight: '59px' }}>{label}</h1>
                        <h1 style={{ textTransform: 'uppercase', color: '#DDB87A', lineHeight: '59px' }}>and issues</h1>
                      </div>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={issue} />
                    </div>
                  </div>
                  <div
                    className='width-100 mobile'
                    style={{ width: this.state.visible2 ? '100%' : '', display: this.state.visible2 ? '' : 'none' }}>
                    <div className='flex width-100 space-between column title_Report_Admin'>
                      <LazyLoadImage effect="blur"
                        src={issue}
                        style={{
                          width: this.state.visible2 ? 'auto' : '24%',
                          marginLeft: this.state.visible2 ? '' : '38%',
                        }}
                      />
                      <div className='flex width-100 column' style={{ marginTop: '24.93px' }}>
                        <h1 style={{ textTransform: 'uppercase', color: '#3B6C98' }}>{label}</h1>
                        <h1 style={{ textTransform: 'uppercase', color: '#DDB87A' }}>and issues</h1>
                      </div>
                    </div>
                  </div>
                  <div className='dekstop width-100'>
                    <div className='width-100' style={{ marginTop: '50px' }}>
                      <div className='box-wavy-gold' style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                        <h2>Stability/Flexibility Factors</h2>
                        <p className='openSans'>{report.stabilityFlexibilityFactorsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                        <h2>Stress Factors</h2>
                        <p className='openSans'>{report.stressFactorsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                        <h2>Relationship Improvement Suggestions</h2>
                        <p className='openSans'>{report.relationshipImprovementSuggestionsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                        <h2>Cooperation and Control Factors</h2>
                        <p className='openSans'>{report.cooperationAndControlFactorsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                        <h2>Underlying Concerns</h2>
                        <p className='openSans'>{report.underlyingConcernsCopy} </p>
                      </div>
                    </div>
                  </div>
                  <div className='mobile width-100'>
                    <div className='width-100' style={{ marginTop: '50px' }}>
                      <div className='box-wavy-gold' style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                        <h2>Stability/Flexibility Factors</h2>
                        <p className='openSans'>{report.stabilityFlexibilityFactorsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                        <h2>Stress Factors</h2>
                        <p className='openSans'>{report.stressFactorsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                        <h2>Relationship Improvement Suggestions</h2>
                        <p className='openSans'>{report.relationshipImprovementSuggestionsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                        <h2>Cooperation and Control Factors</h2>
                        <p className='openSans'>{report.cooperationAndControlFactorsCopy} </p>
                      </div>
                      <div className='box-wavy-gold' style={{ border: this.state.visible2 ? '' : '1px solid #ececec' }}>
                        <h2>Underlying Concerns</h2>
                        <p className='openSans'>{report.underlyingConcernsCopy} </p>
                      </div>
                    </div>
                  </div>
                  <div className='width-100 flex' style={{ border: '1px solid #E0E0E0', margin: '50px 0px' }} />
                  <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                    <div className='flex width-100 space-between '>
                      <div className='flex width-100 column title_work'>
                        <h1 style={{ textTransform: 'uppercase', color: '#3B6C98', lineHeight: '59px' }}>{label}</h1>
                        <h1 style={{ textTransform: 'uppercase', color: '#DDB87A', lineHeight: '59px' }}>
                          and finances
                        </h1>
                      </div>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={finance} />
                    </div>
                  </div>
                  <div className='width-100 mobile' style={{ display: this.state.visible2 ? '' : 'none' }}>
                    <div className='flex width-100 space-between column title_Report_Admin'>
                      <LazyLoadImage effect="blur"
                        src={finance}
                        style={{
                          width: this.state.visible2 ? 'auto' : '16%',
                          marginLeft: this.state.visible2 ? '' : '42%',
                          objectFit: 'contain',
                        }}
                      />
                      <div className='flex width-100 column' style={{ marginTop: '24.93px' }}>
                        <h1 style={{ textTransform: 'uppercase', color: '#3B6C98' }}>{label}</h1>
                        <h1 style={{ textTransform: 'uppercase', color: '#DDB87A' }}>and finances</h1>
                      </div>
                    </div>
                  </div>
                  <div className='width-100' style={{ marginTop: '50px' }}>
                    <div
                      className='flex width-100 space-between finance_Column'
                      style={{ flexDirection: this.state.visible2 ? '' : 'row' }}>
                      <div className='bg_finance' style={{ width: this.state.visible2 ? '' : '27%' }}>
                        <h2>Financial Management</h2>
                        <p className='openSans'>{report.financialManagementCopy} </p>
                      </div>
                      <div className='bg_finance' style={{ width: this.state.visible2 ? '' : '27%' }}>
                        <h2>Budget and Financial Issues</h2>
                        <p className='openSans'>{report.budgetAndFinancialIssuesCopy} </p>
                      </div>
                      <div className='bg_finance' style={{ width: this.state.visible2 ? '' : '27%' }}>
                        <h2>Purchasing Tendencies</h2>
                        <p className='openSans'>{report.purchasingTendenciesCopy} </p>
                      </div>
                    </div>
                  </div>
                  <div className='width-100 flex' style={{ border: '1px solid #E0E0E0', margin: '50px 0px' }} />
                  <div className='dimensions'>
                    <h1>dimensions of personality</h1>
                    <p className='flex width-100 center openSans personal-text'>
                      This report is based on four dimensions of personality. Each dimension has a continuum of
                      behaviours associated with it as shown below.
                    </p>
                    <div className='width-100 dekstop'>
                      <div className='table_dimensions'>
                        <div className='sub_table'>
                          <p className='text1'>Adaptive</p>
                          <div className='center-range'>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                            <p className='text'>Mid-range</p>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                          </div>
                          <p className='text2'>Directing</p>
                        </div>
                        <div className='sub_table'>
                          <p className='text1'>Reserved</p>
                          <div className='center-range'>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                            <p className='text'>Mid-range</p>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                          </div>
                          <p className='text2'>Interacting</p>
                        </div>
                        <div className='sub_table'>
                          <p className='text1'>Objective</p>
                          <div className='center-range'>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                            <p className='text'>Mid-range</p>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                          </div>
                          <p className='text2'>Supportive</p>
                        </div>
                        <div className='sub_table'>
                          <p className='text1'>Unconventional</p>
                          <div className='center-range'>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                            <p className='text'>Mid-range</p>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                          </div>
                          <p className='text2'>Conscientious</p>
                        </div>
                      </div>
                    </div>
                    <div className='width-100 mobile-flex center'>
                      {/* <div className=" flex width-100 center"> */}
                      <div className='dekstop width-100' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                        <div className='flex width-100 center'>
                          <div
                            className='flex box_dimensions_personality'
                            style={{ margin: '0px 0%', display: 'flow-root', overflow: 'auto' }}>
                            <div className='table_dimensions' style={{ width: '700px', marginLeft: '0px' }}>
                              <div className='sub_table'>
                                <p className='text1'>Adaptive</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Directing</p>
                              </div>
                              <div className='sub_table'>
                                <p className='text1'>Reserved</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Interacting</p>
                              </div>
                              <div className='sub_table'>
                                <p className='text1'>Objective</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Supportive</p>
                              </div>
                              <div className='sub_table'>
                                <p className='text1'>Unconventional</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Conscientious</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='width-100 mobile' style={{ display: this.state.visible2 ? '' : 'none' }}>
                        <div className='width-100 flex center'>
                          <div
                            className='flex box_dimensions_personality'
                            style={{
                              margin: '0px 0%',
                              display: 'flex',
                              overflow: 'auto',
                              width: this.state.visible2 ? `${this.state.width * 0.7}px` : '700px',
                            }}>
                            <div className='table_dimensions' style={{ width: '700px', marginLeft: '0px' }}>
                              <div className='sub_table'>
                                <p className='text1'>Adaptive</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Directing</p>
                              </div>
                              <div className='sub_table'>
                                <p className='text1'>Reserved</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Interacting</p>
                              </div>
                              <div className='sub_table'>
                                <p className='text1'>Objective</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Supportive</p>
                              </div>
                              <div className='sub_table'>
                                <p className='text1'>Unconventional</p>
                                <div className='center-range'>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrLeft} className='arrLeft' />
                                  <p className='text'>Mid-range</p>
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrRight} className='arrRight' />
                                </div>
                                <p className='text2'>Conscientious</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className='struggle'>
                    <h1
                      style={{
                        fontSize: this.state.visible2 ? '' : '24px',
                        lineHeight: this.state.visible2 ? '' : '29px',
                      }}>
                      Strengths and Struggles
                    </h1>
                    <p className='openSans'>
                      The strengths and struggles in this section of the report relate to your Personality Profiler
                      scores for the four individual dimensions. This information gives more depth to the report and
                      helps you as a manager to focus on the unique style of this individual.
                    </p>
                    <p className='openSans'>
                      There are many advantages to understanding a person’s unique set of talents and motivations, You
                      will enjoy operating in some environments more than in others, simply because they are a better
                      fit for this person’s natural style. A goal should be to align your work as much as possivle with
                      the personal profile strengths and minimize exposure in the areas in which people with this
                      profile typically struggle. On the other hand, a manager can coach a team member to help improve
                      weak areas. For example, a person who tends to be impatient and is not naturally a good listener
                      can improve relationships through a focused effort to improve these areas of struggle. Also keep
                      in mind that strengths overdone become weaknesses.
                    </p>
                    <p className='openSans'>
                      The strengths and struggles that follow are typical for people who share a common profile score.
                      Keep in mind that every individual is unique, so some of the items may not fit you. The list
                      should be objectively reviewed to help determine whether all of the descriptions apply.
                    </p>
                  </div>
                  <div className='dimension-one'>
                    <h1>dimension one:</h1>
                    <div className='flex center column'>
                      <div className='sub_title'>
                        <h3
                          style={{
                            fontSize: this.state.visible2 ? '' : '24px',
                            lineHeight: this.state.visible2 ? '' : '30px',
                          }}>
                          Adaptive - Directing
                        </h3>
                      </div>
                      <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                        <div
                          className='box-dimension-desc'
                          style={{
                            width: this.state.visible2 ? '' : '100%',
                            display: this.state.visible2 ? '' : 'flex',
                            justifyContent: this.state.visible2 ? '' : 'space-between',
                          }}>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Adaptive
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Pliable
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Conforming
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Passive
                            </p>
                          </div>
                          <div
                            className='box-centerRange'
                            style={{
                              width: this.state.visible2 ? '' : '60%',
                              margin: this.state.visible2 ? '' : 'auto 0px',
                            }}>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(ndirecting * 92) / 100}%` }}>
                                <p>{ndirecting} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Directing
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Assertive{' '}
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Decisive
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Controlling
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className='mobile width-100 '
                        style={{ flexDirection: 'column', display: this.state.visible2 ? '' : 'none' }}>
                        <div className='box-dimension-desc flex column'>
                          <div className='width-100 flex space-between'>
                            <div className='content align-left'>
                              <h3>Adaptive</h3>
                              <p>Pliable</p>
                              <p>Conforming</p>
                              <p>Passive</p>
                            </div>

                            <div className='content align-right'>
                              <h3>Directing</h3>
                              <p>Assertive </p>
                              <p>Decisive</p>
                              <p>Controlling</p>
                            </div>
                          </div>
                          <div className='box-centerRange'>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(ndirecting * 92) / 100}%` }}>
                                <p>{ndirecting} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {dataDimensionOne}
                    </div>
                  </div>
                  <div className='dimension-one'>
                    <h1>dimension two:</h1>
                    <div className='flex center column'>
                      <div className='sub_title'>
                        <h3
                          style={{
                            fontSize: this.state.visible2 ? '' : '24px',
                            lineHeight: this.state.visible2 ? '' : '30px',
                          }}>
                          Reserved - Interacting
                        </h3>
                      </div>
                      <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                        <div
                          className='box-dimension-desc'
                          style={{
                            width: this.state.visible2 ? '' : '100%',
                            display: this.state.visible2 ? '' : 'flex',
                            justifyContent: this.state.visible2 ? '' : 'space-between',
                          }}>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Reserved
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Modest
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Quiet
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Distant
                            </p>
                          </div>
                          <div
                            className='box-centerRange'
                            style={{
                              width: this.state.visible2 ? '' : '60%',
                              margin: this.state.visible2 ? '' : 'auto 0px',
                            }}>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(ninteracting * 92) / 100}%` }}>
                                <p>{ninteracting} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Interacting
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Outgoing
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Convincing{' '}
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Excitable
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className='mobile width-100 '
                        style={{ flexDirection: 'column', display: this.state.visible2 ? '' : 'none' }}>
                        <div className='box-dimension-desc flex column'>
                          <div className='width-100 flex space-between'>
                            <div className='content align-left'>
                              <h3>Reserved</h3>
                              <p>Modest</p>
                              <p>Quiet</p>
                              <p>Distant</p>
                            </div>

                            <div className='content align-right'>
                              <h3>Interacting</h3>
                              <p>Outgoing</p>
                              <p>Convincing </p>
                              <p>Excitable</p>
                            </div>
                          </div>
                          <div className='box-centerRange'>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(ninteracting * 92) / 100}%` }}>
                                <p>{ninteracting} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {dataDimensionTwo}
                    </div>
                  </div>
                  <div className='dimension-one'>
                    <h1>dimension three:</h1>
                    <div className='flex center column'>
                      <div className='sub_title'>
                        <h3
                          style={{
                            fontSize: this.state.visible2 ? '' : '24px',
                            lineHeight: this.state.visible2 ? '' : '30px',
                          }}>
                          Objective - Supportive
                        </h3>
                      </div>
                      <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                        <div
                          className='box-dimension-desc'
                          style={{
                            width: this.state.visible2 ? '' : '100%',
                            display: this.state.visible2 ? '' : 'flex',
                            justifyContent: this.state.visible2 ? '' : 'space-between',
                          }}>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Objective
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Questioning{' '}
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Impatient
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Critical
                            </p>
                          </div>
                          <div
                            className='box-centerRange'
                            style={{
                              width: this.state.visible2 ? '' : '60%',
                              margin: this.state.visible2 ? '' : 'auto 0px',
                            }}>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(nsupportive * 92) / 100}%` }}>
                                <p>{nsupportive} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Supportive
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Harmonious
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Patient
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Lenient
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className='mobile width-100 '
                        style={{ flexDirection: 'column', display: this.state.visible2 ? '' : 'none' }}>
                        <div className='box-dimension-desc flex column'>
                          <div className='width-100 flex space-between'>
                            <div className='content align-left'>
                              <h3>Objective</h3>
                              <p>Questioning </p>
                              <p>Impatient</p>
                              <p>Critical</p>
                            </div>

                            <div className='content align-right'>
                              <h3>Supportive</h3>
                              <p>Harmonious</p>
                              <p>Patient</p>
                              <p>Lenient</p>
                            </div>
                          </div>
                          <div className='box-centerRange'>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(nsupportive * 92) / 100}%` }}>
                                <p>{nsupportive} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {dataDimensionThree}
                    </div>
                  </div>
                  <div className='dimension-one'>
                    <h1>dimension four:</h1>
                    <div className='flex center column'>
                      <div className='sub_title'>
                        <h3
                          style={{
                            fontSize: this.state.visible2 ? '' : '24px',
                            lineHeight: this.state.visible2 ? '' : '30px',
                          }}>
                          Unconventional - Conscientious
                        </h3>
                      </div>
                      <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                        <div
                          className='box-dimension-desc'
                          style={{
                            width: this.state.visible2 ? '' : '100%',
                            display: this.state.visible2 ? '' : 'flex',
                            justifyContent: this.state.visible2 ? '' : 'space-between',
                          }}>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Unconventional
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Instinctive
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Improvised
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Unorganized
                            </p>
                          </div>
                          <div
                            className='box-centerRange'
                            style={{
                              width: this.state.visible2 ? '' : '60%',
                              margin: this.state.visible2 ? '' : 'auto 0px',
                            }}>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(nconscientious * 92) / 100}%` }}>
                                <p>{nconscientious} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                          <div className='content'>
                            <h3
                              style={{
                                fontSize: this.state.visible2 ? '' : '24px',
                                lineHeight: this.state.visible2 ? '' : '33px',
                              }}>
                              Conscientious
                            </h3>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Detailed
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Precise
                            </p>
                            <p
                              style={{
                                fontSize: this.state.visible2 ? '' : '14px',
                                lineHeight: this.state.visible2 ? '' : '19px',
                              }}>
                              Perfectionist
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className='mobile width-100 '
                        style={{ flexDirection: 'column', display: this.state.visible2 ? '' : 'none' }}>
                        <div className='box-dimension-desc flex column'>
                          <div className='width-100 flex space-between'>
                            <div className='content align-left'>
                              <h3>Unconventional</h3>
                              <p>Instinctive</p>
                              <p>Improvised</p>
                              <p>Unorganized</p>
                            </div>

                            <div className='content align-right'>
                              <h3>Conscientious</h3>
                              <p>Detailed</p>
                              <p>Precise</p>
                              <p>Perfectionist</p>
                            </div>
                          </div>
                          <div className='box-centerRange'>
                            <div className='range-dimension-one'>
                              <div className='dynamic-range' style={{ marginLeft: `${(nconscientious * 92) / 100}%` }}>
                                <p>{nconscientious} </p>
                              </div>
                              <div className='center-range'></div>
                            </div>
                            <div className='flex width-100 space-between'>
                              <p>20</p>
                              <p>50</p>
                              <p>80</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {dataDimensionFour}
                    </div>
                  </div>
                  <div className='box-conclusion'>
                    <div className='flex width-100' style={{ marginBottom: '24px' }}>
                      <div className='line-con'></div>
                      <h1>CONCLUSION</h1>
                      <div className='line-con'></div>
                    </div>
                    <p className='openSans '>{dataReport.components.shared.conclusion.single.conclusionCopy}</p>
                  </div>
                  <div className='flex width-100 center' style={{ marginBottom: '59px' }}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} />
                  </div>
                </div>
              </div>
            </PDFExport>
          </div>
        </div>
        <Modal  id="course-outline41" visible={this.state.popup_personality} effect="fadeInUp" onClickAway={() => this.closePopupPersonality()}>
            <div className="popup_dlc bb flex column">
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={() => this.closePopupPersonality()}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                 <h2 className="openSans">Rate this Personality Profiler</h2>
                 <Rating name="pristine" value={this.state.rate} 
                  onClick={this.changeRatting} onChange={this.changeRatting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)}
                  // onChange={this.changeRatting}
                  />
                 <h3 className="openSans flex">{this.state.desc} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1)Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4)Good</p>
                   <p className="openSans">(5)Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this Personality Profiler</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                   {/* <p>{this.state.comment}</p> */}
                 </div>
                 <button onClick={this.submitRatingPersonality} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    result2: state.allResult,
    dataReportValues1 : state.reportValuesRed,
  };
  
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllResult: (user_id) => {
      dispatch(getAllResult(user_id));
    },
    getReportValues: (user_id) => {
      dispatch(getReportValues(user_id));
    },
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(componentName);
