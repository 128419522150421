import React, { Component } from 'react';
import work_check from "../images/work_check.svg"
import work_bg from "../images/work_bg.svg"
import work_personality from "../images/work_personality.svg"
import work_interest from "../images/work_interest.svg"
import work_values from "../images/work_values.svg"
import lock_VIP from "../images/lock_VIP.svg"
import work_finished from "../images/work_finished.svg"
import ReactGA from "react-ga";
import diss from "../images/Assessment_diss.png"
import { connect } from "react-redux";
import {getProfileId} from "../_actions/user";
import Loading from "../components/Loading";
import axios from "axios";
import {API} from '../_redux/helper'
import {  getAllResult,getReportValues } from "../_actions/quest";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';


class _Work_Assessment extends Component {
    constructor() {
        super();
        this.state = {
          user_id: "",
          resVIP:"",
          data:[],
        };
      }
    
      componentDidMount() {
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        this.setState({ user_id: user_id });
        this.props.getProfileId(user_id);
        this.props.getAllResult(user_id);
        this.props.getReportValues(user_id)
        axios.get(`${API}/api/survey/findsubmission/${user_id}`)
        .then(response => {
            this.setState({resVIP:response.data})
        })
        ReactGA.pageview(window.location.pathname + window.location.search);
      }


    render() {
        const  profile  = this.props.ownProps;
        const { allRes } = this.props.result2;
        const {dataReportValues, isLoading} = this.props.dataReportValues1
        let n_val
       
        dataReportValues.data != undefined && dataReportValues.data.length > 0 ?
        dataReportValues.data.map((item,i)=>{
            if (i==0){
                n_val = item.persen_score
            }
        }):console.log("error");


       let val_interest 
       allRes.map((item,i)=>{
           if (i==0){
               val_interest = item.scoring
           }
       })

       if (isLoading) {
        return (
            <React.Fragment>
            <Loading />
            </React.Fragment>
        );
        }
       
        


        let bt_values
        let bt_interest
        let bt_pid
        let bt_vip
        let ck_1
        let ck_2
        let ck_3
        let ck_4
        let progress_bar
        let progress_bar2

        if (profile.is_work_assessment == true){
            bt_values = <Link to="/Values-Profiler">
                            <button style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98"}}>
                                <p>View Individual Report</p>
                            </button>
                            
                        </Link>
            ck_3  =     <div className="work-cycle" style={{backgroundColor:"#3B6C98"}}>
                            <img loading="lazy" alt="" src={work_check} style={{margin:"auto"}}/>
                        </div>
        }else{
            bt_values = <Link to="/values_profiler">
                            <button>
                                <p>Start Assessment</p>
                            </button>
                        </Link>
            ck_3  =<div className="work-cycle"></div>
        }

        if (val_interest > 0){
            bt_interest = <Link to="/MyAssessment">
                            <button style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98"}}>
                                <p>View Individual Report</p>
                            </button>
                        </Link>
            ck_2  = <div className="work-cycle" style={{backgroundColor:"#3B6C98"}}>
                        <img loading="lazy" alt="" src={work_check} style={{margin:"auto"}}/>
                    </div>
        }else{
            bt_interest = <Link to="/Howtodo">
                            <button>
                                <p>Start Assessment</p>
                            </button>
                        </Link>
            ck_2  =<div className="work-cycle"></div>
        }

        if ( this.state.resVIP !== "Data Not Found"){
            bt_pid = <Link to="/Report">
                            <button style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98"}}>
                                <p>View Individual Report</p>
                            </button>
                        </Link>
            ck_1  = <div className="work-cycle " style={{backgroundColor:"#3B6C98"}}>
                        <img loading="lazy" alt="" src={work_check} style={{margin:"auto"}}/>
                    </div>
        }else{
            bt_pid = <Link to="/personality_profiler">
                            <button>
                                <p>Start Assessment</p>
                            </button>
                        </Link>
            ck_1  =<div className="work-cycle"></div>
        }

        if ((profile.is_work_assessment == true) && (val_interest > 0) && (this.state.resVIP !== "Data Not Found")){
            bt_vip =   <div className="flex height-100 column space-between width-100">
                        <div className="flex width-100 column">
                            <div className="width-auto flex center column" style={{padding:"14px"}}>
                                <img loading="lazy" alt="" className="work-img" src={work_finished} />
                                <p className="work-text openSans" style={{color:"white"}}>With all the combined report of your Values, Interest, and Personality profiler, through this Full Report you will get more valuable insights on how to be the best version of you at work.</p>
                            </div>
                        </div>
                        <div className="flex width-100 center">
                            <Link to="/VIP_Report">
                            <button style={{backgroundColor:"#E2BE81", border:"1px solid #E2BE81"}}>
                                <p>View Full Report</p>
                            </button>
                            </Link>
                        </div> 
                    </div>      
            ck_4  =<div className="work-cycle" style={{backgroundColor:"#3B6C98"}}>
                    <img loading="lazy" alt="" src={work_check} style={{margin:"auto"}}/>
                </div>
            progress_bar = 79
            progress_bar2 = 100
        }else{
            bt_vip = <img loading="lazy" alt="" src={diss} className="height-100 width-100"  />
            ck_4  = <div className="work-cycle" style={{backgroundColor:" #DADADA"}}>
                        <img loading="lazy" alt="" src={lock_VIP} style={{margin:"auto"}}/>
                    </div>
            progress_bar = 1
            progress_bar2 = 0
                    
        }


        return (
            <div>
               <div className="box-wrapper bg-color work_assessment" >
                    <div className="wrapper">
                        <div className="content ">
                            <div className="page-container scrollContent width-100">
                                <div className="page-container-outer job-summary dashboard">
                                    <div className="box-logo width-100">
                                      <h1 className="titleSpartan">Assessments </h1>
                                    </div>
                                    <div className="width-100 flex column box_work_assessment">
                                        <div className="width-100 flex center column">
                                            <div className="dekstop width-100">
                                            <div className="flex width-100 center">
                                                <div className="box-work-cycle">
                                                    <div className="flex line-cycle column" >
                                                    <div className="flex height-100 column fill-line" style={{backgroundColor:"#3B6C98", width:`${progress_bar}%`, position:"absolute", height:"4px"}} >
                                                        </div>
                                                        <div className="flex width-100 space-between">
                                                        {ck_1} {ck_2} {ck_3} {ck_4}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex space-between width-100 ">
                                                <div className="flex center column box-subTitle" >
                                                    <h3 className="openSans" style={{color:(this.state.resVIP !== "Data Not Found")? " #333333":" #3B6C98"}}>Take Personality Profiler</h3>
                                                </div>
                                                <div className="flex center column box-subTitle" >
                                                    <h3 className="openSans" style={{color:(val_interest > 0)? " #333333":" #3B6C98"}}>Take Interest Profiler</h3>
                                                </div>
                                                <div className="flex center column box-subTitle" >
                                                    <h3 className="openSans" style={{color:(n_val > 0)? " #333333":" #3B6C98"}}>Take Values Profiler</h3>
                                                </div>
                                                <div className="flex center column box-subTitle" >
                                                    <h3 className="openSans" style={{color:((n_val > 0) && (val_interest > 0) && (this.state.resVIP !== "Data Not Found"))? " #333333":" #3B6C98"}}>Unlock VIP Report</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mobile width-100">
                                            <div className="flex width-100 height-work-step">
                                                <div className="box-work-cycle">
                                                    <div className="flex line-cycle column" >
                                                        <div className="flex height-100 column width-100" style={{backgroundColor:"#3B6C98", height:`${progress_bar2}%`}} >
                                                        </div>
                                                    </div>
                                                    <div className="flex height-100 column width-100 space-between" style={{marginTop:"12px", marginLeft:"-22px"}}>
                                                        {ck_1} {ck_2} {ck_3} {ck_4}
                                                    </div>
                                                </div>
                                            
                                                <div className="flex space-between column width-80 ">
                                                    <div className="flex center column box-subTitle" >
                                                        <h3 className="openSans" style={{color:(this.state.resVIP !== "Data Not Found")? " #333333":" #3B6C98"}}>Take Personality Profiler</h3>
                                                    </div>
                                                    <div className="flex center column box-subTitle" >
                                                        <h3 className="openSans" style={{color:(val_interest > 0)? " #333333":" #3B6C98"}}>Take Interest Profiler</h3>
                                                    </div>
                                                    <div className="flex center column box-subTitle" >
                                                        <h3 className="openSans" style={{color:(n_val > 0)? " #333333":" #3B6C98"}}>Take Values Profiler</h3>
                                                    </div>
                                                    <div className="flex center column box-subTitle" >
                                                        <h3 className="openSans" style={{color:((n_val > 0) && (val_interest > 0) && (this.state.resVIP !== "Data Not Found"))? " #333333":" #3B6C98"}}>Unlock VIP Report</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="width-100 revert" >
                                        <div className="flex wd-steps space-between columnMobile " style={{marginTop:"49px"}}>
                                            <div className="flex center box-content">
                                                <div className="flex width-100 column">
                                                    <div className="header-box-content" style={{backgroundImage:`url(${work_bg})`}}>
                                                        <h5>Personality Profiler</h5>
                                                    </div>
                                                    <div className="width-auto flex center column" style={{padding:"14px"}}>
                                                        <div className="flex width-100 box-img-work">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="work-img" src={work_personality} />
                                                        </div>
                                                        <p className="work-text openSans">This profiler will assess your personality and behavioral style to improve work productivity and teamwork, letting you know Who you really are. It is licensed by Crown Do Well.</p> 
                                                    </div>
                                                </div>
                                                <div className="flex width-100 center">
                                                   {bt_pid}
                                                </div>
                                            </div>
                                            <div className="flex center box-content">
                                                <div className="flex width-100 column">
                                                    <div className="header-box-content" style={{backgroundImage:`url(${work_bg})`}}>
                                                        <h5>Interest Profiler</h5>
                                                    </div>
                                                    <div className="width-auto flex center column" style={{padding:"14px"}}>
                                                        <div className="flex width-100 box-img-work">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="work-img" src={work_interest} />
                                                        </div>
                                                        <p className="work-text openSans">This profiler will assess the nature of your work interest. Based on that, you will understand more about your various interests that are suitable for you. This profiler is based on Holland code assessment developed by John L. Holland.</p>
                                                    </div>
                                                </div>
                                                <div className="flex width-100 center">
                                                {bt_interest}
                                                </div>
                                            </div>
                                            <div className="flex center box-content">
                                                <div className="flex width-100 column">
                                                    <div className="header-box-content" style={{backgroundImage:`url(${work_bg})`}}>
                                                        <h5>Values Profiler</h5>
                                                    </div>
                                                    <div className="width-auto flex center column" style={{padding:"14px"}}>
                                                        <div className="flex width-100 box-img-work">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" className="work-img" src={work_values} />
                                                        </div>
                                                        <p className="work-text openSans">This profiler will assess the work values preferences of each person. It is created and adapted from the theory of Dr. Shalom Schwartz's about human basic values.</p>
                                                    </div>
                                                </div>
                                                <div className="flex width-100 center">
                                                {bt_values}
                                                </div>
                                            </div>
                                            <div className="flex center box-content" style={{backgroundColor:"#3B6C98", border:"1px solid #3B6C98"}}>
                                               {bt_vip}
                                                
                                            </div>
                                        </div>
                                        </div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      profile1: state.profileId,
      result2: state.allResult,
      dataReportValues1 : state.reportValuesRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
      getAllResult: (user_id) => {
        dispatch(getAllResult(user_id));
      },
      getReportValues: (user_id) => {
        dispatch(getReportValues(user_id));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(_Work_Assessment);
