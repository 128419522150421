import React, { Component } from "react";
import bg_homepage_22 from "../images/bg_homepage_22.png";
import art_homepage_22 from "../images/art_hp.png";
import dashline_homepage_22 from "../images/dashline_homepage_22.png";
import { Helmet } from "react-helmet";
import hp_candidates from "../images/hp_candidates.svg";
import hp_clients from "../images/hp_clients.svg";
import hp_assess from "../images/hp_assess.svg";
import hp_advice from "../images/hp_advice.svg";
import Loading from "../components/Loading";
import hp_match from "../images/hp_match.svg";
import hp_connector_line from "../images/hp_connector_line.svg";
import connector_mobile from "../images/connector_mobile.svg";
import About from "../images/About.jpg";
import _ from "lodash";
import hp_ex_human from "../images/hp_ex_human.jpg";
import hp_ex_public from "../images/hp_ex_public.jpg";
import hp_ex_finance from "../images/hp_ex_finance.jpg";
import hp_ex_sales from "../images/hp_ex_sales.jpg";
import hp_ex_management from "../images/hp_ex_management.jpg";
import hp_bg_join from "../images/hp_bg_join.svg";
import yellow_dots from "../images/yellow_dots.svg";
import yellow_dots2 from "../images/yellow_dots2.svg";
import hp_bootcamp from "../images/hp_bootcamp.png";
import hp_Prev from "../images/hp_Prev.svg";
import hp_Next from "../images/hp_Next.svg";
import Footer from "../components/Footer";
import Navbar_Homepage from "../components/Navbar_Homepage";
import { connect } from "react-redux";
import { API } from "../_redux/helper";
import { getJobFunction } from "../_actions/work";
import { jobByTitle } from "../_actions/jobs";
import {
  priceSplitter,
  animatedText,
  animatedText_desc,
  wave,
  sortFunction,
  checkId,
  sortUpdate,
} from "../_redux/function";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import { getAllArticle } from "../_actions/article";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";

class About_Acquisition extends Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.clients = React.createRef();
    this.career = React.createRef();
    this.hiring = React.createRef();
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.image = React.createRef();
    this.state = {
      link: "",
      talents: false,
      resources: false,
      path: ["/", "/jo"],
      transform: "",
      no: 0,
      hiring: 0,
      career: 0,
      no_client: 0,
      id_slide: "",
      list_index: [1, 4, 7, 10],
      before: false,
      after: false,
      dis: false,
      candidates: [],
      clients: [],
      filter: {},
      data_dynamic: [],
      aboutUs: [],
      data_banner: [],
      height_: 0,
      n: 0,
      sal: "",
      br: 0,
      salary: 0,
      loaded: false,
      images: [
        hp_ex_human,
        hp_ex_sales,
        hp_ex_finance,
        hp_ex_public,
        hp_ex_management,
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    window.addEventListener("scroll", this.handleScroll);
    if (localStorage.getItem("token")) {
      window.location.href = "/NewDashboard";
    }
    const img = this.image.current;
    if (img && img.complete) {
      this.handleImageLoaded();
    }
    this.props.getJobFunction();
    this.props.jobByTitle(1, "", "", "", "", "", "", "", "", "");
    this.props.getAllArticle();
    axios.get(`${API}/api/new_homepage/bydb/about_us_consummer`).then((res) => {
      res.data.map((item) => {
        this.state.aboutUs.push(item);
        if (item.category === "Candidates") {
          this.state.candidates.push(item);
        } else if (item.category === "Clients") {
          this.state.clients.push(item);
        } else {
          this.state.data_dynamic.push(item);
        }
      });
    });
    axios.get(`${API}/api/new_homepage/bydb/homepage_banner`).then((res) => {
      res.data.map((item) => {
        this.state.data_banner.push(item);
      });
    });

    if (window.location.search !== "") {
      const n = window.location.search
        .split("?code=")[1]
        .replace("&state=_linkedin", "");
      this.postLogin(n);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  postLogin = (e) => {
    const data = { ltoken: e };
    axios
      .post(`${API}/api/login`, data)
      .then((res) => {
        if (
          res.data.is_tooltip === "" ||
          res.data.is_tooltip === null ||
          res.data.is_tooltip === "null" ||
          res.data.is_tooltip === undefined
        ) {
          sessionStorage.setItem("tooltip", 1);
        }
        sessionStorage.setItem("sidebar", false);
        if (res.data.emailconfirmed === true) {
          localStorage.setItem("token", res.data.token);
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("email", res.data.email);
          localStorage.setItem("email", res.data.email);
          sessionStorage.setItem(
            "kuesionerconfirmed",
            res.data.kuesionerconfirmed
          );
          localStorage.setItem(
            "kuesionerconfirmed",
            res.data.kuesionerconfirmed
          );
          sessionStorage.setItem("rating-values", false);
          sessionStorage.setItem("rating-interest", false);
          sessionStorage.setItem("rating-vip", false);
          sessionStorage.setItem("rating-personality", false);
          sessionStorage.setItem("rating-program", false);
          if (
            window.location.search &&
            window.location.search.indexOf("refer_code") === -1
          ) {
            window.location.href = "/Welcome-to-KaryaTalents";
          } else if (
            res.data.is_new_journey === null ||
            res.data.is_new_journey === "" ||
            res.data.is_new_journey === 0 ||
            res.data.is_new_journey === "null"
          ) {
            window.location.href = "/Welcome-to-KaryaTalents";
          } else if (
            res.data.is_new_journey === true ||
            res.data.is_new_journey === "true"
          ) {
            let list_ref = [
              "/referral",
              "/work_assessment",
              "/myprofile",
              "/newdashboard",
              "/digitallearningplatform",
              "/careercenter",
              "/jobopportunities",
            ];
            setTimeout(() => {
              if (sessionStorage.getItem("ref") !== null) {
                if (
                  list_ref.indexOf(
                    sessionStorage.getItem("ref").toLowerCase()
                  ) !== -1
                ) {
                  window.location.href = sessionStorage.getItem("ref");
                } else {
                  window.location.href = "/NewDashboard";
                }
              } else {
                window.location.href = "/NewDashboard";
              }
            }, 200);
          }
        } else if (
          res.data.emailconfirmed === false ||
          res.data.kuesionerconfirmed === false
        ) {
          this.setState({ verify_: true, email_v: this.state.email });
        }
      })
      .catch((err) => {
        this.setState({ failedLogin: true });
      });
  };

  handleImageLoaded() {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  }

  handleCallback = (childData) => {
    this.setState({ link: childData });
  };

  setLink = (x, y) => {
    if (y === "talents") {
      this.setState({ talents: false });
    } else if (y === "resources") {
      this.setState({ resources: false });
    }
    window.location.href = x;
  };

  showMenu = (x, y) => {
    if (y === "talents") {
      this.setState({ talents: x });
    } else {
      this.setState({ resources: x });
    }
  };

  handleScroll = (event) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (parseFloat(winScroll) < 250) {
      const height = document.getElementById("container-u").clientHeight;
      this.setState({ height_: height });
      this.setState({ br: scrolled });
    }
  };

  checkWidth = (e) => {
    let width = 0;
    if (this.state.br > 0) {
      width = document.getElementById(e).clientWidth;
    } else {
      width = 10;
    }
    return width;
  };

  prevSlider = (e) => {
    const { candidates } = this.state;
    let n;
    if (candidates > 0) {
      n = candidates - 0;
    } else {
      n = 0;
    }
    this.setState({ candidates: n });
  };

  formatRupiah = (angka) => {
    var number_string = angka.replace(/[^,\d]/g, "").toString(),
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    const prefix = rupiah ? "Rp. " + rupiah : "";
    return prefix;
  };

  filterJob = (e, y) => {
    const n = this.state.filter;
    if (y === "salary_from") {
      if (!/[0-9]/.test(e)) {
        if (this.state.salary.length <= 5 && e == "Rp. ") {
          this.setState({ salary: 0 });
          n[y] = e;
          this.setState({ n });
          this.setState({ sal: 0 });
        }
      } else {
        this.setState({ salary: e });
        n[y] = e;
        this.setState({ n });
        this.setState({
          sal: this.formatRupiah(
            (
              parseInt(
                e.replace(/^\D+/g, "").replace(".", "").replace(".", "")
              ) * 1
            ).toString()
          ),
        });
      }
    } else {
      n[y] = e;
      this.setState({ n });
    }
  };

  searchJob = (e, y) => {
    const n = this.state.filter;
    let job_function_id;
    if (y === "job") {
      job_function_id = e;
    } else {
      job_function_id = n["job_function"] != undefined ? n["job_function"] : "";
    }

    const m = {
      city_id: "",
      country_id: "",
      industry_id: [],
      job_function_id: job_function_id,
      job_level_id: [],
      job_title: n["job_title"] != undefined ? n["job_title"] : "",
      job_type: [],
      salary_from:
        n["salary_from"] != undefined
          ? parseInt(
              this.state.salary
                .replace(/^\D+/g, "")
                .replace(".", "")
                .replace(".", "")
            ) * 1
          : 0,
      salary_to: 100000000,
      user_id: 1,
    };
    sessionStorage.setItem("jobOpportunitySearch", JSON.stringify(m));
    window.location.href = "/Job-Opportunity-Public";
    const mz = {
      salary_from: "",
      job_function: "",
      job_title: "",
    };
    this.setState({ filter: mz });
  };

  searchJobOpportunity = (e, y) => {
    const m = {
      city_id: "",
      country_id: "",
      industry_id: [],
      job_function_id: e !== "" ? [e] : "",
      job_level_id: [],
      job_title: "",
      job_type: [],
      salary_from: 0,
      salary_to: 100000000,
      user_id: 1,
    };
    sessionStorage.setItem("jobOpportunitySearch", JSON.stringify(m));
    e !== ""
      ? (window.location.href = `/Job-Opportunity-Public/${y}`)
      : (window.location.href = `/Job-Opportunity-Public`);
  };

  render() {
    const {
      salary,
      transform,
      no,
      br,
      list_index,
      candidates,
      clients,
      filter,
      data_dynamic,
      hiring,
      career,
      data_banner,
      images,
    } = this.state;
    const { dataJobFunction } = this.props.jobFunction1;
    const { dataJobByTitle } = this.props.jobByTitle1;
    const { data, isLoading, error } = this.props.about1;
    var bulan = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const new_dataJob = dataJobByTitle.sort(sortFunction);
    const new_dataArtikel = data.sort(sortFunction);
    const randomImage = (e) => {
      return Math.floor(Math.random() * e.length);
    };
    let explore_job = [];
    if (dataJobFunction.length > 0) {
      dataJobFunction.map((item) => {
        if (item.status == 1) {
          explore_job.push(item);
        }
      });
    }

    let data_career = [];
    let data_hiring = [];
    if (new_dataArtikel.length > 0) {
      new_dataArtikel.map((item, i) => {
        if (parseInt(item.slug) === 2) {
          data_hiring.push(item);
        } else if (parseInt(item.slug) === 1 || item.slug === "") {
          data_career.push(item);
        } else {
          console.log("err");
        }
      });
    }
    const data_career2 = data_career.sort(sortFunction);
    const data_hiring2 = data_hiring.sort(sortFunction);
    let sc;
    if (br == 0) {
      sc = 0;
    } else {
      sc =
        parseFloat(window.innerHeight) / (parseFloat(this.state.height_) + 150);
    }
    let mt;
    const zn = parseFloat(window.innerHeight) - parseFloat(this.state.height_);
    if (sc > 1) {
      mt = 0;
    } else {
      mt = zn - 100;
    }
    const wh = parseFloat(window.innerHeight);
    const sh = parseFloat(this.state.height_) + 150;

    const showBanner = (e) => {
      let n;
      let img;
      let icon;
      let category;
      let subtitle;
      let sub_category;
      if (data_banner.length !== 0) {
        n = data_banner[e];
        img = `${API}/api/new_homepage/image/${data_banner[e].img}`;
        icon = `${API}/api/new_homepage/image/${data_banner[e].icon}`;
        category = data_banner[e].category;
        subtitle = data_banner[e].subtitle;
        sub_category = data_banner[e].sub_category;
      } else {
        n = "";
        img = "";
        icon = "";
        category = "";
        subtitle = "";
        sub_category = "";
      }
      return {
        img: img,
        category: category,
        icon: icon,
        subtitle: subtitle,
        sub_category: sub_category,
      };
    };

    const showJobFunction = (e) => {
      let n = {};
      let photo = "";
      dataJobFunction.map((item, i) => {
        if (item.id === e) {
          n = { name: item.job_function_name, photo: item.photo };
        }
      });

      if (n.photo !== undefined) {
        photo = n.photo
          .split(",")
          [randomImage(n.photo.split(","))].replace("]", "")
          .replace("[", "")
          .replace('"', "");
      } else {
        photo = "";
      }
      return { name: n.name, photo: photo };
    };

    const prevSlide = (e) => {
      let n;
      if (e > 0) {
        n = e - 1;
      } else {
        n = 0;
      }
      return n;
    };
    const nextSlide = (e, y) => {
      let n;
      if (e < y.length - 1) {
        n = e + 1;
      } else {
        n = y.length - 1;
      }
      return n;
    };

    const showImage = (e) => {
      let n;
      if (data_dynamic.length !== 0) {
        n = data_dynamic[e].img;
      } else {
        n = "";
      }
      return `${API}/api/employer_dynamic/image/${n}`;
    };

    const showContent = (e, y) => {
      let n;
      if (data_dynamic.length !== 0) {
        n = data_dynamic[e][y];
      } else {
        n = "";
      }
      return n;
    };
    const findIndexOfJob = (e) => {
      return dataJobFunction.findIndex((x) => x.job_function_name === e);
    };

    const setting_career = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      arrow: true,
      prevArrow: (
        <img
          src={hp_Prev}
          className="hg-168 flex br-20 width-100 mr-24 cover"
        />
      ),
      nextArrow: (
        <img
          src={hp_Next}
          className="hg-168 flex br-20 width-100 ml-24 cover"
        />
      ),
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      speed: 300,
      // initialSlide: 0,
      autoplay: false,
      draggable: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const settings2 = {
      dots: true,
      infinite: false,
      slidesToShow: 1,
      arrow: true,
      prevArrow: (
        <img
          src={hp_Prev}
          className="hg-168 flex br-20 width-100 mr-24 cover"
        />
      ),
      nextArrow: (
        <img
          src={hp_Next}
          className="hg-168 flex br-20 width-100 ml-24 cover"
        />
      ),
      slidesToScroll: 1,
      autoplaySpeed: 4000,
      // fade: true,
      // cssEase: 'linear',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "40px",
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "20px",
          },
        },
      ],
    };

    const settingImage = {
      dots: true,
      arrow: true,
      prevArrow: (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.8">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.9428 7.72378C21.4635 8.24448 21.4635 9.0887 20.9428 9.60939L13.8857 16.6666L20.9428 23.7238C21.4635 24.2445 21.4635 25.0887 20.9428 25.6094C20.4222 26.1301 19.5779 26.1301 19.0572 25.6094L11.0572 17.6094C10.5365 17.0887 10.5365 16.2445 11.0572 15.7238L19.0572 7.72378C19.5779 7.20308 20.4222 7.20308 20.9428 7.72378Z"
              fill="white"
            />
          </g>
        </svg>
      ),
      nextArrow: (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.8">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.0572 24.2762C10.5365 23.7555 10.5365 22.9113 11.0572 22.3906L18.1143 15.3334L11.0572 8.27622C10.5365 7.75553 10.5365 6.91131 11.0572 6.39061C11.5778 5.86991 12.4221 5.86991 12.9428 6.39061L20.9428 14.3906C21.4635 14.9113 21.4635 15.7555 20.9428 16.2762L12.9428 24.2762C12.4221 24.7969 11.5778 24.7969 11.0572 24.2762Z"
              fill="white"
            />
          </g>
        </svg>
      ),
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    };

    const clientSettings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      arrow: false,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      prevArrow: (
        <svg
          className="mr-53 pointer"
          width="12"
          height="19"
          viewBox="0 0 12 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.9428 0.72402C11.4635 1.24472 11.4635 2.08894 10.9428 2.60964L3.88566 9.66683L10.9428 16.724C11.4635 17.2447 11.4635 18.0889 10.9428 18.6096C10.4222 19.1303 9.57793 19.1303 9.05723 18.6096L1.05723 10.6096C0.536532 10.0889 0.536532 9.24472 1.05723 8.72402L9.05723 0.72402C9.57793 0.203321 10.4222 0.203321 10.9428 0.72402Z"
            fill="white"
          />
        </svg>
      ),
      nextArrow: (
        <svg
          className="ml-53 pointer"
          width="12"
          height="19"
          viewBox="0 0 12 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.05715 18.276C0.536451 17.7553 0.536451 16.9111 1.05715 16.3904L8.11434 9.33317L1.05715 2.27598C0.536451 1.75528 0.536451 0.911062 1.05715 0.390364C1.57785 -0.130337 2.42207 -0.130337 2.94277 0.390364L10.9428 8.39036C11.4635 8.91106 11.4635 9.75528 10.9428 10.276L2.94277 18.276C2.42207 18.7967 1.57785 18.7967 1.05715 18.276Z"
            fill="white"
          />
        </svg>
      ),
    };

    const candidateSettings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      arrow: false,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      prevArrow: (
        <svg
          className="mr-53 pointer"
          width="12"
          height="19"
          viewBox="0 0 12 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.9428 0.72402C11.4635 1.24472 11.4635 2.08894 10.9428 2.60964L3.88566 9.66683L10.9428 16.724C11.4635 17.2447 11.4635 18.0889 10.9428 18.6096C10.4222 19.1303 9.57793 19.1303 9.05723 18.6096L1.05723 10.6096C0.536532 10.0889 0.536532 9.24472 1.05723 8.72402L9.05723 0.72402C9.57793 0.203321 10.4222 0.203321 10.9428 0.72402Z"
            fill="white"
          />
        </svg>
      ),
      nextArrow: (
        <svg
          className="ml-53 pointer"
          width="12"
          height="19"
          viewBox="0 0 12 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.05715 18.276C0.536451 17.7553 0.536451 16.9111 1.05715 16.3904L8.11434 9.33317L1.05715 2.27598C0.536451 1.75528 0.536451 0.911062 1.05715 0.390364C1.57785 -0.130337 2.42207 -0.130337 2.94277 0.390364L10.9428 8.39036C11.4635 8.91106 11.4635 9.75528 10.9428 10.276L2.94277 18.276C2.42207 18.7967 1.57785 18.7967 1.05715 18.276Z"
            fill="white"
          />
        </svg>
      ),
    };
    let st_dis = false;
    if (br === 0) {
      st_dis = false;
    } else {
      st_dis = true;
    }

    if (showBanner(0).img === "") {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        {/* <Nav/> */}
        <div className="flex column width-100 Hp-22 _s bg-ff">
          <div
            className="Banner-bx width-100 mb-24"
            style={{
              backgroundImage: `url(${
                showBanner(0).img !== "" ? showBanner(0).img : bg_homepage_22
              })`,
            }}
            id="tuyq"
          >
            <Navbar_Homepage status={st_dis} />
            <div
              className="wd-content flex auto column height-100"
              id="container-u"
            >
              <div className="width-100 flex height-100">
                <div className="text-bx flex column ">
                  <Fade duration={1300} cascade right>
                    <div className="text-bx flex column ">
                      {/* <span className='c-ff flex Kanit-400 s-48 mt-234'>{showBanner(0).subtitle}</span>
                                <span className='c-ff Kanit-400 s-5684'>{showBanner(0).category} </span>
                                <span className='c-ff Kanit w-600 s-64'>{showBanner(0).sub_category} </span> */}
                      <br></br>
                      <br></br>
                      <br></br>
                      <p className="c-ff flex Kanit-400 s-48 mt-234">
                        ACQUISITION
                      </p>
                      <p className="c-ff Kanit-300 s-2436">Executive Search</p>
                      <p className="c-ff Kanit-300 s-2436">
                        Provide you with exemplary leaders for your
                        organization’s success!
                      </p>
                    </div>
                  </Fade>
                  <Slide bottom duration={500}>
                    <div className="flex-mob mt-38">
                      <div
                        className="bg-36  br-24 p-1024 bd-36 flex pointer mr-14"
                        onClick={(e) => this.searchJobOpportunity("")}
                      >
                        <span className="c-ff Kanit s-16 let-04 w-600 Montserrat text-cap">
                          FIND A JOB
                        </span>
                      </div>
                      <div
                        className="bg-12  br-24 p-1024 flex pointer"
                        onClick={(e) => (window.location.href = "/Employer")}
                      >
                        <span className="c-ff Kanit s-16 let-04 w-600 Montserrat text-cap">
                          HIRE TALENTS
                        </span>
                      </div>
                    </div>
                  </Slide>
                </div>

                <div
                  className="flex width-50 height-100 m-auto00 bg-contain no-repeat bg-position-bottom"
                  style={{
                    backgroundImage: `url(${
                      showBanner(1).img !== ""
                        ? showBanner(1).img
                        : dashline_homepage_22
                    })`,
                  }}
                >
                  <Zoom duration={1000}>
                    <img
                      loading="lazy"
                      src={showBanner(0).icon}
                      ref={this.image}
                      onLoad={this.handleImageLoaded}
                      onError={(e) => {
                        e.target.src = art_homepage_22;
                      }}
                      className="flex m-auto20 art_"
                    />
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky br-30 flex column wd-fit-content p-10 space-between">
            <a
              href="https://www.linkedin.com/company/karya-talents"
              rel="noopener noreferrer"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM8.2 13.3V24H11.6V13.3H8.2ZM8 9.9C8 11 8.8 11.8 9.9 11.8C11 11.8 11.8 11 11.8 9.9C11.8 8.8 11 8 9.9 8C8.9 8 8 8.8 8 9.9ZM20.6 24H23.8V17.4C23.8 14.1 21.8 13 19.9 13C18.2 13 17 14.1 16.7 14.8V13.3H13.5V24H16.9V18.3C16.9 16.8 17.9 16 18.9 16C19.9 16 20.6 16.5 20.6 18.2V24Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="https://instagram.com/karyatalents?igshid=odlswb4q0a06"
              rel="noopener noreferrer"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4 9.2H12.6C11.8 9.3 11.4 9.4 11.1 9.5C10.7 9.6 10.4 9.8 10.1 10.1C9.86261 10.3374 9.75045 10.5748 9.61489 10.8617C9.57916 10.9373 9.5417 11.0166 9.5 11.1C9.48453 11.1464 9.46667 11.1952 9.44752 11.2475C9.34291 11.5333 9.2 11.9238 9.2 12.6V19.4C9.3 20.2 9.4 20.6 9.5 20.9C9.6 21.3 9.8 21.6 10.1 21.9C10.3374 22.1374 10.5748 22.2495 10.8617 22.3851C10.9374 22.4209 11.0165 22.4583 11.1 22.5C11.1464 22.5155 11.1952 22.5333 11.2475 22.5525C11.5333 22.6571 11.9238 22.8 12.6 22.8H19.4C20.2 22.7 20.6 22.6 20.9 22.5C21.3 22.4 21.6 22.2 21.9 21.9C22.1374 21.6626 22.2495 21.4252 22.3851 21.1383C22.4209 21.0626 22.4583 20.9835 22.5 20.9C22.5155 20.8536 22.5333 20.8048 22.5525 20.7525C22.6571 20.4667 22.8 20.0762 22.8 19.4V12.6C22.7 11.8 22.6 11.4 22.5 11.1C22.4 10.7 22.2 10.4 21.9 10.1C21.6626 9.86261 21.4252 9.75045 21.1383 9.61488C21.0627 9.57918 20.9833 9.54167 20.9 9.5C20.8536 9.48453 20.8048 9.46666 20.7525 9.44752C20.4667 9.3429 20.0762 9.2 19.4 9.2ZM16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM21.4 11.6C21.4 12.1523 20.9523 12.6 20.4 12.6C19.8477 12.6 19.4 12.1523 19.4 11.6C19.4 11.0477 19.8477 10.6 20.4 10.6C20.9523 10.6 21.4 11.0477 21.4 11.6Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM12.6 7.7H19.4C20.3 7.8 20.9 7.9 21.4 8.1C22 8.4 22.4 8.6 22.9 9.1C23.4 9.6 23.7 10.1 23.9 10.6C24.1 11.1 24.3 11.7 24.3 12.6V19.4C24.2 20.3 24.1 20.9 23.9 21.4C23.6 22 23.4 22.4 22.9 22.9C22.4 23.4 21.9 23.7 21.4 23.9C20.9 24.1 20.3 24.3 19.4 24.3H12.6C11.7 24.2 11.1 24.1 10.6 23.9C10 23.6 9.6 23.4 9.1 22.9C8.6 22.4 8.3 21.9 8.1 21.4C7.9 20.9 7.7 20.3 7.7 19.4V12.6C7.8 11.7 7.9 11.1 8.1 10.6C8.4 10 8.6 9.6 9.1 9.1C9.6 8.6 10.1 8.3 10.6 8.1C11.1 7.9 11.7 7.7 12.6 7.7Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="https://web.facebook.com/KT300CLUB/?_rdc=1&_rdr"
              rel="noopener noreferrer"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16 8C20.4 8 24 11.6 24 16C24 20 21.1 23.4 17.1 24V18.3H19L19.4 16H17.2V14.5C17.2 13.9 17.5 13.3 18.5 13.3H19.5V11.3C19.5 11.3 18.6 11.1 17.7 11.1C15.9 11.1 14.7 12.2 14.7 14.2V16H12.7V18.3H14.7V23.9C10.9 23.3 8 20 8 16C8 11.6 11.6 8 16 8Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/channel/UCAXhjK2OivKsLeYPVNMsLHA"
              rel="noopener noreferrer"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18.6 16L14.4 13.6V18.4L18.6 16Z" fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM22.2 10.7C22.9 10.9 23.4 11.4 23.6 12.1C24 13.4 24 16 24 16C24 16 24 18.6 23.7 19.9C23.5 20.6 23 21.1 22.3 21.3C21 21.6 16 21.6 16 21.6C16 21.6 10.9 21.6 9.7 21.3C9 21.1 8.5 20.6 8.3 19.9C8 18.6 8 16 8 16C8 16 8 13.4 8.2 12.1C8.4 11.4 8.90001 10.9 9.60001 10.7C10.9 10.4 15.9 10.4 15.9 10.4C15.9 10.4 21 10.4 22.2 10.7Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
          <WhatsAppWidget
            phoneNumber="6287717888800"
            companyName="Karya Talents"
          />
          <div className="flex width-100 column center">
            <div className="flex-mob mb-100 search-job wd-1240 br-10 bg-d4 bd-d4 space-between p-14 shd-30 ">
              <input
                type="text"
                onChange={(e) => this.filterJob(e.target.value, "job_title")}
                value={filter["job_title"]}
                className="br-10 bg-ff shd_ p-14 s-14 Kanit wd-305 out-none bd-ff"
                placeholder="Search Job Here"
              />
              <select
                className="br-10 bg-ff shd_ p-14 s-1422 Kanit wd-305 out-none bd-ff"
                value={filter["job_function"]}
                onChange={(e) => this.filterJob(e.target.value, "job_function")}
              >
                <option style={{ display: "none" }}>Job Function</option>
                {dataJobFunction.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.job_function_name}
                  </option>
                ))}
              </select>
              <div className="flex column">
                <input
                  allowDecimals={true}
                  decimalsLimit={2}
                  ref={this.input}
                  onChange={(e) =>
                    this.filterJob(e.target.value, "salary_from")
                  }
                  value={salary !== 0 ? this.state.sal : ""}
                  className="br-10  bg-ff shd_ p-14 s-14 Kanit wd-305 hg-100 out-none bd-ff"
                  placeholder="Minimum Salary (Rp)"
                />
                {/* <p className={`absolute s-14 Kanit dec_ c-00`}>{salary!==0? "Rp":""}</p> */}
              </div>
              {/* <select className='br-10 bg-ff shd_ p-14 s-1422 Kanit wd-305 out-none bd-ff' value={filter["salary_from"]} onChange={(e)=>this.filterJob(e.target.value,"salary_from")} >
                                    <option style={{display:"none"}}>Minimum Salary</option>
                                    {min_salary.map((item)=>(
                                    <option>{priceSplitter(item*1)} </option>))}
                                </select> */}
              <div
                className="bg-12  br-24 p-1024 bd-12 flex pointer wd-fit-content "
                onClick={(e) => this.searchJob(e, "search")}
              >
                <span className="c-ff Kanit s-1422 let-04 w-600 Kanit text-cap auto">
                  SEARCH JOB
                </span>
              </div>
            </div>
            <Slide bottom duration={2000} cascade>
              <div className="flex column mb-24 wd-1240">
                <h2 className="s-48 Kanit-400 c-09">
                  Specialized in Recruiting for &nbsp;
                </h2>
                <h2 className="s-48 Kanit w-600 c-09">
                  Managerial Level and C-suite Executives
                </h2>
              </div>
            </Slide>
            <Fade bottom cascade>
              {data_dynamic[0] !== undefined && (
                <p
                  className="Kanit-300 wd-1240 s-1832 c-29 mb-48"
                  dangerouslySetInnerHTML={{
                    __html: data_dynamic[0].description,
                  }}
                />
              )}
            </Fade>
            <div className="flex space-between wd-962 mb-37 relative col-mobile">
              <img
                loading="lazy"
                ref={this.image}
                onLoad={this.handleImageLoaded}
                src={connector_mobile}
                className="none connector-line hg-100"
              />
              <div className="rad br-100 wd-150 hg-150 bg-02 shd-15 flex">
                <img
                  loading="lazy"
                  ref={this.image}
                  onLoad={this.handleImageLoaded}
                  src={showImage(1)}
                  className="flex hg-89 auto contain"
                />
              </div>
              <div className="bg-12 p-424 br-26 none">
                <p className="c-ff s-20 Kanit w-600">
                  {showContent(1, "subtitle")}{" "}
                </p>
              </div>
              <Slide bottom duration={1000}>
                <div className="none wd-228">
                  <p
                    className="Kanit-300 s-1828 c-28 "
                    dangerouslySetInnerHTML={{
                      __html: showContent(1, "description"),
                    }}
                  />
                </div>
              </Slide>
              <div className="rad br-100 wd-150 hg-150 bg-02 shd-15 flex">
                <img
                  loading="lazy"
                  src={showImage(2)}
                  ref={this.image}
                  onLoad={this.handleImageLoaded}
                  className="flex hg-89 auto contain"
                />
              </div>
              <div className="bg-12 p-424 br-26 none">
                <p className="c-ff s-20 Kanit w-600">
                  {showContent(2, "subtitle")}
                </p>
              </div>
              <Slide bottom duration={1000}>
                <div className="none wd-228">
                  <p
                    className="Kanit-300 s-1828 c-28 "
                    dangerouslySetInnerHTML={{
                      __html: showContent(2, "description"),
                    }}
                  />
                </div>
              </Slide>
              <div className="rad br-100 wd-150 hg-150 bg-02 shd-15 flex">
                <img
                  loading="lazy"
                  src={showImage(3)}
                  ref={this.image}
                  onLoad={this.handleImageLoaded}
                  className="flex hg-89 auto contain"
                />
              </div>
              <div className="bg-12 p-424 br-26 none">
                <p className="c-ff s-20 Kanit w-600">
                  {showContent(3, "subtitle")}
                </p>
              </div>
              <Slide bottom duration={1000}>
                <div className="none wd-228">
                  <p
                    className="Kanit-300 s-1828 c-28 "
                    dangerouslySetInnerHTML={{
                      __html: showContent(3, "description"),
                    }}
                  />
                </div>
              </Slide>
            </div>
            <div className="flex mob-none">
              <img
                loading="lazy"
                src={hp_connector_line}
                ref={this.image}
                onLoad={this.handleImageLoaded}
                className="flex auto wd-696"
              />
            </div>
            <div className="flex mob-none space-between wd-930 mt--38 mb-14">
              <div className="bg-12 p-424 br-26">
                <p className="c-ff s-20 Kanit w-600">
                  {showContent(1, "subtitle")}{" "}
                </p>
              </div>
              <div className="bg-12 p-424 br-26">
                <p className="c-ff s-20 Kanit w-600">
                  {showContent(2, "subtitle")}
                </p>
              </div>
              <div className="bg-12 p-424 br-26">
                <p className="c-ff s-20 Kanit w-600">
                  {showContent(3, "subtitle")}
                </p>
              </div>
            </div>
            <Slide bottom duration={1000} cascade>
              <div className="flex mob-none space-between wd-1040 mb-100 ">
                {/* <Slide bottom duration={1000}> */}
                <div className="flex wd-228">
                  <p
                    className="Kanit-300 s-1828 c-28 "
                    dangerouslySetInnerHTML={{
                      __html: showContent(1, "description"),
                    }}
                  />
                </div>
                {/* </Slide>
                            <Slide bottom duration={1000} delay={200}> */}
                <div className="flex wd-228">
                  <p
                    className="Kanit-300 s-1828 c-28 "
                    dangerouslySetInnerHTML={{
                      __html: showContent(2, "description"),
                    }}
                  />
                </div>
                {/* </Slide>
                            <Slide bottom duration={1000} delay={300}> */}
                <div className="flex wd-228">
                  <p
                    className="Kanit-300 s-1828 c-28 "
                    dangerouslySetInnerHTML={{
                      __html: showContent(3, "description"),
                    }}
                  />
                </div>
                {/* </Slide> */}
              </div>
            </Slide>
            <div className="flex width-100  border-dash" />
            <Slide bottom cascade duration={1500}>
              <div className="flex column wd-338 bg-ff mt--36 k_">
                <h2 className="Kanit-400 s-48 c-09">{"Explore"}</h2>
                <h2 className="s-48 Kanit w-600 c-d87 dekstop  mb-24">
                  {"Our Newest Job"}
                </h2>
              </div>
            </Slide>
            <p className="s-48 Kanit w-600 c-d87 mb-24 mobile">
              {"Our Newest Job"}
            </p>
            <Slide bottom duration={1000}>
              <p
                className="Kanit-300 s-1832 c-29 mb-48 wd-content"
                dangerouslySetInnerHTML={{
                  __html: showContent(4, "description"),
                }}
              />
            </Slide>
            <div className="none center  hiring ">
              <Slider
                {...settings2}
                className="flex center mb-100 wd-1150 newest"
              >
                {new_dataJob.map((item1, i) => {
                  return (
                    <div
                      key={i}
                      onClick={(e) =>
                        (window.location.href = `/JobDetail_Public/${
                          item1.job_opportunity_id
                        }/${item1.job_title.split(" ").join("-")}`)
                      }
                      className="wd-292 flexi column br-20 hg-472 bg-ff filter-10 mb-24 text-center pointer"
                    >
                      <img
                        loading="lazy"
                        ref={this.image}
                        onLoad={this.handleImageLoaded}
                        src={`${API}/File/Job_Function/${showJobFunction(
                          findIndexOfJob(item1.job_function) + 1
                        ).photo.replace('"', "")}`}
                        onError={(e) => {
                          e.target.src = About;
                        }}
                        className="flex br-20 wd-292 hg-292 cover mb-14"
                      />
                      <p className="c-29 Kanit-500 s-1828 mb2">
                        {item1.job_title}{" "}
                      </p>
                      <p className="c-12 Kanit-500 s-12 mb6 dec-underline">
                        {item1.company === null
                          ? item1.alias_text
                          : item1.company}{" "}
                      </p>
                      <p className="Kanit-400 s-1422 c-09 mb6">
                        IDR {priceSplitter(item1.salary_from * 1)} -{" "}
                        {priceSplitter(item1.salary_to * 1)}
                      </p>
                      <div className="flex mb-12">
                        <svg
                          className="mr2"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.48615 13.3074C2.70234 8.34976 2 7.84096 2 6.01895C2 3.5232 4.23857 1.5 7 1.5C9.76143 1.5 12 3.5232 12 6.01895C12 7.84096 11.2977 8.34976 7.51385 13.3074C7.26555 13.6316 6.73443 13.6316 6.48615 13.3074ZM7 7.90185C8.1506 7.90185 9.08333 7.05885 9.08333 6.01895C9.08333 4.97905 8.1506 4.13605 7 4.13605C5.8494 4.13605 4.91667 4.97905 4.91667 6.01895C4.91667 7.05885 5.8494 7.90185 7 7.90185Z"
                            fill="#3A4C5F"
                          />
                        </svg>
                        <p className="c-09 s-12 Kanit-400">{item1.city} </p>
                      </div>
                      <div
                        className="flex p-424 mb-24 br-26 bg-12  pointer"
                        onClick={(e) =>
                          (window.location.href = `/JobDetail_Public/${
                            item1.job_opportunity_id
                          }/${item1.job_title.split(" ").join("-")}`)
                        }
                      >
                        <p className="c-ff Kanit w-600 s-1422">VIEW JOB</p>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <img
                loading="lazy"
                src={yellow_dots}
                className="flex yel-1 dekstop"
              />
              <img
                loading="lazy"
                src={yellow_dots}
                className="flex yel-2 absolute dekstop"
              />
              <img
                loading="lazy"
                src={yellow_dots2}
                className="flex yel-1 mob"
              />
              <img
                loading="lazy"
                src={yellow_dots2}
                className="flex yel-2 absolute mob"
              />
            </div>
            <Slide bottom duration={1000} cascade>
              <div
                className="flex flex-start wrap wd-1264  mb-48 fD"
                id="container-u"
                style={{
                  transform:
                    parseInt(window.innerWidth) > 1024
                      ? wh > sh
                        ? ""
                        : `scale(${sc})`
                      : "",
                  transformOrigin:
                    parseInt(window.innerWidth) > 1024 ? `bottom` : "",
                  marginTop:
                    parseInt(window.innerWidth) > 1024
                      ? wh > sh
                        ? "0px"
                        : `${mt}px`
                      : "",
                }}
              >
                {new_dataJob.map((item1, i) => {
                  if (i <= 7) {
                    return (
                      <a
                        href={`/JobDetail_Public/${item1.job_opportunity_id}/${item1.job_title}`}
                        rel="noopener noreferrer"
                        className="wd-292 flex column br-20 hg-472 bg-ff filter-10 mb-24 text-center"
                      >
                        <img
                          loading="lazy"
                          ref={this.image}
                          onLoad={this.handleImageLoaded}
                          src={`${API}/File/Job_Function/${showJobFunction(
                            findIndexOfJob(item1.job_function) + 1
                          ).photo.replace('"', "")}`}
                          onError={(e) => {
                            e.target.src = About;
                          }}
                          className="flex br-20 wd-292 hg-292 cover mb-14"
                        />
                        <p className="c-29 Kanit-500 s-1828 mb2">
                          {item1.job_title}{" "}
                        </p>
                        <p className="c-12 Kanit-500 s-12 mb6 dec-underline">
                          {item1.company === null
                            ? item1.alias_text
                            : item1.company}{" "}
                        </p>
                        <p className="Kanit-400 s-1422 c-09 mb6">
                          IDR {priceSplitter(item1.salary_from * 1)} -{" "}
                          {priceSplitter(item1.salary_to * 1)}
                        </p>
                        <div className="flex mb-12">
                          <svg
                            className="mr2"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.48615 13.3074C2.70234 8.34976 2 7.84096 2 6.01895C2 3.5232 4.23857 1.5 7 1.5C9.76143 1.5 12 3.5232 12 6.01895C12 7.84096 11.2977 8.34976 7.51385 13.3074C7.26555 13.6316 6.73443 13.6316 6.48615 13.3074ZM7 7.90185C8.1506 7.90185 9.08333 7.05885 9.08333 6.01895C9.08333 4.97905 8.1506 4.13605 7 4.13605C5.8494 4.13605 4.91667 4.97905 4.91667 6.01895C4.91667 7.05885 5.8494 7.90185 7 7.90185Z"
                              fill="#3A4C5F"
                            />
                          </svg>
                          <p className="c-09 s-12 Kanit-400">{item1.city} </p>
                        </div>
                        <div
                          className="flex p-424 mb-24 br-26 bg-12  pointer"
                          onClick={(e) =>
                            (window.location.href = `/JobDetail_Public/${
                              item1.job_opportunity_id
                            }/${item1.job_title.split(" ").join("-")}`)
                          }
                        >
                          <p className="c-ff Kanit w-600 s-1422">VIEW JOB</p>
                        </div>
                      </a>
                    );
                  }
                })}
                <img loading="lazy" src={yellow_dots} className="flex yel-1" />
                <img
                  loading="lazy"
                  src={yellow_dots}
                  className="flex yel-2 absolute"
                />
              </div>
            </Slide>
            <div
              className="flex p-1424 br-26 bg-ff bd-67 l_ mb-100  pointer"
              onClick={(e) => this.searchJobOpportunity("")}
            >
              <p className="w-600 Kanit c-09 s-1422">VIEW ALL JOB</p>
            </div>
            <Slide bottom duration={1000}>
              <div className="flex-mob mb-48">
                <h2 className="s-48 c-d87 Kanit-400 mr-14">Explore by</h2>
                <h2 className="s-48 c-09 Kanit w-600">Job Function</h2>
              </div>
            </Slide>
            <Fade bottom duration={1500} cascade>
              <div className="flex center mb-100 wrap">
                {explore_job.map((item, i) => {
                  if (i < 5) {
                    return (
                      <div
                        className="flex wd-228 hg-228 center m-auto125-14 pointer br-20"
                        style={{ backgroundImage: `url(${images[i]})` }}
                        onClick={() =>
                          this.searchJobOpportunity(
                            item.id,
                            item.job_function_name.split(" ").join("-")
                          )
                        }
                      >
                        <p className="Kanit-500 s-24 c-ff wd-111 auto p-014">
                          {item.job_function_name}{" "}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            </Fade>
            <div
              className="flex width-100 hg-584 mb-100 slide-logo"
              style={{ backgroundImage: `url(${showImage(5)})` }}
            >
              <div className="flex-mob wd-content space-between auto">
                <Slide bottom duration={2000}>
                  <div className="flex column align-left">
                    {/* {animatedText(showContent(5,"subtitle"),"lights","h2","Kanit-400 s-3654 c-ff")}
                                    {animatedText(showContent(5,"category"),"lights","h2","Kanit-400 s-4466 c-ff")}
                                    {animatedText(showContent(5,"sub_category"),"lights","h2","Kanit w-600 s-5684 c-ff mb-48")} */}
                    {/* <Fade bottom duration={1500} cascade> */}
                    <h2 className="Kanit-400 s-3654 c-ff">
                      {showContent(5, "subtitle")}{" "}
                    </h2>
                    <h2 className="Kanit-400 s-4466 c-ff">
                      {showContent(5, "category")}{" "}
                    </h2>
                    <h2 className="Kanit w-600 s-5684 c-ff mb-48">
                      {showContent(5, "sub_category")}{" "}
                    </h2>
                    {/* </Fade> */}
                    {/* <p className='Kanit-400 s-3654 c-ff'>{showContent(5,"subtitle")} </p>
                                    <p className='Kanit-400 s-4466 c-ff'>{showContent(5,"category")} </p>
                                    <p className='Kanit w-600 s-5684 c-ff mb-48'>{showContent(5,"sub_category")} </p> */}
                    <div
                      className="bd-ff l_ p-1424 br-26 flex wd-fit-content pointer"
                      onClick={(e) => (window.location.href = "/Employer")}
                    >
                      <p className="c-ff Kanit w-600 s-1422">LEARN MORE</p>
                    </div>
                  </div>
                </Slide>
                <Slider
                  {...settingImage}
                  className="flex wd-150 wd-254 center auto-80"
                >
                  {data_banner.map((item, i) => {
                    if (item.title === "Carousel") {
                      return (
                        // <a href={item.sub_category}  rel="noopener noreferrer">
                        <img
                          loading="lazy"
                          ref={this.image}
                          onLoad={this.handleImageLoaded}
                          src={showBanner(i).img}
                          className="flex auto wd-254"
                        />
                        // </a>
                      );
                    }
                  })}
                </Slider>
              </div>
            </div>
            <div className="dekstop-tab flex column wd-content center">
              <h2 className="Kanit-400 s-48 c-d87">Explore our</h2>
              <h2 className="Kanit-500 w-500 s-48 c-09">
                Tech Bootcamp Programs
              </h2>
              <img
                src={hp_bootcamp}
                className="width-100 flex hg-fit-content mt-48 mb-124 contain pointer"
                onClick={(e) =>
                  (window.location.href = "/data-science-rockstar")
                }
              />
            </div>
            {/* <div className='wd-1240 flex br-20 bg-47-linear mb-100'> */}
            {/* </div> */}
            {/* <div className='flex width-100 hg-584 mb-100' style={{backgroundImage:`url(${showBanner(5).img!==""?showBanner(5).img:hp_bg_join})`}}> */}
            <Slider
              {...settingImage}
              className="flex column bann_3 center width-100"
            >
              {data_banner.map((item, i) => {
                if (item.title === "Carousel Banner 3") {
                  return (
                    <a href={item.sub_category} rel="noopener noreferrer">
                      {/* <img src={showBanner(i).img} className="flex width-100 hg-584 mb-100" /> */}
                      <img
                        loading="lazy"
                        ref={this.image}
                        onLoad={this.handleImageLoaded}
                        src={showBanner(i).img}
                        className="flex m_ width-100 hg-auto contain-i"
                      />
                    </a>
                    // <div className='flex width-100 hg-584 mb-100' style={{backgroundImage:`url(${showBanner(i).img!==""?showBanner(i).img:hp_bg_join})`}}></div>
                  );
                }
              })}
            </Slider>
            {/* </div> */}
            <div className="flex width-100 mt-142 border-dash" />
            <Slide bottom duration={1000}></Slide>
            <div className="flex width-100  border-dash" />
            <Fade bottom duration={1000} cascade>
              <div className="flex center column wd-338 bg-ff mt--36 mb-48 dekstop">
                {/* <h2 className='Kanit w-400 s-48 c-09 p-032'>{showContent(8,"subtitle")}</h2>
                            <h2 className='s-48 Kanit-400 c-d87 '>{showContent(8,"sub_category")}</h2> */}
                <div className="flex p-032">
                  <h2 className="Kanit-400 s-48 c-09 ">Make The Best </h2>
                  <h2 className="Kanit w-600 s-48 c-d87 ">
                    {" "}
                    &nbsp;Hiring Decision&nbsp;
                  </h2>
                  <h2 className="Kanit w-400 s-48 c-09 ">and</h2>
                </div>
                <div className="flex">
                  <h2 className="s-48 Kanit-400 c-09 ">Discover Your</h2>
                  <h2 className="s-48 Kanit w-600 c-09 ">
                    &nbsp;Career Journey
                  </h2>
                </div>
              </div>
            </Fade>
            <div className="flex wrap wd-338 bg-ff mt--36 mb-48 mobile sm-2">
              <h2 className="Kanit-400 s-48 c-09 ">Make The Best </h2>
              <h2 className="Kanit w-600 s-48 c-d87 ">
                {" "}
                &nbsp;Hiring Decision&nbsp;
              </h2>
              <h2 className="Kanit w-400 s-48 c-09 ">and&nbsp;</h2>
              <h2 className="s-48 Kanit-400 c-09 ">Discover Your</h2>
              <h2 className="s-48 Kanit w-600 c-09 ">&nbsp;Career Journey</h2>
            </div>
            <div className="flex center mb-150 col-tab">
              <Fade bottom left duration={1500}>
                <a
                  onClick={(e) => this.searchJobOpportunity("")}
                  className="flex column br-20 wd-596 mr-24"
                >
                  <img
                    loading="lazy"
                    ref={this.image}
                    onLoad={this.handleImageLoaded}
                    src={showImage(9)}
                    className="flex width-100 cover br-20_0 hg-335"
                  />
                  <div
                    className="flex width-100 br-0_20 pointer bg-09"
                    onClick={(e) => this.searchJobOpportunity("")}
                  >
                    <div className="flex m-24auto">
                      <p className="c-ff Kanit-500 s-24 mr-24">
                        {showContent(9, "subtitle")}
                      </p>
                      <svg
                        className="auto-00"
                        width="34"
                        height="16"
                        viewBox="0 0 34 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33.2071 8.70711C33.5976 8.31658 33.5976 7.68342 33.2071 7.29289L26.8431 0.928932C26.4526 0.538408 25.8195 0.538408 25.4289 0.928932C25.0384 1.31946 25.0384 1.95262 25.4289 2.34315L31.0858 8L25.4289 13.6569C25.0384 14.0474 25.0384 14.6805 25.4289 15.0711C25.8195 15.4616 26.4526 15.4616 26.8431 15.0711L33.2071 8.70711ZM0.5 9H32.5V7H0.5V9Z"
                          fill="#EDAF12"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </Fade>
              <Fade bottom right duration={1500}>
                <a href="/Employer" className="flex column br-20 wd-596 ml-24">
                  <img
                    loading="lazy"
                    src={showImage(10)}
                    className="flex width-100 cover br-20_0 hg-335"
                  />
                  <div
                    className="flex width-100 br-0_20 pointer bg-12"
                    onClick={(e) => (window.location.href = "/Employer")}
                  >
                    <div className="flex m-24auto">
                      <p className="c-ff Kanit-500 s-24 mr-24">
                        {showContent(10, "subtitle")}
                      </p>
                      <svg
                        className="auto-00"
                        width="34"
                        height="16"
                        viewBox="0 0 34 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33.2071 8.70711C33.5976 8.31658 33.5976 7.68342 33.2071 7.29289L26.8431 0.928932C26.4526 0.538408 25.8195 0.538408 25.4289 0.928932C25.0384 1.31946 25.0384 1.95262 25.4289 2.34315L31.0858 8L25.4289 13.6569C25.0384 14.0474 25.0384 14.6805 25.4289 15.0711C25.8195 15.4616 26.4526 15.4616 26.8431 15.0711L33.2071 8.70711ZM0.5 9H32.5V7H0.5V9Z"
                          fill="#091F37"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </Fade>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    jobFunction1: state.jobFunctionRed,
    jobByTitle1: state.jobByTitleRed,
    about1: state.allArticle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobFunction: () => {
      dispatch(getJobFunction());
    },
    getAllArticle: () => {
      dispatch(getAllArticle());
    },
    jobByTitle: (
      user_id,
      job_title,
      country_id,
      city_id,
      job_function_id,
      industry_id,
      job_level_id,
      salary_from,
      salary_to,
      job_type
    ) => {
      dispatch(
        jobByTitle(
          user_id,
          job_title,
          country_id,
          city_id,
          job_function_id,
          industry_id,
          job_level_id,
          salary_from,
          salary_to,
          job_type
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About_Acquisition);
