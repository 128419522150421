import React, { Component } from "react";
import { connect } from "react-redux";
import ire11 from "../images/ire11.png";
import download from "../images/icons/icons/download.svg";
import print from "../images/icons/icons/print.svg";
import share1 from "../images/icons/icons/share.svg";
import Nav from "../components/Nav";
import Loading from "../components/Loading";
import Footer from "../components/Footer";
import {share, getProgress} from "../_actions/user";
import Pdf from "react-to-pdf";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PDFExport } from "@progress/kendo-react-pdf";

class AssesmentReport extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      talents:true
    };
  }

  openModal(e) {
    if (e == 'link') {
        this.setState({
            link: true
        });
    }
}

closeModal() {
    this.setState({
        link: false,
    });
}

  componentDidMount() {
    this.props.share(this.props.user_id);
    this.props.getProgress(this.props.user_id);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    ReactGA.pageview(window.location.pathname + window.location.search);
    if ( window.location.pathname.toLowerCase().indexOf("interest_profiler2")!==-1){
      this.setState({talents:true})
    }else{
      this.setState({talents:false})
    }
  }

  download = () => {
    const link = document.createElement("a");
    link.href = `/Testradio.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
  };
  
  render() {
    const { dataLink, isLoading, error } = this.props.result1;
    const { progress } = this.props.progress1;
   const {talents} = this.state

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Mohon, refresh halaman ini!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    const options = {
        orientation: 'potrait',
        unit: 'in',
        format: [960,940]
    };
    const ref = React.createRef();
    const ref2 = React.createRef();

    return (
      <div ref={ref}>
        <div className="box-wrapper"  >
          <div className="wrapper" >
            {talents===false &&
            <Nav />}
            <div className="myAs"  >
            <div className="content" id="download" >
              <div className="page-container width-100 mt-80"  style={{marginTop:talents?"0px":""}}>
                <div className="page-container-outer" id="container-print" style={{width:talents?"100%":""}}>
                  <div className="box-logo width-100 logo-btn-justify" id="no-print">
                    <h1 style={{ color: "#3B6C98" }} id="no-print">Interest Profiler</h1>
                    {
                      talents &&
                        <div className=" report_Profiler" >
                            <div className="flex title_Profiler" style={{margin: 0}} >
                                <button 
                                  style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      backgroundColor: 'white'
                                  }} 
                                  onClick={this.exportPDFWithComponent} 
                                >
                                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={download}  />
                                    <p style={{color: '#3b6c98'}} className="ml-10">Download PDF</p>
                                </button>
                            </div>
                        </div>
                    }
                    <Link to="TableQuestion" style={{display:"none"}}>
                      <button
                        type="button"
                        className="btn btn-primary btn-50"
                        id="no-print"
                      >
                        Take New Assessment
                      </button>
                    </Link>
                  </div>
                  <PDFExport ref={component => (this.pdfExportComponent = component)}  fileName='My-assessment' margin={20} className="width-100"  style={{width:"100% !important"}}>
                  <div className="page-container-inner width-100" id="myAs-print">
                    <div className="box-edu-container width-100">
                      <div className="box-edu-content " style={{ width: "100%" }}>
                        <div className="box-assessment-title width-100">
                          <h4 className=" width-100">
                          My Work Interest Assessment Result
                          </h4>
                          <button style={{display:"none"}}
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={this.print}
                            id="no-print"
                          >
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={print}  />
                            Print
                          </button>
                          <Pdf targetRef={ref} filename="My-assessment.pdf" options={options} x={0} y={.5} >
                            {({ toPdf }) => (
                              <button style={{display:"none"}}
                              onClick={toPdf}
                              type="button"
                              className="btn btn-outline-secondary"
                              id="no-print"
                            >
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={download}  />
                              Download
                            </button>
                            )}
                          </Pdf>
                          {/* <Link
                            to="/MyAssessment.pdf"
                             
                            rel="noopener noreferrer"
                            download
                          > */}
                           
                          {/* </Link> */}
                          <button style={{display:"none"}}
                            type="button"
                            className="btn btn-outline-secondary"
                            id="no-print"
                            onClick={() => this.openModal('link')}
                          >
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={share1}  />
                            Share
                          </button>
                        </div>
                        <div className="box-horizontal-line width-100">
                          <div className="horizontal-line width-100"></div>
                        </div>
                        <div className="box-assessment-result width-100">
                          <div className="assessment-result-profile width-50">
                            {dataLink.map((item, i) => {
                              if (i === 0)
                                return (
                                  <h4 key={i} className=" width-100">
                                    {item.name}
                                  </h4>
                                );
                            })}

                            <div className="box-result-profile-label width-100">
                              {dataLink.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="result-profile-label conventional "
                                    style={{
                                      background: `${item.color}`,
                                      border: `1px solid ${item.color}`,
                                    }}
                                  >
                                    <p style={{ padding: 0, margin: 0 }}>
                                      {item.interest_name}{" "}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="assessment-result-grafik width-50" >
                            {progress.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="box-result-grafik width-100"
                                 
                                >
                                  <div className="result-grafik-label">
                                    <p id="name-print">{item.interest_name} </p>
                                  </div>
                                  <div className="result-grafik-value">
                                    <div className="progress">
                                      <div
                                        className="progress-bar bg-realistic"
                                        role="progressbar"
                                        style={{
                                          width: `${item.scoring * 4}%`,
                                          backgroundColor: `${item.color}`,
                                        }}
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        <div
                                          className="progress-value realistic"
                                          style={{
                                            backgroundColor: `${item.color}`,
                                            alignItems: 'center'
                                          }}
                                        >
                                          {item.scoring * 4}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="box-assessment-description width-100" id="box-assessment-print">
                          {dataLink.map((item, i) => {
                            return (
                              <div key={i} className="assessment-description">
                                <div className="assessment-description-inner width-100" id="box-description-inner">
                                  <div className="assessment-description-title width-100">
                                    <div className="box-description-title-img  width-100">
                                      <div
                                        className="description-title-img conventional"
                                        style={{
                                          backgroundColor: `${item.color}`,
                                          border: `1px solid ${item.color}`,
                                          alignItems: 'center'
                                        }}
                                      >
                                        <LazyLoadImage effect="blur"
                                          className=""
                                          src={ire11}
                                          style={{ height:"48px"}}
                                        />
                                      </div>
                                    </div>
                                    <h4
                                      className="conventional-h4 width-100"
                                      style={{ color: `${item.color}` }}
                                    >
                                      {item.interest_name}{" "}
                                    </h4>
                                    <h5
                                      className="conventional-h5 width-100"
                                      style={{ color: `${item.color}` }}
                                    >
                                      {item.interest_type}{" "}
                                    </h5>
                                  </div>
                                  <div className="assessment-description-content width-100" id="box-description-content">
                                    <p className=" width-100" id="p-print">
                                      {item.interest_description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        </div>
                      </div>
                  </div>
                  </PDFExport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {talents===false&&
        <div  id="no-print">
        <Footer/>
        </div>}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: ownProps.match.params.user_id,
    result1: state.shareRed,
    progress1: state.progressRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    share: (user_id) => {
      dispatch(share(user_id));
    },
    getProgress: (user_id) => {
      dispatch(getProgress(user_id));
    },
   
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssesmentReport));
