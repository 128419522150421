import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setProfile } from "../_actions/user";
import karya from "../images/logoBaru.png";
import checklist from "../images/icons/checklist.svg";
import job from "../images/icons/job.svg";
import edujob from "../images/icons/edu-job.svg";
import sd from "../images/icons/sd.svg";
import smp from "../images/icons/smp.svg";
import sma from "../images/icons/sma.svg";
import diploma from "../images/icons/diploma.svg";
import star from "../images/icons/star.svg";
import nav_top from "../images/icons/nav-top.svg";
import ExpandCollapse from "react-expand-collapse";
import Union from "../images/Union.png"
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


var $ = require("jquery");
class PageEdu extends Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      education_level_id: 0,
      position_id: 0,
      work_experience_id: 0,
    };
    this.goBack = this.goBack.bind(this); //for go back (kembali) button
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var id = decode1["user_id"];
    this.setState({ id: id });
    sessionStorage.setItem('setupTime', new Date().getTime())
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0)
  }

  profile = (id, eduValue) => {
    const token = sessionStorage.getItem("token");
    const education_level_id = { education_level_id: eduValue };
    this.setState({ education_level_id: education_level_id });
    this.props.setProfile1(token, id, education_level_id);
    this.setActiveBtn("edu-content-level", eduValue);
    sessionStorage.setItem('setupTime', new Date().getTime())
    GAevent('PageEdu','Submit Education Level') 
  };

  experience = (id, expValue) => {
    const token = sessionStorage.getItem("token");
    const work_experience_id = { work_experience_id: expValue };
    this.setState({ work_experience_id: work_experience_id });
    this.props.setProfile1(token, id, work_experience_id);
    this.setActiveBtn("edu-content-exp", expValue);
    sessionStorage.setItem('setupTime', new Date().getTime())
    GAevent('PageEdu','Submit Work Experience (year)') 
  };

  goBack() {
    this.props.history.goBack();
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  position = (id, positionValue) => {
    const token = sessionStorage.getItem("token");
    const position_id = { position_id: positionValue };
    this.setState({ position_id: position_id });
    this.props.setProfile1(token, id, position_id);
    this.setActiveBtn("edu-content-position", positionValue);
    sessionStorage.setItem('setupTime', new Date().getTime())
    GAevent('PageEdu','Submit Current Position') 
  };

  setActiveBtn = (group, value) => {
    let button = $(`div.${group} button[value=${value}]`);
    button.addClass("active").siblings().removeClass("active");
  };

 

  postComplete =()=>{
    sessionStorage.setItem('kuesionerconfirmed',true)
    localStorage.setItem('kuesionerconfirmed', true)
  }

  render() {
    const pos = this.state.position_id;
    const exp = this.state.work_experience_id;
    const edu = this.state.education_level_id;

     
    let btn
    if ((edu === 0) || (exp === 0) || (pos === 0)) {
      btn = <Link to="/AssesmentReport">
        <button type="button" className="btn btn-primary" disabled="disabled" style={{color:"gray", fontWeight:"bold", backgroundColor:"white", border:"1px solid gray"}} >
          See Result
        </button>
      </Link>
    }
    if (edu !== 0 && exp !== 0 && pos !== 0) {
      btn = <Link to="/AssesmentReport">
        <button type="button" className="btn btn-primary" onClick={this.postComplete}>
          See Result
        </button>
      </Link>
    }
   
   

    return (
      <div>
        <div className="edu">
        <div className="box-wrapper orange e-dC">
          <div className="wrapper">
            <div className="content">
              <div className="page-container width-100">
                <div className="background-top width-100"></div>

                <div className="page-container-outer">
                  <div className="box-logo width-100 dekstop">
                    {/* <Link to="/"> */}
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" src={karya} alt="" />
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="mobile-information width-100">
                  <div className="box-logo width-100">
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy"  src={karya} alt="" />
                    </div>
                  </div>
                  </div>
                  <div className="page-container-inner width-100">
                    <div className="box-edu-container width-100">
                      <div className="box-edu-description mobile expandmobile">
                        <ExpandCollapse
                          previewHeight="126px"
                          expandText="wew"
                          ellipsisText=""
                        >
                          <div className="edu-desc-title width-100">
                            <div className="box-img-inner active">
                              <div className="box-img done">
                                <LazyLoadImage effect="blur" loading="lazy" src={checklist} alt="" className="search-icon"/>
                              </div>
                            </div>
                            <div className="box-img-inner">
                              <div className="box-img">
                                <LazyLoadImage effect="blur" loading="lazy" src={Union} alt=""  className="edu-icon" />
                              </div>
                            </div>
                            <div className="box-img-inner">
                              <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                                <LazyLoadImage effect="blur" loading="lazy" src={job} alt="" className="job-icon" />
                              </div>
                            </div>
                          </div>
                          <div className="edu-desc-content width-100">
                            <div className="box-image">
                              <div className="background-img"></div>
                              <LazyLoadImage effect="blur" loading="lazy" src={edujob} alt="" />
                            </div>

                            <h5>Education and Work Experience </h5>
                            <p>
                            Please select your latest education, your total work experience and your current position
                            </p>
                          </div>

                          <div className="edu-desc-footer">
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={this.goBack}
                              style={{display:"none"}}
                            >
                              Back
                            </button>
                            <Link to="/AssesmentReport">
                              <button type="button" className="btn btn-primary">
                                See Result
                              </button>
                            </Link>
                          </div>

                          <div className="edu-desc-nav width-100">
                            <div className="edu-desc-nav-img">
                              <LazyLoadImage effect="blur" loading="lazy" src={nav_top} alt="" />
                            </div>
                          </div>
                        </ExpandCollapse>
                      </div>
                      <div className="box-edu-description questions-desktop">
                        <div className="edu-desc-title width-100">
                          <div className="box-img-inner active">
                            <div className="box-img done">
                              <LazyLoadImage effect="blur" loading="lazy" src={checklist} alt="" className="search-icon"/>
                            </div>
                          </div>
                          <div className="box-img-inner">
                            <div className="box-img">
                              <LazyLoadImage effect="blur" loading="lazy" src={Union} alt=""  className="edu-icon" />
                            </div>
                          </div>
                          <div className="box-img-inner">
                            <div className="box-img" style={{border: "1px solid #E0E0E0"}}>
                              <LazyLoadImage effect="blur" loading="lazy" src={job} alt=""  className="job-icon"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="edu-desc-content width-100">
                          <div className="box-image">
                            <div className="background-img"></div>
                            <LazyLoadImage effect="blur" loading="lazy" src={edujob} alt="" />
                          </div>

                          <h5>Education and Work Experience </h5>
                          <p>
                          Please select your latest education, your total work experience and your current position
                          </p>
                        </div>

                        <div className="edu-desc-footer">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={this.goBack}
                            style={{display:"none"}}
                          >
                            Back
                          </button>
                          {btn}
                        </div>

                        <div className="edu-desc-nav width-100">
                          <div className="edu-desc-nav-img">
                            <LazyLoadImage effect="blur" loading="lazy" src={nav_top} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="box-edu-content">
                        <div className="edu-content-level width-100" style={{textAlign:"left"}}>
                          <h4 className=" width-100">Education Level</h4>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={1}
                            onClick={() => {
                              this.profile(this.state.id, 1, "aducation_id");
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy" src={sd} alt="" />
                            Elementary School
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={2}
                            onClick={() => {
                              this.profile(this.state.id, 2);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy"  src={smp} alt="" />
                            Middle School
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={3}
                            onClick={() => {
                              this.profile(this.state.id, 3);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy"  src={sma} alt="" />
                            High School
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={4}
                            onClick={() => {
                              this.profile(this.state.id, 4);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy" src={diploma} alt="" />
                            Diploma
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={5}
                            onClick={() => {
                              this.profile(this.state.id, 5);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy" src={diploma} alt="" />
                            Bachelor and Above
                          </button>
                        </div>
                        <div className="edu-content-exp width-100"  style={{textAlign:"left"}}>
                          <h4 className=" width-100">
                          Work Experience (year)
                          </h4>

                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={1}
                            onClick={() => {
                              this.experience(this.state.id, 1);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={star} />
                            0-2 yrs
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={2}
                            onClick={() => {
                              this.experience(this.state.id, 2);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy" src={star} alt="" />
                            3-5 yrs
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={3}
                            onClick={() => {
                              this.experience(this.state.id, 3);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy"  src={star} alt="" />
                            6-8 yrs
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={4}
                            onClick={() => {
                              this.experience(this.state.id, 4);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy" src={star} alt="" />
                            9-11 yrs
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={5}
                            onClick={() => {
                              this.experience(this.state.id, 5);
                            }}
                          >
                            <LazyLoadImage effect="blur" loading="lazy"  src={star} alt="" />
                            {`>`} 11 yrs
                          </button>
                        
                        </div>
                        <div className="edu-content-position width-100"  style={{textAlign:"left"}}>
                          <h4 className=" width-100">Current Position</h4>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={1}
                            onClick={() => {
                              this.position(this.state.id, 1);
                            }}
                          >
                            No Experience
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={2}
                            onClick={() => {
                              this.position(this.state.id, 2);
                            }}
                          >
                            Internship
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={3}
                            onClick={() => {
                              this.position(this.state.id, 3);
                            }}
                          >
                            Staff
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={4}
                            onClick={() => {
                              this.position(this.state.id, 4);
                            }}
                          >
                            Supervisor
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={5}
                            onClick={() => {
                              this.position(this.state.id, 5);
                            }}
                          >
                            Asst. Manager
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            value={6}
                            onClick={() => {
                              this.position(this.state.id, 6);
                            }}
                          >
                            Manager or Above
                          </button>
                        </div>
                        <div className="edu-desc-footer width-100">
                          {/* <Link to="AssesmentReport">
                            <button type="button" className="btn btn-primary">
                              Continue
                            </button>
                          </Link> */}
                          {btn}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: state.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfile1: (
      token,
      id,
      education_level_id,
      work_experience_id,
      position_id
    ) => {
      dispatch(
        setProfile(
          token,
          id,
          education_level_id,
          work_experience_id,
          position_id
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEdu);
