import React, { Component } from 'react';
import logo from "../images/KaryaTalents_logo.svg"
import { connect } from "react-redux";
import {API} from '../_redux/helper'
import axios from "axios";
import Modal from "react-awesome-modal";
import apply_success from "../images/apply_success.svg"
import warning_referral from "../images/warning_referral.svg"
import { getKnowProgram} from "../_actions/jobs";
import {getJobFunction,getJobLevel,getWorkId} from "../_actions/work";
import { getProfileId} from "../_actions/user";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';

class Registration_Form extends Component {
    constructor(){
        super()
        this.state={
            user_id: "",
            name:"",
            email:"",
            phone_number:"",
            address:"",
            work_exp_id:0,
            job_function_id:"",
            job_level_id:"",
            current_company:"",
            question_join:"",
            question_know:"",
            summary:"",
            question_taken:"",
            question_placed:"",
            interested:"",
            visible:true,
            nameErr:"",
            success:false,
            failed:false,
            emailErr:"",
            phone_numberErr:"",
            addressErr:"",
            work_exp_idErr:"",
            job_function_idErr:"",
            job_level_idErr:"",
            current_companyErr:"",
            question_joinErr:"",
            question_knowErr:"",
            summaryErr:"",
            question_takenErr:"",
            question_placedErr:"",
            interestedErr:"",
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        ReactPixel.track(window.location.pathname);
        this.props.getJobFunction();
        this.props.getJobLevel();
        this.props.getKnowProgram();
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        let userId
        if (sessionStorage.getItem("token")!==null  ){
            var decode1 = jwt.decode(token1);
            var user_id = decode1["user_id"];
                userId=user_id
        }else{
            userId=""
        }
        this.setState({ user_id: userId });
        this.props.getWorkId(user_id);
        this.props.getProfileId(user_id);
        
    }


    closePopup=()=> {
        // this.setState({ success:false});
        this.props.history.goBack();
      }
    
      closeFail=()=> {
        this.setState({failed: false});
      }
    
   

    postData=()=>{
        const { profile } = this.props.profile1;
        const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        let f_name
        let f_email
        let f_phone
        let f_address
        let f_work
        let f_job_function
        let f_job_level
        /* eslint eqeqeq: 0 */
        if ((this.state.name=="") && (profile.name !=="")){
            f_name= profile.name
        }else if (this.state.name==="" && profile.name ===""){
            f_name= this.state.name
        }else{
            f_name=this.state.name
        }


        if ((this.state.email=="") && (profile.email !=="")){
            f_email= profile.email
        }else if ((this.state.email=="") && (profile.email =="")){
            f_email= this.state.email
        }else{
            f_email=this.state.email
        }

        if ((this.state.phone_number=="") && (profile.phone_number !=="")){
            f_phone= profile.phone_number
        }else if ((this.state.phone_number=="") && (profile.phone_number ==="")){
            f_phone= this.state.phone_number
        }else{
            f_phone=this.state.phone_number
        }

        if ((this.state.address=="") && (profile.address !== "")){
            f_address= profile.address
        }else if ((this.state.address=="") && (profile.address == "")){
            f_address= this.state.address
        }else{
            f_address=this.state.address
        }

        if ((this.state.work_exp_id==0) && (profile.work_experience_id !== "")){
            f_work= profile.work_experience_id
        }else if ((this.state.work_exp_id==0) && (profile.work_experience_id == "")){
            f_work= this.state.work_exp_id
        }else{
            f_work=this.state.work_exp_id
        }

        if ((this.state.job_function_id=="") && (profile.job_function_id !== "")){
            f_job_function= profile.job_function_id
        }else if ((this.state.job_function_id=="") && (profile.job_function_id === "")){
            f_job_function= this.state.job_function_id
        }else{
            f_job_function=this.state.job_function_id
        }

        if ((this.state.job_level_id=="") || (profile.job_level_id !=="")){
            f_job_level= profile.job_level_id
        }else if ((this.state.job_level_id=="") || (profile.job_level_id ==="")){
            f_job_level= this.state.job_level_id
        }else{
            f_job_level=this.state.job_level_id
        }


        if((f_name==="" )|| (f_name==undefined) ){
            this.setState({nameErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({nameErr:""})
            this.setState({visible:true})
        }

        if((regEmail.test(f_email)==false) || ((f_email==undefined))){
            this.setState({emailErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({emailErr:""})
            this.setState({visible:true})
        }

        if((f_phone=="") || ((f_phone==undefined))){
            this.setState({phone_numberErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({phone_numberErr:""})
            this.setState({visible:true})
        }

        if((f_address=="") || ((f_address==undefined))){
            this.setState({addressErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({addressErr:""})
            this.setState({visible:true})
        }

        if((f_work==0) || ((f_work==undefined))){
            this.setState({work_exp_idErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({work_exp_idErr:""})
            this.setState({visible:true})
        }

        if(this.state.current_company==""){
            this.setState({current_companyErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({current_companyErr:""})
            this.setState({visible:true})
        }

        if((f_job_function=="") || ((f_job_function==undefined))){
            this.setState({job_function_idErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({job_function_idErr:""})
            this.setState({visible:true})
        }

        if((f_job_level=="") || (f_job_level==undefined)){
            this.setState({job_level_idErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({job_level_idErr:""})
            this.setState({visible:true})
        }

        if(this.state.question_know==""){
            this.setState({question_knowErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({question_knowErr:""})
            this.setState({visible:true})
        }

        if(this.state.summary=="" && this.state.question_know==3){
            this.setState({summaryErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({summaryErr:""})
            this.setState({visible:true})
        }

        if(this.state.question_taken==""){
            this.setState({question_takenErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({question_takenErr:""})
            this.setState({visible:true})
        }

        if(this.state.question_join==""){
            this.setState({question_joinErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({question_joinErr:""})
            this.setState({visible:true})
        }

        if(this.state.question_placed==""){
            this.setState({question_placedErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({question_placedErr:""})
            this.setState({visible:true})
        }

        if(this.state.interested==""){
            this.setState({interestedErr:"Please fill out this field"})
            this.setState({visible:false})
        }else{
            this.setState({interestedErr:""})
            this.setState({visible:true})
        }


        if (this.state.visible==false || this.state.interested=="" || this.state.question_join=="" || this.state.question_taken==""
            || this.state.question_know=="" || this.state.current_company=="" || f_address=="" || f_name=="" || f_phone==""
            || f_job_function=="" || f_job_level=="" || f_work==0 || (this.state.summary=="" && this.state.question_know==3)){
            window.location.href="#"
        }else{
   
        const data={
            name:f_name,
            email:f_email,
            phone_number:parseInt(f_phone),
            address:f_address,
            work_experience:f_work,
            last_salary:this.state.current_company,
            job_function_id:f_job_function,
            job_level_id:f_job_level,
            question_join:this.state.question_join,
            question_know:this.state.question_know,
            question_taken:this.state.question_taken,
            question_placed:this.state.question_placed,
            emailconfirmed:this.state.interested,
            program_id:sessionStorage.getItem("pr-1"),
        }
        axios.post(`${API}/api/dlp/create`, data).then((res)=>{
            this.setState({success:true})  
        }).catch((err)=>{
            console.log(err,"error");
            this.setState({failed:true})
        })
    }
    }

    changeName=(event)=>{
        const reg = /^(?![\s.]+$)[a-zA-Z\s.]*$/
        if (reg.test(event.target.value)==true){
            this.setState({name:event.target.value})
        }
    }
    changeEmail=(event)=>{
        this.setState({email:event.target.value})
    }
    changePhoneNumber=(event)=>{
        const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
        if (reg.test(event.target.value)===true){
        this.setState({phone_number:event.target.value})
        }
    }
    changeAddress=(event)=>{
        this.setState({address:event.target.value})
    }
    changeWork=(ev)=>{
        this.setState({work_exp_id:ev})
    }
    changeJobFunction=(event)=>{
        this.setState({job_function_id:event.target.value})
    }
    changeJobLevel=(event)=>{
        this.setState({job_level_id:event.target.value})
    }
    changeCompany=(event)=>{
        this.setState({current_company:event.target.value})
    }
    changeQuestionJoin=(event)=>{
        this.setState({question_join:event.target.value})
    }
    changeQuestionKow=(event)=>{
        this.setState({question_know:event.target.value})
    }
    changeSummary=(event)=>{
        this.setState({summary:event.target.value})
    }
    changeQuestionTaken=(event)=>{
        this.setState({question_taken:event.target.value})
    }
    changeQuestionPlaced=(event)=>{
        this.setState({question_placed:event.target.value})
    }
    changeInterested=(event)=>{
        this.setState({interested:event})
    }

    render() {
        const {dataJobLevel} = this.props.jobLevel1;
        const {dataJobFunction} = this.props.jobFunction1;
        const { profile } = this.props.profile1;
        const { dataKnow } = this.props.know1;
        const { workId, error, isLoading } = this.props.workId1;
   



        let n_date = []
        let n_job_level = []
        let n_job_function = []
        workId.map((item,i)=>{n_date.push(Date.parse(item.work_period_start))
                              n_job_level.push(item.job_level_id)
                              n_job_function.push(item.job_function_id)
        })
        const n_max = Math.max.apply(null, n_date)
        const n_idx = n_date.indexOf(n_max)


       
        
        
       
       
        let selected 
        if ((this.state.work_exp_id != 0  || this.state.work_exp_id != "") &&  (profile.work_experience_id !== "" || profile.work_experience_id !== undefined)){
            selected = this.state.work_exp_id
        } else if(this.state.work_exp_id ===0 &&  (profile.work_experience_id !== "" || profile.work_experience_id !== undefined)){
            selected = profile.work_experience_id
        }
        else{
            selected = this.state.work_exp_id
        }
        // const selected = selected1
        
        //UNTUK MENAMPILKAN LIST JOB_LEVEL_NAME
        const listJobLevel = dataJobLevel.map((item,i)=>{
            return( item.job_level_name)
        })
        //UNTUK MENAMPILKAN LIST JOB_FUNCTION_NAME
        const listJobFunction = dataJobFunction.map((item,i)=>{
        return( item.job_function_name)
    })

    let params_quest

    if (this.state.question_know==3){
        params_quest = <input type="text" className="width-100"  placeholder="Your Answer" onChange={this.changeSummary} />
    }else{
        params_quest = <input type="text" className="width-100" disabled={true} placeholder="Your Answer" onChange={this.changeSummary} />
    }



        return (
            <div>
                <div className="header-landing-page">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="logo" />
                </div>
                <div className="flex width-100 column center">
                    <div className="flex reg-container column" style={{marginTop:"104px"}}>
                        <div className="registration-program center flex width-100" >
                            <div className="flex column height-100 center">
                                <h1>Karya Talents Transformers Program</h1>
                                <h3 className="dekstop width-100">Registration Form</h3>
                                <div className="mobile width-100">
                                    <div className="flex width-100 space-between" style={{marginTop:"14px"}}>
                                        <div className="l-dash" style={{margin: "auto auto auto 0px"}} />
                                        <h3 >Registration Form</h3>
                                        <div className="l-dash" style={{margin : "auto 0px auto auto"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="page-container " >
                            <div className="page-container-outer"> */}
                                <div className="flex width-100 center reg-form">
                                    <div className="reg_inner_box flex column">
                                        <p className="rg-desc">
                                        Please fill this form prior to joining our program. Make sure that the email address you fill is the email address you use the most and still active. Keep checking on your as we send you important informations about this program.
                                        </p>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Full Name</p>
                                            <input type="text" className="width-100" defaultValue={profile.name} ref={this.input}  placeholder="Your name" onChange={this.changeName} />
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.nameErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Email</p>
                                            <input type="text" className="width-100" defaultValue={profile.email} ref={this.input} placeholder="Your email" onChange={this.changeEmail} />
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.emailErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Phone Number</p>
                                            <input type="text" className="width-100" placeholder="Your phone number" defaultValue={profile.phone_number} ref={this.input} onChange={this.changePhoneNumber} />
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.phone_numberErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Address</p>
                                            <input type="textarea" className="width-100" defaultValue={profile.address} ref={this.input} placeholder="Your address" onChange={this.changeAddress} />
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.addressErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Work Experience</p>
                                            <div className="dekstop width-100">
                                                <div className="width-100 space-between flex" style={{marginTop:"12px"}}>
                                                    <div className="flex box-radio" >
                                                        <input type="radio" id="male" name="D1" checked={selected==1} value="1" onChange={() => {this.changeWork(1)}}/>
                                                        <label for="m1" onClick={() => {this.changeWork(1)}}>0-2 Years</label>
                                                    </div>
                                                    <div className="flex box-radio">
                                                        <input type="radio" id="male" name="D2" checked={selected==2} value="2" onChange={() => {this.changeWork(2)}}/>
                                                        <label for="m2" onClick={() => {this.changeWork(2)}} value="2">3-5 Years</label>
                                                    </div>
                                                    <div className="flex box-radio" >
                                                        <input type="radio" id="male" name="D3" checked={selected==3} value="3" onClick={() => {this.changeWork(3)}}/>
                                                        <label for="m3" onClick={() => {this.changeWork(3)}}  value="3">6-8 Years</label>
                                                    </div>
                                                    <div className="flex box-radio" style={{marginRight:"0px"}}>
                                                        <input type="radio" id="male" name="D4" checked={selected==4} value="4" onClick={() => {this.changeWork(4)}}/>
                                                        <label for="m4" onClick={() => {this.changeWork(4)}}>9-11 Years</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dekstop width-100">
                                                <div className="width-100 flex" style={{marginTop:"12px"}}>
                                                    <div className="flex box-radio">
                                                        <input type="radio" id="male" name="D5" checked={selected==5}  value="5" onClick={() => {this.changeWork(5)}}/>
                                                        <label for="m5" onClick={() => {this.changeWork(5)}}> {`>`} 11 Years</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="big_mobile width-100">
                                                <div className="width-100  flex" style={{marginTop:"12px"}}>
                                                    <div className="flex box-radio" >
                                                        <input type="radio" id="male" name="BM-1" checked={selected==1} value="1" onClick={() => {this.changeWork(1)}}/>
                                                        <label for="m11" onClick={() => {this.changeWork(1)}}>0-2 Years</label>
                                                    </div>
                                                    <div className="flex box-radio">
                                                        <input type="radio" id="male" name="BM-2" checked={selected==2}  value="2" onClick={() => {this.changeWork(2)}}/>
                                                        <label for="m12" onClick={() => {this.changeWork(2)}}>3-5 Years</label>
                                                    </div>
                                                    <div className="flex box-radio" style={{marginRight:"0px"}}>
                                                        <input type="radio" id="male" name="BM-3" checked={selected==3}  value="3" onClick={() => {this.changeWork(3)}}/>
                                                        <label for="m13" onClick={() => {this.changeWork(3)}}>6-8 Years</label>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="big_mobile width-100">
                                                <div className="width-100 flex" style={{marginTop:"12px"}}>
                                                    <div className="flex box-radio" >
                                                        <input type="radio" id="male" name="BM-4" checked={selected==4} value="4" onClick={() => {this.changeWork(4)}}/>
                                                        <label for="m14" onClick={() => {this.changeWork(4)}}>9-11 Years</label>
                                                    </div>
                                                    <div className="flex box-radio">
                                                        <input type="radio" id="male" name="BM-5" checked={selected==5}  value="5" onClick={() => {this.changeWork(5)}}/>
                                                        <label for="m15" onClick={() => {this.changeWork(5)}}> {`>`} 11 Years</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="small_mobile width-100">
                                                <div className="flex width-100 column">
                                                    <div className="flex width-100" style={{marginTop:"12px"}}>
                                                        <div className="flex box-radio" >
                                                            <input type="radio" id="male"  name="SM-1" checked={selected==1} value="1" onClick={() => {this.changeWork(1)}}/>
                                                            <label for="m21" onClick={() => {this.changeWork(1)}}>0-2 Years</label>
                                                        </div>
                                                        <div className="flex box-radio">
                                                            <input type="radio" id="male" name="SM-2" checked={selected==2} value="2" onClick={() => {this.changeWork(2)}}/>
                                                            <label for="m22" onClick={() => {this.changeWork(2)}}>3-5 Years</label>
                                                        </div>
                                                    </div>
                                                    <div className="flex width-100" style={{margin:"12px 0px"}}>
                                                        <div className="flex box-radio" >
                                                            <input type="radio" id="male" name="SM-3" checked={selected==3} value="3" onClick={() => {this.changeWork(3)}}/>
                                                            <label for="m23" onClick={() => {this.changeWork(3)}}>6-8 Years</label>
                                                        </div>
                                                        <div className="flex box-radio" style={{marginRight:"0px"}}>
                                                            <input type="radio" id="male" name="SM-4" checked={selected==4} value="4" onClick={() => {this.changeWork(4)}}/>
                                                            <label for="m24" onClick={() => {this.changeWork(4)}}>9-11 Years</label>
                                                        </div>
                                                    </div>
                                                    <div className="flex width-100">
                                                        <div className="flex box-radio">
                                                            <input type="radio" id="male"name="SM-5" checked={selected==4} value="4" onClick={() => {this.changeWork(5)}}/>
                                                            <label for="m25" onClick={() => {this.changeWork(5)}}> {`>`} 11 Years</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <span style={{color: "red", fontSize:"12px", width:"100%", textAlign:"left"}}>{this.state.work_exp_idErr}</span>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Job Function</p>
                                            <select className="width-100" onChange={this.changeJobFunction} >
                                                <option disabled selected style={{display:"none"}}>{listJobFunction[(n_job_function[n_idx])-1]}</option>
                                                {dataJobFunction.map((item,i)=>(
                                                            <option key={i} value={item.id}>{item.job_function_name}</option>
                                                        ))} 
                                            </select>
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.job_function_idErr}</span>
                                            {/* <input type="text" className="width-100" placeholder="Your phone number" /> */}
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Job Level</p>
                                            <select className="width-100" onChange={this.changeJobLevel}>
                                                <option disabled selected style={{display:"none"}}>{listJobLevel[(n_job_level[n_idx])-1]}</option>
                                                {dataJobLevel.map((item,i)=>(
                                                            <option key={i} value={item.id}>{item.job_level_name}</option>
                                                        ))} 
                                            </select>
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.job_level_idErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Current/Latest Company</p>
                                            <input type="text" className="width-100" placeholder="Your Answer" onChange={this.changeCompany} />
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.current_companyErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Why do you want to join this program?</p>
                                            <input type="textarea" className="width-100" placeholder="Your Answer" onChange={this.changeQuestionJoin} />
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.question_joinErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>How do you know this program</p>
                                            {/* <input type="text" className="width-100" placeholder="Your phone number" onChange={this.changeQuestionKow} /> */}
                                            <select className="width-100" onChange={this.changeQuestionKow}>
                                                <option disabled selected style={{display:"none"}}>(choose)</option>
                                                {dataKnow.map((item,i)=>(
                                                            <option key={i} value={item.id}>{item.title}</option>
                                                        ))} 
                                            </select>
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.question_knowErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>If others, please specify</p>
                                            {params_quest}
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.summaryErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Have you taken online course before? If yes, where?</p>
                                            <input type="text" className="width-100" placeholder="Your Answer" onChange={this.changeQuestionTaken} />
                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.question_takenErr}</span>
                                        </div>
                                        <div className="flex width-100 column reg-textfield">
                                            <p>Are you interested to be placed by KaryaTalents after graduating from this program?</p>
                                               <div className="width-100 dekstop">
                                                    <div className="flex width-100" style={{marginTop:"13px"}}>
                                                        <div className="flex box-radio" >
                                                            <input type="radio" id="male" name="gender" value="male" onClick={()=>{this.changeInterested(1)}} />
                                                            <label for="male" style={{width:"147px"}}>Yes, I am Interested</label>
                                                        </div>
                                                        <div className="flex box-radio">
                                                            <input type="radio" id="male" name="gender" value="male" onClick={()=>{this.changeInterested(2)}}/>
                                                            <label for="male">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="width-100 big_mobile">
                                                    <div className="flex width-100" style={{marginTop:"13px"}}>
                                                        <div className="flex box-radio" >
                                                            <input type="radio" id="male" name="gender" value="male" onClick={()=>{this.changeInterested(1)}} />
                                                            <label for="male" style={{width:"147px"}}>Yes, I am Interested</label>
                                                        </div>
                                                        <div className="flex box-radio">
                                                            <input type="radio" id="male" name="gender" value="male" onClick={()=>{this.changeInterested(2)}}/>
                                                            <label for="male">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="width-100 small_mobile">
                                                    <div className="flex space-between  width-100 rd-reg">
                                                        <div className="flex box-radio r-fs" >
                                                            <input type="radio" id="male" name="gender" value="male" onClick={()=>{this.changeInterested(1)}} />
                                                            <label for="male" style={{width:"147px"}}>Yes, I am Interested</label>
                                                        </div>
                                                        <div className="flex box-radio" style={{marginRight:"0px"}}>
                                                            <input type="radio" id="male" name="gender" value="male" onClick={()=>{this.changeInterested(2)}}/>
                                                            <label for="male">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                           <span style={{color: "red", fontSize:"12px"}}>{this.state.interestedErr}</span>
                                        </div>
                                        <button className="reg-submit width-100 flex center" onClick={this.postData}>
                                            <p>Submit</p>
                                        </button>
                                        <div className="flex center width-100">
                                            <button className="reg-cancel width-100 flex center" onClick={()=>{this.props.history.goBack()}} >
                                                <p>Cancel Registration</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            {/* </div>
                        </div> */}
                    </div>
                </div>
                <Modal id="failed" visible={this.state.failed} effect="fadeInUp">
                    <div  className="modal_apply">
                        <div className="width-100 flex center">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={warning_referral} style={{marginTop:"24px", marginBottom:"24px"}}/>
                        </div>
                        <h5>Oops!</h5>
                        <p style={{width:"90%", marginLeft:"5%"}}>Email is already registered Please register with a new email!</p>
                        <div className="width-100 flex center" style={{marginBottom:"20px"}}>
                            <button onClick={this.closeFail}>
                                <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal id="success" visible={this.state.success} effect="fadeInUp">
                <div  className="modal_apply">
                    <div className="width-100 flex center">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={apply_success} style={{marginTop:"24px", marginBottom:"24px"}}/>
                    </div>
                    <h5>Registration Succsessful!</h5>
                    <p  style={{width:"90%", marginLeft:"5%"}}>Registration Succsessful Please check your registered email for email confirmation!</p>
                    <div className="width-100 flex center">
                        <button onClick={()=>{ this.props.history.goBack()}} style={{marginBottom:"20px"}}>
                            <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                        </button>
                    </div>
                </div>
            </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      jobLevel1 : state.jobLevelRed,
      jobFunction1 : state.jobFunctionRed,
      user_id: state.user_id,
      profile1: state.profileId,
      know1: state.knowProgramRed,
      workId1: state.getWorkIdRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getJobFunction: () =>{
        dispatch(getJobFunction())
      },
      getJobLevel: () =>{
        dispatch(getJobLevel())
      },
      getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
      getKnowProgram: () =>{
        dispatch(getKnowProgram())
      },
      getWorkId: (user_id) => {
        dispatch(getWorkId(user_id));
      },
      
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Registration_Form);