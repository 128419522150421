import React, { Component } from "react";
import { connect } from "react-redux";
import { getResult} from "../_actions/quest";
import { putProfileId} from "../_actions/user";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from '@material-ui/lab/Rating';
import { BrowserRouter as Router, Switch, Route, Link , NavLink} from "react-router-dom";
import Modal from "react-awesome-modal";
import ScrollToTop from "../components/ScrollToTop";
import DlcCourseInfo from "../components/DlcCourseInfo"
import DlcCourseOutline from "../components/DlcCourseOutline"
import DlcCourseQuiz from "../components/DlcCourseQuiz"
import dlc_close from "../images/dlc_close.svg"
import ReactGA from "react-ga";
import { API } from "../_redux/helper";
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import $ from "jquery";
import {GAevent} from './TrackingEvent'
import ReactPixel from 'react-facebook-pixel';

// material ui

// icon

class _DigitalLearningCenter extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      showStore: false,
      sizeStore: false,
      next_job:"",
      visible: true,
      tooltip:"",
      statusTab:"",
      popup:false,
      ratting:0,
      desc_ratting:"",
      rate:0,
      comment:"",
      show_modal: JSON.parse(sessionStorage.getItem("rating-program-m")),
      redirectLink: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.changeStatusTab(window.location.pathname)
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    var tooltip_val = sessionStorage.getItem("tooltip");
    this.setState({ tooltip: tooltip_val });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  openModal(e) {
     /* eslint eqeqeq: 0 */
    if (e == "link") {
      this.setState({
        link: true,
      });
    }
  }

  closeModal() {
    this.setState({
      link: false,
    });
  }

  closePopup() {
    this.setState({
      popup: false,
    });
  }

  changeStatusTab = (status) => {
    this.setState({
      statusTab : status
    })
  }

 // POPUP RATING START 
 changeRatting=(e)=>{
  this.setState({ratting:Number((e.target.textContent).split('')[0])})
  let val
  if ((e.target.textContent).split('')[0]==1){
    val = "Very Bad"
  }else if ((e.target.textContent).split('')[0]==2){
    val = "Bad"
  }else if ((e.target.textContent).split('')[0]==3){
    val = "Need Improvement"
  }else if ((e.target.textContent).split('')[0]==4){
    val = "Good"
  }else{
    val = "Very Good"
  }

  this.setState({desc_ratting:val})
  this.state.rate = this.state.ratting
  this.state.desc = this.state.desc_ratting
}

changeComment=(e)=>{
  this.setState({comment:e.currentTarget.textContent})
}

onChangeRedirect = () => {
  let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
    obj[key] = sessionStorage.getItem(key);
    return obj
  }, {});
  
  const allowed = ['rating-interest', 'rating-personality', 'rating-program-m', 'rating-values', 'rating-vip'];
  let finalObj = {}

  allowed.map(x => {
    if(x in raw) {
      finalObj[x] = raw[x]
    }
  })
  let id_program
  if (sessionStorage.getItem("i-program")==""){
    id_program=1
  } else {
    id_program = sessionStorage.getItem("i-program")
  }
  let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
  let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
  let arr = [{ ratting_interest: "", comment_interest: ""}, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting: "", comment: "" }, { ratting_vip: "", comment_vip: "" }]
  if(getKeyTrue) {
    let checkIndex = allowed.indexOf(getKeyTrue)
    this.setState({ 
      show_modal: "true",
      urlPopup: getAllUrl[checkIndex],
      dataPopup: arr[checkIndex],
      keyPopup: getKeyTrue
    })
    
  } 
  // else {
  //   window.location.href = `/${this.state.redirectLink}`
  // }
  
}
submitRatingAll = () => {
  this.state.dataPopup[Object.keys(this.state.dataPopup)[0]] = this.state.rate
  this.state.dataPopup[Object.keys(this.state.dataPopup)[1]] = this.state.comment

  axios.post(this.state.urlPopup, this.state.dataPopup)
    .then((res)=>{
        this.setState({show_modal:false})
        sessionStorage.setItem(this.state.keyPopup,false)
        sessionStorage.removeItem(this.state.keyPopup)
        window.location.reload()
        GAevent(`Submit Rating - ${window.location.pathname}`,'Submit Rating Digital Learning Center') 
        ReactPixel.track(`Submit Rating - ${window.location.pathname}`,'Submit Rating Digital Learning Center'); 
    }).catch((err)=>{
        console.log(err,"error");
    })
}

_onKeyDown=(e)=>{
  if (e.key === 'Enter') {
    this.submitRatingAll()
  }
}

closeRatingAll = () => {
  let raw = Object.keys(sessionStorage).reduce(function(obj, key) {
    obj[key] = sessionStorage.getItem(key);
    return obj
  }, {});
  
  const allowed = ['rating-interest', 'rating-personality', 'rating-program-m', 'rating-values', 'rating-vip'];
  let finalObj = {}

  allowed.map(x => {
    if(x in raw) {
      finalObj[x] = raw[x]
    }
  })
  let id_program
  if (sessionStorage.getItem("i-program")==""){
    id_program=1
  } else {
    id_program = sessionStorage.getItem("i-program")
  }
  let getAllUrl = [`${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, `${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`, `${API}/api/user/updateUserRatting/${this.state.user_id}`]
  let getKeyTrue = Object.keys(finalObj).find(key => finalObj[key] === "true")
  let arr = [{ ratting_interest: "-", comment_interest: ""}, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting: "-", comment: "" }, { ratting_vip: "-", comment_vip: "" }]
  let data_rating = []
  if(getKeyTrue) {
    let checkIndex = allowed.indexOf(getKeyTrue)
    this.setState({ 
      show_modal: "true",
      urlPopup: getAllUrl[checkIndex],
      dataPopup: arr[checkIndex],
      keyPopup: getKeyTrue
    })
    data_rating.push(arr[checkIndex])
  }  

  axios.post(this.state.urlPopup,data_rating[0])
    .then((res)=>{
        this.setState({show_modal:false})
        sessionStorage.setItem(this.state.keyPopup,false)
        sessionStorage.removeItem(this.state.keyPopup)
        window.location.reload()
        GAevent(`Close Rating - ${window.location.pathname}`,'Close Rating Digital Learning Center') 
        ReactPixel.track(`Close Rating - ${window.location.pathname}`,'Close Rating Digital Learning Center'); 
    }).catch((err)=>{
        console.log(err,"error");
    })

}


// RATING PROGRAM 

  setMyCourse = () =>{
    localStorage.setItem("getMyCOurse", "My Courses");
  }

 

  render() {
 
    const  profile = this.props.profile;
    const { ownProps } = this.props.ownProps
  
    $('.slick-list').css('width','auto');


    return (
      <div>
        <div className="box-wrapper new_Dashboard">
          {/* DEKSTOP */}
          <div className="wrapper dekstop">
            <div className="myAs new_Home" id="space0">
              <div className="content" id="download">
                <div className="page-container scrollContent width-100" id="space0">
                  <div className="page-container-outer" id="container-print" style={{position:"absolute", marginTop:"79px", width:"90%"}}>
                    <Link to="/DigitalLearningPlatform" onClick={this.setMyCourse()}>
                      <div className="box-logo width-100 logo-btn-justify btn-my-course">
                          <p>❮ My Courses</p>
                      </div>
                    </Link>
                    <div
                      className="box-training-recomended-container width-100"
                      style={{ paddingTop: "0px", marginBottom: "50px" }}
                    >
                      <div className="box-dlc-content training-recomended-container-inner width-100 dc">
                        <div className="pad-dlc">
                        <div className="tabs-info-container">
                          <div className="tabs-info">
                            <NavLink exact to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseInfo"} onClick={e=>{this.changeStatusTab(`/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseInfo`)}}>
                              <p>Course Info</p>
                            </NavLink>
                            <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"} onClick={e=>{this.changeStatusTab(`/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseOutline`)}}>
                              <p>Course Outline</p>
                            </NavLink>
                            <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/Quiz"} onClick={e=>{this.changeStatusTab(`/DigitalLearningCenter/${ownProps.match.params.program_id}/Quiz`)}}>
                              <p>Quiz</p>
                            </NavLink>
                          </div>
                        </div>
                        <p className="border-bottom-tabs"></p>
                        {
                          this.state.statusTab === `/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseInfo` ?
                            <DlcCourseInfo ownProps={ownProps}/>
                            :
                          this.state.statusTab === `/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseOutline` ?
                            <DlcCourseOutline  ownProps={ownProps} profileId={profile}/>
                            :
                          this.state.statusTab === `/DigitalLearningCenter/${ownProps.match.params.program_id}/Quiz` ?
                            <DlcCourseQuiz ownProps={ownProps}/> :""
                        }
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END DEKSTOP */}
          <div className="wrapper mobile">
            <div className="myAs new_Home" id="space0">
              <div className="content" id="download">
                <div className="box-dlc-content box-menu-mobile-dlp width-100">
                  <div className="tabs-info-container">
                    <div className="tabs-info">
                      <NavLink exact to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseInfo"} onClick={e=>{this.changeStatusTab(`/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseInfo`)}}>
                        <p>Course Info</p>
                      </NavLink>
                      <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"} onClick={e=>{this.changeStatusTab(`/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseOutline`)}}>
                        <p>Course Outline</p>
                      </NavLink>
                      <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/Quiz"} onClick={e=>{this.changeStatusTab(`/DigitalLearningCenter/${ownProps.match.params.program_id}/Quiz`)}}>
                        <p>Quiz</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="page-container scrollContent width-100" id="space0">
                  <div className="page-container-outer" id="container-print" style={{position:"absolute", marginTop:"133px", width:"1190px !important"}}>
                    <Link to="/DigitalLearningPlatform">
                      <div className="box-logo width-100 logo-btn-justify btn-my-course-mobile">
                          <p>❮ My Courses</p>
                      </div>
                    </Link>
                    <div
                      className="box-training-recomended-container width-100"
                      style={{ paddingTop: "0px", marginBottom: "50px" }}
                    >
                      <div className="box-dlc-content training-recomended-container-inner width-100 dc">
                      <div className="pad-dlc">
                        {
                          this.state.statusTab === `/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseInfo` ?
                            <DlcCourseInfo ownProps={ownProps}/>
                            :
                          this.state.statusTab === `/DigitalLearningCenter/${ownProps.match.params.program_id}/CourseOutline` ?
                            <DlcCourseOutline  ownProps={ownProps} profileId={profile}/>
                            :
                          this.state.statusTab === `/DigitalLearningCenter/${ownProps.match.params.program_id}/Quiz` ?
                            <DlcCourseQuiz ownProps={ownProps}/> :""
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal  id="course-outline1" visible={this.state.show_modal} effect="fadeInUp" onClickAway={this.closeRatingAll}>
            <div className="popup_dlc  flex column" onClick={this.onChangeRedirect}>
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={this.closeRatingAll}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                 <h2 className="openSans">Rate this course Mobile</h2>
                 <Rating name="pristine" value={this.state.rate} onClick={this.changeRatting} onChange={this.changeRatting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)} />
                 <h3 className="openSans flex">{this.state.desc} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1) Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4) Good</p>
                   <p className="openSans">(5) Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this course</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                 </div>
                 <button onClick={this.submitRatingAll} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    ownProps: ownProps
    // result1: state.result,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    putProfile11: (user_id) =>{
        dispatch(putProfileId(user_id))
    },
    getResult: (user_id) => {
      dispatch(getResult(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(_DigitalLearningCenter);
