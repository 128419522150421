import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import React, { Component, useEffect } from 'react';
import axios from 'axios';
import {API} from '../../_redux/helper'


function Signin_By_Google(props) {
  const clientId ="15553973332-h0ikku7as5d40ken4f6r5maktcbflc64.apps.googleusercontent.com"
  const clientSecret ="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"

  useEffect(() => {
  
    const initClient = () => {
      gapi.client.init({
        'apiKey': clientSecret,
        'clientId': clientId,
        'scope': '', 
    })
     };
     gapi.load('client:auth2', initClient);
 });
  const onSuccess = (res) => {
      const data = {gtoken:res.tokenId}
      props.postLogin(data,"google")
  };
  const onFailure = (err) => {
    console.log('failed:', err);
  };


  return (
    <div className='flex width-100'>
      {props.status ?
      <GoogleLogin className='flex width-100 c-29i center bd-eai br-20i hg-40 mb-14 pointer shd-none trans-3 eb_'
        clientId={clientId}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={false}
    />:
        <GoogleLogin className='flex width-100 c-29i center bd-eai br-20i hg-40 mb-14 pointer shd-none trans-3 eb_'
        clientId={clientId}
        buttonText="Sign up with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={false}
    />
      }
    </div>
  );
}


export default Signin_By_Google;