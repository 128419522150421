import { createStore, combineReducers, applyMiddleware } from 'redux';
import { sessionReducer, sessionService } from 'redux-react-session';
import {
  quest,
  answerQuest,
  result,
  allResult,
  checkQuestRed,
  questPID,
  submitPIDRed,
  resultPIDRed,
  questValuesRed,
  putValuesRed,
  reportValuesRed,
  limitValuesRed, submitPID_StandAloneRed,
  getQuestPID_StandAloneRed,
} from '../_reducers/quest';
import {
  delCertificationRed,
  postCertificationRed,
  getCertificationIdRed,
  getCertificationRed,
  putCertificationRed,
} from '../_reducers/certification';
import {
  getDocumentIdRed,
  getDocumentRed,
  postDocumentRed,
  putDocumentRed,
  delDocumentRed,
  documentPendukung,
} from '../_reducers/document';
import {
  getWorkIdRed,
  getWorkRed,
  putWorkRed,
  postWorkRed,
  delWorkRed,
  industriRed,
  jobLevelRed,
  jobFunctionRed,
  getWorkPreRed,
  getWorkStaRed
} from '../_reducers/work';
import {
  getLastIdRed,
  getLastRed,
  putLastRed,
  delLastRed,
  getLastListRed,
  postLastRed,
} from '../_reducers/last';
import {
  getExpectedIdRed,
  getExpectedRed,
  getExpectedListRed,
  putExpectedRed,
  getBenefitListRed,
  delExpectedRed,
  postExpectedRed,
} from '../_reducers/expected';
import {
  getEducationIdRed,
  getEducationRed,
  postEducationRed,
  putEducationRed,
  delEducationRed,
  listEducationRed,
} from '../_reducers/education';
import {
  job,
  jobDetail,
  training,
  jobExclude,
  pengetahuan,
  karakter,
  kemampuan,
  keterampilan,
  nilai,
  detailJob,
  JobOpportunity,
  AllCityRed,
  detailOpportunityRed,
  jobByTitleRed,
  JobOpportunityPublicRed,
  jobTypeRed,
  applyJobRed,
  jobDetailPublicRed,
  job2,
  getProgramRed,
  popular1Red,
  popular2Red,
  popular3Red,
  userCourseRed,
  userProgramRed,
  detailContentRed,
  detailProgramRed,
  courseByContentRed,
  courseDetailRed,
  dropdownRegRed,
  allProgramDLPRed,
  aboutDLPRed,
  knowProgramRed,
  getJobByInterest,
} from '../_reducers/job';
import {
  signUp,
  login,
  profile,
  update,
  profileId,
  updatePwd,
  changePwd,
  updateInfo,
  autoLogin,
  passwordEmail,
  shareRed,
  progressRed,
  progressProfileRed,
  verifyRed,
  cityRed,
  onlyCity,
  genderRed,
  provinceRed,
  countryRed,
  getSkillRed,
  postSkillRed,
  getProjectRed,
  onCityRed,
  postProject,
  editProjectRed,
  onStateRed,
  countryNewRed,
  postReferralRed,
  getReferralRed,
  getInvitationRed,
  putProfileIdRed,
} from '../_reducers/user';
import {
  article,
  articleDetail,
  faq,
  about,
  otherArticle,
  contact,
  message,
  allArticle,
  allPhoto,
  bannerRed,
  bannerByIdRed,
} from '../_reducers/article';
import { findEmailRed, userCourseDetailRed } from '../_reducers/dlp';
import { promise } from './middleware';

const rootReducers = combineReducers({
  quest,
  answerQuest,
  signUp,
  login,
  profile,
  update,
  result,
  putValuesRed,
  allResult,
  job,
  jobDetail,
  profileId,
  training,
  jobExclude,
  questValuesRed,
  article,
  articleDetail,
  faq,
  about,
  otherArticle,
  contact,
  bannerByIdRed,
  aboutDLPRed,
  message,
  updatePwd,
  changePwd,
  allArticle,
  pengetahuan,
  bannerRed,
  getExpectedListRed,
  getLastListRed,
  reportValuesRed,
  karakter,
  kemampuan,
  keterampilan,
  nilai,
  updateInfo,
  onlyCity,
  putProfileIdRed,
  limitValuesRed,
  autoLogin,
  passwordEmail,
  detailJob,
  getProjectRed,
  editProjectRed,
  allPhoto,
  applyJobRed,
  checkQuestRed,
  dropdownRegRed,
  submitPID_StandAloneRed,
  getCertificationIdRed,
  getCertificationRed,
  getDocumentRed,
  getDocumentIdRed,
  allProgramDLPRed,
  getEducationIdRed,
  getEducationRed,
  getLastIdRed,
  getLastRed,
  questPID,
  getBenefitListRed,
  userCourseRed,
  knowProgramRed,
  getQuestPID_StandAloneRed,
  getWorkIdRed,
  getWorkRed,
  getWorkPreRed,
  getWorkStaRed,
  getExpectedIdRed,
  getExpectedRed,
  submitPIDRed,
  userProgramRed,
  postCertificationRed,
  postDocumentRed,
  documentPendukung,
  job2,
  getSkillRed,
  postSkillRed,
  getProgramRed,
  courseByContentRed,
  postEducationRed,
  postExpectedRed,
  postLastRed,
  postWorkRed,
  resultPIDRed,
  detailContentRed,
  putCertificationRed,
  putDocumentRed,
  putEducationRed,
  putExpectedRed,
  detailProgramRed,
  putLastRed,
  putWorkRed,
  delCertificationRed,
  delDocumentRed,
  popular1Red,
  courseDetailRed,
  popular2Red,
  popular3Red,
  delExpectedRed,
  delLastRed,
  JobOpportunityPublicRed,
  jobTypeRed,
  delWorkRed,
  postProject,
  industriRed,
  jobLevelRed,
  jobFunctionRed,
  postReferralRed,
  jobDetailPublicRed,
  delEducationRed,
  listEducationRed,
  shareRed,
  progressRed,
  progressProfileRed,
  getInvitationRed,
  verifyRed,
  cityRed,
  countryRed,
  genderRed,
  provinceRed,
  onCityRed,
  onStateRed,
  countryNewRed,
  JobOpportunity,
  AllCityRed,
  detailOpportunityRed,
  jobByTitleRed,
  getReferralRed,
  sessionReducer,
  getJobByInterest,
  findEmailRed, userCourseDetailRed
});
const store = createStore(rootReducers, applyMiddleware(promise));

export default store;
