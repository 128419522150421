import React, { Component } from 'react';
import {putProfileId} from "../../_actions/user";
import iVIP from "../../images/iVIP.svg";
import iLearning from "../../images/iLearning.png";
import human from "../../images/person.svg"
import Fade from 'react-reveal/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";
import { connect } from "react-redux";
import bg_tooltip_bottom from "../../images/tooltip_bg_bottom.svg"
import tooltip_career from "../../images/tooltip_career.svg"
import tooltip_job from "../../images/tooltip_job.svg"
import tooltip_referral from "../../images/tooltip_referral.svg"


class Tooltip extends Component {
    constructor() {
        super();
        this.state = {
          user_id: "",
          tooltip_bottom :false,
          tooltip_top :false,
          height:"",
          tooltip:"",
        };
      }

      async componentDidMount() {
        var tooltip_val = sessionStorage.getItem("tooltip");
        this.setState({ tooltip: tooltip_val });
        this.setState({height:window.innerHeight})
        ReactPixel.track(window.location.pathname);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }

      componentDidUpdate(prevProps, prevState, snapshot){
        if (window.innerWidth < 1440 && (prevState.tooltip_bottom !== true)){
          this.setState({tooltip_bottom:true})
        }
        if (window.innerWidth > 1440 && (prevState.tooltip_top !== true)){
          this.setState({tooltip_top:true})
        }
      }

      changeTooltip=(x,y)=>{
        this.setState({[x]:y})
      }

      tooltip1 = () => {
        this.setState({ tooltip: 2 });
      };
      tooltip2 = () => {
        this.setState({ tooltip: 3 });
      };
      tooltip3 = () => {
        this.setState({ tooltip: 4 });
      };
      tooltip4 = () => {
        this.setState({ tooltip: 5 });
      };
      tooltip5 = () => {
        this.setState({ tooltip: 6 });
      };
      tooltip6 = () => {
        const data_tooltip = {
          user_id : this.state.user_id,
          is_tooltip : 1,
        }
        this.props.putProfile11(data_tooltip);
        sessionStorage.removeItem("tooltip");
        this.setState({ tooltip: "" });
      };
      tooltip7 = () => {
        this.setState({ tooltip: 8 });
      };
      tooltip8 = (e) => {
        const data_tooltip = {
          user_id : this.state.user_id,
          is_tooltip : 1,
        }
        this.props.putProfile11(data_tooltip);
        sessionStorage.removeItem("tooltip");
        this.setState({ tooltip: "" });
      };
    
    
      skip = () => {
        const data_tooltip = {
          user_id : this.state.user_id,
          is_tooltip : 1,
        }
        this.props.putProfile11(data_tooltip);
        sessionStorage.removeItem("tooltip");
        this.setState({ tooltip: "" });
      };


    render() {
        const { profile , user_id} = this.props
        const {tooltip} = this.state

        console.log("ini masuk tooltip", parseInt(tooltip));
        return (
            <div>
                {this.state.tooltip &&  <Fade cascade delay={1000}>
              <div className="bg_tooltip-dashboard tl-m1">
              <div className="tooltip_dashboard tooltip_myProfile"  style={{display: this.state.tooltip === 1 || this.state.tooltip==="1"? "block":"none"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={human} />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip">
                        <h1>My Profile</h1>
                        <p>In your profile, you can put many information about you such as Education, Experience, Compensation, etc. We encourage you to complete your profile so that we can inform you when there's suitable job opportunity for you. </p>
                    </div>
                    <div className="button_tooltip">
                      <button className="btn_skip" onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={(e)=>this.changeTooltip("tooltip",2)}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <Fade cascade>
              <div className="tooltip_dashboard tooltip_job" style={{display: this.state.tooltip === 2 || this.state.tooltip==="2"? "block":"none",
                }}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_job} />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip">
                        <h1>Job Opportunities</h1>
                        <p>Explore all the available job opportunities that might be suitable for you to apply. You  would also be able to refer the jobs to your friends and share the jobs to your social media</p>
                    </div>
                    <div className="button_tooltip">
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip2}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_ref" style={{display: this.state.tooltip === 3 || this.state.tooltip==="3"? "block":"none"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_referral} />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip">
                        <h1>Referral</h1>
                        <p>Help your friends to get their dream job by referring them to the available job opportunities. By being our referrer, you will be able to help your friends and get the benefit as well!</p>
                    </div>
                    <div className="button_tooltip">
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip3}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_interest" style={{display: this.state.tooltip === 4 || this.state.tooltip==="4"? "block":"none"}}>
                <div className="box_tooltip_dashboard">
                    <div className="bg_colors_tooltip">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="height-100" />
                    </div>
                    <div className="content_tooltip">
                      <div className="text_tooltip">
                          <h1>Assessments</h1>
                          <p>With our Assessments, you would be able to learn depper about yourself so that you can increase your Self-Awareness to adapt well in your workplace and your social life.</p>
                      </div>
                      <div className="button_tooltip" >
                        <button className="btn_skip"  onClick={this.skip}>
                          <p>Skip</p>
                        </button>
                        <button className="btn_next" onClick={this.tooltip4}>
                          <p>Next</p>
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
              </Fade>
             
              {this.state.tooltip_top &&
              <Fade cascade>
              <div className="tooltip_dashboard  tooltip_career  big_tooltip" style={{display: this.state.tooltip === 5 || this.state.tooltip==="5"? "block":"none"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="height-100" />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip" style={{marginBottom:"24px",height: "164px"}}>
                        <h1>Digital Learning Platform</h1>
                        <p>Here, you can learn so many things about how to improve yourself in soft skills, Such as Communication Skills, Leadership Skills, Social Skills, Productivity, Creative, and any other 21st Century Skills that are needed in this era.</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip5}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              {this.state.tooltip_bottom &&
              <Fade cascade>
              <div className="tooltip_dashboard  tooltip_career  small_tooltip" style={{display: this.state.tooltip === 5 || this.state.tooltip==="5"? "block":"none", background:`url(${bg_tooltip_bottom})`, 
                marginTop:"83px", height:"331px"}}>
                 <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="height-100" />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip" style={{marginBottom:"24px",height: "176px"}}>
                        <h1>Digital Learning Platform</h1>
                        <p>Here, you can learn so many things about how to improve yourself in soft skills, Such as Communication Skills, Leadership Skills, Social Skills, Productivity, Creative, and any other 21st Century Skills that are needed in this era.</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip5}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              {/* tooltip_career */}
              {this.state.tooltip_top &&
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_personality big_tooltip" style={{display: this.state.tooltip === 6 || this.state.tooltip==="6"? "block":"none"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_career} />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip">
                        <h1>Career Center</h1>
                        <p>Here you can view detailed information about each jobs such as key responsibilities of the job, knowledge, skill and abilities required for the job as well as recommended training for the respective jobs</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip6}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              {/* tooltip_personality */}
              {this.state.tooltip_bottom &&
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_personality small_tooltip" style={{display: this.state.tooltip === 6 || this.state.tooltip==="6"? "block":"none", background:`url(${bg_tooltip_bottom})`, 
                marginTop:"140px", height:"331px"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={tooltip_career} />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip" style={{marginBottom:"24px",height: "176px"}}>
                        <h1>Career Center</h1>
                        <p>Here you can view detailed information about each jobs such as key responsibilities of the job, knowledge, skill and abilities required for the job as well as recommended training for the respective jobs</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip6}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              {this.state.tooltip_top &&
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_dlp big_tooltip" style={{display: this.state.tooltip === 7 || this.state.tooltip==="7"? "block":"none"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="height-100" />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip">
                        <h1>Digital Learning Platform</h1>
                        <p>Here, you can learn so many things about how to improve yourself in soft skills, Such as Communication Skills, Leadership Skills, Social Skills, Productivity, Creative, and any other 21st Century Skills that are needed in this era.</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip7}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              {this.state.tooltip_bottom &&
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_personality small_tooltip" style={{display: this.state.tooltip === 7 || this.state.tooltip==="7"? "block":"none", background:`url(${bg_tooltip_bottom})`, 
                marginTop:"197px", height:"331px"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="height-100" />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip" style={{marginBottom:"24px",height: "176px"}}>
                        <h1>Digital Learning Platform</h1>
                        <p>Here, you can learn so many things about how to improve yourself in soft skills, Such as Communication Skills, Leadership Skills, Social Skills, Productivity, Creative, and any other 21st Century Skills that are needed in this era.</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip7}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              {this.state.tooltip_top &&
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_assessment big_tooltip" style={{display: this.state.tooltip === 8 || this.state.tooltip==="8"? "block":"none"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="height-100" />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip">
                        <h1>Assessments</h1>
                        <p>With our Assessments, you would be able to learn depper about yourself so that you can increase your Self-Awareness to adapt well in your workplace and your social life.</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip8}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              {this.state.tooltip_bottom &&
              <Fade cascade>
              <div className="tooltip_dashboard tooltip_personality small_tooltip" style={{display: this.state.tooltip === 8 || this.state.tooltip==="8"? "block":"none", background:`url(${bg_tooltip_bottom})`, 
                marginTop:"254px", height:"331px"}}>
                <div className="box_tooltip_dashboard">
                  <div className="bg_colors_tooltip">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="height-100" />
                  </div>
                  <div className="content_tooltip">
                    <div className="text_tooltip" style={{marginBottom:"24px",height: "176px"}}>
                        <h1>Assessments</h1>
                        <p>With our Assessments, you would be able to learn depper about yourself so that you can increase your Self-Awareness to adapt well in your workplace and your social life.</p>
                    </div>
                    <div className="button_tooltip" >
                      <button className="btn_skip"  onClick={this.skip}>
                        <p>Skip</p>
                      </button>
                      <button className="btn_next" onClick={this.tooltip8}>
                        <p>Next</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Fade>}
              </div>
              </Fade>}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      data_tooltip: state.putProfileIdRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      putProfile11: (user_id) =>{
        dispatch(putProfileId(user_id))
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);