import {
  GET_JOB,
  JOB_DETAIL,
  JOB_TRAINING,
  JOB_EXCLUDE,
  GET_PENGETAHUAN,
  GET_KETERAMPILAN,
  GET_KEMAMPUAN,
  GET_KARAKTER,
  GET_NILAI,
  DETAIL_JOB,
  JOB_OPPORTUNITY,
  GET_ALL_CITY,
  DETAIL_JOB_OPPORTUNITY,
  GET_JOB_BY_TITLE,
  GET_JOB_OPPORTUNITY_PUBLIC,
  GET_JOB_TYPE,
  APPLY_JOB,
  GET_JOB_DETAIL_PUBLIC,
  GET_JOB_CAREER_CENTER,
  GET_PROGRAM,
  GET_POPULAR1,
  GET_POPULAR2,
  GET_POPULAR3,
  GET_JOB_BY_INTEREST,
  GET_JOB_BY_INTEREST_ARTISTIC,
  GET_USER_COURSE_DLP,
  GET_USER_PROGRAM_DLP,
  GET_DETAIL_CONTENT_DLP,
  GET_DETAIL_PROGRAM_DLP,
  GET_COURSE_BY_CONTENT_DLP,
  GET_COURSE_DETAIL_DLP,
  GET_DROPDOWN_REGISTRATION_DLP,
  GET_ALLPROGRAM_DLP,
  GET_ABOUT_DLP,
  GET_KNOW_PROGRAM,
} from '../config/constants';
const initialState = {
  data: [],
  data2: [],
  dataJobExclude: [],
  dataOpportunity: [],
  dataTraining: [],
  dataKarakter: [],
  dataKemampuan: [],
  dataKeterampilan: [],
  dataNilai: [],
  dataPopular1: [],
  dataPopular2: [],
  dataPopular3: [],
  dataJobByInterest: [],
  dataProgram: [],
  dataAllProgramDLP: [],
  dataUserProgramDLP: [],
  dataUserCourseDLP: [],
  dataDetailContentDLP: [],
  dataDetailProgramDLP: [],
  dataDetailCourseDLP: [],
  dataCourseByContentDLP: [],
  dataDropdownDLP: [],
  dataAboutDLP: [],
  allCity: [],
  dataKnow: [],
  dataDetail: [],
  dataPengetahuan: [],
  dataJobByTitle: [],
  dataJobType: [],
  dataJobDetailPublic: [],
  dataJobOpportunityPublic: [],
  isLoading: false,
  error: false,
};

export const job = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_JOB}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_JOB}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${GET_JOB}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const job2 = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_JOB_CAREER_CENTER}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_JOB_CAREER_CENTER}_FULFILLED`:
      return {
        ...state,
        data2: action.payload.data,
        isLoading: false,
      };
    case `${GET_JOB_CAREER_CENTER}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const jobDetail = (state = initialState, action) => {
  switch (action.type) {
    case `${JOB_DETAIL}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${JOB_DETAIL}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${JOB_DETAIL}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const training = (state = initialState, action) => {
  switch (action.type) {
    case `${JOB_TRAINING}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${JOB_TRAINING}_FULFILLED`:
      return {
        ...state,
        dataTraining: action.payload.data,
        isLoading: false,
      };
    case `${JOB_TRAINING}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const jobExclude = (state = initialState, action) => {
  switch (action.type) {
    case `${JOB_EXCLUDE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${JOB_EXCLUDE}_FULFILLED`:
      return {
        ...state,
        dataJobExclude: action.payload.data,
        isLoading: false,
      };
    case `${JOB_EXCLUDE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const pengetahuan = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PENGETAHUAN}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_PENGETAHUAN}_FULFILLED`:
      return {
        ...state,
        dataPengetahuan: action.payload.data,
        isLoading: false,
      };
    case `${GET_PENGETAHUAN}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const keterampilan = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_KETERAMPILAN}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_KETERAMPILAN}_FULFILLED`:
      return {
        ...state,
        dataKeterampilan: action.payload.data,
        isLoading: false,
      };
    case `${GET_KETERAMPILAN}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const kemampuan = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_KEMAMPUAN}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_KEMAMPUAN}_FULFILLED`:
      return {
        ...state,
        dataKemampuan: action.payload.data,
        isLoading: false,
      };
    case `${GET_KEMAMPUAN}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const karakter = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_KARAKTER}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_KARAKTER}_FULFILLED`:
      return {
        ...state,
        dataKarakter: action.payload.data,
        isLoading: false,
      };
    case `${GET_KARAKTER}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const nilai = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_NILAI}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_NILAI}_FULFILLED`:
      return {
        ...state,
        dataNilai: action.payload.data,
        isLoading: false,
      };
    case `${GET_NILAI}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const detailJob = (state = initialState, action) => {
  switch (action.type) {
    case `${DETAIL_JOB}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${DETAIL_JOB}_FULFILLED`:
      return {
        ...state,
        dataDetail: action.payload.data,
        isLoading: false,
      };
    case `${DETAIL_JOB}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const JobOpportunity = (state = initialState, action) => {
  switch (action.type) {
    case `${JOB_OPPORTUNITY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${JOB_OPPORTUNITY}_FULFILLED`:
      return {
        ...state,
        dataOpportunity: action.payload.data,
        isLoading: false,
      };
    case `${JOB_OPPORTUNITY}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const AllCityRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ALL_CITY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_ALL_CITY}_FULFILLED`:
      return {
        ...state,
        allCity: action.payload.data,
        isLoading: false,
      };
    case `${GET_ALL_CITY}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const detailOpportunityRed = (state = initialState, action) => {
  switch (action.type) {
    case `${DETAIL_JOB_OPPORTUNITY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${DETAIL_JOB_OPPORTUNITY}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${DETAIL_JOB_OPPORTUNITY}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const jobByTitleRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_JOB_BY_TITLE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_JOB_BY_TITLE}_FULFILLED`:
      return {
        ...state,
        dataJobByTitle: action.payload.data,
        isLoading: false,
      };
    case `${GET_JOB_BY_TITLE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const jobTypeRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_JOB_TYPE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_JOB_TYPE}_FULFILLED`:
      return {
        ...state,
        dataJobType: action.payload.data,
        isLoading: false,
      };
    case `${GET_JOB_TYPE}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const JobOpportunityPublicRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_JOB_OPPORTUNITY_PUBLIC}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_JOB_OPPORTUNITY_PUBLIC}_FULFILLED`:
      return {
        ...state,
        dataJobOpportunityPublic: action.payload.data,
        isLoading: false,
      };
    case `${GET_JOB_OPPORTUNITY_PUBLIC}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const applyJobRed = (state = initialState, action) => {
  switch (action.type) {
    case `${APPLY_JOB}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${APPLY_JOB}_FULFILLED`:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case `${APPLY_JOB}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const jobDetailPublicRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_JOB_DETAIL_PUBLIC}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_JOB_DETAIL_PUBLIC}_FULFILLED`:
      return {
        ...state,
        dataJobDetailPublic: action.payload.data,
        isLoading: false,
      };
    case `${GET_JOB_DETAIL_PUBLIC}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const getProgramRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PROGRAM}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_PROGRAM}_FULFILLED`:
      return {
        ...state,
        dataProgram: action.payload.data,
        isLoading: false,
      };
    case `${GET_PROGRAM}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const popular1Red = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_POPULAR1}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_POPULAR1}_FULFILLED`:
      return {
        ...state,
        dataPopular1: action.payload.data,
        isLoading: false,
      };
    case `${GET_POPULAR1}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const popular2Red = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_POPULAR2}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_POPULAR2}_FULFILLED`:
      return {
        ...state,
        dataPopular2: action.payload.data,
        isLoading: false,
      };
    case `${GET_POPULAR2}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const popular3Red = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_POPULAR3}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_POPULAR3}_FULFILLED`:
      return {
        ...state,
        dataPopular3: action.payload.data,
        isLoading: false,
      };
    case `${GET_POPULAR3}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const getJobByInterest = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_JOB_BY_INTEREST}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_JOB_BY_INTEREST}_FULFILLED`:
      let arrayJob = state.dataJobByInterest;
      let sumArray = '';

      if (arrayJob.length >= 15) {
        arrayJob = [];
      }
      sumArray = arrayJob.concat(action.payload.data);

      return {
        ...state,
        dataJobByInterest: sumArray,
        isLoading: false,
      };
    case `${GET_JOB_BY_INTEREST}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const userCourseRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER_COURSE_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_USER_COURSE_DLP}_FULFILLED`:
      return {
        ...state,
        dataUserCourseDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_USER_COURSE_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const userProgramRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER_PROGRAM_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_USER_PROGRAM_DLP}_FULFILLED`:
      return {
        ...state,
        dataUserProgramDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_USER_PROGRAM_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const detailContentRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_DETAIL_CONTENT_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_DETAIL_CONTENT_DLP}_FULFILLED`:
      return {
        ...state,
        dataDetailContentDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_DETAIL_CONTENT_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const detailProgramRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_DETAIL_PROGRAM_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_DETAIL_PROGRAM_DLP}_FULFILLED`:
      return {
        ...state,
        dataDetailProgramDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_DETAIL_PROGRAM_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const courseByContentRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_COURSE_BY_CONTENT_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_COURSE_BY_CONTENT_DLP}_FULFILLED`:
      return {
        ...state,
        dataCourseByContentDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_COURSE_BY_CONTENT_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const courseDetailRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_COURSE_DETAIL_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_COURSE_DETAIL_DLP}_FULFILLED`:
      return {
        ...state,
        dataDetailCourseDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_COURSE_DETAIL_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const dropdownRegRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_DROPDOWN_REGISTRATION_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_DROPDOWN_REGISTRATION_DLP}_FULFILLED`:
      return {
        ...state,
        dataDropdownDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_DROPDOWN_REGISTRATION_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const allProgramDLPRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ALLPROGRAM_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_ALLPROGRAM_DLP}_FULFILLED`:
      return {
        ...state,
        dataAllProgramDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_ALLPROGRAM_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const aboutDLPRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ABOUT_DLP}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_ABOUT_DLP}_FULFILLED`:
      return {
        ...state,
        dataAboutDLP: action.payload.data,
        isLoading: false,
      };
    case `${GET_ABOUT_DLP}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const knowProgramRed = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_KNOW_PROGRAM}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_KNOW_PROGRAM}_FULFILLED`:
      return {
        ...state,
        dataKnow: action.payload.data,
        isLoading: false,
      };
    case `${GET_KNOW_PROGRAM}_REJECTED`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
