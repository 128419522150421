import React, { Component } from 'react';
import { API_DLP , API} from "../_redux/helper";
import axios from 'axios';
import Loading from "../components/Loading";
import Rating from "@material-ui/lab/Rating";
import lp_icon from "../images/lp_icon_list.svg"
import lv from "../images/lv.svg"
import star from "../images/k-star.svg"
import check from "../images/k_checklist.svg"
import check2 from "../images/work_check.svg"
import k_contents from "../images/k-contents.svg"
import enroll from "../images/k-enroll.svg"
import share from "../images/k-share.svg"
import logo from "../images/KaryaTalents_logo.svg"
import v1 from "../images/icons/icons/video-icon.png"
import v3 from "../images/icons/icons/book-icon.png"
import Modal from "react-awesome-modal";
import ReactGA from "react-ga";
import apply_success from "../images/apply_success.svg"
import ExpandMoreIcon from "../images/k-bottom.svg";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {getWorkId} from "../_actions/work";
import {getDetailProgramDLP, getDetailContentDLP,getAboutDLP, getCourseContentDLP } from "../_actions/jobs"
import { getProfileId } from "../_actions/user";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';

class componentName extends Component {
    constructor() {
        super();
        this.state ={
          content_id: 0,
          enroll_status:"",
          open: null,
          list_courses: [],
          error: false,
          loading: true,
          user_id: "",
          name:"",
          email:"",
          phone_number:"",
          address:"",
          work_exp_id:0,
          job_function_id:"",
          job_level_id:"",
          current_company:"",
          question_join:"",
          question_know:"",
          summary:"",
          question_taken:"",
          question_placed:"",
          interested:"",
          visible:true,
          success:false,
          failed:false,
          expanded:"panel1",
      }
    } 

    async componentDidMount() {
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        let userId
        if (sessionStorage.getItem("token")!==null  ){
            var decode1 = jwt.decode(token1);
            var user_id = decode1["user_id"];
                userId=user_id
        }else{
            userId=""
        }
        this.setState({ user_id: userId });
        this.props.getDetailProgramDLP(this.props.match.params.id);
        this.props.getDetailContentDLP(this.props.match.params.id);
        this.props.getAboutDLP(this.props.match.params.id);
        this.props.getWorkId(user_id);
        this.props.getProfileId(user_id);
        const program_id = this.props.match.params.id
        const dlp_user_id = localStorage.getItem('i-dlp')
        window.scrollTo(0, 0)
        ReactPixel.track(window.location.pathname);
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.setState({
            loading: true
        })
        let arr = []

        await Promise.all(
        [axios
            .get(`${API_DLP}/api/getcontent_by_program/${this.props.match.params.id}`)
            .then((courseModel) => {
                this.setState({
                    list_courses : courseModel.data
                })
                courseModel.data.forEach(el => {
                    arr.push(
                        new Promise((resolve, reject) => {
                            axios
                            .get(`${API_DLP}/api/getcourse_by_content/${el.program_content_id}`)
                            .then((courses) => {
                                el["content_courses"] = courses.data
                                resolve(courses.data)
                            })
                        })
                    )
                });
            })]
        )
        await Promise.all(arr).then((values)=>{
            if (values.length !== 0) {
              this.setState({
                loading: false
              })
            }
          })

        const API_enroll = await axios
          .get(`${API}/api/program/check_enroll/${program_id}/${dlp_user_id}`)
          .then((res) => {
            this.setState({ enroll_status: res.data.is_enroll });
          });
      }
    
      componentDidUpdate(prevState, prevProps){
        if (prevProps.content_id !== this.state.content_id){
            this.props.getCourseContentDLP(this.state.content_id);
        }
      }

    //   updateID=(id)=>{
    //    this.setState({content_id:id})
    // }

    onClickList(id, index){
        // this.updateID(id)
        this.setState({
            content_id: id,
            open: index
        })
    }
    enroll=(id)=>{
        const { profile } = this.props.profile1;
        const { workId, error, isLoading } = this.props.workId1;

        let f_name
        let f_email
        let f_phone
        let f_address
        let f_work
        let f_job_function
        let f_job_level

        let n_date = []
        let n_job_level = []
        let n_job_function = []
        workId.map((item,i)=>{n_date.push(Date.parse(item.work_period_start))
                              n_job_level.push(item.job_level_id)
                              n_job_function.push(item.job_function_id)
        })
        const n_max = Math.max.apply(null, n_date)
        const n_idx = n_date.indexOf(n_max)

        if ((this.state.name=="") && (profile.name !=="")){
            f_name= profile.name
        }else{
            f_name=this.state.name
        }


        if ((this.state.email=="") && (profile.email !=="")){
            f_email= profile.email
        }else{
            f_email=this.state.email
        }

        if ((this.state.phone_number=="") && (profile.phone_number !=="")){
            f_phone= profile.phone_number
       }else{
            f_phone=this.state.phone_number
        }

        if ((this.state.address=="") && (profile.address !== "")){
            f_address= profile.address
        }else{
            f_address=this.state.address
        }

        if ((this.state.work_exp_id==0) && (profile.work_experience_id !== "")){
            f_work= profile.work_experience_id
        }else{
            f_work=this.state.work_exp_id
        }

        if ((this.state.job_function_id=="") && (profile.job_function_id !== "")){
            f_job_function= n_job_function[n_idx]
        }else{
            f_job_function=this.state.job_function_id
        }

        if ((this.state.job_level_id=="") || (profile.job_level_id !=="")){
            f_job_level= n_job_level[n_idx]
        }else{
            f_job_level=this.state.job_level_id
        }
        const data={
            name:f_name,
            email:f_email,
            phone_number:parseInt(f_phone),
            address:f_address,
            work_experience:f_work,
            last_salary:this.state.current_company,
            job_function_id:f_job_function,
            job_level_id:f_job_level,
            question_join:this.state.question_join,
            question_know:this.state.question_know,
            question_taken:this.state.question_taken,
            question_placed:this.state.question_placed,
            emailconfirmed:this.state.interested,
            program_id:this.props.match.params.id,
        }
        this.setState({success:true})
        axios.post(`${API}/api/dlp/create`, data).then((res)=>{
            // this.setState({success:true})
            console.log("success");
        }).catch((err)=>{
            console.log(err,"error");
            this.setState({failed:true})
        })
    }

    closePopup=(e)=>{
        this.setState({enroll_status:true})
        window.location.href="/DigitalLearningPlatform"
        localStorage.setItem("getMyCOurse","My Courses")
    }

    back=()=> {
        this.props.history.goBack();
      }

      handleChange = (panel) => (event, isExpanded) => {
        this.setState({expanded : (isExpanded ? panel : false)});
    };
   

    render() {
        const {dataDetailContentDLP} = this.props.content1;
        const {dataDetailProgramDLP} = this.props.program1;
        const {dataAboutDLP} = this.props.about1;
        const {dataCourseByContentDLP} = this.props.detail1;
        const { profile } = this.props.profile1;
        const { workId, error, isLoading } = this.props.workId1;


        let btn_enroll
        if (this.state.enroll_status==true){
            btn_enroll = <div className="k-enroll width-100 flex space-between" style={{borderColor:"#BDBDBD", backgroundColor:"#BDBDBD"}} >
                            <p>Enrolled</p>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={check2} />
                        </div>
        }else{
            btn_enroll = <div className="k-enroll width-100 flex space-between" onClick={this.enroll}>
                            <p>Enroll Now</p>
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={enroll} />
                        </div>
        }

        if (this.state.error) {
            return (
                <div className="message-question-load">
                <h3>Ups... Please, refresh this page!</h3>
                </div>
            );
        }
    
        if (this.state.loading) {
            return (
                <React.Fragment>
                <Loading />
                </React.Fragment>
            );
        }
        return (
            <div>
                <div className="header-landing-page none">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className="logo" />
                    </div>
                <div className="learning-program flex width-100 column" style={{marginTop:"80px"}}>
                    <div className="width-100 flex center klp-container"  >
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/DLP/Program/${sessionStorage.getItem("pic")}`} className="height-100"  style={{objectFit:"cover"}}/>
                    </div>
                    <div className="page-container" >
                            <div className="page-container-outer">
                                <div className="flex width-100 column box-k-learn">
                                    <div className="flex width-100 k-learn-top">
                                        <button onClick={this.back} >
                                            <p style={{color:"#333333"}}> {`<`} Back</p>
                                        </button>
                                    </div>
                                    <div className="flex width-100 space-between k-learn-content" style={{marginBottom:"50px"}}>
                                    <div className="mobile width-100">
                                        <div className="flex width-100 column">
                                            <div className="flex width-100 column k-learn-right" style={{height:"fit-content"}}>
                                                <div style={{padding:"14px"}}>
                                                    <div className="flex width-100 column">
                                                    <video className="video" src={`${API}/File/DLP/Program/${sessionStorage.getItem("vd")}`}  controls controlsList="nodownload"  disablePictureInPicture onContextMenu={e=>e.preventDefault()} />
                                                        <div className="k-box-dsc">
                                                            <p>FREE</p>
                                                        </div>
                                                        <div className="k-enroll add-whislist width-100 flex center" style={{display:"none"}}>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={lv} />
                                                            <p>Add to Wishlist</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="width-100 flex center k-learn-right" style={{display:"none"}}>
                                                <div className="p-14">
                                                    <p className="p-share">Share this program</p>
                                                    <div className="flex width-100 center">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="flex column k-learn-left" >
                                            <div style={{padding:"14px"}}>
                                                {dataAboutDLP.map((item,i)=>(
                                                <div key={i} className="block width-100">
                                                <h1 className="flex width-100">{item.program_name} </h1>
                                                <p className="openSans">{item.program_desc} </p>
                                                <div className="k-rating flex width-100" style= {{display:"none"}}>
                                                    <Rating name="read-only" value={5} readOnly />
                                                    <p>(325)</p>
                                                </div>
                                                <div className="flex width-100 col space-between">
                                                    <div className="flex width-50 column">
                                                        <div className="width-100 flex column k-list-course">
                                                            <h6 className="openSans">Course Type</h6>
                                                            <div className="flex width-100">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                                                <p className="openSans">{item.course_type} </p>
                                                            </div>
                                                        </div>
                                                        <div className="width-100 flex column k-list-course">
                                                            <h6 className="openSans">Job Level</h6>
                                                            <div className="flex width-100">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                                                <p className="openSans">{item.job_level} </p>
                                                            </div>
                                                        </div>
                                                        <div className="width-100 flex column k-list-course">
                                                            <h6 className="openSans">Benefits</h6>
                                                            <div className="flex width-100">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                                                <p className="openSans">{item.benefit} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex width-50 column">
                                                        <div className="width-100 flex column k-list-course">
                                                            <h6 className="openSans">Time Period</h6>
                                                            <div className="flex width-100">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                                                <p className="openSans">{item.time_period} </p>
                                                            </div>
                                                        </div>
                                                        <div className="width-100 flex column k-list-course">
                                                            <h6 className="openSans">Language</h6>
                                                            <div className="flex width-100">
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={lp_icon} />
                                                                <p className="openSans">{item.language} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="k-about flex width-100">
                                                    <h1>About this program</h1>
                                                    <div className="line-k-about" />
                                                </div>
                                                <p className="openSans c-about">{item.about} </p>
                                                </div>
                                                ))}
                                                <div className="k-about flex width-100">
                                                    <h1>What will you learn</h1>
                                                    <div className="line-k-about" />
                                                </div>
                                                <div className=" width-100 block list-learn" style={{display:"block"}}>
                                                    {dataDetailProgramDLP.map((item,i)=>(
                                                    <div key={i} className="flex width-50 ">
                                                        <div className="flex width-100  k-list-course">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={check} />
                                                            <p className="openSans">{item.learn_desc} </p>
                                                        </div>
                                                    </div>
                                                    ))}
                                                </div>
                                                <div className="k-about flex width-100">
                                                    <h1 style={{width:"auto"}}>Contents</h1>
                                                    <div className="line-k-about" />
                                                </div>
                                                <div className="k-box-acc width-100 flex column" style={{marginBottom:"17px"}}>
                                                {this.state.list_courses.map((item,i)=>{
                                                    if (i==0){
                                                        return(
                                                            <Accordion key={i} expanded={i==0?(this.state.expanded === 'panel1'):""} onChange={i==0?(this.handleChange('panel1')):""}>
                                                                <AccordionSummary expandIcon={<LazyLoadImage effect="blur" loading="lazy" alt="" src={ExpandMoreIcon} />} id="panel1a-header" >
                                                                    <p className="openSans-Bold">{item.program_content_name} </p>
                                                                </AccordionSummary>
                                                                {item.content_courses.map((item,i)=>(
                                                                    <div style={{padding:"0px 14px"}}>
                                                                        <div className="flex width-100 space-between k-list-course">
                                                                            <div className="flex">
                                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={((item.course_type_id==1)?v1:"") || ((item.course_type_id==2)?k_contents:"") || ((item.course_type_id==3)?v3:"")} />
                                                                                <p className="openSans">{item.course_name} </p>
                                                                            </div>
                                                                            <p className="k-times openSans">{item.duration} </p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Accordion>
                                                        )
                                                    }
                                                    if (i>0){
                                                        return(
                                                        <Accordion key={i}>
                                                            <AccordionSummary expandIcon={<LazyLoadImage effect="blur" loading="lazy" alt="" src={ExpandMoreIcon} />} id="panel1a-header" >
                                                                <p className="openSans-Bold">{item.program_content_name} </p>
                                                            </AccordionSummary>
                                                            {item.content_courses.map((item,i)=>(
                                                                <div style={{padding:"0px 14px"}}>
                                                                    <div className="flex width-100 space-between k-list-course">
                                                                        <div className="flex">
                                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={((item.course_type_id==1)?v1:"") || ((item.course_type_id==2)?k_contents:"") || ((item.course_type_id==3)?v3:"")} />
                                                                            <p className="openSans">{item.course_name} </p>
                                                                        </div>
                                                                        <p className="k-times openSans">{item.duration} </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Accordion>
                                                        )
                                                    }
                                                })}
                                                </div>
                                                <div className="block width-100" style={{display:"none"}}>
                                                <div className="k-about flex width-100" >
                                                    <h1  className="rating">Program Rating {`&`} Reviews</h1>
                                                    <div className="line-k-about" />
                                                </div>
                                                <div className="flex width-100 space-between k-comment" >
                                                    <div className="flex column k-comment-left">
                                                        <h2>5.0</h2>
                                                        <Rating name="read-only" value={5} readOnly />
                                                        <p style={{marginTop:"4px"}}>(325)</p>
                                                    </div>
                                                    <div className="flex column k-comment-right">
                                                        <div className="flex width-100 space-between b-14">
                                                            <p>5</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={star} />
                                                            <div  className="k-progress flex flex-start">
                                                                <div className="width-100 heigt-100 flex" style={{backgroundColor:"#3B6C98", borderRadius:"5px"}}/>
                                                            </div>
                                                        </div>
                                                        <div className="flex width-100 space-between b-14">
                                                            <p>4</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={star} />
                                                            <div  className="k-progress flex flex-start">
                                                                <div className="heigt-100 flex" style={{backgroundColor:"#3B6C98", borderRadius:"5px", width:"73%"}}/>
                                                            </div>
                                                        </div>
                                                        <div className="flex width-100 space-between b-14">
                                                            <p>3</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={star} />
                                                            <div  className="k-progress flex flex-start">
                                                                <div className="heigt-100 flex" style={{backgroundColor:"#3B6C98", borderRadius:"5px", width:"70%"}}/>
                                                            </div>
                                                        </div>
                                                        <div className="flex width-100 space-between b-14">
                                                            <p>2</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={star} />
                                                            <div  className="k-progress flex flex-start">
                                                                <div className="width-35 heigt-100 flex" style={{backgroundColor:"#3B6C98", borderRadius:"5px"}}/>
                                                            </div>
                                                        </div>
                                                        <div className="flex width-100 space-between b-14">
                                                            <p>1</p>
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={star} />
                                                            <div  className="k-progress flex flex-start">
                                                                <div className="heigt-100 flex" style={{backgroundColor:"#3B6C98", borderRadius:"5px", width:"15%"}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex width-100 k-container-comment">
                                                    <div style={{padding:"14px 2%", width:"98%"}} >
                                                        <div className=" flex width-100 column">
                                                            <div className="flex width-100 space-between">
                                                                <div className="flex">
                                                                    <div className="k-avatar"></div>
                                                                    <div className="flex column k-name">
                                                                        <p>John Doe</p>
                                                                        <Rating name="read-only" value={5} readOnly />
                                                                    </div>
                                                                </div>
                                                                <p className="k-date">22/11/2020</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex width-100 k-container-comment">
                                                    <div style={{padding:"14px 2%", width:"98%"}} >
                                                        <div className=" flex width-100 column">
                                                            <div className="flex width-100 space-between">
                                                                <div className="flex">
                                                                    <div className="k-avatar"></div>
                                                                    <div className="flex column k-name">
                                                                        <p>John Doe</p>
                                                                        <Rating name="read-only" value={5} readOnly />
                                                                    </div>
                                                                </div>
                                                                <p className="k-date">22/11/2020</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex width-100 k-container-comment">
                                                    <div style={{padding:"14px 2%", width:"98%"}} >
                                                        <div className=" flex width-100 column">
                                                            <div className="flex width-100 space-between">
                                                                <div className="flex">
                                                                    <div className="k-avatar"></div>
                                                                    <div className="flex column k-name">
                                                                        <p>John Doe</p>
                                                                        <Rating name="read-only" value={5} readOnly />
                                                                    </div>
                                                                </div>
                                                                <p className="k-date">22/11/2020</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dekstop width-30">
                                        <div className="flex width-100 column kl-sticky">
                                            <div className="flex width-100 column k-learn-right" style={{height:"fit-content"}}>
                                                <div style={{padding:"14px"}}>
                                                    <div className="flex width-100 column">
                                                        <video className="video" src={`${API}/File/DLP/Program/${sessionStorage.getItem("vd")}`}  controls controlsList="nodownload"  disablePictureInPicture onContextMenu={e=>e.preventDefault()} />
                                                        {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={klp_video} className="video" /> */}
                                                        <div className="k-box-dsc">
                                                            <p>FREE</p>
                                                        </div>
                                                        {btn_enroll}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="width-100 flex center k-learn-right" style={{display:"none"}}>
                                                <div className="p-14">
                                                    <p className="p-share">Share this program</p>
                                                    <div className="flex width-100 center">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={share} className="share"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="mobile width-10 center">
                   
                        <div className=" width-100 flex " style={{position:"fixed", bottom:"15px"}} >
                           {btn_enroll}
                        </div>
               
                    </div>
                </div>
                <Modal id="success" visible={this.state.success} effect="fadeInUp">
                <div  className="modal_apply">
                    <div className="width-100 flex center">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={apply_success} style={{marginTop:"24px", marginBottom:"24px"}}/>
                    </div>
                    <h5>Enrollment Successful!</h5>
                    <p  style={{width:"90%", marginLeft:"5%"}}>Enrollment Successful!  Please click here to view your courses</p>
                    <div className="width-100 flex center">
                        <button onClick={this.closePopup} style={{marginBottom:"20px"}}>
                            <p style={{margin:"12px", color:"#4F4F4F"}}>My Course</p>
                        </button>
                    </div>
                </div>
            </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    //   id: ownProps.match.params.id,
      content1: state.detailContentRed,
      program1: state.detailProgramRed,
      about1: state.aboutDLPRed,
      detail1: state.courseByContentRed,
      user_id: state.user_id,
      profile1: state.profileId,
      workId1: state.getWorkIdRed,

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getDetailProgramDLP: (id) => {
        dispatch(getDetailProgramDLP(id));
      },
      getDetailContentDLP: (id) => {
        dispatch(getDetailContentDLP(id));
      },
      getAboutDLP: (id) => {
        dispatch(getAboutDLP(id));
      }, 
      getCourseContentDLP: (id) => {
        dispatch(getCourseContentDLP(id));
      }, 
      getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
       getWorkId: (user_id) => {
        dispatch(getWorkId(user_id));
      }, 
    };
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(componentName)
  );
