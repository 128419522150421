import {GET_WORK_EXP,GET_WORK_PRE, GET_WORK_STA,GET_WORK_EXP_ID, PUT_WORK_EXP, DELETE_WORK_EXP, POST_WORK_EXP, GET_INDUSTRI, GET_JOB_LEVEL, GET_JOB_FUNCTION,  } from "../config/constants";
  const initialState = {
    work:[],
    data:[],
    workId:[],
    deleteWork:[],
    postWork:[],
    putWork:[],
    workPre :[],
    workSta:[],
    dataIndustri:[],
    dataJobFunction:[],
    dataJobLevel:[],
    isLoading: false,
    error: false,
  };
  
  export const getWorkRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_WORK_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_WORK_EXP}_FULFILLED`:
        return {
          ...state,
          work: action.payload.data,
          isLoading: false,
        };
      case `${GET_WORK_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getWorkIdRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_WORK_EXP_ID}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_WORK_EXP_ID}_FULFILLED`:
        return {
          ...state,
          workId: action.payload.data,
          isLoading: false,
        };
      case `${GET_WORK_EXP_ID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const postWorkRed = (state = initialState, action) => {
    switch (action.type) {
      case `${POST_WORK_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${POST_WORK_EXP}_FULFILLED`:
        return {
          ...state,
          postWork: action.payload.data,
          isLoading: false,
        };
      case `${POST_WORK_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const putWorkRed = (state = initialState, action) => {
    switch (action.type) {
      case `${PUT_WORK_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${PUT_WORK_EXP}_FULFILLED`:
        return {
          ...state,
          putWork: action.payload.data,
          isLoading: false,
        };
      case `${PUT_WORK_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const delWorkRed = (state = initialState, action) => {
    switch (action.type) {
      case `${DELETE_WORK_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${DELETE_WORK_EXP}_FULFILLED`:
        return {
          ...state,
          deleteWork: action.payload.data,
          isLoading: false,
        };
      case `${DELETE_WORK_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const industriRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_INDUSTRI}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_INDUSTRI}_FULFILLED`:
        return {
          ...state,
          dataIndustri: action.payload.data,
          isLoading: false,
        };
      case `${GET_INDUSTRI}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  export const jobLevelRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_JOB_LEVEL}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_JOB_LEVEL}_FULFILLED`:
        return {
          ...state,
          dataJobLevel: action.payload.data,
          isLoading: false,
        };
      case `${GET_JOB_LEVEL}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  export const jobFunctionRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_JOB_FUNCTION}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_JOB_FUNCTION}_FULFILLED`:
        return {
          ...state,
          dataJobFunction: action.payload.data,
          isLoading: false,
        };
      case `${GET_JOB_FUNCTION}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  
  export const getWorkPreRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_WORK_PRE}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_WORK_PRE}_FULFILLED`:
        return {
          ...state,
          workPre: action.payload.data,
          isLoading: false,
        };
      case `${GET_WORK_PRE}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  

  export const getWorkStaRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_WORK_STA}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_WORK_STA}_FULFILLED`:
        return {
          ...state,
          workSta: action.payload.data,
          isLoading: false,
        };
      case `${GET_WORK_STA}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };