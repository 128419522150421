import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from "./Loading";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams, useHistory } from "react-router-dom";
import { API_DLP } from "../_redux/helper"
import axios from 'axios';
import DlcCircularProgress from "./DlcCircularProgress"
// material ui
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from "@material-ui/core/Typography";
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
// icon


function DlcQuizCourse(props) {
  let history = useHistory()
  let params = useParams()
  const [btnDisable, setBtnDisable] = useState(true)
  const [questions, setQuestions] = useState([])
  const [selectCourse, setSelectCourse] = useState({});
  const [loading, setLoading] = useState(true)
  const [score, setScore] = useState(0)
  const [minimumScore, setMinimumScore] = useState(0)
  const [end_date_course, setEnd_date_course] = useState("")
  const [resQuiz, setResQuiz] = useState([])
  const [user_id, setUser_id] = useState(0)
  const [quiz_id, setQuiz_id] = useState(0)
  const [whatToShow, setWhatToShow] = useState("quiz")
  const [resultQuiz, setResultQUiz] = useState({})


  const handleCourse = (e) => {  
    let result = {...selectCourse, [e.target.name]: e.target.value}
    setSelectCourse(result)
  }

  const handleSubmitQuiz = (e) => {
    e.preventDefault();
    if (Object.keys(selectCourse).length !== questions.length) {
    } else {
      let objJawaban = {}
      let resultJawaban = {}
      let count = 0
      questions.forEach(question => {
        objJawaban = {...objJawaban, [question.id]: question.kunci_jawaban}
      });
      questions.forEach(question => {
        resultJawaban = {...resultJawaban, [question.id]:question[objJawaban[question.id]]}
      });
      questions.forEach(question => {
        /* eslint eqeqeq: 0 */
        if(selectCourse[question.id] == resultJawaban[question.id]){
          count++
        }
      });

      updateScoreQuiz((count/questions.length)*score, objJawaban)
      setResultQUiz({passed:count, totalQuiz: questions.length, score:(count/questions.length)*score, minimumScore:minimumScore})
      goToAssessment()
      
    }
  }

  const goToAssessment = () =>{
    setLoading(true)
    setSelectCourse({})
    setTimeout(() => {
      setWhatToShow("asessment")
        setLoading(false)
      }, 3000);
  }

  const gotToQuiz = () =>{
    setSelectCourse({})
    setLoading(true)
    setTimeout(() => {
      setWhatToShow("quiz")
        setLoading(false)
      }, 3000);
  }

  
  const getListCourse = (id) => {
    async function fetchData() {
      let arr = []
      await Promise.all([
      axios
      .get(`${API_DLP}/api/getcontent_by_program/${props.ownProps.match.params.program_id}`)
      .then((courseModel) => {
          courseModel.data.forEach(el => {
              arr.push(
                  new Promise((resolve, reject) => {
                      axios
                      .get(`${API_DLP}/api/getusercourse_by_content/${id}/${el.program_content_id}`)
                      .then((courses) => {
                        // console.log(courses.data)
                          el["content_courses"] = courses.data
                          resolve(courses.data)
                      })
                  })
              )
          });
      })])
      await Promise.all(arr).then((values)=>{
          if (values.length !== 0) {
            values.forEach(arr1 => {
              arr1.forEach(arr2 => {
                if (arr2.course_id == props.ownProps.match.params.course_id) {
                  setScore(Number(arr2.score))
                  setMinimumScore(Number(arr2.score_minimum))
                  setQuiz_id(arr2.id)
                }
              });
            });
          }
        })
    }
    fetchData();
  } 

  const updateScoreQuiz = (resultScore, objJawaban) =>{
    var token=  sessionStorage.getItem('token')
    axios({
      method: "POST",
      url:  `${API_DLP}/api/updatequiz/${quiz_id}/${resultScore}`,
      headers: {
        Authorization: ` ${token}`,
      },
      data: {
        objJawaban: objJawaban
      },
    }).then(({data}) => {
      console.log(data)
    })
  }

  const getDueDate = (id) =>{
    axios
      .get(`${API_DLP}/api/program/user/${id}`)
      .then(({data}) => {
          data.forEach(el => {
              if (el.program_id == props.ownProps.match.params.program_id) {
                  var d = new Date(el.end_date);
                  setEnd_date_course(`${el.end_date.split("T")[0].split("-").reverse().join("-")}, 23:59 WIB`)
              }
          });
      })
  }


  let data_Result
  let list_opt = []
  let id_quiz
  const getResultQuiz = (id) =>{
    axios
      .get(`${API_DLP}/api/getuserquiz/${id}/${props.ownProps.match.params.program_id}`)
      .then(({data}) => {
        data.map((item,i)=>{
          if (i==0){
            id_quiz = item.id
             data_Result = JSON.parse(item.body).objJawaban
              Object.entries(data_Result).map((item,i)=>{
                 list_opt.push({id_quiz : id_quiz, id: item[0], value: item[1]})
             })
          }
        })
        setResQuiz(list_opt)
      })
  }
  
  useEffect(() => {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var email = decode1["email"];
    // console.log(props,"ini props DlcQuizCourse");
 
    axios            
        .get(`${API_DLP}/api/dlp/find/email/${email}`)
        .then(({data}) => {
          setUser_id(data.id)
          // console.log((`${API_DLP}/api/getusercoursedetail_by_course/${data.id}/${props.ownProps.match.params.course_id}`),"ini api2");
            axios
            .get(`${API_DLP}/api/getusercoursedetail_by_course/${data.id}/${props.ownProps.match.params.course_id}`)
            .then((detailCourse) => {
              let array = []
              detailCourse.data.forEach(el => {
                array.push(JSON.parse(el.course_value))
              });
           
            getListCourse(data.id)
            getDueDate(data.id)
            setTimeout(()=>{
            setQuestions(array)
          }, 1000)
            getResultQuiz(data.id)
            setLoading(false)
         
          })
        })
  }, [whatToShow])

  useEffect(() => {
    if (Object.keys(selectCourse).length !== questions.length) {
      setBtnDisable(true)
    } else {
      setBtnDisable(false)
    }
  });

  if (loading) {
    return (
      <React.Fragment>
        <Loading />
      </React.Fragment>
    );
  }
  const indexClick = resQuiz.findIndex((x) => (x.id === "8" && x.value==="a"))



    return (
      <React.Fragment>
        <div style={{display: whatToShow == "quiz"? "block" : "none"}}>
          <Breadcrumbs className="dekstop" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link color="inherit" to={"/DigitalLearningCenter/"+props.ownProps.match.params.program_id+"/CourseOutline"}>
                  <p className="breadcrumb-preview-dlp">
                      Course Outline
                  </p>
              </Link>
              <Link color="inherit" to={"/DigitalLearningCenter/"+props.ownProps.match.params.program_id+"/CourseOutline"}>
                  <p className="breadcrumb-preview-dlp">
                      {window.location.pathname.split("/")[window.location.pathname.split("/").length-2].split("%20").join(" ")}
                  </p>
              </Link>
              <Typography color="textPrimary">
                  <p className="breadcrumb-now-dlp">
                      {window.location.pathname.split("/")[window.location.pathname.split("/").length-1].split("%20").join(" ")}
                  </p> 
              </Typography>
          </Breadcrumbs>
          <Breadcrumbs className="mobile" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link color="inherit" to={"/DigitalLearningCenter/"+props.ownProps.match.params.program_id+"/CourseOutline"}>
                  <p className="breadcrumb-preview-dlp-mobile">
                      Course Outline
                  </p>
              </Link>
              <Link color="inherit" to={"/DigitalLearningCenter/"+props.ownProps.match.params.program_id+"/CourseOutline"}>
                  <p className="breadcrumb-preview-dlp-mobile">
                      {window.location.pathname.split("/")[window.location.pathname.split("/").length-2].split("%20").join(" ")}
                  </p>
              </Link>
              <Typography color="textPrimary">
                  <p className="breadcrumb-now-dlp-mobile">
                      {window.location.pathname.split("/")[window.location.pathname.split("/").length-1].split("%20").join(" ")}
                  </p> 
              </Typography>
          </Breadcrumbs>
          <p className="border-bottom-tabs"></p>
            {/* DESKTOP */}
            <div className="heading-quiz desktop">
              <h1 className="title-head-quiz">Quiz</h1>
              <p>Total Points: <b>{score}</b></p>
              <p>Due date: <b>{end_date_course}</b></p>
            </div>
            <form className="form-quiz-dlp desktop" onSubmit={e=>handleSubmitQuiz(e)} style={{display:(sessionStorage.getItem("view")=="false")?"":"none"}}>
              <ol >
                  {
                    questions.map((question, index)=>{
                      const select = resQuiz.findIndex((selectedValue) => selectedValue.id == question.id);
                      const selectedProfile = resQuiz[select] ? resQuiz[select].id : ""
                      const selected = resQuiz[select] ? resQuiz[select].value : ""
                      return(
                        <React.Fragment key={index}>
                        <li>{question.soal}</li>
                        <RadioGroup className="block-i width-100">
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.a.split(" ").join("_")} value={question.a} 
                          onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.a.split(" ").join("_")}>{question.a}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.b.split(" ").join("_")} value={question.b} 
                          onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.b.split(" ").join("_")}>{question.b}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.c.split(" ").join("_")} value={question.c} 
                          onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.c.split(" ").join("_")}>{question.c}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.d.split(" ").join("_")} value={question.d} 
                          onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.d.split(" ").join("_")}>{question.d}</label><br/>
                          </RadioGroup>
                      </React.Fragment>
                      )
                    })
                  }
              </ol>
              {
                btnDisable ?
              // <input className="button-submit-form-quiz-disable"  type="submit" value="Submit" disabled style={{display:"-webkit-inline-box"}} />
              <button className="btn-submit-quiz-Dlc" style={{backgroundColor:" #BDBDBD", border:"1px solid  #BDBDBD"}}>
                <p>Submit</p>
              </button>
              :
              // <input className="button-submit-form-quiz" type="submit" value="Submit" style={{display:"-webkit-inline-box"}}/>
              <button className="btn-submit-quiz-Dlc" type="submit" value="Submit">
                <p>Submit</p>
              </button>
              }
            </form>

            <form className="form-quiz-dlp desktop" style={{display:(sessionStorage.getItem("view")=="true")?"":"none"}}>
              <ol >
                  {
                    questions.map((question, index)=>{
                      const select = resQuiz.findIndex((selectedValue) => selectedValue.id == question.id);
                      const selectedProfile = resQuiz[select] ? resQuiz[select].id : ""
                      const selected = resQuiz[select] ? resQuiz[select].value : ""
                      return(
                        <React.Fragment key={index}>
                        <li>{question.soal}</li>
                        <RadioGroup className="block-i width-100">
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.a.split(" ").join("_")} value={question.a} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "a")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.a.split(" ").join("_")}>{question.a}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.b.split(" ").join("_")} value={question.b} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "b")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.b.split(" ").join("_")}>{question.b}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.c.split(" ").join("_")} value={question.c} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "c")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.c.split(" ").join("_")}>{question.c}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.d.split(" ").join("_")} value={question.d} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "d")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.d.split(" ").join("_")}>{question.d}</label><br/>
                        </RadioGroup>
                      </React.Fragment>
                      )
                    })
                  }
              </ol>
              {
                btnDisable ?
                <button className="btn-submit-quiz-Dlc" style={{backgroundColor:" #BDBDBD", border:"1px solid  #BDBDBD"}}>
                  <p>Submit</p>
                </button>
              :
              <button className="btn-submit-quiz-Dlc" type="submit" value="Submit">
                <p>Submit</p>
              </button>
              }
            </form>
            {/* END DESKTOP */}
            {/* MOBILE */}
            <div className="mobile">
              <h1 className="title-head-quiz-mobile">Quiz</h1>
              <p className="text-points-and-duedate-mobile" style={{marginTop: "10px", marginBottom: "10px"}}>Total Points: <b>{score}</b></p>
              <p className="text-points-and-duedate-mobile">Due date: <b>{end_date_course}</b></p>
            </div>
            <form className="form-quiz-dlp-mobile mobile" onSubmit={handleSubmitQuiz} style={{display:(sessionStorage.getItem("view")=="false")?"":"none"}}>
              <ol>
                  {
                    questions.map((question, index)=>{
                      return(
                        <React.Fragment key={index}>
                        <li>{question.soal}</li>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.a.split(" ").join("_")} value={question.a} onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz-mobile" htmlFor={index+question.id+question.a.split(" ").join("_")}>{question.a}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.b.split(" ").join("_")} value={question.b} onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz-mobile" htmlFor={index+question.id+question.b.split(" ").join("_")}>{question.b}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.c.split(" ").join("_")} value={question.c} onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz-mobile" htmlFor={index+question.id+question.c.split(" ").join("_")}>{question.c}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.d.split(" ").join("_")} value={question.d} onChange={e=>handleCourse(e)}/>
                          <label className="label-radio-quiz-mobile" htmlFor={index+question.id+question.d.split(" ").join("_")}>{question.d}</label><br/>
                      </React.Fragment>
                      )
                    })
                  }
              </ol>
              {
                btnDisable ?
                <button className="btn-submit-quiz-Dlc" style={{backgroundColor:" #BDBDBD", border:"1px solid  #BDBDBD"}}>
                  <p>Submit</p>
                </button>
              :
              <button className="btn-submit-quiz-Dlc" type="submit" value="Submit">
                <p>Submit</p>
              </button>
              }
            </form>

            <form className="form-quiz-dlp-mobile mobile" style={{display:(sessionStorage.getItem("view")=="true")?"":"none"}}>
              <ol>
                  {
                    questions.map((question, index)=>{
                      const select = resQuiz.findIndex((selectedValue) => selectedValue.id == question.id);
                      const selectedProfile = resQuiz[select] ? resQuiz[select].id : ""
                      const selected = resQuiz[select] ? resQuiz[select].value : ""
                      return(
                        <React.Fragment key={index}>
                        <li>{question.soal}</li>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.a.split(" ").join("_")} value={question.a} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "a")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.a.split(" ").join("_")}>{question.a}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.b.split(" ").join("_")} value={question.b} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "b")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.b.split(" ").join("_")}>{question.b}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.c.split(" ").join("_")} value={question.c} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "c")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.c.split(" ").join("_")}>{question.c}</label><br/>
                          <Radio color="primary" className="input-radio-quiz" name={question.id} type="radio" id={index+question.id+question.d.split(" ").join("_")} value={question.d} 
                          checked={resQuiz.findIndex((selectedValue) => selectedValue.id == question.id)!== -1 && (selectedProfile == question.id && selected === "d")? true:false} />
                          <label className="label-radio-quiz" htmlFor={index+question.id+question.d.split(" ").join("_")}>{question.d}</label><br/>
                      </React.Fragment>
                      )
                    })
                  }
              </ol>
              {
                btnDisable ?
                <button className="btn-submit-quiz-Dlc" style={{backgroundColor:" #BDBDBD", border:"1px solid  #BDBDBD"}}>
                  <p>Submit</p>
                </button>
              :
              <button className="btn-submit-quiz-Dlc" type="submit" value="Submit">
                <p>Submit</p>
              </button>
              }
            </form>
        </div>
        <div style={{display: whatToShow != "quiz"? "block" : "none"}}>
          <div className="dekstop">
            <p className="heading-assessment-quiz">{Number(resultQuiz.score) > Number(resultQuiz.minimumScore) ? "Congratulations!" : "You have failed"}</p>
            <p className="heading-assessment-quiz">{Number(resultQuiz.score) > Number(resultQuiz.minimumScore) ? "You have passed the quiz" : "Please retake the quiz"}</p>
            <DlcCircularProgress resultQuiz={resultQuiz}/>
            <div style={{display:'flex', justifyContent:"center", marginTop: "70px"}}>
              <Button onClick={()=>{gotToQuiz()}} className="btn-retake-assessment" variant="contained">
                  <span style={{fontSize:"16px !important"}}>Retake</span>
              </Button>
              <Link to={"/DigitalLearningCenter/"+props.ownProps.match.params.program_id+"/CourseOutline"}>
                  <Button className="btn-continue-assessment" variant="contained">
                      <span style={{fontSize:"16px !important"}}>Continue</span>
                  </Button>
              </Link> 
            </div>           
          </div>
          <div className="mobile">
            <p style={{fontSize:"20px"}} className="heading-assessment-quiz">{Number(resultQuiz.score) > Number(resultQuiz.minimumScore) ? "Congratulations!" : "You have failed"}</p>
            <p style={{fontSize:"20px"}} className="heading-assessment-quiz">{Number(resultQuiz.score) > Number(resultQuiz.minimumScore) ? "You have passed the quiz" : "Please retake the quiz"}</p>
            <DlcCircularProgress resultQuiz={resultQuiz}/>
            <div style={{display:'flex', justifyContent:"center", marginTop: "70px"}}>
                <Button onClick={()=>{gotToQuiz()}} className="btn-retake-assessment" variant="contained">
                    <span style={{fontSize:"16px !important"}}>Retake</span>
                </Button>
                <Link to={"/DigitalLearningCenter/"+props.ownProps.match.params.program_id+"/CourseOutline"}>
                    <Button className="btn-continue-assessment" variant="contained">
                        <span style={{fontSize:"16px !important"}}>Continue</span>
                    </Button>
                </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default DlcQuizCourse;
