import React, { Component } from 'react';
import Maintenance from "../images/maintenance.png"
import logo from '../images/KaryaTalents_logo.png';

class Main extends Component {
    render() {
        return (
            <div className="mtc">
                <div className="boX">
                    <img src={logo} className="log" />
                    <h4  className="HK-Grotesk-Bold flex"> Sorry for the inconvenience, We will back soon! </h4>
                    <p className="HK-Grotesk flex">Maintenance  in progress...</p>
                </div>
                <img src={Maintenance} className="err auto"/>
            </div>
        );
    }
}

export default Main;