import React, { Component } from "react";
import { connect } from "react-redux";
import { putProfileId} from "../_actions/user";
import {getDetailContentDLP} from "../_actions/jobs"
import {findEmail, userCourseDetail} from "../_actions/dlp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import ReactGA from "react-ga";
import {  API_DLP} from "../_redux/helper";
import axios from 'axios';
import $ from "jquery";
import DlcVideoPlaylist from "./DlcVideoPlaylist"
// material ui
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
// icon
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


class _DlcDetailCourse extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: false,
      showStore: false,
      sizeStore: false,
      next_job:"",
      visible: true,
      tooltip:"",
      video_title:"",
      phase:"",
      course_type_id:"",
      cek:"",
      id:"",
      data:[],
      list_phase:sessionStorage.getItem("list-phase").split(","),
      list_id_phase:sessionStorage.getItem("content_id").split(","),
      id_phase:sessionStorage.getItem("phase_id")
    };
  }

  openModal(e) {
     /* eslint eqeqeq: 0 */
    if (e == "link") {
      this.setState({
        link: true,
      });
    }
  }

  closeModal() {
    this.setState({
      link: false,
    });
  }

  setMyCourse = () =>{
    localStorage.setItem("getMyCOurse", "My Courses");
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    const {ownProps} = this.props.ownProps
    const email = localStorage.getItem("email")
    this.setState({ user_id: user_id });
    var tooltip_val = sessionStorage.getItem("tooltip");
    this.setState({ tooltip: tooltip_val });
    this.props.findEmail(email);
    // this.props.userCourseDetail(this.state.id,ownProps.match.params.course_id );
    this.props.getDetailContentDLP(ownProps.match.params.program_id)
    ReactGA.pageview(window.location.pathname + window.location.search);
    const index = ownProps.match.params.index
    let course_type_id = []
    let IdByEmail = sessionStorage.getItem("i-dlp")
    axios.get(`${API_DLP}/api/getusercoursedetail_by_course/${IdByEmail}/${ownProps.match.params.course_id}`)
    .then(({data}) => {
      this.setState({data:data})
      data.map((item,i)=>{
      if (i==index){
        course_type_id.push(item.course_type_id)
        this.setState({video_title : item.course_title})
        this.setState({course_type_id : item.course_type_id})
      }
      })})
      setTimeout(() => {
      const courseModel = this.props.DetailContentDLP1.dataDetailContentDLP
      let list_phase = []
      let id
      course_type_id.map((item)=> id = item)
      courseModel.map((item,i)=>{
          if (item.program_content_id == id){
            this.setState({phase:item.program_content_name})
          this.state.cek = this.state.phase
        }
      })
      }, 1000);
  }


    changeTitle=(index)=>{
      console.log(index,"ini index parentCallBack");
      this.setState({video_title : index})
    }



  render() {
    const {ownProps} = this.props.ownProps
    const index_phase = this.state.list_id_phase.indexOf(`${this.state.course_type_id}`)
    // const {dataUser} = this.props.dataUser1;
    $('.slick-list').css('width','auto');

    return (
      <div>
        <div className="box-wrapper new_Dashboard _DlcDetailCourse">
          {/* DEKSTOP */}
          
          <div className="wrapper flex column">
          <div className="width-100 mobile">
            <div className="box-dlc-content box-menu-mobile-dlp width-100">
                  <div className="tabs-info-container">
                    <div className="tabs-info">
                      <NavLink exact to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseInfo"}>
                        <p>Course Info</p>
                      </NavLink>
                      <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"} style={{backgroundColor:"#3b6c98", borderRadius:"18px"}}>
                        <p style={{color:"white"}} >Course Outline</p>
                      </NavLink>
                      <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/Quiz"}>
                        <p>Quiz</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
          </div>
            <div className="myAs new_Home" id="space0">
              <div className="content" id="download">
                <div className="page-container scrollContent width-100" id="space0">
                  <div className="page-container-outer dtl-dekstop" id="container-print" style={{position:"absolute", marginTop:"79px", width:"90%"}}>
                  <Link to="/DigitalLearningPlatform" onClick={this.setMyCourse()}>
                    <div className="box-logo width-100 logo-btn-justify btn-my-course">
                        <p>❮ My Courses</p>
                    </div>
                  </Link>
                    <div
                      className="box-training-recomended-container width-100 DC"
                      style={{ paddingTop: "0px", marginBottom: "50px" }}
                    >
                      <div className="box-dlc-content training-recomended-container-inner width-100 DLCourse">
                        <div className="pad-dl-course">
                        <div className="tabs-info-container">
                          <div className="tabs-info">
                            <NavLink exact to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseInfo"}>
                              <p>Course Info</p>
                            </NavLink>
                            <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"} style={{backgroundColor:"#3b6c98", borderRadius:"18px"}}>
                              <p style={{color:"white"}} >Course Outline</p>
                            </NavLink>
                            <NavLink to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/Quiz"}>
                              <p>Quiz</p>
                            </NavLink>
                          </div>
                        </div>
                        <p className="border-bottom-tabs fsT"></p>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link color="inherit" to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"}>
                                <p className="breadcrumb-preview-dlp">
                                    Course Outline
                                </p>
                            </Link>
                            <Link color="inherit" to={"/DigitalLearningCenter/"+ownProps.match.params.program_id+"/CourseOutline"}>
                                <p className="breadcrumb-preview-dlp">
                                   {this.state.list_phase[index_phase]}
                                </p>
                            </Link>
                            <Typography color="textPrimary">
                                <p className="breadcrumb-now-dlp">
                                {this.state.video_title}
                                </p> 
                            </Typography>
                        </Breadcrumbs>
                        <p className="border-bottom-tabs"></p>
                          <DlcVideoPlaylist ownProps={ownProps} parentCallback={this.changeTitle} />
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END DEKSTOP */}
        </div>
        <ScrollToTop />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    ownProps:ownProps,
    findEmail1: state.findEmailRed,
    DetailContentDLP1: state.detailContentRed,
    dataUser1: state.userCourseDetailRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    putProfile11: (user_id) =>{
        dispatch(putProfileId(user_id))
    },
    findEmail: (user_id) =>{
      dispatch(findEmail(user_id))
    },
    getDetailContentDLP: (user_id) =>{
      dispatch(getDetailContentDLP(user_id))
    },
    userCourseDetail: (user_id, course_id) =>{
      dispatch(userCourseDetail(user_id, course_id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(_DlcDetailCourse);
