import React, { Component } from "react";
import { connect } from "react-redux";
import searchOpportunity from "../images/searchOpportunity.png";
import location from "../images/location.svg";
import search from "../images/search.svg";
import { getAllCity, jobByTitle, getJobType } from "../_actions/jobs";
import { getBannerById } from "../_actions/article";
import { getIndustri, getJobFunction, getJobLevel } from "../_actions/work";
import { getCountry } from "../_actions/user";
import Loading from "../components/Loading";
import Navbar_Homepage from "../components/Navbar_Homepage";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputRange from "react-input-range";
import ReactGA from "react-ga";
import wallet from "../images/y-wallet.svg";
import func from "../images/y-function.svg";
import industry from "../images/y-industry.svg";
import place from "../images/y-place.svg";
import fulltime from "../images/gold_time.svg";
import TextField from "@material-ui/core/TextField";
import CurrencyInput from "react-currency-input-field";
import "react-input-range/lib/css/index.css";
import { API } from "../_redux/helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPixel from "react-facebook-pixel";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { setDate, priceSplitter, checkId } from "../_redux/function";
import moment from "moment";
var $ = require("jquery");

class componentName extends Component {
  constructor() {
    super();
    this.state = {
      br: 0,
      user_id: "",
      country_id: "",
      city_id: "",
      job_level_id: [],
      industry_id: [],
      job_function_id: [],
      job_title: "",
      job_type: [],
      salary_from: "",
      salary_to: "",
      top: "",
      height: 0,
      theposition: "",
      top_box: 100,
      top_margin: 0,
      revert: false,
      test_id: "1",
      data_reload: "reload",
      value5: {
        min: 0,
        max: 100000000,
      },
      coordinate: {
        x: 0,
        y: 0,
      },
      height_box: 0,
      box1: false,
      box2: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    window.addEventListener("scroll", this.handleScroll);

    var user_id = 1;
    this.setState({ user_id: user_id });
    this.props.getCountry();
    this.props.getIndustri();
    this.props.getJobFunction();
    this.props.getJobLevel();
    this.props.getJobType();
    this.props.getBannerById(1);
    if (sessionStorage.getItem("jobOpportunitySearch") !== null) {
      let checkLast = JSON.parse(
        sessionStorage.getItem("jobOpportunitySearch")
      );
      let mn = [];
      let salary_from_n = 0;
      if (
        checkLast.job_function_id[0] !== null &&
        checkLast.job_function_id.length > 1
      ) {
        checkLast.job_function_id
          .split(",")
          .map((item) => mn.push(parseInt(item)));
      } else {
        mn.push(parseInt(checkLast.job_function_id));
      }
      salary_from_n =
        checkLast.salary_from !== null ? checkLast.salary_from : 0;
      if (checkLast) {
        this.setState({
          country_id: checkLast.country_id,
          city_id: checkLast.city_id,
          job_title: checkLast.job_title,
          job_level_id: checkLast.job_level_id,
          job_function_id: mn,
          industry_id: checkLast.industry_id,
          value5: {
            min: checkLast.salary_from,
            max: checkLast.salary_to,
          },
          job_type: checkLast.job_type,
        });
        this.props.jobByTitle(
          checkLast.user_id,
          checkLast.job_title,
          checkLast.country_id,
          checkLast.city_id,
          checkLast.job_function_id,
          checkLast.industry_id,
          checkLast.job_level_id,
          salary_from_n,
          checkLast.salary_to,
          checkLast.job_type
        );
      } else {
        this.props.jobByTitle(
          user_id,
          this.state.job_title,
          this.state.country_id,
          this.state.city_id,
          this.state.job_function_id,
          this.state.industry_id,
          this.state.job_level_id,
          this.state.salary_from,
          this.state.salary_to,
          this.state.job_type
        );
      }
    } else {
      this.props.jobByTitle(1, "", "", "", "", "", "", 0, 100000000, "");
      this.setState({ job_function_id: [] });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ height: window.innerHeight });
    // window.addEventListener('scroll', this.toScroll);
    this.setCoordinate();
  }

  //   componentWillUnmount() {
  //     window.removeEventListener('scroll', this.listenToScroll)
  //   }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      country_id,
      city_id,
      job_title,
      job_level_id,
      job_function_id,
      industry_id,
      salary_from,
      salary_to,
      job_type,
      value5,
    } = this.state;
    if (
      country_id !== prevState.country_id ||
      job_title !== prevState.job_title ||
      city_id !== prevState.city_id ||
      job_level_id !== prevState.job_level_id ||
      job_function_id !== prevState.job_function_id ||
      industry_id !== prevState.industry_id ||
      salary_from !== prevState.salary_from ||
      salary_to !== prevState.salary_to ||
      job_type !== prevState.job_type
    ) {
      const new_job_function = job_function_id.filter(function (value) {
        return !Number.isNaN(value);
      });
      this.props.jobByTitle(
        1,
        job_title,
        country_id,
        city_id,
        new_job_function,
        this.setArray(industry_id),
        this.setArray(job_level_id),
        value5.min,
        value5.max,
        ""
      );
    }
    if (
      this.state.country_id !== "" &&
      this.state.country_id !== undefined &&
      this.state.country_id !== prevState.country_id
    ) {
      this.props.getAllCity(country_id);
    }
  }

  setArray = (e) => {
    let n;
    if (e.length > 0) {
      n = e;
    } else {
      n = "";
    }
    return n;
  };

  toScroll = (e) => {
    const height_box = $(".job_opportunity .left_box").height();
    const height_window = window.innerHeight;

    if (height_window > height_box) {
      this.setState({ top_box: 100 });
      // this.setState({ top_margin: 0 })
      this.setState({ top_visible: true });
    } else {
      this.setState({ top_box: height_box - height_window });
      this.setState({ top_visible: false });
    }
  };

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const scrolled = winScroll;

    this.setState({
      theposition: scrolled,
    });
  };

  changeCountry = (event, values) => {
    this.setState({ data_reload: "" });
    let cek_id;
    if (values === undefined || values === "" || values === null) {
      cek_id = "";
    } else {
      cek_id = values.id;
    }
    this.setState({ country_id: cek_id });
  };
  changeCity = (event, values) => {
    this.setState({ data_reload: "" });
    let cek_id;
    if (values === undefined || values === "" || values === null) {
      cek_id = "";
    } else {
      cek_id = values.id;
    }
    this.setState({ city_id: cek_id });
  };
  changeIndustry = (event) => {
    this.setState({ data_reload: "" });
    let industry_value;
    if (event === this.state.industry_id) {
      industry_value = "";
    } else {
      industry_value = event;
    }
    const cek1 = [...this.state.industry_id];
    const index = cek1.indexOf(industry_value);
    if (index === -1) {
      cek1.push(industry_value);
    } else {
      cek1.splice(index, 1);
    }
    this.setState({ industry_id: cek1 });
  };

  changeJobLevel = (event) => {
    this.setState({ data_reload: "" });
    let level_job;
    if (event === this.state.job_level_id) {
      level_job = "";
    } else {
      level_job = event;
    }
    const cek1 = [...this.state.job_level_id];
    const index = cek1.indexOf(level_job);
    if (index === -1) {
      cek1.push(level_job);
    } else {
      cek1.splice(index, 1);
    }
    this.setState({ job_level_id: cek1 });
  };

  changeJobFunction = (event) => {
    this.setState({ data_reload: "" });
    let value_job;
    if (event === this.state.job_function_id) {
      value_job = "";
    } else {
      value_job = event;
    }
    const cek1 = [...this.state.job_function_id];
    const index = cek1.indexOf(value_job);
    if (index === -1) {
      cek1.push(value_job);
    } else {
      cek1.splice(index, 1);
    }
    this.setState({ job_function_id: cek1 });
    const event4 = cek1.toString();
  };
  onKeyDown = (e) => {
    if (e.key || e.key === "Enter") {
      this.setState({ data_reload: "" });
      const {
        country_id,
        city_id,
        user_id,
        job_title,
        job_level_id,
        job_function_id,
        industry_id,
        salary_from,
        salary_to,
        job_type,
      } = this.state;
      ReactPixel.track("Filter Job - /JobOpportunities", "Filter Job");
      this.props.jobByTitle(
        user_id,
        e.target.value,
        country_id,
        city_id,
        job_function_id,
        industry_id,
        job_level_id,
        salary_from,
        salary_to,
        job_type
      );
    }
  };
  changeTitle = (event) => {
    this.setState({ job_title: event.target.value });
    sessionStorage.setItem("setupTime", new Date().getTime());
  };
  searchTitle = (e) => {
    e.preventDefault();
    this.setState({ data_reload: "" });
    const country_id = this.state.country_id;
    const city_id = this.state.city_id;
    const user_id = this.state.user_id;
    const job_title = this.state.job_title;
    const job_level_id = this.state.job_level_id;
    const job_function_id = this.state.job_function_id;
    const industry_id = this.state.industry_id;
    const salary_from = this.state.value5.min;
    const salary_to = this.state.value5.max;
    const job_type = this.state.job_type;
    ReactPixel.track("Filter Job - /JobOpportunities", "Filter Job");
    this.props.jobByTitle(1, job_title, "", "", "", "", "", "", "", "");
  };
  changeSalaryFrom = (event) => {
    this.setState({ data_reload: "" });
    var reg = /^\d+$/;
    if (reg.test(event) === true || event === " " || event === undefined) {
      this.setState({ value5: { min: event, max: this.state.value5.max } });
    }
    sessionStorage.setItem("setupTime", new Date().getTime());
    const country_id = this.state.country_id;
    const city_id = this.state.city_id;
    const user_id = this.state.user_id;
    const job_title = this.state.job_title;
    const job_level_id = this.state.job_level_id;
    const job_function_id = this.state.job_function_id;
    const industry_id = this.state.industry_id;
    const salary_from = event;
    const salary_to = this.state.value5.max;
    const job_type = this.state.job_type;
    ReactPixel.track("Filter Job - /JobOpportunities", "Filter Job");
    this.props.jobByTitle(
      user_id,
      job_title,
      country_id,
      city_id,
      job_function_id,
      industry_id,
      job_level_id,
      salary_from,
      salary_to,
      job_type
    );
  };

  changeSalaryTo = (event) => {
    this.setState({ data_reload: "" });
    var reg = /^\d+$/;
    if (reg.test(event) === true || event === "" || event === undefined) {
      this.setState({ value5: { min: this.state.value5.min, max: event } });
    }
    sessionStorage.setItem("setupTime", new Date().getTime());
    const country_id = this.state.country_id;
    const city_id = this.state.city_id;
    const user_id = this.state.user_id;
    const job_title = this.state.job_title;
    const job_level_id = this.state.job_level_id;
    const job_function_id = this.state.job_function_id;
    const industry_id = this.state.industry_id;
    const salary_from = this.state.value5.min;
    const salary_to = event;
    const job_type = this.state.job_type;
    ReactPixel.track("Filter Job - /JobOpportunities", "Filter Job");
    this.props.jobByTitle(
      user_id,
      job_title,
      country_id,
      city_id,
      job_function_id,
      industry_id,
      job_level_id,
      salary_from,
      salary_to,
      job_type
    );
  };

  changeSalary = (value) => {
    this.setState({ value5: value });
  };
  changeType = (event) => {
    this.setState({ data_reload: "" });
    let type_value;
    if (event === this.state.job_type) {
      type_value = "";
    } else {
      type_value = event;
    }
    const cek1 = [...this.state.job_type];
    const index = cek1.indexOf(type_value);
    if (index === -1) {
      cek1.push(type_value);
    } else {
      cek1.splice(index, 1);
    }
    this.setState({ job_type: cek1 });
    sessionStorage.setItem("setupTime", new Date().getTime());
    const country_id = this.state.country_id;
    const city_id = this.state.city_id;
    const user_id = this.state.user_id;
    const job_title = this.state.job_title;
    const job_level_id = this.state.job_level_id;
    const job_function_id = this.state.job_function_id;
    const industry_id = this.state.industry_id;
    const salary_from = this.state.value5.min;
    const salary_to = this.state.value5.max;
    const job_type = cek1;
    ReactPixel.track("Filter Job - /JobOpportunities", "Filter Job");
    this.props.jobByTitle(
      user_id,
      job_title,
      country_id,
      city_id,
      job_function_id,
      industry_id,
      job_level_id,
      salary_from,
      salary_to,
      job_type
    );
  };

  reset = () => {
    window.location.reload();
  };

  changeRevert = (e) => {
    this.setState({ revert: !this.state.revert, top: e.pageY });
  };

  completeChange = (e) => {
    const country_id = this.state.country_id;
    const city_id = this.state.city_id;
    const user_id = this.state.user_id;
    const job_title = this.state.job_title;
    const job_level_id = this.state.job_level_id;
    const job_function_id = this.state.job_function_id;
    const industry_id = this.state.industry_id;
    const salary_from = e.min;
    const salary_to = e.max;
    const job_type = this.state.job_type;
    ReactPixel.track("Filter Job - /JobOpportunities", "Filter Job");
    this.props.jobByTitle(
      user_id,
      job_title,
      country_id,
      city_id,
      job_function_id,
      industry_id,
      job_level_id,
      salary_from,
      salary_to,
      job_type
    );
  };

  setCoordinate = () => {
    setTimeout(() => {
      if (
        sessionStorage.getItem("position") != null &&
        sessionStorage.getItem("check_position") != null
      ) {
        const y = parseInt(sessionStorage.getItem("position"));
        window.scrollTo(0, y);
      } else {
        window.scrollTo(0, 0);
      }
    }, 500);
  };

  setPosition = (e) => {
    const val = this.state.coordinate;
    const val_y = parseInt(e.pageY) - 540;
    val["x"] = e.clientX;
    val["y"] = val_y;
    this.setState({ val });
    sessionStorage.setItem("position", val_y);
    sessionStorage.setItem("check_position", true);
    sessionStorage.setItem(
      "jobOpportunitySearch",
      JSON.stringify({
        country_id: this.state.country_id,
        city_id: this.state.city_id,
        user_id: this.state.user_id,
        job_title: this.state.job_title,
        job_level_id: this.state.job_level_id,
        job_function_id: this.state.job_function_id,
        industry_id: this.state.industry_id,
        salary_from: this.state.value5.min,
        salary_to: this.state.value5.max,
        job_type: this.state.job_type,
      })
    );
  };

  changeBox = (e) => {
    const height = parseInt(window.innerHeight) - 140;
    this.setState({ height_box: height, box1: true });
  };

  _onMouseMove = (e) => {
    this.setState({ box1: true, box2: false });
    // document.getElementsByTagName('html')[0].style.overflow = "hidden";
  };
  _onMouseMoveRight = (e) => {
    this.setState({ height_box: 0 });
    // document.getElementsByTagName('html')[0].style.overflow = "auto";
  };

  handleScroll = (event) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (parseFloat(winScroll) < 250) {
      const height = document.getElementById("container-u").clientHeight;
      this.setState({ height_: height });
      this.setState({ br: scrolled });
    }
  };

  render() {
    const { dataCountry } = this.props.country1;
    const { allCity } = this.props.allCity1;
    const { dataIndustri } = this.props.industri1;
    const { dataJobLevel } = this.props.jobLevel1;
    const { dataJobFunction } = this.props.jobFunction1;
    const { dataJobByTitle } = this.props.jobByTitle1;
    const { dataJobType } = this.props.jobType1;
    const { dataBannerById, isLoading, error } = this.props.bannerId1;
    const {
      country_id,
      city_id,
      job_title,
      job_level_id,
      job_function_id,
      industry_id,
      job_type,
      salary_from,
      salary_to,
      value5,
    } = this.state;
    const listData = dataCountry.map((item, i) => ({
      title: item.country_name,
    }));
    var index = listData.indexOf(5);
    if (index > -1) {
      listData.splice(index, 1);
    }

    // Respon jika hasil job tidak ada (kosong)
    let Response;
    /* eslint eqeqeq: 0 */
    if (
      this.state.data_reload == "" &&
      (this.state.data_reload == undefined ||
        this.state.data_reload == null ||
        dataJobByTitle.length == 0)
    ) {
      Response = "Job Not Found";
    } else if (
      (this.state.data_reload !== "reload" &&
        (dataJobByTitle != "" ||
          dataJobByTitle != undefined ||
          dataJobByTitle != null)) ||
      dataJobByTitle.length > 0
    ) {
      Response = "";
    } else {
      Response = "Please wait ...";
    }

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    const { br } = this.state;

    let st_dis = false;
    if (br === 0) {
      st_dis = false;
    } else {
      st_dis = true;
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    const hg_set = parseInt(window.innerHeight) - 150;
    return (
      <div>
        <div className=" flex column width-100 Hp-22 _s bg-209">
          <Navbar_Homepage status="bg-ff fsh_" />
          <div className="job_opportunity unv_">
            <div className="" id="container-u">
              <div
                className="page-container  width-100"
                style={{ marginTop: "80px" }}
              >
                <div className="page-container-outer job-summary dashboard">
                  <div className="box-logo width-100">
                    <h1
                      className="titleSpartan none"
                      onClick={this.setCoordinate}
                    >
                      Job Opportunities
                    </h1>
                  </div>
                  <div className="flex wd-webkit br-10 bg-ff p-4 mb-28 ">
                    <LazyLoadImage
                      effect="blur"
                      loading="lazy"
                      alt=""
                      src={`${API}/File/Banner/${dataBannerById.photo}`}
                      style={{
                        width: "100%",
                        height: "114px",
                        objectFit: "cover",
                        borderRadius: "6px",
                        filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))",
                      }}
                    />
                  </div>

                  <div className="page-container-inner width-100 dekstop">
                    <div className="box-edu-container width-100">
                      <div className="flex width-100">
                        {/* <div className="left_box" id='lf-box'  onFocus={this.changeBox} style={{height: height_box !=0 ?height_box:"",
                                        overflow: box1 ?"auto":"", width: box1 ?"415px":""}} onMouseMove={this._onMouseMove}> */}
                        <div
                          className="left_box"
                          id="lf-box"
                          //  onFocus={this.changeBox}  onMouseMove={this._onMouseMove}
                        >
                          <div
                            className="width-100  overflow hg-set"
                            style={{ height: `${hg_set}px` }}
                          >
                            <div className="flex width-100 space-between">
                              <p className="title_left">Filter</p>
                              <p
                                className="title_right pointer"
                                onClick={(e) => this.reset(e)}
                              >
                                Reset
                              </p>
                            </div>
                            <div
                              className="width-100"
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "14px 0px 0px",
                              }}
                            />
                            <div className="width-100 flex">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  onClick={this.changeRevert}
                                  id="panel1a-header"
                                >
                                  <p
                                    className="title_left"
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    Job Function
                                  </p>
                                </AccordionSummary>
                                {dataJobFunction.map((item, i) => (
                                  <div
                                    key={i}
                                    className="width-100 flex checkbox_opp"
                                  >
                                    <input
                                      type="checkbox"
                                      value={item.id}
                                      className="checkk"
                                      onClick={(e) => {
                                        this.changeJobFunction(item.id);
                                      }}
                                      checked={checkId(
                                        item.id,
                                        this.state.job_function_id
                                      )}
                                    />
                                    <p
                                      onClick={(e) => {
                                        this.changeJobFunction(item.id);
                                      }}
                                    >
                                      {item.job_function_name}
                                    </p>
                                  </div>
                                ))}
                              </Accordion>
                            </div>
                            <div
                              className="width-100"
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "0px",
                              }}
                            />
                            <div className="width-100 flex">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  id="panel1a-header"
                                  onClick={this.changeRevert}
                                >
                                  <p
                                    className="title_left"
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    Job Level
                                  </p>
                                </AccordionSummary>
                                {dataJobLevel.map((item, i) => (
                                  <div
                                    key={i}
                                    className="width-100 flex checkbox_opp"
                                  >
                                    <input
                                      type="checkbox"
                                      value={item.id}
                                      className="job-level-check"
                                      onChange={(e) => {
                                        this.changeJobLevel(item.id);
                                      }}
                                      checked={checkId(
                                        item.id,
                                        this.state.job_level_id
                                      )}
                                    />
                                    <p
                                      onClick={(e) => {
                                        this.changeJobLevel(item.id);
                                      }}
                                    >
                                      {item.job_level_name}
                                    </p>
                                  </div>
                                ))}
                              </Accordion>
                            </div>
                            <div
                              className="width-100"
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "0px",
                              }}
                            />
                            <div className="width-100 flex">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  onClick={this.changeRevert}
                                  id="panel1a-header"
                                >
                                  <p
                                    className="title_left"
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    Industry
                                  </p>
                                </AccordionSummary>
                                {dataIndustri.map((item, i) => (
                                  <div
                                    key={i}
                                    className="width-100 flex checkbox_opp"
                                  >
                                    <input
                                      type="checkbox"
                                      value={item.id}
                                      className="industry-check"
                                      onChange={(e) => {
                                        this.changeIndustry(item.id);
                                      }}
                                      checked={checkId(
                                        item.id,
                                        this.state.industry_id
                                      )}
                                    />
                                    <p
                                      onClick={(e) => {
                                        this.changeIndustry(item.id);
                                      }}
                                    >
                                      {item.industry_name}
                                    </p>
                                  </div>
                                ))}
                              </Accordion>
                            </div>
                            <div
                              className="width-100"
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "0px 0px 14px",
                              }}
                            />

                            {/* <div className="width-100" style={{borderBottom:"1px solid #E0E0E0", margin:"14px 0px 0px"}}/>   */}
                            <div className="flex width-100 column">
                              <p
                                className="title_left"
                                style={{ fontWeight: "500" }}
                              >
                                Salary Range
                              </p>
                              <div className="flex width-100">
                                <CurrencyInput
                                  id="input-example"
                                  className="width-50"
                                  name="input-name"
                                  placeholder="IDR 0"
                                  defaultValue="0"
                                  value={
                                    this.state.value5.min === 0
                                      ? priceSplitter(this.state.value5.min)
                                      : this.state.value5.min
                                  }
                                  allowDecimals={true}
                                  onChange={this.changeSalaryFrom}
                                  ref={this.input}
                                  decimalsLimit={2}
                                  style={{ marginRight: "10px" }}
                                />

                                <CurrencyInput
                                  id="input-example"
                                  className="width-50"
                                  name="input-name"
                                  placeholder="IDR 100,000,000"
                                  value={
                                    this.state.value5.max === 0
                                      ? priceSplitter(this.state.value5.max)
                                      : this.state.value5.max
                                  }
                                  allowDecimals={true}
                                  onChange={this.changeSalaryTo}
                                  ref={this.input}
                                  decimalsLimit={2}
                                  style={{ marginLeft: "10px" }}
                                />
                              </div>
                            </div>
                            <div className="flex width-100 rangeSalary">
                              <InputRange
                                draggableTrack
                                ref="react_input_range"
                                maxValue={100000000}
                                minValue={0}
                                step={100000}
                                value={this.state.value5}
                                onChange={this.changeSalary}
                                onChangeComplete={this.completeChange}
                                formatLabel={(a) => a}
                              />
                            </div>
                            <div
                              className="width-100"
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "14px 0px 0px",
                              }}
                            />
                            <div className="flex width-100 colum none">
                              <p
                                className="title_left"
                                style={{ fontWeight: "500" }}
                              >
                                Employment Status
                              </p>
                              {/* <input type="text" placeholder="Input Industry" /> */}
                              <select onChange={this.changeType}>
                                <option
                                  disabled
                                  selected
                                  style={{
                                    display: "none",
                                    color: "#828282",
                                    fontWeight: "500px",
                                  }}
                                >
                                  (choose)
                                </option>
                                {dataJobType.map((item, i) => (
                                  <option key={i} value={item.id}>
                                    {item.job_type_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="width-100 flex">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  id="panel1a-header"
                                >
                                  <p
                                    className="title_left"
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    Employment Status
                                  </p>
                                </AccordionSummary>
                                {dataJobType.map((item, i) => (
                                  <div
                                    key={i}
                                    className="width-100 flex checkbox_opp"
                                  >
                                    <input
                                      type="checkbox"
                                      value={item.id}
                                      className="industry-check"
                                      onChange={(e) => {
                                        this.changeType(item.id);
                                      }}
                                      checked={
                                        item.id ===
                                          parseInt(this.state.job_type[0]) ||
                                        item.id ===
                                          parseInt(this.state.job_type[1]) ||
                                        item.id ===
                                          parseInt(this.state.job_type[2])
                                          ? true
                                          : false
                                      }
                                    />
                                    <p
                                      onClick={(e) => {
                                        this.changeType(item.id);
                                      }}
                                    >
                                      {item.job_type_name}
                                    </p>
                                  </div>
                                ))}
                              </Accordion>
                            </div>
                            <div
                              className="width-100"
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "0px 0px 0px",
                              }}
                            />
                            <div className="flex width-100 column">
                              <p
                                className="title_left"
                                style={{ fontWeight: "500" }}
                              >
                                Location
                              </p>
                              <div className="flex column none width-100">
                                <select onClick={this.changeCountry}>
                                  <option
                                    disabled
                                    selected
                                    style={{
                                      display: "none",
                                      color: "#828282",
                                      fontWeight: "500px",
                                    }}
                                  >
                                    Coba2
                                  </option>
                                  {dataCountry.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.country_name}{" "}
                                    </option>
                                  ))}
                                </select>
                                <select onClick={this.changeCity}>
                                  <option
                                    disabled
                                    selected
                                    style={{
                                      display: "none",
                                      color: "#828282",
                                      fontWeight: "500px",
                                    }}
                                  >
                                    City
                                  </option>
                                  {allCity.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.city_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="width-100 flex autocomplete">
                                <Autocomplete
                                  id="combo-box-demo width-100"
                                  onChange={this.changeCountry}
                                  options={dataCountry.map((x, i) => x)}
                                  getOptionLabel={(option) =>
                                    option.country_name
                                  }
                                  style={{ width: 300 }}
                                  renderInput={(params) => {
                                    if (this.state.country_id) {
                                      dataCountry.map((x) => {
                                        if (x.id == this.state.country_id)
                                          params.inputProps.value =
                                            x.country_name;
                                      });
                                      return (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                        />
                                      );
                                    } else {
                                      return (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          placeholder="Country"
                                        />
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <div className="width-100 flex autocomplete">
                                <Autocomplete
                                  id="combo-box-demo width-100"
                                  onChange={this.changeCity}
                                  options={allCity.map((x, i) => x)}
                                  getOptionLabel={(option) => option.city_name}
                                  style={{ width: 300 }}
                                  renderInput={(params) => {
                                    if (this.state.city_id) {
                                      allCity.map((x) => {
                                        if (x.id == this.state.city_id)
                                          params.inputProps.value = x.city_name;
                                      });
                                      return (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                        />
                                      );
                                    } else {
                                      return (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          placeholder="City"
                                        />
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="right_box width-100 _c"
                          onMouseMove={this._onMouseMoveRight}
                        >
                          <div className="first_right_box width-100">
                            <div className="flex width-100 space-between sc">
                              {this.state.job_title ? (
                                <input
                                  type="text"
                                  className="width-85"
                                  onChange={this.changeTitle}
                                  onKeyDown={this.onKeyDown}
                                  refs="job_title"
                                  placeholder="Search job by title or keyword"
                                  value={this.state.job_title}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="width-85"
                                  onChange={this.changeTitle}
                                  onKeyDown={this.onKeyDown}
                                  refs="job_title"
                                  placeholder="Search job by title or keyword"
                                />
                              )}
                              <button
                                className="flex"
                                type="submit"
                                onClick={this.searchTitle}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ marginRight: "14px" }}
                                >
                                  <g clip-path="url(#clip0_10526_429)">
                                    <path
                                      d="M17.877 16.4848L13.609 12.2168C13.5281 12.1359 13.4227 12.0938 13.3102 12.0938H12.8461C13.9535 10.8105 14.625 9.14062 14.625 7.3125C14.625 3.27305 11.352 0 7.3125 0C3.27305 0 0 3.27305 0 7.3125C0 11.352 3.27305 14.625 7.3125 14.625C9.14062 14.625 10.8105 13.9535 12.0938 12.8461V13.3102C12.0938 13.4227 12.1395 13.5281 12.2168 13.609L16.4848 17.877C16.65 18.0422 16.9172 18.0422 17.0824 17.877L17.877 17.0824C18.0422 16.9172 18.0422 16.65 17.877 16.4848ZM7.3125 12.9375C4.20469 12.9375 1.6875 10.4203 1.6875 7.3125C1.6875 4.20469 4.20469 1.6875 7.3125 1.6875C10.4203 1.6875 12.9375 4.20469 12.9375 7.3125C12.9375 10.4203 10.4203 12.9375 7.3125 12.9375Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_10526_429">
                                      <rect
                                        width="18"
                                        height="18"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p>Search</p>
                              </button>
                            </div>
                          </div>
                          <div className="second_right_box width-100">
                            {dataJobByTitle.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="box_item width-100"
                                  onClick={this.setPosition}
                                >
                                  <div className="flex width-100">
                                    <div className="profil_picture">
                                      {item.photo && (
                                        <LazyLoadImage
                                          effect="blur"
                                          loading="lazy"
                                          alt=""
                                          src={`${API}/File/Job_Opportunity/${item.photo}`}
                                        />
                                      )}
                                    </div>
                                    <div className="flex column width-100 content">
                                      <div className="flex width-100 space-between">
                                        <p className="left">
                                          {item.company === null
                                            ? item.alias_text
                                            : item.company}{" "}
                                        </p>
                                        <div
                                          className="flex loc"
                                          style={{ display: "none" }}
                                        >
                                          <LazyLoadImage
                                            effect="blur"
                                            loading="lazy"
                                            alt=""
                                            src={location}
                                          />
                                          <p className="right">{item.city}</p>
                                        </div>
                                      </div>
                                      <div
                                        className="width-100 flex"
                                        style={{
                                          height: "24px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        <p className="job_title">
                                          {item.job_title}{" "}
                                        </p>
                                      </div>
                                      <p
                                        className="time"
                                        style={{ display: "none" }}
                                      >
                                        Full Time
                                      </p>
                                    </div>
                                  </div>
                                  {/* <p className="width-100 job_desc">{slc}</p> */}
                                  <p className="width-100 job_desc">
                                    {item.description !== null
                                      ? item.description.slice(0, 200) + "..."
                                      : ""}
                                  </p>
                                  <div className="width-100 flex column industry">
                                    {item.industry && (
                                      <div className="flex width-100 list_industry">
                                        <LazyLoadImage
                                          effect="blur"
                                          loading="lazy"
                                          alt=""
                                          src={industry}
                                        />
                                        <p>{item.industry} </p>
                                      </div>
                                    )}
                                    {item.job_level && (
                                      <div className="flex width-100 list_industry">
                                        <LazyLoadImage
                                          effect="blur"
                                          loading="lazy"
                                          alt=""
                                          src={func}
                                        />
                                        <p>{item.job_level} </p>
                                      </div>
                                    )}
                                    <div className="flex width-100 list_industry">
                                      <LazyLoadImage
                                        effect="blur"
                                        loading="lazy"
                                        alt=""
                                        src={wallet}
                                      />
                                      <p>
                                        IDR{" "}
                                        {priceSplitter(item.salary_from * 1)} -{" "}
                                        {priceSplitter(item.salary_to * 1)}
                                      </p>
                                    </div>
                                    {item.job_type && (
                                      <div className="flex width-100 list_industry">
                                        <LazyLoadImage
                                          effect="blur"
                                          loading="lazy"
                                          alt=""
                                          src={fulltime}
                                        />
                                        <p>{item.job_type} </p>
                                      </div>
                                    )}
                                    {item.city && (
                                      <div className="flex width-100 list_industry">
                                        <LazyLoadImage
                                          effect="blur"
                                          loading="lazy"
                                          alt=""
                                          src={place}
                                          style={{ margin: "0px 12px 0px 3px" }}
                                        />
                                        <p>{item.city}</p>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className="width-100"
                                    style={{
                                      border: "1px solid #F2F2F2",
                                      marginBottom: "15px",
                                    }}
                                  ></div>
                                  <div className="flex width-100 space-between">
                                    <div className="flex  list_industry">
                                      <p
                                        id="created-time-count"
                                        className="c-72 s-1419 openSans auto-00"
                                      >
                                        {setDate(item.createdAt)}
                                        {parseInt(setDate(item.createdAt)) > 1
                                          ? "s"
                                          : ""}{" "}
                                        ago{" "}
                                      </p>
                                    </div>
                                    <div className="flex ">
                                      <a
                                        className="mr-10 none"
                                        href="/Referral"
                                      >
                                        <button
                                          className="btn_job c-36 bg-ff bd-ea w-400"
                                          onClick={this.setPosition}
                                        >
                                          Refer friend
                                        </button>
                                      </a>
                                      <a
                                        href={`/JobDetail_Public/${
                                          item.job_opportunity_id
                                        }/${item.job_title
                                          .split(" ")
                                          .join("-")}`}
                                      >
                                        <button
                                          className="btn_job bg-36 c-ff bd-36 bold"
                                          onClick={this.setPosition}
                                        >
                                          View Job
                                        </button>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <h3 className="width-100 center">{Response} </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile width-100">
                    <div className="page-container-inner width-100">
                      <div className="box-edu-container width-100">
                        <div className="flex column width-100">
                          <div
                            className="first_right_box width-100"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="flex width-100 space-between flex-pad">
                              <input
                                type="text"
                                className="width-85"
                                onChange={this.changeTitle}
                                onKeyDown={this.onKeyDown}
                                placeholder="Search job by title or keyword"
                                refs="job_title"
                              />
                              <button
                                className="flex"
                                type="submit"
                                onClick={this.searchTitle}
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  loading="lazy"
                                  alt=""
                                  src={search}
                                />
                                {/* <p>Search</p> */}
                              </button>
                              <LazyLoadImage
                                effect="blur"
                                loading="lazy"
                                alt=""
                                src={searchOpportunity}
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                          <div className="left_box width-100">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div className="flex width-100 space-between">
                                  <p className="title_left">Filter</p>
                                  <p
                                    className="title_right pointer"
                                    onClick={(e) => this.reset(e)}
                                  >
                                    Reset
                                  </p>
                                </div>
                              </AccordionSummary>
                              <div
                                className="width-100"
                                style={{
                                  borderBottom: "1px solid #E0E0E0",
                                  margin: "14px 0px 0px",
                                }}
                              />
                              <div className="width-100 flex">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header"
                                  >
                                    <p
                                      className="title_left"
                                      style={{
                                        fontWeight: "500",
                                        marginBottom: "0px",
                                        marginTop: "0px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      Job Function
                                    </p>
                                  </AccordionSummary>
                                  {dataJobFunction.map((item, i) => (
                                    <div
                                      key={i}
                                      className="width-100 flex checkbox_opp"
                                    >
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        name="check"
                                        className="checkk"
                                        onChange={(e) => {
                                          this.changeJobFunction(item.id);
                                        }}
                                        checked={checkId(
                                          item.id,
                                          this.state.job_function_id
                                        )}
                                      />
                                      <p
                                        onClick={(e) => {
                                          this.changeJobFunction(item.id);
                                        }}
                                      >
                                        {item.job_function_name}
                                      </p>
                                    </div>
                                  ))}
                                </Accordion>
                              </div>
                              <div
                                className="width-100"
                                style={{
                                  borderBottom: "1px solid #E0E0E0",
                                  margin: "0px",
                                }}
                              />
                              <div className="width-100 flex">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header"
                                  >
                                    <p
                                      className="title_left"
                                      style={{
                                        fontWeight: "500",
                                        marginBottom: "0px",
                                        marginTop: "0px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      Job Level
                                    </p>
                                  </AccordionSummary>
                                  {dataJobLevel.map((item, i) => (
                                    <div
                                      key={i}
                                      className="width-100 flex checkbox_opp"
                                    >
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        className="job-level-check"
                                        onChange={(e) => {
                                          this.changeJobLevel(item.id);
                                        }}
                                        checked={checkId(
                                          item.id,
                                          this.state.job_level_id
                                        )}
                                      />
                                      <p
                                        onClick={(e) => {
                                          this.changeJobLevel(item.id);
                                        }}
                                      >
                                        {item.job_level_name}
                                      </p>
                                    </div>
                                  ))}
                                </Accordion>
                              </div>
                              <div
                                className="width-100"
                                style={{
                                  borderBottom: "1px solid #E0E0E0",
                                  margin: "0px",
                                }}
                              />
                              <div className="width-100 flex">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header"
                                  >
                                    <p
                                      className="title_left"
                                      style={{
                                        fontWeight: "500",
                                        marginBottom: "0px",
                                        marginTop: "0px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      Industry
                                    </p>
                                  </AccordionSummary>
                                  {dataIndustri.map((item, i) => (
                                    <div
                                      key={i}
                                      className="width-100 flex checkbox_opp"
                                    >
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        className="industry-check"
                                        onChange={(e) => {
                                          this.changeIndustry(item.id);
                                        }}
                                        checked={checkId(
                                          item.id,
                                          this.state.industry_id
                                        )}
                                      />
                                      <p
                                        onClick={(e) => {
                                          this.changeIndustry(item.id);
                                        }}
                                      >
                                        {item.industry_name}
                                      </p>
                                    </div>
                                  ))}
                                </Accordion>
                              </div>
                              <div
                                className="width-100"
                                style={{
                                  borderBottom: "1px solid #E0E0E0",
                                  margin: "0px 0px 14px",
                                }}
                              />
                              {/* <div className="width-100" style={{borderBottom:"1px solid #E0E0E0", margin:"14px 0px 0px"}}/>   */}
                              <div className="flex width-100 column">
                                <p
                                  className="title_left"
                                  style={{ fontWeight: "500" }}
                                >
                                  Salary Range
                                </p>
                                <div className="flex width-100">
                                  {/* <input className="width-50" type="text" placeholder="Minimum" ref={this.input} value={`${priceSplitter(this.state.value5.min)}`} onChange={this.changeSalaryFrom} />
                                                                <input className="width-50" type="text" placeholder="Maximum"  ref={this.input} value={`${priceSplitter(this.state.value5.max)}`} onChange={this.changeSalaryTo}/> */}
                                  <CurrencyInput
                                    id="input-example"
                                    className="width-50"
                                    name="input-name"
                                    placeholder="IDR 0"
                                    defaultValue="0"
                                    value={
                                      this.state.value5.min === 0
                                        ? priceSplitter(this.state.value5.min)
                                        : this.state.value5.min
                                    }
                                    allowDecimals={true}
                                    onChange={this.changeSalaryFrom}
                                    ref={this.input}
                                    decimalsLimit={2}
                                    style={{ marginRight: "10px" }}
                                  />

                                  <CurrencyInput
                                    id="input-example"
                                    className="width-50"
                                    name="input-name"
                                    placeholder="IDR 100,000,000"
                                    value={
                                      this.state.value5.max === 0
                                        ? priceSplitter(this.state.value5.max)
                                        : this.state.value5.max
                                    }
                                    allowDecimals={true}
                                    onChange={this.changeSalaryTo}
                                    ref={this.input}
                                    decimalsLimit={2}
                                    style={{ marginLeft: "10px" }}
                                  />
                                </div>
                              </div>
                              <div className="flex width-100 rangeSalary">
                                <InputRange
                                  draggableTrack
                                  maxValue={100000000}
                                  minValue={0}
                                  step={100000}
                                  value={this.state.value5}
                                  onChange={this.changeSalary}
                                  onChangeComplete={this.completeChange}
                                />
                              </div>
                              <div
                                className="width-100"
                                style={{
                                  borderBottom: "1px solid #E0E0E0",
                                  margin: "0px 0px 0px",
                                }}
                              />
                              <div className="width-100 flex">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header"
                                  >
                                    <p
                                      className="title_left"
                                      style={{
                                        fontWeight: "500",
                                        marginBottom: "0px",
                                        marginTop: "0px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      Employment Status
                                    </p>
                                  </AccordionSummary>
                                  {dataJobType.map((item, i) => (
                                    <div
                                      key={i}
                                      className="width-100 flex checkbox_opp"
                                    >
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        className="industry-check"
                                        onChange={(e) => {
                                          this.changeType(item.id);
                                        }}
                                        checked={
                                          item.id ===
                                            parseInt(this.state.job_type[0]) ||
                                          item.id ===
                                            parseInt(this.state.job_type[1]) ||
                                          item.id ===
                                            parseInt(this.state.job_type[2])
                                            ? true
                                            : false
                                        }
                                      />
                                      <p
                                        onClick={(e) => {
                                          this.changeType(item.id);
                                        }}
                                      >
                                        {item.job_type_name}
                                      </p>
                                    </div>
                                  ))}
                                </Accordion>
                              </div>
                              <div className="flex width-100 column none">
                                <p
                                  className="title_left"
                                  style={{ fontWeight: "500" }}
                                >
                                  Employment Status
                                </p>
                                {/* <input type="text" placeholder="Input Industry" /> */}
                                <select
                                  onChange={(e) => {
                                    this.changeType(e.target.value);
                                  }}
                                >
                                  <option
                                    disabled
                                    selected
                                    style={{
                                      display: "none",
                                      color: "#828282",
                                      fontWeight: "500px",
                                    }}
                                  >
                                    (choose)
                                  </option>
                                  {dataJobType.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.job_type_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div
                                className="width-100"
                                style={{
                                  borderBottom: "1px solid #E0E0E0",
                                  margin: "0px 0px 14px",
                                }}
                              />
                              <p
                                className="title_left"
                                style={{ fontWeight: "500" }}
                              >
                                Location
                              </p>
                              <div
                                className="flex column width-100"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div className="width-100 flex autocomplete">
                                  <Autocomplete
                                    id="combo-box-demo width-100"
                                    onChange={this.changeCountry}
                                    options={dataCountry.map((x, i) => x)}
                                    getOptionLabel={(option) =>
                                      option.country_name
                                    }
                                    style={{ width: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Country"
                                      />
                                    )}
                                  />
                                </div>
                                <div className="width-100 flex autocomplete">
                                  <Autocomplete
                                    id="combo-box-demo width-100"
                                    onChange={this.changeCity}
                                    options={allCity.map((x, i) => x)}
                                    getOptionLabel={(option) =>
                                      option.city_name
                                    }
                                    style={{ width: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="City"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </Accordion>
                          </div>
                          <div className="right_box width-100">
                            <div className="second_right_box width-100">
                              {dataJobByTitle.map((item, i) => {
                                return (
                                  <div key={i} className="box_item flex">
                                    <div className="flex column width-100">
                                      <div className="flex width-100">
                                        <div className="profil_picture">
                                          {item.photo && (
                                            <LazyLoadImage
                                              effect="blur"
                                              loading="lazy"
                                              alt=""
                                              src={`${API}/File/Job_Opportunity/${item.photo}`}
                                            />
                                          )}
                                        </div>
                                        <div className="flex column width-100 content">
                                          <div className="flex width-100 space-between">
                                            <p className="left">
                                              {item.company === null
                                                ? item.alias_text
                                                : item.company}{" "}
                                            </p>
                                          </div>
                                          <p className="job_title">
                                            {item.job_title}{" "}
                                          </p>
                                          <p className="time none">Full Time</p>
                                          {/* <p className="width-100 job_desc">Fermentum venenatis, mauris augue tincidunt aenean risus nam tristique. Faucibus cras cras feugiat id et scelerisque. Imperdiet ultrices et pharetra pellentesque pulvinar semper tristique pellentesque commodo. Mattis faucibus porttitor phasellus ultricies penatibus. Blandit malesuada scelerisque egestas ornare feugiat ac amet.</p> */}
                                        </div>
                                      </div>
                                      <div className="flex loc none">
                                        <LazyLoadImage
                                          effect="blur"
                                          loading="lazy"
                                          alt=""
                                          src={location}
                                        />
                                        <p className="right">{item.city}</p>
                                      </div>
                                      <div>
                                        <p className="width-100 job_desc">
                                          {item.description !== null
                                            ? item.description.slice(0, 200) +
                                              "..."
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="width-100 flex column industry">
                                        {item.industry && (
                                          <div className="flex width-100 list_industry">
                                            <LazyLoadImage
                                              effect="blur"
                                              loading="lazy"
                                              alt=""
                                              src={industry}
                                            />
                                            <p>{item.industry} </p>
                                          </div>
                                        )}
                                        {item.job_level && (
                                          <div className="flex width-100 list_industry">
                                            <LazyLoadImage
                                              effect="blur"
                                              loading="lazy"
                                              alt=""
                                              src={func}
                                            />
                                            <p>{item.job_level} </p>
                                          </div>
                                        )}
                                        <div className="flex width-100 list_industry">
                                          <LazyLoadImage
                                            effect="blur"
                                            loading="lazy"
                                            alt=""
                                            src={wallet}
                                          />
                                          <p>
                                            IDR{" "}
                                            {priceSplitter(
                                              item.salary_from * 1
                                            )}{" "}
                                            -{" "}
                                            {priceSplitter(item.salary_to * 1)}
                                          </p>
                                        </div>
                                        {item.job_type && (
                                          <div className="flex width-100 list_industry">
                                            <LazyLoadImage
                                              effect="blur"
                                              loading="lazy"
                                              alt=""
                                              src={fulltime}
                                            />
                                            <p>{item.job_type} </p>
                                          </div>
                                        )}
                                        {item.city && (
                                          <div className="flex width-100 list_industry">
                                            <LazyLoadImage
                                              effect="blur"
                                              loading="lazy"
                                              alt=""
                                              src={place}
                                              style={{
                                                margin: "0px 12px 0px 3px",
                                              }}
                                            />
                                            <p>{item.city}</p>
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        className="width-100"
                                        style={{
                                          border: "1px solid #F2F2F2",
                                          marginBottom: "15px",
                                        }}
                                      ></div>
                                      <div className="flex width-100 space-between f-col">
                                        <div className="flex  list_industry">
                                          <p
                                            id="created-time-count "
                                            className="c-72 s-1419 openSans auto-00"
                                          >
                                            {setDate(item.createdAt)}
                                            {parseInt(setDate(item.createdAt)) >
                                            1
                                              ? "s"
                                              : ""}{" "}
                                            ago{" "}
                                          </p>
                                        </div>
                                        <div className="flex flex-end-mob">
                                          <a
                                            className="mr-10 none"
                                            href="/Referral"
                                          >
                                            <button
                                              className="btn_job c-36 bg-ff bd-ea w-400"
                                              onClick={this.setPosition}
                                            >
                                              Refer friend
                                            </button>
                                          </a>
                                          <a
                                            href={`/JobDetail_Public/${
                                              item.job_opportunity_id
                                            }/${item.job_title
                                              .split(" ")
                                              .join("-")}`}
                                          >
                                            <button
                                              className="btn_job bg-36 c-ff bd-36 bold"
                                              onClick={this.setPosition}
                                            >
                                              View Job
                                            </button>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              <h3 className="width-100 center">{Response} </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    country1: state.countryRed,
    allCity1: state.AllCityRed,
    industri1: state.industriRed,
    bannerId1: state.bannerByIdRed,
    jobLevel1: state.jobLevelRed,
    jobFunction1: state.jobFunctionRed,
    jobByTitle1: state.jobByTitleRed,
    jobType1: state.jobTypeRed,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountry: (country_id) => {
      dispatch(getCountry(country_id));
    },
    getAllCity: (country_id) => {
      dispatch(getAllCity(country_id));
    },
    getBannerById: (banner_id) => {
      dispatch(getBannerById(banner_id));
    },
    getIndustri: () => {
      dispatch(getIndustri());
    },
    getJobType: () => {
      dispatch(getJobType());
    },
    getJobFunction: () => {
      dispatch(getJobFunction());
    },
    getJobLevel: () => {
      dispatch(getJobLevel());
    },
    jobByTitle: (
      user_id,
      job_title,
      country_id,
      city_id,
      job_function_id,
      industry_id,
      job_level_id,
      salary_from,
      salary_to,
      job_type
    ) => {
      dispatch(
        jobByTitle(
          user_id,
          job_title,
          country_id,
          city_id,
          job_function_id,
          industry_id,
          job_level_id,
          salary_from,
          salary_to,
          job_type
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(componentName);
