import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./Loading";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_DLP } from "../_redux/helper";
import axios from 'axios';
import check from "../images/k_checklist.svg"
import bookIcon from "../images/icons/icons/book-icon.svg"
import videoIcon from "../images/icons/icons/video-icon.svg"
import likeIcon from "../images/icons/icons/LikeButton.svg"
import k_contents from "../images/k-contents.svg"

// material ui
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from '@material-ui/core/IconButton';
// icon
import StarIcon from '@material-ui/icons/Star';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {findEmail} from "../_actions/dlp";
import {getDetailProgramDLP} from "../_actions/jobs"

class DlcCourseInfo extends Component {
  constructor() {
    super();
    this.state = {
      quick_detail: [
        {program: "Specialized Program", type: "Course Type"},
        {program: "5 Weeks (8 hours total)", type: "Time Period"}, 
        {program: "All", type: "Job Level"}, 
        {program: "English", type: "Language"}, 
        {program: "Certificate, Lifetime Access", type: "Benefits"}
      ],
      what_you_lern: [],
      desc_content:[],
      open_list_content: "",
      content_list: ["Introduction", "Problem Solving", "Analytical Thinking", "Leadership", "Critical Thinking", "Communication", "Assessment"],
      progresbar_list: [
        {rating: "5", value:90},
        {rating: "4", value:40},
        {rating: "3", value:30},
        {rating: "2", value:20},
        {rating: "1", value:10}
      ],
      list_user_rating:[
        {name: "Jhon Doe"},
        {name: "Budi"},
        {name: "Ani"}
      ],
      error: false,
      loading: true,
      user_id: 0,
      list_courses: [],
      expanded:"panel1",
    };
  }


  async componentDidMount() {
    const { ownProps } = this.props.ownProps
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_email = decode1["email"];
    this.props.findEmail(user_email);
    this.props.getDetailProgramDLP(ownProps.match.params.program_id);

    setTimeout(() => {
      const data = this.props.Program1.dataDetailProgramDLP
      this.setState({ what_you_lern: data})
    }, 1000);

    axios
      .get(`${API_DLP}/api/program/${ownProps.match.params.program_id}`)
      .then(({data}) => {
        this.setState({
          desc_content: data[0]
        })
    })

    this.setState({
      loading: true
    })
    let arr = []
    await Promise.all(
      [axios
        .get(`${API_DLP}/api/getcontent_by_program/${ownProps.match.params.program_id}`)
        .then((courseModel) => {
            this.setState({
                list_courses : courseModel.data
            })
            courseModel.data.forEach(el => {
                arr.push(
                    new Promise((resolve, reject) => {
                        axios
                        .get(`${API_DLP}/api/getusercourse_by_content/${this.state.user_id}/${el.program_content_id}`)
                        .then((courses) => {
                            el["content_courses"] = courses.data
                            resolve(courses.data)
                        })
                    })
                )
            });
        })]
      )
    
    await Promise.all(arr).then((values)=>{
      if (values.length !== 0) {
        this.setState({loading: false})
      }
    })
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.findEmail1.dataEmail.length===0 && prevState.user_id !== this.state.user_id){
      const id = this.props.findEmail1.dataEmail.id
      this.setState({user_id: id})
    }
  }

  handleClickListContent = (openList) => { 
    if (this.state.open_list_content === openList) {
      this.setState({
        open_list_content: ""
      })  
    } else {
      this.setState({
        open_list_content: openList
      })
    }
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({expanded : (isExpanded ? panel : false)});
};



  render() {
    const BorderLinearProgress = withStyles((theme) => ({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
      },
    }))(LinearProgress);

    if (this.state.error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (this.state.loading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

      return (
          <React.Fragment>
          <h2 className="font-transformers dekstop">{this.state.desc_content.program_name}</h2>
          <h2 className="font-transformers mobile" style={{fontSize:"28px"}}>{this.state.desc_content.program_name}</h2>
          <p className="paragraph-1-dlc">{this.state.desc_content.program_desc}</p>
          <div style={{display:"none"}} className="rate-transformer">
            <StarIcon className="icon-dlc-star"/>
            <StarIcon className="icon-dlc-star"/>
            <StarIcon className="icon-dlc-star"/>
            <StarIcon className="icon-dlc-star"/>
            <StarIcon className="icon-dlc-star"/>
            <p>(354)</p>
          </div>
          <div className="dekstop-dlc recomended-list-work-inner width-100 dekstop-only">
          {
            this.state.quick_detail.map((item, index) => {
              return(
                <div key={index} className="quick-detail-dlc">
                    <div className="box-icon-container"></div>
                    <p>{item.program}</p>
                  </div>
                )
              })
            }
          </div>
          <div className="mobile mobile-tablet">
            {
              this.state.quick_detail.map((item, index) => {
                return(
                  <div key={index} style={{marginTop:"15px"}}>
                    <p className="text-quick-detail-dlc">{item.type}</p>
                    <div key={index} className="quick-detail-dlc-mobile">
                      <div className="box-icon-container"></div>
                      <p>{item.program}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {/* ABOUT PROGRAM */}
          <div className="title-blue-dlc about-program-dlc">
            <h4>About this program</h4>
            <p>
            {this.state.desc_content.about}
            </p>
          </div>
          {/* WHAT WILL YOU LERN */}
          <div className="title-blue-dlc">
            <h4>What will you learn</h4>
            <Grid container spacing={4} style={{marginTop: "5px"}}>
              {
                this.state.what_you_lern.map((item, index) => {
                  return(
                    <Grid key={index} item xs={12} sm={6}>
                      <div className="container-checkCircle">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={check} />
                        <p className="text-checkCircle">{item.learn_desc}</p>
                      </div>
                    </Grid>
                  )
                })
              }
            </Grid>
          </div>
          {/* CONTENTS */}
          <div className="title-blue-dlc contents-dlc">
            <h4 style={{marginBottom:"10px"}}>Contents</h4>
              {
                this.state.list_courses.map((list_course, i) => {
                  if (i==0){
                  return(
                    <React.Fragment key={list_course.id}>
                      {!list_course.content_courses || list_course.content_courses.length < 1?  "" : 
                      <Accordion expanded={i==0?(this.state.expanded === 'panel1'):""} onChange={i==0?(this.handleChange('panel1')):""}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{list_course.program_content_name}</Typography>
                        </AccordionSummary>
                        {
                          list_course.content_courses.map((content_course)=>{
                            return(
                              <AccordionDetails key={content_course.id}>
                                <List style={{width:"100%"}} component="div" disablePadding>
                                  <ListItem button>
                                    <ListItemIcon>
                                      {
                                        content_course.course_type_name === "Book" ?
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp-courseInfo" src={bookIcon} />
                                        : content_course.course_type_name === "Video" ?
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp-courseInfo" src={videoIcon} />
                                        :
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp-courseInfo" src={k_contents} />
                                      }
                                    </ListItemIcon>
                                    <ListItemText primary={content_course.course_name} />
                                    <p className="mobile" style={{fontSize:"12px", color:"#707070"}}>{content_course.duration}</p>
                                    <p className="dekstop" style={{fontSize:"14px", color:"#707070"}}>{content_course.duration}</p>
                                  </ListItem>
                                </List>
                              </AccordionDetails>
                            )
                          })
                        }
                      </Accordion>}
                    </React.Fragment>
                  )}
                  if (i>0){
                    return(
                      <React.Fragment key={list_course.id}>
                        {!list_course.content_courses || list_course.content_courses.length < 1?  "" : 
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{list_course.program_content_name}</Typography>
                          </AccordionSummary>
                          {
                            list_course.content_courses.map((content_course)=>{
                              return(
                                <AccordionDetails key={content_course.id}>
                                  <List style={{width:"100%"}} component="div" disablePadding>
                                    <ListItem button>
                                      <ListItemIcon>
                                        {
                                          content_course.course_type_name === "Book" ?
                                          <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp-courseInfo" src={bookIcon} />
                                          : content_course.course_type_name === "Video" ?
                                          <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp-courseInfo" src={videoIcon} />
                                          :
                                          <LazyLoadImage effect="blur" loading="lazy" alt="" className="icon-dlp-courseInfo" src={k_contents} />
                                        }
                                      </ListItemIcon>
                                      <ListItemText primary={content_course.course_name} />
                                      <p className="mobile" style={{fontSize:"12px", color:"#707070"}}>{content_course.duration}</p>
                                      <p className="dekstop" style={{fontSize:"14px", color:"#707070"}}>{content_course.duration}</p>
                                    </ListItem>
                                  </List>
                                </AccordionDetails>
                              )
                            })
                          }
                        </Accordion>}
                      </React.Fragment>
                    )}
                })
              }
          </div>
          {/* Program Rating & Reviews */}
          <div style={{display:"none"}} className="title-blue-dlc">
            <h4>Program Rating &amp; Reviews</h4>
            <div className="container-rating-review">
              <div className="rating-review">
                <h1 className="dekstop" style={{fontSize:"62px"}}>5.0</h1>
                <h1 className="mobile" style={{fontSize:"48px"}}>5.0</h1>
                <StarIcon className="icon-dlc-star"/>
                <StarIcon className="icon-dlc-star"/>
                <StarIcon className="icon-dlc-star"/>
                <StarIcon className="icon-dlc-star"/>
                <StarIcon className="icon-dlc-star"/>
                <p>(354)</p>
              </div>
              <div style={{width:"100%"}}>
              {
                this.state.progresbar_list.map((item, index) => {
                  return(
                    <div key={index} className="progressbar-rating">
                      <p>{item.rating}</p>
                      <StarIcon className="dlc-star-horizontal"/>
                      <BorderLinearProgress variant="determinate" value={item.value} />
                    </div>
                  )
                })
              }
              </div>
            </div>
            {/* Card review & rating */}
            <div className="card-review-rating">
            {
                this.state.list_user_rating.map((item, index) => {
                  return(
                    <Card variant="outlined" key={index} style={{marginTop:"10px"}}>
                      <CardContent>
                        <div className="card-container-review-dlc">
                          <div className="header-content-review-dlc">
                            <div className="avatar-rating">
                              <Avatar>{item.name.split("")[0]}</Avatar>
                              <div className="container-name-star">
                                <p className="name-review-dlc">{item.name}</p>
                                <StarIcon className="icon-dlc-star" />
                                <StarIcon className="icon-dlc-star" />
                                <StarIcon className="icon-dlc-star" />
                                <StarIcon className="icon-dlc-star" />
                                <StarIcon className="icon-dlc-star" />
                              </div>
                            </div>
                            <p className="tanggal-review-dlc">22/11/2020</p>
                          </div>
                          <div className="content-review-dlc">
                            <p>Diam a id viverra amet scelerisque lectus. Pellentesque aliquam malesuada nunc tempor, dui dictum laoreet duis. Quisque ultrices ut bibendum euismod urna phasellus quam dignissim. Vitae sit mauris ut quisque vulputate. Sit aliquam amet dolor elit pellentesque.</p>
                          </div>
                        <p className="border-bottom-review"></p>
                        </div>
                      </CardContent>
                      <CardActions>
                        <p className="text-helpful-dlp">Was this helpful?</p>
                        <IconButton style={{padding:"8px"}} aria-label="like">
                          <LazyLoadImage effect="blur" loading="lazy" alt="" style={{height:"30px"}} src={likeIcon} />
                        </IconButton>
                      </CardActions>
                    </Card>
                  )
                })
            }
            </div>
          </div>
        </React.Fragment>
      );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    ownProps: ownProps,
    findEmail1: state.findEmailRed,
    Program1:state.detailProgramRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    findEmail: (user_id) =>{
      dispatch(findEmail(user_id))
    },
    getDetailProgramDLP: (program_id) =>{
      dispatch(getDetailProgramDLP(program_id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DlcCourseInfo);
