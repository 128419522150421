import React, { Component } from 'react';
import { connect } from "react-redux";
import {getBannerById} from "../_actions/article"
import {getJobFunction,getJobLevel} from "../_actions/work";
import {postReferral, getReferral, checkUserExist } from "../_actions/user";
import Loading from "../components/Loading";
import bottom_referral from "../images/bottom_referral.svg"
import top_referral from "../images/top_referral.svg"
import apply_success from "../images/apply_success.svg"
import ReactGA from "react-ga";
import sym_bottom from "../images/sym_bottom.svg"
import Modal from "react-awesome-modal";
import invite1 from "../images/Bullet.svg"
import invite2 from "../images/invite1.svg"
import referral from "../images/People.svg"
import info from "../images/info.svg"
import warning_referral from "../images/warning_referral.svg"
import 'react-input-range/lib/css/index.css'
import referral_mobile from "../images/referral_mobile2.PNG"
import {API} from '../_redux/helper'
import {GAevent} from '../components/TrackingEvent'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { referral_agreement } from './Referral_Agreement';
import ReactPixel from "react-facebook-pixel"

class componentName extends Component {
    constructor(){
        super()
        this.state={
            user_id:"",
            job_level_id:"",
            job_function_id:"",
            name:"",
            email:"",
            phone_number:"",
            agreement:false,
            nameErr:"",
            emailErr:"",
            phone_numberErr:"",
            success:false,
            failed:false,
            popup_karya:false,
            user_refer_id:[],
            hide_refer_id:[],
            top:"",
            height:"",

        }
    }

    componentDidMount() {
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        this.setState({ user_id: user_id });
        this.props.getJobFunction();
        this.props.getJobLevel();
        this.props.getBannerById(2);
        this.props.getReferral(user_id)
        ReactGA.pageview(window.location.pathname + window.location.search);
        // sessionStorage.setItem("setupTime", new Date().getTime());
      }

      componentDidUpdate(prevProps, prevState, snapshot){
        if ((JSON.parse(sessionStorage.getItem('invitation')) === "true" || JSON.parse(sessionStorage.getItem('invitation')) === true) && (prevState.success !== JSON.parse(sessionStorage.getItem('invitation')))){
            this.setState({
                success: true,
              });
        }
       
      }
      
      changeJobLevel = (event) => {
        this.setState({ job_level_id: event.target.value });
      };
      changeJobFunction = (event) => {
        this.setState({ job_function_id: event.target.value });
      };

     changeName = (e)=>{
       const reg = /^(?![\s.]+$)[a-zA-Z\s.]*$/
       const invalid_name = "Name is not valid"
       const valid_name = ""
       if (reg.test(e.target.value)===true || reg.test(e.target.value)==="true"){
        this.setState({name: e.target.value})
        this.setState({nameErr: valid_name})
       }else{
        this.setState({name: e.target.value})
        this.setState({nameErr: invalid_name})
       }
     }

     changeEmail =(e)=>{
       const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
       const invalid_email = "Email is not valid"
       const valid_email = ""
       if (reg.test(e.target.value)===true || reg.test(e.target.value)==="true"){
        this.setState({email: e.target.value})
        this.setState({emailErr: valid_email})
       }else{
        this.setState({email: e.target.value})
        this.setState({emailErr: invalid_email})
       }
       this.props.checkUserExist(e.target.value, this.state.phone_number, this.state.user_id)
     }

     changePhone =(e)=>{
      const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
      const invalid_number = "Phone number is not valid"
      const valid_number = ""
      if ((reg.test(e.target.value)===true || reg.test(e.target.value)==="true" ) && (e.target.value.length > 9 && e.target.value.length < 14)){
       this.setState({phone_number: e.target.value})
       this.setState({phone_numberErr: valid_number})
      }else{
       this.setState({phone_number: e.target.value})
       this.setState({phone_numberErr: invalid_number})
      }
      this.props.checkUserExist(this.state.email, e.target.value, this.state.user_id)
    }
    
    changeAgreement =(e)=>{
      this.setState({agreement:!this.state.agreement})
      this.props.checkUserExist(this.state.email, this.state.phone_number, this.state.user_id)
    }
    
    sendReferral = (e)=>{
      e.preventDefault()
      this.props.checkUserExist(this.state.email, this.state.phone_number, this.state.user_id)

      const {invitation} = this.props.invitation1;
      if (this.state.nameErr !=="" || this.state.phone_numberErr !=="" || this.state.emailErr !== ""){
        window.location.href="#"
      }else{ 
        if (invitation.length > 0){
          this.setState({failed:true})
        }else{
      const referral = {
        user_id : this.state.user_id,
        name : this.state.name,
        email : this.state.email,
        phone_number: this.state.phone_number,
        job_function_id: this.state.job_function_id,
        job_level_id : this.state.job_level_id,
        job_opportunity_id:null,
        is_agrement : this.state.agreement,
      }

      this.props.sendReferral1(referral)
      GAevent('Referral','Post Referral') 
      ReactPixel.track('Post Referral - /Referral','Submit Data'); 
    }}}


    displayArrow = (event)=>{
      let user_refer_id
      if (event===undefined || event==="" || event===null || event === this.state.job_function_id){
        user_refer_id=""
      }else{
        user_refer_id = event
      }
  
      const cek1 =  [...this.state.user_refer_id]
      const index = cek1.indexOf(user_refer_id);
        if (index===-1){
          cek1.push(user_refer_id)
      }else{
          cek1.splice(index,1)
      }
      this.setState({user_refer_id: cek1})
    }

    hideArrow = (event)=>{
      let user_refer_id
      if (event===undefined || event==="" || event===null || event === this.state.job_function_id){
        user_refer_id=""
      }else{
        user_refer_id = event
      }
  
      const cek1 =  [...this.state.hide_refer_id]
      const index = cek1.indexOf(user_refer_id);
        if (index===-1){
          cek1.push(user_refer_id)
      }else{
          cek1.splice(index,1)
      }
      this.setState({hide_refer_id: cek1})
    }

   

      reset=()=>{
       window.location.reload(true)
      }

      openSuccess=()=> {
        this.setState({
          success: true,
        });
      }
      closeSuccess=()=> {
        sessionStorage.removeItem('invitation')
        this.setState({
          success: false,
        });
      }
      openFailed=()=> {
        this.setState({
          failed: true,
        });
      }

      closeFailed=()=> {
        this.setState({
          failed: false,
        });
      }

      openKarya=()=> {
        this.setState({
          popup_karya: true,
        });
      }


      closeKarya=()=> {
        this.setState({
          popup_karya: false,
        });
      }

      changeRevert = (e)=>{
        this.setState({top:window.innerWidth,
        height:window.innerHeight })
    }
    
    

    render() {
        const {dataJobLevel} = this.props.jobLevel1;
        const {dataJobFunction} = this.props.jobFunction1;
        const {dataBannerById, isLoading, error} = this.props.bannerId1;
        const {dataReferral} = this.props.dataReferral1;
       
        let length_referral
        if (dataReferral.length > 9){
          length_referral = <p style={{padding:"2px 0px", fontSize:"10px", lineHeight:"16px"}}>{dataReferral.length} </p>  
        }else{
          length_referral = <p>{dataReferral.length}</p>  
        }

       

        if (error) {
        return (
            <div className="message-question-load">
            <h3>Ups... Please, refresh this page!</h3>
            </div>
        );
        }

        if (isLoading) {
        return (
            <React.Fragment>
            <Loading />
            </React.Fragment>
        );
        }

      

        let btn_apply
        if ((this.state.name ==="" || this.state.name === undefined) || (this.state.phone_number ==="" || this.state.phone_number === undefined) || (this.state.email ==="" || 
            this.state.email === undefined) || (this.state.job_function_id ==="" || this.state.job_function_id === undefined) || (this.state.job_level_id ==="" || this.state.job_level_id === undefined) || (this.state.agreement ==="false" || this.state.agreement === false)){
              btn_apply = <button type="submit" style={{backgroundColor:"#BDBDBD", borderColor:"#BDBDBD", cursor:"default"}}>
                            <p>Send Invitation</p>
                          </button>
             
        }else{
          btn_apply =  <button type="submit" onClick={this.sendReferral}>
                        <p>Send Invitation</p>
                      </button>
        }


        
        

        return (
            <div>
                <div className="box-wrapper bg-color referral-page" onClick={this.changeRevert}>
                    <div className="wrapper">
                        <div className="content Dashbrd">
                            <div className="page-container scrollContent rF width-100">
                                <div className="page-container-outer job-summary dashboard">
                                    <div className="box-logo width-100">
                                      <h1 className="titleSpartan">Referral </h1>
                                    </div>
                                    <div className="flex width-100 advertisement" >
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={`${API}/File/Banner/${dataBannerById.photo}`} />
                                    </div>
                                    <div className="flex width-100 banner mobile" >
                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={referral_mobile} className="width-100"/>
                                    </div>
                                    <div className="page-container-inner width-100 ">
                                        <div className="box-edu-container width-100">
                                       <div className="flex width-100">
                                           <div className="right_box width-100">
                                               <div className="first_box width-100 dekstop">
                                                   <div className="flex width-100 space-between">
                                                      <div className="width-35 flex column left_box">
                                                        <div className="flex width-100">
                                                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={info} style={{marginRight:"8px"}}/>
                                                            <p style={{color:"#AD8C53"}}>Please fill out the information of the person that you would like to refer below</p>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p >Name</p>
                                                            <input type="text" onChange={this.changeName} className="width-100" value={this.state.name} />
                                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.nameErr}</span>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p >Email</p>
                                                            <input type="text" onChange={this.changeEmail} className="width-100" />
                                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.emailErr}</span>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p>Phone Number</p>
                                                            <input type="text" onChange={this.changePhone} className="width-100" />
                                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.phone_numberErr}</span>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p>Job Function</p>
                                                            <select className="width-100" onChange={this.changeJobFunction}>
                                                              <option selected="selected" style={{color:"grey", display:"none"}}>Choose</option>
                                                              {dataJobFunction.map((item,i)=>(
                                                                <option value={item.id}>{item.job_function_name} </option>
                                                              ))}
                                                            </select>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p>Job Level</p>
                                                            <select className="width-100" onChange={this.changeJobLevel}>
                                                              <option selected="selected" style={{color:"grey", display:"none"}}>Choose</option>
                                                              {dataJobLevel.map((item,i)=>(
                                                                <option value={item.id}>{item.job_level_name} </option>
                                                              ))}
                                                            </select>
                                                        </div>
                                                        <div className="width-100 check_box">
                                                          <div className="flex width-100">
                                                            <input type="checkbox" onClick={this.changeAgreement} />
                                                            <div className="flex column">
                                                              <p>By ticking this box, I agreed to</p>
                                                              <p style={{color:"#3B6C98", textDecoration:"underline", cursor:"pointer"}} onClick={this.openKarya} >Karya Talents Referral Agreement</p>
                                                          </div>
                                                          </div>
                                                        </div>
                                                       {btn_apply}
                                                      </div>
                                                      <div className="width-65 right_box_item">
                                                          <LazyLoadImage effect="blur" loading="lazy" alt="" src={referral} className="width-100"/>
                                                      </div>
                                                   </div>
                                               </div>
                                               <div className="mobile form_referral width-100">
                                                 <div className="flex notif width-100">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={info} />
                                                      <p>Please fill out the information of the person that you would like to refer below</p>
                                                 </div>


                                                 
                                                 <div className="width-100 flex column left_box">
                                                        <div className="width-100 field_box">
                                                            <p>Name</p>
                                                            <input type="text" onChange={this.changeName} className="width-100" />
                                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.nameErr}</span>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p>Email</p>
                                                            <input type="text" onChange={this.changeEmail} className="width-100" />
                                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.emailErr}</span>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p>Phone Number</p>
                                                            <input type="text" onChange={this.changePhone} className="width-100" />
                                                            <span style={{color: "red", fontSize:"12px"}}>{this.state.phone_numberErr}</span>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p>Job Function</p>
                                                            <select className="width-100" onChange={this.changeJobFunction}>
                                                              <option selected="selected" style={{color:"grey", display:"none"}}>Choose</option>
                                                              {dataJobFunction.map((item,i)=>(
                                                                <option value={item.id}>{item.job_function_name} </option>
                                                              ))}
                                                            </select>
                                                        </div>
                                                        <div className="width-100 field_box">
                                                            <p>Job Level</p>
                                                            <select className="width-100" onChange={this.changeJobLevel}>
                                                              <option selected="selected" style={{color:"grey", display:"none"}}>Choose</option>
                                                              {dataJobLevel.map((item,i)=>(
                                                                <option value={item.id}>{item.job_level_name} </option>
                                                              ))}
                                                            </select>
                                                        </div>
                                                        <div className="width-100 check_box">
                                                          <div className="flex width-100">
                                                            <input type="checkbox" onClick={this.changeAgreement} />
                                                            <div className="flex column">
                                                              <p>By ticking this box, I agreed to</p>
                                                              <p style={{color:"#3B6C98", textDecoration:"underline",  cursor:"pointer"}} onClick={this.openKarya} >Karya Talents Referral Agreement</p>
                                                          </div>
                                                          </div>
                                                        </div>
                                                        {btn_apply}
                                                      </div>



                                                
                                               </div>
                                           </div>
                                       </div>
                                            <div className="second_box width-100">
                                              <div className="container column">
                                                  <div className="flex width-100">
                                                    <h5>Dashboard</h5>
                                                    <div className="user_notif">
                                                      {length_referral}
                                                    </div>
                                                  </div>
                                                  <div className="line_referral"></div>
                                                  <div className="dekstop width-100">
                                                {dataReferral.map((item,i)=>(
                                                  <div key={i} className="flex column width-100">
                                                  <div  className="box_status" style={{marginBottom:"20px"}}>
                                                      <div className="flex width-100">
                                                        <div className="flex column box_user_35">
                                                          <h6>{item.name}</h6>
                                                          <div className="flex width-100 User_Con">
                                                            <p>{item.email}</p>
                                                            <p className="dot">.</p>
                                                            <p>{item.phone_number}</p>
                                                          </div>
                                                        </div>
                                                        <div className="flex width-65 space-between">
                                                          <div className="flex column box_invited">
                                                            <p className="title_status" style={{color : item.is_invited === 1 || item.is_invited==="1" ? "#3B6C98": "#828282"}}>INVITED</p>
                                                            <div className="user_invite">
                                                              {(item.is_invited===2 || item.is_invited==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                              (item.is_invited===1 || item.is_invited==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                              (item.is_invited===0 || item.is_invited==="0" ? "" :"")}
                                                            </div>
                                                          </div>
                                                          <div className="line_referral" style={{width:"1px"}}></div>
                                                          <div className="flex column box_invited">
                                                            <p className="title_status" style={{color : item.is_signed_up === 1 || item.is_signed_up==="1" ? "#3B6C98": "#828282"}}>SIGNED UP</p>
                                                            <div className="user_invite">
                                                            {(item.is_signed_up===2 || item.is_signed_up==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                              (item.is_signed_up===1 || item.is_signed_up==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                              (item.is_signed_up===0 || item.is_signed_up==="0" ? "" :"")}
                                                              {/* {signed_up} */}
                                                            </div>
                                                          </div>
                                                          <div className="line_referral" style={{width:"1px"}}></div>
                                                          <div className="flex column box_invited">
                                                            <p className="title_status" style={{color : item.is_joined_network === 1 || item.is_joined_network==="1" ? "#3B6C98": "#828282"}}>JOINED NETWORK</p>
                                                            <div className="user_invite">
                                                            {(item.is_joined_network===2 || item.is_joined_network==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                              (item.is_joined_network===1 || item.is_joined_network==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                              (item.is_joined_network===0 || item.is_joined_network==="0" ? "" :"")}
                                                            </div>
                                                          </div>
                                                          <div className="line_referral" style={{width:"1px"}}></div>
                                                          <div className="flex column box_invited">
                                                            <p className="title_status" style={{color : item.is_interviewed === 1 || item.is_interviewed==="1" ? "#3B6C98": "#828282"}}>INTERVIEWED</p>
                                                            <div className="user_invite">
                                                            {(item.is_interviewed===2 || item.is_interviewed==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                              (item.is_interviewed===1 || item.is_interviewed==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                              (item.is_interviewed===0 || item.is_interviewed==="0" ? "" :"")}
                                                            </div>
                                                          </div>
                                                          <div className="line_referral" ></div>
                                                          <div className="flex column box_invited">
                                                            <p className="title_status" style={{color : item.is_hired === 1 || item.is_hired==="1" ? "#3B6C98": "#828282"}}>HIRED</p>
                                                            <div className="user_invite">
                                                            {(item.is_hired===2 || item.is_hired==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                              (item.is_hired===1 || item.is_hired==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                              (item.is_hired===0 || item.is_hired==="0" ? "" :"")}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                  {/* <div className="line_referral"></div> */}
                                                  </div>
                                                  ))}
                                                  </div>
                                                  
                                                  <div className="mobile width-100 refers">
                                                  {dataReferral.map((item,i)=>(
                                                        <div className="box_user flex" style={{ height : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id)||(this.state.user_refer_id[2]===item.user_refer_friend_id)||(this.state.user_refer_id[3]===item.user_refer_friend_id)||(this.state.user_refer_id[4]===item.user_refer_friend_id))?"435px":"155px",
                                                        transition : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id)||(this.state.user_refer_id[2]===item.user_refer_friend_id)||(this.state.user_refer_id[3]===item.user_refer_friend_id)||(this.state.user_refer_id[4]===item.user_refer_friend_id))?"height 0.35s":"height 0.28s"}}>
                                                          <div className="inner_box">
                                                          {/* <Accordion className="Mui-expanded" >
                                                          <AccordionSummary className="Mui-expanded"> */}
                                                            <div className="flex column title_Box width-100 " >
                                                              <p className="title">{item.name}</p>
                                                              <div className="flex inline column width-100">
                                                                <p style={{marginBottom:"10px"}}>{item.email}</p>
                                                                <p style={{margin:"auto 10px", display:"none"}}>.</p>
                                                                <p>{item.phone_number}</p>
                                                                <div className="width-100 flex center" style={{marginTop:"10px"}} >
                                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={bottom_referral}  style={{ display : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id)||(this.state.user_refer_id[2]===item.user_refer_friend_id)||(this.state.user_refer_id[3]===item.user_refer_friend_id)||(this.state.user_refer_id[4]===item.user_refer_friend_id))?"none":"block"}} onClick={(e)=>{this.displayArrow(item.user_refer_friend_id)}}/>
                                                                  {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={top_referral} style={{ display : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id))?"block":"none"}}/> */}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {/* </AccordionSummary> */}
                                                            <div className="flex width-100 center none" style={{marginTop:"20px"}}>
                                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={sym_bottom}/>
                                                            </div>
                                                            <div className="flex width-100 status" style={{ transform : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id)||(this.state.user_refer_id[2]===item.user_refer_friend_id)||(this.state.user_refer_id[3]===item.user_refer_friend_id)||(this.state.user_refer_id[4]===item.user_refer_friend_id))?"scaleY(1)":"scaleY(0)",
                                                             height : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id)||(this.state.user_refer_id[2]===item.user_refer_friend_id)||(this.state.user_refer_id[3]===item.user_refer_friend_id)||(this.state.user_refer_id[4]===item.user_refer_friend_id))?"auto":"0px",
                                                             transformOrigin : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id)||(this.state.user_refer_id[2]===item.user_refer_friend_id)||(this.state.user_refer_id[3]===item.user_refer_friend_id)||(this.state.user_refer_id[4]===item.user_refer_friend_id))?"top":"unset",
                                                             transition : ((this.state.user_refer_id[0]===item.user_refer_friend_id)||(this.state.user_refer_id[1]===item.user_refer_friend_id)||(this.state.user_refer_id[2]===item.user_refer_friend_id)||(this.state.user_refer_id[3]===item.user_refer_friend_id)||(this.state.user_refer_id[4]===item.user_refer_friend_id))?"transform 0.33s ease":"transform 0.20s ease"}}>
                                                              <div className="flex width-100 column " style={{marginTop:"20px", padding:"0px 16px"}}>
                                                                <div className="flex width-100 space-between box_state">
                                                                    <p className="state_name" style={{color : item.is_invited === 1 || item.is_invited==="1" ? "#3B6C98": "#828282"}}>INVITED</p>
                                                                    <div className="state_circle">
                                                                    {(item.is_invited===2 || item.is_invited==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2} className="width-100"/> :"") ||
                                                                      (item.is_invited===1 || item.is_invited==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1} className="width-100"/> :"") ||
                                                                      (item.is_invited===0 || item.is_invited==="0" ? "" :"")}
                                                                    </div>
                                                                </div>
                                                                <div className="line_referral" style={{width:"100%", margin:"10px 0px"}}></div>
                                                                <div className="flex width-100 space-between box_state">
                                                                    <p className="state_name" style={{color : item.is_signed_up === 1 || item.is_signed_up==="1" ? "#3B6C98": "#828282"}}>SIGNED UP</p>
                                                                    <div className="state_circle">
                                                                    {(item.is_signed_up===2 || item.is_signed_up==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                                      (item.is_signed_up===1 || item.is_signed_up==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                                      (item.is_signed_up===0 || item.is_signed_up==="0" ? "" :"")}
                                                                    </div>
                                                                </div>
                                                                <div className="line_referral" style={{width:"100%", margin:"10px 0px"}}></div>
                                                                <div className="flex width-100 space-between box_state">
                                                                    <p className="state_name" style={{color : item.is_joined_network === 1 || item.is_joined_network==="1" ? "#3B6C98": "#828282"}}>JOINED NETWORK</p>
                                                                    <div className="state_circle">
                                                                    {(item.is_joined_network===2 || item.is_joined_network==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                                    (item.is_joined_network===1 || item.is_joined_network==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                                    (item.is_joined_network===0 || item.is_joined_network==="0" ? "" :"")}
                                                                    </div>
                                                                </div>
                                                                <div className="line_referral" style={{width:"100%", margin:"10px 0px"}}></div>
                                                                <div className="flex width-100 space-between box_state">
                                                                    <p className="state_name" style={{color : item.is_interviewed === 1 || item.is_interviewed==="1" ? "#3B6C98": "#828282"}}>INTERVIEWED</p>
                                                                    <div className="state_circle">
                                                                    {(item.is_interviewed===2 || item.is_interviewed==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                                    (item.is_interviewed===1 || item.is_interviewed==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                                    (item.is_interviewed===0 || item.is_interviewed==="0" ? "" :"")}
                                                                    </div>
                                                                </div>
                                                                <div className="line_referral" style={{width:"100%", margin:"10px 0px"}}></div>
                                                                <div className="flex width-100 space-between box_state">
                                                                    <p className="state_name" style={{color : item.is_hired === 1 || item.is_hired==="1" ? "#3B6C98": "#828282"}}>HIRED</p>
                                                                    <div className="state_circle">
                                                                    {(item.is_hired===2 || item.is_hired==="2" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite2}/> :"") ||
                                                                    (item.is_hired===1 || item.is_hired==="1" ? <LazyLoadImage effect="blur" loading="lazy" alt="" src={invite1}/> :"") ||
                                                                    (item.is_hired===0 || item.is_hired==="0" ? "" :"")}
                                                                    </div>
                                                                </div>
                                                                <div className="width-100 flex center" style={{marginTop:"10px"}}>
                                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={top_referral}  
                                                                  onClick={(e)=>{this.displayArrow(item.user_refer_friend_id)}}
                                                                  />
                                                                </div>
                                                                </div>
                                                            </div>
                                                            {/* </Accordion> */}
                                                          </div>

                                                        </div>
                                                  ))}
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                        <Modal id="success" visible={this.state.success} effect="fadeInUp">
                                          <div  className="modal_apply">
                                              <div className="width-100 flex center">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={apply_success} style={{marginTop:"24px", marginBottom:"24px"}}/>
                                              </div>
                                              <h5>Good Job!</h5>
                                              <p  style={{width:"90%", marginLeft:"5%"}}>We have sent the invitation, tell your friend to check it out!</p>
                                              <div className="width-100 flex center">
                                                  <button onClick={this.closeSuccess} style={{marginBottom:"20px"}}>
                                                      <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                                                  </button>
                                              </div>
                                          </div>
                                      </Modal>
                                      <Modal id="failed" visible={this.state.failed} effect="fadeInUp">
                                          <div  className="modal_apply">
                                              <div className="width-100 flex center">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={warning_referral} style={{marginTop:"24px", marginBottom:"24px"}}/>
                                              </div>
                                              <h5>Oops!</h5>
                                              <p style={{width:"90%", marginLeft:"5%"}}>This Email/Phone Number is already registered, Try to invite another of your friend</p>
                                              <div className="width-100 flex center" style={{marginBottom:"20px"}}>
                                                  <button onClick={this.closeFailed}>
                                                      <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                                                  </button>
                                              </div>
                                          </div>
                                      </Modal>
                                      <Modal id="popup_karya" visible={this.state.popup_karya} effect="fadeInUp"  onClickAway={() => this.closeKarya()}>
                                        <div className="dekstop width-100">
                                          <div  className="modal_apply annoucement" style={{width:`${this.state.top*0.60}px`, height:`${this.state.height-50}px`, overflow:"auto"}}>
                                              
                                              
                                              {referral_agreement}
                                             



                                              <div className="width-100 flex center" style={{margin:"30px auto 20px"}}>
                                                  <button onClick={this.closeKarya}>
                                                      <p style={{margin:"12px 20px", color:"#4F4F4F"}}>Close</p>
                                                  </button>
                                              </div>
                                              </div>
                                          </div>
                                          <div className="mobile width-100">
                                          <div  className="modal_apply annoucement" style={{width:`${this.state.top-40}px`, height:`${this.state.height-80}px`, overflow:"auto"}}>
                                              
                                              
                                              
                                              {referral_agreement}




                                              <div className="width-100 flex center" style={{margin:"30px auto 30px"}}>
                                                  <button onClick={this.closeKarya}>
                                                      <p style={{margin:"8px 18px", color:"#4F4F4F"}}>Close</p>
                                                  </button>
                                              </div>
                                              </div>
                                          </div>
                                      </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      bannerId1 : state.bannerByIdRed,
      jobLevel1 : state.jobLevelRed,
      referral : state.postReferralRed,
      jobFunction1 : state.jobFunctionRed,
      dataReferral1: state.getReferralRed,
      invitation1: state.getInvitationRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getBannerById: (banner_id) =>{
        dispatch(getBannerById(banner_id))
      },
      getJobFunction: () =>{
        dispatch(getJobFunction())
      },
      getJobLevel: () =>{
        dispatch(getJobLevel())
      },
      sendReferral1: (referral) => {
        dispatch(postReferral(referral));
      },
      getReferral:(user_id)=>{
        dispatch(getReferral(user_id))
      },
      checkUserExist: (email, phone_number, user_id)=>{
         dispatch(checkUserExist(email,phone_number, user_id))
      }
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(componentName);
  
