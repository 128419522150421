import React, { Component } from 'react';
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import iOpenSidebar from "../images/iOpenSidebar.png";
import iDashboard from "../images/iDashboard.png";
import iVIP from "../images/iVIP.svg";
import iCareer from "../images/iCareer.png";
import iReferral from "../images/iReferral.png";
import iWork from "../images/iWork.png";
import iMyProfile from "../images/iMyprofile.png";
import iLearning from "../images/iLearning.png";
import iTraining from "../images/iTraining.png";
import closeSidebar from "../images/closeSidebar.svg"
import Zoom from 'react-reveal/Zoom';
import { BrowserRouter as Router, Switch, Route, NavLink, Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const styles = {
    fadeInDown: {
      animation: "x .5s",
      animationName: Radium.keyframes(fadeIn, "fadeIn"),
      marginTop: "-86px",
    },
  };
class LeftBar extends Component {
    constructor() {
        super();
        this.state = {
          user_id: "",
          link: false,
          var_report: sessionStorage.getItem("report"),
          var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
          visible: true,
          height:"",
          ratting:0,
        };
      }

      changeBackground = () => {
        this.setState({
          link: !this.state.link,
        });
        sessionStorage.setItem("sidebar",!this.state.link)
        this.props.parentCallback(this.state.link);
      };

      removeSession=()=>{
        sessionStorage.removeItem("position")
        sessionStorage.removeItem("check_position")
      }

    render() {
        return (
            <div>
                 <div style={{ marginTop: "0px" }}>
            <div>
  
        <StyleRoot>
          <div className="flex" style={styles.zoomIn}>
            <div
              className="bigSidebar HC"
              style={{ width: this.state.link ? "255px" : "76px", height:"100%" }}
            >
              <div className="flex column">
                <div style={{ height: "87px", width: "100%" }}>
                  <LazyLoadImage effect="blur"
                    src={iOpenSidebar} className="flex a_28"
                    style={{
                      width: "24px",
                      height: "18px",
                      margin: "34px auto 34px 28px",
                      // display : this.state.link? "none":"block"
                    }}
                    // onClick={this.changeBackground}
                  />
                  <LazyLoadImage effect="blur"
                    src={closeSidebar} className="none "
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "34px",
                      marginLeft: "32px",
                      // display : this.state.link? "block":"none"
                    }}
                    // onClick={this.changeBackground}
                  />
                </div>
                <NavLink to="/NewDashboard">
                <div className="flex hov" title={this.state.link ?"":"Dashboard"} onClick={this.removeSession}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iDashboard} className="bullet"  />
                  <p  className="ws">
                    Dashboard
                  </p>
                </div>
                </NavLink>
                <NavLink to="/MyProfile">
                <div className="flex hov" title={this.state.link ?"":"My Proflile"} onClick={this.removeSession}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iMyProfile} className="bullet" />
                  <p  className="ws">
                    My Profile
                  </p>
                </div>
                </NavLink>
                <NavLink to="/JobOpportunities">
                <div className="flex hov"  title={this.state.link ?"":"Job Opportunities"} onClick={this.removeSession}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iCareer} className="bullet" />
                  <p >
                    Job Opportunities
                  </p>
                </div>
                </NavLink>
                <NavLink to="/Referral">
                <div className="flex hov"  title={this.state.link ?"":"Referral"} onClick={this.removeSession}>
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iReferral} className="bullet" />
                  <p  className="ws">
                    Referral
                  </p>
                </div>
                </NavLink>
                <NavLink to="/work_assessment">
                  <div className="flex hov" title={this.state.link ?"":"Assessments"} onClick={this.removeSession}>
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="bullet" />
                      <div className="flex column">
                        <div className="new-ntf a" style={{ display: this.state.link ? "none" : "flex" }}> <span className="openSans-Bold" >New</span></div>
                      <p style={{ display: this.state.link ? "block" : "none" }} className="ws">
                        Assessments
                      </p>
                      <div className="new-ntf b" style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >New</span></div>
                      </div>
                  </div>
                </NavLink>
                <NavLink to="/DigitalLearningPlatform" >
                  <div className="flex hov" title={this.state.link ?"":"Digital Learning Platform"} onClick={this.removeSession}>
                  <Zoom >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} className="bullet" />
                  </Zoom>
                    <div className="flex column">
                      <div className="new-ntf a" style={{ display: this.state.link ? "none" : "flex" }}> <span className="openSans-Bold" >BETA</span></div>
                    <p >
                      Digital Learning Platform
                    </p>
                    <div className="new-ntf b" style={{ display: this.state.link ? "flex" : "none", position:"absolute", margin:"21px auto auto 143px" }}> <span className="openSans-Bold" >BETA</span></div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/CareerCenter">
                <div className="flex hov" title={this.state.link ?"":"Career Center"} onClick={this.removeSession}>
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iWork} className="bullet" />
                  <p  className="ws">
                    Career Center
                  </p>
                </div>
                </NavLink>
                <div className="flex hov" style={{display:"none"}} onClick={this.removeSession}>
                <Zoom >
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iTraining} className="bullet" />
                </Zoom>
                <Zoom>
                  <p >
                    Training Marketplace
                  </p>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
          </StyleRoot>
        </div>
            </div>
            </div>
        );
    }
}

export default LeftBar;