import React, { Component } from 'react';
import karya from '../images/logo-KT-color.png'
import Navbar from "../components/NavBar";
import {
    BrowserRouter as Router,
    NavLink
  } from "react-router-dom";

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
          link:"",
          talents:false,
          resources:false,
          path:["/","/jo"]
        };
      }

    handleCallback = (childData) =>{
        // this.setState({link: childData})
        // window.location.href=`/${childData}`
    }

    setLink=(x,y)=>{
        if (y==="talents"){
            this.setState({talents:false})
        }else if(y==="resources"){
            this.setState({resources:false})
        }
        window.location.href=(x)
    }
    
    showMenu=(x,y)=>{
        if (y==="talents"){
            this.setState({talents:x})
        }else{
            this.setState({resources:x})
        }
    }

    searchJobOpportunity=(e)=>{
        const m = {
            city_id: "",
            country_id: "",
            industry_id: [],
            job_function_id: "",
            job_level_id: [],
            job_title:"",
            job_type: [],
            salary_from: 0,
            salary_to: 100000000,
            user_id: 1
        }
        sessionStorage.setItem("jobOpportunitySearch",JSON.stringify(m))
        window.location.href="/Job-Opportunity-Public"
    }
    

    render() {
        const {link, talents, resources, path} = this.state
        const checkPath=(e)=>{
            let n = false
            if (path.indexOf(e) != -1){
                n=true
            }
            return(n)
        }

        const icon_dropdown = <svg className='m-auto08' width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.34467 0.21967C0.637563 -0.0732233 1.11244 -0.0732233 1.40533 0.21967L5.375 4.18934L9.34467 0.21967C9.63756 -0.0732233 10.1124 -0.0732233 10.4053 0.21967C10.6982 0.512563 10.6982 0.987437 10.4053 1.28033L5.90533 5.78033C5.61244 6.07322 5.13756 6.07322 4.84467 5.78033L0.34467 1.28033C0.0517767 0.987437 0.0517767 0.512563 0.34467 0.21967Z" fill="#545454"/>
                            </svg>
        const dropdown_item = <svg className='auto-0 transf-90' width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.34467 0.21967C0.637563 -0.0732233 1.11244 -0.0732233 1.40533 0.21967L5.375 4.18934L9.34467 0.21967C9.63756 -0.0732233 10.1124 -0.0732233 10.4053 0.21967C10.6982 0.512563 10.6982 0.987437 10.4053 1.28033L5.90533 5.78033C5.61244 6.07322 5.13756 6.07322 4.84467 5.78033L0.34467 1.28033C0.0517767 0.987437 0.0517767 0.512563 0.34467 0.21967Z" fill="#545454"/>
                            </svg>
        const line = <div className='flex hg-24 wd-2 auto bg-d4'/>
        return (
            <div>
                <div className='flex width-100 center fixed z-i99'>
                <div className="dekstop nt_ width-100 fs-JL Nav_" style={{top:checkPath(window.location.pathname)?"30px":"0px"}}>
                    <div className='flex space-between wd-content bg-ff hg-100 m-auto'>
                        <img src={karya} className="flex auto-00 hg-40 pointer" onClick={()=>this.setLink("/","")}/>
                        <div className='flex auto-0' >
                            <a href="/"  rel="noopener noreferrer" className='c-54 Kanit s-16 w-600 let-04 text-up m-auto14 pointer' onClick={()=>this.setLink("/","")}>Home</a>
                            {line}
                            <div className='flex column li- relative'>
                                <a href="/Talents"  rel="noopener noreferrer" className='c-54 Kanit s-16 let-04 w-600 text-up m-auto14 pointer flex' onClick={()=>{this.showMenu(!talents,"talents")
                            this.setLink("/Talents","talents")}}>talents 
                                {icon_dropdown}
                                </a>
                                <div className={`flex column shd-15 bg-ff align-left absolute mt-50 br-10 p-14 ${talents?"op-1":"op-0"}`}>
                                    <a href="/Job-Opportunity-Public" rel="noopener noreferrer" className='flex space-between width-100 mb-10 pointer' onClick={()=>this.searchJobOpportunity()} >
                                        <p className='text-up c-54 Kanit  w-600 s-16 let-04'>Jobs</p>
                                    </a>
                                    <div className='flex width-100 hg-1 bg-eb' />
                                    <a href="/Talents"  rel="noopener noreferrer" className='flex space-between width-100 mb-10 mt-10 pointer' onClick={()=>this.setLink("/Talents","talents")}>
                                        <p className='text-up c-54 Kanit  w-600 s-16 let-04'>why join Us</p>
                                    </a>
                                    <div className='flex width-100 hg-1 bg-eb' />
                                    <a href="/data-science-rockstar"   rel="noopener noreferrer" className='flex space-between width-100 mt-10  pointer' onClick={()=>this.setLink("/data-science-rockstar","talents")}>
                                        <p className='text-up c-54 Kanit  w-600 s-16 let-04'>Data Science Rockstar</p>
                                    </a>
                                    {/* <div className='flex width-100 hg-1 bg-eb' />
                                    <div className='flex space-between width-100 mt-14 pointer' onClick={()=>this.setLink("/Employer","talents")}>
                                        <p className='text-up c-54 Kanit  w-600 let-04 s-16'>kt 300 Club </p>
                                    </div> */}
                                </div>
                            </div>
                            {line}
                            <a href="/Employer"  rel="noopener noreferrer" className='c-54 Kanit s-16 let-04 w-600 text-up m-auto14 pointer' onClick={()=>this.setLink("/EMPLOYER","")}>EMPLOYER</a>
                            {line}
                            <div className='flex column li- relative'>
                                <div className='c-54 Kanit s-16 let-04 w-600 text-up m-auto14 pointer flex' onClick={()=>this.showMenu(!resources,"resources")}>resources 
                                {icon_dropdown}
                                </div>
                                <div className={`flex column shd-15 bg-ff align-left absolute mt-50 br-10 p-14 ${resources?"op-1":"op-0"}`}>
                                    <a href="/career-resources" rel="noopener noreferrer" className='flex space-between width-100 mb-14 pointer' onClick={()=>{this.setLink("/Article","resources")
                                sessionStorage.setItem("slug",'career')}}>
                                        <p className='text-up c-54 Kanit  w-600 let-04 s-16'>career resources  </p>
                                    </a>
                                    <div className='flex width-100 hg-1 bg-eb' />
                                    <a href="/hiring-resources"  rel="noopener noreferrer" className='flex space-between width-100 mt-14 pointer' onClick={()=>{this.setLink("/Article","resources")
                                sessionStorage.setItem("slug",'hiring')}}>
                                        <p className='text-up c-54 Kanit let-04  w-600 s-16'>hiring resources </p>
                                    </a>
                                </div>
                            </div>
                            {line}
                            <a href="/ContactUs"  rel="noopener noreferrer" className='c-54 Kanit s-16 let-04 w-600 text-up m-auto14 pointer' onClick={()=>this.setLink("/ContactUs","")}>contact Us</a>
                            <NavLink to="/login">
                            <div className='bg-ff m-auto14 br-24 p-1024 bd-d4 flex pointer' onClick={() => this.handleCallback("login")}>
                                <div className='c-54 Kanit s-16 let-04 w-600 Montserrat text-up wd-72' >login</div>
                            </div>
                            </NavLink>
                            <NavLink to="/signup">
                            <div className='bg-36  br-24 p-1024 bd-36 flex pointer' onClick={() => this.handleCallback("signup")}>
                                <div className='c-ff Kanit s-16 let-04 w-600 Montserrat text-up wd-72'>sign up</div>
                                {/* <span className='c-ff Kanit s-16 let-04 w-600 Montserrat text-up'>sign up &nbsp; ➔</span> */}
                            </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="mobile nt_ width-100" style={{top:checkPath(window.location.pathname)?"30px":"0px"}}>
                  <Navbar checkPath={checkPath(window.location.pathname)} />
                </div>
                </div>
            </div>
        );
    }
}

export default Nav;