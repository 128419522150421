import {GET_EXP, GET_EXP_ID,GET_BENEFIT_LIST,GET_EXP_LIST, PUT_EXP, DELETE_EXP, POST_EXP } from "../config/constants";
  const initialState = {
    expected:[],
    expectedId:[],
    deleteExpected:[],
    postExpected:[],
    expectedList : [],
    benefitList : [],
    putExpected:[],
    isLoading: false,
    error: false,
  };
  
  export const getExpectedRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_EXP}_FULFILLED`:
        return {
          ...state,
          expected: action.payload.data,
          isLoading: false,
        };
      case `${GET_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };


  export const getExpectedListRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_EXP_LIST}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_EXP_LIST}_FULFILLED`:
        return {
          ...state,
          expectedList: action.payload.data,
          isLoading: false,
        };
      case `${GET_EXP_LIST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getBenefitListRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_BENEFIT_LIST}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_BENEFIT_LIST}_FULFILLED`:
        return {
          ...state,
          benefitList: action.payload.data,
          isLoading: false,
        };
      case `${GET_BENEFIT_LIST}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getExpectedIdRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_EXP_ID}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_EXP_ID}_FULFILLED`:
        return {
          ...state,
          expectedId: action.payload.data,
          isLoading: false,
        };
      case `${GET_EXP_ID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const postExpectedRed = (state = initialState, action) => {
    switch (action.type) {
      case `${POST_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${POST_EXP}_FULFILLED`:
        return {
          ...state,
          postExpected: action.payload.data,
          isLoading: false,
        };
      case `${POST_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const putExpectedRed = (state = initialState, action) => {
    switch (action.type) {
      case `${PUT_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${PUT_EXP}_FULFILLED`:
        return {
          ...state,
          putExpected: action.payload.data,
          isLoading: false,
        };
      case `${PUT_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const delExpectedRed = (state = initialState, action) => {
    switch (action.type) {
      case `${DELETE_EXP}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${DELETE_EXP}_FULFILLED`:
        return {
          ...state,
          deleteExpected: action.payload.data,
          isLoading: false,
        };
      case `${DELETE_EXP}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  
  