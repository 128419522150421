import React from "react";


export const phone = <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.37365 0.131836H2.47138C0.989908 0.131836 -0.072332 1.53424 0.401854 2.9396C1.34962 5.74843 2.62414 8.2368 4.28477 9.89743C5.9454 11.5581 8.43378 12.8326 11.2426 13.7803C12.6479 14.2546 14.0504 13.1923 14.0504 11.7108V9.80825C14.0504 9.26367 13.7552 8.7619 13.2793 8.49726L11.622 7.57587L11.5018 7.51592C11.0945 7.33503 10.625 7.34387 10.2222 7.54528L8.70628 8.3024L8.67031 8.29117C8.61042 8.27166 8.54311 8.24818 8.46951 8.22037C8.09261 8.07799 7.71504 7.87919 7.36539 7.61705C7.21368 7.50331 7.07029 7.37968 6.93639 7.24579C6.80084 7.11023 6.67569 6.96484 6.5606 6.81085C6.29936 6.46131 6.10069 6.08379 5.95792 5.70689L5.88683 5.50598L5.87503 5.4689L6.63267 3.95522C6.85404 3.51255 6.84275 2.98912 6.60251 2.55635L5.6851 0.903784C5.42057 0.427344 4.91854 0.131836 4.37365 0.131836ZM2.47138 1.63184H4.37366L5.29105 3.28436L4.40722 5.05205C4.33325 5.2 4.31026 5.36827 4.34184 5.53065C4.45261 6.10012 4.75667 6.90279 5.3591 7.70884C5.51635 7.91924 5.68832 8.11904 5.87574 8.30646C6.06093 8.49164 6.25812 8.66166 6.46561 8.81722C7.27338 9.42281 8.07801 9.72677 8.64909 9.8363C8.81089 9.86733 8.97841 9.84422 9.12576 9.77055L10.8931 8.88688L12.5504 9.80825V11.7108C12.5504 12.1852 12.1363 12.4988 11.7222 12.3591C9.11382 11.479 6.80512 10.2965 5.34543 8.83677C3.88574 7.37708 2.70325 5.06838 1.82313 2.46004C1.68339 2.04589 1.997 1.63184 2.47138 1.63184Z" fill="#292929"/>
                    </svg>

export const mail = <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5 0.625C16.6115 0.627247 16.7172 0.647621 16.8228 0.697839C16.9317 0.749923 17.0275 0.828399 17.1 0.925C17.1475 0.995795 17.1364 0.97455 17.1 0.925C17.1971 1.05453 17.25 1.21313 17.25 1.375C17.2497 1.33505 17.2481 1.31771 17.25 1.375V12.625C17.25 13.0392 16.9142 13.375 16.5 13.375H1.5C1.08579 13.375 0.75 13.0392 0.75 12.625V1.375C0.75 1.24073 0.786425 1.10741 0.854943 0.992252C0.99457 0.756985 1.23642 0.630314 1.5 0.625H16.5ZM15.75 2.875L9.45 7.6C9.21 7.78 8.889 7.798 8.6328 7.654L8.55 7.6L2.25 2.875V11.875H15.75V2.875ZM3.75 2.125L9 6.0625L14.25 2.125H3.75Z" fill="#292929"/>
                    </svg>

export const cv = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_21437_12569)">
                    <path d="M24.9413 0C21.3085 0 18.353 2.95548 18.353 6.58824C18.353 10.2211 21.3085 13.1765 24.9413 13.1765C28.574 13.1765 31.5295 10.2211 31.5295 6.58824C31.5295 2.95548 28.574 0 24.9413 0ZM24.3452 9.17415L21.7974 6.62632L23.1284 5.29531L24.3452 6.51213L26.7542 4.10309L28.0852 5.4341L24.3452 9.17415Z" fill="white"/>
                    <path d="M12.7061 22.5879C10.6303 22.5879 8.94141 24.2767 8.94141 26.3526H16.4708C16.4708 24.2767 14.782 22.5879 12.7061 22.5879Z" fill="white"/>
                    <path d="M12.7061 20.7061C13.7457 20.7061 14.5884 19.8634 14.5884 18.8238C14.5884 17.7842 13.7457 16.9414 12.7061 16.9414C11.6665 16.9414 10.8237 17.7842 10.8237 18.8238C10.8237 19.8634 11.6665 20.7061 12.7061 20.7061Z" fill="white"/>
                    <path d="M16.4707 6.58772C16.4707 4.84767 16.9983 3.22872 17.9017 1.88184H0.470703V31.9995H24.9413V15.0583C20.2705 15.0583 16.4707 11.2584 16.4707 6.58772ZM4.23541 5.64654H12.706V7.52889H4.23541V5.64654ZM4.23541 9.41125H14.5884V11.2936H4.23541V9.41125ZM4.23541 13.176H8.00012V15.0583H4.23541V13.176ZM18.3531 28.2348H7.05894V26.3524C7.05894 24.2284 8.23792 22.3748 9.97558 21.4108C9.33533 20.7357 8.94129 19.8247 8.94129 18.823C8.94129 16.7472 10.6301 15.0583 12.706 15.0583C14.7819 15.0583 16.4707 16.7472 16.4707 18.823C16.4707 19.8247 16.0767 20.7357 15.4364 21.4108C17.1741 22.3748 18.3531 24.2284 18.3531 26.3524V28.2348Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_21437_12569">
                    <rect width="32" height="32" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>

export const cv_green = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_21642_13559)">
                        <path d="M24.9413 0C21.3085 0 18.353 2.95548 18.353 6.58824C18.353 10.2211 21.3085 13.1765 24.9413 13.1765C28.574 13.1765 31.5295 10.2211 31.5295 6.58824C31.5295 2.95548 28.574 0 24.9413 0ZM24.3452 9.17415L21.7974 6.62632L23.1284 5.29531L24.3452 6.51213L26.7542 4.10309L28.0852 5.4341L24.3452 9.17415Z" fill="#2BBA3A"/>
                        <path d="M12.7061 22.5879C10.6303 22.5879 8.94141 24.2767 8.94141 26.3526H16.4708C16.4708 24.2767 14.782 22.5879 12.7061 22.5879Z" fill="#2BBA3A"/>
                        <path d="M12.7061 20.7061C13.7457 20.7061 14.5884 19.8634 14.5884 18.8238C14.5884 17.7842 13.7457 16.9414 12.7061 16.9414C11.6665 16.9414 10.8237 17.7842 10.8237 18.8238C10.8237 19.8634 11.6665 20.7061 12.7061 20.7061Z" fill="#2BBA3A"/>
                        <path d="M16.4707 6.5887C16.4707 4.84865 16.9983 3.2297 17.9017 1.88281H0.470703V32.0005H24.9413V15.0593C20.2705 15.0593 16.4707 11.2594 16.4707 6.5887ZM4.23541 5.64752H12.706V7.52987H4.23541V5.64752ZM4.23541 9.41222H14.5884V11.2946H4.23541V9.41222ZM4.23541 13.1769H8.00012V15.0593H4.23541V13.1769ZM18.3531 28.2358H7.05894V26.3534C7.05894 24.2294 8.23792 22.3758 9.97558 21.4118C9.33533 20.7367 8.94129 19.8257 8.94129 18.824C8.94129 16.7481 10.6301 15.0593 12.706 15.0593C14.7819 15.0593 16.4707 16.7481 16.4707 18.824C16.4707 19.8257 16.0767 20.7367 15.4364 21.4118C17.1741 22.3758 18.3531 24.2294 18.3531 26.3534V28.2358Z" fill="#2BBA3A"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_21642_13559">
                        <rect width="32" height="32" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>


export const people = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 4.875C6 3.01104 7.51104 1.5 9.375 1.5C11.239 1.5 12.75 3.01104 12.75 4.875C12.75 6.73896 11.239 8.25 9.375 8.25C7.51104 8.25 6 6.73896 6 4.875Z" fill="#3B6C98"/>
                        <path d="M7.0179 9.75H10.9821C11.7949 9.74999 12.4506 9.74999 12.9815 9.79336C13.5281 9.83802 14.0082 9.93237 14.4524 10.1587C15.158 10.5182 15.7318 11.0919 16.0913 11.7975C16.3176 12.2418 16.412 12.7219 16.4566 13.2685C16.5 13.7994 16.5 14.4551 16.5 15.2679V15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H2.25C1.83579 16.5 1.5 16.1642 1.5 15.75V15.2679C1.49999 14.4551 1.49999 13.7994 1.54336 13.2685C1.58803 12.7219 1.68239 12.2418 1.90873 11.7975C2.26824 11.0919 2.84192 10.5182 3.54755 10.1587C3.99176 9.93238 4.47191 9.83802 5.01854 9.79336C5.54945 9.74999 6.20507 9.74999 7.0179 9.75Z" fill="#3B6C98"/>
                      </svg>

export const salary = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_21437_12535)">
                        <path d="M4.206 3.64946L11.3817 1.69079L11.0017 0.922678C10.7531 0.423409 10.1467 0.217233 9.64744 0.465857L3.22363 3.64946H4.206Z" fill="#3B6C98"/>
                        <path d="M13.6097 1.74707C13.5208 1.74707 13.4319 1.7592 13.3429 1.78345L11.6571 2.24432L6.50879 3.64914H12.3525H14.8993L14.584 2.49294C14.4627 2.04218 14.0544 1.74707 13.6097 1.74707Z" fill="#3B6C98"/>
                        <path d="M15.9524 4.35645H15.722H15.4086H15.0953H12.7041H3.91735H2.76519H1.79495H1.61505H1.01269C0.693318 4.35645 0.40831 4.504 0.222347 4.73646C0.137451 4.84359 0.0727681 4.96689 0.0363841 5.10232C0.0141494 5.18722 0 5.27615 0 5.36711V5.48839V6.64056V16.628C0 17.1859 0.452779 17.6387 1.01067 17.6387H15.9504C16.5083 17.6387 16.961 17.1859 16.961 16.628V13.8082H10.9637C10.0157 13.8082 9.24559 13.0381 9.24559 12.0901V11.1643V10.851V10.5377V9.84236C9.24559 9.37745 9.43154 8.95501 9.73274 8.64575C9.99955 8.37082 10.3573 8.18486 10.7576 8.13837C10.8243 8.13031 10.893 8.12624 10.9617 8.12624H16.1202H16.4335H16.7468H16.961V5.36711C16.9631 4.80923 16.5103 4.35645 15.9524 4.35645Z" fill="#3B6C98"/>
                        <path d="M17.6706 9.0929C17.5695 8.99992 17.4503 8.92917 17.3169 8.88268C17.2138 8.84834 17.1046 8.82812 16.9894 8.82812H16.9631H16.9429H16.6296H15.4997H10.9638C10.4059 8.82812 9.95312 9.28088 9.95312 9.83879V10.3421V10.6554V10.9687V12.0885C9.95312 12.6464 10.4059 13.0992 10.9638 13.0992H16.9631H16.9894C17.1046 13.0992 17.2138 13.079 17.3169 13.0446C17.4503 13.0001 17.5695 12.9274 17.6706 12.8344C17.8727 12.6505 18.0001 12.3836 18.0001 12.0885V9.83879C18.0001 9.54366 17.8727 9.27682 17.6706 9.0929ZM13.0741 11.1648C13.0741 11.4437 12.8477 11.6701 12.5687 11.6701H12.2332C11.9543 11.6701 11.7279 11.4437 11.7279 11.1648V10.8292C11.7279 10.6675 11.8027 10.524 11.9219 10.433C12.0088 10.3663 12.116 10.3239 12.2332 10.3239H12.3181H12.5687C12.8477 10.3239 13.0741 10.5503 13.0741 10.8292V11.1648Z" fill="#3B6C98"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_21437_12535">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>

export const skill = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.125 0.75C5.46815 0.75 4.125 2.09315 4.125 3.75H1.5C1.08579 3.75 0.75 4.08579 0.75 4.5V9C0.75 9.41421 1.08579 9.75 1.5 9.75H3C3.82843 9.75 4.5 10.4216 4.5 11.25C4.5 12.0784 3.82843 12.75 3 12.75H1.5C1.08579 12.75 0.75 13.0858 0.75 13.5V16.5C0.75 16.9142 1.08579 17.25 1.5 17.25H12.75C13.1642 17.25 13.5 16.9142 13.5 16.5V14.25H14.25C15.9069 14.25 17.25 12.9069 17.25 11.25C17.25 9.59315 15.9069 8.25 14.25 8.25H13.5V4.5C13.5 4.08579 13.1642 3.75 12.75 3.75H10.125C10.125 2.09315 8.78185 0.75 7.125 0.75Z" fill="#3B6C98"/>
                    </svg>

export const work_exp = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 4.875C10.836 4.875 10.5 4.539 10.5 4.125V3H7.5V4.125C7.5 4.539 7.164 4.875 6.75 4.875C6.336 4.875 6 4.539 6 4.125V3C6 2.17275 6.67275 1.5 7.5 1.5H10.5C11.3273 1.5 12 2.17275 12 3V4.125C12 4.539 11.664 4.875 11.25 4.875Z" fill="#3B6C98"/>
                        <path d="M9.5325 11.5348C9.3975 11.5873 9.2025 11.6248 9 11.6248C8.7975 11.6248 8.6025 11.5873 8.4225 11.5198L0 8.71484V14.4373C0 15.5773 0.9225 16.4998 2.0625 16.4998H15.9375C17.0775 16.4998 18 15.5773 18 14.4373V8.71484L9.5325 11.5348Z" fill="#3B6C98"/>
                        <path d="M18 5.8125V7.53L9.18 10.47C9.12 10.4925 9.06 10.5 9 10.5C8.94 10.5 8.88 10.4925 8.82 10.47L0 7.53V5.8125C0 4.6725 0.9225 3.75 2.0625 3.75H15.9375C17.0775 3.75 18 4.6725 18 5.8125Z" fill="#3B6C98"/>
                        </svg>

export const education = <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.91161 0.678541C8.73295 0.607153 8.5337 0.607153 8.35504 0.678541L0.471711 3.82854C0.186833 3.94237 0 4.21822 0 4.525C0 4.83178 0.186833 5.10763 0.471711 5.22146L3.58337 6.46481V10.85C3.58337 11.051 3.66406 11.2436 3.80733 11.3846L4.33337 10.85C3.80733 11.3846 3.80713 11.3844 3.80733 11.3846L3.80814 11.3853L3.80911 11.3863L3.81157 11.3887L3.81851 11.3954L3.84037 11.4162C3.85846 11.4332 3.88357 11.4564 3.91542 11.485C3.97908 11.542 4.06994 11.6204 4.18584 11.7131C4.4171 11.898 4.75106 12.142 5.17023 12.3858C6.00277 12.87 7.20904 13.375 8.63339 13.375C10.0577 13.375 11.264 12.87 12.0965 12.3858C12.5157 12.142 12.8497 11.898 13.0809 11.7131C13.1968 11.6204 13.2877 11.542 13.3514 11.485C13.3832 11.4564 13.4083 11.4332 13.4264 11.4162L13.4483 11.3954L13.4552 11.3887L13.4577 11.3863L13.4586 11.3853C13.4588 11.3852 13.4594 11.3846 12.9334 10.85L13.4594 11.3846C13.6027 11.2436 13.6834 11.051 13.6834 10.85V6.46476L15.7667 5.63233V8.02486C15.7667 8.43908 16.1024 8.77486 16.5167 8.77486C16.9309 8.77486 17.2667 8.43908 17.2667 8.02486V4.56616C17.2667 4.55918 17.2666 4.55221 17.2664 4.54528C17.2666 4.53853 17.2666 4.53177 17.2666 4.525C17.2666 4.21822 17.0798 3.94237 16.7949 3.82854L8.91161 0.678541ZM12.1834 7.06413L8.91161 8.37146C8.73295 8.44285 8.5337 8.44285 8.35504 8.37146L5.08337 7.06418V10.5099C5.09595 10.5201 5.10903 10.5307 5.1226 10.5416C5.30756 10.6894 5.58056 10.8892 5.92436 11.0891C6.61777 11.4924 7.56151 11.875 8.63339 11.875C9.70526 11.875 10.649 11.4924 11.3424 11.0891C11.6862 10.8892 11.9592 10.6894 12.1442 10.5416C12.1577 10.5307 12.1708 10.5201 12.1834 10.5099V7.06413Z" fill="#3B6C98"/>
                        </svg>

export const certificate = <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 4.25C3.75 3.83579 4.08579 3.5 4.5 3.5H13.5C13.9142 3.5 14.25 3.83579 14.25 4.25C14.25 4.66421 13.9142 5 13.5 5H4.5C4.08579 5 3.75 4.66421 3.75 4.25Z" fill="#3B6C98"/>
                            <path d="M4.5 6.125C4.08579 6.125 3.75 6.46079 3.75 6.875C3.75 7.28921 4.08579 7.625 4.5 7.625H6.75C7.16421 7.625 7.5 7.28921 7.5 6.875C7.5 6.46079 7.16421 6.125 6.75 6.125H4.5Z" fill="#3B6C98"/>
                            <path d="M3.75 9.5C3.75 9.08579 4.08579 8.75 4.5 8.75H6C6.41421 8.75 6.75 9.08579 6.75 9.5C6.75 9.91421 6.41421 10.25 6 10.25H4.5C4.08579 10.25 3.75 9.91421 3.75 9.5Z" fill="#3B6C98"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 2C0.75 1.17157 1.42157 0.5 2.25 0.5H15.75C16.5784 0.5 17.25 1.17158 17.25 2V11.75C17.25 12.5784 16.5784 13.25 15.75 13.25H13.5V14.7502C13.5 15.0102 13.3654 15.2516 13.1443 15.3882C12.9232 15.5249 12.6471 15.5373 12.4146 15.421L11.25 14.8387L10.0854 15.421C9.85292 15.5373 9.57681 15.5249 9.3557 15.3882C9.13459 15.2516 9 15.0102 9 14.7502V13.25H2.25C1.42158 13.25 0.75 12.5784 0.75 11.75V2ZM13.5 11.75V11.1094C13.9668 10.5805 14.25 9.88584 14.25 9.125C14.25 7.46815 12.9069 6.125 11.25 6.125C9.59315 6.125 8.25 7.46815 8.25 9.125C8.25 9.88584 8.53323 10.5805 9 11.1094V11.75H2.25V2H15.75V11.75H13.5Z" fill="#3B6C98"/>
                            </svg>

export const project = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 1.5C3.75 1.08579 4.08579 0.75 4.5 0.75H13.5C13.9142 0.75 14.25 1.08579 14.25 1.5V3.375H16.5C16.9142 3.375 17.25 3.71079 17.25 4.125C17.25 6.78047 15.7061 8.40641 13.88 8.60452C13.1992 10.3596 11.6429 11.6698 9.74808 11.946C9.74935 11.9638 9.75 11.9818 9.75 12V12.75H10.8909C11.143 12.75 11.3782 12.8766 11.517 13.0871L13.0011 15.3371C13.153 15.5674 13.1662 15.8626 13.0354 16.1055C12.9046 16.3485 12.6509 16.5 12.375 16.5H5.625C5.35333 16.5 5.10289 16.3531 4.97034 16.1159C4.83778 15.8788 4.84383 15.5885 4.98615 15.3571L6.36991 13.1071C6.50639 12.8852 6.74824 12.75 7.00876 12.75H8.25V12C8.25 11.9818 8.25065 11.9638 8.25192 11.946C6.35709 11.6698 4.80075 10.3596 4.11999 8.60452C2.29394 8.40641 0.75 6.78047 0.75 4.125C0.75 3.71079 1.08579 3.375 1.5 3.375H3.75V1.5ZM3.75 4.875H2.30517C2.48688 6.04116 3.09267 6.69902 3.75 6.97267V4.875ZM14.25 4.875V6.97267C14.9073 6.69902 15.5131 6.04116 15.6948 4.875H14.25Z" fill="#3B6C98"/>
                        </svg>

export const industry = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.44922 0H0.527344C0.236109 0 0 0.236109 0 0.527344V1.75781H5.97656V0.527344C5.97656 0.236109 5.74045 0 5.44922 0Z" fill="#3B6C98"/>
                        <path d="M17.7351 5.02696C17.5712 4.93295 17.3695 4.93376 17.2065 5.02917L11.9883 8.08073V5.48438C11.9883 5.29545 11.8872 5.12096 11.7233 5.02696C11.5594 4.93295 11.3578 4.93376 11.1947 5.02917L5.97656 8.08073V2.8125H0V17.4727C0 17.7639 0.236109 18 0.527344 18H17.4727C17.7639 18 18 17.7639 18 17.4727V5.48438C18 5.29545 17.8989 5.12096 17.7351 5.02696ZM8.08594 14.291C8.08594 14.5822 7.84983 14.8184 7.55859 14.8184H5.44922C5.15798 14.8184 4.92188 14.5822 4.92188 14.291V12.1816C4.92188 11.8904 5.15798 11.6543 5.44922 11.6543H7.55859C7.84983 11.6543 8.08594 11.8904 8.08594 12.1816V14.291ZM14.0977 14.291C14.0977 14.5822 13.8615 14.8184 13.5703 14.8184H11.4609C11.1697 14.8184 10.9336 14.5822 10.9336 14.291V12.1816C10.9336 11.8904 11.1697 11.6543 11.4609 11.6543H13.5703C13.8615 11.6543 14.0977 11.8904 14.0977 12.1816V14.291Z" fill="#3B6C98"/>
                        </svg>

export const art_personal = <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M58.9042 7.0931C86.3513 5.47857 112.126 25.0376 118.975 52.1659C124.902 75.6447 104.045 94.1383 84.2906 107.652C67.3989 119.208 45.877 125.588 29.3761 113.461C12.737 101.231 11.3766 78.2812 16.9605 58.1667C23.5162 34.5516 34.857 8.50764 58.9042 7.0931Z" fill="#F8F1E4"/>
                            <path d="M100 22H27V106H100V22Z" fill="#292929"/>
                            <path d="M99 23H28V105H99V23Z" fill="white"/>
                            <path d="M63 101C65.2091 101 67 99.433 67 97.5C67 95.567 65.2091 94 63 94C60.7909 94 59 95.567 59 97.5C59 99.433 60.7909 101 63 101Z" fill="#E6E6E6"/>
                            <path d="M87.6113 95.5859C87.8913 95.5984 88.1683 95.5247 88.4049 95.3747C88.6414 95.2247 88.8261 95.0057 88.9338 94.7474C89.0414 94.4891 89.067 94.2039 89.0069 93.9306C88.9469 93.6572 88.8041 93.4088 88.598 93.2191C88.5633 93.0824 88.5384 92.9842 88.5037 92.8474C88.7705 92.1674 89.2346 91.5826 89.8366 91.1679C90.4386 90.7532 91.151 90.5275 91.8824 90.5197C92.6139 90.512 93.3311 90.7225 93.9421 91.1243C94.5532 91.5261 95.0302 92.1009 95.3121 92.7751C96.9385 96.5593 99.0417 100.476 98.0059 104.648C104.869 90.0645 102.497 71.9372 92.2969 59.4851C89.4808 57.941 87.1957 55.1943 86.5967 52.0025C87.99 52.4719 89.7242 51.3389 88.6748 49.8757C88.2201 49.3165 87.7609 48.7617 87.3062 48.2025C92.5863 42.5715 98.9034 51.4038 93.0938 59.0024C95.0024 61.3844 96.6565 63.9589 98.029 66.6841C97.7083 63.789 98.0805 60.8595 99.1149 58.1366C100.164 55.599 102.134 53.4604 103.868 51.2656C105.977 48.5912 110.329 49.8186 110.619 53.2088C108.669 53.8512 108.421 56.6644 110.652 57.0467C110.079 63.3548 105.961 69.1562 100.282 71.9293C102.391 77.8074 103.221 84.0663 102.717 90.2887C103.708 83.0161 111.522 81.242 116.981 78.3454C117.329 78.1223 117.734 78.005 118.147 78.0078C118.56 78.0106 118.963 78.1334 119.308 78.3612C119.652 78.589 119.923 78.9119 120.087 79.2907C120.251 79.6694 120.301 80.0875 120.231 80.494C119.477 80.8011 118.756 81.1822 118.078 81.6317C117.76 81.8456 117.517 82.1524 117.381 82.5101C117.244 82.8678 117.222 83.2589 117.317 83.6297C117.413 84.0004 117.62 84.3328 117.912 84.5812C118.204 84.8296 118.565 84.9819 118.947 85.0172C116.204 91.8266 109.091 96.4055 101.778 96.3886C101.08 99.4389 100.067 102.408 98.7539 105.249L87.8994 105.262C87.8603 105.142 87.8251 105.018 87.7898 104.897C88.7947 104.959 89.8032 104.898 90.793 104.715C88.1296 101.576 86.0712 99.9749 87.6109 95.5861L87.6113 95.5859Z" fill="#E4C695"/>
                            <path d="M63 53H40V54H63V53Z" fill="#E6E6E6"/>
                            <path d="M74 59H40V62H74V59Z" fill="#E4C695"/>
                            <path d="M71 67H40V69H71V67Z" fill="#E6E6E6"/>
                            <path d="M88 74H40V76H88V74Z" fill="#E4C695"/>
                            <path d="M80 80H40V82H80V80Z" fill="#E6E6E6"/>
                            <path d="M63.3332 28.5693C61.6453 28.5692 59.9954 29.0709 58.5919 30.0108C57.1885 30.9507 56.0946 32.2866 55.4486 33.8497C54.8026 35.4128 54.6335 37.1328 54.9627 38.7923C55.2919 40.4517 56.1046 41.976 57.298 43.1724C58.4915 44.3688 60.012 45.1836 61.6674 45.5138C63.3228 45.844 65.0387 45.6746 66.5981 45.0272C68.1575 44.3799 69.4904 43.2835 70.4282 41.8768C71.366 40.47 71.8666 38.8162 71.8667 37.1242V37.1238C71.8667 36.0004 71.646 34.888 71.2171 33.8501C70.7882 32.8123 70.1596 31.8692 69.3672 31.0749C68.5747 30.2805 67.6339 29.6504 66.5986 29.2205C65.5632 28.7906 64.4534 28.5693 63.3328 28.5693H63.3332ZM63.3332 31.1354C63.8395 31.1354 64.3344 31.2859 64.7554 31.5679C65.1764 31.8499 65.5045 32.2506 65.6983 32.7195C65.892 33.1884 65.9427 33.7044 65.844 34.2022C65.7452 34.6999 65.5014 35.1572 65.1434 35.5161C64.7854 35.8749 64.3292 36.1193 63.8326 36.2183C63.3361 36.3174 62.8213 36.2666 62.3536 36.0723C61.8858 35.8781 61.486 35.5492 61.2047 35.1272C60.9234 34.7052 60.7733 34.2091 60.7733 33.7016C60.7733 33.021 61.043 32.3683 61.523 31.887C62.0031 31.4058 62.6543 31.1354 63.3332 31.1354ZM63.3332 43.4898C62.3218 43.4852 61.3268 43.2333 60.4346 42.756C59.5423 42.2786 58.7797 41.5903 58.2128 40.7507C58.254 39.0398 61.6264 38.098 63.3332 38.098C65.04 38.098 68.4124 39.0398 68.4536 40.7507C67.8861 41.5897 67.1233 42.2776 66.2312 42.7548C65.3391 43.2321 64.3444 43.4843 63.3332 43.4898Z" fill="#E4C695"/>
                            </svg>

export const art_salary = <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M62.2718 9.01209C78.4816 8.56488 91.4655 20.5963 101.658 33.1792C111.957 45.8937 120.36 60.9898 117.398 77.0679C114.301 93.8837 102.909 109.66 86.6029 114.921C71.8777 119.671 58.6301 107.623 44.9262 100.449C32.1991 93.7866 16.219 89.6792 12.127 75.9335C7.84407 61.5464 16.5184 47.3002 25.5256 35.2796C35.0177 22.6117 46.4244 9.4493 62.2718 9.01209Z" fill="#F8F1E4"/>
                            <path d="M93.1398 116.613C106.254 116.613 116.892 106.171 116.892 93.2802C116.892 80.3895 106.254 69.9473 93.1398 69.9473C80.0259 69.9473 69.3872 80.3895 69.3872 93.2802C69.3872 106.171 80.0259 116.613 93.1398 116.613Z" fill="#E4C695" stroke="#292929" stroke-width="0.5"/>
                            <path d="M92.4639 106.699V103.292C90.7717 103.258 89.0211 102.651 88 101.807L88.7002 99.5469C89.9136 100.473 91.3852 100.981 92.9015 100.998C94.9731 100.998 96.3735 99.6143 96.3735 97.6912C96.3735 95.8356 95.2356 94.6885 93.0766 93.6763C90.1006 92.3268 88.2625 90.7747 88.2625 87.8396C88.2625 85.0392 89.9839 82.9136 92.6682 82.4076V79H94.4771V82.2727C95.8259 82.2914 97.1416 82.701 98.27 83.4535L97.5405 85.6803C96.4583 84.9262 95.1759 84.526 93.8644 84.5331C91.6179 84.5331 90.7717 86.085 90.7717 87.4347C90.7717 89.189 91.8512 90.0662 94.3896 91.2809C97.3947 92.6979 98.9119 94.4523 98.9119 97.455C98.9119 100.12 97.3072 102.617 94.302 103.19V106.699H92.4639Z" fill="#292929"/>
                            <path d="M20.8275 62.164H13.2115C12.8795 62.1636 12.5613 62.0313 12.3266 61.796C12.0919 61.5607 11.9598 61.2417 11.9595 60.909V49.9347C11.9598 49.602 12.0919 49.283 12.3266 49.0477C12.5613 48.8124 12.8795 48.6801 13.2115 48.6797H20.8275C21.1594 48.6801 21.4777 48.8124 21.7124 49.0477C21.9471 49.283 22.0791 49.602 22.0795 49.9347V60.909C22.0791 61.2417 21.9471 61.5607 21.7124 61.796C21.4777 62.0313 21.1594 62.1636 20.8275 62.164ZM13.2115 49.2811C13.0386 49.2813 12.8729 49.3503 12.7507 49.4728C12.6284 49.5953 12.5597 49.7614 12.5595 49.9347V60.909C12.5597 61.0823 12.6284 61.2484 12.7507 61.3709C12.8729 61.4934 13.0386 61.5623 13.2115 61.5626H20.8275C21.0004 61.5623 21.1661 61.4934 21.2883 61.3709C21.4105 61.2484 21.4793 61.0823 21.4795 60.909V49.9347C21.4793 49.7614 21.4105 49.5953 21.2883 49.4728C21.1661 49.3503 21.0004 49.2813 20.8275 49.2811H13.2115Z" fill="#324F64"/>
                            <path d="M34.7386 62.165H27.8609C27.4311 62.1645 27.019 61.9931 26.7151 61.6885C26.4112 61.3838 26.2403 60.9708 26.2397 60.54V38.377C26.2403 37.9462 26.4112 37.5331 26.7151 37.2285C27.019 36.9238 27.4311 36.7525 27.8609 36.752H34.7386C35.1684 36.7525 35.5805 36.9238 35.8844 37.2285C36.1883 37.5331 36.3593 37.9462 36.3598 38.377V60.54C36.3593 60.9708 36.1883 61.3838 35.8844 61.6885C35.5805 61.9931 35.1684 62.1645 34.7386 62.165ZM27.8609 37.3534C27.5902 37.3537 27.3306 37.4616 27.1392 37.6535C26.9477 37.8454 26.84 38.1056 26.8397 38.377V60.54C26.84 60.8113 26.9477 61.0715 27.1392 61.2634C27.3306 61.4553 27.5902 61.5633 27.8609 61.5636H34.7386C35.0094 61.5633 35.2689 61.4553 35.4604 61.2634C35.6518 61.0715 35.7595 60.8113 35.7598 60.54V38.377C35.7595 38.1056 35.6518 37.8454 35.4604 37.6535C35.2689 37.4616 35.0094 37.3537 34.7386 37.3534L27.8609 37.3534Z" fill="#324F64"/>
                            <path d="M48.7533 62.1637H42.4067C41.9065 62.1632 41.427 61.9637 41.0733 61.6092C40.7195 61.2546 40.5206 60.7739 40.52 60.2725V27.6676C40.5206 27.1662 40.7195 26.6855 41.0733 26.3309C41.427 25.9764 41.9065 25.7769 42.4067 25.7764H48.7533C49.2536 25.7769 49.7331 25.9764 50.0868 26.3309C50.4405 26.6855 50.6395 27.1662 50.6401 27.6676V60.2725C50.6395 60.7739 50.4405 61.2546 50.0868 61.6092C49.7331 61.9637 49.2536 62.1632 48.7533 62.1637ZM42.4067 26.3778C42.0656 26.3782 41.7385 26.5142 41.4973 26.756C41.2561 26.9978 41.1204 27.3257 41.12 27.6676V60.2725C41.1204 60.6144 41.2561 60.9423 41.4973 61.1841C41.7385 61.4259 42.0656 61.5619 42.4067 61.5623H48.7533C49.0945 61.5619 49.4215 61.4259 49.6628 61.1841C49.904 60.9423 50.0397 60.6144 50.0401 60.2725V27.6676C50.0397 27.3257 49.904 26.9978 49.6628 26.756C49.4215 26.5142 49.0945 26.3782 48.7533 26.3778H42.4067Z" fill="#324F64"/>
                            <path d="M19.8766 50.7695H14.1646V60.0738H19.8766V50.7695Z" fill="#E4C695"/>
                            <path d="M34.1554 38.96H28.4434V60.0736H34.1554V38.96Z" fill="#E4C695"/>
                            <path d="M48.4356 27.9873H42.7236V60.0753H48.4356V27.9873Z" fill="#E4C695"/>
                            <path d="M54.7 66.3744H7.3C7.2606 66.3745 7.22159 66.3667 7.18518 66.3516C7.14878 66.3365 7.1157 66.3143 7.08784 66.2864C7.05999 66.2585 7.03789 66.2253 7.02281 66.1888C7.00774 66.1523 6.99999 66.1132 7 66.0737V18.8603C7 18.7805 7.03161 18.704 7.08787 18.6476C7.14413 18.5913 7.22044 18.5596 7.3 18.5596C7.37956 18.5596 7.45587 18.5913 7.51213 18.6476C7.56839 18.704 7.6 18.7805 7.6 18.8603V65.773H54.7C54.7796 65.773 54.8559 65.8047 54.9121 65.8611C54.9684 65.9175 55 65.994 55 66.0737C55 66.1535 54.9684 66.23 54.9121 66.2864C54.8559 66.3428 54.7796 66.3744 54.7 66.3744Z" fill="#324F64"/>
                            <path d="M119.899 41.7164H87.2455C87.2133 41.7164 87.1813 41.71 87.1515 41.6977C87.1218 41.6853 87.0947 41.6672 87.0719 41.6443C87.0491 41.6215 87.031 41.5943 87.0187 41.5645C87.0063 41.5346 87 41.5026 87 41.4703V8.24611C87 8.18083 87.0259 8.11824 87.0719 8.07208C87.1179 8.02593 87.1804 8 87.2455 8C87.3106 8 87.3731 8.02593 87.4191 8.07208C87.4652 8.11824 87.491 8.18083 87.491 8.24611V41.2242H119.899C119.964 41.2242 120.026 41.2501 120.072 41.2963C120.119 41.3424 120.144 41.405 120.144 41.4703C120.144 41.5356 120.119 41.5982 120.072 41.6443C120.026 41.6905 119.964 41.7164 119.899 41.7164Z" fill="#324F64"/>
                            <path d="M98.5139 38.631H92.2811C92.0094 38.6307 91.7489 38.5224 91.5568 38.3299C91.3647 38.1373 91.2567 37.8763 91.2563 37.6039V28.6228C91.2567 28.3505 91.3647 28.0894 91.5568 27.8968C91.7489 27.7043 92.0094 27.596 92.2811 27.5957H98.5139C98.7855 27.596 99.0459 27.7043 99.238 27.8969C99.43 28.0894 99.5381 28.3505 99.5384 28.6228V37.6039C99.5381 37.8762 99.43 38.1373 99.238 38.3298C99.0459 38.5224 98.7855 38.6307 98.5139 38.631ZM92.2811 28.0879C92.1396 28.0881 92.004 28.1445 91.9039 28.2448C91.8039 28.345 91.7476 28.481 91.7474 28.6228V37.6039C91.7476 37.7458 91.8039 37.8817 91.9039 37.982C92.004 38.0822 92.1396 38.1387 92.2811 38.1388H98.5139C98.6553 38.1386 98.7909 38.0822 98.8909 37.982C98.9909 37.8817 99.0472 37.7457 99.0473 37.6039V28.6228C99.0472 28.481 98.9909 28.345 98.8909 28.2448C98.7909 28.1445 98.6553 28.0881 98.5139 28.0879H92.2811Z" fill="#324F64"/>
                            <path d="M111.278 38.6315H105.541C105.204 38.6311 104.88 38.4965 104.642 38.2574C104.403 38.0182 104.269 37.6939 104.269 37.3557V21.0882C104.269 20.75 104.403 20.4257 104.642 20.1866C104.88 19.9474 105.204 19.8129 105.541 19.8125H111.278C111.615 19.8129 111.939 19.9474 112.177 20.1866C112.416 20.4257 112.55 20.75 112.551 21.0882V37.3557C112.55 37.6939 112.416 38.0182 112.177 38.2574C111.939 38.4965 111.615 38.6311 111.278 38.6315ZM105.541 20.3047C105.334 20.3049 105.135 20.3875 104.989 20.5344C104.842 20.6813 104.76 20.8805 104.76 21.0882V37.3557C104.76 37.5634 104.842 37.7626 104.989 37.9095C105.135 38.0564 105.334 38.139 105.541 38.1393H111.278C111.485 38.139 111.684 38.0564 111.83 37.9095C111.977 37.7626 112.059 37.5634 112.06 37.3557V21.0882C112.059 20.8805 111.977 20.6813 111.83 20.5344C111.684 20.3876 111.485 20.3049 111.278 20.3047H105.541Z" fill="#324F64"/>
                            <path d="M97.6223 29.3916H92.9478V37.0061H97.6223V29.3916Z" fill="#E4C695"/>
                            <path d="M110.747 21.8555H106.073V36.5882H110.747V21.8555Z" fill="#E4C695"/>
                            <path d="M95.3468 24.9807C96.5672 24.9807 97.5565 23.989 97.5565 22.7657C97.5565 21.5424 96.5672 20.5508 95.3468 20.5508C94.1265 20.5508 93.1372 21.5424 93.1372 22.7657C93.1372 23.989 94.1265 24.9807 95.3468 24.9807Z" fill="#E4C695"/>
                            <path d="M108.36 16.6125C109.58 16.6125 110.569 15.6208 110.569 14.3976C110.569 13.1743 109.58 12.1826 108.36 12.1826C107.139 12.1826 106.15 13.1743 106.15 14.3976C106.15 15.6208 107.139 16.6125 108.36 16.6125Z" fill="#E4C695"/>
                            </svg>
                        
export const art_skills = <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M72.5338 42.6689H22.0257C21.1172 42.6805 20.2381 42.3482 19.5653 41.7389C18.8924 41.1295 18.4758 40.2883 18.3992 39.3847C18.3665 38.9027 18.4333 38.4191 18.5955 37.964C18.7576 37.5088 19.0118 37.0918 19.3421 36.7387C19.6724 36.3856 20.0719 36.1041 20.5157 35.9115C20.9595 35.7189 21.4383 35.6194 21.9222 35.6191H72.5338C73.4703 35.6191 74.3685 35.9905 75.0307 36.6515C75.6929 37.3126 76.0649 38.2091 76.0649 39.144C76.0649 40.0789 75.6929 40.9754 75.0307 41.6365C74.3685 42.2975 73.4703 42.6689 72.5338 42.6689H72.5338Z" fill="#E4C695"/>
                            <path d="M101.587 48.397H18.5384C18.4604 48.397 18.3855 48.3661 18.3303 48.311C18.2751 48.2559 18.2441 48.1812 18.2441 48.1033C18.2441 48.0254 18.2751 47.9507 18.3303 47.8956C18.3855 47.8405 18.4604 47.8096 18.5384 47.8096H101.587C101.666 47.8096 101.74 47.8405 101.796 47.8956C101.851 47.9507 101.882 48.0254 101.882 48.1033C101.882 48.1812 101.851 48.2559 101.796 48.311C101.74 48.3661 101.666 48.397 101.587 48.397Z" fill="#E6E6E6"/>
                            <path d="M98.134 42.8161H21.9223C20.9468 42.8161 20.0112 42.4293 19.3215 41.7407C18.6317 41.0521 18.2441 40.1182 18.2441 39.1444C18.2441 38.1706 18.6317 37.2367 19.3215 36.5481C20.0112 35.8595 20.9468 35.4727 21.9223 35.4727H98.134C99.1095 35.4727 100.045 35.8595 100.735 36.5481C101.425 37.2367 101.812 38.1706 101.812 39.1444C101.812 40.1182 101.425 41.0521 100.735 41.7407C100.045 42.4293 99.1095 42.8161 98.134 42.8161ZM21.9223 36.0601C21.1029 36.0601 20.317 36.3851 19.7376 36.9635C19.1582 37.5419 18.8326 38.3264 18.8326 39.1444C18.8326 39.9624 19.1582 40.7469 19.7376 41.3253C20.317 41.9037 21.1029 42.2286 21.9223 42.2286H98.134C98.9534 42.2286 99.7393 41.9037 100.319 41.3253C100.898 40.7469 101.224 39.9624 101.224 39.1444C101.224 38.3264 100.898 37.5419 100.319 36.9635C99.7393 36.3851 98.9534 36.0601 98.134 36.0601H21.9223Z" fill="#324F64"/>
                            <path d="M49.8763 70.2792H22.0257C21.1172 70.2909 20.2381 69.9586 19.5653 69.3492C18.8924 68.7399 18.4758 67.8987 18.3992 66.995C18.3665 66.513 18.4333 66.0295 18.5955 65.5743C18.7576 65.1192 19.0118 64.7021 19.3421 64.3491C19.6724 63.996 20.0719 63.7144 20.5157 63.5219C20.9595 63.3293 21.4383 63.2298 21.9222 63.2295H49.8763C50.8128 63.2295 51.7109 63.6009 52.3731 64.2619C53.0353 64.9229 53.4074 65.8195 53.4074 66.7544C53.4074 67.6892 53.0353 68.5858 52.3731 69.2468C51.7109 69.9078 50.8128 70.2792 49.8763 70.2792H49.8763Z" fill="#E4C695"/>
                            <path d="M101.587 76.0074H18.5384C18.4604 76.0074 18.3855 75.9764 18.3303 75.9214C18.2751 75.8663 18.2441 75.7916 18.2441 75.7137C18.2441 75.6358 18.2751 75.561 18.3303 75.506C18.3855 75.4509 18.4604 75.4199 18.5384 75.4199H101.587C101.666 75.4199 101.74 75.4509 101.796 75.506C101.851 75.561 101.882 75.6358 101.882 75.7137C101.882 75.7916 101.851 75.8663 101.796 75.9214C101.74 75.9764 101.666 76.0074 101.587 76.0074Z" fill="#E6E6E6"/>
                            <path d="M98.134 70.4264H21.9223C20.9468 70.4264 20.0112 70.0396 19.3215 69.351C18.6317 68.6624 18.2441 67.7285 18.2441 66.7547C18.2441 65.7809 18.6317 64.847 19.3215 64.1584C20.0112 63.4698 20.9468 63.083 21.9223 63.083H98.134C99.1095 63.083 100.045 63.4698 100.735 64.1584C101.425 64.847 101.812 65.7809 101.812 66.7547C101.812 67.7285 101.425 68.6624 100.735 69.351C100.045 70.0396 99.1095 70.4264 98.134 70.4264ZM21.9223 63.6705C21.1029 63.6705 20.317 63.9954 19.7376 64.5738C19.1582 65.1522 18.8326 65.9367 18.8326 66.7547C18.8326 67.5727 19.1582 68.3572 19.7376 68.9356C20.317 69.514 21.1029 69.839 21.9223 69.839H98.134C98.9534 69.839 99.7393 69.514 100.319 68.9356C100.898 68.3572 101.224 67.5727 101.224 66.7547C101.224 65.9367 100.898 65.1522 100.319 64.5738C99.7393 63.9954 98.9534 63.6705 98.134 63.6705H21.9223Z" fill="#324F64"/>
                            <path d="M87.2465 97.8915H22.0257C21.1172 97.9032 20.2381 97.5709 19.5653 96.9615C18.8924 96.3522 18.4758 95.511 18.3992 94.6073C18.3665 94.1253 18.4333 93.6418 18.5955 93.1866C18.7576 92.7315 19.0118 92.3144 19.3421 91.9614C19.6724 91.6083 20.0719 91.3267 20.5157 91.1342C20.9595 90.9416 21.4383 90.8421 21.9222 90.8418H87.2465C88.183 90.8418 89.0812 91.2132 89.7434 91.8742C90.4056 92.5352 90.7776 93.4318 90.7776 94.3667C90.7776 95.3015 90.4056 96.1981 89.7434 96.8591C89.0812 97.5201 88.183 97.8915 87.2465 97.8915H87.2465Z" fill="#E4C695"/>
                            <path d="M101.587 103.62H18.5384C18.4604 103.62 18.3855 103.589 18.3303 103.534C18.2751 103.479 18.2441 103.404 18.2441 103.326C18.2441 103.248 18.2751 103.173 18.3303 103.118C18.3855 103.063 18.4604 103.032 18.5384 103.032H101.587C101.666 103.032 101.74 103.063 101.796 103.118C101.851 103.173 101.882 103.248 101.882 103.326C101.882 103.404 101.851 103.479 101.796 103.534C101.74 103.589 101.666 103.62 101.587 103.62Z" fill="#E6E6E6"/>
                            <path d="M98.134 98.0388H21.9223C20.9468 98.0388 20.0112 97.6519 19.3215 96.9633C18.6317 96.2748 18.2441 95.3408 18.2441 94.367C18.2441 93.3932 18.6317 92.4593 19.3215 91.7707C20.0112 91.0822 20.9468 90.6953 21.9223 90.6953H98.134C99.1095 90.6953 100.045 91.0822 100.735 91.7707C101.425 92.4593 101.812 93.3932 101.812 94.367C101.812 95.3408 101.425 96.2748 100.735 96.9633C100.045 97.6519 99.1095 98.0388 98.134 98.0388ZM21.9223 91.2828C21.1029 91.2828 20.317 91.6077 19.7376 92.1861C19.1582 92.7646 18.8326 93.549 18.8326 94.367C18.8326 95.185 19.1582 95.9695 19.7376 96.5479C20.317 97.1263 21.1029 97.4513 21.9223 97.4513H98.134C98.9534 97.4513 99.7393 97.1263 100.319 96.5479C100.898 95.9695 101.224 95.185 101.224 94.367C101.224 93.549 100.898 92.7646 100.319 92.1861C99.7393 91.6077 98.9534 91.2828 98.134 91.2828H21.9223Z" fill="#324F64"/>
                            <path d="M67.7413 39.0856H67.1528V16.4551H109.719V17.0426H67.7413V39.0856Z" fill="#CCCCCC"/>
                            <path d="M111.538 20.0179C113.479 20.0179 115.053 18.4469 115.053 16.509C115.053 14.571 113.479 13 111.538 13C109.596 13 108.022 14.571 108.022 16.509C108.022 18.4469 109.596 20.0179 111.538 20.0179Z" fill="#CCCCCC"/>
                            <path d="M47.3118 66.6979H47.9003V53.7607H5.33447V54.3482H47.3118V66.6979Z" fill="#CCCCCC"/>
                            <path d="M3.51512 57.3226C5.45647 57.3226 7.03025 55.7516 7.03025 53.8137C7.03025 51.8757 5.45647 50.3047 3.51512 50.3047C1.57377 50.3047 0 51.8757 0 53.8137C0 55.7516 1.57377 57.3226 3.51512 57.3226Z" fill="#CCCCCC"/>
                            <path d="M87.1617 95.3066H86.5732V111.475H122.666V110.887H87.1617V95.3066Z" fill="#CCCCCC"/>
                            <path d="M124.485 114.928C126.426 114.928 128 113.357 128 111.419C128 109.481 126.426 107.91 124.485 107.91C122.544 107.91 120.97 109.481 120.97 111.419C120.97 113.357 122.544 114.928 124.485 114.928Z" fill="#CCCCCC"/>
                          </svg>

export const art_work_exp = <svg width="128" height="110" viewBox="0 0 128 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M108.028 86.5619L49.1333 106.503C48.3999 106.751 47.5984 106.697 46.9048 106.353C46.2112 106.009 45.682 105.404 45.4334 104.669L18.1528 23.8015C17.9058 23.0667 17.9599 22.2638 18.3031 21.5688C18.6463 20.8739 19.2506 20.3438 19.9835 20.0947L78.8779 0.153076C79.6114 -0.0943473 80.4128 -0.0401943 81.1065 0.303653C81.8001 0.6475 82.3293 1.25296 82.5779 1.9872L109.858 82.855C110.105 83.5898 110.051 84.3928 109.708 85.0877C109.365 85.7826 108.761 86.3128 108.028 86.5619Z" fill="#E6E6E6"/>
                            <path d="M106.525 85.0685L49.424 104.403C48.9285 104.57 48.3869 104.533 47.9183 104.301C47.4496 104.069 47.0921 103.66 46.9241 103.163L20.2497 24.0927C20.0829 23.5962 20.1194 23.0537 20.3513 22.5841C20.5832 22.1146 20.9915 21.7564 21.4867 21.5881L78.5874 2.25382C79.0829 2.08664 79.6245 2.12323 80.0931 2.35556C80.5618 2.58789 80.9194 2.99699 81.0873 3.4931L107.762 82.5639C107.929 83.0604 107.892 83.6029 107.66 84.0724C107.428 84.542 107.02 84.9002 106.525 85.0685Z" fill="white"/>
                            <path d="M92.1701 109.762H30.003C29.2291 109.761 28.4871 109.453 27.9399 108.905C27.3927 108.356 27.0849 107.613 27.084 106.838V21.4762C27.0849 20.7008 27.3927 19.9575 27.9399 19.4093C28.4871 18.861 29.2291 18.5526 30.003 18.5518H92.1701C92.944 18.5526 93.6859 18.861 94.2332 19.4093C94.7804 19.9575 95.0882 20.7008 95.0891 21.4762V106.838C95.0882 107.613 94.7804 108.356 94.2332 108.905C93.6859 109.453 92.944 109.761 92.1701 109.762Z" fill="#CCCCCC"/>
                            <path d="M91.2235 107.865H30.9498C30.4269 107.864 29.9256 107.656 29.5559 107.285C29.1861 106.915 28.9781 106.413 28.9775 105.889V22.4242C28.9781 21.9003 29.1861 21.3981 29.5559 21.0276C29.9256 20.6572 30.4269 20.4488 30.9498 20.4482H91.2235C91.7464 20.4488 92.2478 20.6572 92.6175 21.0276C92.9873 21.3981 93.1953 21.9003 93.1958 22.4242V105.889C93.1953 106.413 92.9873 106.915 92.6175 107.285C92.2478 107.656 91.7464 107.864 91.2235 107.865Z" fill="white"/>
                            <path d="M78.3829 44.1245H38.5736C38.2382 44.1245 37.9166 43.991 37.6794 43.7535C37.4423 43.5159 37.3091 43.1937 37.3091 42.8577C37.3091 42.5217 37.4423 42.1994 37.6794 41.9619C37.9166 41.7243 38.2382 41.5908 38.5736 41.5908H78.3829C78.7183 41.5908 79.0399 41.7243 79.277 41.9619C79.5142 42.1994 79.6474 42.5217 79.6474 42.8577C79.6474 43.1937 79.5142 43.5159 79.277 43.7535C79.0399 43.991 78.7183 44.1245 78.3829 44.1245Z" fill="#CCCCCC"/>
                            <path d="M83.599 48.3999H38.5736C38.4075 48.3999 38.2431 48.3671 38.0897 48.3035C37.9363 48.2398 37.7969 48.1465 37.6794 48.0289C37.562 47.9112 37.4689 47.7716 37.4053 47.6179C37.3418 47.4642 37.3091 47.2994 37.3091 47.1331C37.3091 46.9667 37.3418 46.802 37.4053 46.6483C37.4689 46.4946 37.562 46.3549 37.6794 46.2373C37.7969 46.1196 37.9363 46.0263 38.0897 45.9626C38.2431 45.899 38.4075 45.8662 38.5736 45.8662H83.599C83.9343 45.8662 84.256 45.9997 84.4931 46.2373C84.7302 46.4748 84.8635 46.7971 84.8635 47.1331C84.8635 47.469 84.7302 47.7913 84.4931 48.0289C84.256 48.2664 83.9343 48.3999 83.599 48.3999Z" fill="#CCCCCC"/>
                            <path d="M78.383 63.2856H38.5736C38.2385 63.2853 37.9172 63.1516 37.6804 62.9141C37.4436 62.6765 37.3105 62.3545 37.3105 62.0188C37.3105 61.6831 37.4436 61.361 37.6804 61.1235C37.9172 60.886 38.2385 60.7523 38.5736 60.752H78.383C78.7183 60.752 79.04 60.8854 79.2771 61.123C79.5142 61.3606 79.6475 61.6828 79.6475 62.0188C79.6475 62.3548 79.5142 62.677 79.2771 62.9146C79.04 63.1522 78.7183 63.2856 78.383 63.2856Z" fill="#E4C695"/>
                            <path d="M83.599 67.5611H38.5736C38.2385 67.5607 37.9172 67.427 37.6804 67.1895C37.4436 66.952 37.3105 66.6299 37.3105 66.2942C37.3105 65.9585 37.4436 65.6364 37.6804 65.3989C37.9172 65.1613 38.2385 65.0277 38.5736 65.0273H83.599C83.9344 65.0273 84.256 65.1608 84.4932 65.3984C84.7303 65.636 84.8635 65.9582 84.8635 66.2942C84.8635 66.6302 84.7303 66.9524 84.4932 67.19C84.256 67.4276 83.9344 67.5611 83.599 67.5611Z" fill="#CCCCCC"/>
                            <path d="M78.383 82.4468H38.5736C38.2385 82.4464 37.9172 82.3128 37.6804 82.0752C37.4436 81.8377 37.3105 81.5157 37.3105 81.1799C37.3105 80.8442 37.4436 80.5222 37.6804 80.2846C37.9172 80.0471 38.2385 79.9135 38.5736 79.9131H78.383C78.7183 79.9131 79.04 80.0466 79.2771 80.2841C79.5142 80.5217 79.6475 80.8439 79.6475 81.1799C79.6475 81.5159 79.5142 81.8382 79.2771 82.0757C79.04 82.3133 78.7183 82.4468 78.383 82.4468Z" fill="#CCCCCC"/>
                            <path d="M83.599 86.7222H38.5736C38.2382 86.7222 37.9166 86.5887 37.6794 86.3511C37.4423 86.1135 37.3091 85.7913 37.3091 85.4553C37.3091 85.1193 37.4423 84.7971 37.6794 84.5595C37.9166 84.3219 38.2382 84.1885 38.5736 84.1885H83.599C83.9343 84.1885 84.256 84.3219 84.4931 84.5595C84.7302 84.7971 84.8635 85.1193 84.8635 85.4553C84.8635 85.7913 84.7302 86.1135 84.4931 86.3511C84.256 86.5887 83.9343 86.7222 83.599 86.7222Z" fill="#CCCCCC"/>
                            </svg>

export const art_education = <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M81.568 86.9697V109.147H25.5338C19.1494 109.147 13.9551 104.173 13.9551 98.0585C13.9551 91.9443 19.1494 86.9697 25.5338 86.9697H81.568Z" fill="#F2F2F2"/>
                                <path d="M82.9445 108.977C82.9444 109.203 82.8538 109.42 82.6925 109.58C82.5312 109.74 82.3124 109.83 82.0843 109.83H24.5359C21.4104 109.83 18.4128 108.598 16.2027 106.407C13.9926 104.215 12.751 101.243 12.751 98.1438C12.751 95.0446 13.9926 92.0722 16.2027 89.8807C18.4128 87.6892 21.4104 86.458 24.5359 86.458H82.0843C82.3124 86.458 82.5312 86.5479 82.6926 86.7078C82.8539 86.8678 82.9445 87.0848 82.9445 87.311C82.9445 87.5372 82.8539 87.7542 82.6926 87.9141C82.5312 88.0741 82.3124 88.164 82.0843 88.164H24.5359C21.8666 88.164 19.3067 89.2154 17.4192 91.087C15.5318 92.9586 14.4714 95.497 14.4714 98.1438C14.4714 100.791 15.5318 103.329 17.4192 105.201C19.3067 107.072 21.8666 108.124 24.5359 108.124H82.0843C82.3124 108.124 82.5312 108.214 82.6925 108.374C82.8538 108.534 82.9444 108.75 82.9445 108.977Z" fill="#E4C695"/>
                                <path d="M81.5683 92.0873H21.3532C21.3076 92.0873 21.2638 92.0693 21.2315 92.0373C21.1993 92.0053 21.1812 91.9619 21.1812 91.9167C21.1812 91.8714 21.1993 91.8281 21.2315 91.7961C21.2638 91.7641 21.3076 91.7461 21.3532 91.7461H81.5683C81.6139 91.7461 81.6577 91.7641 81.6899 91.7961C81.7222 91.8281 81.7403 91.8714 81.7403 91.9167C81.7403 91.9619 81.7222 92.0053 81.6899 92.0373C81.6577 92.0693 81.6139 92.0873 81.5683 92.0873Z" fill="#CCCCCC"/>
                                <path d="M81.5683 96.182H21.3532C21.3076 96.182 21.2638 96.164 21.2315 96.132C21.1993 96.1001 21.1812 96.0567 21.1812 96.0114C21.1812 95.9662 21.1993 95.9228 21.2315 95.8908C21.2638 95.8588 21.3076 95.8408 21.3532 95.8408H81.5683C81.6139 95.8408 81.6577 95.8588 81.6899 95.8908C81.7222 95.9228 81.7403 95.9662 81.7403 96.0114C81.7403 96.0567 81.7222 96.1001 81.6899 96.132C81.6577 96.164 81.6139 96.182 81.5683 96.182Z" fill="#CCCCCC"/>
                                <path d="M81.5683 100.276H21.3532C21.3076 100.276 21.2638 100.258 21.2315 100.226C21.1993 100.194 21.1812 100.15 21.1812 100.105C21.1812 100.06 21.1993 100.017 21.2315 99.9845C21.2638 99.9525 21.3076 99.9346 21.3532 99.9346H81.5683C81.6139 99.9346 81.6577 99.9525 81.6899 99.9845C81.7222 100.017 81.7403 100.06 81.7403 100.105C81.7403 100.15 81.7222 100.194 81.6899 100.226C81.6577 100.258 81.6139 100.276 81.5683 100.276Z" fill="#CCCCCC"/>
                                <path d="M81.5683 104.37H21.3532C21.3076 104.37 21.2638 104.353 21.2315 104.321C21.1993 104.289 21.1812 104.245 21.1812 104.2C21.1812 104.155 21.1993 104.111 21.2315 104.079C21.2638 104.047 21.3076 104.029 21.3532 104.029H81.5683C81.6139 104.029 81.6577 104.047 81.6899 104.079C81.7222 104.111 81.7403 104.155 81.7403 104.2C81.7403 104.245 81.7222 104.289 81.6899 104.321C81.6577 104.353 81.6139 104.37 81.5683 104.37Z" fill="#CCCCCC"/>
                                <path d="M124.874 91.1445L110.841 108.414L67.2087 73.5523C62.2374 69.5803 61.3402 62.4751 65.2089 57.7141C69.0776 52.9531 76.27 52.3112 81.2414 56.2831L124.874 91.1445Z" fill="#F2F2F2"/>
                                <path d="M112.021 109.136C111.878 109.313 111.67 109.425 111.443 109.449C111.216 109.473 110.989 109.407 110.812 109.265L65.9998 73.4621C63.5659 71.5176 62.0108 68.694 61.6765 65.6125C61.3422 62.531 62.2561 59.444 64.2172 57.0307C66.1782 54.6174 69.0258 53.0753 72.1334 52.7438C75.241 52.4123 78.3542 53.3186 80.788 55.2631L125.6 91.0665C125.688 91.1367 125.761 91.2235 125.815 91.3218C125.869 91.4201 125.904 91.5281 125.916 91.6395C125.928 91.7509 125.918 91.8636 125.886 91.9711C125.854 92.0786 125.801 92.1789 125.73 92.2662C125.659 92.3534 125.572 92.426 125.473 92.4797C125.373 92.5333 125.265 92.5671 125.152 92.5791C125.04 92.591 124.926 92.5809 124.818 92.5493C124.709 92.5177 124.608 92.4652 124.52 92.3949L79.7086 56.5915C78.6795 55.7686 77.4969 55.1548 76.2285 54.7852C74.9602 54.4156 73.6308 54.2974 72.3163 54.4373C71.0018 54.5773 69.728 54.9727 68.5677 55.6009C67.4074 56.2291 66.3832 57.0778 65.5538 58.0986C64.7243 59.1194 64.1058 60.2922 63.7335 61.55C63.3612 62.8079 63.2426 64.1262 63.3842 65.4295C63.5259 66.7329 63.9251 67.9958 64.5591 69.1461C65.1931 70.2964 66.0494 71.3117 67.0792 72.1337L111.891 107.937C112.069 108.079 112.182 108.285 112.206 108.51C112.231 108.735 112.164 108.96 112.021 109.136Z" fill="#CCCCCC"/>
                                <path d="M121.636 95.1291L74.7477 57.6667C74.7301 57.6527 74.7155 57.6353 74.7046 57.6156C74.6938 57.596 74.687 57.5744 74.6846 57.5521C74.6822 57.5298 74.6842 57.5073 74.6906 57.4858C74.6969 57.4643 74.7075 57.4443 74.7217 57.4268C74.7358 57.4094 74.7533 57.3949 74.7732 57.3842C74.793 57.3734 74.8148 57.3667 74.8372 57.3643C74.8597 57.3619 74.8824 57.3639 74.9041 57.3702C74.9258 57.3765 74.946 57.387 74.9636 57.401L121.852 94.8634C121.869 94.8775 121.884 94.8948 121.895 94.9145C121.906 94.9342 121.913 94.9558 121.915 94.9781C121.918 95.0004 121.916 95.0229 121.909 95.0444C121.903 95.0659 121.892 95.086 121.878 95.1035C121.864 95.1209 121.846 95.1354 121.826 95.1462C121.807 95.1569 121.785 95.1637 121.762 95.166C121.74 95.1684 121.717 95.1664 121.695 95.1601C121.674 95.1537 121.654 95.1432 121.636 95.1291V95.1291Z" fill="#CCCCCC"/>
                                <path d="M119.045 98.3175L72.1572 60.8551C72.1217 60.8266 72.0991 60.7854 72.0942 60.7405C72.0894 60.6955 72.1027 60.6505 72.1313 60.6153C72.16 60.5801 72.2015 60.5576 72.2468 60.5527C72.2921 60.5479 72.3375 60.5611 72.3731 60.5894L119.261 98.0518C119.279 98.0658 119.294 98.0832 119.304 98.1028C119.315 98.1225 119.322 98.1441 119.325 98.1664C119.327 98.1887 119.325 98.2112 119.319 98.2328C119.312 98.2543 119.302 98.2743 119.288 98.2918C119.273 98.3093 119.256 98.3238 119.236 98.3345C119.216 98.3453 119.194 98.352 119.172 98.3544C119.149 98.3568 119.127 98.3547 119.105 98.3484C119.083 98.3421 119.063 98.3316 119.045 98.3175Z" fill="#CCCCCC"/>
                                <path d="M116.455 101.506L69.5665 64.0437C69.531 64.0153 69.5083 63.9741 69.5034 63.9291C69.4985 63.8841 69.5119 63.839 69.5405 63.8038C69.5691 63.7686 69.6107 63.7461 69.6561 63.7412C69.7015 63.7364 69.7469 63.7496 69.7824 63.778L116.671 101.24C116.688 101.254 116.703 101.272 116.714 101.291C116.725 101.311 116.731 101.333 116.734 101.355C116.736 101.377 116.734 101.4 116.728 101.421C116.721 101.443 116.711 101.463 116.697 101.48C116.683 101.498 116.665 101.512 116.645 101.523C116.625 101.534 116.604 101.54 116.581 101.543C116.559 101.545 116.536 101.543 116.514 101.537C116.493 101.531 116.472 101.52 116.455 101.506V101.506Z" fill="#CCCCCC"/>
                                <path d="M113.864 104.694L66.9757 67.2312C66.9581 67.2171 66.9435 67.1998 66.9327 67.1801C66.9218 67.1604 66.915 67.1388 66.9126 67.1166C66.9102 67.0943 66.9122 67.0718 66.9186 67.0503C66.925 67.0288 66.9355 67.0087 66.9497 66.9913C66.9639 66.9738 66.9814 66.9593 67.0012 66.9486C67.021 66.9379 67.0428 66.9311 67.0653 66.9287C67.0877 66.9263 67.1104 66.9283 67.1321 66.9346C67.1538 66.9409 67.174 66.9514 67.1916 66.9655L114.08 104.428C114.097 104.442 114.112 104.459 114.123 104.479C114.134 104.499 114.141 104.52 114.143 104.543C114.146 104.565 114.144 104.587 114.137 104.609C114.131 104.63 114.12 104.65 114.106 104.668C114.092 104.685 114.074 104.7 114.055 104.711C114.035 104.721 114.013 104.728 113.99 104.73C113.968 104.733 113.945 104.731 113.923 104.725C113.902 104.718 113.882 104.708 113.864 104.694Z" fill="#CCCCCC"/>
                                <path d="M127.828 110H0.172043C0.126414 110 0.0826544 109.982 0.0503901 109.95C0.0181258 109.918 0 109.875 0 109.83C0 109.785 0.0181258 109.741 0.0503901 109.709C0.0826544 109.677 0.126414 109.659 0.172043 109.659H127.828C127.874 109.659 127.917 109.677 127.95 109.709C127.982 109.741 128 109.785 128 109.83C128 109.875 127.982 109.918 127.95 109.95C127.917 109.982 127.874 110 127.828 110Z" fill="#CCCCCC"/>
                                <path d="M38.968 57.1039C33.8743 57.0707 28.8443 55.9783 24.2029 53.8973C23.9266 53.7752 23.6921 53.5761 23.528 53.3241C23.3638 53.0722 23.2771 52.7784 23.2783 52.4784V39.8878C23.2788 39.4753 23.4442 39.0799 23.7384 38.7882C24.0325 38.4965 24.4313 38.3325 24.8473 38.332H53.0887C53.5046 38.3325 53.9034 38.4966 54.1976 38.7882C54.4917 39.0799 54.6571 39.4753 54.6576 39.8878V52.4785C54.6589 52.7784 54.5721 53.0722 54.408 53.3241C54.2438 53.5761 54.0093 53.7752 53.733 53.8973C49.0917 55.9784 44.0617 57.0707 38.968 57.1039Z" fill="#E4C695"/>
                                <path d="M38.9638 46.9046C38.7544 46.9048 38.5471 46.8635 38.3541 46.7831L15.9591 37.414C15.6703 37.2932 15.4246 37.0894 15.2537 36.8289C15.0828 36.5683 14.9945 36.263 15.0003 35.9522C15.006 35.6415 15.1055 35.3395 15.2859 35.0854C15.4663 34.8312 15.7194 34.6364 16.0124 34.5262L38.4078 26.1016C38.7685 25.9653 39.1673 25.9662 39.5274 26.104L61.6227 34.5285C61.9141 34.6397 62.1654 34.8345 62.3446 35.088C62.5237 35.3416 62.6224 35.6424 62.6282 35.9519C62.634 36.2615 62.5465 36.5657 62.3769 36.8256C62.2074 37.0856 61.9635 37.2894 61.6764 37.4112L39.5809 46.7801C39.3858 46.8625 39.1759 46.9048 38.9638 46.9046Z" fill="#E4C695"/>
                                <path d="M25.8927 54.7542C27.0481 54.7542 27.9847 53.8255 27.9847 52.6798C27.9847 51.5342 27.0481 50.6055 25.8927 50.6055C24.7374 50.6055 23.8008 51.5342 23.8008 52.6798C23.8008 53.8255 24.7374 54.7542 25.8927 54.7542Z" fill="#324F64"/>
                                <path opacity="0.2" d="M61.7531 36.0804L39.6575 45.4493C39.4852 45.5224 39.2999 45.5602 39.1126 45.5607C38.9252 45.5611 38.7397 45.5242 38.5671 45.4519L16.1721 36.0832C15.8908 35.9683 15.6562 35.7638 15.5052 35.502C15.4065 35.6718 15.3453 35.8605 15.3257 36.0555C15.3061 36.2505 15.3286 36.4475 15.3917 36.6332C15.4548 36.819 15.557 36.9893 15.6915 37.1329C15.826 37.2765 15.9899 37.3901 16.1721 37.4661L38.5671 46.8349C38.7397 46.9071 38.9252 46.944 39.1126 46.9436C39.2999 46.9432 39.4852 46.9053 39.6575 46.8322L61.7531 37.4633C61.9344 37.3867 62.0972 37.2727 62.2308 37.1291C62.3644 36.9854 62.4658 36.8153 62.5282 36.6299C62.5906 36.4446 62.6127 36.2482 62.5929 36.0538C62.5732 35.8593 62.5121 35.6712 62.4136 35.502C62.2638 35.7617 62.0315 35.9651 61.7531 36.0804Z" fill="black"/>
                                <path d="M26.0684 52.6708C26.1609 52.6708 26.2495 52.6343 26.3149 52.5695C26.3803 52.5047 26.417 52.4167 26.417 52.325V40.7565L37.9162 36.6026C38.0087 36.604 38.0979 36.5688 38.1643 36.505C38.2307 36.4411 38.2687 36.3537 38.2701 36.262C38.2715 36.1703 38.2361 36.0818 38.1716 36.016C38.1072 35.9502 38.0191 35.9125 37.9266 35.9111L26.4274 40.0651C26.3349 40.063 26.2429 40.0795 26.157 40.1137C26.0711 40.1479 25.9931 40.199 25.9277 40.2639C25.8616 40.3281 25.8091 40.4048 25.7734 40.4894C25.7377 40.574 25.7194 40.6648 25.7197 40.7565V52.325C25.7197 52.4167 25.7565 52.5047 25.8219 52.5695C25.8872 52.6343 25.9759 52.6708 26.0684 52.6708Z" fill="#324F64"/>
                                </svg>

export const art_certification = <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.1507 10.3161C24.8908 10.3161 26.3014 8.90216 26.3014 7.15803C26.3014 5.4139 24.8908 4 23.1507 4C21.4106 4 20 5.4139 20 7.15803C20 8.90216 21.4106 10.3161 23.1507 10.3161Z" fill="#F2F2F2"/>
                                    <path d="M40.1507 124.316C41.8908 124.316 43.3014 122.902 43.3014 121.158C43.3014 119.414 41.8908 118 40.1507 118C38.4106 118 37 119.414 37 121.158C37 122.902 38.4106 124.316 40.1507 124.316Z" fill="#F2F2F2"/>
                                    <path d="M108.151 33.316C109.891 33.316 111.301 31.9021 111.301 30.158C111.301 28.4139 109.891 27 108.151 27C106.411 27 105 28.4139 105 30.158C105 31.9021 106.411 33.316 108.151 33.316Z" fill="#F2F2F2"/>
                                    <path d="M91.5489 24.0977C88.6605 36.9507 81.2524 66.9608 73.7365 73.237C73.2599 73.6881 72.6884 74.0264 72.0641 74.2268C45.6456 80.6778 37.1807 64.2226 37.1807 64.2226C37.1807 64.2226 44.2605 60.7645 44.44 54.005L58.7176 58.4453L71.1637 30.3712L72.9952 28.6588L73.0055 28.6486L91.5489 24.0977Z" fill="#324F64"/>
                                    <path d="M29.7358 102.007L29.9466 25.8015C29.9526 24.2591 30.5688 22.7821 31.6599 21.6945C32.751 20.6069 34.228 19.9975 35.7668 20L93.1847 20.1596C94.7235 20.1656 96.1971 20.7832 97.2822 21.8768C98.3673 22.9705 98.9752 24.4509 98.9727 25.9933L98.7619 102.199C98.7559 103.741 98.1398 105.218 97.0487 106.306C95.9575 107.393 94.4806 108.003 92.9418 108L35.5238 107.84C33.985 107.834 32.5115 107.217 31.4264 106.123C30.3413 105.03 29.7333 103.549 29.7358 102.007Z" fill="#E6E6E6"/>
                                    <path d="M34.2036 79.002L34.3392 30.0104C34.3447 28.5659 34.9218 27.1826 35.9437 26.164C36.9655 25.1455 38.3488 24.5748 39.7899 24.5772L89.1358 24.7143C90.577 24.7199 91.9571 25.2982 92.9734 26.3225C93.9896 27.3467 94.5591 28.7332 94.5567 30.1778L94.3692 97.99C94.3635 99.4345 93.7864 100.818 92.7645 101.836C91.7426 102.855 90.3593 103.426 88.9181 103.423L58.3494 103.338C51.93 103.313 45.7827 100.737 41.256 96.1746C36.7294 91.6122 34.1931 85.4364 34.2036 79.002Z" fill="white"/>
                                    <path d="M83.4816 46.0958H45.0044C44.3921 46.0958 43.8049 45.852 43.372 45.418C42.939 44.9841 42.6958 44.3955 42.6958 43.7818C42.6958 43.1681 42.939 42.5795 43.372 42.1455C43.8049 41.7116 44.3921 41.4678 45.0044 41.4678H83.4816C84.0939 41.4678 84.6811 41.7116 85.114 42.1455C85.547 42.5795 85.7902 43.1681 85.7902 43.7818C85.7902 44.3955 85.547 44.9841 85.114 45.418C84.6811 45.852 84.0939 46.0958 83.4816 46.0958Z" fill="#E4C695"/>
                                    <path d="M53.4694 35.4259H45.0044C44.3921 35.4259 43.8049 35.1821 43.372 34.7481C42.939 34.3142 42.6958 33.7256 42.6958 33.1119C42.6958 32.4981 42.939 31.9096 43.372 31.4756C43.8049 31.0417 44.3921 30.7979 45.0044 30.7979H53.4694C54.0817 30.7979 54.6689 31.0417 55.1018 31.4756C55.5348 31.9096 55.778 32.4981 55.778 33.1119C55.778 33.7256 55.5348 34.3142 55.1018 34.7481C54.6689 35.1821 54.0817 35.4259 53.4694 35.4259Z" fill="#E4C695"/>
                                    <path d="M83.4816 56.7677H45.0044C44.3921 56.7677 43.8049 56.5239 43.372 56.0899C42.939 55.656 42.6958 55.0674 42.6958 54.4537C42.6958 53.8399 42.939 53.2514 43.372 52.8174C43.8049 52.3834 44.3921 52.1396 45.0044 52.1396H83.4816C84.0939 52.1396 84.6811 52.3834 85.114 52.8174C85.547 53.2514 85.7902 53.8399 85.7902 54.4537C85.7902 55.0674 85.547 55.656 85.114 56.0899C84.6811 56.5239 84.0939 56.7677 83.4816 56.7677Z" fill="#E4C695"/>
                                    <path d="M83.4816 67.4366H45.0044C44.3921 67.4366 43.8049 67.1928 43.372 66.7588C42.939 66.3249 42.6958 65.7363 42.6958 65.1226C42.6958 64.5089 42.939 63.9203 43.372 63.4863C43.8049 63.0524 44.3921 62.8086 45.0044 62.8086H83.4816C84.0939 62.8086 84.6811 63.0524 85.114 63.4863C85.547 63.9203 85.7902 64.5089 85.7902 65.1226C85.7902 65.7363 85.547 66.3249 85.114 66.7588C84.6811 67.1928 84.0939 67.4366 83.4816 67.4366Z" fill="#E4C695"/>
                                    <path d="M80.3682 95.5526C85.4415 95.5526 89.5542 91.4303 89.5542 86.3451C89.5542 81.26 85.4415 77.1377 80.3682 77.1377C75.2949 77.1377 71.1821 81.26 71.1821 86.3451C71.1821 91.4303 75.2949 95.5526 80.3682 95.5526Z" fill="#E4C695"/>
                                    <path d="M79.1155 91.1367C78.9568 91.1367 78.8004 91.0997 78.6585 91.0286C78.5166 90.9575 78.3932 90.8542 78.2981 90.727L75.792 87.3777C75.7113 87.2701 75.6525 87.1476 75.6191 87.0173C75.5857 86.8869 75.5782 86.7512 75.5971 86.6179C75.616 86.4847 75.6609 86.3565 75.7293 86.2406C75.7977 86.1248 75.8882 86.0236 75.9957 85.9428C76.1031 85.8621 76.2254 85.8033 76.3554 85.77C76.4855 85.7366 76.6209 85.7293 76.7539 85.7484C76.8868 85.7675 77.0147 85.8127 77.1301 85.8814C77.2456 85.9501 77.3465 86.0409 77.4269 86.1487L79.0665 88.3397L83.2775 82.0086C83.3517 81.8962 83.4474 81.7996 83.5589 81.7244C83.6704 81.6491 83.7957 81.5966 83.9275 81.57C84.0593 81.5434 84.1951 81.5431 84.327 81.5691C84.4589 81.5952 84.5844 81.6471 84.6963 81.7218C84.8082 81.7966 84.9042 81.8927 84.979 82.0048C85.0537 82.1168 85.1056 82.2425 85.1318 82.3747C85.158 82.5069 85.1578 82.643 85.1314 82.7751C85.105 82.9073 85.0528 83.0329 84.9779 83.1448L79.9658 90.6806C79.8751 90.8169 79.7532 90.9294 79.6102 91.0087C79.4672 91.088 79.3073 91.1318 79.1439 91.1364C79.1344 91.1366 79.1249 91.1367 79.1155 91.1367Z" fill="white"/>
                                    </svg>

export const art_project = <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M128 86.835H0V87.4258H128V86.835Z" fill="#E6E6E6"/>
                            <path d="M128 63.792H0V64.3829H128V63.792Z" fill="#E6E6E6"/>
                            <path d="M128 40.749H0V41.3399H128V40.749Z" fill="#E6E6E6"/>
                            <path d="M102.694 18H102.106V110.175H102.694V18Z" fill="#E6E6E6"/>
                            <path d="M77.0935 18H76.5063V110.175H77.0935V18Z" fill="#E6E6E6"/>
                            <path d="M51.4939 18H50.9067V110.175H51.4939V18Z" fill="#E6E6E6"/>
                            <path d="M25.8938 18H25.3066V110.175H25.8938V18Z" fill="#E6E6E6"/>
                            <path d="M76.9172 41.043H25.541V64.0868H76.9172V41.043Z" fill="#E4C695"/>
                            <path d="M25.541 62.3145V87.1308L52.2566 63.2008L25.541 62.3145Z" fill="#E4C695"/>
                            <path d="M76.9174 63.2008V64.0871L76.5827 64.3854L75.9075 64.9881C62.7757 65.387 52.1688 74.8526 51.6902 86.5902L51.0884 87.1279L51.0825 87.1308V62.3145L76.9174 63.2008Z" fill="#E4C695"/>
                            <path d="M76.7706 64.3838C76.7089 64.3838 76.6443 64.3838 76.5827 64.3867C62.6143 64.4754 51.2616 74.6146 51.0884 87.1292C51.0825 87.2296 51.0825 87.3271 51.0825 87.4276C51.0825 100.134 62.6055 110.471 76.7706 110.471C90.9357 110.471 102.459 100.134 102.459 87.4276C102.459 74.721 90.9357 64.3838 76.7706 64.3838ZM76.7706 109.88C62.9313 109.88 51.6697 99.8092 51.6697 87.4276C51.6697 87.1469 51.6755 86.8692 51.6902 86.5915C52.1688 74.8539 62.7757 65.3883 75.9075 64.9894C76.1923 64.9776 76.4799 64.9747 76.7706 64.9747C90.6099 64.9747 101.872 75.046 101.872 87.4276C101.872 99.8092 90.6099 109.88 76.7706 109.88Z" fill="#E6E6E6"/>
                            <path d="M62.6788 87.1355L61.2109 87.1273C61.2116 86.9959 61.4467 73.9863 76.4627 73.084L76.5501 74.5586C62.9316 75.3769 62.6827 86.6559 62.6788 87.1355Z" fill="#E6E6E6"/>
                            <path d="M91.5903 86.8356C91.5337 85.1517 91.1412 83.4964 90.4362 81.9683C89.7311 80.4401 88.7279 79.0702 87.4861 77.9401C84.5668 75.2612 80.7506 73.7894 76.7999 73.8188H76.7705C68.706 73.8188 62.1239 79.63 61.9507 86.8356C61.9448 86.9331 61.9448 87.0335 61.9448 87.131C61.9448 90.9007 63.6975 94.3071 66.5099 96.7296C69.3815 99.1575 73.02 100.474 76.7705 100.443H76.7999C84.9613 100.428 91.5962 94.4637 91.5962 87.131C91.5962 87.0335 91.5962 86.9331 91.5903 86.8356Z" fill="#E6E6E6"/>
                            <path d="M87.4861 77.9401L87.0458 78.333L66.9503 96.3367L66.5099 96.7296C63.6975 94.3071 61.9448 90.9007 61.9448 87.131C61.9448 87.0335 61.9448 86.9331 61.9507 86.8356C62.1239 79.63 68.706 73.8188 76.7705 73.8188H76.7999C80.7506 73.7894 84.5668 75.2612 87.4861 77.9401Z" fill="#E4C695"/>
                            </svg>

export const pen = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.24446 1.16699C9.39925 1.16696 9.54771 1.22846 9.65714 1.33794L11.7877 3.46947C12.0154 3.69725 12.0154 4.06646 11.7877 4.29424L5.75525 10.3294C5.64585 10.4388 5.49743 10.5003 5.34268 10.5003H3.2085C2.88633 10.5003 2.62516 10.2392 2.62516 9.91699V7.79363C2.62516 7.63904 2.68653 7.49077 2.79577 7.3814L8.83184 1.33809C8.94123 1.22857 9.08966 1.16702 9.24446 1.16699Z" fill="#3B6C98"/>
                    <path d="M2.04183 11.667C1.71966 11.667 1.4585 11.9282 1.4585 12.2503C1.4585 12.5725 1.71966 12.8337 2.04183 12.8337H12.5418C12.864 12.8337 13.1252 12.5725 13.1252 12.2503C13.1252 11.9282 12.864 11.667 12.5418 11.667H2.04183Z" fill="#3B6C98"/>
                    </svg>
              
export const male = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7661 2.5013C12.7661 2.04106 13.1391 1.66797 13.5994 1.66797H17.3494C17.8096 1.66797 18.1827 2.04106 18.1827 2.5013V6.25132C18.1827 6.71156 17.8096 7.08465 17.3494 7.08465C16.8891 7.08465 16.5161 6.71156 16.5161 6.25132V4.51322L13.5237 7.50553C14.5077 8.72331 14.9998 10.2077 14.9998 11.69C14.9998 13.3948 14.3488 15.1024 13.0472 16.404C10.4437 19.0075 6.2226 19.0075 3.61912 16.404C1.01564 13.8005 1.01563 9.5794 3.61911 6.97592C5.99325 4.60175 9.7126 4.3926 12.3237 6.34848L15.3376 3.33464H13.5994C13.1391 3.33464 12.7661 2.96154 12.7661 2.5013ZM11.7573 8.0464C9.79753 6.20247 6.71355 6.23848 4.79764 8.15442C2.84502 10.107 2.84501 13.2729 4.79764 15.2255C6.75024 17.1781 9.9161 17.1781 11.8687 15.2255C12.8452 14.249 13.3332 12.9709 13.3332 11.69C13.3332 10.4091 12.8452 9.13091 11.8687 8.15443C11.856 8.14167 11.8431 8.129 11.8303 8.11641C11.8132 8.10186 11.7965 8.0865 11.7804 8.07035C11.7725 8.06248 11.7648 8.0545 11.7573 8.0464Z" fill="#292929"/>
                    <path d="M4.92784 8.17346C6.84375 6.25752 9.92774 6.22151 11.8875 8.06544C11.895 8.07354 11.9027 8.08152 11.9106 8.08939C11.9267 8.10555 11.9434 8.1209 11.9605 8.13545C11.9733 8.14804 11.9862 8.16072 11.9989 8.17347C12.9754 9.14995 13.4634 10.4281 13.4634 11.709C13.4634 12.9899 12.9754 14.2681 11.9989 15.2445C10.0463 17.1971 6.88044 17.1971 4.92784 15.2445C2.97522 13.2919 2.97523 10.1261 4.92784 8.17346Z" fill="#3B6C98"/>
                    </svg>

export const female = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.15252 3.51512C9.75601 0.911626 13.9771 0.911626 16.5806 3.51512C19.1841 6.11862 19.1841 10.3397 16.5806 12.9432C14.1602 15.3637 10.3415 15.5338 7.7245 13.4537L6.51525 14.663L8.57765 16.7254C8.90308 17.0508 8.90308 17.5784 8.57765 17.9039C8.25221 18.2293 7.72457 18.2293 7.39914 17.9039L5.33674 15.8415L2.97972 18.1985C2.65428 18.5239 2.12664 18.5239 1.80121 18.1985C1.47577 17.8731 1.47577 17.3454 1.80121 17.02L4.15823 14.663L2.09583 12.6006C1.7704 12.2751 1.7704 11.7475 2.09583 11.4221C2.42127 11.0966 2.94891 11.0966 3.27435 11.4221L5.33674 13.4845L6.55801 12.2632C5.65269 11.0747 5.1999 9.65096 5.1999 8.22917C5.1999 6.52431 5.85093 4.81671 7.15252 3.51512ZM15.4021 4.69363C13.4495 2.74101 10.2837 2.74101 8.33103 4.69363C7.35456 5.67011 6.86656 6.94826 6.86656 8.22917C6.86656 9.51007 7.35456 10.7882 8.33103 11.7647C10.2837 13.7173 13.4495 13.7173 15.4021 11.7647C17.3547 9.81208 17.3547 6.64625 15.4021 4.69363Z" fill="#292929"/>
                      <path d="M8.33068 4.69493C10.2833 2.74231 13.4491 2.74231 15.4017 4.69493C17.3544 6.64756 17.3544 9.81338 15.4017 11.766C13.4491 13.7186 10.2833 13.7186 8.33068 11.766C7.3542 10.7895 6.86621 9.51137 6.86621 8.23047C6.86621 6.94957 7.3542 5.67141 8.33068 4.69493Z" fill="#EB3858"/>
                      </svg>

export const red = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="14" height="14" rx="7" fill="#AF0000"/>
                    </svg>

export const yellow = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="14" height="14" rx="7" fill="#DCE000"/>
                      </svg>

export const green = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="14" height="14" rx="7" fill="#00AF27"/>
                      </svg>

export const cv1 = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_21516_12563)">
                    <path d="M24.9413 0C21.3085 0 18.353 2.95548 18.353 6.58824C18.353 10.2211 21.3085 13.1765 24.9413 13.1765C28.574 13.1765 31.5295 10.2211 31.5295 6.58824C31.5295 2.95548 28.574 0 24.9413 0ZM24.3452 9.17415L21.7974 6.62632L23.1284 5.29531L24.3452 6.51213L26.7542 4.10309L28.0852 5.4341L24.3452 9.17415Z" fill="#292929"/>
                    <path d="M12.7061 22.5879C10.6303 22.5879 8.94141 24.2767 8.94141 26.3526H16.4708C16.4708 24.2767 14.782 22.5879 12.7061 22.5879Z" fill="#292929"/>
                    <path d="M12.7061 20.7061C13.7457 20.7061 14.5884 19.8634 14.5884 18.8238C14.5884 17.7842 13.7457 16.9414 12.7061 16.9414C11.6665 16.9414 10.8237 17.7842 10.8237 18.8238C10.8237 19.8634 11.6665 20.7061 12.7061 20.7061Z" fill="#292929"/>
                    <path d="M16.4707 6.5887C16.4707 4.84865 16.9983 3.2297 17.9017 1.88281H0.470703V32.0005H24.9413V15.0593C20.2705 15.0593 16.4707 11.2594 16.4707 6.5887ZM4.23541 5.64752H12.706V7.52987H4.23541V5.64752ZM4.23541 9.41222H14.5884V11.2946H4.23541V9.41222ZM4.23541 13.1769H8.00012V15.0593H4.23541V13.1769ZM18.3531 28.2358H7.05894V26.3534C7.05894 24.2294 8.23792 22.3758 9.97558 21.4118C9.33533 20.7367 8.94129 19.8257 8.94129 18.824C8.94129 16.7481 10.6301 15.0593 12.706 15.0593C14.7819 15.0593 16.4707 16.7481 16.4707 18.824C16.4707 19.8257 16.0767 20.7367 15.4364 21.4118C17.1741 22.3758 18.3531 24.2294 18.3531 26.3534V28.2358Z" fill="#292929"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_21516_12563">
                    <rect width="32" height="32" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
      
export const icon_delete = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.125 12.375C6.71079 12.375 6.375 12.7108 6.375 13.125C6.375 13.5392 6.71079 13.875 7.125 13.875H10.875C11.2892 13.875 11.625 13.5392 11.625 13.125C11.625 12.7108 11.2892 12.375 10.875 12.375H7.125Z" fill="#E21515"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.075 1.125C5.70968 1.125 5.39751 1.38821 5.33578 1.74828L4.99263 3.75H2.25C1.83579 3.75 1.5 4.08579 1.5 4.5C1.5 4.91421 1.83579 5.25 2.25 5.25H3.42183L4.12656 16.1733C4.15202 16.5679 4.47953 16.875 4.875 16.875H13.125C13.5205 16.875 13.848 16.5679 13.8734 16.1733L14.5782 5.25H15.75C16.1642 5.25 16.5 4.91421 16.5 4.5C16.5 4.08579 16.1642 3.75 15.75 3.75H13.0074L12.6642 1.74828C12.6025 1.38821 12.2903 1.125 11.925 1.125H6.075ZM11.4855 3.75H6.51451L6.70737 2.625H11.2926L11.4855 3.75ZM5.57817 15.375L4.92495 5.25H13.0751L12.4218 15.375H5.57817Z" fill="#E21515"/>
                          </svg>

export const industry_icon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.84375 0H0.46875C0.209875 0 0 0.209875 0 0.46875V1.5625H5.3125V0.46875C5.3125 0.209875 5.10263 0 4.84375 0Z" fill="#DDB87A"/>
                            <path d="M15.7645 4.46841C15.6188 4.38484 15.4396 4.38556 15.2946 4.47038L10.6562 7.18288V4.875C10.6562 4.70706 10.5664 4.55197 10.4207 4.46841C10.2751 4.38484 10.0958 4.38556 9.95088 4.47038L5.3125 7.18288V2.5H0V15.5312C0 15.7901 0.209875 16 0.46875 16H15.5312C15.7901 16 16 15.7901 16 15.5312V4.875C16 4.70706 15.9102 4.55197 15.7645 4.46841ZM7.1875 12.7031C7.1875 12.962 6.97763 13.1719 6.71875 13.1719H4.84375C4.58487 13.1719 4.375 12.962 4.375 12.7031V10.8281C4.375 10.5692 4.58487 10.3594 4.84375 10.3594H6.71875C6.97763 10.3594 7.1875 10.5692 7.1875 10.8281V12.7031ZM12.5312 12.7031C12.5312 12.962 12.3214 13.1719 12.0625 13.1719H10.1875C9.92862 13.1719 9.71875 12.962 9.71875 12.7031V10.8281C9.71875 10.5692 9.92862 10.3594 10.1875 10.3594H12.0625C12.3214 10.3594 12.5312 10.5692 12.5312 10.8281V12.7031Z" fill="#DDB87A"/>
                            </svg>

export const function_icon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.99967 4.33398C9.63167 4.33398 9.33301 4.03532 9.33301 3.66732V2.66732H6.66634V3.66732C6.66634 4.03532 6.36767 4.33398 5.99967 4.33398C5.63167 4.33398 5.33301 4.03532 5.33301 3.66732V2.66732C5.33301 1.93198 5.93101 1.33398 6.66634 1.33398H9.33301C10.0683 1.33398 10.6663 1.93198 10.6663 2.66732V3.66732C10.6663 4.03532 10.3677 4.33398 9.99967 4.33398Z" fill="#DDB87A"/>
                              <path d="M8.47333 10.2528C8.35333 10.2994 8.18 10.3328 8 10.3328C7.82 10.3328 7.64667 10.2994 7.48667 10.2394L0 7.74609V12.8328C0 13.8461 0.82 14.6661 1.83333 14.6661H14.1667C15.18 14.6661 16 13.8461 16 12.8328V7.74609L8.47333 10.2528Z" fill="#DDB87A"/>
                              <path d="M16 5.16732V6.69398L8.16 9.30732C8.10667 9.32732 8.05333 9.33398 8 9.33398C7.94667 9.33398 7.89333 9.32732 7.84 9.30732L0 6.69398V5.16732C0 4.15398 0.82 3.33398 1.83333 3.33398H14.1667C15.18 3.33398 16 4.15398 16 5.16732Z" fill="#DDB87A"/>
                              </svg>
