import React, { Component } from 'react';
import rdsr from "../images/rdsr_bg.png"
import rdsr_multi from "../images/rdsr_multi.svg"
import rdsr_kt from "../images/rdsr_kt.svg"
import { connect } from "react-redux";
import { getAllCity, jobByTitle, getJobType} from "../_actions/jobs"
import { getListEducation } from "../_actions/education";
import {getIndustri, getJobFunction,getJobLevel} from "../_actions/work";
import {getCountry,getProvince, getCity } from "../_actions/user";
import {API} from '../_redux/helper'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Footer from "../components/Footer"
import Modal from "react-awesome-modal";
import warning_referral from "../images/warning_referral.svg"
import apply_success from "../images/apply_success.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Registration_Form_DSR extends Component {
    constructor(){
        super()
        this.state={
        selectedOption: null,
        age:"",
        list_bootcamp_program:["Data Science","Full Stack Engineer"],
        list_batch:["Batch 1 (October 2022)","Batch 2 (November 2022)"],
        list_goals:["Switch Career","Upskilling","Others"],
        bootcamp_program:'',
        batch_interested:"",
        name:"",
        email:"",
        phone_number:"",
        state_id:"",
        city_id:"",
        education_level_id:"",
        institution_name:"",
        degree:"",
        company_name:"",
        job_title:"",
        job_level_id:"",
        job_function_id:"",
        industry_id:"",
        total_year_workexperience:"",
        input_goals:"",
        goal_bootcamp:"",
        errorMessage:{},
        success:false,
        failed:false,
      }}

      componentDidMount(){
        window.scrollTo(0,0)
        window.history.scrollRestoration = "manual"
        this.props.getCountry();
        this.props.getIndustri();
        this.props.getJobFunction();
        this.props.getJobLevel();
        this.props.getCity()
        this.props.getProvince(102)
        this.props.getJobType();
        this.props.getListEducation();
      }

      
    componentDidUpdate(prevProps,prevState){
        if (prevState.state_id !== this.state.state_id){
            this.setState({city_id:""})
        }
    }


    handleChange=(e,y)=>{
        if (y=="state_id"){
            this.props.getCity(e.target.value)
        }
        if (y=="phone_number"|| y== "total_year_workexperience"){
            let k  = e.target.value.replace(/[^,\d]/g, '')
            // let k = e.target.value.match(/\d/g)
            // if (k !== null && k.length>1){
            //     k=k.join("")
            // }
            this.setState({[y]:k})
        }else{
            this.setState({[y]:e.target.value})
        }  
    }


      handleChange2 = (event) => {
        this.setState({age:event.target.value})
      };

      refreshForm=(e)=>{
        const list_id = ["bootcamp_program","batch_interested","name","email","phone_number","state_id","city_id","education_level_id",
                        "institution_name","degree","company_name","job_title","job_level_id","job_function_id","industry_id","total_year_workexperience","goal_bootcamp","input_goals"]
        list_id.map(item=>(
            this.setState({[item]:""})
        ))
        window.scrollTo(0,0)
        }

      closeFailed=()=> {
        this.setState({
          failed: false,
          success:false,
          input_goals:""
        });

        this.refreshForm()
      }


      submitForm=(e)=>{
        const { selectedOption, age, list_batch,list_bootcamp_program,batch_interested,name,email,phone_number,state_id,list_goals,input_goals,
            city_id,education_level_id,bootcamp_program, institution_name,degree,company_name,job_title,job_level_id,job_function_id,
            industry_id,total_year_workexperience,goal_bootcamp, errorMessage } = this.state;
        let goals=""
        if (goal_bootcamp==="Others"){
            goals=input_goals
        }else{
            goals=goal_bootcamp
        }
        const list_data =[bootcamp_program,batch_interested,name,email,phone_number, state_id,city_id, education_level_id,institution_name,degree,
            company_name,job_title,job_level_id,job_function_id,industry_id,total_year_workexperience,goals]
        const list_id = ["bootcamp_program","batch_interested","name","email","phone_number","state_id","city_id","education_level_id",
                        "institution_name","degree","company_name","job_title","job_level_id","job_function_id","industry_id","total_year_workexperience","goal_bootcamp"]
        
        const data ={bootcamp_program,batch_interested,name,email,phone_number, state_id,city_id, education_level_id,institution_name,degree,
                    company_name,job_title,job_level_id,job_function_id,industry_id,total_year_workexperience,goal_bootcamp:goals}

        const regEmail =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let list = []
        const err = "This field is required"
        list_data.map((item,i)=>{
            if (item==="" || item==null){
                errorMessage[list_id[i]]=err
                list.push(i)
            }else{
                if (i===3 && String(email).toLowerCase().match(regEmail) === null){
                    errorMessage[list_id[i]]=err
                    list.push(i)
                }else{
                    errorMessage[list_id[i]]=""
                }
            }
            this.setState({errorMessage})
        })

        
        if (list.length >0){
            const element = document.getElementById(list_id[list[0]]);
            element.scrollIntoView();
        }else{
            data["country_id"]=102
            axios.post(`${API}/api/register_dsr`,data).then((res)=>{
                this.setState({success:true})
                console.log(res,"ini data sukses terkirim");
            }).catch((e)=>{
                this.setState({failed:true})
            })
        }
        
      }


    render() {
    const { selectedOption, age, list_batch,list_bootcamp_program,batch_interested,name,email,phone_number,state_id,list_goals,
    city_id,education_level_id,bootcamp_program, institution_name,degree,company_name,job_title,job_level_id,job_function_id,input_goals,
    industry_id,total_year_workexperience,goal_bootcamp, errorMessage } = this.state;
    const {dataCountry} = this.props.country1;
    const { dataCity } = this.props.city1;
    const { listEdu } = this.props.list1;
    const {allCity} = this.props.allCity1;
    const {dataIndustri} = this.props.industri1;
    const {dataJobLevel} = this.props.jobLevel1;
    const {dataJobFunction} = this.props.jobFunction1;
    const {dataJobType} = this.props.jobType1;
    const { dataProvince } = this.props.province1;
    console.log(goal_bootcamp,"ini goal", input_goals);

    const select = <MenuItem value="" style={{display:"none"}}>
                        <em>(Select)</em>
                    </MenuItem>

        return (
            <React.Fragment>
            <Helmet>
            {/* <body className='homepage-22'></body> */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            </Helmet>
                <div className='flex column center width-100  rdsr'>
                <div className='flex column width-100 center height-100 p-20-100 bg-cover no-repeat' style={{backgroundImage:`url(${rdsr})`}}>
                    <div className='wd-fit-content flex col-tab space-between'>
                        <a href='/data-science-rockstar' className='out-none flex c-ff bg-12 bd-12 hg-fit-content p-1024 wd-fit-content mr-36 Kanit w-600 s-1624 br-26 bd-unset pointer'>Back to landing page</a>
                        <div className='flex column center wd-760'>
                            <div className='flex mb-45 alignCenter m'>
                                <img src={rdsr_kt} className="flex hg-32 wd-fit-content l" />
                                <p className='m-auto14 Kanit-400 s-20 c-ff'>x</p>
                                <img src={rdsr_multi} className="flex hg-32 wd-fit-content c " />
                            </div>
                            <p className='Kanit-500 s-32 c-12'>REGISTER</p>
                            <p className='Kanit w-600 s-48 mb-14 c-ff'>Data Science Rockstar Bootcamp</p>
                            <div className='flex column width-100 align-left'>
                                <p className='c-ff flex wrap Kanit-400 s-1626 align-left mb-14'>Hi! Welcome to Data Science Rockstar Bootcamp presented by Multimatics {"&"} Karya Talents.</p>
                                <p className='c-ff flex wrap Kanit-400 s-1626 align-left mb-14'>
                                Data Science Rockstar Bootcamp is a training program that is designed to help you become a professional certified Data Scientist. Trained, Guided, and Mentored by professional certified Data practitioners, you'll be a professional data scientist effortlessly in 12 Weeks.
                                </p>
                                <p className='c-ff flex wrap Kanit-400 s-1626 align-left '>
                                Our Class starts on October 3, 2022. Registration will be closed on September 23, 2022</p>
                                <p className='c-ff flex wrap Kanit-400 s-1626 align-left mb-22'>
                                Join us and you will be the right fit for your dream company! </p>
                            </div>
                            <div className='flex column bg-ff wd-webkit br-20 shd-24 p-48 align-left'>
                                <p className='DM-Sans w-400 c-red let-02 mb-14'>*required</p>
                                <div className='flex width-100 wrap space-between'>
                                    <div className='wd-320 flex column mb-48' id='bootcamp_program'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Choose Your Bootcamp Program</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={bootcamp_program} onChange={(e)=>this.handleChange(e,"bootcamp_program")} displayEmpty>
                                            {select}
                                            {list_bootcamp_program.map(item=>
                                            <MenuItem value={item}>{item} </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["bootcamp_program"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-48 c' id='batch_interested'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Which batch are you interested to join?</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={batch_interested} onChange={(e)=>this.handleChange(e,"batch_interested")} displayEmpty>
                                            {select}
                                            {list_batch.map(item=>
                                            <MenuItem value={item}>{item} </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["batch_interested"]}</p>
                                    </div>
                                    <p className='flex width-100 Montserrat s-1620 w-700 mb-14 stroke_ '>Basic Information</p>
                                    <div className='wd-320 flex column mb-14' id='name'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Full Name</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={name} onChange={(e)=>this.handleChange(e,"name")} />
                                        <p className='errorText'>{errorMessage["name"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-14' id='email'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Email</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={email} onChange={(e)=>this.handleChange(e,"email")}/>
                                        <p className='errorText'>{errorMessage["email"]}</p>
                                    </div>
                                    <div className='flex width-100 space-between'>
                                        <div className='wd-320 flex column mb-14' id='phone_number'>
                                            <div className='flex mb4'>
                                                <p className='DM-Sans w-500 c-17 s-1420'>Phone Number</p>
                                                <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                            </div>
                                            <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={phone_number} onChange={(e)=>this.handleChange(e,"phone_number")}/>
                                            <p className='errorText'>{errorMessage["phone_number"]}</p>
                                        </div>
                                    </div>
                                    <div className='wd-320 flex column mb-48' id='state_id'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Your Domicile Province</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={state_id} onChange={(e)=>this.handleChange(e,"state_id")} displayEmpty>
                                            {select}
                                            {dataProvince.map(item=>
                                            <MenuItem value={item.id}>{item.state_name} </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["state_id"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-48 c' id='city_id'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Your Domicile City</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={city_id} onChange={(e)=>this.handleChange(e,"city_id")} displayEmpty>
                                            {select}
                                            {dataCity.map(item=>
                                            <MenuItem value={item.id}>{item.city_name} </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["city_id"]}</p>
                                    </div>
                                    <p className='flex width-100 Montserrat s-1620 w-700 mb-14 stroke_ '>Last Education</p>
                                    <div className='wd-320 flex column mb-14' id='education_level_id'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Educational Stage</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={education_level_id} onChange={(e)=>this.handleChange(e,"education_level_id")} displayEmpty>
                                            {select}
                                            {listEdu.map(item=>
                                            <MenuItem value={item.id}>{item.education_name} </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["education_level_id"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-14' id='institution_name'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Institution Name</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <input type='text' value={institution_name} className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' onChange={(e)=>this.handleChange(e,"institution_name")} />
                                        <p className='errorText'>{errorMessage["institution_name"]}</p>
                                    </div>
                                    <div className='flex width-100 mb-48 c'>
                                        <div className='wd-320 flex column' id='degree'>
                                            <div className='flex mb4'>
                                                <p className='DM-Sans w-500 c-17 s-1420'>Degree</p>
                                                <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                            </div>
                                            <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={degree} onChange={(e)=>this.handleChange(e,"degree")} />
                                            <p className='errorText'>{errorMessage["degree"]}</p>
                                        </div>
                                    </div>
                                    <p className='flex width-100 Montserrat s-1620 w-700 mb-14 stroke_ '>Work Experience</p>
                                    <div className='wd-320 flex column mb-14' id='company_name'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Latest Company Name</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={company_name} onChange={(e)=>this.handleChange(e,"company_name")}/>
                                        <p className='errorText'>{errorMessage["company_name"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-14' id='job_title'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Latest Job Title</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={job_title} onChange={(e)=>this.handleChange(e,"job_title")}/>
                                        <p className='errorText'>{errorMessage["job_title"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-14' id='job_level_id'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Latest Job Level</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={job_level_id} onChange={(e)=>this.handleChange(e,"job_level_id")} displayEmpty>
                                            {select}
                                            {dataJobLevel.map(item=>
                                            <MenuItem value={item.id}>{item.job_level_name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["job_level_id"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-14' id='job_function_id'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Latest Job Function</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={job_function_id} onChange={(e)=>this.handleChange(e,"job_function_id")} displayEmpty>
                                            {select}
                                            {dataJobFunction.map(item=>
                                            <MenuItem value={item.id}>{item.job_function_name} </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["job_function_id"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-48' id='industry_id'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Latest Industry</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <FormControl>
                                            <Select value={industry_id} onChange={(e)=>this.handleChange(e,"industry_id")} displayEmpty>
                                            {select}
                                            {dataIndustri.map(item=>
                                            <MenuItem value={item.id}>{item.industry_name} </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <p className='errorText'>{errorMessage["industry_id"]}</p>
                                    </div>
                                    <div className='wd-320 flex column mb-48 c' id='total_year_workexperience'>
                                        <div className='flex mb4'>
                                            <p className='DM-Sans w-500 c-17 s-1420'>Total Years of Work Experience</p>
                                            <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                        </div>
                                        <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={total_year_workexperience} onChange={(e)=>this.handleChange(e,"total_year_workexperience")}/>
                                        <p className='errorText'>{errorMessage["total_year_workexperience"]}</p>
                                    </div>
                                    <p className='flex width-100 Montserrat s-1620 w-700 mb-14 stroke_ ' id='content2'>Goal in this Bootcamp</p>
                                    <div className='flex width-100 mb-48 wrap space-between'>
                                        <div className='wd-320 flex column ' id='goal_bootcamp'>
                                            <div className='flex mb4'>
                                                <p className='DM-Sans w-500 c-17 s-1420'>What is Your Goal in This Bootcamp?</p>
                                                <p className='DM-Sans w-600 s-1420 c-red'>*</p>
                                            </div>
                                            <FormControl>
                                                <Select value={goal_bootcamp} onChange={(e)=>this.handleChange(e,"goal_bootcamp")} displayEmpty>
                                                {select}
                                                {list_goals.map(item=>
                                                <MenuItem value={item}>{item} </MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            {goal_bootcamp !== "Others" &&
                                            <p className='errorText'>{errorMessage["goal_bootcamp"]}</p>}
                                        </div>
                                        {goal_bootcamp==="Others" &&
                                        <div className='wd-320 flex column ' id='goal_bootcamp'>
                                            <div className='flex mb4'>
                                                <p className='DM-Sans w-500 c-tr s-1420'> n </p>
                                            </div>
                                            <input type='text' className='flex hg-56 wd-webkit out-none bd-unset p-1814 shd-br-10 bg-ff' value={input_goals} 
                                             onChange={(e)=>this.handleChange(e,"input_goals")}/>
                                            <p className='errorText'>{errorMessage["goal_bootcamp"]}</p>
                                        </div>}
                                    </div>
                                    <div className='flex width-100 center' onClick={(e)=>this.submitForm(e)}>
                                        <p className='p-1024 c-ff bg-12 br-26 Kanit s-1624 w-600 pointer'>REGISTER</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='out-none flex c-ff bg-12 br-12 hg-fit-content p-1024 wd-fit-content ml-36 Kanit w-600 s-1624 bd-unset op-0'>Back to landing page</p>
                    </div>
                </div>
                <div className='flex align-just wd-1192 mt-48 mb-48'>
                    <p className='Kanit-300 s-1624'>How we go the extra mile to ensure your companies are hiring the right person is through these processes. We assess the talents to ensure they have the necessary skills, competencies, emotional intelligence, and growth mindset to perform the job. We use a unique algorithm based on skills, experience, values, interest, and personality to match the best fit talents according to your organization’s needs. Not only that, we will provide insights on each talent through a candidate profile report and interview summary about their skills, competencies, personality, work values, so that both the companies and talents will have a great self-awareness of the talents.</p>
                </div>
                </div>
                <Modal id="success" visible={this.state.success} effect="fadeInUp">
                    <div  className="modal_apply">
                        <div className="width-100 flex center">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={apply_success} style={{marginTop:"24px", marginBottom:"24px"}}/>
                        </div>
                        <h5>Good Job!</h5>
                        <p  style={{width:"90%", marginLeft:"5%"}}>Please check your email for verification!</p>
                        <div className="width-100 flex center">
                            <button onClick={this.closeFailed} style={{marginBottom:"20px"}}>
                                <p style={{margin:"12px", color:"#4F4F4F"}}>OK</p>
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal id="failed" visible={this.state.failed} effect="fadeInUp">
                    <div  className="modal_apply">
                        <div className="width-100 flex center">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={warning_referral} style={{marginTop:"24px", marginBottom:"24px"}}/>
                        </div>
                        <h5>Oops!</h5>
                        <p style={{width:"90%", marginLeft:"5%"}}>Email is already registered. Please register with a new email!</p>
                        <div className="width-100 flex center" style={{marginBottom:"20px"}}>
                            <button onClick={this.closeFailed}>
                                <p style={{margin:"12px", color:"#4F4F4F"}}>Close</p>
                            </button>
                        </div>
                    </div>
                </Modal>
                <Footer/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      country1: state.countryRed,
      allCity1: state.AllCityRed,
      industri1 : state.industriRed,
      jobLevel1 : state.jobLevelRed,
      jobFunction1 : state.jobFunctionRed,
      province1: state.provinceRed,
      city1: state.cityRed,
      jobType1 : state.jobTypeRed,
      list1: state.listEducationRed,
      /*untuk put*/
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getCountry: (country_id) =>{
          dispatch(getCountry(country_id))
      },
      getAllCity: (country_id) =>{
        dispatch(getAllCity(country_id))
      },
      getIndustri: () =>{
        dispatch(getIndustri())
      },
      getCity: (province_id) => {
        dispatch(getCity(province_id));
      },
      getJobType: () =>{
        dispatch(getJobType())
      },
      getJobFunction: () =>{
        dispatch(getJobFunction())
      },
      getProvince: (country_id) => {
        dispatch(getProvince(country_id));
      },
      getJobLevel: () =>{
        dispatch(getJobLevel())
      },
      getListEducation: () => {
        dispatch(getListEducation());
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Registration_Form_DSR);