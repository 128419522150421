import { GET_EDUCATION, GET_EDUCATION_ID, POST_EDUCATION, DELETE_EDUCATION, PUT_EDUCATION, LIST_EDUCATION } from "../config/constants";
import axios from "axios";
import { API } from '../_redux/helper'

const token = sessionStorage.getItem('token')
export const getEducation = (id_edu) => {
  return {
    type: GET_EDUCATION,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_education/${id_edu}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const getEducationId = (user_id) => {
  return {
    type: GET_EDUCATION_ID,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_education?user_id=${user_id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const postEducation = (edu) => {
  return {
    type: POST_EDUCATION,
    payload: axios({
      method: "POST",
      url: `${API}/api/user_education`,
      data: edu,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const putEducation = (id) => {
  return {
    type: PUT_EDUCATION,
    payload: axios({
      method: "PUT",
      url: `${API}/api/user_education/${id.id}`,
      data: id,
      headers: {
        Authorization: ` ${token}`,
      }
    })
  };
};

export const deleteEducation = (id) => {
  return {
    type: DELETE_EDUCATION,
    payload: axios({
      method: "DELETE",
      url: `${API}/api/user_education/${id}`,
      data: id,
      headers: {
        Authorization: ` ${token}`,
      }
    })
  };
};

export const getListEducation = () => {
  return {
    type: LIST_EDUCATION,
    payload: axios({
      method: "GET",
      url: `${API}/api/m_education`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};