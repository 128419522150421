import React, { Component } from "react";
import About from "../images/About.jpg";
import { connect } from "react-redux";
import Navbar_Homepage from "../components/Navbar_Homepage";
import Footer from "../components/Footer";
import { getAbout } from "../_actions/article";
import ScrollToTop from "../components/ScrollToTop";
import Loading from "../components/Loading";
import ReactGA from "react-ga";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPixel from "react-facebook-pixel";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      br: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    window.addEventListener("scroll", this.handleScroll);

    this.props.getAbout();
    if (localStorage.getItem("token")) {
      window.location.href = "/NewDashboard";
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0);
    let new_description =
      "We are the best job vacancies platform in Indonesian for job seekers and company employees. New Recruitment talent acquistion jakarta Jobs in Indonesian is available at karyatalents";
    let new_keywords =
      "lowongan kerja jabodetabek, situs lowongan kerja terpercaya, lowongan Kerja IT manager Jakarta disc personality test indonesia, talent recruitment job in jakarta indonesia, tes assessment online gratis, tes kepribadian online indonesia gratis";
    $("#index_description").attr("content", new_description);
    $("#index_keywords").attr("content", new_keywords);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (parseFloat(winScroll) < 250) {
      const height = document.getElementById("container-u").clientHeight;
      this.setState({ height_: height });
      this.setState({ br: scrolled });
    }
  };

  render() {
    const { dataAbout, isLoading, error } = this.props.about1;
    const { br } = this.state;

    let st_dis = false;
    if (br === 0) {
      st_dis = false;
    } else {
      st_dis = true;
    }

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Mohon, refresh halaman ini!</h3>
        </div>
      );
    }
    /* eslint eqeqeq: 0 */
    if (isLoading || dataAbout == "" || dataAbout == undefined) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <div>
        <Helmet>
          <title>
            Executive Search Firms Jakarta Indonesia - Best Executive Recruiters
            in Jakarta
          </title>
        </Helmet>
        <div className="">
          <div classNax4me="">
            <div className="flex column width-100 Hp-22 _s bg-ff">
              <Navbar_Homepage status={st_dis} />
              <div className="page-container master-page width-100">
                <div className="banner width-100">
                  <LazyLoadImage
                    effect="blur"
                    loading="lazy"
                    src={About}
                    className="width-100 mb-24 img-center"
                    alt=""
                  />
                </div>
                <div className="page-container-outer" id="container-u">
                  <div className="box-title width-100">
                    <Zoom right cascade>
                      <h3
                        className=" width-100"
                        style={{ fontFamily: "League Spartan" }}
                      >
                        {dataAbout.title}
                      </h3>
                    </Zoom>
                    <div className="line-horizontal width-100"></div>
                  </div>
                  <Fade bottom cascade>
                    <div className="companyContent">
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dataAbout.content,
                          }}
                        />
                      }
                    </div>
                  </Fade>
                </div>
              </div>

              <ScrollToTop />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    about1: state.about,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAbout: () => {
      dispatch(getAbout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
