import React, { Component } from 'react';
import {
    phone, mail, cv, cv_green, people, salary, skill, work_exp, education, certificate, project, industry,
    art_certification, art_personal, art_education, art_project, art_salary, art_skills, art_work_exp, pen
} from "../../_redux/svg"
import { formatDate } from "../../_redux/function"


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const createData = (
    name,
    calories,
    fat,
    carbs,
    protein,
    name1,
    calories1,
    fat1,
    carbs1,
    protein1,
) => {
    return {
        name, calories, fat, carbs, protein, name1,
        calories1,
        fat1,
        carbs1,
        protein1
    };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Eclair', 262, 16.0, 24, 6.0, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Cupcake', 305, 3.7, 67, 4.3, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
];



class Work_Experience extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            jobs: ["Designer", "Software Developer", "System Analyst", "Software Developer"],
            user_id: ""
        }
    }



    render() {
        const { profile, workId, dataIndustri, dataJobFunction, dataJobLevel } = this.props
        let visible = true
        if (workId.length > 0){
            visible = false
        }
let end_date
        const set_endDate_tab =(a,b)=>{
            if (a!=null && b===null){
               end_date = "Present"
            }else if (a===null && b===null){
                end_date = "-"
            } else{
               end_date = formatDate(b)
            }
            return end_date
           }
        
        return (
            <div>
                <div className='flex column width-100 bg-ff br-10 shd-input mb-24 work_exp' >
                    <div className='flex p-1814 bb-ea space-between'>
                        <div className='flex'>
                            <div className='flex m-auto-00'>
                                {work_exp}
                            </div>
                            <p className='ml-14 DM-Sans c-29 s-20 '>Work Experience</p>
                        </div>
                        {!visible &&
                            <div className='flex pointer m-auto0' onClick={(e) => {this.props.handlePopup("work_experience")
                            this.props.getWork()}}>
                                {pen}
                                <p className='DM-Sans s-14 c-36 ml6'>Edit</p>
                            </div>}
                    </div>
                    <div className='flex column hg-382 width-100 '>
                        {visible &&
                            <div className='flex m-auto column wd-375 s-p-2 center pb-2'>
                                {art_work_exp}
                                <p className='c-29 DM-Sans s-14 mt-48 mb-48 tx-c'>Put at least 3 of your latest experiences to officially join our talent network and our recruiter will contact you once there are jobs that are relevant to your work experiences.</p>
                                <div className='bg-36 p-1014 br-20' onClick={(e) => this.props.handlePopup("add_work")}>
                                    <p className='s-14 c-ff DM-Sans pointer'>Add Work Experince</p>
                                </div>
                            </div>}

                        {!visible &&

                            <>
                                <TableContainer className="m-hide" component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="DM-Sans-Medium">Job Title</TableCell>
                                                <TableCell className="DM-Sans-Medium" align="">Job Function</TableCell>
                                                <TableCell className="DM-Sans-Medium" align="">Job Level</TableCell>
                                                <TableCell className="DM-Sans-Medium" align="">Company Name</TableCell>
                                                <TableCell className="DM-Sans-Medium" align="">Industry</TableCell>
                                                <TableCell className="DM-Sans-Medium" align="">Work Period Start</TableCell>
                                                <TableCell className="DM-Sans-Medium" align="">Work Period End</TableCell>
                                                <TableCell className="DM-Sans-Medium" align="">Job Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {workId.map((item, i) => {
                                                 if (i<=2){
                                                return (
                                                    <TableRow
                                                        key={i} style={{backgroundColor:i%2? "#FBFBFB":"#FFFFFF"}}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{item.job_title}</TableCell>
                                                        <TableCell align="">{dataJobFunction.map((item2) => { if (item2.id === parseInt(item.job_function_id)) { return (item2.job_function_name) } })}</TableCell>
                                                        <TableCell align="">{dataJobLevel.map((item2) => { if (item2.id === parseInt(item.job_level_id)) { return (item2.job_level_name) } })}</TableCell>
                                                        <TableCell align="">{item.company_name}</TableCell>
                                                        <TableCell align="">{dataIndustri.map((item2) => { if (item2.id === parseInt(item.industry_id)) { return (item2.industry_name) } })}</TableCell>
                                                        <TableCell >{item.work_period_start !== null ?formatDate(item.work_period_start):"-"}</TableCell>
                                                        <TableCell align="">{set_endDate_tab(item.work_period_start,item.work_period_end)}</TableCell>
                                                        {/* <TableCell align=""> Read more</TableCell> */}
                                                        <TableCell style={{ cursor: 'pointer', color: '#3B6C98' }} onClick={(e) => {
                                                            this.props.updateJobDecId(i)
                                                            this.props.handlePopup("job_desc")
                                                        }} align="">Read more</TableCell>

                                                    </TableRow>
                                                )}
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="m-show old-bg">
                                    {workId.map((item, i) => {
                                        if (i<=2){
                                        return (
                                            <Accordion >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className="acc-title">{item.job_title}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="card-m mb-10">
                                                        <p className="m-label">Job Function</p>
                                                        <p className="m-desc">{dataJobFunction.map((item2) => { if (item2.id === parseInt(item.job_function_id)) { return (item2.job_function_name) } })}</p>
                                                    </div>
                                                    <div className="card-m mb-10">
                                                        <p className="m-label">Job Level</p>
                                                        <p className="m-desc">{dataJobLevel.map((item2) => { if (item2.id === parseInt(item.job_level_id)) { return (item2.job_level_name) } })}</p>
                                                    </div>
                                                    <div className="card-m mb-10">
                                                        <p className="m-label">Company Name</p>
                                                        <p className="m-desc">{item.company_name}</p>
                                                    </div>
                                                    <div className="card-m mb-10">
                                                        <p className="m-label">Industry</p>
                                                        <p className="m-desc">{dataIndustri.map((item2) => { if (item2.id === parseInt(item.industry_id)) { return (item2.industry_name) } })}</p>
                                                    </div>
                                                    <div className="card-m mb-10">
                                                        <p className="m-label">Work Period Start</p>
                                                        <p className="m-desc">{formatDate(item.work_period_start)}</p>
                                                    </div>
                                                    <div className="card-m mb-10">
                                                        <p className="m-label">Work Period End</p>
                                                        <p className="m-desc">{set_endDate_tab(item.work_period_start,item.work_period_end)}</p>
                                                    </div>
                                                    {/* <div className="card-m mb-10">
                                                        <p className="m-label">Job Function:</p>
                                                        <p className="m-desc">{dataJobFunction.map((item2) => { if (item2.id === parseInt(item.job_function_id)) { return (item2.job_function_name) } })}</p>
                                                    </div> */}
                                                    <div className="card-m mb-10">
                                                        <p className="m-label">Job Description</p>
                                                        <p className="m-desc co" onClick={(e) => {
                                                            this.props.updateJobDecId(i)
                                                            this.props.handlePopup("job_desc")
                                                        }}
                                                        >Read more</p>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                    })}
                                </div>
                            </>
                        }
                        {/* {!visible && 
                        <div className='flex width-100 column'>
                            <div className='flex  width-100 bt-ea bb-ea'>
                                <div className='flex m-14 space-between width-100'>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 wd-120 align-left'>Job Title</p>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-200'>Job Function</p>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-200'>Job Level</p>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-124'>Company Name</p>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-128'>Industry</p>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-128'>Work Period Start</p>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-128'>Work Period End</p>
                                    <p className='s-14 c-29 DM-Sans-Medium w-500 align-left wd-126'>Job Description</p>
                                </div>
                            </div>
                            {workId.map((item,i)=>{
                                return(
                            <div className='flex  width-100 bb-ea'style={{backgroundColor:i%2?"#FBFBFB":""}} >
                                <div className='flex m-14 space-between width-100'>
                                    <p className='s-14 c-29 DM-Sans w-400 wd-120 align-left'>{item.job_title} </p>
                                    <p className='s-14 c-29 DM-Sans w-400 align-left wd-200'>{dataJobFunction.map((item2)=>{if (item2.id===parseInt(item.job_function_id)){return(item2.job_function_name)}})} </p>
                                    <p className='s-14 c-29 DM-Sans w-400 align-left wd-200'>{dataJobLevel.map((item2)=>{if (item2.id===parseInt(item.job_level_id)){return(item2.job_level_name)}})} </p>
                                    <p className='s-14 c-29 DM-Sans w-400 align-left wd-124'>{item.company_name} </p>
                                    <p className='s-14 c-29 DM-Sans w-400 align-left wd-128'>{dataIndustri.map((item2)=>{if (item2.id===parseInt(item.industry_id)){return(item2.industry_name)}})} </p>
                                    <p className='s-14 c-29 DM-Sans w-400 align-left wd-128'>{formatDate(item.work_period_start)} </p>
                                    <p className='s-14 c-29 DM-Sans w-400 align-left wd-128'>{formatDate(item.work_period_end)} </p>
                                    <p className='s-14 c-36 DM-Sans w-400 align-left wd-126 pointer'>Read more</p>
                                </div>
                            </div>
                            )
                        })}
                        </div>} */}
                    </div>
                </div>
            </div>
        );
    }
}


export default Work_Experience;