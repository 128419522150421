import React, { Component } from "react";
import hp1 from "../images/hp_2.png";
import hp2 from "../images/hp_1.png";
import nhp_vip from "../images/nhp_vip.png";
import nhp_personality from "../images/nhp_personality.png";
import h_learn from "../images/h-learn.png";
import h_discov from "../images/h-discov.png";
import h_align from "../images/h-align.png";
import r_right from "../images/r-right.svg";
import albert from "../images/albert.jpg";

import ruby from "../images/ruby.png";
import ade from "../images/ade.jpg";
import louis from "../images/louis.jpg";
import rightt from "../images/next_arrow.svg";
import warning_referral from "../images/warning_referral.svg";
import Modal from "react-awesome-modal";
import arrowNextGray from "../images/ArrowNextGray.svg";
import left_disabled from "../images/left_disabled.svg";
import light_arr from "../images/light_arr.svg";
import right_light from "../images/right_light.svg";
import left_mobile from "../images/ar_left_mobile.svg";
import right_mobile from "../images/ar_right_mobile.svg";
import place from "../images/place.png";
import thumbnail from "../images/video-thumbnail.png";
import Footer_Homepage from "../components/Footer";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
// import {Talents} from "../components/NavBar";
import Navbar_Homepage from "../components/Navbar_Homepage";
import ReactGA from "react-ga";
import Session from "../components/Session";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllArticle } from "../_actions/article";
import { getJobOpportunityPublic } from "../_actions/jobs";
import axios from "axios";
import { API } from "../_redux/helper";
import videoKarya from "../images/videoKarya.mp4";
import ReactTooltip from "react-tooltip";
import Fade from "react-reveal/Fade";
import Pulse from "react-reveal/Pulse";
import Zoom from "react-reveal/Zoom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPixel from "react-facebook-pixel";
import Nav from "../components/Nav";
import { Helmet } from "react-helmet";
import Hero from "../images/hero-comp.png";

ReactGA.initialize("UA-170839109-1");

class Talent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      register: false,
      forgotPassword: false,
      visible: false,
      next_job: "",
      ft1: [],
      photo: [],
      banner_d: "",
      banner_m: "",
      art: "",
      src: "",
      link: "",
      expired: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getAllArticle();
    this.props.getJobOpportunityPublic();

    var timeSession = localStorage.getItem("setupTime");
    if (timeSession) {
      if (new Date().getTime() - timeSession > 60 * 30 * 1000) {
        Session.removeSessionLogout();
      } else if (localStorage.getItem("logout") == "false") {
      /* eslint eqeqeq: 0 */
        localStorage.setItem("logout", false);
        Session.checkToken();
        window.location.href = "/NewDashboard";
      }
    } else if (
      this.props.location.search &&
      this.props.location.search.indexOf("refer_code") == -1
    ) {
      this.state.login = true;
    } else if (this.props.location.search.indexOf("refer_code") != -1) {
      this.state.register = true;
    }
    if (this.props.location.search.indexOf("expired-token") != -1) {
      // console.log('udah masuk di sini')
      this.state.expired = true;
    }

    let list_ref = [
      "/referral",
      "/work_assessment",
      "/myprofile",
      "/newdashboard",
      "/digitallearningplatform",
      "/careercenter",
      "/jobopportunities",
    ];
    // let list_ref = ["/referral"]
    if (sessionStorage.getItem("log-user") == "false") {
      sessionStorage.setItem("ref", "/Homepage");
      sessionStorage.setItem("log-user", "true");
    }
    if (
      sessionStorage.getItem("ref") !== "" &&
      sessionStorage.getItem("ref") !== null
    ) {
      if (
        list_ref.indexOf(sessionStorage.getItem("ref").toLowerCase()) !== -1
      ) {
        this.setState({ login: true });
      } else if (localStorage.getItem("logout") == false) {
        this.setState({ login: false });
      }
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track("/Homepage");
    const idEl = [3, 4, 5];
    const imageLoader = new Image();
    idEl.forEach((el) => {
      axios.get(`${API}/api/banner/${el}`).then((res) => {
        if (res.data.banner_name == "banner_homepage_dekstop") {
          imageLoader.src = `${API}/File/Banner/${res.data.photo}`;
          this.setState({ banner_d: imageLoader.src });
        } else if (res.data.banner_name == "banner_homepage_mobile") {
          imageLoader.src = `${API}/File/Banner/${res.data.photo}`;
          this.setState({ banner_m: imageLoader.src });
        } else if (res.data.banner_name == "art_homepage") {
          imageLoader.src = `${API}/File/Banner/${res.data.photo}`;
          this.setState({ art: imageLoader.src });
        } else {
          // console.log("error");
        }
      });
    });
  }

  closeExpired = () => {
    this.setState({
      expired: false,
    });
  };

  handleCallback = (childData) => {
    // this.setState({link: childData})
    window.location.href = `/${childData}`;
  };

  clickViewJob(a) {
    window.location.href = a;
  }

  render() {
    if (this.props.location.state) {
      if (this.props.location.state.from.search) {
        window.location.href = `/Homepage${this.props.location.state.from.search}`;
      }
    }
    const { data, isLoading, error } = this.props.about1;
    const { dataJobOpportunityPublic } = this.props.dataJobOpportunityPublic1;

    var bulan = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];

    let buatakunbaru;
    let buatakunbaru2;
    if (!sessionStorage.getItem("token")) {
      buatakunbaru = (
        <div
          className="btn-text-image"
          onClick={ReactGA.pageview(
            window.location.pathname + window.location.search
          )}
        >
          <button
            value="Open"
            onClick={() => this.handleCallback("signup")}
            style={{ borderRadius: "32px", backgroundColor: "#EDAF12" }}
          >
            <a>Join Us</a>
          </button>
        </div>
      );
      buatakunbaru2 = (
        <div
          className="btn-text-image1"
          onClick={ReactGA.pageview(
            window.location.pathname + window.location.search
          )}
        >
          <button
            value="Open"
            onClick={() => this.handleCallback("signup")}
            style={{ borderRadius: "32px" }}
          >
            <a>Join Us</a>
          </button>
        </div>
      );
    }

    const settings = {
      //   dots: true,
      infinite: true,
      slidesToShow: 3,
      arrow: false,
      slidesToScroll: 1,
      // autoplay: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1310,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            // centerMode: true,
            centerPadding: "70px",
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "70px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "20px",
          },
        },
        {
          breakpoint: 373,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let nextStep;
    const settingJob = {
      beforeChange: (current, next, dots) => this.setState({ next_job: next }),
      //  nextStep2 = next,
      afterChange: (current) => {
        if (current === 4) {
          nextStep = (
            <Link to="GetStarted">
              <button type="button" className="btn btn-primary">
                {" "}
                {nextStep}I Understand
              </button>
            </Link>
          );
          this.setState({ cek: nextStep });
        }
      },

      arrow: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      // autoplay:true,
      nextArrow:
        this.state.next_job < dataJobOpportunityPublic.length - 4 ? (
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            alt=""
            src={right_light}
          />
        ) : (
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            alt=""
            src={arrowNextGray}
          />
        ),
      prevArrow:
        this.state.next_job > 0 ? (
          <LazyLoadImage effect="blur" loading="lazy" alt="" src={light_arr} />
        ) : (
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            alt=""
            src={left_disabled}
          />
        ),
      responsive: [
        {
          breakpoint: 1763,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 3 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_light}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={light_arr}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 2 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_light}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={light_arr}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
          },
        },
        // {
        //   breakpoint: 845,
        //   settings: {
        //     slidesToShow: 2,
        //     slidesToScroll: 1,
        //   },
        // },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 2 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
          },
        },
        {
          breakpoint: 651,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // dots:true,
            // fade: true,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 1 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
            speed: 1000,
            // centerMode: true,
            // centerPadding: "35px",
          },
        },
      ],
    };
    // if (isLoading || this.state.photo ==undefined || this.state.ft1 == undefined || this.state.ft1.length < 3) {
    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <body></body>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          {/* <meta name="keywords" id="index_keywords"
            content="Looking for a job? Discover many job vacancies & find your next career journey from various field and companies on Karya Talents. Apply today!
            "/> */}
        </Helmet>
        <div className="home-layer tal_ homePage nhp-rev">
          <div className="box-wrapper central_page">
            <div className="">
              <div className="flex column width-100 Hp-22 _s bg-ff ">
                <Navbar_Homepage status="bg-ff fsh_" />
                <div className="page-container scrollContent homepage-master width-100">
                  <Modal
                    id="failed"
                    visible={this.state.expired}
                    effect="fadeInUp"
                  >
                    <div className="modal_apply">
                      <div className="width-100 flex center">
                        <LazyLoadImage
                          effect="blur"
                          loading="lazy"
                          alt=""
                          src={warning_referral}
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                      </div>
                      <h5>Try verifiying your email again</h5>
                      <p style={{ width: "90%", marginLeft: "5%" }}>
                        Your request to verify your email has expired or the
                        link has already been used
                      </p>
                      <div
                        className="width-100 flex center"
                        style={{ marginBottom: "20px" }}
                      >
                        <button onClick={this.closeExpired}>
                          <p style={{ margin: "12px", color: "#4F4F4F" }}>
                            Close
                          </p>
                        </button>
                      </div>
                    </div>
                  </Modal>
                  <div
                    className="background Banner-bx"
                    style={{ backgroundImage: `url(${this.state.banner_d})` }}
                  >
                    <div className="text-image text-image-inner">
                      <div className="mobile-information cH_">
                        <LazyLoadImage
                          loading="lazy"
                          alt=""
                          className="width-100"
                          src={this.state.art}
                        />
                      </div>
                      <div className="homepageCenter  flex width-100">
                        <div className="mb-38t flex column width-100">
                          <span
                            style={{ fontFamily: "Poppins Bold" }}
                            className="c-ff"
                          >
                            Find Joy at Work
                          </span>
                          <span
                            style={{
                              marginBottom: "24px",
                              color: "#E1B564",
                              fontFamily: "Poppins Bold",
                            }}
                            className="c-ff"
                          >
                            with Karya Talents
                          </span>
                          <h1 className="c-ff Kanit-300 s-2436 wd-371 mb-38 ">
                            The right place to get tailored job offers that
                            matches your profile
                          </h1>
                        </div>
                        <div className="width-100">{buatakunbaru}</div>
                      </div>
                    </div>
                    {/* </Slide> */}
                  </div>

                  <div
                    className="backgroundTablet"
                    style={{ backgroundImage: `url(${this.state.banner_d})` }}
                  >
                    <div className="text-image text-image-inner flex space-between">
                      <div className="homepageCenter">
                        <Zoom>
                          <div className="flex column">
                            <span
                              style={{
                                fontFamily: "Poppins Bold",
                                marginBottom: "0px",
                              }}
                              className="c-ff"
                            >
                              Find Joy at Work
                            </span>
                            <span
                              style={{
                                fontFamily: "Poppins Bold",
                                color: "#E1B564",
                              }}
                              className="c-ff"
                            >
                              with Karya Talents
                            </span>
                            <h1 className="c-ff Kanit-300 s-2436 wd-371 ">
                              The right place to get tailored job offers that
                              matches your profile
                            </h1>
                          </div>

                          <div className="width-100">{buatakunbaru}</div>
                        </Zoom>
                      </div>

                      <div className="boxImage">
                        <LazyLoadImage
                          loading="lazy"
                          alt=""
                          className="width-100"
                          src={this.state.art}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-container-outer none outer-master top-0">
                    <div className="none column width-100 section-video top-100">
                      <Fade bottom>
                        <div className="content-left">
                          <h1 style={{ fontFamily: "HK Grotesk Bold" }}>
                            Fit Right into the Right Job
                          </h1>
                          <p>
                            <b>Karya Talents </b> is your lifetime career
                            counsellor. With us, learn more about yourself and
                            we will recommend you training and career
                            opportunities so you can Fit Right into the Right
                            Jobs!
                          </p>
                        </div>
                      </Fade>
                      <div className="content-right">
                        {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={imgvideo} /> */}
                        <video
                          src={videoKarya}
                          style={{ width: "100%", height: "100%" }}
                          controls
                          onContextMenu={(e) => e.preventDefault()}
                          poster={thumbnail}
                          typeof="mp4"
                          controlsList="nodownload"
                          disablePictureInPicture
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex column width-100 center top-175">
                    <div className="dekstop width-100 center">
                      <Fade bottom>
                        <div
                          className="content-left"
                          style={{ margin: "auto" }}
                        >
                          <h2
                            className="flex  center c-21 "
                            style={{ margin: "auto" }}
                          >
                            Increase Your Self-Awareness
                          </h2>
                          <p>
                            In Karya Talents, we help you to discover work
                            satisfaction and enhance productivity in your job
                            through our comprehensive assessment tools.
                          </p>
                        </div>
                      </Fade>
                    </div>
                    <div className="mobile width-100 center">
                      <Fade bottom>
                        <div className="page-container-outer">
                          <div className="content-left">
                            <h2 className="flex width-100 center mb-14">
                              Increase Your Self-Awareness
                            </h2>
                            <p>
                              In Karya Talents, we help you to discover work
                              satisfaction and enhance productivity in your job
                              through our comprehensive assessment tools.
                            </p>
                          </div>
                        </div>
                      </Fade>
                    </div>
                    <div className="block width-100 center">
                      <div className="flex width-100 nhp-container">
                        <div className="flex width-80 auto space-between">
                          <div className="flex  left-i">
                            <LazyLoadImage
                              effect="blur"
                              loading="lazy"
                              alt=""
                              src={nhp_vip}
                              className="flex width-100 auto"
                            />
                          </div>
                          <div className="flex  column right-i">
                            <div className="flex column right-c">
                              <div className="width-100 flex column nhp-title">
                                <h3 className="openSans-Bold">
                                  Karya VIP Profiler
                                </h3>
                                <p className="openSans">
                                  Learn more about your authentic self through
                                  deeper understandings of your personality,
                                  interest and, values. Our profilers will help
                                  you to make better career decisions so that
                                  you’ll be able to thrive and find greater
                                  satisfaction in your work.{" "}
                                </p>
                              </div>
                              <div className="width-100 nhp-hover flex">
                                <div className="wd-30 center">
                                  <LazyLoadImage
                                    effect="blur"
                                    loading="lazy"
                                    alt=""
                                    src={h_learn}
                                  />
                                </div>
                                <div className="flex wd-70 column">
                                  <div className="flex width-100">
                                    <h4 className="openSans-Bold">Learn</h4>
                                    <h4
                                      className="openSans-Bold"
                                      style={{ color: "#DDB87A" }}
                                    >
                                      &nbsp;#whoyouare
                                    </h4>
                                  </div>
                                  <div
                                    className="flex"
                                    style={{
                                      width: "45px",
                                      height: "0px",
                                      border: "1px solid #DDB87A",
                                      marginBottom: "10px",
                                    }}
                                  />
                                  <p className="openSans">
                                    Learn how to capitalize the strengths that
                                    your personality have and learn to deal with
                                    the aspect of your personality that could
                                    become obstacles for you.
                                  </p>
                                </div>
                              </div>
                              <div className="width-100 nhp-hover flex">
                                <div className="wd-30 center">
                                  <LazyLoadImage
                                    effect="blur"
                                    loading="lazy"
                                    alt=""
                                    src={h_discov}
                                  />
                                </div>
                                <div className="flex wd-70 column">
                                  <div className="flex width-100">
                                    <h4 className="openSans-Bold">Discover</h4>
                                    <h4
                                      className="openSans-Bold"
                                      style={{ color: "#DDB87A" }}
                                    >
                                      &nbsp;#whatyoulove
                                    </h4>
                                  </div>
                                  <div
                                    className="flex"
                                    style={{
                                      width: "45px",
                                      height: "0px",
                                      border: "1px solid #DDB87A",
                                      marginBottom: "10px",
                                    }}
                                  />
                                  <p className="openSans">
                                    Discover what kind of jobs fit your passion.
                                    You will discover that you have many jobs
                                    fit with your interest that you can pursue.
                                  </p>
                                </div>
                              </div>
                              <div className="width-100 nhp-hover flex">
                                <div className="wd-30 center">
                                  <LazyLoadImage
                                    effect="blur"
                                    loading="lazy"
                                    alt=""
                                    src={h_align}
                                  />
                                </div>
                                <div className="flex wd-70 column">
                                  <div className="flex width-100">
                                    <h4 className="openSans-Bold">Align</h4>
                                    <h4
                                      className="openSans-Bold"
                                      style={{ color: "#DDB87A" }}
                                    >
                                      &nbsp;#whyyouwork
                                    </h4>
                                  </div>
                                  <div
                                    className="flex"
                                    style={{
                                      width: "45px",
                                      height: "0px",
                                      border: "1px solid #DDB87A",
                                      marginBottom: "10px",
                                    }}
                                  />
                                  <p className="openSans">
                                    Align what is important for you in your
                                    ideal job, so that you can choose the
                                    suitable organization that you will be happy
                                    to work for.
                                  </p>
                                </div>
                              </div>
                              <div className="flex width-100 center nhp-bt">
                                <button
                                  onClick={() => {
                                    window.location.href = "/VIP-Report-Static";
                                  }}
                                >
                                  <p>View VIP Sample Report</p>
                                </button>
                                <button
                                  className="flex bt-cl"
                                  style={{
                                    backgroundColor: "#3B6C98",
                                    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.2)",
                                  }}
                                  onClick={() => this.handleCallback("signup")}
                                >
                                  <p
                                    style={{
                                      color: "#FFFFFF",
                                      marginRight: "0px",
                                    }}
                                  >
                                    Get Full Report
                                  </p>
                                  <LazyLoadImage
                                    effect="blur"
                                    loading="lazy"
                                    alt=""
                                    src={r_right}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex width-100 nhp-personality ">
                        <div className="flex width-80 auto space-between">
                          <div className="flex column wd-40 tab-nhp-personality">
                            <div className="flex column personality-ct">
                              <h2 className="HK-Grotesk-Bold c-33i align-left">
                                Try our Personality Profiler to learn how to
                                perform at your best at work
                              </h2>
                              <p className="HK-Grotesk">
                                Everybody is unique in their own way, including
                                their personalities. Therefore, self-awareness
                                is one of the critical components of career
                                success. This assessment will help you to know
                                how to leverage your strength and make you aware
                                of your shortcomings so that you can
                                successfully navigate your daily work life and
                                become the best version of yourself
                              </p>
                            </div>
                            <div
                              className="flex width-100 center nhp-bt"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <button
                                onClick={() => {
                                  window.location.href = "/PID-Report-Static";
                                }}
                              >
                                <p>View Sample Report</p>
                              </button>
                              <button
                                className="flex bt-cl"
                                style={{
                                  backgroundColor: "#3B6C98",
                                  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.2)",
                                }}
                                onClick={() => {
                                  window.location.href =
                                    "/Karya_Personality_Profiler";
                                }}
                              >
                                <p
                                  style={{
                                    color: "#FFFFFF",
                                    marginRight: "0px",
                                  }}
                                >
                                  Get Started
                                </p>
                                <LazyLoadImage
                                  effect="blur"
                                  loading="lazy"
                                  alt=""
                                  src={r_right}
                                />
                              </button>
                            </div>
                          </div>
                          <div className="flex wd-60">
                            <LazyLoadImage
                              effect="blur"
                              loading="lazy"
                              alt=""
                              src={nhp_personality}
                            />
                          </div>
                          <div
                            className=" wd-40 dc-nhp"
                            style={{ flexDirection: "column" }}
                          >
                            <div className="flex column personality-ct">
                              <h2 className="HK-Grotesk-Bold c-33i align-left">
                                Try our Personality Profiler to learn how to
                                perform at your best at work
                              </h2>
                              <p className="HK-Grotesk">
                                Everybody is unique in their own way, including
                                their personalities. Therefore, self-awareness
                                is one of the critical components of career
                                success. This assessment will help you to know
                                how to leverage your strength and make you aware
                                of your shortcomings so that you can
                                successfully navigate your daily work life and
                                become the best version of yourself
                              </p>
                            </div>
                            <div className="flex width-100 center nhp-bt">
                              <button
                                style={{ backgroundColor: "#F9F9F9" }}
                                onClick={() => {
                                  window.location.href = "/PID-Report-Static";
                                }}
                              >
                                <p
                                  style={{
                                    color: " #3B6C98",
                                    fontWeight: "600",
                                  }}
                                >
                                  View Sample Report
                                </p>
                              </button>
                              <button
                                className="flex bt-cl"
                                style={{
                                  backgroundColor: "#3B6C98",
                                  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.2)",
                                }}
                                onClick={() => {
                                  window.location.href =
                                    "/Karya_Personality_Profiler";
                                }}
                              >
                                <p
                                  style={{
                                    color: "#FFFFFF",
                                    marginRight: "0px",
                                  }}
                                >
                                  Get Started
                                </p>
                                <LazyLoadImage
                                  effect="blur"
                                  loading="lazy"
                                  alt=""
                                  src={r_right}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2
                        className="openSans-Bold "
                        style={{ display: "none" }}
                      >
                        Karya VIP Profiler
                      </h2>
                    </div>
                  </div>
                  <div className="width-100 background_job ">
                    <h2
                      className="s-48 m-42auto center wd-1240 Kanit-400 "
                      style={{ color: "white" }}
                    >
                      Connect with Our Recruitment Consultant
                    </h2>
                    <h2
                      className="s-48 center wd-1240 m-auto Kanit w-600"
                      style={{ color: "white" }}
                    >
                      and Find the Right Job
                    </h2>
                    <p className="desc-slide flex HK-Grotesk">
                      Apply to jobs that fit you and we will get in touch with
                      you, whenever your profile matches the requirements
                    </p>
                    <div className="width-100 flex center">
                      <Slider {...settingJob} className="slide_box">
                        {dataJobOpportunityPublic.map((item, i) => (
                          <div key={i} className="box width-100">
                            <div
                              className="width-100"
                              style={{ height: "119px" }}
                            >
                              <div
                                className="flex space-between location"
                                style={{ height: "unset" }}
                              >
                                <p className="time">{item.company}</p>
                              </div>
                              <div
                                className="job_name"
                                style={{ marginTop: "10px" }}
                              >
                                <p style={{ color: "#3B6C98" }}>
                                  {item.job_title}
                                </p>
                              </div>
                              <p className="company">{item.job_type}</p>
                              <div className="flex place">
                                <LazyLoadImage
                                  effect="blur"
                                  src={place}
                                  style={{
                                    height: "12.5px",
                                    width: "10px",
                                    marginTop: "2px",
                                  }}
                                />
                                <p>{item.city}</p>
                              </div>
                            </div>
                            <div className="referral flex flex-end width-100">
                              {/* <p>Referral </p> */}
                              {/* <Link to={`JobDetail_Public/${item.job_opportunity_id}/${item.job_title}`}> */}
                              {/* <Link> */}
                              {/* <button> */}
                              <button
                                onClick={() =>
                                  this.clickViewJob(
                                    `JobDetail_Public/${
                                      item.job_opportunity_id
                                    }/${item.job_title.split(" ").join("-")}`
                                  )
                                }
                              >
                                <p className="apply flex">View Job</p>
                              </button>
                              {/* </Link> */}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                  <div className="flex width-100 center column bg-linear">
                    <div className="flex width-100 center">
                      <div className="flex column width-100  up-card top-100">
                        <Fade bottom>
                          <div className="content-left">
                            <h2 className="mb-14 s-38">Be the Right Fit</h2>
                            <p>
                              Learn how to be adaptable and thrive in today’s
                              competitive job market through our Karya Learning
                              Platform that equips and empowers you to have the
                              right skills and perspectives in dealing with
                              various challenges in the workplace.
                            </p>
                          </div>
                        </Fade>
                      </div>
                    </div>
                    <div className="page-container-outer outer-master">
                      <div className="mobile-information">
                        <div className="flex space-between width-100 columnMobile newHome">
                          <div className="width-50 right">
                            <div className="rightPic">
                              {/* <a href="http://www.freepik.com"  > */}
                              {/* <Slide left> */}
                              <LazyLoadImage
                                effect="blur"
                                src={hp1}
                                title="http://www.freepik.com"
                                data-for="svgTooltip2"
                                cx="10"
                                cy="25"
                                r="22"
                                fill="#fff"
                                stroke="#000"
                                strokeWidth="4"
                              />
                              {/* </Slide> */}
                              <ReactTooltip
                                id="svgTooltip2"
                                place="bottom"
                                type="light"
                              />
                              {/* </a> */}
                            </div>
                          </div>
                          <Fade bottom cascade>
                            <div className="width-50 left auto">
                              <h3 className="mb-24">
                                Upskill {`&`} Reskill Yourself
                              </h3>
                              <p style={{ fontFamily: "HK Grotesk Medium" }}>
                                Upgrade your knowledge {"&"} skills through
                                bite-sized video learning materials. Our
                                learning materials include leadership,
                                management, career development, personal growth,
                                soft skills, and many more. And also, they are
                                all free!
                              </p>
                            </div>
                          </Fade>
                        </div>
                      </div>

                      <div className="flex width-100 column hp-box">
                        <div
                          className="flex space-between width-100 columnMobile newNone newHome"
                          style={{ marginTop: "0px" }}
                        >
                          <div className="width-50 left1 auto">
                            <div className="rightPic">
                              {/* <a href="http://www.freepik.com"  > */}
                              {/* <Slide left> */}
                              <LazyLoadImage
                                effect="blur"
                                src={hp1}
                                title="http://www.freepik.com"
                                data-for="svgTooltip2"
                                cx="10"
                                cy="25"
                                r="22"
                                fill="#fff"
                                stroke="#000"
                                strokeWidth="4"
                              />
                              {/* </Slide> */}
                              <ReactTooltip
                                id="svgTooltip2"
                                place="bottom"
                                type="light"
                              />
                              {/* </a> */}
                            </div>
                          </div>
                          <Fade bottom cascade>
                            <div
                              className="width-50 right1 auto"
                              style={{ textAlign: "left" }}
                            >
                              <h3 className="HK-Grotesk mb-24">
                                Upskill {`&`} Reskill Yourself
                              </h3>
                              <p
                                className="HK-Grotesk"
                                style={{ fontFamily: "HK Grotesk Medium" }}
                              >
                                Upgrade your knowledge {"&"} skills through
                                bite-sized video learning materials. Our
                                learning materials include leadership,
                                management, career development, personal growth,
                                soft skills, and many more. And also, they are
                                all free!
                              </p>
                            </div>
                          </Fade>
                        </div>
                        <div className="mobile width-100">
                          <div className="flex space-between width-100  columnMobile newHome">
                            <div className="width-50 right">
                              <div className="rightPic">
                                <LazyLoadImage
                                  effect="blur"
                                  src={hp2}
                                  title="https://www.freepik.com/photos/hand"
                                  data-for="svgTooltip2"
                                  cx="10"
                                  cy="25"
                                  r="22"
                                  fill="#fff"
                                  stroke="#000"
                                  strokeWidth="4"
                                />
                                <ReactTooltip
                                  id="svgTooltip2"
                                  place="bottom"
                                  type="light"
                                />
                                {/* </a> */}
                              </div>
                            </div>
                            <Fade bottom cascade>
                              <div className="width-50 left auto">
                                <h3 className="HK-Grotesk mb-24">
                                  Learn Anytime, Anywhere
                                </h3>
                                <p
                                  className="HK-Grotesk"
                                  style={{ fontFamily: "HK Grotesk Medium" }}
                                >
                                  We develop user-friendly interfaces to make
                                  learning experiences enjoyable. Learn with
                                  convenience through our self-paced courses. We
                                  also provide multiple quizzes and reading
                                  materials to reinforce your learning effort.
                                  Finally, you will also get certificates upon
                                  completion for each course.
                                </p>
                              </div>
                            </Fade>
                          </div>
                        </div>
                        <div className="dekstop width-100">
                          <div className="flex space-between width-100  columnMobile newHome">
                            <Fade bottom cascade>
                              <div className="width-50 left auto">
                                <h3 className="HK-Grotesk mb-24">
                                  Learn Anytime, Anywhere
                                </h3>
                                <p
                                  className="HK-Grotesk"
                                  style={{ fontFamily: "HK Grotesk Medium" }}
                                >
                                  We develop user-friendly interfaces to make
                                  learning experiences enjoyable. Learn with
                                  convenience through our self-paced courses. We
                                  also provide multiple quizzes and reading
                                  materials to reinforce your learning effort.
                                  Finally, you will also get certificates upon
                                  completion for each course.
                                </p>
                              </div>
                            </Fade>
                            <div className="width-50 right">
                              <div className="rightPic">
                                <LazyLoadImage
                                  effect="blur"
                                  src={hp2}
                                  title="https://www.freepik.com/photos/hand"
                                  data-for="svgTooltip2"
                                  cx="10"
                                  cy="25"
                                  r="22"
                                  fill="#fff"
                                  stroke="#000"
                                  strokeWidth="4"
                                />
                                <ReactTooltip
                                  id="svgTooltip2"
                                  place="bottom"
                                  type="light"
                                />
                                {/* </a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex column width-100  top100">
                    <div className="page-container-outer">
                      <div className="content-left" style={{ margin: "auto" }}>
                        <Pulse>
                          <h2>Career Resources</h2>
                          <p style={{ marginBottom: "0px" }}>
                            Resources about career, self-improvement, and
                            current trends to help you in your career
                            development
                          </p>
                        </Pulse>
                      </div>
                    </div>
                  </div>
                  <div className="flex careerProfile ">
                    <Slider {...settings} className="width-100">
                      {data.map((item, i) => {
                        if (i < 4) {
                          return (
                            <div key={i} className="boxCareer">
                              <LazyLoadImage
                                effect="blur"
                                className="boxCareer-item"
                                src={`${API}/File/Artikel/${item.photo}`}
                              />
                              <div className="icon-boxCareer">
                                <p>
                                  {new Date(item.published_at).getDate()}{" "}
                                  {
                                    bulan[
                                      new Date(item.published_at).getMonth()
                                    ]
                                  }{" "}
                                </p>
                              </div>
                              <a
                                href={`/Career-Resources/${item.id}/${item.judul}`}
                              >
                                <h6>{item.judul}</h6>
                              </a>
                            </div>
                          );
                        }
                      })}
                    </Slider>
                    <div
                      className="line-horizontal-profile"
                      style={{ border: "none" }}
                    />
                  </div>
                  <div className="flex width-100 center column">
                    <a href="/Article">
                      <button className="bt-resc flex">
                        <p>View more Resources</p>
                        <LazyLoadImage
                          effect="blur"
                          loading="lazy"
                          alt=""
                          src={rightt}
                        />
                      </button>
                    </a>
                    <Fade bottom>
                      <div className="content-left">
                        <h2>What our Talents say</h2>
                        <p>Testimonial from Our Talents</p>
                      </div>
                    </Fade>
                  </div>
                  <div className="flex width-100 rev-card">
                    <div className="user-card flex column">
                      <div style={{ padding: "24px" }}>
                        <div className="flex width-100">
                          <div className="av-user">
                            <LazyLoadImage
                              effect="blur"
                              loading="lazy"
                              alt=""
                              src={ruby}
                              className="width-100 height-100"
                            />
                          </div>
                          <div className="flex column space-between">
                            <h3 className="HK-Grotesk">Ruby</h3>
                            <h6 className="HK-Grotesk">
                              Vice President Business Development of PT.
                              Prakarsa Tani Sejati
                            </h6>
                          </div>
                        </div>
                        <p className="HK-Grotesk p-card">
                          It is rare to find a recruitment company with a
                          recruitment solution as good as Karya Talents. What
                          they are providing through Career Consultation
                          sessions helps me to know my strengths and
                          non-strengths, and makes me discover what areas I need
                          to improve, in order to be a better professional. I
                          appreciated Karya Talents' effort to source a suitable
                          engagement that I love, which is aligned to the
                          client, mutual values and expectations.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="user-card flex column">
                      <div style={{ padding: "24px" }}>
                        <div className="flex width-100">
                          <div className="av-user">
                            <LazyLoadImage
                              effect="blur"
                              loading="lazy"
                              alt=""
                              src={louis}
                              className="width-100 height-100"
                            />
                          </div>
                          <div className="flex column space-between">
                            <h3 className="HK-Grotesk">Ade</h3>
                            <h6 className="HK-Grotesk">
                              STR Manager at Local Supermarket
                            </h6>
                          </div>
                        </div>
                        <p className="HK-Grotesk p-card">
                          Karya Talents has been absolutely amazing throughout
                          the whole recruitment process. I can tell that Karya
                          Talents is not just a usual headhunter, because they
                          really help me find the fit company according to my
                          profile and background. The VIP Assessment of Karya
                          Talents really gave me a clear understanding and
                          helped me learn more about myself from a different
                          perspective. In addition to that, I have the
                          opportunity to work with a great leader who trusted
                          and taught me a lot of things. For me, I'm very
                          pleased to provide this letter of recommendation for
                          Karya Talents as a recruitment company.
                        </p>
                      </div>
                    </div>
                    <div className="user-card flex column">
                      <div style={{ padding: "24px" }}>
                        <div className="flex width-100">
                          <div className="av-user">
                            <LazyLoadImage
                              effect="blur"
                              loading="lazy"
                              alt=""
                              src={ade}
                              className="width-100 height-100"
                            />
                          </div>
                          <div className="flex column space-between">
                            <h3 className="HK-Grotesk">Allen</h3>
                            <h6 className="HK-Grotesk">
                              HR Manager – Fast-Growing Green Tech
                            </h6>
                          </div>
                        </div>
                        <p className="HK-Grotesk p-card">
                          My overall experience with Karya Talents was really
                          great. Karya Talents didn't only approach me to find
                          out my background but also explore my suitability with
                          the company on an ongoing basis to find a suitable
                          company, and vice versa. For me who has experience in
                          several companies, Career Consulting sessions allow me
                          to see my vision more clearly in terms of career and
                          what kind of work environment that suits me, and areas
                          I need to develop. I believe there will be challenges
                          in today's company, but the suitability of my work
                          values and company values makes me more energized to
                          move forward together.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
          {/* <Zoom> */}
          <div
            className="background3"
            style={{ background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)" }}
          >
            <div className="background3-inner">
              {/* <h1>Siap menemukan <blue style={{ color: "#0D19A3", backgroundColor: "white" }}>minat kerja</blue> dan <blue style={{ color: "#0D19A3", backgroundColor: "white" }}>pelatihan</blue> yang tepat untuk kamu?</h1> */}
              <div className=" width-100 center tx3">
                <h1 style={{ width: "unset", margin: "0px", padding: "0px" }}>
                  Your Joy Begins{" "}
                </h1>
                <h1
                  className="gold"
                  style={{
                    color: "#E1B564",
                    width: "unset",
                    margin: "0px 0px 0px 11px",
                    padding: "0px",
                  }}
                >
                  Here
                </h1>
              </div>
              {buatakunbaru2}
            </div>
          </div>
          {/* </Zoom> */}

          <Footer_Homepage />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    about1: state.allArticle,
    dataJobOpportunityPublic1: state.JobOpportunityPublicRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllArticle: () => {
      dispatch(getAllArticle());
    },
    getJobOpportunityPublic: () => {
      dispatch(getJobOpportunityPublic());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Talent);
