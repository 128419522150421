import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import ReactPDF from "@react-pdf/renderer";
import _MyAssessment from "./_MyAssessment";
import html2pdf from "html2pdf.js"

const pdfConverter = require("jspdf");
window.html2canvas = html2canvas;

class Chart extends Component {
  cData = {
    labels: ["L 1", "L 2", "L 3", "L 4", "L 5"],
    datasets: [
      {
        label: "Label",
        data: [100, 150, 123, 170, 162],
        backgroundColor: ["red", "green", "yellow", "blue", "orange", "red"]
      }
    ]
  };

  div2PDF = (e) => {
    const but = e.target;
    but.style.display = "none";
    html2pdf().set({
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    });
    html2canvas(document.querySelector("#pdfContainer"), {
      useCORS: true,
      scale: 2,
      allowTaint: true,
      scrollY: -window.scrollY
    }).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new pdfConverter("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;

      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      let imgHeight = (canvas.height * 210) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(img, "png", marginX, marginY, canvasWidth, canvasHeight);

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        // to create next page
        pdf.addPage();
        pdf.addImage(img, "png", marginX, marginY, canvasWidth, canvasHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("restitution-graphique.pdf");
    });
  };

  print=(e)=>{
    var element = document.getElementById('pdfContainer');
    var opt = {
      margin:       0,
      filename:     'myfile-test.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 1 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
html2pdf(element,opt);
  }

  render() {
    const data = {
      labels: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      datasets: [
        {
          label: "Budget",
          backgroundColor: "rgba(59, 110, 113, 1)",
          borderColor: "rgba(59, 110, 113, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(59, 110, 113, 0.7)",
          hoverBorderColor: "rgba(59, 110, 113, 0.7)",
          data: [65, 59, 80, 81, 56, 55, 40]
        },
        {
          label: "Réalisé",
          backgroundColor: "rgba(168, 228, 255, 1)",
          borderColor: "rgba(168, 228, 255, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(168, 228, 255, 0.7)",
          hoverBorderColor: "rgba(168, 228, 255, 0.7)",
          data: [55, 49, 70, 71, 46, 45, 30]
        }
      ]
    };

    const options = {
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true
          }
        ]
      }
    };

    const profile = {
      address
: 
"Palopat",
batch_interested
: 
null,
birth_date
: 
"1998-12-01",
bootcamp_program
: 
null,
city_id
: 
"56440",
comment
: 
"",
comment_interest
: 
"",
comment_vip
: 
"",
company_name
: 
null,
country_id
: 
"102",
createdAt
: 
"2020-07-28T02:29:31.000Z",
created_by
: 
null,
currently_working
: 
null,
date_dsr
: 
null,
degree
: 
null,
education_level_id
: 
1,
email
: 
"nuriindayani44@gmail.com",
emailconfirmed
: 
true,
emailverificationtoken
: 
"c6969cb4b64468640dd30000386f71bb3481010d",
expected_monthly_salary
: 
"980000.00",
expected_monthly_salary_until
: 
"9000018.00",
from_dsr
: 
null,
from_pid
: 
null,
gender_id
: 
2,
goal_bootcamp
: 
null,
id
: 
121,
industry_id
: 
"24,27,18,28",
institution_name
: 
null,
is_login
: 
true,
is_new_journey
: 
true,
is_pid
: 
true,
is_profilecomplete
: 
true,
is_referal
: 
null,
is_tooltip
: 
true,
is_work_assessment
: 
true,
job_function_id
: 
"11,2,7,5,1",
job_level_id
: 
"6",
job_level_independence_id
: 
null,
job_title
: 
null,
jobsearch_status_id
: 
null,
know_kt
: 
null,
last_login
: 
"2022-10-25T08:54:41.000Z",
lastest_monthly_salary
: 
"1781113.00",
lastest_monthly_salary_until
: 
"980000.00",
linkedin_link
: 
"",
name
: 
"Nuri Indayani",
phone_number
: 
"0821099888",
phone_number2
: 
"",
phone_number3
: 
"",
photo
: 
"Photo-Profile-121-1661424826720.jpg",
position_id
: 
2,
professional_summary
: 
"test summary mldap al;akdk a;akld[aka[kakdaddddddddddddddddddd lkmpakmdap km nnnnnnnnnnnnnnnnnnnnnnnnnnnnhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjcfdddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd",
profile_progress
: 
"100.00",
project_award
: 
"[{\"name\":\"test skill\",\"date_taken\":\"11/11/2018\"},{\"name\":\"project 66\",\"date_taken\":\"Juni (2005)\"},{\"name\":\"project 55\",\"date_taken\":\"Agustus (2014)\"},{\"name\":\"project kamis\",\"date_taken\":\"Juni (2015)\"},{\"name\":\"test name pro\",\"date_taken\":\"Agustus (2015)\"},{\"name\":\"test name agustus\",\"date_taken\":\"Agustus (2015)\"},{\"name\":\"test name agustus\",\"date_taken\":\"Agustus (2015)\"}]",
ratting
: 
"-",
ratting_interest
: 
"-",
ratting_vip
: 
"-",
refer_code
: 
null,
refered_by
: 
null,
role_id
: 
1,
skill
: 
"Testing1,Java,C++,Selenium,Recruiting,Public Speaking",
state_id
: 
"1807",
status_id
: 
1,
status_ratting
: 
"Active",
status_ratting_interest
: 
"Active",
status_ratting_vip
: 
"Active",
summary
: 
null,
token
: 
"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTnVyaSBJbmRheWFuaSIsImVtYWlsIjoibnVyaWluZGF5YW5pNDRAZ21haWwuY29tIiwidXNlcl9pZCI6MTIxLCJwZXJtaXNzaW9uTGV2ZWwiOjEsImlhdCI6MTY2OTc5NDU1NiwiZXhwIjoxNjcwMzk5MzU2fQ.c8TzQPCzndB_AaZot6HXQatkIMCPiKSJyGPnKjWBKZo",
total_year_workexperience
: 
null,
updateAt_interest
: 
"2021-08-20T07:46:27.000Z",
updateAt_ratting
: 
"2021-08-18T06:21:46.000Z",
updateAt_vip
: 
"2021-08-18T06:19:32.000Z",
updatedAt
: 
"2022-11-30T07:49:16.000Z",
updated_by
: 
null,
user_refer_friend_id
: 
null,
work_experience_id
: 
5,
work_preference_id
: 
"6",
work_status_id
: 
"5"
    }
    return (
      <div>
        <div id="pdfContainer" style={{ margin: 10 }}>
          <h2>Coucou</h2>
          <p>je suis un paragraphe</p>
          <div className="div2PDF">
            <Bar
              data={this.cData}
              options={{
                title: {
                  display: true,
                  text: "Graph de test",
                  fontSize: 32
                },
                legend: {
                  display: true,
                  position: "right"
                }
              }}
              height={200}
            />
          </div>
          {/* <div className="div2PDF">
            <Bar data={data} options={options} />
          </div> */}
          <_MyAssessment ownProps={profile} />
        </div>
        <div>
          <button onClick={(e) => this.div2PDF(e)}>Export 2 PDF</button>
          <button onClick={(e) => this.print(e)}>print PDF</button>
        </div>
      </div>
    );
  }
}

export default Chart;

// ReactPDF.render(<Chart />, "example.pdf");
// ReactDOM.render(<Chart />, document.getElementById("root"));
