import React, { Component } from "react";
import hp1 from "../images/hp_2.png";
import hp2 from "../images/hp_1.png";
import nhp_vip from "../images/nhp_vip.png";
import nhp_personality from "../images/nhp_personality.png";
import h_learn from "../images/h-learn.png";
import h_discov from "../images/h-discov.png";
import h_align from "../images/h-align.png";
import r_right from "../images/r-right.svg";
import albert from "../images/albert.jpg";
import ruby from "../images/ruby.png";
import ade from "../images/ade.jpg";
import louis from "../images/louis.jpg";
import rightt from "../images/next_arrow.svg";
import warning_referral from "../images/warning_referral.svg";
import Modal from "react-awesome-modal";
import arrowNextGray from "../images/ArrowNextGray.svg";
import left_disabled from "../images/left_disabled.svg";
import light_arr from "../images/light_arr.svg";
import right_light from "../images/right_light.svg";
import left_mobile from "../images/ar_left_mobile.svg";
import right_mobile from "../images/ar_right_mobile.svg";
import place from "../images/place.png";
import thumbnail from "../images/video-thumbnail.png";
import Footer_Homepage from "../components/Footer";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { Talents } from "../components/NavBar";
import Nav from "../components/Nav";
import Navbar_Homepage from "../components/Navbar_Homepage";
import ReactGA from "react-ga";
import Session from "../components/Session";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllArticle } from "../_actions/article";
import { getJobOpportunityPublic } from "../_actions/jobs";
import axios from "axios";
import { API } from "../_redux/helper";
import videoKarya from "../images/videoKarya.mp4";
import ReactTooltip from "react-tooltip";
import Fade from "react-reveal/Fade";
import Pulse from "react-reveal/Pulse";
import Zoom from "react-reveal/Zoom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import Hero from "../images/hero-comp.png";
import Navbar from "../components/NavBar";

ReactGA.initialize("UA-170839109-1");

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      br: 0,
      login: false,
      register: false,
      forgotPassword: false,
      visible: false,
      next_job: "",
      ft1: [],
      photo: [],
      banner_d: "",
      banner_m: "",
      art: "",
      src: "",
      link: "",
      expired: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    window.addEventListener("scroll", this.handleScroll);
    this.props.getAllArticle();
    this.props.getJobOpportunityPublic();

    var timeSession = localStorage.getItem("setupTime");
    if (timeSession) {
      if (new Date().getTime() - timeSession > 60 * 30 * 1000) {
        Session.removeSessionLogout();
      } else if (localStorage.getItem("logout") == "false") {
        /* eslint eqeqeq: 0 */
        localStorage.setItem("logout", false);
        Session.checkToken();
        window.location.href = "/NewDashboard";
      }
    } else if (
      this.props.location.search &&
      this.props.location.search.indexOf("refer_code") == -1
    ) {
      this.state.login = true;
    } else if (this.props.location.search.indexOf("refer_code") != -1) {
      this.state.register = true;
    }
    if (this.props.location.search.indexOf("expired-token") != -1) {
      // console.log('udah masuk di sini')
      this.state.expired = true;
    }

    let list_ref = [
      "/referral",
      "/work_assessment",
      "/myprofile",
      "/newdashboard",
      "/digitallearningplatform",
      "/careercenter",
      "/jobopportunities",
    ];
    // let list_ref = ["/referral"]
    if (sessionStorage.getItem("log-user") == "false") {
      sessionStorage.setItem("ref", "/Homepage");
      sessionStorage.setItem("log-user", "true");
    }
    if (
      sessionStorage.getItem("ref") !== "" &&
      sessionStorage.getItem("ref") !== null
    ) {
      if (
        list_ref.indexOf(sessionStorage.getItem("ref").toLowerCase()) !== -1
      ) {
        this.setState({ login: true });
      } else if (localStorage.getItem("logout") == false) {
        this.setState({ login: false });
      }
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track("/Homepage");
    const idEl = [3, 4, 5];
    const imageLoader = new Image();
    idEl.forEach((el) => {
      axios.get(`${API}/api/banner/${el}`).then((res) => {
        if (res.data.banner_name == "banner_homepage_dekstop") {
          imageLoader.src = `${API}/File/Banner/${res.data.photo}`;
          this.setState({ banner_d: imageLoader.src });
        } else if (res.data.banner_name == "banner_homepage_mobile") {
          imageLoader.src = `${API}/File/Banner/${res.data.photo}`;
          this.setState({ banner_m: imageLoader.src });
        } else if (res.data.banner_name == "art_homepage") {
          imageLoader.src = `${API}/File/Banner/${res.data.photo}`;
          this.setState({ art: imageLoader.src });
        } else {
          // console.log("error");
        }
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (parseFloat(winScroll) < 250) {
      const height = document.getElementById("container-u").clientHeight;
      this.setState({ height_: height });
      this.setState({ br: scrolled });
    }
  };

  closeExpired = () => {
    this.setState({
      expired: false,
    });
  };

  handleCallback = (childData) => {
    // this.setState({link: childData})
    window.location.href = `/${childData}`;
  };

  clickViewJob(a) {
    window.location.href = a;
  }

  render() {
    if (this.props.location.state) {
      if (this.props.location.state.from.search) {
        window.location.href = `/Homepage${this.props.location.state.from.search}`;
      }
    }
    const { data, isLoading, error } = this.props.about1;
    const { dataJobOpportunityPublic } = this.props.dataJobOpportunityPublic1;
    const { br } = this.state;
    let st_dis = false;
    if (br === 0) {
      st_dis = false;
    } else {
      st_dis = true;
    }

    var bulan = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];

    let buatakunbaru;
    let buatakunbaru2;
    if (!sessionStorage.getItem("token")) {
      buatakunbaru = (
        <div
          className="btn-text-image"
          onClick={ReactGA.pageview(
            window.location.pathname + window.location.search
          )}
        >
          <button
            value="Open"
            onClick={() => this.handleCallback("signup")}
            style={{ borderRadius: "32px", backgroundColor: "#EDAF12" }}
          >
            <a>Join Us</a>
          </button>
        </div>
      );
      buatakunbaru2 = (
        <div
          className="btn-text-image1"
          onClick={ReactGA.pageview(
            window.location.pathname + window.location.search
          )}
        >
          <button
            value="Open"
            onClick={() => this.handleCallback("signup")}
            style={{ borderRadius: "32px" }}
          >
            <a>Join Us</a>
          </button>
        </div>
      );
    }

    const settings = {
      //   dots: true,
      infinite: true,
      slidesToShow: 3,
      arrow: false,
      slidesToScroll: 1,
      // autoplay: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1310,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            // centerMode: true,
            centerPadding: "70px",
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "70px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "20px",
          },
        },
        {
          breakpoint: 373,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let nextStep;
    const settingJob = {
      beforeChange: (current, next, dots) => this.setState({ next_job: next }),
      //  nextStep2 = next,
      afterChange: (current) => {
        if (current === 4) {
          nextStep = (
            <Link to="GetStarted">
              <button type="button" className="btn btn-primary">
                {" "}
                {nextStep}I Understand
              </button>
            </Link>
          );
          this.setState({ cek: nextStep });
        }
      },

      arrow: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      // autoplay:true,
      nextArrow:
        this.state.next_job < dataJobOpportunityPublic.length - 4 ? (
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            alt=""
            src={right_light}
          />
        ) : (
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            alt=""
            src={arrowNextGray}
          />
        ),
      prevArrow:
        this.state.next_job > 0 ? (
          <LazyLoadImage effect="blur" loading="lazy" alt="" src={light_arr} />
        ) : (
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            alt=""
            src={left_disabled}
          />
        ),
      responsive: [
        {
          breakpoint: 1763,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 3 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_light}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={light_arr}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 2 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_light}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={light_arr}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
          },
        },
        // {
        //   breakpoint: 845,
        //   settings: {
        //     slidesToShow: 2,
        //     slidesToScroll: 1,
        //   },
        // },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 2 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
          },
        },
        {
          breakpoint: 651,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // dots:true,
            // fade: true,
            nextArrow:
              this.state.next_job < dataJobOpportunityPublic.length - 1 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={right_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={arrowNextGray}
                />
              ),
            prevArrow:
              this.state.next_job > 0 ? (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_mobile}
                />
              ) : (
                <LazyLoadImage
                  effect="blur"
                  loading="lazy"
                  alt=""
                  src={left_disabled}
                />
              ),
            speed: 1000,
            // centerMode: true,
            // centerPadding: "35px",
          },
        },
      ],
    };
    // if (isLoading || this.state.photo ==undefined || this.state.ft1 == undefined || this.state.ft1.length < 3) {
    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <body></body>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          {/* <meta name="keywords" id="index_keywords"
            content="Looking for a job? Discover many job vacancies & find your next career journey from various field and companies on Karya Talents. Apply today!
            "/> */}
        </Helmet>
        <div className="home-layer tal_ homePage nhp-rev">
          <div className="box-wrapper central_page">
            <div className="wrapper">
              <div className=" column width-100 Hp-22 _s bg-ff">
                {/* {Talents} */}
                <Navbar_Homepage status={st_dis} />
                <div className="page-container homepage-master width-100">
                  <div
                    className="backgroundTablet"
                    style={{ backgroundImage: `url(${this.state.banner_d})` }}
                    id="container-u"
                  >
                    <div
                      className="wd-content tr- flex-mob auto height-100 space-between h_"
                      id="homepage22"
                    >
                      <div className="wd-490 flex height-100 h_">
                        <Zoom>
                          <div className="text-bx tr- flex column">
                            <p className="c-ff flex Kanit-400 s-64 mt-162 stroke_">
                              ASSESSMENT
                            </p>
                            <p className="c-ff Kanit-300 s-2437">
                              Executive Assessment
                              <p className="mt--5">
                                A Through Understanding of Executive Strengths
                                and Potential
                              </p>
                            </p>
                            {/* <span style={{fontFamily:"Poppins Bold", marginBottom:"0px"}} className="c-ff">Find Joy at Work</span>
                          <span style={{fontFamily:"Poppins Bold", color:"#E1B564"}} className="c-ff">with Karya Talents</span>
                          <h1 className="c-ff Kanit-300 s-2436 wd-371 ">The right place to get tailored job offers that matches your profile</h1> */}
                          </div>
                        </Zoom>
                      </div>
                      <div className="boxImage">
                        <LazyLoadImage
                          loading="lazy"
                          alt=""
                          className="width-100"
                          src={this.state.art}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex column width-100 center">
                  <div className="block width-100 center">
                    <div className="flex width-100 nhp-container">
                      <div className="flex width-80 auto space-between">
                        <div className="flex  left-i">
                          <LazyLoadImage
                            effect="blur"
                            loading="lazy"
                            alt=""
                            src={nhp_vip}
                            className="flex width-100 auto"
                          />
                        </div>
                        <div className="flex  column right-i">
                          <div className="flex column right-c">
                            <div className="width-100 flex column nhp-title">
                              <h3 className="openSans-Bold">Our Services</h3>
                            </div>
                            <div className="width-100 nhp-hover flex">
                              <div className="wd-30 center">
                                <LazyLoadImage
                                  effect="blur"
                                  loading="lazy"
                                  alt=""
                                  src={h_learn}
                                />
                              </div>
                              <div className="flex wd-70 column">
                                <div className="flex width-100">
                                  <h4 className="openSans-Bold">Recruitment Assessment</h4>
                                  {/* <h4
                                    className="openSans-Bold"
                                    style={{ color: "#DDB87A" }}
                                  >
                                    &nbsp;#whoyouare
                                  </h4> */}
                                </div>
                                <div
                                  className="flex"
                                  style={{
                                    width: "45px",
                                    height: "0px",
                                    border: "1px solid #DDB87A",
                                    marginBottom: "10px",
                                  }}
                                />
                                <p className="openSans">
                                We help organizations who need an independent body for recruitment to hire the right talents.
                                </p>
                              </div>
                            </div>
                            <div className="width-100 nhp-hover flex">
                              <div className="wd-30 center">
                                <LazyLoadImage
                                  effect="blur"
                                  loading="lazy"
                                  alt=""
                                  src={h_discov}
                                />
                              </div>
                              <div className="flex wd-70 column">
                                <div className="flex width-100">
                                  <h4 className="openSans-Bold">Talent Promotion Assessment</h4>
                                  {/* <h4
                                    className="openSans-Bold"
                                    style={{ color: "#DDB87A" }}
                                  >
                                    &nbsp;#whatyoulove
                                  </h4> */}
                                </div>
                                <div
                                  className="flex"
                                  style={{
                                    width: "45px",
                                    height: "0px",
                                    border: "1px solid #DDB87A",
                                    marginBottom: "10px",
                                  }}
                                />
                                <p className="openSans">
                                We provide assessment that aids to determine whether the employees should receive a promotion or raise.
                                </p>
                              </div>
                            </div>
                            <div className="width-100 nhp-hover flex">
                              <div className="wd-30 center">
                                <LazyLoadImage
                                  effect="blur"
                                  loading="lazy"
                                  alt=""
                                  src={h_align}
                                />
                              </div>
                              <div className="flex wd-70 column">
                                <div className="flex width-100">
                                  <h4 className="openSans-Bold">Close-the-Gap Talent Profiling Assessment</h4>
                                  {/* <h4
                                    className="openSans-Bold"
                                    style={{ color: "#DDB87A" }}
                                  >
                                    &nbsp;#whatyoulove
                                  </h4> */}
                                </div>
                                <div
                                  className="flex"
                                  style={{
                                    width: "45px",
                                    height: "0px",
                                    border: "1px solid #DDB87A",
                                    marginBottom: "10px",
                                  }}
                                />
                                <p className="openSans">
                                We help you to build a competent workforce by providing you with recommendations to fill the gaps within your organization.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer_Homepage />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    about1: state.allArticle,
    dataJobOpportunityPublic1: state.JobOpportunityPublicRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllArticle: () => {
      dispatch(getAllArticle());
    },
    getJobOpportunityPublic: () => {
      dispatch(getJobOpportunityPublic());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
