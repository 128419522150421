
import React, { Component } from "react";
import { connect } from "react-redux";
import { getQuest, answerQuest } from "../_actions/quest";
import sepuluh from '../images/10.png'
import duapuluh from '../images/20.png'
import tigapuluh from '../images/30.png'
import emot1 from '../images/emot1a.png'
import emot2 from '../images/emot2a.png'
import emot3 from '../images/emot3a.png'
import emot4 from '../images/emot4a.png'
import emot5 from '../images/emot5a.png'
import Loading from './Loading.js'
import PageProgressbar from './PageProgressbar'
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import ReactGA from 'react-ga';
import {GAevent} from './TrackingEvent'
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

var $ = require('jquery');

const styles = theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '10%',
    width: 32,
    height: 32,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  },
  checkedIcon1: {
    backgroundColor: '#FF7575',
    transition:"0.4s",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#FF7575',
    },
  },
  checkedIcon2: {
    backgroundColor: '#FFA775',
    transition:"0.4s",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#FFA775',
    },
  },
  checkedIcon3: {
    backgroundColor: '#F7E43F',
    transition:"0.4s",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#F7E43F',
    },
  },
  checkedIcon4: {
    backgroundColor: '#AEE05D',
    transition:"0.4s",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#AEE05D',
    },
  },
  checkedIcon5: {
    backgroundColor: '#61C877',
    transition:"0.4s",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#61C877',
    },
  },
})

class Quest extends Component {
  constructor() {
    super()
    this.state = {
      sumAll :0,
      todos: {
        id: 0,
        radio: [],
        scoring: 0,
        user_id: "",
        profile_question_id: " ",
      },
      checked1: "",
      checked2: "",
      checked3: "",
      checked4: "",
      checked5: "",
      selectedValue: [],
      user_id: "",
      currentPage: 1,
      todosPerPage: 10,
      upperPageBound: 10,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: 'disabled',
      pageBound: 10
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem('token')
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id })
    this.props.getQuest(user_id);
  }

  handleToken = () => {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem('token')
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
  }

  handleChange = (profile_question_id) => (e) => {
    let value = e.target.value
    let dataRadio = { profile_question_id, value };
    const { todosPerPage, selectedValue, currentPage } = this.state;
    const filter = this.state.selectedValue.findIndex((selectedValue) => selectedValue.profile_question_id === profile_question_id)
    if (filter === -1) {
      this.state.selectedValue.push({ profile_question_id, value })
      this.setState({ selectedValue: this.state.selectedValue })
      this.setState({ sumAll : this.state.sumAll + 1 })
    } else {
      this.state.selectedValue[filter] = { profile_question_id, value }
      this.setState({ selectedValue: this.state.selectedValue })
      // this.setState({ sumAll : this.state.sumAll - 1 })
    }
    const areAllRadiosChecked = selectedValue.length / currentPage >= todosPerPage;
    if (areAllRadiosChecked) {
      this.setState({ isNextBtnActive: '' });
    }
  }


  answer = (id, scoringRadio, e) => {
    const token = sessionStorage.getItem("token")
    const scoring = { scoring: scoringRadio }
    this.props.answerQuest1(token, id, scoring)
    GAevent('TableQuestion','Submit Question') 
  };

  componentDidUpdate() {
    $("ul li.active").removeClass('active');
    $('ul li#' + this.state.currentPage).addClass('active');
  }
  handleClick(event) {
    event.preventDefault();
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
    $("ul li.active").removeClass('active');
    $('ul li#' + listid).addClass('active');
    this.setPrevAndNextBtnClass(listid);
  }
  setPrevAndNextBtnClass(listid) {
    let { todos, todosPerPage, selectedValue } = this.state;
    let totalPage = Math.ceil(todos.length / todosPerPage);
    let areAllRadiosChecked = selectedValue.length / listid >= todosPerPage;
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    }
    else if (areAllRadiosChecked) {
      this.setState({ isNextBtnActive: '' });
    }
  }
  btnIncrementClick(e) {
    e.preventDefault()
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick(e) {
    e.preventDefault()
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick(e) {
    e.preventDefault()
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  btnNextClick(e) {
    e.preventDefault()
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    const { data, isLoading, error } = this.props.quest1;
    const { currentPage, todosPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive, selectedValue } = this.state;
    const todos = data.map((item, i) => {
      const select = this.state.selectedValue.findIndex((selectedValue) => selectedValue.profile_question_id === item.profile_question_id);
      const selectedProfile = this.state.selectedValue[select] ? this.state.selectedValue[select].profile_question_id : ""
      const selected = this.state.selectedValue[select] ? this.state.selectedValue[select].value : ""
      const { classes } = this.props;

      return (
          <tr key={i} className="flex question flex-question " id="test">
            <div className="flex width-64">
            <p style={{width:"unset", paddingRight:"5px"}}>{i + 1}. </p>
            <p style={{width:"100%", textAlign:"left"}}> {item.question}</p>
            </div>
            <div className="container-radio">

              <Radio className={classes.root} disableRipple color="default"
                checked={selectedProfile === item.profile_question_id && selected === "1"} 
                onChange={this.handleChange(item.profile_question_id)} 
                type="radio" name={"pilih[" + item.profile_question_id + "]"} value={1} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon1)} />}
                icon={<span className={classes.icon} />} 
                onClick={() => { this.answer(item.id, 1) }}/>
              <Radio className={classes.root} disableRipple color="default"
                checked={selectedProfile === item.profile_question_id && selected === "2"} 
                onChange={this.handleChange(item.profile_question_id)} 
                type="radio" name={"pilih[" + item.profile_question_id + "]"} value={2}
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon2)} />}
                icon={<span className={classes.icon} />}
                onClick={() => { this.answer(item.id, 2) }}/>
              <Radio className={classes.root} disableRipple color="default"
                checked={selectedProfile === item.profile_question_id && selected === "3"} 
                onChange={this.handleChange(item.profile_question_id)} 
                type="radio" name={"pilih[" + item.profile_question_id + "]"} value={3} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon3)} />}
                icon={<span className={classes.icon} />} 
                onClick={() => { this.answer(item.id, 3) }} />
              <Radio className={classes.root} disableRipple color="default"
                checked={selectedProfile === item.profile_question_id && selected === "4"} 
                onChange={this.handleChange(item.profile_question_id)} 
                type="radio" name={"pilih[" + item.profile_question_id + "]"} value={4} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon4)} />}
                icon={<span className={classes.icon} />} 
                onClick={() => { this.answer(item.id, 4) }} />
              <Radio className={classes.root} disableRipple color="default"
                checked={selectedProfile === item.profile_question_id && selected === "5"} 
                onChange={this.handleChange(item.profile_question_id)} 
                type="radio" name={"pilih[" + item.profile_question_id + "]"} value={5} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon5)} />}
                icon={<span className={classes.icon} />}
                onClick={() => { this.answer(item.id, 5) }} />

            </div>
          </tr>
      );
    })

    // Button class
    const areAllRadiosChecked = selectedValue.length / currentPage >= todosPerPage;
    const classNextActive = areAllRadiosChecked ? 'active-quest-btn' : 'inactive-quest-btn';
    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className='active' id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
        )
      }
      else if ((number < upperPageBound + 1) && number > lowerPageBound) {
        return (
          <li key={number} id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
        )
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip;</a></li>
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
    }

    let renderNextBtn = null;
    if (currentPage >= 1) {
      renderNextBtn = <button className={classNextActive} onClick={this.btnNextClick} disabled={isNextBtnActive}><a href='#' id="btnNext"> Next </a></button>
    }
    if (currentPage >= 3 && this.state.sumAll==30) {
      renderNextBtn = <Link to="/Education-and-Work-Experience"><button className={classNextActive} disabled={isNextBtnActive}><a href='Education-and-Work-Experience' id="btnNext"><a> Next </a></a></button></Link>
    }

    let renderPrevBtn = null;
    if (currentPage <= 1) {
      renderPrevBtn = <button className="inactive-quest-btn"><a href='#' id="btnPrev" disabled={isPrevBtnActive}> Previous </a></button>
    }
    if (currentPage > 1) {
      renderPrevBtn = <button className="active-quest-btn" onClick={this.btnPrevClick}><a href='#' id="btnPrev" > Previous </a></button>
    }

    let ketPage = null;
    if (currentPage <= 1) {
      ketPage = <div>1/3</div>
    } else if (currentPage === 2) {
      ketPage = <div>2/3</div>
    }
    else {
      ketPage = <div>3/3</div>
    }


    let ProgressBar = null;
    if (currentPage <= 1) {
      ProgressBar = <div className="progress-bar-quest">
        <p className="progress-page-title"><LazyLoadImage effect="blur" loading="lazy" alt="" src={sepuluh}/> &nbsp; / 30 Questions</p>
        <PageProgressbar value={33} style={{margin:"10px 0"}} /></div>
    }
    else if (currentPage === 2) {
      ProgressBar = <div className="progress-bar-quest">
        <p className="progress-page-title"><LazyLoadImage effect="blur" loading="lazy" alt="" src={duapuluh}/> &nbsp; / 30 Questions</p>
        <PageProgressbar value={67} /></div>
    }
    else {
      ProgressBar = <div className="progress-bar-quest">
        <p className="progress-page-title"><LazyLoadImage effect="blur" loading="lazy" alt="" src={tigapuluh}/> &nbsp; / 30 Questions</p>
        <PageProgressbar value={100} /></div>
    }

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
         </React.Fragment>
      );
    }

    return (
      <div>
        <div className="question-bar">
          {ProgressBar}
          <div className=" box-quest flex column">
            <div className="flex width-100 space-between">
            <div ><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot1} className="rad-100"/></div>
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot2} className="rad-100"/></div>
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot3} className="rad-100"/></div>
            <div><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot4} className="rad-100"/></div>
            <div className="flex column"><LazyLoadImage effect="blur" loading="lazy" alt="" src={emot5} className="rad-100"/>
            </div>
          </div>
          <div className="flex space-between _abs">
          <p className="Monserrat-Bold">Strongly Dislike</p>
            <p className="Monserrat-Bold">Strongly Like</p>
          </div>
          </div>
        </div>
        <div className="table-questions">
          {renderTodos}
          <div className="pagination">
            <div className="flex group-quest">
              {renderPrevBtn}
              <p>
                {ketPage}
              </p>
              {/* {pageDecrementBtn} */}
              {/* {renderPageNumbers} */}
              {/* {pageIncrementBtn} */}
              {renderNextBtn}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    quest1: state.quest
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuest: user_id => {
      dispatch(getQuest(user_id));
    },
    answerQuest1: (token, id, scoring) => {
      dispatch(answerQuest(token, id, scoring));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Quest));
