import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import {API} from '../_redux/helper'
import Session from "./Session"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const StyledButton = withStyles({
  root: {
    border: 0,
    padding: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: "#ffffff",
    },
    '&:onclick': {
      backgroundColor: "#ffffff",
    },
  },
})(IconButton);

export default function MenuAppBar() {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profiles, setProfiles] = useState([])
  const open = Boolean(anchorEl);

  useEffect(() => {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    
    axios
        .get(`${API}/api/user/`+user_id,{
          headers: {
            Authorization: token1,
          },
        })
        .then(res => {
            setProfiles(res.data)
        })
        .catch(err => {
        })
  }, [])

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleLogout=()=>{
    Session.removeSessionLogout()
}

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {/*For desktop  */}
      <div className="desktop-dropmenu">
        <div className={classes.root}>
        {/* <AppBar position="static" style={{backgroundColor:"green"}} >
          <Toolbar> */}
            {auth && (
              <div>
                <StyledButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  <li style={{display:"flex", paddingTop:"0px"}} >
                  <p className="dropdownmenu-header" style={{textTransform:"uppercase"}}>{profiles.name}</p>
                  
                  </li>
                </StyledButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                  style={{top:"30px"}}
                >
                  <Link to="/NewDashboard"><MenuItem onClick={handleClose} className="lineHeader"  >DASHBOARD </MenuItem></Link>
                  <Link to="/MyProfile"><MenuItem onClick={handleClose} className="lineHeader"  >MY PROFILE</MenuItem></Link>
                  <Link to="/MyAssessment"><MenuItem onClick={handleClose} className="lineHeader" >ASSESSMENT</MenuItem></Link>
                  <Link to="/"><MenuItem onClick={handleLogout} className="lineHeader"  >LOGOUT</MenuItem></Link>
                </Menu>
              </div>
            )}
          {/* </Toolbar>
        </AppBar> */}
        </div>
      </div>

      {/* For mobile */}
      <div className="mobile-dropmenu">
        <a href="/NewDashboard"><li>DASHBOARD</li></a>
        <a href="/MyProfile"><li>MY PROFILE</li></a>
        <a href="/MyAssessment"><li>ASSESSMENT</li></a>
        <Link  onClick={handleLogout}><li>LOGOUT</li></Link>
      </div>
    </React.Fragment>
  );
}
