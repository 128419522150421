import React, { Component } from 'react';
import ip from "../images/profil-img.jpg"
import profile_static from "../images/ProfilePicture.png";
import { FileUploader } from "react-drag-drop-files";
import iconChange from "../images/iconChange.png";
import {
  phone, mail, cv, cv_green, people, salary, skill, work_exp, education, certificate, project, industry,
  art_certification, art_personal, art_education, art_project, art_salary, art_skills, art_work_exp, pen
} from "../_redux/svg"
import Personal_Information from './My_Profile/Personal_Information';
import Salary_Benefit from './My_Profile/Salary_Benefit';
import Skills from './My_Profile/Skills';
import Work_Experience from './My_Profile/Work_Experience';
import Education from './My_Profile/Education';
import Certification from './My_Profile/Certification';
import Project_And_Awards from './My_Profile/Project_And_Awards';
import Industry_And_Job from './My_Profile/Industry_And_Job';
import Popup from './My_Profile/Popup';
import { getLastId } from "../_actions/last";
import { getExpId } from "../_actions/expected";
import { getWork, getWorkId, postWork, putWork, deleteWork, getIndustri, getJobFunction, getJobLevel, getWorkPre, getWorkSta } from "../_actions/work";
import {setInformation, getProvince, getCity, getCountry, getOnCity, getOnlyCity, getOnState, getOnCountry, getSkill, getProject } from "../_actions/user";
import { getCertificationId, postCertification, putCertification, deleteCertification, } from "../_actions/certification";
import { getEducationId, postEducation, putEducation, deleteEducation, getListEducation } from "../_actions/education";
import { getDocumentId, getDocumentPendukung } from "../_actions/document";
import { formatDate, range, list_month, month_year, sortFunction, sortFunctionDate, sortByMonth } from "../_redux/function"
import { connect } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Spinner from 'react-spinner-material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styles from './_MyProfile.css';
import { API } from "../_redux/helper";
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
// import { token } from "../_redux/function"
import { Upload, message } from 'antd';

const token = localStorage.getItem("token")
class _MyProfile extends Component {
  constructor() {
    super()
    this.state = {
      industry_: ["Food & Beverages", "Automotive", "Statistics", "Communication", "Information Technology"],
      job_function_: ["Customer Service", "Education & Training", "Information Techonology & Software", "Manufacturing"],
      user_id: "",
      add_work: false,
      add_edu: false,
      add_certification: false,
      add_project: false,
      add_documents: false,
      upload_cv1: false,
      upload_cv2: false,
      job_desc: false,
      personal_info: false,
      salary_: false,
      skills_: false,
      work_experience: false,
      education_: false,
      certification_: false,
      project_: false,
      interest_: false,
      promoter_score: false,
      feedback: false,
      file: false,
      job_description_id: 0,
      data_doc:[],
      data_doc2:[],
      dataProvince_:[],
      dataCity_:[],
      status_doc:false,
      status_doc2:false,
      ncity_name:"",
      nstate_name:""
    }
  }

  async componentDidMount(){
    var jwt = require('jsonwebtoken');
    var decode1 = jwt.decode(token);
    const user_id = decode1["user_id"];
    this.setState({user_id:user_id})
    this.props.getIndustri();
    this.props.getJobFunction();
    this.props.getJobLevel();
    this.props.getOnlyCity()
    this.props.getCountry();
    this.props.getListEducation();
    this.props.getWorkPre()
    this.props.getWorkSta()
    this.props.getSkill()
this.getWork(user_id)
    // this.props.getWorkId(user_id);
    // this.props.getCertificationId(user_id)
    // this.props.getEducationId(user_id);
    // this.props.getExpId(user_id);
    // this.props.getLastId(user_id);
    // this.props.getProject(user_id)
    // this.getAllDocument(user_id)
    this.getProvinceId()

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.user_id !== prevProps.profile.id) {
      const a = prevProps.profile
      this.setState({ user_id: prevProps.profile.id })
      this.props.getProvince(a.country_id)
      this.props.getOnState(a.state_id);
      this.props.getCity(a.state_id);
      this.getProvinceId()
      this.props.getOnCity(a.state_id, a.country_id, a.city_id);
      
    }
  }

  getAllDocument=(user_id)=>{
    const url = [{link:`${API}/api/user_document?user_id=${user_id}&document_type_id=1`,n1:"data_doc",n2:"status_doc"},
                {link:`${API}/api/user_document?user_id=${user_id}&document_type_id=2`,n1:"data_doc2",n2:"status_doc2"}]

    {url.map((item)=>(
     axios.get(item.link,{headers: {
      Authorization: ` ${token}`,
    }})
    .then(res => {
      this.setState({[item.n1]:res.data,[item.n2]:true}) 
    })))}
  }

  getProvinceId =(e)=>{
    const { profile } = this.props
    axios.get(`${API}/api/state?country_id=${profile.country_id}`).then(res => {
      this.setState({dataProvince_:res.data})
      res.data.map(item=>{
        if (parseInt(item.id) === parseInt(profile.state_id)){
          this.setState({nstate_name:item.state_name})
          }})
    })
    axios.get(`${API}/api/city?state_id=${profile.state_id}`).then(res => {
      this.setState({dataCity_:res.data})
      res.data.map(item=>{
        if (parseInt(item.id)===parseInt(profile.city_id)){
          this.setState({ncity_name:item.city_name})
        }
    })}
      )}


  setModal=(e)=>{
    const m = ["add_work","add_edu","add_certification","add_project","add_documents","upload_cv1", "upload_cv2","promoter_score","feedback",
              "job_desc","personal_info","salary_","skills_","work_experience","education_","certification_","project_","interest_","file"]
    m.map(item=>this.setState({[item]:false}))
    setTimeout(() => {
      this.setState({[e]:true})
    }, 300);
   }

  handleChange = (file) => {
    if (file) {
      const { profile } = this.props
      let formData = new FormData();
      if (file) formData.append("photo", file);
      axios
        .put(`${API}/api/user/${profile.id}`, formData, {
          headers: {
            authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.props.getProfile(this.state.user_id)
        }).catch(err => {
          console.log(err, "error");
        })

    }
    //setFile(file);
  };

  handlePopup = (e) => {
    this.setState({ [e]: !this.state[e] })
  }

  changePopup = (e, y) => {
    this.setState({ [e]: !this.state[e], [y]: !this.state[y] })
  }

  handleProvince = (e) => {
    this.props.getProvince(e)
    this.getProvinceId()
  }

  updateJobDecId = (y) => {
    var newY = parseInt(y)
    this.setState({ job_description_id: newY })
  }

  handleCity = (e) => {
    this.props.getCity(e)
    this.props.getOnState(e);
  }

  getDoc=(user_id)=>{
    this.getAllDocument(user_id)
  }

  getSalaryBenefit=(user_id)=>{
    this.props.getExpId(user_id);
    this.props.getLastId(user_id);
    this.props.getProfile(user_id)
    this.props.getProject(user_id)
  }
  getCity=(state_id, country_id, city_id)=>{
    this.props.getOnState(state_id);
    this.props.getOnCity(state_id, country_id, city_id);
    this.props.getCity(state_id)
    this.getProvinceId()
  }
  
  getWork=(user_id)=>{
    setTimeout(() => {
      this.props.getWorkId(user_id);
    this.props.getProfile(user_id)
    this.props.getCertificationId(user_id)
    this.props.getEducationId(user_id);
    this.props.getExpId(user_id);
    this.props.getLastId(user_id);
    this.props.getProject(user_id)
    this.getAllDocument(user_id)
    }, 700);
    
  }

  getEducation=(user_id)=>{
    this.props.getEducationId(user_id);
  }

  getCertification=(user_id)=>{
    this.props.getCertificationId(user_id)
  }


  render() {
    const { add_certification, add_documents, add_edu, add_project, add_work, upload_cv1, upload_cv2, job_desc,
      personal_info, salary_, skills_, work_experience, education_, certification_, data_doc,data_doc2,
      project_, interest_, promoter_score, feedback, job_description_id, status_doc, status_doc2 ,ncity_name,nstate_name} = this.state
    const { profile } = this.props
    const { workId, isLoading } = this.props.workId1;
    const { work } = this.props.work2;
    const { onlyCity1 } = this.props
    const { dataIndustri } = this.props.industri1;
    const { dataJobLevel } = this.props.jobLevel1;
    const { dataJobFunction } = this.props.jobFunction1;
    const { dataProvince_, dataCity_  } = this.state;
    const { dataCity } = this.props.city1;
    const { dataProvince } = this.props.province1;
    const { dataCountry } = this.props.country1;
    const { dataCountryNew } = this.props.country2;
    const { onState } = this.props.onState1;
    const { onCity } = this.props.onCity1;
    const { certificationId } = this.props.certificationId1;
    const { educationId } = this.props.educationId1;
    const { listEdu } = this.props.list1;
    const { expectedId } = this.props.expectedId1;
    const { lastId } = this.props.lastId1;
    const { workPre } = this.props.worklist;
    const { workSta } = this.props.workstatus_list
    const { skill } = this.props.skill
    const { project } = this.props.project1
    workId.sort(sortFunction)
    // workId.sort((a, b) => Date.parse(a.work_period_start) - Date.parse(b.work_period_start));
    const edu_ = educationId.sort(sortFunctionDate)
    const cer_ = certificationId.sort(sortFunctionDate)
    const pro_ =project.sort(sortFunctionDate)
    const educationId_ = edu_.sort(sortByMonth)
    const certificationId_ =  cer_.sort(sortByMonth)
    const project__ = pro_.sort(sortByMonth)
    const fileTypes = ["JPG", "PNG", "JPEG"];
    let country_name = ""
    if (dataCountry.length > 0) {
      dataCountry.map((item) => {
        if (parseInt(item.id) === parseInt(profile.country_id)) {
          country_name = item.country_name
        }
      })
    }
    let city_name = ""
    if (onCity.length > 0) {
      onCity.map((item) => {
        if (parseInt(item.id) === parseInt(profile.city_id)) {
          city_name = item.city_name
        }
      })
    }
    let work_pre_name = ""
    if (workPre.length > 0) {
      workPre.map((item) => {
        if (parseInt(item.id) === parseInt(profile.work_preference_id)) {
          work_pre_name = item.work_preference_name
        }
      })
    }
    let work_status_name = ""
    if (workSta.length > 0) {
      workSta.map((item) => {
        if (parseInt(item.id) === parseInt(profile.work_status_id)) {
          work_status_name = item.work_status_name
        }
      })
    }
    let skillList = []
    if (!!profile.skill) {
      skillList = profile.skill.split(",")
    }


    const currentYear = (new Date()).getFullYear();
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }));
    const sort_startDate = (e) => e.sort((a, b) => new Date(b.work_period_start) - new Date(a.work_period_start))
    const sort_endDate = (e) => e.sort((a, b) => new Date(b.work_period_end) - new Date(a.work_period_end))
    let data_workId_=[]
    let data_workId2_=[]
    workId.map((item,i)=>{
      if (item.work_period_end===null && item.work_period_start!==null){
        data_workId_.push(item)
      }
      if ((item.work_period_end!==null && item.work_period_start!==null) || (item.work_period_end===null && item.work_period_start===null)){
        data_workId2_.push(item)
      }
    })
    const data_workId = sort_startDate(data_workId_)
    sort_endDate(data_workId2_).map(n=>data_workId.push(n))

    const da = educationId.sort((a, b) => parseInt(b.id) - parseInt(a.id))
    const d = da.sort((a, b) => new Date(b.work_period_end) - new Date(a.work_period_end))
    let data_educationId=[]
    d.map((item,i)=>{
      // if (i<3){
        data_educationId.push(item)
      // }
    })

    const ca = certificationId_.sort((a, b) => parseInt(b.month) - parseInt(a.month))
    const cap = ca.sort((a, b) => new Date(b.year) - new Date(a.year))
    let data_certificationId=[]
    cap.map((item,i)=>{
      // if (i<3){
        data_certificationId.push(item)
      // }
    })

    const p = project__.sort((a, b) => parseInt(b.month) - parseInt(a.month))
    const pa = p.sort((a, b) => new Date(b.year) - new Date(a.year))
    let data_project=[]
    pa.map((item,i)=>{
      // if (i<3){
        data_project.push(item)
      // }
    })

    const status = profile.length !== 0 && profile.photo !== undefined && profile.name !== undefined

    return (
      <div>
        <div className='flex width-100 mt-120 wd-'>
          <div className='flex column wd-content  auto'>
            <h1 className='c-36 s-42 leagueSpartan ml-30 mb-24 mb-m-0 mb-s-18'  >My Profile</h1>
            {/* <div className='wd-content flex mb-24 br-10 bg-linear-bc hg-140 p-2448'>
              <div className='flex width-100 space-between'>
                <p className='DM-Sans-Bold s-2026 c-29 align-self-c'>Let’s complete your profile</p>
                <div className='flex wrap '></div>
              </div>
            </div> */}
            <Box className="flexbox_gap20 mb-24 flex-mob">
              <Box item xs={6} className='p-1424 sm-m-2  flex-1 p-2 bg-ff br-10 shd-input'>
                <div className="flexbox_gap20">
                  <Box className="flexbox_gap20">
                    <Box item={3} className="mo-d-flex sm-m-2 wd-82px" >
                      <FileUploader handleChange={this.handleChange} name="file" types={fileTypes} >
                        <div className='flex column'>
                          {status ?
                        <img src={profile.photo !== null ? `${API}/File/Profile/${profile.photo}` : profile_static} className="flex wd-82 hg-82 cover br-100 " />
                        :<Skeleton variant="circular" width={80} height={80} />}
                        <img src={iconChange} className="flex absolute pointer change-profile wd-24 hg-24" />
                        </div>
                      </FileUploader>
                      {status ?
                      <p className='DM-Sans-Bold mb-14 c-29 s-26 mo-show'>{profile.name} </p>
                      :<Skeleton variant="text" sx={{ fontSize: '30px' }} />}
                    </Box>
                    <Box item={9} className="email-box">
                      <div className=' '>
                        {status?
                        <p className='DM-Sans-Bold mb-14 c-29 s-26 mo-hide'>{profile.name} </p>
                        :<Skeleton variant="text" sx={{ fontSize: '30px' }} />}
                        <div className='flexbox_gap20 flex-wrap m-flex-wrap mo-d-block'>
                          <div className='flex sm-m-2'>
                            {phone}
                            {status?
                            <p className='DM-Sans s-14 c-29 ml-14 mwd-100'>{(!!profile.phone_number) ? profile.phone_number : "-"} </p>
                            :<Skeleton variant="text" sx={{ fontSize: '20px', width:"100%" }} />}
                          </div>
                          {status ?
                          <div className='flex '>
                            {mail}
                            <p className='DM-Sans s-14 c-29 ml-14'>{profile.email} </p>
                          </div>:<Skeleton variant="text" sx={{ fontSize: '20px',width:"100%" }} />}
                        </div>
                      </div>
                    </Box>
                  </Box>

                </div>

              </Box>
              {data_doc.length > 0 ?
                <Box item xs={6} className="p-1424 flex-1 bg-ff br-10 green-br shd-input">
                  <div style={{ height: '100%' }} >
                    <div className='flexbox_gap20 flex m-auto-00' style={{ height: '100%' }}>
                      <div className='flex m-center space-between auto valign-center wd-webkit' >
                        <div className='flex-mob valign-center'>
                        {cv_green}
                        <div className="m-l-15 m-r-10">
                          <p className='DM-Sans s-20 c-black'>CV {"&"} Documents</p>
                          <p className='DM-Sans s-16 c-black'>Great! You have submitted your CV.</p>
                        </div>
                        </div>
                        <div className='bg-ff br-20 pointer' onClick={(e) => this.handlePopup("add_documents")}>
                          <p className='c-36 DM-Sans s-14 add_doc_btn'>Add documents</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box> : <Box item xs={6} className="p-1424 flex-1 bg-36 br-10 shd-input flex ">
                  <div className='flex width-100 hg-100' >
                    <div className='uploadcv flexbox_gap20 flex m-auto-00 width-100'>
                      <div className='flex-mob width-100 space-between auto'>
                        <div className='flex-mob valign-center space-between'>
                          {cv}
                          <p className='DM-Sans s-20 f-1rem c-ff ml-25'>Add your CV to join our Talent Network!</p>
                        </div>
                        <div className='bg-ff br-20 p-1014 pointer' onClick={(e) => this.handlePopup("upload_cv1")}>
                          <p className='c-36 DM-Sans s-14'>Upload CV</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              }

            </Box>

            <Box className="flexbox_gap20 equ_part ">
              <div className="flex-1 sm-m-2 mb-24 ">
                <Personal_Information profile={profile} country_name={country_name} onState={onState} cityName={ncity_name} stateName={nstate_name} expectedList={expectedId} work_pre_name={work_pre_name} work_status_name={work_status_name} 
                latestList={lastId} onCity={onCity} city_name={city_name} handlePopup={this.handlePopup} personal_info={personal_info}  dataCity={dataCity} dataProvince={dataProvince}/>
              </div>
              <div className='flex-1 mb-24 flex column'>
                <Salary_Benefit profile={profile} handlePopup={this.handlePopup} salary_={salary_} expectedId={expectedId} lastId={lastId} cityName={ncity_name} stateName={nstate_name} />
                <Skills profile={profile} handlePopup={this.handlePopup} skills_={skills_} skillList={skillList} />
              </div>

            </Box>
           
            <Work_Experience profile={profile} workId={data_workId} dataIndustri={dataIndustri} dataJobLevel={dataJobLevel} dataJobFunction={dataJobFunction} getWork={(e)=>this.getWork(profile.id)}
              handlePopup={this.handlePopup} changePopup={this.changePopup} add_work={add_work} updateJobDecId={this.updateJobDecId} work_experience={work_experience} />
            <Box className="flexbox_gap20 equ_part">
              <div className="flex-1 mb-24">
                <Education profile={profile} handlePopup={this.handlePopup} add_edu={add_edu} education_={education_} educationId={data_educationId} listEdu={listEdu} />
              </div>
              <div className='flex-1 mb-24'>
                <Certification profile={profile} handlePopup={this.handlePopup} add_certification={add_certification} certification_={certification_} certificationId={data_certificationId} />
              </div>
            </Box>
            <Box className="flexbox_gap20 equ_part ">
              <div className="flex-1 mb-24">
                <Project_And_Awards profile={profile} handlePopup={this.handlePopup} add_project={add_project} project_={project_} dataProject={data_project} />
              </div>

              <div className="flex-1 mb-24">
                <Industry_And_Job profile={profile} workId={data_workId} dataIndustri={dataIndustri} dataJobLevel={dataJobLevel} dataJobFunction={dataJobFunction}
                  handlePopup={this.handlePopup} interest_={interest_} />
              </div>
            </Box>
          </div>
        </div>
        <Popup profile={profile} handlePopup={this.handlePopup} add_work={add_work} add_edu={add_edu} add_certification={add_certification} add_project={add_project} add_documents={add_documents} upload_cv1={upload_cv1} upload_cv2={upload_cv2}
          job_desc={job_desc} personal_info={personal_info} salary_={salary_} skills_={skills_} work_experience={work_experience} education_={education_} certification_={certification_} project_={project_} month_year={month_year} dataProject={data_project}
          interest_={interest_} promoter_score={promoter_score} feedback={feedback} changePopup={this.changePopup} dataIndustri={dataIndustri} dataJobLevel={dataJobLevel} dataJobFunction={dataJobFunction} dataCountry={dataCountry}
          dataProvince={dataProvince} dataCity={dataCity} handleProvince={this.handleProvince} handleCity={this.handleCity} years={range(currentYear, currentYear - 100, -1)} list_month={list_month} certificationId={data_certificationId}
          educationId={data_educationId} job_description_id={job_description_id} listEdu={listEdu} formatDate={formatDate} updateJobDecId={this.updateJobDecId} workId={data_workId} onCity={onCity} expectedList={expectedId} latestList={lastId} projectList={project__} 
          workPre={workPre} workSta={workSta} skillList={skillList} skill_list={skill} document1list={data_doc} document2list={data_doc2} getDoc={(e)=>this.getDoc(profile.id)} getSalaryBenefit={(e)=>this.getSalaryBenefit(profile.id)} 
          getWork={(e)=>this.getWork(profile.id)} getEducation={(e)=>this.getEducation(profile.id)} getCertification={(e)=>this.getCertification(profile.id)} getCity={(e)=>this.getCity(profile.state_id,profile.country_id,profile.city_id)} setModal={this.setModal} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id_work: state.id_work,
    workId1: state.getWorkIdRed,
    work2: state.getWorkRed,
    industri1: state.industriRed,
    jobLevel1: state.jobLevelRed,
    jobFunction1: state.jobFunctionRed,
    onState1: state.onStateRed,
    onCity1: state.onCityRed,
    city1: state.cityRed,
    country1: state.countryRed,
    country2: state.countryNewRed,
    province1: state.provinceRed,
    certificationId1: state.getCertificationIdRed,
    educationId1: state.getEducationIdRed,
    list1: state.listEducationRed,
    expectedId1: state.getExpectedIdRed,
    lastId1: state.getLastIdRed,
    worklist: state.getWorkPreRed,
    workstatus_list: state.getWorkStaRed,
    skill: state.getSkillRed,
    project1: state.getProjectRed
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    getWorkId: (user_id) => {
      dispatch(getWorkId(user_id));
    },
    getExpId: (user_id) => {
      dispatch(getExpId(user_id));
    },
    getLastId: (user_id) => {
      dispatch(getLastId(user_id));
    },
    getWork: (id_work) => {
      dispatch(getWork(id_work));
    },
    getOnlyCity: () => {
      dispatch(getOnlyCity())
    },
    getIndustri: () => {
      dispatch(getIndustri())
    },
    getJobFunction: () => {
      dispatch(getJobFunction())
    },
    getJobLevel: () => {
      dispatch(getJobLevel())
    },
    getCity: (province_id) => {
      dispatch(getCity(province_id));
    },
    getOnState: (state_id) => {
      dispatch(getOnState(state_id));
    },
    getOnCity: (state_id, country_id, city_id) => {
      dispatch(getOnCity(state_id, country_id, city_id));
    },
    getProvince: (country_id) => {
      dispatch(getProvince(country_id));
    },
    getCountry: () => {
      dispatch(getCountry());
    },
    getOnCountry: (country_id) => {
      dispatch(getOnCountry(country_id));
    },
    getCertificationId: (user_id) => {
      dispatch(getCertificationId(user_id));
    },
    getEducationId: (user_id) => {
      dispatch(getEducationId(user_id));
    },
    getListEducation: () => {
      dispatch(getListEducation());
    },
    getWorkPre: () => {
      dispatch(getWorkPre())
    },
    getSkill: () => {
      dispatch(getSkill())
    },
    getWorkSta: () => {
      dispatch(getWorkSta())
    },
    getProject: (id) => {
      dispatch(getProject(id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(_MyProfile);