import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "videojs-plus";
import "videojs-plus/dist/videojs-plus.css";


export default function DlcPlayerVideo({
  children,
  playerOptions,
  onPlayerInit,
  onPlayerDispose,
  playBack
}) {
  const containerRef = useRef(null);
  const [speedC, setSpeedC] = useState(1)
  const [show_speed, setShowSpeed] = useState(false)
  const setPlayBack = () => {
    containerRef.current.querySelector("video").playbackRate = speedC;
  };
  const selectSpeed=(n)=>{
    setShowSpeed(false)
    setSpeedC(n)
    containerRef.current.querySelector("video").playbackRate=n
    }
 
 

  useEffect(() => {
    // var player = this;
    // if(window) window.videojs = videojs;
    // require('videojs-resolution-switcher')
    // require('videojs-playbackrate-adjuster')
    require('@silvermine/videojs-quality-selector')(videojs);
    require('@silvermine/videojs-quality-selector/dist/css/quality-selector.css')

    const defaultPlayerOptions = {
      autoplay: true,
      muted: false,
      controls: true,
      aspectRatio: "16:9",
      mobileView: false,
      playbackRates: true,
      preload:"auto",
      controlBar: {
        children: [
           'playToggle',
           'playBackRate',
           'progressControl',
           'volumePanel',
           'qualitySelector',
           'fullscreenToggle',
        ],
     },
    }

   

    if (containerRef.current) {
      const videoEl = containerRef.current.querySelector("video");
      const player = videojs(videoEl, {
        playbackRates: [0.5, 1, 1.5, 2],
        ...defaultPlayerOptions,
        ...playerOptions,
      }
      );
      
 

      // used to move children component into player's elment
      // your may not thest script
      const playerEl = player.el();
      const flag = player.getChild("PlayToggleLayer").el();
      for (const child of containerRef.current.children) {
        if (child !== playerEl) {
          playerEl.insertBefore(child, flag);
        }
      }
      // ----
      onPlayerInit && onPlayerInit(player);

      // for debug purpose
      window.player = player;
   


      return () => {
        onPlayerDispose && onPlayerDispose(null);
        // player.vhs.options_.externHls.GOAL_BUFFER_LENGTH = 60;
        // player.vhs.options_.externHls.MAX_GOAL_BUFFER_LENGTH = 90;
        // player.videoJsResolutionSwitcher()
        // player.dispose();
        // player.currentResolution('360'); 
      };
    }
  }, [onPlayerInit, onPlayerDispose, playerOptions]);
  

  return (
    <div className="player" ref={containerRef}>
      <video data-setup='{"playbackRates": [0.5, 1, 1.5, 2]}' className="video-js vjs-default-skin" 
      controls  onDurationChange={() => setPlayBack()} />
      {children}
      <button className="btn-speed-control-video" onClick={()=>setShowSpeed(!show_speed)} >
          <p>{speedC}</p>
      </button>
      <div className="flex column menu-speed-control" style={{ display:(show_speed==true)?"":"none" }}>
          <p onClick={()=>{selectSpeed(0.5)}} >0.5</p>
          <p onClick={()=>{selectSpeed(1)}}>1</p>
          <p onClick={()=>{selectSpeed(1.5)}}>1.5</p>
          <p onClick={()=>{selectSpeed(2)}}>2</p>
      </div>
    </div>
  );
}
