import React, { Component } from 'react';
import iOpenSidebar from "../images/Burger-icon.png";
import iDashboard from "../images/iDashboard.png";
import iClose from '../images/closemodal.png'
import { connect } from "react-redux";
import { API } from "../_redux/helper";
import {getProfileId} from "../_actions/user";
import iCareer from "../images/iCareer.png";
import iReferral from "../images/iReferral.png";
import iWork from "../images/iWork.png";
import iMyProfile from "../images/iMyprofile.png";
import iLearning from "../images/iLearning.png";
import iTraining from "../images/iTraining.png";
import help from "../images/help-icon.svg";
import newBell from "../images/bell.svg"
import setting from "../images/setting-icon.svg";
import logout from "../images/logout-icon.svg";
import logoMobile from "../images/logoPutih.png"
import iVIP from "../images/iVIP.svg";
import Session from "./Session"
import v from "../images/v.png";
import {  fadeIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
var $ = require("jquery");



const styles = {
  fadeInDown: {
    animation: "x 5s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
    marginTop: "-86px",
  },
};

class componentName extends Component {
    constructor() {
        super();
        this.state = {
          user_id: "",
          visible: false,
          dropdown:true,
          var_confirm: JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
          var_report: sessionStorage.getItem("report"),
          rating:"",
          ratting:0,
          comment:"",
          desc_ratting:"",
          rating_vip:"",
          rating_interest:"",
          redirectLink: "",
          popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
          popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
          popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
          popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
          popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
            };
      }
    
      componentDidMount() {
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        this.setState({ user_id: user_id });
        this.props.getProfileId(user_id);
        window.scrollTo(0, 0)
      }
    
      changePopup=()=>{
        if (JSON.parse(sessionStorage.getItem("rating-personality"))===true){
           this.setState({popup_personality:true})
        }else if (JSON.parse(sessionStorage.getItem("rating-interest"))===true){
          this.setState({popup_interest:true})
        }else if (JSON.parse(sessionStorage.getItem("rating-vip"))===true){
          this.setState({popup_vip:true})
        }else if (JSON.parse(sessionStorage.getItem("rating-values"))===true){
          this.setState({popup_values:true})
        }else if (JSON.parse(sessionStorage.getItem("rating-program"))===true){
          this.setState({popup_program:true})
        }else{
          this.setState({ visible: !this.state.visible });
          this.setState({ dropdown: true });
        }
          this.setState({ visible: !this.state.visible });
          this.setState({ dropdown: true });
      }

      changeVisible = () => {
        this.setState({ visible: !this.state.visible });
        this.setState({ dropdown: true });
      };

      openDropdown = () => {
        this.setState({ dropdown: !this.state.dropdown });
      };
      away = () => {
        this.setState({ visible: true });
      };
      logout=()=>{
        Session.removeSessionLogout()
      }

     // POPUP RATING START 
     changeRatting=(e, vals)=>{
      this.setState({ratting:Number((e.target.textContent).split('')[0])})
      let val
      if ((e.target.textContent).split('')[0]==1){
        val = "Very Bad"
      }else if ((e.target.textContent).split('')[0]==2){
        val = "Bad"
      }else if ((e.target.textContent).split('')[0]==3){
        val = "Need Improvement"
      }else if ((e.target.textContent).split('')[0]==4){
        val = "Good"
      }else{
        val = "Very Good"
      }
      this.setState({desc_ratting:val})
    }

    _onKeyDown=(e)=>{
      if (e.key === 'Enter') {
        if (JSON.parse(this.state.popup_program)== true){
          this.submitRatingProgram()
        }else if (JSON.parse(this.state.popup_vip)==true){
          this.submitRatingVIP()
        }else if (JSON.parse(this.state.popup_interest)===true){
          this.submitRatingInterest()
        }else if (JSON.parse(this.state.popup_personality)==true){
          this.submitRatingPersonality()
        }else if (JSON.parse(this.state.popup_values)==true){
          this.submitRatingValues()
        }
        else {
          console.log("enter = ", e.key);
        }
      }
    }

    changeComment=(e)=>{
      this.setState({comment:e.currentTarget.textContent})
    }

  // RATING INTEREST PROFILER 
  closePopupInterest() {
    const data={
      ratting_interest: "-",
      comment_interest: ""
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_interest:false})
        sessionStorage.setItem("rating-interest",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }



  submitRatingInterest=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting_interest: this.state.ratting,
      comment_interest: this.state.comment
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_interest:false})
        sessionStorage.setItem("rating-interest",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING VALUES PROFILER 
  closePopupValues() {
    const data={
      ratting: "-",
      comment: ""
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_values:false})
        sessionStorage.setItem("rating-values",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }

  submitRatingValues=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.ratting,
      comment: this.state.comment
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_values:false})
        sessionStorage.setItem("rating-values",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING PERSONALITY PROFILER 
  closePopupPersonality() {
    const data={
      ratting: "-",
      comment: ""
  }
      axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_personality:false})
        sessionStorage.setItem("rating-personality",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }


  submitRatingPersonality=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.ratting,
      comment: this.state.comment
  }
    axios.post(`${API}/api/survey/updateSubmissionRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_personality:false})
        sessionStorage.setItem("rating-personality",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING VIP REPORT 
  closePopupVIP() {
    const data={
      ratting_vip: "-",
      comment_vip: ""
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_vip:false})
        sessionStorage.setItem("rating-vip",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }


  submitRatingVIP=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting_vip: this.state.ratting,
      comment_vip: this.state.comment
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_vip:false})
        sessionStorage.setItem("rating-vip",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // RATING PROGRAM 
  closePopupProgram() {
    const data={
      ratting: "-",
      comment: ""
  }
  let id_program
  if (sessionStorage.getItem("i-program")==""){
    id_program=1
  }else{
    id_program = sessionStorage.getItem("i-program")
  }
    axios.post(`${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, data).then((res)=>{
        this.setState({popup_program:false})
        sessionStorage.setItem("rating-program",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })

    }

  submitRatingProgram=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.ratting,
      comment: this.state.comment
  }
  let id_program
  if (sessionStorage.getItem("i-program")==""){
    id_program=1
  }else{
    id_program = sessionStorage.getItem("i-program")
  }
    axios.post(`${API}/api/program/updateProgramRatting/${sessionStorage.getItem("i-dlp")}/${id_program}`, data).then((res)=>{
        this.setState({popup_program:false})
        sessionStorage.setItem("rating-program",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  // POPUP RATING END 



 


  render() {
    const { profile } = this.props.profile1;
    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }


    const nama = '' + nickname
    const name = nama.split(" ")[0]


    return (
      <div className="header-dashboard-mobile width-100" style={{top:"0px"}}>
          <div className="flex header-dashboard space-between header_shadow"  style={{display: this.state.visible ? "none":"flex"}}>
              <div style={{margin:"auto", marginLeft:"0px"}}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={iOpenSidebar} onClick={this.changePopup} />
              </div>
              <div>
                  <div className="flex space-between" style={{width:"95px"}}>
                    <div style={{margin:"auto"}}>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={newBell} className="bell" style={{display:"none"}}/>
                        <div className="notif" style={{display:"none"}}>
                        <p>90</p>
                    </div>
                  </div>
                  <div className="avatar">
                  {profile.photo &&
                    <LazyLoadImage effect="blur"
                      src={`${API}/File/Profile/${profile.photo}`} className="cover pointer br-100"
                      style={{
                        height: "32px",
                        width: "32px",
                      }} onClick={this.openDropdown}
                    />}
                  </div>
                 </div>
              </div>
              <div style={{margin:"auto 0px auto 14px"}}>
                      <p style={{textTransform:"capitalize", fontSize:"14px"}} onClick={this.openDropdown}>{name} </p>
              </div>
              <div style={{margin:"auto 0px auto 14px"}}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={v} onClick={this.openDropdown} style={{width:"12px", height:"6px"}}/>
              </div>
          </div>
          <StyleRoot>
          <div
              className="popup_settings"
              style={{ display: this.state.dropdown ? "none" : "flex" }}
            >
              <div className="mini_box">
                <div className="flex column">
                  <div className="flex pad_box"  onClick={this.openDropdown} style={{display:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} />
                    <p>Settings</p>
                  </div>
                  <div className="flex pad_box"  onClick={this.openDropdown} style={{display:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={help} />
                    <p>Help</p>
                  </div>
                  <div className="flex pad_box" onClick={this.logout}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logout} />
                    <p style={{ color: "red" }}>Logout</p>
                  </div>
                </div>
              </div>
            </div>
            </StyleRoot>
        <div className="burger-dashboard width-100" style={{transform: this.state.visible ? "scaleY(1)":"scaleY(0)", width: this.state.visible ? "100%":"0px", display: this.state.visible ? "block":"none"}}>
            <div className="menu-sidebar flex column width-100">
                <div className="flex space-between">
                <LazyLoadImage effect="blur" loading="lazy" alt="" className="logoMobile" src={logoMobile}  id="no-print" onClick={this.changePopup} />
                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iClose} className="closeMobile" onClick={this.changeVisible}/>
            </div>
            {/* <Link to="/NewDashboard"> */}
                <a href="/NewDashboard">
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'NewDashboard'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iDashboard} />
                        <p> Dashboard</p>
                    </div>
                </div>
                </a>
                {/* </Link> */}
                {/* <Link to="/MyProfile"> */}
                <a href="/MyProfile">
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'MyProfile'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iMyProfile} />
                        <p> My Profile</p>
                    </div>
                </div>
                </a>
                {/* </Link> */}
                {/* <Link to="/JobOpportunities"> */}
                <a href="/JobOpportunities">
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'JobOpportunities'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iCareer} />
                        <p>Job Opportunities</p>
                    </div>
                </div>
                </a>
                
                <a href="/Referral">
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'Referral'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iReferral} />
                        <p>Referral</p>
                    </div>
                </div>
                </a>
                {/* </Link> */}
                <a href="/work_assessment">
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'work_assessment'}>
                  <div className="flex width-100 space-between">
                    <div className="flex ">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={iVIP} className="bullet" />
                     <p> Assessments </p>
                    </div>
                    <div className="new-ntf" style={{ display: "flex", margin:"auto 0px auto 14px" }}> <span className="openSans-Bold" >New</span></div>
                    </div>
                </div>
                </a>
                <a href="/DigitalLearningPlatform" >
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'DigitalLearningPlatform'}>
                <div className="flex width-100 space-between">
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iLearning} />
                        <p>Digital Learning Platform</p>
                    </div>
                    <div className="new-ntf" style={{ display: "flex", margin:"auto 0px auto 14px" }}> <span className="openSans-Bold" >BETA</span></div>
                </div>
                </div>
                </a>
                <a href="/CareerCenter">
                <div className="item-dashboard" onClick={() => this.state.redirectLink = 'CareerCenter'}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iWork} />
                        <p>Career Center</p>
                    </div>
                </div>
                </a>
                
                <div className="item-dashboard" style={{display:"none"}}>
                    <div className="flex ">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={iTraining} />
                        <p>Training Marketplace</p>
                    </div>
                </div>
               
                <div className="width-100" style={{backgroundColor:"#376186", display:"none"}}>
                <div className="item-dashboard" style={{padding:"15px 0px"}}>
                    <div className="flex">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={help} style={{height:"22px",marginLeft:"7px", marginRight:"32px"}}/>
                        <p>Help</p>
                    </div>
                </div>
                <div className="item-dashboard" style={{padding:"15px 0px"}}>
                    <div className="flex">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={setting} style={{height:"22px",marginLeft:"7px", marginRight:"32px"}} />
                        <p>Setting</p>
                    </div>
                </div>
                <div className="item-dashboard"  style={{padding:"15px 0px"}} onClick={this.logout}>
                    <div className="flex">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logout} style={{height:"22px",marginLeft:"7px", marginRight:"32px"}}/>
                        <p>Logout</p>
                    </div>
                </div>
                </div>
                
            </div>
            {/* <Modal  id="course-outline11a" visible={this.state.popup_program} effect="fadeInUp" onClickAway={() => this.closePopupProgram()}>
            <div className="popup_dlc  flex column">
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={() => this.closePopupProgram()}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                 <h2 className="openSans">Rate this course</h2>
                 <Rating name="pristine" value={this.state.ratting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)} />
                 <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1) Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4) Good</p>
                   <p className="openSans">(5) Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this course</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >

                 </div>
                 <button onClick={this.submitRatingProgram} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
        <Modal  id="course-outline21a" visible={this.state.popup_vip} effect="fadeInUp" onClickAway={() => this.closePopupVIP()}>
            <div className="popup_dlc  flex column">
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={() => this.closePopupVIP()}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                 <h2 className="openSans">Rate this VIP Report</h2>
                 <Rating name="pristine" value={this.state.ratting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)} />
                 <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1) Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4) Good</p>
                   <p className="openSans">(5) Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this VIP Report</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >

                 </div>
                 <button onClick={this.submitRatingVIP} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
        <Modal  id="course-outline31a" visible={this.state.popup_interest} effect="fadeInUp" onClickAway={() => this.closePopupInterest()}>
            <div className="popup_dlc  flex column">
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={() => this.closePopupInterest()}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                 <h2 className="openSans">Rate this Interest Profiler</h2>
                 <Rating name="pristine" value={this.state.ratting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)} />
                 <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1) Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4) Good</p>
                   <p className="openSans">(5) Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this Interest Profiler</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >

                 </div>
                 <button onClick={this.submitRatingInterest} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
        <Modal  id="course-outline41a" visible={this.state.popup_personality} effect="fadeInUp" onClickAway={() => this.closePopupPersonality()}>
            <div className="popup_dlc cc flex column">
              <div className="box_popup_dlc" >
                <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={() => this.closePopupPersonality()}/>
                </div>
               <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                 <h2 className="openSans">Rate this Personality Profiler</h2>
                 <Rating name="pristine" value={this.state.ratting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)} />
                 <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                 <div className="flex width-100 space-between exp-dlc">
                   <p className="openSans">(1) Very Bad</p>
                   <p className="openSans">(2) Bad</p>
                   <p className="openSans">(3) Need Improvement</p>
                   <p className="openSans">(4) Good</p>
                   <p className="openSans">(5) Very Good</p>
                 </div>
                 <h2 className="openSans">Give us your thought about this Personality Profiler</h2>
                 <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >

                 </div>
                 <button onClick={this.submitRatingPersonality} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                   <p>Submit Rating</p>
                 </button>
               </div>
              </div>
          </div>
        </Modal>
        <Modal  id="course-outline51a"  className="val-bd" visible={this.state.popup_values} effect="fadeInUp" onClickAway={() => this.closePopupValues()}>
              <div className="popup_dlc  flex column">
              <div className="box_popup_dlc" >
                  <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={() => this.closePopupValues()}/>
                  </div>
              <div className="flex width-100 center column" onKeyDown={this._onKeyDown}>
                  <h2 className="openSans">Rate this Values Profiler</h2>
                  <Rating name="pristine" value={this.state.ratting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)} />
                  <h3 className="openSans flex">{this.state.desc_ratting} </h3>
                  <div className="flex width-100 space-between exp-dlc">
                  <p className="openSans">(1) Very Bad</p>
                  <p className="openSans">(2) Bad</p>
                  <p className="openSans">(3) Need Improvement</p>
                  <p className="openSans">(4) Good</p>
                  <p className="openSans">(5) Very Good</p>
                  </div>
                  <h2 className="openSans">Give us your thought about this Values Profiler</h2>
                  <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >

                  </div>
                  <button id="vl-51" onClick={this.submitRatingValues} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                  <p>Submit Rating</p>
                  </button>
              </div>
              </div>
          </div>
          </Modal> */}
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      profile1: state.profileId,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(componentName);