import React, { Component } from "react";
import Slider from "react-slick";
import Rating from "@material-ui/lab/Rating";
import iconJob from "../images/1.jpg";
import Loading from "../components/Loading";
import soon from "../images/soon.png";
import keterampilan from "../images/icons/icons/keterampilan.svg";
import pengetahuan from "../images/icons/icons/pengetahuan.svg";
import kemampuan from "../images/icons/icons/Kemampuan.svg";
import tech from "../images/tech.svg";
import ability from "../images/icons/icons/ability.svg";
import lamp from "../images/icons/icons/lamp.svg";
import close from "../images/icons/icons/close.svg";
import star from "../images/icons/icons/star-color.svg";
import educ from "../images/icons/icons/edu-color.svg";
import edux from "../images/icons/icons/edu-exp.svg";
import karakter from "../images/icons/icons/karakter-kerja.svg";
import character from "../images/icons/icons/character.svg";
import nilaiKerja from "../images/icons/icons/nilai-kerja.svg";
import value from "../images/icons/icons/value.svg";
import karya from "../images/logoBaru.png";
import ScrollToTop from "../components/ScrollToTop";
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowNext.svg";
import { connect } from "react-redux";
import Scrollbar from "react-scrollbars-custom";
import {getDetail,getTraining,getJobExclude,getKarakter,getKemampuan,getKeterampilan,getNilai,getPengetahuan,getDetailJob,} from "../_actions/jobs";
import { progressProfile } from "../_actions/user";
import Session from '../components/Session'
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactGA from "react-ga";
import { GAevent } from "../components/TrackingEvent";
import { API } from "../_redux/helper";
import ReactTooltip from "react-tooltip";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';
import nhp_vip from "../images/About.jpg";

var $ = require("jquery");
class DetailSummary extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      job_id: "",
      pengetahuan_id: "",
      karakter_kerja_id: "",
      keterampilan_id: "",
      kemampuan_id: "",
      nilai_kerja_id: "",
      knowledge_id:"",
      skill_id:"",
      ability_id:"",
      character_id:"",
      career_id:"",
    };
    // this._toggleDiv = this._toggleDiv.bind(this)
  }

  componentDidMount() {
    Session.checkToken()
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getTraining(user_id);
    this.props.getPengetahuan(this.props.pengetahuan_id);
    this.props.getKarakter(this.props.karakter_kerja_id);
    this.props.getKeterampilan(this.props.keterampilan_id);
    this.props.getKemampuan(this.props.kemampuan_id);
    this.props.getNilai(this.props.nilai_kerja_id);
    this.props.getDetail(this.props.job_id);
    this.props.getDetailJob(this.props.job_id);
    this.props.getJobExclude(this.props.job_id, user_id);
    this.props.progressProfile(user_id);
    var cek = this.props.getJobExclude(this.props.job_id, user_id);
    window.scrollTo(0, 0)
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.job1.data.length > 0) {
      if (this.props.job1.data[0] !== prevProps.job1.data[0]) {
        this.props.getPengetahuan(this.props.job1.data[0].pengetahuan_id);
      }
    }
    if (this.props.job1.data.length > 0) {
      if (this.props.job1.data[0] !== prevProps.job1.data[0]) {
        this.props.getKarakter(this.props.job1.data[0].karakter_kerja_id);
      }
    }
    if (this.props.job1.data.length > 0) {
      if (this.props.job1.data[0] !== prevProps.job1.data[0]) {
        this.props.getKeterampilan(this.props.job1.data[0].keterampilan_id);
      }
    }
    if (this.props.job1.data.length > 0) {
      if (this.props.job1.data[0] !== prevProps.job1.data[0]) {
        this.props.getKemampuan(this.props.job1.data[0].kemampuan_id);
      }
    }
    if (this.props.job1.data.length > 0) {
      if (this.props.job1.data[0] !== prevProps.job1.data[0]) {
        this.props.getNilai(this.props.job1.data[0].nilai_kerja_id);
      }
    }
  }

  tracker = () => {
    GAevent("Detail Job", "Join (Detail Job)");
  };

  refresh = (job_id) => {
    this.props.getTraining(this.state.user_id);
    this.props.getDetail(job_id);
    this.props.getPengetahuan(this.props.pengetahuan_id);
    this.props.getKarakter(this.props.karakter_kerja_id);
    this.props.getKeterampilan(this.props.keterampilan_id);
    this.props.getKemampuan(this.props.kemampuan_id);
    this.props.getNilai(this.props.nilai_kerja_id);
    this.props.getJobExclude(job_id, this.state.user_id);
    window.location.href = `/DetailSummary/${job_id}`;
    ReactGA.pageview(window.location.pathname + window.location.search);
  };


  Knowledge(e){
    this.setState({knowledge_id:e})
  }
  Skill(e){
    this.setState({skill_id:e})
  }
  Abilities(e){
    this.setState({ability_id:e})
  }
  Characteristic(e){
    this.setState({character_id:e})
  }
  Career(e){
    this.setState({career_id:e})
  }

  render() {
    const { data, isLoading } = this.props.job1;
    const { dataTraining } = this.props.training1;
    const { dataJobExclude } = this.props.jobExclude1;
    const { dataDetail } = this.props.detail1;
    const { dataPengetahuan } = this.props.pengetahuan1;
    const { dataKeterampilan, error  } = this.props.keterampilan1;
    const { dataKemampuan } = this.props.kemampuan1;
    const { dataKarakter } = this.props.karakter1;
    const { dataNilai } = this.props.nilai1;
    const { allProgress } = this.props.allProgress1;;
    const {knowledge_id, skill_id, ability_id, character_id, career_id} = this.state

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }



    const priceSplitter = (number) =>
      number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    let btnProgress;
    if (allProgress.progress < 100) {
      btnProgress = (
        <div className="notifSummary">
          <div className="flex detail-flex">
            <div>
              <p>Are you interested in joining our talent network?</p>
              <p>
                {" "}
                We will contact you when we have suitable opportunity for you
              </p>
            </div>
            <Link to="/MyProfile">
              <button onClick={this.tracker}>Join</button>
            </Link>
          </div>
        </div>
      );
    } else {
      btnProgress = "";
    }

    const name =
      this.props.job1.data !== null ? this.props.job1.data.name : "Undefined";
    const settings = {
      //   dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
      prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // {
        //   breakpoint: 1008,
        //   settings: {
        //     slidesToShow: 2,
        //     slidesToScroll: 1
        //   }
        // },
      ],
    };

    const settingsmobile = {
      infinite: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // arrows: false,
      nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
      prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
    };
    const settingsmobileJob = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      nextArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowRight} />,
      prevArrow: <LazyLoadImage effect="blur" loading="lazy" alt="" src={arrowLeft} />,
    };


    return (
      <div>
        {btnProgress}
        <div className="box-wrapper orange">
          <div className="wrapper detailSummary">
            <div className="content">
              <div className="backgroundBlue"></div>
              <div className="page-container width-100">
                <div className="page-container-outer job-summary">
                  <div className="box-logo width-100">
                    <div className="flex center width-100">
                      {/* <Link to="/"> */}
                      <div className="box-logo-img">
                        <LazyLoadImage effect="blur" loading="lazy"  src={karya} alt="" />
                      </div>
                      {/* </Link> */}
                    </div>
                  </div>
                  <div className="page-container-inner width-100">

                    <div className="box-edu-container width-100">
                      {data.map((item, i) => (
                        <div key={i} className="box-job-desc-container width-100">
                          <div className="job-desc-image">
                            <LazyLoadImage effect="blur"
                              src={`${API}/File/Job/${item.photo}`}
                              className=" img-center"
                              alt=""  onError={(e)=>{e.target.src = nhp_vip}}
                            />
                          </div>
                          <div className="job-desc-content">
                            <div className="job-desc-content-title width-100">
                              <h3>{item.preferred_job_title} </h3>
                            </div>
                
                            <div className="box-job-desc-detail width-100">
                              <h5>Key Responsibilities</h5>
                              <ul type="circle" className="leftCircle">
                                {dataDetail.map((item, i) => {
                                    return (
                                      <div key={i}>
                                        <li className="li-detail">
                                          {item.job_task}{" "}
                                        </li>
                                      </div>
                                    );
                                })}
                               
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}

                      
                      {data.map((item, i) => (
                        <div key={i} className="box-criteria-container width-100">
                          <div className="criteria-container-inner width-100">
                            <div className="box-list-criteria">
                              <div className="list-criteria-inner width-100">
                                <div className="criteria-list-option width-100">
                                  <div className="list-criteria-title width-100">
                                    <div className="criteria-title-img">
                                      <LazyLoadImage effect="blur" loading="lazy"  src={pengetahuan} alt="" />
                                    </div>
                                    <h3>Knowledge</h3>
                                  </div>
                                  <div className="list-criteria-description width-100">
                                  {dataPengetahuan.map((item, i) => {
                                      return (
                                          <div className="list-criteria-point width-100" id="pengetahuan-menu-1">
                                              <div key={i}>
                                                <div className="flex" onClick={()=>this.Knowledge(i)}>
                                                  <div className="list-criteria-point-img">
                                                    <LazyLoadImage effect="blur" loading="lazy" src={lamp} alt="" />
                                                  </div>
                                                  <h6>
                                                    {item.pengetahuan.split(",")}{" "}
                                                  </h6>
                                                </div>
                                              </div>
                                          </div>
                                     );})}
                                  </div>
                                </div>
                                <div className="criteria-description-show width-100"
                                  id="pengetahuan-view-1" style={{visibility: knowledge_id!==""?"visible":"", opacity:knowledge_id!==""?"1":""}}>
                                    {dataPengetahuan.map((item, i) => {
                                        if (i === Number(knowledge_id)) {
                                          return (
                                            <div className="criteria-description-show-inner" key={i}>
                                              <div className="list-criteria-title width-100">
                                                <div className="criteria-title-img">
                                                  <LazyLoadImage effect="blur" loading="lazy" src={lamp} alt="" />
                                                </div>
                                                  <div >
                                                    <h3>
                                                      {item.pengetahuan.split(",")}{" "}
                                                    </h3>
                                                    <div className="close-menu close-pengetahuan-1">
                                                      <LazyLoadImage effect="blur" loading="lazy" src={close} alt=""  onClick={()=>this.Knowledge("")}/>
                                                    </div>
                                                </div>
                                                <div className="list-criteria-description width-100">

                                                    <p>
                                                      {item.keterangan.split(",")}{" "}
                                                    </p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                </div>
                              </div>
                            </div>




                            <div className="box-list-criteria criteria-skill">
                              <div className="list-criteria-inner width-100">
                                <div className="criteria-list-option width-100">
                                  <div className="list-criteria-title width-100">
                                    <div className="criteria-title-img">
                                      <LazyLoadImage effect="blur" loading="lazy" src={keterampilan} alt="" />
                                    </div>
                                    <h3>Skills</h3>
                                  </div>
                                  <div className="list-criteria-description  width-100">
                                    <Scrollbar style={{ height: "100%" }}>
                                      <div className="criteria-skill-inner width-100">
                                        <div className="general-skill">
                                        {dataKeterampilan.map((item, i) => {
                                          return (
                                          <div className="list-criteria-point width-100" id="keterampilan-menu-1">
                                            <div key={i}>
                                              <div className="flex" onClick={()=>this.Skill(i)}>
                                                <div className="list-criteria-point-img">
                                                  <LazyLoadImage effect="blur" src={star} alt=""/>
                                                </div>
                                                <h6>{item.keterampilan.split(",")}{" "}</h6>
                                              </div>
                                            </div>
                                          </div>
                                            );})}

                                        </div>
                                        <div className="spesifik-skill">
                                          {item.technology.split(",").map((it)=>(
                                             <div className="list-criteria-point width-100" id="keterampilan-menu-1">
                                             <div className="list-criteria-point-img">
                                               {it && <LazyLoadImage effect="blur" loading="lazy" src={tech} alt="" />}
                                             </div>
                                             <h6>{it}</h6>
                                           </div>
                                            ))}        
                                        </div>
                                      </div>
                                    </Scrollbar>
                                  </div>
                                </div>
                               
                                <div className="criteria-description-show width-100" id="keterampilan-view-1" 
                                style={{visibility: skill_id!==""?"visible":"", opacity:skill_id!==""?"1":""}}>
                                   {dataKeterampilan.map((item, i) => {
                                if (i === skill_id) {
                                  return (
                                  <div className="criteria-description-show-inner">
                                    <div className="list-criteria-title width-100">
                                      <div className="criteria-title-img">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={lamp} alt="" />
                                      </div>
                                          <h3>{item.keterampilan.split(",")}{" "}</h3>
                                      <div className="close-menu close-keterampilan-1">
                                        <LazyLoadImage effect="blur" loading="lazy" src={close} alt="" onClick={()=>this.Skill("")}/>
                                      </div>
                                    </div>
                                    <div className="list-criteria-description width-100">
                                      <Scrollbar style={{ height: "100%" }}>
                                      <p> {item.keterangan.split(",")}{" "} </p>
                                      </Scrollbar>
                                    </div>
                                  </div>
                                   );
                                  }
                                })}
                                </div>
                              </div>
                            </div>

                            <div className="box-list-criteria">
                              <div className="list-criteria-inner width-100">
                                <div className="criteria-list-option width-100">
                                  <div className="list-criteria-title width-100">
                                    <div className="criteria-title-img">
                                      <LazyLoadImage effect="blur" loading="lazy" src={kemampuan} alt="" />
                                    </div>
                                    <h3>Abilities</h3>
                                  </div>
                                  <div className="list-criteria-description width-100">
                                  {dataKemampuan.map((item, i) => {
                                      return (
                                    <div className="list-criteria-point width-100" id="kemampuan-menu-1">
                                        <div key={i}>
                                          <div className="flex" onClick={()=>this.Abilities(i)}>
                                            <div className="list-criteria-point-img">
                                              <LazyLoadImage effect="blur" loading="lazy" src={ability} alt="" />
                                            </div>
                                            <h6>
                                              {item.kemampuan.split(",")}{" "}
                                            </h6>
                                          </div>
                                        </div>
                                    </div>
                                     ); 
                                  })}
                                  </div>
                                </div>


                                <div className="criteria-description-show width-100" id="kemampuan-view-1" 
                                style={{visibility: ability_id!==""?"visible":"", opacity:ability_id!==""?"1":""}}>
                                {dataKemampuan.map((item, i) => {
                                  if (i === ability_id) {
                                    return (
                                  <div className="criteria-description-show-inner" key={i}>
                                    <div className="list-criteria-title width-100">
                                      <div className="criteria-title-img">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={ability} alt="" />
                                      </div>
                                          <h3>{item.kemampuan.split(",")}{" "}</h3>
                                      <div className="close-menu close-kemampuan-1">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={close} alt="" onClick={()=>this.Abilities("")} />
                                      </div>
                                    </div>
                                    <div className="list-criteria-description width-100">
                                        <p> {item.keterangan.split(",")}{" "}</p>
                                    </div>
                                  </div>
                                    );
                                  }
                                })}
                                </div>
                              </div>
                            </div>

                            <div className="box-list-criteria edu-exp">
                              <div className="list-criteria-inner width-100">
                                <div className="criteria-list-option width-100">
                                  <div className="list-criteria-title width-100">
                                    <div className="criteria-title-img">
                                      <LazyLoadImage effect="blur" loading="lazy"  src={edux} alt="" />
                                    </div>
                                    <h3>Education & Work Experience</h3>
                                  </div>
                                  <div className="list-criteria-description  width-100">
                                    <div
                                      className="list-criteria-point width-100"
                                      id="pengetahuan-menu-1"
                                    >
                                      <div className="list-criteria-point-img">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={educ} alt="" />
                                      </div>
                                      <h6>
                                        {item.education_level.split(",")[0]}{" "}
                                        <br />{" "}
                                        {item.education_level.split(",")[1]}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box-list-criteria">
                              <div className="list-criteria-inner width-100">
                                <div className="criteria-list-option width-100">
                                  <div className="list-criteria-title width-100">
                                    <div className="criteria-title-img">
                                      <LazyLoadImage effect="blur" loading="lazy"  src={karakter} alt="" />
                                    </div>
                                    <h3>Work Characteristics</h3>
                                  </div>
                                  <div className="list-criteria-description width-100">
                                  {dataKarakter.map((item, i) => {
                                      return (
                                      <div className="list-criteria-point width-100" id="karakter-menu-1" key={i}>
                                        <div >
                                          <div className="flex" onClick={()=>this.Characteristic(i)} >
                                            <div className="list-criteria-point-img">
                                              <LazyLoadImage effect="blur" loading="lazy"  src={character} alt="" />
                                            </div>
                                            <h6>{item.karakter_kerja.split( "," )}{" "}</h6>
                                          </div>
                                        </div>
                                    </div>
                                     );})}
                                  </div>
                                </div>

                               
                                <div className="criteria-description-show width-100" id="karakter-view-1"
                                style={{visibility: character_id!==""?"visible":"", opacity:character_id!==""?"1":""}}>
                                {dataKarakter.map((item, i) => {
                                  if (i === character_id) {
                                    return (
                                  <div className="criteria-description-show-inner">
                                    <div className="list-criteria-title width-100">
                                      <div className="criteria-title-img">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={character} alt="" />
                                      </div>
                                          <h3>{item.karakter_kerja.split(",")}{" "}</h3>
                                      <div className="close-menu close-karakter-1"id="close-karakter-1">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={close} alt="" onClick={()=>this.Characteristic("")} />
                                      </div>
                                    </div>
                                    <div className="list-criteria-description width-100">
                                        <p>{item.keterangan.split(",")}{" "}</p>
                                    </div>
                                  </div>
                                  );}})}
                                </div>
                              </div>
                            </div>

                            <div className="box-list-criteria">
                              <div className="list-criteria-inner width-100">
                                <div className="criteria-list-option width-100">
                                  <div className="list-criteria-title width-100">
                                    <div className="criteria-title-img">
                                      <LazyLoadImage effect="blur" loading="lazy"  src={nilaiKerja} alt="" />
                                    </div>
                                    <h3>Career Center</h3>
                                  </div>
                                  <div className="list-criteria-description width-100">

                                  {dataNilai.map((item, i) => {
                                      return (
                                      <div className="list-criteria-point width-100" id="nilaiKerjar-menu-1" key={i}>
                                          <div >
                                            <div className="flex" onClick={()=>this.Career(i)} >
                                              <div className="list-criteria-point-img">
                                                <LazyLoadImage effect="blur" loading="lazy"  src={value} alt="" />
                                              </div>
                                              <h6>
                                                {item.nilai_kerja.split(",")}{" "}
                                              </h6>
                                            </div>
                                          </div>
                                      </div>
                                     );
                                  })}
                                  </div>
                                </div>


                                <div className="criteria-description-show width-100" id="nilaiKerjar-view-1"
                                style={{visibility: career_id!==""?"visible":"", opacity:career_id!==""?"1":""}}>
                                {dataNilai.map((item, i) => {
                                  if (i === career_id) {
                                    return (
                                  <div className="criteria-description-show-inner" key={i}>
                                    <div className="list-criteria-title width-100">
                                      <div className="criteria-title-img">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={value} alt="" />
                                      </div>
                                          <h3>{item.nilai_kerja.split(",")}{" "}</h3>
                                      <div className="close-menu close-nilai-kerja-1">
                                        <LazyLoadImage effect="blur" loading="lazy"  src={close} alt="" onClick={()=>this.Career("")} />
                                      </div>
                                    </div>
                                    <div className="list-criteria-description width-100">
                                       <p>{item.keterangan.split(",")}{" "}</p>
                                    </div>
                                  </div>
                                   );
                                  }
                                })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="box-training-recomended-container width-100">
                        <div className="training-recomended-container-inner width-100">
                          <h3>Training Recommendation</h3>
                          <div className="box-training-recomended width-100">
                            <div className="boxComing">
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={soon} />
                              {/* <h5 className="titleComing">Coming Soon</h5> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box-other-recomended-container width-100">
                        <div className="other-recomended-container-inner width-100">
                          <h3>Other Job Recommendation</h3>
                          <div className="box-recomended-list-work width-100">
                            <div className="recomended-list-work-inner width-100">
                              {dataJobExclude.map((item, i) => {
                                return (
                                  <div key={i} className="box-list-work">
                                    <div className="box-list-work-inner width-100">
                                      <div className="list-work-image width-100">
                                        {/* <a href={item.link_photo}  > */}
                                        <LazyLoadImage effect="blur" src={`${API}/File/Job/${item.photo}`} className=" img-center"
                                          alt=""
                                          title={item.link_photo} data-for="svgTooltip2" cx="10" cy="25" r="22"
                                          fill="#fff" stroke="#000" strokeWidth="4"
                                        />
                                        {/* </a> */}
                                        <ReactTooltip
                                          id="svgTooltip2"
                                          place="bottom"
                                          type="light"
                                        />
                                        <div className="box-rating width-100">
                                        </div>
                                      </div>
                                      <div className="list-work-description width-100">
                                        <h5>{item.original_job_title}</h5>
                                        <div className="list-work-trend width-100">
                                          <Rating value={item.star} readOnly />
                                        </div>
                                        <div className="box-link width-100">
                                          <Link
                                            onClick={() => {
                                              this.refresh(item.job_id);
                                            }}
                                          >
                                            <a
                                              href={`/DetailSummary/${item.job_id}`}
                                               
                                            >
                                              See job detail
                                            </a>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="job-mobile">
                              <div className="recomended-list-work-inner width-100">
                                <Slider {...settingsmobileJob}>
                                  {dataJobExclude.map((item, i) => {
                                    return (
                                      <div key={i} className="box-list-work">
                                        <div className="box-list-work-inner width-100">
                                          <div className="list-work-image width-100">
                                            {/* <a href={item.link_photo}  > */}
                                            <LazyLoadImage effect="blur"
                                              src={`${API}/File/Job/${item.photo}`}
                                              className=" img-center"
                                              alt=""
                                              title={item.link_photo}
                                              data-for="svgTooltip2"
                                              cx="10"
                                              cy="25"
                                              r="22"
                                              fill="#fff"
                                              stroke="#000"
                                              strokeWidth="4"
                                            />
                                            <ReactTooltip
                                              id="svgTooltip2"
                                              place="bottom"
                                              type="light"
                                            />
                                            {/* </a> */}
                                    
                                            <div className="box-rating width-100">
                                             
                                            </div>
                                          </div>
                                          <div className="list-work-description width-100">
                                            <h5>{item.original_job_title}</h5>
                                            <div className="list-work-trend width-100">
                                              <Rating
                                                value={item.star}
                                                readOnly
                                              />
                                            </div>
                                            <div className="box-link width-100">
                                              <Link
                                                onClick={() => {
                                                  this.refresh(item.job_id);
                                                }}
                                              >
                                                <a
                                                  href={`/DetailSummary/${item.job_id}`}
                                                   
                                                >
                                                  See job detail
                                                </a>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex center bottom100 width-100">
                <Link to="/NewDashboard">
                  <button className="btn-myinformation">
                    Back to Dashboard
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
        {/* <Footer /> */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    job_id: ownProps.match.params.job_id,
    pengetahuan_id: ownProps.match.params.pengetahuan_id,
    karakter_kerja_id: ownProps.match.params.karakter_kerja_id,
    keterampilan_id: ownProps.match.params.keterampilan_id,
    kemampuan_id: ownProps.match.params.kemampuan_id,
    karakter1: state.karakter,
    nilai1: state.nilai,
    detail1: state.detailJob,
    keterampilan1: state.keterampilan,
    job1: state.jobDetail,
    kemampuan1: state.kemampuan,
    training1: state.training,
    jobExclude1: state.jobExclude,
    pengetahuan1: state.pengetahuan,
    allProgress1: state.progressProfileRed,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetail: (job_id) => {
      dispatch(getDetail(job_id));
    },
    getTraining: (user_id) => {
      dispatch(getTraining(user_id));
    },
    getJobExclude: (job_id, user_id) => {
      dispatch(getJobExclude(job_id, user_id));
    },
    getPengetahuan: (pengetahuan_id) => {
      dispatch(getPengetahuan(pengetahuan_id));
    },
    getKarakter: (karakter_kerja_id) => {
      dispatch(getKarakter(karakter_kerja_id));
    },
    getKeterampilan: (keterampilan_id) => {
      dispatch(getKeterampilan(keterampilan_id));
    },
    getKemampuan: (kemampuan_id) => {
      dispatch(getKemampuan(kemampuan_id));
    },
    getNilai: (nilai_kerja_id) => {
      dispatch(getNilai(nilai_kerja_id));
    },
    getDetailJob: (job_id) => {
      dispatch(getDetailJob(job_id));
    },
    progressProfile: (user_id) => {
      dispatch(progressProfile(user_id));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailSummary)
);
