import React, { Component } from "react";
import karya from "../images/logoBaru.png";
import rec from "../images/rec_New.svg"
import shadow_JobFunction from "../images/shadow_JobFunction.svg"
import { connect } from "react-redux";
import Session from '../components/Session'
import { putProfileId} from "../_actions/user";
import {getIndustri} from "../_actions/work";
import ReactGA from 'react-ga';
import {GAevent} from '../components/TrackingEvent'
import bgMobile from "../images/mobile_newJourney.png"
import shadow_JobFunction_mobile from "../images/shadow_mobile_journey.png"
import Loading from "../components/Loading";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


class GetStarted extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      industry_id :[],
    }
    };


  goBack() {
    this.props.history.goBack();
  }
  componentDidMount(){
    Session.checkToken()
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    this.setState({ token: token1 });
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getIndustri();
    sessionStorage.setItem('setupTime', new Date().getTime())
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0)
  }

  changeIndustry = (event, values) => {
    let industry_id
        if (event===undefined || event==="" || event===null || event === this.state.industry_id){
          industry_id=""
        }else  if ( event === this.state.industry_id && this.state.industry_id.length){
          industry_id=""
        }else{
          industry_id = event
        }
        const cek1 =  [...this.state.industry_id]
        const index = cek1.indexOf(industry_id);
        if (this.state.industry_id.length <5){
          if (index===-1){
              cek1.push(industry_id)
          }else{
              cek1.splice(index,1)
          }
          this.setState({industry_id: cek1})
          const industry = {
            user_id : this.state.user_id,
            industry_id: cek1
          }
          this.props.Industry11(industry)
          GAevent('Industry','Put Industry') 
        }else if ((event === this.state.industry_id[0])|| (event === this.state.industry_id[1])||(event === this.state.industry_id[2])
        ||(event === this.state.industry_id[3])||(event === this.state.industry_id[4])){
       cek1.splice(index,1)
       this.setState({industry_id: cek1})
       const industry = {
        user_id : this.state.user_id,
        industry_id: cek1
      }
      this.props.Industry11(industry)
      GAevent('Industry','Put Industry') 
      ReactPixel.track('Post Industry - /Industry','Post Industry') 
     }    
  };

  render() {
    const {dataIndustri, isLoading, error} = this.props.industri1;
    let btnContinue 
    if (this.state.industry_id.length===0 || this.state.industry_id===""){
      btnContinue = <button style={{marginBottom:"24px", backgroundColor:"#BDBDBD", borderColor:"#BDBDBD"}}>
                      <p>Continue</p>
                    </button>
    }else{
      btnContinue =  
      // <Link to="/JobLevel">
                        <a href="/JobLevel">
                        <button className="bg-36 pointer br-5" style={{marginBottom:"24px"}}>
                          <p>Continue</p>
                        </button>
                        </a>
                    {/* </Link> */}
    }


     if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }
    
    return (
      <div>
        <div className="edu welcome">
        <div className="box-wrapper jobFunction">
          <div className="wrapper industry" >
            <div className="content">
              <div className="page-container assessment-result width-100">
              <div className="width-100 dekstop">
                  <div className="background-top width-100" style={{background:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={rec} className="width-100" />
                  </div>
                </div>
                <div className="width-100 mobile">
                  <div className="background-top width-100" style={{background:"none"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bgMobile} className="width-100" />
                  </div>
                </div>
                <div className="page-container-outer list-industry">
                  <div className="box-logo width-100 dekstop" style={{marginTop:"-218px"}}>
                    {/* <Link to="/"> */}
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya} />
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="mobile-information width-100">
                  <div className="box-logo width-100">
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya} />
                    </div>
                  </div>
                  </div>
                  <div className="page-container-inner width-100" style={{marginTop:"-143px"}}>
                    <div className="box-content flex column center width-100">
                    <div className="width-100 dekstop">
                        <LazyLoadImage effect="blur" loading="lazy" alt=""  src={shadow_JobFunction}  className="bg_func width-100"/>
                     </div>
                     <div className="width-100 mobile">
                        <LazyLoadImage effect="blur" loading="lazy" alt=""  src={shadow_JobFunction_mobile}  className="bg_func width-100"/>
                     </div>
                     <div className="content-shadow width-100">
                       <p className="first_p">INDUSTRY</p>
                       <h1>Which Industry are you interested in the most?</h1>
                       <p className="second_p">You can choose a maximum of 5 industries</p>
                     </div>
                     <div className="buttons_jobFunction width-100" style={{marginTop:"24px"}}>
                       {dataIndustri.map((item,i)=>(
                       <button key={i} className="job_item" onClick={(e)=>{this.changeIndustry(item.id)}}  
                       style={{
                         color: (((this.state.industry_id[0]  === item.id )||(this.state.industry_id[1]  === item.id ) ||(this.state.industry_id[2]  === item.id )||(this.state.industry_id[3]  === item.id )||(this.state.industry_id[4]  === item.id ))? "#3B6C98":"" ||
                         this.state.industry_id.length>=5 &&((this.state.industry_id[0]  !== item.id )&&(this.state.industry_id[1]  !== item.id ) &&(this.state.industry_id[2]  !== item.id )&&(this.state.industry_id[3]  !== item.id )&&(this.state.industry_id[4]  !== item.id ))? "#BDBDBD":"" ),
                          borderColor: (((this.state.industry_id[0]  === item.id )||(this.state.industry_id[1]  === item.id ) ||(this.state.industry_id[2]  === item.id )||(this.state.industry_id[3]  === item.id )||(this.state.industry_id[4]  === item.id ))? "#3B6C98":"" || this.state.industry_id.length>=5 &&((this.state.industry_id[0]  !== item.id )&&(this.state.industry_id[1]  !== item.id ) &&(this.state.industry_id[2]  !== item.id )&&(this.state.industry_id[3]  !== item.id )&&(this.state.industry_id[4]  !== item.id ))? "white":"" ),
                          backgroundColor: (((this.state.industry_id[0]  === item.id )||(this.state.industry_id[1]  === item.id ) ||(this.state.industry_id[2]  === item.id )||(this.state.industry_id[3]  === item.id )||(this.state.industry_id[4]  === item.id ))? "#ECF1F5":"" || this.state.industry_id.length>=5 &&((this.state.industry_id[0]  !== item.id )&&(this.state.industry_id[1]  !== item.id ) &&(this.state.industry_id[2]  !== item.id )&&(this.state.industry_id[3]  !== item.id )&&(this.state.industry_id[4]  !== item.id ))? "white":"" )}}>
                         <p>{item.industry_name} </p>
                       </button>
                       ))}
                       
                     </div>
                     <div className="width-100 btn_Continue">
                       {btnContinue}
                     </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    industri1 : state.industriRed,
    industry: state.putProfileIdRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIndustri: () =>{
      dispatch(getIndustri())
    },
    Industry11: (user_id) =>{
      dispatch(putProfileId(user_id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);

