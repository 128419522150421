import React, { Component } from 'react';
import table_ref from "../images/table_ref.png"
import bullet_ref from "../images/bullet_ref.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


export const referral_agreement =  
<div className="flex column width-100">
<h5 style={{marginTop:"24px"}}>REFERRAL AGREEMENT</h5>
<div className="box-content-ref">
<p className="flex">This Referral Agreement (the <p className="bold">“Agreement”</p>) is between PT Bina Sumberdaya Indonesia, with an address of AXA Tower 37 th Floor, Jalan Prof. Dr. Satrio, Kav 18, Kuningan City, Jakarta Selatan, Indonesia (the <p className="bold">“Company”</p>) and Referrer (the <p className="bold">“Affiliate”</p>), collectively “the Parties.”</p>
<p>This agreement is valid when Affiliate agrees with the Agreement.</p>
<div className="box-list-ref">
  <div className="flex item-ref">
    <p className="number">1.</p>
    <p className="flex text-ref"><p className="bold">Purpose.</p> Company is in the business of talent development and job placement. Company is desirous of     gaining additional candidates for the company’s job placement service. Affiliate is in a position to refer potential candidates to the Company.</p>
  </div>
  <div className="flex item-ref">
    <p className="number">2.</p>
    <p className="flex text-ref" ><p className="bold">Referral Arrangement.</p> Upon the Effective Date of this Agreement, Affiliate may, from time to time, refer potential candidates to Company. Company will pay Affiliate a fee for these referrals.</p>
  </div>
  <div className="flex item-ref">
    <p className="number">3.</p>
    <p className="flex text-ref" ><p className="bold">Compensation. </p> Company shall pay Affiliate approximately 20% of the success fee (see Appendix 1: Success Fee) for each successful referral, where a successful referral is defined as a referral that the company has successfully placed out the candidate to the corporate client, the candidate has successfully entered into the
    employment of the company after passing the probation and the Company’s
    placement guarantee period and the Company has received payment for its
    service. Company will also receive potential candidates recommended by the
    Affiliate to the Company’s candidate’s data bank which is refer to as Karya
    Network and upon the successful placement of each potential candidate, the
    Company shall pay the Affiliate success fee as stated above. Company shall pay Affiliate within thirty (30) days of a successful referral. The fee mentioned is exclusive of Value-Added Tax (PPN).
    </p>
  </div>
  <div className="flex item-ref">
    <p className="number">4.</p>
    <p className="flex text-ref" ><p className="bold">Term.  </p> This Agreement shall commence upon the Effective Date, as stated above, and will continue until termination by either party.</p>
  </div>
  <div className="flex item-ref">
    <p className="number">5.</p>
    <p className="flex text-ref" ><p className="bold">Confidentiality.</p> During the course of this Agreement, it may be necessary for Company to share proprietary information, including trade secrets, industry knowledge, and other confidential information, to Affiliate in order for Affiliate to seek out potential referrals. Affiliate will not share any of this proprietary information at any time. Affiliate also will not use any of this proprietary information for his/her personal benefit at any time. This section remains in full force and effect even after termination of the Agreement by its natural termination or the early termination by either party.</p>
  </div>
  <div className="flex item-ref">
    <p className="number">6.</p>
    <p className="flex text-ref" ><p className="bold">Termination.  </p> This Agreement may be terminated at any time by either Party upon 7 days written notice to the other party. Upon termination, Company shall
    pay Affiliate within 30 days all compensation due and owing for completed
    referrals made prior to the date of termination, but not yet paid.
    </p>
  </div>
  <div className="flex item-ref">
    <p className="number">7.</p>
    <p className="flex text-ref" ><p className="bold">Representations and Warranties.  </p> Both Parties represent that they are fully authorized to enter into this Agreement. The performance and obligations of either Party will not violate or infringe upon the rights of any third-party or violate
    any other agreement between the Parties, individually, and any other person,
    organization, or business or any law or governmental regulation. The Affiliate
    acknowledges the nature of the relationship is one of an independent contractor
    and the Affiliate is not an agent or representative of the Company.
    </p>
  </div>
  <div className="flex item-ref">
    <p className="number">8.</p>
    <p className="flex text-ref" ><p className="bold">Indemnity. </p> The Parties each agree to indemnify and hold harmless the other Party, its respective affiliates, officers, agents, employees, and permitted successors and assigns against any and all claims, losses, damages, liabilities,
    penalties, punitive damages, expenses, reasonable legal fees and costs of any
    kind or amount whatsoever, which result from the negligence of or breach of this
    Agreement by the indemnifying party, its respective successors and assigns that
    occurs in connection with this Agreement. This section remains in full force and
    effect even after termination of the Agreement by its natural termination or the
    early termination by either party.
    </p>
  </div>
  <div className="flex item-ref">
    <p className="number">9.</p>
    <p className="flex text-ref" ><p className="bold">Limitation of Liability. </p> Under no circumstances shall either party be liable to the other party and any third party for any damages resulting from any part of this
    agreement such as, but not limited to, loss of revenue or anticipated profit or lost
    business, costs of delay or failure of delivery, which are not related to or the
    direct result of a party’s negligence or breach.
    </p>
  </div>
  <div className="flex item-ref">
    <p className="number">10.</p>
    <p className="flex text-ref" ><p className="bold">Disclaimer of Warranties. </p>Affiliate shall refer potential candidates as requested
      by company. Affiliate does not represent or warrant that such referrals will create
      any additional profits, sales, exposure, brand recognition, or the like. Affiliate has
      no responsibility to company if the referrals do not lead to Company’s desired
      result(s).
      </p>
  </div>
  <div className="flex item-ref">
    <p className="number">11.</p>
    <p className="flex text-ref" ><p className="bold">Severability. </p> In the event any provision of this Agreement is deemed invalid or
      unenforceable, in whole or in part, that part shall be severed from the remainder
      of the Agreement and all other provisions should continue in full force and effect
      as valid and enforceable.
      </p>
  </div>
  <div className="flex item-ref">
    <p className="number">12.</p>
    <p className="flex text-ref" ><p className="bold">Waiver. </p> The failure by either party to exercise any right, power or privilege under
      the terms of this Agreement will not be construed as a waiver of any subsequent
      or further exercise of that right, power or privilege or the exercise of any other
      right, power or privilege.
      </p>
  </div>
  <div className="flex item-ref">
    <p className="number">13.</p>
    <p className="flex text-ref" ><p className="bold">Legal and Binding Agreement.  </p> This Agreement is legal and binding between the
    Parties as stated above. The Parties each represent that they have the authority
    to enter into this Agreement.
    </p>
  </div>
  <div className="flex item-ref">
    <p className="number">14.</p>
    <p className="flex text-ref" ><p className="bold">Dispute Settlement.</p> The Parties agree in good faith that all disputes arising out
      of the implementation of this Agreement shall be resolved by deliberation to
      reach consensus.
      </p>
  </div>
  <div className="flex item-ref">
    <p className="number">15.</p>
    <p className="flex text-ref" ><p className="bold">Entire Agreement. </p> The Parties acknowledge and agree that this Agreement
      represents the entire agreement between the Parties. In the event that the
      Parties desire to change, add, or otherwise modify any terms, they shall do so in
      writing to be agreed and signed by both parties.
      </p>
  </div>
  <div className="width-100 center">
    <h5 style={{margin:"50px auto 16px"}}>Appendix 1: Success FEE</h5>
    <LazyLoadImage effect="blur" loading="lazy" alt="" src={table_ref} />
  </div>
  <p className="flex width-100">Notes</p>
  <div className="flex item-ref width-100">
    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bullet_ref}  className="bullet_ref"/>
    <p style={{margin: "10px auto 5px 0px"}}>Success Fee is calculated based on salary gross per month.</p>
  </div>
  <div className="flex item-ref width-100">
    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bullet_ref}  className="bullet_ref"/>
    <p style={{margin: "10px auto 5px 0px"}}>Success Fee amount applies for candidate who hired as permanent contract
      employment.
      </p>
  </div>
  <div className="flex item-ref width-100">
    <LazyLoadImage effect="blur" loading="lazy" alt="" src={bullet_ref}  className="bullet_ref"/>
    <p style={{margin: "10px auto 5px 0px"}}>Success fee is subject to income tax (Pph 21)</p>
  </div>
</div> 
</div>
</div>