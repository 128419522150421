import {
    REGISTER,
    LOGIN,
    PROFILE,
    UPDATE_PROFILE,
    GET_PROFILE_ID,
    GET_SKILL_LIST,
    POST_SKILL,
    VERIFIKASI,
    GET_PROJECT,
    UPDATE_PASSWORD,
    CHANGE_PASSWORD,
    UPDATE_INFORMATION,
    AUTOLOGIN,
    PASSWORD_EMAIL,
    LINK_TO_SHARE,
    ALL_PROGRESS,
    PROGRESS_PROFILE,
    VERIFIKASI_EMAIL, POST_REFERRAL, GET_REFERRAL, POST_USER_PROJECT,EDIT_USER_PROJECT,
    GET_GENDER, GET_PROVINCE, GET_COUNTRY, GET_CITY, CITY, GET_ONLY_CITY, STATE, GET_COUNTRY_NEW, CHECK_USER,
    POST_REFERRAL_REGISTER, GET_REFERRER, PUT_PROFILE_ID
} from "../config/constants";
import axios from "axios";
import { API } from '../_redux/helper'

const token = sessionStorage.getItem("token");
export const setUsers = (user) => {
    return {
        type: REGISTER,
        payload: axios({
            method: "post",
            url: `${API}/api/register`,
            data: user,
        })
            .then((res) => {
                sessionStorage.setItem("token", res.data.token);
                window.location.href = "/afterRegister";
                sessionStorage.removeItem("s-name")
                sessionStorage.removeItem("s-email")
            })
            .catch((err) => {
                window.location.href = "#";
                alert("email sudah terdaftar, silahkan mendaftar dengan email baru");
            }),
    };
};

export const setLogin = (user) => {
    return {
        type: LOGIN,
        payload: axios({
            method: "POST",
            url: `${API}/api/login`,
            data: user,
        }).then((res) => {
            sessionStorage.setItem("token", res.data.token);
            localStorage.setItem("logout", false)
            window.location.href = "/afterRegister";
        })
            .catch((err) => {
                window.location.href = "#";
                alert("email sudah terdaftar, silahkan mendaftar dengan email baru");
            }),
    };
};



export const setVerify = (user) => {
    return {
        type: VERIFIKASI_EMAIL,
        payload: axios({
            method: "POST",
            url: `${API}/api/kirim-verifikasi-email`,
            data: user,
        }).then((res) => {
            window.location.href = "/afterRegister";
        })
            .catch((err) => {
                window.location.href = "#";
                alert("email sudah terdaftar, silahkan mendaftar dengan email baru");
            }),
    };
};

export const getProfile = () => {
    return {
        type: PROFILE,
        payload: axios({
            method: "get",
            url: `${API}/api/user`,
            headers: {
                Authorization: ` ${token}`,
            },
        }),
    };
};

export const setInformation = (id) => {
    return {
        type: UPDATE_INFORMATION,
        payload: axios({
            method: "PUT",
            url: `${API}/api/userrecalculatejobzone/${id.id}`,
            data: id,
            headers: {
                Authorization: ` ${token}`,
            }
        })
            .then((res) => {
                window.location.href = "/MyProfile";
            })
    };
};

export const setProfile = (id, education_level_id) => {
    return {
        type: UPDATE_PROFILE,
        payload: axios({
            method: "PUT",
            url: `${API}/api/userrecalculatejobzone/${id}`,
            data: education_level_id,
            headers: {
                Authorization: `${token}`,
            },
        }),
    };
};

export const getProfileId = (user_id) => {
    let token1
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get('token');

    if (foo !== "" && foo !== undefined) {
        token1 = foo
    } else {
        token1 = token
    }
    return {
        type: GET_PROFILE_ID,
        payload: axios({
            method: "GET",
            url: `${API}/api/user/${user_id}`,
            data: user_id,
            headers: {
                Authorization: `${token1}`,
            },
        }),
    };
};

export const share = (user_id) => {
    return {
        type: LINK_TO_SHARE,
        payload: axios({
            method: "GET",
            url: `${API}/api/getresultquestion2/${user_id}?limit=3 `,
            data: user_id,
        }),
    };
};


export const getSkill = () => {
    return {
        type: GET_SKILL_LIST,
        payload: axios({
            method: "GET",
            url: `${API}/api/skill`,
        }),
    };
};

export const getProject = (user_id) => {
    return {
        type: GET_PROJECT,
        payload: axios({
            method: "GET",
            url: `${API}/api/user_project?user_id=${user_id}`,
            data: user_id,
            headers: {
                Authorization: `${token}`,
            },
        }),
    };
};



export const postSkill = (user) => {
    return {
        type: POST_SKILL,
        payload: axios({
            method: "POST",
            url: `${API}/api/user/updateUserSkill`,
            data: user,
        })
    };
};

export const progressProfile = (user_id) => {
    return {
        type: PROGRESS_PROFILE,
        payload: axios({
            method: "GET",
            url: `${API}/api/checkprogressuser/${user_id}`,
            data: user_id,
            headers: {
                Authorization: `${token}`,
            },
        }),
    };
};

export const getProgress = (user_id) => {
    return {
        type: ALL_PROGRESS,
        payload: axios({
            method: "GET",
            url: `${API}/api/getresultquestion2/${user_id} `,
            data: user_id,
        }),
    };
};

export const updateUserProject = (user) => {
    return {
        type: POST_USER_PROJECT,
        payload: axios({
            method: "POST",
            url: `${API}/api/user_project`,
            data: user,
            headers: {
                Authorization: `${token}`,
            },
        })
    };
};

export const editUserProject = (id) => {
    return {
        type: EDIT_USER_PROJECT,
        payload: axios({
            method: "PUT",
            url: `${API}/api/user_project/${id}`,
            data: id,
        })
    };
};

export const updatePassword = (user) => {
    return {
        type: UPDATE_PASSWORD,
        payload: axios({
            method: "POST",
            url: `${API}/api/forgotpasswordtoken`,
            data: user,
        }),
    };
};

export const verifikasiUlang = (user) => {
    return {
        type: VERIFIKASI,
        payload: axios({
            method: "POST",
            url: `${API}/api/login`,
            data: user,
        }),
    };
};

export const changePassword = (user_id, data) => {
    return {
        type: CHANGE_PASSWORD,
        payload: axios({
            method: "PUT",
            url: `${API}/api/changepassworduser2/${user_id}`,
            data: data,
            headers: {
                Authorization: `${token}`,
            }
        })
            .then((res) => {
                localStorage.removeItem('token')
                sessionStorage.removeItem('token');
                window.location.href = '/';
                alert(res.data['message'])
            })
            .catch((err) => {
                alert("password salah")
            }),
    };
};

export const postAutoLogin = (token1) => {
    return {
        type: AUTOLOGIN,
        payload: axios({
            method: "POST",
            url: `${API}/api/login-token`,
            data: token1,
        }).then((res) => {
            window.location.href = `https://kerjakarya.careeya.net/HowToDo`;
        }),
    };
};

export const putPasswordEmail = (user_id, data, token1) => {
    return {
        type: PASSWORD_EMAIL,
        payload: axios({
            method: "PUT",
            url: `${API}/api/changepassworduser/${user_id}`,
            data: data,
            headers: {
                Authorization: `${token1}`,
            },
        })
            .then((res) => {
                alert(res.data['message'])
                window.location.href = '/';
            })
            .catch((err) => {
                if (window.confirm('Maaf link kamu sudah tidak aktif, mohon lakukan lupa password kembali')) {
                    window.location.href = '/';
                };
            }),
    };
};
export const getGender = () => {
    return {
        type: GET_GENDER,
        payload: axios({
            method: "get",
            url: `${API}/api/m_gender`,
            headers: {
                Authorization: ` ${token}`,
            },
        }),
    };
};
export const getProvince = (country_id) => {
    return {
        type: GET_PROVINCE,
        payload: axios({
            method: "get",
            url: `${API}/api/state?country_id=${country_id}`,
            // headers: {
            //     Authorization: ` ${token}`,
            // },
        }),
    };
};
export const getCity = (province_id) => {
    return {
        type: GET_CITY,
        payload: axios({
            method: "get",
            url: `${API}/api/city?state_id=${province_id}`,
            // headers: {
            //     Authorization: ` ${token}`,
            // },
        }),
    };
};


export const getOnlyCity = () => {
    return {
        type: GET_ONLY_CITY,
        payload: axios({
            method: "get",
            url: `${API}/api/city`,
            headers: {
                Authorization: ` ${token}`,
            },
        }),
    };
};


export const getCountry = () => {
    return {
        type: GET_COUNTRY,
        payload: axios({
            method: "get",
            url: `${API}/api/country`,
            // headers: {
            //     Authorization: ` ${token}`,
            // },
        }),
    };
};
export const getOnCountry = (country_id) => {
    return {
        type: GET_COUNTRY_NEW,
        payload: axios({
            method: "get",
            url: `${API}/api/country?id=${country_id}`,
            headers: {
                Authorization: ` ${token}`,
            },
        }),
    };
};


export const getOnState = (province_id) => {
    return {
        type: STATE,
        payload: axios({
            method: "get",
            url: `${API}/api/state?id=${province_id}
            `,
            headers: {
                Authorization: ` ${token}`,
            },
        }),
    };
};
export const getOnCity = (province_id, country_id, city_id) => {
    return {
        type: CITY,
        payload: axios({
            method: "get",
            url: `${API}/api/city?state_id=${province_id}&country_id=${country_id}&id=${city_id}`,
            headers: {
                Authorization: ` ${token}`,
            },
        }),
    };
};

export const postReferral = (user) => {
    return {
        type: POST_REFERRAL,
        payload: axios({
            method: "POST",
            url: `${API}/api/user_refer_friend`,
            data: user,
            headers: {
                Authorization: ` ${token}`,
            },
        })
            .then((res) => {
                window.location.reload(true)
                sessionStorage.setItem('invitation', true)
            })
    };
};

export const getReferral = (user_id) => {
    return {
        type: GET_REFERRAL,
        payload: axios({
            method: "GET",
            url: `${API}/api/getdashboard_referfriend/${user_id}`,
            headers: {
                Authorization: ` ${token}`,
            },
        })
    };
};

export const checkUserExist = (email, phone_number, user_id) => {
    return {
        type: CHECK_USER,
        payload: axios({
            method: "GET",
            url: `${API}/api/checkemailorphoneuser?email=${email}&phone=${phone_number}&user_id=${user_id}`,
            headers: {
                Authorization: `${token}`
            }
        })
    }
}

export const setReferral = (data) => {
    return {
        type: POST_REFERRAL_REGISTER,
        payload: axios({
            method: "POST",
            url: `${API}/api/register_referfriend`,
            data: data
        })
            .then((res) => {
                sessionStorage.setItem("token", res.data.token);
                window.location.href = "/afterRegister";
            })
            .catch((err) => {
                window.location.href = "/";
                alert("email sudah terdaftar, silahkan mendaftar dengan email baru");
            }),
    }
}

export const putProfileId = (user_id) => {
    const job_function_id = user_id.job_function_id
    const industry_id = user_id.industry_id
    const is_new_journey = user_id.is_new_journey
    const job_level_id = user_id.job_level_id
    const is_tooltip = user_id.is_tooltip


    let data_id
    if ((industry_id === undefined || industry_id === "undefined" || industry_id === "") && (is_new_journey === undefined || is_new_journey === "undefined" || is_new_journey === "") &&
        (job_level_id === undefined || job_level_id === "undefined" || job_level_id === "") && (is_tooltip === undefined || is_tooltip === "undefined" || is_tooltip === "")) {
        data_id = {
            job_function_id: `${job_function_id}` === undefined ? "" : `${job_function_id}`,
        }
    } else if ((job_function_id === undefined || job_function_id === "undefined" || job_function_id === "") && (is_new_journey === undefined || is_new_journey === "undefined" || is_new_journey === "") &&
        (job_level_id === undefined || job_level_id === "undefined" || job_level_id === "") && (is_tooltip === undefined || is_tooltip === "undefined" || is_tooltip === "")) {
        data_id = {
            industry_id: `${industry_id}` === undefined ? "" : `${industry_id}`,
        }
    } else if ((job_function_id === undefined || job_function_id === "undefined" || job_function_id === "") && (is_new_journey === undefined || is_new_journey === "undefined" || is_new_journey === "") &&
        (industry_id === undefined || industry_id === "undefined" || industry_id === "") && (is_tooltip === undefined || is_tooltip === "undefined" || is_tooltip === "")) {
        data_id = {
            job_level_id: `${job_level_id}` === undefined ? "" : `${job_level_id}`,
        }
    } else if ((job_function_id === undefined || job_function_id === "undefined" || job_function_id === "") && (is_new_journey === undefined || is_new_journey === "undefined" || is_new_journey === "") &&
        (industry_id === undefined || industry_id === "undefined" || industry_id === "") && (job_level_id === undefined || job_level_id === "undefined" || job_level_id === "")) {
        data_id = {
            is_tooltip: `${is_tooltip}` === undefined ? "" : `${is_tooltip}`,
        }
    } else {
        data_id = {
            is_new_journey: `${is_new_journey}` === undefined ? "" : `${is_new_journey}`,
        }
    }

    return {
        type: PUT_PROFILE_ID,
        payload: axios({
            method: "PUT",
            url: `${API}/api/user/${user_id.user_id}`,
            data: data_id,
            headers: {
                Authorization: `${token}`,
            },
        })
        // .then((res) => {
        //     window.location.reload(true);
        //   })
    };
};

