import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarMiddle from "../components/SidebarMiddle";
import {getProfileId} from "../_actions/user";
import Account from "../components/Account"
import LeftBar from "../components/LeftBar";
import ReactGA from "react-ga";
import _CareerCenter from "../components/_CareerCenter";
import _Howtodo from "../components/_Howtodo";
import _JobOpportunities from "../components/_JobOpportunities";
import _Work_Assessment from "../components/_Work_Assessment";
import _DLP_Home from "../components/_DLP_Home";
import _ChangePassword from "../components/_ChangePassword"
import _MyInformation from "../components/_MyInformation";
import _Help from "../components/_Help";
import _Referral from "../components/_Referral";
import _Personality from "../components/_Personality"
import ReactPixel from 'react-facebook-pixel';


class NewHeader extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      link: JSON.parse(sessionStorage.getItem("sidebar")),
      var_report: sessionStorage.getItem("report"),
      var_confirm:JSON.parse(sessionStorage.getItem("kuesionerconfirmed")),
      visible: true,
      height:"",
      ratting:0,
      cn:"",
    };
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    this.setState({height:window.innerHeight})
    window.scrollTo(0, 0)
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
  }

  

  handleCallback = (childData) =>{
    this.setState({link: !childData})
}


  render() {
    const { profile, isLoading  } = this.props.profile1;
  

    return (
      <div>
        <div className="block HC-pages" style={{top:"0px"}}>
          <div className="NewHeader" style={{position:"unset", top:"0px"}}>
            <Account profile={profile}/>
            <div className="flex">
             <LeftBar parentCallback = {this.handleCallback}/>

            {/* CONTENT */}
            <div className="cubo width-100"  >
              <div className="block">
                <SidebarMiddle />
              </div>
              {window.location.pathname.toLowerCase()==="/careercenter" &&
              <_CareerCenter/>
              }
               {window.location.pathname.toLowerCase()==="/howtodo" &&
              <_Howtodo/>
              }
              {window.location.pathname.toLowerCase()==="/jobopportunities" &&
              <_JobOpportunities/>
              }
              {window.location.pathname.toLowerCase()==="/work_assessment" &&
              <_Work_Assessment ownProps={profile}/>
              }
              {window.location.pathname.toLowerCase()==="/digitallearningplatform" &&
              <_DLP_Home />
              }
              {window.location.pathname.toLowerCase()==="/changepassword" &&
              <_ChangePassword />
              }
              {window.location.pathname.toLowerCase()==="/myinformation" &&
              <_MyInformation />
              }
              {window.location.pathname.toLowerCase()==="/help" &&
              <_Help />
              }
              {window.location.pathname.toLowerCase()==="/referral" &&
              <_Referral/>
              }
              {window.location.pathname.toLowerCase()==="/personality_profiler" &&
              <_Personality/>
              }
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);