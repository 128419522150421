import React, { Component } from 'react';
import report_bg from '../images/report_bg.svg';
import logo from '../images/KaryaTalents_logo.svg';
import wavy_left from '../images/wavy_left.svg';
import wavy_right from '../images/wavy_right.svg';
import icon_download from '../images/icon_download_report.svg';
import { i_download } from '../images/svg/svg';
import {
  report,
  label,
  date_report,
} from '../components/ParamsReport';
import { API } from '../_redux/helper';
import axios from 'axios';
import { FormatListBulletedOutlined } from '@material-ui/icons';
import { Radar } from 'react-chartjs-2';
import { GAevent } from '../components/TrackingEvent';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import ReactDOM from 'react-dom';
import { getReportValues, getReportLimit, getAllResult} from '../_actions/quest';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import 'chartjs-plugin-datalabels';
import Modal from "react-awesome-modal";
import Rating from '@material-ui/lab/Rating';
import dlc_close from "../images/dlc_close.svg"
import right_one from "../images/right-one.png"
import undo_img from "../images/undo.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from "react-facebook-pixel"
import Loading from "../components/Loading";
var $ = require('jquery');


class componentName extends Component {
  constructor() {
    super();
    this.state = {
      user_id: '',
      width: '',
      visible: true,
      visible2: true,
      lastUpdate: '',
      rating:"",
      ratting:0,
      comment:"",
      desc_ratting:"",
      rating_vip:"",
      rating_interest:"",
      popup_interest:JSON.parse(sessionStorage.getItem("rating-interest")),
      popup_personality:JSON.parse(sessionStorage.getItem("rating-personality")),
      popup_vip:JSON.parse(sessionStorage.getItem("rating-vip")),
      popup_values:JSON.parse(sessionStorage.getItem("rating-values")),
      popup_program:JSON.parse(sessionStorage.getItem("rating-program")),
      rate:"",
      desc:"",
      resVIP:'',
      br:0,
      show:false
    };
  }

  async componentDidMount() {
    window.history.scrollRestoration = "manual"
    var jwt = require('jsonwebtoken');
    var token1 = sessionStorage.getItem('token');
    var decode1 = jwt.decode(token1);
    var user_id = decode1['user_id'];
    this.setState({ user_id: user_id });
    this.setState({ width: window.window.outerWidth });
    this.props.getReportValues(user_id);
    this.props.getReportLimit(user_id);
    this.props.getAllResult(user_id);
    window.addEventListener('scroll', this.handleScroll);
    axios.get(`${API}/api/survey/findsubmission/${user_id}`)
      .then(response => {
          this.setState({resVIP:response.data})
      })
    await axios.get(`${API}/api/getresult_work_assessment_lastupdate/${user_id}`).then((res) => {
      this.setState({ lastUpdate: res.data.map((item) => item.last_update) });
    });
  }

  //PDF TERBARU
  exportPDFWithMethod = () => {
    savePDF(ReactDOM.findDOMNode(this.container), {
      paperSize: 'auto',
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
    this.setState({ visible: false });
    setTimeout(() => {
      window.location.reload()
    }, 1500);
    GAevent('MyAssessment', 'Download');
  };

  exportPDFWithComponent = () => {
    this.pdfExportComponent.save({
      toolbar: ['pdf'],
      pdf: {
        forceProxy: true,
        proxyURL: '/proxy',
      },
      dataSource: {
        type: 'odata',
        transport: {
          read: 'https://demos.telerik.com/kendo-ui/service/Northwind.svc/Products',
        },
        pageSize: 7,
      },
      pageable: true,
      columns: [
        { width: 300, field: 'ProductName', title: 'Product Name' },
        { field: 'UnitsOnOrder', title: 'Units On Order' },
        { field: 'UnitsInStock', title: 'Units In Stock' },
      ],
    });
    GAevent('Values Profiler', 'Download PDF');
    ReactPixel('Download PDF - /Values-Profiler', 'Download PDF');
    this.setState({ visible: false });
    setTimeout(() => {
      window.location.href = '/Report';
    }, 2500);
  };

  exportPDFWithComponentMobile = () => {
    this.pdfExportComponent.save();
    GAevent('Values Profiler', 'Download PDF');
    ReactPixel('Download PDF - /Values-Profiler', 'Download PDF');
    this.setState({ visible2: false });
    setTimeout(() => {
      window.location.href = '/Report';
    }, 4500);
  };

  changeRatting=(e)=>{
    this.setState({ratting:Number((e.target.textContent).split('')[0])})
    let val
    if ((e.target.textContent).split('')[0]==1){
      val = "Very Bad"
    }else if ((e.target.textContent).split('')[0]==2){
      val = "Bad"
    }else if ((e.target.textContent).split('')[0]==3){
      val = "Need Improvement"
    }else if ((e.target.textContent).split('')[0]==4){
      val = "Good"
    }else{
      val = "Very Good"
    }

    this.setState({desc_ratting:val})
    this.state.rate = this.state.ratting
    this.state.desc = this.state.desc_ratting
  }

  changeComment=(e)=>{
    this.setState({comment:e.currentTarget.textContent})
  }

   // RATING VALUES PROFILER 
   closePopupValues() {
    const data={
      ratting: "-",
      comment: ""
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_values:false})
        sessionStorage.setItem("rating-values",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }

  submitRatingValues=()=>{
    if (this.state.ratting==0){
      window.location.href="#"
    }else{
    const data={
      ratting: this.state.rate,
      comment: this.state.comment
  }
    axios.post(`${API}/api/user/updateUserRatting/${this.state.user_id}`, data).then((res)=>{
        this.setState({popup_values:false})
        sessionStorage.setItem("rating-values",false)
        window.location.reload()
    }).catch((err)=>{
        console.log(err,"error");
    })
  }}

  _onKeyDown=(e)=>{
    if (e.key === 'Enter') {
   this.submitRatingValues()
    }
  }

  takeAssessment=()=>{
    if (JSON.parse(sessionStorage.getItem("rating-values"))==true){
      window.location.href="#"
      this.setState({popup_values:"true"})
   }else{
      window.location.href="/values_getstarted"
  }
  }

  handleScroll=(event)=>{
    const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop
  const height =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight
  const scrolled = winScroll / height
  if (parseFloat(winScroll)<250){
        this.setState({br:scrolled})
  }
}

  render() {
    const { dataReportValues } = this.props.dataReportValues1;
    const { dataLimitValues } = this.props.dataLimitValues1;
    const {br, show} = this.state

    const dataLimit = [];
    dataLimitValues.data != undefined && dataLimitValues.data.length > 0
      ? dataLimitValues.data.map((item) => dataLimit.push(item))
      : console.log('failed');

      let list_reportValues = []
    let list_dataLimit = []
    let index_max = []

    dataLimit.map((item)=>(
        list_dataLimit.push(item.values)
    ))


    // setTimeout(() => {
    let nil1;
    let nil2;
    let nil3;
    let nil4;
    let nil5;
    let nil6;
    let nil7;
    let nil8;
    let nil9;
    let nil10;
    let val1;
    let val2;
    let val3;
    let val4;
    let val5;
    let val6;
    let val7;
    let val8;
    let val9;
    let val10;
    let name;
    dataReportValues.data != undefined && dataReportValues.data.length > 0
      ? dataReportValues.data.map((item, i) => {
          if (i == 0) {
            nil1 = item.persen_score * 1;
            val1 = item.values.toUpperCase();
            name = item.name;
          } else if (i == 1) {
            nil2 = item.persen_score * 1;
            val2 = item.values.toUpperCase();
          } else if (i == 2) {
            nil3 = item.persen_score * 1;
            val3 = item.values.toUpperCase();
          } else if (i == 3) {
            nil4 = item.persen_score * 1;
            val4 = item.values.toUpperCase();
          } else if (i == 4) {
            nil5 = item.persen_score * 1;
            val5 = item.values.toUpperCase();
          } else if (i == 5) {
            nil6 = item.persen_score * 1;
            val6 = item.values.toUpperCase();
          } else if (i == 6) {
            nil7 = item.persen_score * 1;
            val7 = item.values.toUpperCase();
          } else if (i == 7) {
            nil8 = item.persen_score * 1;
            val8 = item.values.toUpperCase();
          } else if (i == 8) {
            nil9 = item.persen_score * 1;
            val9 = item.values.toUpperCase();
          } else {
            nil10 = item.persen_score * 1;
            val10 = item.values.toUpperCase();
          }
          list_reportValues.push(item.values)
        })
      : console.log('failed');
    // }, 3000);


    const val_day = new Date(this.state.lastUpdate).getDay();
    const val_date = new Date(this.state.lastUpdate).getDate();
    const val_month = new Date(this.state.lastUpdate).getMonth();
    const val_year = new Date(this.state.lastUpdate).getFullYear();
    const val_Hours = new Date(this.state.lastUpdate).getHours();
    const val_Minutes = new Date(this.state.lastUpdate).getMinutes();

    const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let Hour;
    if (val_Hours >= 13) {
      Hour = val_Hours - 12 + ':' + val_Minutes + ' PM';
    } else {
      Hour = val_Hours + ':' + val_Minutes + ' AM';
    }

    const date_PID = day[val_day] + ', ' + month[val_month] + ' ' + val_date + ', ' + val_year + '  ' + Hour;

    const format1 = new Date(`${date_report}`).getDay();
    const  profile  = this.props.ownProps;
    const nama = '' + profile.name;
    const nick_name = nama.split(' ')[0];
    const nick_name2 = nick_name.charAt(0).toUpperCase() + nick_name.slice(1);

    let regText = /__first_name__/gi;
    let regProfile = /__profile__/gi;
    var dataURI = 'data:text/plain;base64,SGVsbG8gV29ybGQh';

    let text = `${report.suggestionsForManagersCopy}`;
    let suggestionsForManagers;
    if (text.indexOf('__first_name__') != -1) {
      suggestionsForManagers = text.replace(regText, nick_name2);
    } else {
      suggestionsForManagers = text.replace(regProfile, label);
    }

    let nilai = [nil1, nil2, nil3, nil4, nil5, nil6, nil7, nil8, nil9, nil10];
    list_dataLimit.map((item)=>(
      index_max.push(list_reportValues.indexOf(item))
  ))
    function checkValue(ar) {
      let arr = [];
      if (ar.length <= 3) return ar;
     
      ar.map((x, index) => {
        let color = 'grey';
        index_max.map((y) => {
          if (index == y) {
            color = '#3B6C98';
          }
        });
        arr.push(color);
      });

      return arr;
    }
    function checkFont() {
      let check = window.innerWidth > 500 ? 12 : 8;
      return check;
    }
    function ratioCheck() {
      let check = window.innerWidth < 500 ? false : true;
      return check;
    }
    // DATA FOR CHART
    const options = {
      responsive: true,
      maintainAspectRatio: ratioCheck(),
      tooltips: false,
      plugins: {
        datalabels: {
          display: function (context) {
            if (nilai.length <= 3) return nilai;
           
            let index = index_max
            // max.map((x) => index.push(x.index));
            let check = index.indexOf(context.dataIndex) != -1 ? true : false;

            return check;
          },
          opacity: 1,
          anchor: 'end',
          align: 'end',
          color: 'white',
          borderColor: '#3B6C98',
          borderWidth: 1,
          borderRadius: 5,
          font: {
            weight: 'bold',
            size: 10,
            lineHeight: 1 /* align v center */,
          },
          padding: {
            top: 5,
          },
          /* hover styling */
          backgroundColor: function (context) {
            return context.hovered ? context.dataset.borderColor : 'white';
          },
          color: function (context) {
            return 'grey';
          },
          formatter: function (value, context) {
            return Math.round(value);
          },
          listeners: {
            enter: function (context) {
              context.hovered = true;
              return true;
            },
            leave: function (context) {
              context.hovered = false;
              return true;
            },
          },
        },
      },
      legend: {
        display: false,
      },
      scale: {
        pointLabels: {
          fontStyle: 'bold',
          fontSize: checkFont(),
          fontColor: checkValue(nilai),
          callback: function (pointLabel, index, labels) {
            return pointLabel;
          },
        },
        angleLines: {
          display: FormatListBulletedOutlined,
          borderDash: [10, 10],
        },
        ticks: {
          maxTicksLimit: 6,
          stepSize: 10,
          beginAtZero: true,
          suggestedMin: 50,
          suggestedMax: 100,
          display: false,
        },
      },
    };

    const data = {
      labels: [
        `${val1}`,
        `${val2}`,
        `${val3}`,
        `${val4}`,
        `${val5}`,
        `${val6}`,
        `${val7}`,
        `${val8}`,
        `${val9}`,
        `${val10}`,
      ],
      datasets: [
        {
          data: [nil1, nil2, null, null, null, null, null, null, null, null],
          label: `ACHIEVEMENT`,
          borderColor: 'rgba(215, 87, 87, 0.5)',
          backgroundColor: 'rgba(215, 87, 87, 0.6)',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          data: [null, null, nil3, nil4, nil5, null, null, null, null, null],
          label: `FUN`,
          borderColor: 'rgba(226, 181, 129, 0.5)',
          backgroundColor: 'rgba(226, 181, 129, 0.6)',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          data: [null, null, null, null, null, nil6, nil7, null, null, null],
          label: `HARMONY`,
          borderColor: 'rgba(91, 184, 139, 0.5)',
          backgroundColor: 'rgba(91, 184, 139, 0.6)',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          data: [null, null, null, null, null, null, null, nil8, nil9, nil10],
          label: `COMPLIANCE`,
          borderColor: 'rgba(91, 128, 184, 0.5)',
          backgroundColor: 'rgba(91, 128, 184, 0.6)',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };

    const  profile1  = this.props.ownProps;

    const { allRes } = this.props.result2;
    const {isLoading} = this.props.dataReportValues1
    let n_val
    
    dataReportValues.data != undefined && dataReportValues.data.length > 0 ?
    dataReportValues.data.map((item,i)=>{
        if (i==0){
            n_val = item.persen_score
        }
    }):console.log("error");


    let val_interest 
    allRes.map((item,i)=>{
        if (i==0){
            val_interest = item.scoring
        }
    })

    if (isLoading) {
      return (
          <React.Fragment>
          <Loading />
          </React.Fragment>
      );
    }
        

    const btn = <div className='flex center bg-36 br-24 bd-e1 p-14 ml-14 pointer '  >
                  <p className='c-ff w-600 Montserrat s-1418 mr-14'>Take Next Assessment</p>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.03033 3.96969C7.81583 3.75519 7.49324 3.69103 7.21299 3.80711C6.93273 3.9232 6.75 4.19668 6.75 4.50002V13.5C6.75 13.8034 6.93273 14.0768 7.21299 14.1929C7.49324 14.309 7.81583 14.2449 8.03033 14.0304L12.5303 9.53035C12.8232 9.23746 12.8232 8.76258 12.5303 8.46969L8.03033 3.96969Z" fill="white"/>
                  </svg>
              </div>
    let bt_next
        
    if(this.state.resVIP == "Data Not Found") {
      bt_next = <Link to="/personality_profiler">
                        {btn}
                    </Link>
    } else if(val_interest < 0) {
      bt_next = <Link to="/Howtodo">
                        {btn}
                    </Link>
    } else if (!profile1.is_work_assessment){
        
      bt_next = <Link to="/values_profiler">
                        {btn}
                    </Link>
    } else {
      bt_next = null
    }

    let st_dis = false
    if (br===0){
        st_dis=false
    }else{
        st_dis=true
    }

    return (
      <div>
        <div className='flex width-100 fixed z-9 bg-ff hg-50 center shd-01 ml--39 trans-3 mt--1' style={{visibility:st_dis? "visible":"hidden", opacity:st_dis ?"1":"0" }}  >
            <div className='flex space-between page-container-outer alignCenter flex-mob'>
            <div className='flex dw-mobile'>
              <p className='c-36 s-3032 leagueSpartan'>Values Profiler</p>
              <div className={`none ${show?"i":""}` } onClick={(e)=>this.setState({show:!show})}>{i_download}</div>
              </div>
              <div className={`flex mt-12 mb-12 flex-mob ${show?"":"i"}`}>
                <div className='flex center bg-ff br-24 bd-e1 p-14 pointer' onClick={this.exportPDFWithMethod}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                    <p className='c-36 w-600 Montserrat s-1418 ml8'>Download</p>
                </div>
                <div className='flex center bg-ff br-24 bd-e1 p-14 ml-14 pointer' onClick={this.takeAssessment}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69671 3.6967C5.0531 2.34029 6.92913 1.5 9 1.5C13.1421 1.5 16.5 4.85787 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C6.92913 16.5 5.0531 15.6597 3.6967 14.3033C3.40381 14.0104 3.40382 13.5355 3.69672 13.2426C3.98961 12.9497 4.46449 12.9497 4.75738 13.2426C5.84396 14.3293 7.34292 15 9 15C12.3137 15 15 12.3137 15 9C15 5.6863 12.3137 3 9 3C7.34292 3 5.84396 3.67075 4.75737 4.75736L4.75736 4.75737C4.54789 4.96685 4.24503 5.29039 3.93772 5.625H5.25C5.66421 5.625 6 5.96079 6 6.375C6 6.78921 5.66421 7.125 5.25 7.125H2.25C1.83579 7.125 1.5 6.78921 1.5 6.375V3.375C1.5 2.96079 1.83579 2.625 2.25 2.625C2.66421 2.625 3 2.96079 3 3.375V4.42911C3.25762 4.15067 3.50756 3.88585 3.69671 3.6967Z" fill="#3B6C98"/>
                    </svg>
                    <p className='c-36 w-600 Montserrat s-1418 ml-14'>Retake Values Profiler</p>
                </div>
                {bt_next}
                </div>
            </div>
        </div>
        <div
          className='page-container report_Profiler'
          style={{ marginTop: '80px', backgroundColor: '#F2F2F2', width: this.state.visible2 ? '100%' : '1200px' }}>
          {/* <div className="page-container report_Profiler" style={{marginTop:"80px",backgroundColor:"#F2F2F2"}}> */}
          <div className='page-container-outer report-values' style={{ boxShadow: 'none' }}>
            <div className='width-100 dekstop'>
              <div className='flex width-100 space-between  title_Profiler'>
                <h1>Values Profiler</h1>
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Link onClick={this.takeAssessment} style={{marginRight: '24px'}}>
                  <button className='flex ret center bg-ff bd-e1'>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69671 3.6967C5.0531 2.34029 6.92913 1.5 9 1.5C13.1421 1.5 16.5 4.85787 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C6.92913 16.5 5.0531 15.6597 3.6967 14.3033C3.40381 14.0104 3.40382 13.5355 3.69672 13.2426C3.98961 12.9497 4.46449 12.9497 4.75738 13.2426C5.84396 14.3293 7.34292 15 9 15C12.3137 15 15 12.3137 15 9C15 5.6863 12.3137 3 9 3C7.34292 3 5.84396 3.67075 4.75737 4.75736L4.75736 4.75737C4.54789 4.96685 4.24503 5.29039 3.93772 5.625H5.25C5.66421 5.625 6 5.96079 6 6.375C6 6.78921 5.66421 7.125 5.25 7.125H2.25C1.83579 7.125 1.5 6.78921 1.5 6.375V3.375C1.5 2.96079 1.83579 2.625 2.25 2.625C2.66421 2.625 3 2.96079 3 3.375V4.42911C3.25762 4.15067 3.50756 3.88585 3.69671 3.6967Z" fill="#3B6C98"/>
                    </svg>
                    <p>Retake Values Profiler</p>
                  </button>
                </Link>
                {bt_next}
                </div>
              </div>
            </div>
            <div className='width-100 mobile' style={{ backgroundColor: '#F2F2F2' }}>
              <div className='flex width-100 Mob_ title_Profiler column'>
                <h1 className='width-100 flex' style={{ marginTop: '15px' }}>
                  Values Profiler
                </h1>
                <Link onClick={this.takeAssessment}>
                  <button className='flex ret center bg-ff bd-e1'>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69671 3.6967C5.0531 2.34029 6.92913 1.5 9 1.5C13.1421 1.5 16.5 4.85787 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C6.92913 16.5 5.0531 15.6597 3.6967 14.3033C3.40381 14.0104 3.40382 13.5355 3.69672 13.2426C3.98961 12.9497 4.46449 12.9497 4.75738 13.2426C5.84396 14.3293 7.34292 15 9 15C12.3137 15 15 12.3137 15 9C15 5.6863 12.3137 3 9 3C7.34292 3 5.84396 3.67075 4.75737 4.75736L4.75736 4.75737C4.54789 4.96685 4.24503 5.29039 3.93772 5.625H5.25C5.66421 5.625 6 5.96079 6 6.375C6 6.78921 5.66421 7.125 5.25 7.125H2.25C1.83579 7.125 1.5 6.78921 1.5 6.375V3.375C1.5 2.96079 1.83579 2.625 2.25 2.625C2.66421 2.625 3 2.96079 3 3.375V4.42911C3.25762 4.15067 3.50756 3.88585 3.69671 3.6967Z" fill="#3B6C98"/>
                    </svg>
                    <p>Retake Values Profiler</p>
                  </button>
                </Link>
                {bt_next}
              </div>
            </div>
            <PDFExport
              ref={(component) => (this.pdfExportComponent = component)}
              width='1000'
              height='1000'
              dataURI={dataURI}
              proxyURL='/proxy'
              fileName={`Personality ${nick_name2}`}
              paperSize='auto'
              className='width-100'
              margin={20}
              style={{ width: '100% !important' }}>
              <div className='canvas_Profiler' ref={(container) => (this.container = container)}>
                <div className='width-100' style={{ marginBottom: '18px' }}>
                  <LazyLoadImage effect="blur"
                    src={report_bg}
                    className='flex width-100 bg_top_profiler'
                    style={{ height: this.state.visible2 ? '' : '199px' }}
                  />
                  <div className='content_bg_report' style={{ height: this.state.visible2 ? '' : '199px' }}>
                    <div className='width-100 dekstop' style={{ display: this.state.visible2 ? '' : 'flex' }}>
                      <div className='flex width-100  header_bg ' style={{ justifyContent: 'space-between' }}>
                        <div className='box_under_lc' >
                          <div className='flex box_under_lc ' >
                            <button onClick={this.exportPDFWithMethod} >
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                              <p>Download PDF</p>
                            </button>
                          </div>
                        </div>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className='logo_bg' />
                        <div className='box_under_lc op-0'  style={{opacity:"0", visibility:"hidden"}}>
                          <div className='flex box_under_lc ' >
                            <button onClick={this.exportPDFWithMethod} >
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                              <p>Download PDF</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='width-100 mobile' style={{ display: this.state.visible2 ? '' : 'none' }}>
                      <div className='flex width-100 space-between column header_bg center'>
                        {/* <button style={{marginBottom:"20px"}} onClick={this.exportPDFWithComponent}>Download PDF</button> */}
                        <button
                          onClick={this.exportPDFWithComponentMobile}
                          style={{  marginBottom: '20px' }}>
                          <LazyLoadImage effect="blur" loading="lazy" alt="" src={icon_download} />
                          <p>Download PDF</p>
                        </button>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} className='logo_bg' style={{ height: '24px' }} />
                      </div>
                    </div>
                    <div className='dekstop width-100'>
                      <div className='flex width-100 center'>
                        {/* <p style={{fontSize:"45px",marginTop:"34px", fontWeight:"bold",backgroundColor:"red", marginLeft:"10%"}}>Personality Profiler</p> */}
                        <div className='title_bg_canvas' style={{ paddingLeft: this.state.visible ? '' : '16%' }}>
                          Values Profiler
                        </div>
                      </div>
                    </div>
                    <div className='mobile width-100'>
                      <div className='flex width-100 center'>
                        {/* <p style={{fontSize:"45px",marginTop:"34px", fontWeight:"bold",backgroundColor:"red", marginLeft:"10%"}}>Personality Profiler</p> */}
                        <div
                          className='title_bg_canvas'
                          style={{
                            marginTop: '34px',
                            padding: this.state.visible2 ? '' : '17px 5% 0px 15%',
                            fontSize: this.state.visible2 ? '' : '40px',
                            lineHeight: this.state.visible2 ? '' : '60px',
                            marginTop: this.state.visible2 ? '' : '17px',
                            marginBottom: this.state.visible2 ? '' : '15px',
                            width: this.state.visible2 ? '' : '600px',
                          }}>
                          Values Profiler
                        </div>
                      </div>
                    </div>
                    <div className='flex width-100 center btn_Idv'>
                      <button>
                        <p>Individual Report</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='width-100 dekstop'>
                  <div className='box_admin_Profile' style={{ border: this.state.visible ? '' : '1px solid #ececec' }}>
                    <div className='flex width-100 height-100 space-between'>
                      <LazyLoadImage effect="blur"
                        src={wavy_left}
                        style={{ height: '100%', objectFit: 'cover', borderBottomLeftRadius: '10px' }}
                      />
                      <LazyLoadImage effect="blur"
                        src={wavy_right}
                        style={{ height: '100%', objectFit: 'cover', borderTopRightRadius: '10px' }}
                      />
                    </div>
                    <div className='flex  column textPrepare custome--profiler'>
                      <p className='top'>Prepared for</p>
                      <div className='flex width-100 space-between'>
                        <div className='flex ' style={{ alignItems: 'center' }}>
                          <h3 className='s-20 openSans-Bold' style={{ textTransform: 'capitalize' }}>
                            {name}{' '}
                          </h3>
                          <div style={{ flex: 1, margin: 'auto' }}>
                            {dataLimit.map((item, i) => (
                              <button key={i} style={{ marginRight: '12px' }}>
                                <p style={{ textTransform: 'uppercase' }}>{item.values.replace(/\s.*/, '')}</p>
                              </button>
                            ))}
                          </div>
                        </div>
                        {date_report && (
                          <p className='date tab-s-11' style={{ margin: 'auto 0px' }}>
                            {date_PID}{' '}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='width-100 mobile'>
                  <div style={{ padding: '14px' }}>
                    <div
                      className='box_admin_Profile'
                      style={{
                        height: '170px',
                        width: '100%',
                        marginLeft: '0%',
                        border: this.state.visible2 ? '' : '1px solid #ececec',
                      }}>
                      <div className='flex width-100 height-100 space-between icon-bg-personality'>
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={wavy_left} style={{ objectFit: 'cover', borderBottomLeftRadius: '0px' }} />
                        <LazyLoadImage effect="blur"
                          src={wavy_right}
                          style={{ objectFit: 'cover', borderTopRightRadius: '0px', marginTop: 'auto' }}
                        />
                      </div>
                      <div
                        className='flex  column textPrepare width-100'
                        style={{ marginLeft: this.state.visible2 ? '' : '4%' }}>
                        <div className='width-100 flex column' style={{ padding: '14px' }}>
                          <p className='top'>Prepared for</p>
                          <h3 style={{ textTransform: 'capitalize' }}>{name} </h3>
                          <div className='flex width-100 space-between column'>
                            {dataLimit.map((item, i) => (
                              <button key={i} style={{ width: '94%', marginLeft: '0px' }}>
                                <p style={{ textTransform: 'uppercase' }}>{item.values.replace(/\s.*/, '')}</p>
                              </button>
                            ))}
                            {date_report && <p className='date tab-s-11'>{date_PID}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='width-report custome--profiler'>
                  <div className='flex width-100 center box-Overview'>
                    <div className='flex width-radar '>
                      <Radar data={data} options={options} />
                    </div>
                  </div>
                </div>
                <div className='flex width-report top3'>
                  <h2 className='s-25' style={{ fontFamily: 'Montserrat-Bold' }}>
                    Your TOP 3
                  </h2>
                  <h2 className='s-25' style={{ color: '#DDB87A', fontWeight: 'normal' }}>
                    VALUES
                  </h2>
                </div>
                <div className='width-report'>
                  <div className='box-ValReport'>
                    <div className='Idea_box' style={{ flexDirection: this.state.visible2 ? '' : 'row' }}>
                      {dataLimit.map((item, i) => {
                        return (
                          <div key={i} className='blue_container' style={{ width: this.state.visible2 ? '' : '30%' }}>
                            <div className='items width-100'>
                              <div className='valuesBox'>
                                <p>{item.values.replace(/\s.*/, '')} </p>
                              </div>
                              <div className='flex width-100 column'>
                                {/* <h1 className="width-100" style={{fontSize:this.state.visible2?"":"24px",lineHeight:this.state.visible2?"":"30px",marginBottom:this.state.visible2?"":"28px"}}>Ideal Environment</h1> */}
                                <p className='width-100 openSans' style={{ marginTop: '24px' }}>
                                  {item.content_description}
                                </p>
                              </div>
                              {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={ideal} /> */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div className="width-100 flex" style={{border:"1px solid #E0E0E0", margin:"50px 0px"}} /> */}

                  <div className='flex width-100 center' style={{ margin: '110px auto 59px auto' }}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} />
                  </div>
                </div>
              </div>
            </PDFExport>
          </div>
        </div>
        <Modal  id="course-outline51a" visible={this.state.popup_values} effect="fadeInUp" onClickAway={() => this.closePopupValues()}>
              <div className="popup_dlc  flex column">
              <div className="box_popup_dlc" >
                  <div className="flex width-100 flex-end" >
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={dlc_close} onClick={() => this.closePopupValues()}/>
                  </div>
              <div className="flex width-100 center column" onKeyDown={this._onKeyDown} >
                  <h2 className="openSans">Rate this Values Profiler</h2>
                  <Rating name="pristine" value={this.state.rate} onClick={this.changeRatting} onChange={this.changeRatting} onTouchStart={(e, newValue)=>this.changeRatting(e,newValue)}/>
                  <h3 className="openSans flex">{this.state.desc} </h3>
                  <div className="flex width-100 space-between exp-dlc">
                  <p className="openSans">(1)Very Bad</p>
                  <p className="openSans">(2) Bad</p>
                  <p className="openSans">(3) Need Improvement</p>
                  <p className="openSans">(4)Good</p>
                  <p className="openSans">(5)Very Good</p>
                  </div>
                  <h2 className="openSans">Give us your thought about this Values Profiler</h2>
                  <div className="input flex width-100"  onInput={this.changeComment}  value={this.state.comment} contentEditable >
                  {/* <p>{this.state.comment}</p> */}
                  </div>
                  <button  onClick={this.submitRatingValues} style={{backgroundColor:this.state.ratting != 0?"#3B6C98":"", borderColor:this.state.ratting != 0?"#3B6C98":""}}>
                  <p>Submit Rating</p>
                  </button>
              </div>
              </div>
          </div>
          </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    result2: state.allResult,
    dataReportValues1: state.reportValuesRed,
    dataLimitValues1: state.limitValuesRed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReportValues: (user_id) => {
      dispatch(getReportValues(user_id));
    },
    getReportLimit: (user_id) => {
      dispatch(getReportLimit(user_id));
    },
    getAllResult: (user_id) => {
      dispatch(getAllResult(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(componentName);
