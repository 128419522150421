import React, { Component } from 'react';
import {phone,mail,cv,cv_green, people, salary, skill, work_exp, education, certificate, project,industry,
    art_certification,art_personal, art_education,art_project,art_salary,art_skills,art_work_exp, pen} from "../../_redux/svg"

class Skills extends Component {
    constructor(){
        super()
        this.state={
            visible:false,
            user_id:""
        }
    }


    render() {
        const {skillList} = this.props
        let visible = true
        if (skillList.length > 0){
            visible= false
        }

        return (
            <div className="h-50 t">
                <div className='flex column bg-ff br-10 shd-input -set h-100' >

                    {/* {onClick={(e)=>this.setState({visible:!visible})}} */}
                    <div className='flex p-1814 bb-ea space-between'>
                        <div className='flex'>
                        <div className='flex m-auto-00'>
                            {skill}
                        </div>
                        <p className='ml-14 DM-Sans c-29 s-20 '>Skills</p>
                        </div>
                        {!visible &&
                        <div className='flex pointer m-auto0' onClick={(e)=>this.props.handlePopup("skills_")}>
                            {pen}
                            <p className='DM-Sans s-14 c-36 ml6'>Edit</p>
                        </div>}
                    </div>
                    <div className='flex column width-100 center'>
                        {visible &&
                        <div className='flex width-100 blank-data-main hg-100 pb-2'>
                            <div className='flex m-2429'>
                                {art_skills}
                            </div>
                            <div className='flex column ml-29 mb-48 wd-375 center mr-10'>
                                <p className='c-29 DM-Sans s-14 mt-48 mb-48 tx-c'>Show skills and abilities that empower you to give your best performance.</p>
                                <div className='bg-36 p-1014 sm-m-2 button-w-main br-20 wd-fit-content pointer' onClick={(e) => this.props.handlePopup("skills_")}>
                                    <p className='s-14 c-ff DM-Sans '>Add Skills</p>
                                </div>
                            </div>
                        </div>}
                        {!visible &&
                        <div className='flex hg-100 width-100'>
                            <div className='p-14 flex wrap'>
                            {skillList.map((item,i) => {
                                    return(
                                <div className='flex p-414 bg-36 br-14 mr-14 mb-14 wd-fit-content hg-fit-content'>
                                    <p className='c-ff s-14 DM-Sans'>{item}</p>
                                </div>)})}
                                {/* <div className='flex p-414 bg-36 br-14 mr-14 mb-14 wd-fit-content hg-fit-content'>
                                    <p className='c-ff s-14 DM-Sans'>Bibendum dignissim odio</p>
                                </div>
                                <div className='flex p-414 bg-36 br-14 mr-14 mb-14 wd-fit-content hg-fit-content'>
                                    <p className='c-ff s-14 DM-Sans'>Non lectus</p>
                                </div> */}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Skills;