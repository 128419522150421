import { GET_DOCUMENT, GET_DOCUMENT_ID, POST_DOCUMENT, DELETE_DOCUMENT, PUT_DOCUMENT, GET_DOCUMENT_PENDUKUNG } from "../config/constants";
import axios from "axios";
import { API } from '../_redux/helper'

const token = sessionStorage.getItem('token')
export const getDocument = (id) => {
  return {
    type: GET_DOCUMENT,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_document/${id}`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const getDocumentId = (user_id) => {
  return {
    type: GET_DOCUMENT_ID,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_document?user_id=${user_id}&document_type_id=1`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};
export const getDocumentPendukung = (user_id) => {
  return {
    type: GET_DOCUMENT_PENDUKUNG,
    payload: axios({
      method: "GET",
      url: `${API}/api/user_document?user_id=${user_id}&document_type_id=2`,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const postDocument = (user) => {
  return {
    type: POST_DOCUMENT,
    payload: axios({
      method: "POST",
      url: `${API}/api/user_document`,
      data: user,
      headers: {
        Authorization: ` ${token}`,
      },
    })
  };
};

export const putDocument = (id) => {
  return {
    type: PUT_DOCUMENT,
    payload: axios({
      method: "PUT",
      url: `${API}/api/user_document/${id.id}`,
      data: id,
      headers: {
        Authorization: ` ${token}`,
      }
    })
      .then((res) => {
        window.location.reload(true);
      })
  };
};

export const deleteDocument = (id_doc) => {
  return {
    type: DELETE_DOCUMENT,
    payload: axios({
      method: "DELETE",
      url: `${API}/api/user_document/${id_doc}`,
      data: id_doc,
      headers: {
        Authorization: ` ${token}`,
      }
    })
  };
};