import React, { Component } from "react";
import karya from "../images/logoBaru.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import rec from "../images/rec_new.png"
import Session from '../components/Session'
import { getProfileId} from "../_actions/user";
import ReactGA from 'react-ga';
import undraw from "../images/undraw.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';

class GetStarted extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
    }
    };


  goBack() {
    this.props.history.goBack();
  }
  componentDidMount(){
    Session.checkToken()
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    this.setState({ token: token1 });
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getProfileId(user_id);
    sessionStorage.setItem('setupTime', new Date().getTime())
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.track(window.location.pathname);
    window.scrollTo(0, 0)
  }

  render() {
    const { profile } = this.props.profile1;
    let nickname
    if (profile.name==="" || profile.name===undefined){
      nickname=""
    }else{
      nickname=profile.name
    }

    const nama = '' + nickname
    const name = nama.split(" ")[0]
   
    
    return (
      <div>
        <div className="edu welcome">
        <div className="box-wrapper getStarted">
          <div className="wrapper">
            <div className="content">
              <div className="page-container assessment-result width-100">
              <div className="background-top width-100" style={{background:"none"}}>
                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={rec} className="width-100" />
                </div>
                <div className="page-container-outer">
                  <div className="box-logo width-100 dekstop" style={{marginTop:"-214px"}}>
                    {/* <Link to="/"> */}
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy"  src={karya} alt="" />
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="mobile-information width-100">
                  <div className="box-logo width-100">
                    <div className="box-logo-img">
                      <LazyLoadImage effect="blur" loading="lazy"  src={karya} alt="" />
                    </div>
                  </div>
                  </div>
                  <div className="page-container-inner width-100" style={{marginTop:"-136px"}}>
                    <div className="dekstop width-100">
                    <div className="box-content">
                      <div className="flex width-100">
                        <div className="width-50 left" style={{margin:"auto"}}>
                          <div className="flex column width-100 box_onBoarding">
                            <h1 style={{textTransform:"capitalize"}}>Welcome to KaryaTalents, {name+"!"}</h1>
                            <p className="content_p">You must be so excited to develop your career.
                                But first, we will show you how to get along
                                with our platform</p>
                                <Link to="/JobFunction">
                                <button className="bg-36 pointer br-5">
                                  <p>Let’s Go</p>
                                </button>
                                </Link>
                          </div>
                        </div>
                        <div className="width-50 right">
                          <div className="flex column width-100">
                            <LazyLoadImage effect="blur" loading="lazy" alt="" src={undraw} />
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="mobile width-100">
                      <div className="box-content">
                      <div className="width-100">
                        <div className="width-100 right">
                            <div className="flex column width-100">
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={undraw} />
                            </div>
                          </div>
                        <div className="width-100 left" style={{margin:"auto"}}>
                          <div className="flex column width-100 box_onBoarding">
                            <h1 style={{textTransform:"capitalize"}}>Welcome to KaryaTalents, {name+"!"}</h1>
                            <p className="content_p">You must be so excited to develop your career.
                                But first, we will show you how to get along
                                with our platform</p>
                                <Link to="/JobFunction">
                                <button className="bg-36 pointer br-5">
                                  <p>Let’s Go</p>
                                </button>
                                </Link>
                          </div>
                        </div>
                        
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    profile1: state.profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileId: (user_id) => {
      dispatch(getProfileId(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);

