import React, { Component } from 'react';
import logo from "../images/KaryaTalents_logo.png";
import lamp from "../images/pid_lamp.svg"
import pid_6 from "../images/pid_6.svg"
import pid_4 from "../images/pid_4.svg"
import pid_1 from "../images/pid_1.svg"
import {getQuestPID, submitPID, getQuestValues, submitValues, res_submitValues} from "../_actions/quest";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {API} from '../_redux/helper'
import axios from "axios";
import ExpandMoreIcon from '../images/top_acc.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


class componentName extends Component {
    constructor() {
        super();
        this.state = {
          id_quest: 0,
          list_quest:[],
          id_values:1,
          page:1,
          pages:1,
          length:0,
          complete:"",
          next : true,
          back:false,
          data_post:{
              user_id:"",
              answer:[],
        },
        data:[],
        dataAnswer:[],
        dataSubmit:[]
          
        }
    }

    componentDidMount() {
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        this.setState({ user_id: user_id });
        this.props.getQuestPID();
        this.props.getQuestValues(user_id);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.track(window.location.pathname);
        window.scrollTo(0, 0)
      }
      
      componentDidUpdate(prevProps, prevState, snapshot){
          if (prevState.page > this.state.page ){
            if (parseInt(this.state.complete.substr(1)*4) <= this.state.list_quest.length && this.state.list_quest.length !== 0 ){
                this.setState({next:true})
            }else{
                this.setState({next:false})
            }
              
          }
      }

    next_quest=()=>{
        if (this.state.pages <10){
        let n
        let val
        if (this.state.pages <= 10){
            n = this.state.pages + 1
        }else{
            n= this.state.pages + 0
            this.submit()
        }

        if (this.state.id_quest >= 20){
            val= 6
        }else{
            val=4
        }

        this.setState({
            id_quest: this.state.id_quest+val,
            pages: n,
            next: false,
            length: this.state.length + val})
        }else{
            window.location.href="/values_complete"
        }
        
    }
    save_quest=(e)=>{
        this.setState({list_quest:e})
    }
    submit =()=>{
        const arr = this.state.data_post.answer
        const result = {
            userId: this.state.user_id,
            answers: [{ "_id": "preamble", "value": "seen" }]
        }
        /* eslint eqeqeq: 0 */
        arr.map(x => {
        let tempObj = {_id: '', value: ["", "", "", ""]}
        let find = result.answers.find(function(post, index) {
            if(post._id == x._id)
            return true;
        });
        if(!find) {
            tempObj._id = x._id
            tempObj.value[x.value_id - 1] = x.value
            result.answers.push(tempObj)
        } else {
            find.value[x.value_id - 1] = x.value
        }
    })
    result.answers.map(x => {
        x.value = JSON.stringify(x.value)
    })
    this.props.question1(result);

    }

    information = () => {
        const token = sessionStorage.getItem("token");
        const data = {
          is_work_assessment:"true",
        };
        axios.put(`${API}/api/userrecalculatejobzone/${this.state.user_id}`, data,
        {headers: {
            Authorization: `${token}`,
        },}).then((res)=>{
            window.location.href="/Values-Profiler"
        }).catch((err)=>{
            console.log(err,"error ");
        })
      
      };


    handleChange = (title,value,_id) => (e) => {
        const ty= {id:title, value:value}
        this.props.question1(title,value)
        let dataRadio = { title, value };
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1["user_id"];
        const { todosPerPage, selectedValue, currentPage } = this.state;
        const select = this.state.list_quest.findIndex((selectedValue) => selectedValue.title === title);
        const selectedProfile = this.state.list_quest[select] ? this.state.list_quest[select].title : ""
        const selected = this.state.list_quest[select] ? this.state.list_quest[select].value : ""
        const filter = this.state.list_quest.findIndex((selectedValue) => selectedValue.value === value)
        const filter2 = this.state.list_quest.findIndex((selectedValue) => selectedValue.title === title && selectedValue._id === _id)
        const filter3 = this.state.list_quest.findIndex((selectedValue) => selectedValue._id === _id && selectedValue.value === value)
        const selectedValue2 = this.state.list_quest[filter] ? this.state.list_quest[filter].value : ""
        let indexClick = this.state.list_quest.findIndex((x, index) => x.title === title && x.value === value && x._id == _id)

        if ( indexClick == -1 && filter3 === -1 && filter2 === -1) {
            let testing = { title, value, _id }
            this.state.data.push({value})
            this.state.dataSubmit.push(ty)
            this.state.dataAnswer.push({_id : _id, value_id : value, value : title})
            this.setState({data_post:{answer:this.state.dataAnswer,user_id: user_id }})

            let temp = []
            temp.push(testing)
            this.state.list_quest.push({ title, value, _id })
            this.setState({ list_quest: this.state.list_quest})
            this.setState({ complete: _id})
            if (this.state.id_quest >= 20){
                if (parseInt((this.state.pages*6)-10) === this.state.list_quest.length ){
                    this.setState({next:true})
                }
            }else {
            if (parseInt(this.state.pages*4) === this.state.list_quest.length ){
                this.setState({next:true})
            }
        }
        } else if(indexClick != -1){
          this.state.list_quest[filter2] = { title, value, _id}
            this.state.list_quest.splice(indexClick, 1)
            this.state.dataSubmit.splice(indexClick, 1)
            this.setState({ list_quest: this.state.list_quest})
            // this.setState({ length: this.state.length - 1})
        }
        if (this.state.list_quest.length== 50){
            const token = sessionStorage.getItem("token");
            const data = {
            is_work_assessment:"true",
            };
            axios.put(`${API}/api/userrecalculatejobzone/${this.state.user_id}`, data,
            {headers: {
                Authorization: `${token}`,
            },}).then((res)=>{
                console.log("complete");
            }).catch((err)=>{
                console.log(err,"error ");
            })
            // this.props.question1(title, scor);
            this.state.dataSubmit.map((item)=>(
                this.props.question1(item.id, item.value)
            ))
        }
       
      }


      backPage=()=>{
        let val
        if (this.state.id_quest >20){
            val= 6
        }else{
            val=4
        }
        this.setState({pages:this.state.pages-1, next: true,
             length: this.state.length - 4,
             id_quest: this.state.id_quest-val,
             back: true})
      }




    render() {
        const {dataQuest} = this.props.dataQuest1;
        const {dataValues} = this.props.dataValues1;
        const {dataSubmit, list_quest,pages, id_quest} = this.state
        const nil = 10

        let temp2 = []
        let second2 = []
        const enbi = dataQuest.map((item, index) => {
            item.questions.map((x) => {
                if(x.choices) {
                    x.choices.map((y) => {
                        second2.push(y.title)
                    })
                    temp2.push(second2)
                }
            })
        })

        const n = this.state.id_quest
        let btn_Next 
        let btn_Prev 
        // if (parseInt(this.state.complete.substr(1)*4) === this.state.list_quest.length && this.state.list_quest.length !== 0 && ((this.state.page *4)== this.state.list_quest.length) ){
        if ( (list_quest.length <= 20 && (list_quest.length !== 0 && ((pages *4)== list_quest.length)) ) && list_quest.length<=50){
            btn_Next=   <button onClick={this.next_quest} style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98", padding:"0px"}}>
                            <p>Next</p>
                        </button>
        } else if ( (id_quest >= 20 && (list_quest.length !== 0 && (((pages *6)-10) == list_quest.length)))&& list_quest.length<=50 ){
            btn_Next=   <button onClick={this.next_quest} style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98", padding:"0px"}}>
                            <p>Next</p>
                        </button>
        }
        else if ((this.state.back == true && (Math.floor(list_quest.length/4) >= pages))){
            btn_Next=   <button onClick={this.next_quest} style={{backgroundColor:"#3B6C98", borderColor:"#3B6C98", padding:"0px"}}>
                            <p>Next</p>
                        </button>
        }else{
            btn_Next=   <button className='p-0' >
                            <p>Next</p>
                        </button>
        }
        
        if (this.state.pages > 1 ){
            btn_Prev =   <button onClick={this.backPage} style={{backgroundColor:"#FFFFFF", borderColor:"#3B6C98", padding:"0px"}}>
                            <p style={{color:"#3B6C98"}}>Back</p>
                        </button>
        }else{
            btn_Prev =   <p style={{width:"90px"}}> </p>
        }

        let range
        if (this.state.pages <= 5){
            range = <LazyLoadImage effect="blur" loading="lazy" alt="" src={pid_4} />
        }else{
            range = <LazyLoadImage effect="blur" loading="lazy" alt="" src={pid_6} />
        }

       
      



        return (
            <div>
                <div className="header_PID width-100">
                <a href="/NewDashboard" style={{margin:"auto"}}>
                    <LazyLoadImage effect="blur" loading="lazy" alt="" src={logo} />
                    </a>
                </div>
                <div className="box-wrapper personality_getStarted PID_Questions qs_" >
                    <div className="wrapper">
                        <div className="content val">
                            <div className="page-container ">
                                <div className="page-container-outer " style={{marginBottom:"50px"}}>
                                    <div className="box_PID">
                                      <div className="flex width-100 columnPID_Mobile columnTablet">

                                        {/* <div className="dekstop"> */}
                                          <div className="width-35 dekstop">
                                              <div className="PID_left">
                                                <div className="progress_profile_PID">
                                                    <h2>Values Profiler</h2>
                                                   
          
                                                    <div className="progressBar_PID">
                                                        <div className="progress-Quest" style={{width:`${((pages -1 )/ (10))* 98}%`}}>
                                                            {pages > 1  &&
                                                            <p>{`${Math.ceil(((pages -1) / (10))* 100)}%`} </p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-Content flex column">
                                                    <div className="flex_Container">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lamp} />
                                                        <p className="title">GUIDE</p>
                                                    </div>
                                                    <div className="width-100" style={{border: "1px solid #F2F2F2", marginBottom:"7px"}} />
                                                    <div className="pid_content_quest">
                                                        <p className="width-100 desc">Order all words on each screen in such a way that all of them describe your values in the best way</p>
                                                        <div className="rangePID">
                                                            <div className="flex space-between width-80">
                                                                {range}
                                                                <div className="pid_range_intro"  />
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={pid_1} />
                                                            </div>
                                                        </div>
                                                        <div className="pid_most_least">
                                                            <p>Most important to me</p>
                                                            <p>Least important to me</p>
                                                        </div>
                                                        <p className="width-100 desc" style={{marginBottom:"143px"}}>If you want to remove the marker from the word, click it again and put the word, click it again and put the marker in another line.</p>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                          {/* </div> */}
                                          <div className="mobile">
                                          <div className="width-35">
                                              <div className="PID_left">
                                                <div className="progress_profile_PID">
                                                    <h2>Values Profiler</h2>
                                                    <div className="progressBar_PID">
                                                        <div className="progress-Quest" style={{width:`${((pages -1 )/ (10))* 98}%`}}>
                                                            {pages > 1  &&
                                                            <p>{`${Math.ceil(((pages -1) / (10))* 100)}%`} </p>}
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                                <div className="box-Content flex column">
                                                <Accordion>
                                                <AccordionSummary
                                                        expandIcon={<LazyLoadImage effect="blur" loading="lazy" alt="" src={ExpandMoreIcon} />}
                                                        // onClick={this.changeRevert}
                                                        id="panel1a-header"
                                                        >
                                                    <div className="flex_Container">
                                                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={lamp} />
                                                        <p className="title">GUIDE</p>
                                                    </div>
                                                    </AccordionSummary>
                                                    <div className="width-100" style={{border: "1px solid #F2F2F2", marginBottom:"7px"}} />
                                                    <div className="pid_content_quest">
                                                        <p className="width-100 desc">Order all words on each screen in such a way that all of them describe your personality in the best way</p>
                                                        <div className="rangePID">
                                                            <div className="flex space-between width-80">
                                                                {range}
                                                                <div className="pid_range_intro"  />
                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={pid_1} />
                                                            </div>
                                                        </div>
                                                        <div className="pid_most_least">
                                                            <p>Most Important to me</p>
                                                            <p>Least important for me</p>
                                                        </div>
                                                        <p className="width-100 desc" style={{marginBottom:"143px"}}>If you want to remove the marker from the word, click it again and put the word, click it again and put the marker in another line.</p>
                                                    </div>
                                                    </Accordion>
                                                </div>
                                               
                                            </div>
                                          </div>
                                          </div>


                                          <div style={{border: "1px solid #F2F2F2", height:"auto"}} />
                                          <div className="width-65">
                                              <div className="box_list_question">
                                                  <div className="flex column center">
                                                      <h5 className="openSans" >QUESTION</h5>
                                                      <h4 className="openSans">Imagine you are looking for your ideal job, how important are these aspects to you?</h4>
                                                      <div className="hr width-100" />
                                                  </div>
                                                 <div className="width-100 flex space-between desc">
                                                     <p>Most Important to me</p>
                                                     <p style={{textAlign:"right"}}>Least important for me</p>
                                                 </div>
                                                 <div className="flex width-100 bar_Mobile"  style={{height:"14px", background: "linear-gradient(90deg, #DDB87A 0%, rgba(221, 184, 122, 0) 100%)"}} 
                                                 />

                                                        <div>
                                                  {(dataValues.data != undefined && dataValues.data.length > 0 ? dataValues.data.map((item3,i)=>{
                                                       const select = this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id);
                                                       const selectedProfile = this.state.list_quest[select] ? this.state.list_quest[select].title : ""
                                                       const selected = this.state.list_quest[select] ? this.state.list_quest[select].value : ""
                                                       const nil1 = this.state.id_quest
                                                       let nil2
                                                       if (this.state.id_quest >= 20){
                                                           nil2 = nil1 + 5
                                                       }else{
                                                           nil2 = nil1 + 3
                                                       }
                                                            if (i<= nil2 && i >= nil1){
                                                            return(
                                                 <div className="PID_item_question" key={i}>
                                                     <p className="title_quest">{item3.question}</p>
                                                     {/* <p className="body_quest">{item3.values}</p> */}
                                                     <div className="choice_quest">
                                                         {this.state.id_quest >= 20 &&
                                                        <button onClick={this.handleChange(item3.id,6, this.state.pages)} style={{
                                                            backgroundColor: this.state.list_quest.findIndex((x, index) => x.title == item3.id && x.value == 6 && x._id == this.state.pages)!== -1? "#DDB87A":"" 
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title !== item3.id  && selectedValue.value ===6) === -1 &&
                                                            (this.state.list_quest.findIndex((selectedValue) => selectedValue._id == this.state.pages &&  selectedValue.value === 6) !== -1) ? "":"#FFFFFF"),
                                                            color: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 6)? "white":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 6) === -1)? "":"#BDBDBD"),
                                                            borderColor: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 6)? "#DDB87A":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 6) === -1)? "":"#BDBDBD"),
                                                            }}>
                                                             <p>6</p>
                                                         </button>}
                                                         {this.state.id_quest >= 20 &&
                                                         <button onClick={this.handleChange(item3.id,5, this.state.pages)} style={{
                                                            backgroundColor: this.state.list_quest.findIndex((x, index) => x.title == item3.id && x.value == 5 && x._id == this.state.pages)!== -1? "#DDB87A":"" 
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title !== item3.id  && selectedValue.value ===5) === -1 &&
                                                            (this.state.list_quest.findIndex((selectedValue) => selectedValue._id == this.state.pages &&  selectedValue.value === 5) !== -1) ? "":"#FFFFFF"),
                                                            color: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 5)? "white":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 5) === -1)? "":"#BDBDBD"),
                                                            borderColor: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 5)? "#DDB87A":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 5) === -1)? "":"#BDBDBD"),
                                                            }}>
                                                             <p>5</p>
                                                         </button>
                                                            }
                                                        <button onClick={this.handleChange(item3.id,4, this.state.pages)} style={{
                                                            backgroundColor: this.state.list_quest.findIndex((x, index) => x.title == item3.id && x.value == 4 && x._id == this.state.pages)!== -1? "#DDB87A":"" 
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title !== item3.id  && selectedValue.value ===4) === -1 &&
                                                            (this.state.list_quest.findIndex((selectedValue) => selectedValue._id == this.state.pages &&  selectedValue.value === 4) !== -1) ? "":"#FFFFFF"),
                                                            color: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 4)? "white":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 4) === -1)? "":"#BDBDBD"),
                                                            borderColor: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 4)? "#DDB87A":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 4) === -1)? "":"#BDBDBD"),
                                                            }}>
                                                             <p>4</p>
                                                         </button>
                                                         <button onClick={this.handleChange(item3.id,3, this.state.pages)} style={{
                                                            backgroundColor: this.state.list_quest.findIndex((x, index) => x.title == item3.id && x.value == 3 && x._id == this.state.pages)!== -1? "#DDB87A":"" 
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title !== item3.id  && selectedValue.value ===3) === -1 &&
                                                            (this.state.list_quest.findIndex((selectedValue) => selectedValue._id == this.state.pages &&  selectedValue.value === 3) !== -1) ? "":"#FFFFFF"),
                                                            color: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 3)? "white":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 3) === -1)? "":"#BDBDBD"),
                                                            borderColor: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 3)? "#DDB87A":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 3) === -1)? "":"#BDBDBD"),
                                                            }}>
                                                             <p>3</p>
                                                         </button>
                                                         <button onClick={this.handleChange(item3.id,2, this.state.pages)} style={{
                                                            backgroundColor: this.state.list_quest.findIndex((x, index) => x.title == item3.id && x.value == 2 && x._id == this.state.pages)!== -1? "#DDB87A":"" 
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title !== item3.id  && selectedValue.value ===2) === -1 &&
                                                            (this.state.list_quest.findIndex((selectedValue) => selectedValue._id == this.state.pages &&  selectedValue.value === 2) !== -1) ? "":"#FFFFFF"),
                                                            color: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 2)? "white":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 2) === -1)? "":"#BDBDBD"),
                                                            borderColor: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 2)? "#DDB87A":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 2) === -1)? "":"#BDBDBD"),
                                                            }}>
                                                             <p>2</p>
                                                         </button>
                                                        <button onClick={this.handleChange(item3.id,1, this.state.pages)} style={{
                                                            backgroundColor: this.state.list_quest.findIndex((x, index) => x.title == item3.id && x.value == 1 && x._id == this.state.pages)!== -1? "#DDB87A":"" 
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title !== item3.id  && selectedValue.value ===1) === -1 &&
                                                            (this.state.list_quest.findIndex((selectedValue) => selectedValue._id == this.state.pages &&  selectedValue.value === 1) !== -1) ? "":"#FFFFFF"),
                                                            color: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 1)? "white":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 1) === -1)? "":"#BDBDBD"),
                                                            borderColor: this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id)!== -1 &&
                                                            (selectedProfile === item3.id && selected === 1)? "#DDB87A":""
                                                            || (this.state.list_quest.findIndex((selectedValue) => selectedValue.title === item3.id) === -1   &&
                                                            (this.state.list_quest.findIndex((selectedValue) =>  selectedValue._id == this.state.pages &&  selectedValue.value === 1) === -1)? "":"#BDBDBD"),
                                                            }}>
                                                             <p>1</p>
                                                         </button>
                                                         
                                                         
                                                        
                                                         
                                                     </div>
                                                     <div className="flex width-100" style={{border: "1px solid #E0E0E0"}} />
                                                 </div>
                                                  )
                                                  }
                                                  }):console.log("null"))}
                                                 
                                                  {/* )
                                                })} */}
                                                </div>
                                           
                                               
                                                 <div className="PID_next">
                                                    {btn_Prev}
                                                    <div className="flex">
                                                        <p className="step">{this.state.pages} </p>
                                                        <p className="step" style={{color:"#BDBDBD", marginLeft:"5px"}}> / {nil}</p>
                                                    </div>
                                                    {btn_Next}
                                                 </div>

                                              </div>
                                          </div>
                                      </div>
                                       
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      dataQuest1 : state.questPID,
      result: state.submitPIDRed,
      dataValues1: state.questValuesRed,
      data : state.putValuesRed,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getQuestPID: (data) =>{
        dispatch(getQuestPID(data))
      },
      getQuestValues: (id)=>{
          dispatch(getQuestValues(id))
      },
      submit1: (result) => {
        dispatch(submitPID(result));
      },
      question1 : (id, scoring)=>{
          dispatch(submitValues(id, scoring));
      }

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(componentName);