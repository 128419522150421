import React, { Component } from 'react';
import onboarding from "../images/onboarding.svg"
import _ from 'lodash';

class Popup_jobDetail extends Component {
    constructor(){
        super();
        this.state={
            selected:1
        }
    }

    render() {
        const {selected} = this.state
        const {closePopup} = this.props
        return (
            <div>
                <div className='popup_onboarding flex column center'>
                    <div className='flex column center wd-552 height-100'>
                    {selected===1 &&
                        <div className='flex flex-end  column align-center height-100'>
                            <img src={onboarding} className="flex" />
                            <p className='DM-Sans-Medium mt-47 mb-14 c-29 s-24'>Welcome to Karya Talents</p>
                            <p className='DM-Sans c-72 s-16 mb-46 mob-52'>We help you to find joy at work by walking alongside you throughout your career journey. Before we go further, please choose what you want to do first</p>
                        </div>}
                   
                    {selected===2 &&
                        <div className='flex column center height-100'>
                            <p className='DM-Sans-Medium mt-47 mb-14 c-29 s-24 mb-48'>What do you want to do first?</p>
                            <div className='flex width-100 space-between wrap mb-48'>
                                <div className='child_bx flex column bg-ff br-10 center mb-24 pointer' onClick={(e)=>closePopup("/JobOpportunities")}>
                                    <svg className='mb-10' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7992 15.5996H35.0996C36.9236 15.5996 38.3996 17.0756 38.3996 18.8996V21.6476L24.2876 26.3516C24.1916 26.3876 24.0956 26.3996 23.9996 26.3996C23.9036 26.3996 23.8076 26.3876 23.7116 26.3516L9.59961 21.6476V18.8996C9.59961 17.0756 11.0756 15.5996 12.8996 15.5996H19.1992V14.4C19.1992 13.0764 20.2756 12 21.5992 12H26.3992C27.7228 12 28.7992 13.0764 28.7992 14.4V15.5996ZM21.5992 15.5996V14.4H26.3992V15.5996H21.5992ZM24.8516 28.0559C24.6356 28.1399 24.3236 28.1999 23.9996 28.1999C23.6756 28.1999 23.3636 28.1399 23.0756 28.0319L9.59961 23.5439V32.6999C9.59961 34.5239 11.0756 35.9999 12.8996 35.9999H35.0996C36.9236 35.9999 38.3996 34.5239 38.3996 32.6999V23.5439L24.8516 28.0559Z" fill="#3B6C98"/>
                                    </svg>
                                    <p className='OpenSans s-14 c-29'>Apply more Jobs</p>
                                </div>
                                <div className='child_bx flex column bg-ff br-10 center mb-24 pointer' onClick={(e)=>closePopup("/work_assessment")}>
                                    <svg className='mb-10' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.8185 20.1617C38.0747 20.2423 38.2759 20.442 38.3589 20.6973C38.4419 20.9527 38.3963 21.2325 38.2367 21.4483L33.1406 28.333L33.2167 36.8983C33.219 37.1668 33.0915 37.4199 32.8742 37.5777C32.7305 37.6822 32.5591 37.7364 32.3859 37.7364C32.2973 37.7364 32.2083 37.7223 32.1222 37.6934L23.9996 34.9743L15.8771 37.6935C15.6225 37.7788 15.3423 37.7357 15.125 37.5778C14.9078 37.42 14.7802 37.1668 14.7826 36.8983L14.8587 28.333L9.76262 21.4483C9.60287 21.2325 9.5573 20.9527 9.64027 20.6974C9.7233 20.442 9.92456 20.2424 10.1807 20.1617L18.3502 17.5873L23.3232 10.6131C23.4791 10.3945 23.7311 10.2646 23.9996 10.2646C24.2681 10.2646 24.5201 10.3945 24.676 10.6131L29.649 17.5873L37.8185 20.1617ZM20.677 21.8027H22.3275L19.7066 29.5125H17.9242L15.3086 21.8027H16.9592L18.4094 26.3906C18.4902 26.6613 18.5729 26.9777 18.6572 27.3398C18.7451 27.6984 18.7996 27.948 18.8207 28.0887C18.8594 27.7652 18.9912 27.1992 19.2162 26.3906L20.677 21.8027ZM23.3031 29.5125V21.8027H24.9379V29.5125H23.3031ZM29.0512 25.4309H28.5133V23.1422H29.2568C29.7033 23.1422 30.0303 23.2336 30.2377 23.4164C30.4486 23.5992 30.5541 23.8822 30.5541 24.2654C30.5541 24.6451 30.4293 24.9352 30.1797 25.1355C29.9301 25.3324 29.5539 25.4309 29.0512 25.4309ZM31.4242 26.1111C31.9445 25.6717 32.2047 25.0371 32.2047 24.2074C32.2047 23.4094 31.9603 22.81 31.4717 22.4092C30.9865 22.0049 30.2764 21.8027 29.3412 21.8027H26.8785V29.5125H28.5133V26.7703H29.2146C30.1709 26.7703 30.9074 26.5506 31.4242 26.1111Z" fill="#3B6C98"/>
                                    </svg>
                                    <p className='OpenSans s-14 c-29'>Take Assessments</p>
                                </div>
                                <div className='child_bx flex column bg-ff br-10 center mb-24 pointer' onClick={(e)=>closePopup("/Referral")}>
                                    <svg className='mb-10' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3268 19.2004L31.166 13.2652C31.442 13.1536 31.7564 13.1872 32.0048 13.3552C32.252 13.5232 32.3996 13.8016 32.3996 14.1004V32.7004C32.3996 32.9992 32.252 33.2776 32.0048 33.4456C31.8536 33.5476 31.676 33.6004 31.4996 33.6004C31.3868 33.6004 31.274 33.5788 31.1648 33.5356L19.0868 28.7044L19.7948 34.9996C19.8236 35.2552 19.742 35.5096 19.5716 35.7004C19.4 35.8912 19.1564 36.0004 18.8996 36.0004H15.2996C14.852 36.0004 14.4728 35.6716 14.4092 35.2276L13.3196 27.6004H12.2996C10.7096 27.6004 9.59961 26.614 9.59961 25.2004V21.9004C9.59961 20.4124 10.8116 19.2004 12.2996 19.2004H16.3268ZM38.0629 18.404L35.0629 20.804C34.8961 20.936 34.6981 21.0008 34.5001 21.0008C34.2361 21.0008 33.9745 20.8844 33.7969 20.6636C33.4861 20.2748 33.5497 19.7084 33.9373 19.3976L36.9373 16.9976C37.3261 16.6892 37.8913 16.7516 38.2033 17.138C38.5141 17.5268 38.4505 18.0932 38.0629 18.404ZM35.0629 27.1969C34.6765 26.8885 34.1101 26.9509 33.7969 27.3373C33.4861 27.7249 33.5497 28.2925 33.9373 28.6033L36.9373 31.0033C37.1041 31.1353 37.3021 31.2001 37.5001 31.2001C37.7641 31.2001 38.0257 31.0837 38.2033 30.8629C38.5141 30.4753 38.4505 29.9077 38.0629 29.5969L35.0629 27.1969ZM34.4996 23.1006H37.4996C37.9964 23.1006 38.3996 23.5038 38.3996 24.0006C38.3996 24.4974 37.9964 24.9006 37.4996 24.9006H34.4996C34.0028 24.9006 33.5996 24.4974 33.5996 24.0006C33.5996 23.5038 34.0028 23.1006 34.4996 23.1006Z" fill="#3B6C98"/>
                                    </svg>
                                    <p className='OpenSans s-14 c-29'>Refer Friends</p>
                                </div>
                                <div className='child_bx flex column bg-ff br-10 center pointer' onClick={(e)=>closePopup("/DigitalLearningPlatform")}>
                                    <svg className='mb-10' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.3419 9.59961V12.6119L23.9991 18.3425L11.6562 12.6119V9.59961L23.9991 16.7996L36.3419 9.59961ZM9.59961 13.7139L23.9996 20.3996L38.3996 13.7139V30.8716C38.3996 31.6946 37.9092 32.4384 37.1528 32.7624L23.9996 38.3996L10.8464 32.7624C10.09 32.4383 9.59961 31.6946 9.59961 30.8716V13.7139Z" fill="#3B6C98"/>
                                    </svg>
                                    <p className='OpenSans s-14 c-29'>Take Online Courses</p>
                                </div>
                                <div className='child_bx flex column bg-ff br-10 center pointer' onClick={(e)=>closePopup("/CareerCenter")}>
                                    <svg className='mb-10' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.0437 21.6444L28.463 18.0655L30.918 15.5395C31.4789 14.9634 31.1569 13.9859 30.3596 13.864L27.0058 13.3487L25.4959 10.1221C25.1698 9.42543 24.0308 9.42543 23.7048 10.1221L22.1948 13.3487L18.841 13.864C18.0411 13.9872 17.7217 14.9634 18.2827 15.5395L20.7376 18.0655L20.1569 21.6444C20.0236 22.4643 20.8947 23.0736 21.6127 22.6736L24.5996 21.0166L27.5879 22.6736C28.3006 23.0723 29.1783 22.4696 29.0437 21.6444ZM21.9586 27.064L18.605 26.5488L17.0951 23.3223C16.7691 22.6256 15.6301 22.6256 15.3041 23.3223L13.7942 26.5488L10.4405 27.064C9.64072 27.1872 9.32132 28.1633 9.88225 28.7395L12.3371 31.2653L11.7564 34.8441C11.6231 35.66 12.4902 36.2759 13.2122 35.8732L16.2002 34.2163L19.1883 35.8732C19.901 36.2706 20.7787 35.6692 20.6441 34.8441L20.0634 31.2653L22.5182 28.7395C23.0778 28.1633 22.7571 27.1872 21.9586 27.064ZM34.2046 26.5487L37.5582 27.064C38.3567 27.1871 38.6774 28.1633 38.1178 28.7394L35.663 31.2652L36.2437 34.8439C36.3783 35.6691 35.5006 36.2704 34.7879 35.8731L31.7998 34.2161L28.8118 35.8731C28.0911 36.2757 27.2227 35.6611 27.356 34.8439L27.9367 31.2652L25.4819 28.7394C24.9209 28.1633 25.2403 27.1871 26.0401 27.064L29.3938 26.5487L30.9037 23.3223C31.2297 22.6256 32.3687 22.6256 32.6947 23.3223L34.2046 26.5487Z" fill="#3B6C98"/>
                                    </svg>
                                    <p className='OpenSans s-14 c-29'>Career Resources</p>
                                </div>
                                <div className='child_bx flex column bg-ff br-10 center pointer' onClick={(e)=>closePopup("/NewDashboard")}>
                                    <p className='OpenSans s-14 c-29'>Explore Karya Talents’ Features</p>
                                </div>
                            </div>
                        </div>}
                        <div className={`flex width-100  ${selected===1?"space-between auto__35":"center auto__48"}`}>
                            {selected===1 &&
                            <div className={`br-20 p-1014 ${selected===1?"mob-none ":""}`} >
                                <p className='c-ff s-14 DM-Sans'>Next</p>
                            </div>}
                            <div className={`flex auto  ${selected===1?"mob-0":""} `}>
                                <svg onClick={(e)=>this.setState({selected:1})} className='m-auto7' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7" cy="7" r="7" fill={selected===1?"#3B6C98":"#D4D4D4"} />
                                </svg>
                                <svg onClick={(e)=>this.setState({selected:2})} className='m-auto7' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7" cy="7" r="7" fill={selected===2?"#3B6C98":"#D4D4D4"}/>
                                </svg>
                            </div>
                            {selected===1 &&
                            <div className='pointer flex  bg-36 br-20 p-1014' onClick={(e)=>this.setState({selected:2})}>
                                <p className='c-ff s-14 DM-Sans'>Next</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup_jobDetail;