import React, { Component } from "react";
import Navbar_Homepage from "../components/Navbar_Homepage";
import artDSR from "../images/artDSR.png";
import bgDSR from "../images/bgDSR.png";
import accent from "../images/dsr_accent.png";
import dsr_bg2 from "../images/dsr_bg2.png";
import dsr_bg3 from "../images/dsr_bg3.png";
import dsr_bg4 from "../images/dsr_bg4.png";
import dsr_bg5 from "../images/dsr_bg5.png";
import dsr_bg6 from "../images/dsr_bg6.png";
import dsr_multi from "../images/dsr_multi.png";
import dsr_ico_bg4 from "../images/dsr_ico_bg4.png";
import dsr_checklist from "../images/dsr_checklist.svg";
import checklist_or from "../images/dsr_checklist_orange.svg";
import Footer from "../components/Footer";
import dsr_accClose from "../images/dsr_accClose.svg";
import dsr_cer1 from "../images/dsr_cer1.svg";
import ScrollToTop from "../components/ScrollToTop";

import dsr_cer2 from "../images/dsr_cer2.svg";
import dsr_cer3 from "../images/dsr_cer3.svg";
import dsr_cer4 from "../images/dsr_cer4.svg";
import fin1 from "../images/dsr_finance1.svg";
import fin2 from "../images/dsr_finance2.svg";
import fin3 from "../images/dsr_finance3.svg";
import fin4 from "../images/dsr_finance4.svg";
import about1 from "../images/dsr_about1.svg";
import about2 from "../images/dsr_about2.svg";
import journey1 from "../images/dsr_journey1.svg";
import journey2 from "../images/dsr_journey2.svg";
import journey3 from "../images/dsr_journey3.svg";
import journey4 from "../images/dsr_journey4.svg";
import journey5 from "../images/dsr_journey5.svg";
import journey6 from "../images/dsr_journey6.svg";
import dsr_trainer1 from "../images/dsr_trainer1.svg";
import dsr_trainer2 from "../images/dsr_trainer2.svg";
import dsr_trainer3 from "../images/dsr_trainer3.svg";
import dsr_ico_grow from "../images/dsr_ico_grow.svg";
import dsr_ico_career from "../images/dsr_ico_career.svg";
import dsr_ico_salary from "../images/dsr_ico_salary.svg";
import dsr_ico_bootcamp from "../images/dsr_ico_bootcamp.svg";
import apply from "../images/dsr_apply.png";
import WhatsAppWidget from "react-whatsapp-widget";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import Pulse from "react-reveal/Pulse";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import { vi } from "date-fns/locale";

class Homepage_DSR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      talents: false,
      resources: false,
      path: ["/", "/jo"],
      transform: "",
      no: 0,
      hiring: 0,
      career: 0,
      no_client: 0,
      id_slide: "",
      list_index: [1, 4, 7, 10],
      before: false,
      after: false,
      dis: false,
      candidates: [],
      clients: [],
      filter: {},
      data_dynamic: [],
      aboutUs: [],
      data_banner: [],
      height_: 0,
      n: 0,
      sal: "",
      br: 0,
      salary: 0,
      loaded: false,
      view: [1, 5],
      view_id: 1,
      bottom: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (parseFloat(winScroll) < 250) {
      this.setState({ br: scrolled });
    }
    if (parseFloat(scrolled) > 0.99) {
      this.setState({ bottom: true });
    }
  };

  setAcc = (e) => {
    const cek1 = this.state.view;
    const n = cek1.findIndex((x) => x == e);
    if (n == -1) {
      cek1.push(e);
    } else {
      cek1.splice(n, 1);
    }
    this.setState({ view: cek1 });
  };

  directToMultimac = (e) => {
    window.location.href =
      "https://forms.office.com/pages/responsepage.aspx?id=3PRlozmRjkia5q6VYspEkVchnlCLsPtNntnylH1nwH1UMVZWUVBPT1pDS1gzWUJCMTBTSklHTks4Vi4u";
  };

  register = (e) => {
    window.location.href = " /registerdsr";
  };

  render() {
    const { height_, br, view, view_id, bottom } = this.state;
    let status = false;
    if (br > 0) {
      status = true;
    }

    const checkId = (e) => {
      let n = false;
      if (view.length > 0 && view.indexOf(e) !== -1) {
        n = true;
      }
      return n;
    };

    return (
      <React.Fragment>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
        </Helmet>
        <div className="flex column width-100 bg-ff alignCenter dsr Hp-22 _s">
          <div
            className="Banner-bx width-100 mb-24 flex hg-762"
            style={{ backgroundImage: `url(${bgDSR})` }}
          >
            <Navbar_Homepage status="bg-ff fsh_" />
            <div
              className="wd-content tr- flex-mob auto height-100 space-between h_"
              id="homepage22"
            >
              <div className="wd-490 flex height-100 h_">
                <div className="text-bx flex column ">
                  <Fade duration={1000} cascade right>
                    <div className="text-bx tr- flex column ">
                      <div className="mt-162 flex" style={{ gap: "1rem" }}>
                        <p className="c-ff flex Kanit-400 s-80">Data</p>
                        <p className="c-ff flex Kanit-400 s-80">Science</p>
                      </div>
                      <p className="c-ff Kanit-600 s-100 w-600 mt--38 stroke_">
                        Rockstar{" "}
                      </p>
                      <p className="c-ff Kanit-400 s-1827 p-620 wd-fit-content br-23 bd-ff mb-14">
                        SELECTED • TRAINED • CERTIFIED • HIRED{" "}
                      </p>
                      <p className="c-ff Kanit-400 s-24 al- k">
                        Pursue Your Professional Data Scientist Career
                      </p>
                      <p className="c-ff Kanit-400 s-24 mb-32 al-">
                        Conquer the Big Data Era!
                      </p>
                      <div
                        className="bg-12  br-24 p-1024 flex pointer mb-10 wd-fit-content"
                        onClick={(e) => this.register(e)}
                      >
                        <span className="c-ff Kanit s-16 let-04 w-600 Montserrat text-cap">
                          REGISTER TODAY
                        </span>
                      </div>
                      <p className="c-ff s-12 Kanit-400">
                        Class Starts in October 2022
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex wd-766 height-100 m-auto00 bg-contain no-repeat bg-position-bottom">
                <Zoom duration={1000}>
                  <img
                    loading="lazy"
                    src={artDSR}
                    onError={(e) => {
                      e.target.src = artDSR;
                    }}
                    className="flex m-auto20 art_"
                  />
                </Zoom>
              </div>
            </div>
          </div>
          <div className="flex column width-100">
            <div className="block width-100  border-dash mt-142" />
            <div className="flex width-100 center">
              <Fade>
                <p className="s-48 Kanit-400 c-09 mt--38 bg-ff p-048">
                  Why Start a
                </p>
              </Fade>
            </div>
            <Fade bottom delay={250}>
              <p className="s-48 flex center width-100 c-d87 w-600 mb-48 _c a w-8">
                Career as Data Scientist
              </p>
            </Fade>
          </div>

          <div className="wd-content a- flex wrap space-between mb-100">
            <Slide bottom>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_grow}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    GROWING DEMAND
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      The Ministry of Communication and Information Technology
                      in Indonesia stated that we need 9 million digital
                      talents.
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Data Scientist have become one of the most in-demand jobs.
                    </li>
                  </ul>
                </div>
              </div>
            </Slide>
            <Fade bottom delay={100}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_salary}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    SKY-HIGH SALARIES
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      The huge demand for data scientists is high while the
                      supply is comparatively low.{" "}
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Companies are willing to offer a high salary for Data
                      Scientist to help them grow.
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={200}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_career}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    CAREER OF TOMORROW
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Businesses are becoming data-driven and innovate every
                      day.
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Data Scientist are essential to help collect and analyze
                      information about the users and offer more personal
                      experience.
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={300}>
              <div className="flex column wd-292 shd-dsr br-30">
                <div className="flex column p-014 wd-fit-content">
                  <img
                    src={dsr_ico_bootcamp}
                    className="flex hg_100 wd-fit content m-48-auto-32"
                  />
                  <p className="Kanit-500 c-29 s-2230 m-auto flex center hg-90 mb-32 hg-90">
                    EFFECTIVE LEARNING THROUGH BOOTCAMP METHOD
                  </p>
                  <ul>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Gain quick upskilling experience in affordable price.
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Get assistance in preparing to launch new careers.
                    </li>
                    <li className="Kanit-300 c-29 s-1618 mb-14">
                      Be at the top of the Data Science field and get hired by
                      top-tier companies
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
          </div>
          <Fade bottom cascade>
            <div
              className="flex width-100 center hg-854"
              style={{ backgroundImage: `url(${dsr_bg2})` }}
            >
              <div className="flex wd-1000 bg-ff br-30 m-auto">
                <div className="p-48 flex-mob width-100 space-between">
                  {/* <Fade bottom duration={900} > */}
                  <div className="flex column wd-480 align-left ">
                    {/* <Fade cascade  bottom  duration={900}> */}
                    <div className="flex column wd-284 mb-14">
                      <p className="s-48_ Kanit-400 c-29">Why</p>
                      <p className="s-48_ Kanit w-600 c-29">
                        Data Science Rockstar
                      </p>
                    </div>
                    {/* </Fade>
                                    <Fade  delay={500}> */}
                    <div className="bg-12  br-24 p-518 flex  mb-48 wd-fit-content">
                      <span className="c-ff Kanit s-16 let-04 w-600 s-48 ">
                        Bootcamp?
                      </span>
                    </div>
                    {/* </Fade>
                                    <Fade delay={200} bottom  duration={800}> */}
                    <p className="Kanit-400 c-29 s-2228 align-just">
                      Data Science Rockstar Bootcamp will open up your doors to
                      become a professional Data scientist. Trained, Guided, and
                      Mentored by professional certified Data Science
                      practitioners, and you'll be a professional data scientist
                      effortlessly in 12 weeks. Join us and you will be the
                      Right Fit for your dream company!
                    </p>
                    {/* </Fade> */}
                  </div>
                  {/* </Fade> */}
                  {/* <Fade delay={200} bottom duration={1000} > */}
                  <div className="flex column wd-352 align-just">
                    <p className="c-29 Kanit w-700 s-2436 mb-24 flex mt-48f">
                      Provide&nbsp;{" "}
                      <p className="c-29 Kanit w-700 s-2436  flex text-un">
                        end-to-end
                      </p>{" "}
                      &nbsp;Solution
                    </p>
                    <p className="Kanit w-600 c-29 s-1624 mb-14 f-">
                      Produce professional Data Science Talents
                    </p>
                    <p className="Kanit-400 c-29 s-1624 mb-24 f-">
                      You will receive intensive data science training for 12
                      weeks and be Trained, Guided, and Mentored by professional
                      certified Data Science practitioners with years of
                      experience.{" "}
                    </p>
                    <p className="Kanit w-600 c-29 s-1624 mb-14 f-">
                      Career Support {"&"} Job Placement Guarantee
                    </p>
                    <p className="Kanit-400 c-29 s-1624 mb-24 f-">
                      We provide full career support to all our Bootcamp
                      participants, starting from the opportunity to do a
                      project demo in front of our hiring partners to the job
                      placement guarantee with one of our hiring partners.
                    </p>
                    <p className="Kanit w-600 c-29 s-1624 mb-14 f-">
                      Continuous Learning Development
                    </p>
                    <p className="Kanit-400 c-29 s-1624 mb-24 f-">
                      After you graduate from our BootCamp, we will continue
                      developing your career progress with our continuing
                      professional development program to equip you with skills
                      that are useful in the workforce.
                    </p>
                  </div>
                  {/* </Fade> */}
                </div>
              </div>
            </div>
          </Fade>

          <div
            className="flex column hg-1415 width-100 center mb-142"
            style={{ backgroundImage: `url(${dsr_bg3})` }}
          >
            <div className="flex column m-auto wd-1144 alignCenter">
              <p className="c-ff s-32 Kanit w-600 mb-48 p-624 bd-3ff br-23 wd-fit-content">
                DATA SCIENCE ROCKSTAR PROVEN CURRICULUM
              </p>
              <div className="width-100 flex-mob tr- space-between mb-48 align-left">
                <Fade cascade left duration={700}>
                  <div className="flex column wd-490">
                    <p className="c-09 Kanit s-32 w-700 width-100 align-center br-14 bg-a3 mb-24">
                      DSR Core Materials
                    </p>
                    <div className="width-100 flex column br-14 bg-ff mb-24">
                      <div className="flex wd-webkit p-24">
                        <img src={dsr_checklist} className="flex wd-42 hg-42" />
                        <p className="c-09 ml-14 Kanit-500 s-2426 flex alignCenter">
                          DATA SCIENCE BASIC
                        </p>
                      </div>
                      <ul
                        className={`flex column  pad-dsr trans--3 ${
                          checkId(1) ? "hg-74 " : "hg-0 block-end"
                        }`}
                      >
                        <li className="Kanit-400 s-1624 c-29">
                          Math {"&"} Statistics
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Programming Python
                        </li>
                        <li className="Kanit-400 s-1624 c-29">SQL Analytics</li>
                      </ul>
                      <div
                        className="flex center wd-webkit bg-d7 p-14 br-014 pointer"
                        onClick={(e) => this.setAcc(1)}
                      >
                        <img
                          src={dsr_accClose}
                          className={`flex trans--3 ${
                            checkId(1) ? "tr-180" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="width-100 flex column br-14 bg-ff mb-24">
                      <div className="flex wd-webkit p-24">
                        <img src={dsr_checklist} className="flex wd-42 hg-42" />
                        <p className="c-09 ml-14 Kanit-500 s-2426 flex alignCenter">
                          DATA SCIENCE INTERMEDIATE
                        </p>
                      </div>
                      <ul
                        className={`flex column  pad-dsr trans--3 ${
                          checkId(2) ? "hg-74 " : "hg-0 block-end"
                        }`}
                      >
                        <li className="Kanit-400 s-1624 c-29">
                          Data Preprocessing
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Data Wranglizing
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Data Visualization
                        </li>
                      </ul>
                      <div
                        className="flex center wd-webkit bg-d7 p-14 br-014 pointer"
                        onClick={(e) => this.setAcc(2)}
                      >
                        <img
                          src={dsr_accClose}
                          className={`flex trans--3 ${
                            checkId(2) ? "tr-180" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="width-100 flex column br-14 bg-ff mb-24">
                      <div className="flex wd-webkit p-24">
                        <img src={dsr_checklist} className="flex wd-42 hg-42" />
                        <p className="c-09 ml-14 Kanit-500 s-2426 flex alignCenter">
                          DATA SCIENCE ADVANCED
                        </p>
                      </div>
                      <ul
                        className={`flex column  pad-dsr trans--3 ${
                          checkId(3) ? "hg-48 " : "hg-0 block-end"
                        }`}
                      >
                        <li className="Kanit-400 s-1624 c-29">
                          Machine Learning
                        </li>
                        <li className="Kanit-400 s-1624 c-29">Deep Learning</li>
                      </ul>
                      <div
                        className="flex center wd-webkit bg-d7 p-14 br-014 pointer"
                        onClick={(e) => this.setAcc(3)}
                      >
                        <img
                          src={dsr_accClose}
                          className={`flex trans--3 ${
                            checkId(3) ? "tr-180" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="width-100 flex column br-14 bg-ff mb-24">
                      <div className="flex wd-webkit p-24">
                        <img src={dsr_checklist} className="flex wd-42 hg-42" />
                        <p className="c-09 ml-14 Kanit-500 s-2426 flex alignCenter">
                          PROJECT
                        </p>
                      </div>
                      <ul
                        className={`flex column  pad-dsr trans--3 ${
                          checkId(4) ? "hg-24 " : "hg-0 block-end"
                        }`}
                      >
                        <li className="Kanit-400 s-1624 c-29">
                          Capstone Project
                        </li>
                      </ul>
                      <div
                        className="flex center wd-webkit bg-d7 p-14 br-014 pointer"
                        onClick={(e) => this.setAcc(4)}
                      >
                        <img
                          src={dsr_accClose}
                          className={`flex trans--3 ${
                            checkId(4) ? "tr-180" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade cascade right duration={700}>
                  <div className="flex column wd-490">
                    <p className="c-09 Kanit s-32 w-700 width-100 align-center br-14 bg-a3 mb-24">
                      Essentials Skills
                    </p>
                    <div className="width-100 flex column br-14 bg-ff mb-24">
                      <div className="flex wd-webkit p-24">
                        <img src={dsr_checklist} className="flex wd-42 hg-42" />
                        <p className="c-09 ml-14 Kanit-500 s-2426 flex alignCenter">
                          DATA SCIENCE PROJECT MANAGEMENT
                        </p>
                      </div>
                      <ul
                        className={`flex column  pad-dsr trans--3 ${
                          checkId(5) ? "hg-96 " : "hg-0 block-end"
                        }`}
                      >
                        <li className="Kanit-400 s-1624 c-29">
                          Project Management Fundamentals And Core Concepts
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Traditional Plan-Based Methodologies
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Agile Frameworks/ Methodologies
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Business Analyst Frameworks
                        </li>
                      </ul>
                      <div
                        className="flex center wd-webkit bg-d7 p-14 br-014 pointer"
                        onClick={(e) => this.setAcc(5)}
                      >
                        <img
                          src={dsr_accClose}
                          className={`flex trans--3 ${
                            checkId(5) ? "tr-180" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="width-100 flex column br-14 bg-ff mb-24">
                      <div className="flex wd-webkit p-24">
                        <img src={dsr_checklist} className="flex wd-42 hg-42" />
                        <p className="c-09 ml-14 Kanit-500 s-2426 flex alignCenter">
                          DATA SCIENCE AGILE PROJECT MANAGEMENT
                        </p>
                      </div>
                      <ul
                        className={`flex column  pad-dsr trans--3  ${
                          checkId(6) ? "hg-120 " : "hg-0 block-end"
                        }`}
                      >
                        <li className="Kanit-400 s-1624 c-29">
                          Agile Way Of Thinking
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Scrum Practices
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Scrum Planning And Estimation
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Monitoring Scrum Projects
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Advance Scrum Projects
                        </li>
                      </ul>
                      <div
                        className="flex center wd-webkit bg-d7 p-14 br-014 pointer"
                        onClick={(e) => this.setAcc(6)}
                      >
                        <img
                          src={dsr_accClose}
                          className={`flex trans--3 ${
                            checkId(6) ? "tr-180" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="width-100 flex column br-14 bg-ff mb-24">
                      <div className="flex wd-webkit p-24">
                        <img src={dsr_checklist} className="flex wd-42 hg-42" />
                        <p className="c-09 ml-14 Kanit-500 s-2426 flex alignCenter">
                          SOFT SKILL 4.0
                        </p>
                      </div>
                      <ul
                        className={`flex column  pad-dsr trans--3 ${
                          checkId(7) ? "hg-144 " : "hg-0 block-end"
                        }`}
                      >
                        <li className="Kanit-400 s-1624 c-29">
                          Business Acumen
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Effective Communication
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Critical Thinking
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          Problem-Solving Skills
                        </li>
                        <li className="Kanit-400 s-1624 c-29">
                          {" "}
                          Creativity {"&"} Innovation
                        </li>
                        <li className="Kanit-400 s-1624 c-29">Teamwork</li>
                      </ul>
                      <div
                        className="flex center wd-webkit bg-d7 p-14 br-014 pointer"
                        onClick={(e) => this.setAcc(7)}
                      >
                        <img
                          src={dsr_accClose}
                          className={`flex trans--3 ${
                            checkId(7) ? "tr-180" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
              <p className="Kanit s-2436 c-ff mb-24">
                International Certifications:
              </p>
              <div className="flex width-100 space-between w_">
                <Fade bottom>
                  <div className="flex column wd-266 center">
                    <img
                      src={dsr_cer1}
                      className="hg-150 wd-fit-content  mb-24"
                    />
                    <p className="Kanit-500 c-12 s-20_ mb4">CDSP</p>
                    <p className="Kanit-400 c-ff s-1624 mb4">
                      (Certified Data Science Practitioner)
                    </p>
                    <p className="Kanit-300 c-ff s-1624">
                      (Issued by CertNexus)
                    </p>
                  </div>
                </Fade>
                <Fade bottom delay={100}>
                  <div className="flex column wd-266 center">
                    <img
                      src={dsr_cer2}
                      className="hg-150 wd-fit-content  mb-24"
                    />
                    <p className="Kanit-500 c-12 s-20_ mb4">PMI PM READY</p>
                    <p className="Kanit-400 c-ff s-1624 mb4">
                      (Project Management)
                    </p>
                    <p className="Kanit-300 c-ff s-1624">
                      (Issued by PMI, USA)
                    </p>
                  </div>
                </Fade>
                <Fade bottom delay={200}>
                  <div className="flex column wd-266 center">
                    <img
                      src={dsr_cer3}
                      className="hg-150 wd-fit-content  mb-24"
                    />
                    <p className="Kanit-500 c-12 s-20_ mb4">
                      AGILE SCRUM FOUNDATION
                    </p>
                    <p className="Kanit-400 c-ff s-1624 mb4">
                      (Product Development)
                    </p>
                    <p className="Kanit-300 c-ff s-1624">
                      (Issued by EXIN, Netherland)
                    </p>
                  </div>
                </Fade>
                <Fade bottom delay={300}>
                  <div className="flex column wd-266 center">
                    <img
                      src={dsr_cer4}
                      className="hg-150 wd-fit-content mb-24"
                    />
                    <p className="Kanit-500 c-12 s-20_ mb4">SOFT SKILL 4.0</p>
                    <p className="Kanit-400 c-ff s-1624 mb4">
                      (Issued by Logical Operation, USA)
                    </p>
                    <p className="Kanit-300 c-ff s-1624"> </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className="block width-100  border-dash" />
          <div className="flex width-100 center column mt--38 mb-24">
            <Fade>
              <p className="s-48 Kanit w-600 c-09  bg-ff p-048 _c">
                Learn from Professional
              </p>
            </Fade>
            <Fade bottom delay={250}>
              <p className="s-48 Kanit-400 c-12  bg-ff p-048">
                Certified Data Scientist
              </p>
            </Fade>
          </div>

          {/*                     
                    <div className='flex width-100 center'>
                    <Fade >
                        <p className='s-48 Kanit w-600 c-09 mt--38 bg-ff p-048'>Learn from Professional</p></Fade>
                    </div>
                    <Fade bottom cascade delay={250}>
                    <p className='s-48 flex center width-100 c-12 Kanit-400 mb-24 _c a'>Certified Data Scientist</p></Fade> */}

          <p className="s-2030 Kanit-500 c-29 p-1024 br-24 bd-209 mb-92">
            Meet Our Trainers:
          </p>
          {/* <Fade left cascade> */}
          <div className="dekstop center width-100">
            <div className="flex center width-100">
              <div className="flex-mob space-between wd-content mb-100 tr-">
                <Fade left delay={500}>
                  <div className="flex column wd-321 alignCenter">
                    <img
                      src={dsr_trainer1}
                      className="flex wd-258 hg-258 br-100 mb-48"
                    />
                    <p className="c-29 s-32 Kanit-500 mb-24 align-center">
                      Tri Agus Setiawan, M.Kom.
                    </p>
                    <p className="s-1618 align-just Kanit-300 c-29 wd-258">
                      Tech Professional who has more than 10 years of experience
                      in IT and Data field. Holds certifications of Microsoft
                      Certified Power BI Data Analyst, Certified Python
                      Programmer, Certified Data Science Practitioner, Certified
                      Artificial Intelligence Practitioner, Enterprise Big Data
                      Professional, CCNA, and SQL Developer.
                    </p>
                  </div>
                </Fade>
                <Fade left delay={300}>
                  <div className="flex column wd-321 alignCenter">
                    <img
                      src={dsr_trainer2}
                      className="flex wd-258 hg-258 br-100 mb-48"
                    />
                    <p className="c-29 s-32 Kanit-500 mb-24 align-center">
                      Dr. Windu Gata, M.Kom.
                    </p>
                    <p className="s-1618 align-just Kanit-300 c-29 wd-258">
                      Tech Guru who is also a veteran IT Consultant, Trainer,
                      and Practitioner with more than 20 years of experience in
                      IT and Data field. His core skills cover Data Science,
                      Data Analytics, Data Mining, Machine Learning, Java
                      Architecture, Programming, and other related skills such
                      as DB Servers, Web Servers, Platform, and Data tools.
                    </p>
                  </div>
                </Fade>
                <Fade left>
                  <div className="flex column wd-321 alignCenter">
                    <img
                      src={dsr_trainer3}
                      className="flex wd-258 hg-258 br-100 mb-48"
                    />
                    <p className="c-29 s-32 Kanit-500 mb-24 align-center">
                      Dr. Agus Setiawan
                    </p>
                    <p className="s-1618 align-just Kanit-300 c-29 wd-258">
                      Result-oriented Director and Accredited trainer with more
                      than 25 years of work experience and extensive involvement
                      in Digital Transformation, IT Management, EGRC, and Agile
                      Project Management field. An Accredited Trainer for
                      PRINCE2, Agile Scrum and CRM, and holds certifications of
                      Agile Project Management, Project Management Ready, COBIT
                      5, ITIL V3, etc.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className="mobile width-100">
            <div className="flex center width-100">
              <div className="flex-mob space-between wd-content mb-100 tr-">
                <Fade left>
                  <div className="flex column wd-321 alignCenter">
                    <img
                      src={dsr_trainer1}
                      className="flex wd-258 hg-258 br-100 mb-48"
                    />
                    <p className="c-29 s-32 Kanit-500 mb-24 align-center">
                      Tri Agus Setiawan, M.Kom.
                    </p>
                    <p className="s-1618 align-just Kanit-300 c-29 wd-258">
                      Tech Professional who has more than 10 years of experience
                      in IT and Data field. Holds certifications of Microsoft
                      Certified Power BI Data Analyst, Certified Python
                      Programmer, Certified Data Science Practitioner, Certified
                      Artificial Intelligence Practitioner, Enterprise Big Data
                      Professional, CCNA, and SQL Developer.
                    </p>
                  </div>
                </Fade>
                <Fade left delay={300}>
                  <div className="flex column wd-321 alignCenter">
                    <img
                      src={dsr_trainer2}
                      className="flex wd-258 hg-258 br-100 mb-48"
                    />
                    <p className="c-29 s-32 Kanit-500 mb-24 align-center">
                      Dr. Windu Gata, M.Kom.
                    </p>
                    <p className="s-1618 align-just Kanit-300 c-29 wd-258">
                      Tech Guru who is also a veteran IT Consultant, Trainer,
                      and Practitioner with more than 20 years of experience in
                      IT and Data field. His core skills cover Data Science,
                      Data Analytics, Data Mining, Machine Learning, Java
                      Architecture, Programming, and other related skills such
                      as DB Servers, Web Servers, Platform, and Data tools.
                    </p>
                  </div>
                </Fade>
                <Fade left delay={500}>
                  <div className="flex column wd-321 alignCenter">
                    <img
                      src={dsr_trainer3}
                      className="flex wd-258 hg-258 br-100 mb-48"
                    />
                    <p className="c-29 s-32 Kanit-500 mb-24 align-center">
                      Dr. Agus Setiawan
                    </p>
                    <p className="s-1618 align-just Kanit-300 c-29 wd-258">
                      Result-oriented Director and Accredited trainer with more
                      than 25 years of work experience and extensive involvement
                      in Digital Transformation, IT Management, EGRC, and Agile
                      Project Management field. An Accredited Trainer for
                      PRINCE2, Agile Scrum and CRM, and holds certifications of
                      Agile Project Management, Project Management Ready, COBIT
                      5, ITIL V3, etc.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          {/* </Fade> */}
          <div
            className="flex width-100 center hg-656 mb-142 alignCenter"
            style={{ backgroundImage: `url(${dsr_bg4})` }}
          >
            <div className="flex-mob wd-1200  space-between m-auto">
              <Fade bottom left cascade>
                <div className="wd-365 flex column flex-end align-left">
                  <img
                    src={dsr_ico_bg4}
                    className="flex mb-32 wd-fit-content"
                  />
                  <p className="s-48_ Kanit-400 c-ff">
                    Earn A Lifetime Benefits For Your
                  </p>
                  <p className="s-48_ Kanit w-600 c-ff">Career Success</p>
                </div>
              </Fade>
              <div className="flex-mob c wd-fit-content">
                <Fade cascade right duration={1050}>
                  <div className="flex column wd-320">
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        1
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Job Placement Guarantee
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        2
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Equipped with Soft Skills 4.0
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        3
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Career Support
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        4
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Connected with Karya Talents’ Network
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        5
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Demo Day in Front of Hiring Partners
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade cascade bottom duration={1000}>
                  <div className="flex column wd-320 ml-14">
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        6
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Relevant Data Science Training Curriculum
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        7
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        International Certification
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        8
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Multimatics Online Learning Platform Free Access
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left mb-14 hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        9
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14">
                        Continuous Professional Development
                      </p>
                    </div>
                    <div className="flex wd-webkit bg-ff br-20 p-20 align-left hgc-80">
                      <div className="wd-42 hg-42 br-100 bg-12 c-ff Kanit w-700 center flex s-20">
                        10
                      </div>
                      <p className="Kanit-500 s-20_ c-29 ml-14 flex">
                        Money Back Guarantee{" "}
                        <p className="Kanit-300 s-1420 c-29 contents_">
                          (t{"&"}c applied){" "}
                        </p>
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className="block width-100  border-dash" />
          <div className="flex width-100 center">
            <Fade>
              <p className="s-48 Kanit w-600 c-09 mt--38 bg-ff p-048">
                Program
              </p>
            </Fade>
          </div>
          <Fade bottom delay={250}>
            <p className="s-48 flex center width-100 c-12 Kanit-400 mb-24 _c a">
              Journey
            </p>
          </Fade>
          <div className="flex width-100 center br-">
            <div className="none o">
              <div className="flex">
                <div className="line- bg-09 wd-6 hg-962 mt-19" />
                <div className="flex column ml--19">
                  <Fade>
                    <img src={checklist_or} className="flex wd-38 hg-38" />
                  </Fade>
                  <Fade delay={100}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-94"
                    />
                  </Fade>
                  <Fade delay={150}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-200"
                    />
                  </Fade>
                  <Fade delay={200}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-166"
                    />
                  </Fade>
                  <Fade delay={250}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-110"
                    />
                  </Fade>
                  <Fade delay={300}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-202"
                    />
                  </Fade>
                </div>
              </div>
            </div>
            <div className="flex wd-666 space-between mb-100">
              <div className="flex column wd-250 alignCenter ">
                <Fade cascade left>
                  <div className="flex column alignCenter width-100 mb-100">
                    <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                      <img src={journey1} className="flex m-auto wd-74 hg-74" />
                    </div>
                    <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                      ASSESSMENT
                    </p>
                    <ul className="flex column align-left pad-inline-20">
                      <li className="s-1618 Kanit-400 c-3a">
                        Technical Skills Assessment
                      </li>
                      <li className="s-1618 Kanit-400 c-3a">
                        Soft Skills Assessment
                      </li>
                      <li className="s-1618 Kanit-400 c-3a">
                        EQ Growth Mindset Assessment
                      </li>
                    </ul>
                  </div>
                </Fade>
                <div className="none">
                  <Fade cascade right delay={100}>
                    <div className="flex column alignCenter width-100 mb-100">
                      <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                        <img
                          src={journey4}
                          className="flex m-auto wd-74 hg-74"
                        />
                      </div>
                      <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                        TRAINING
                      </p>
                      <p className="s-1618 Kanit-400 c-3a align-left">
                        Talents will deepen their skill and understanding about:
                      </p>
                      <ul className="flex column align-left pad-inline-20">
                        <li className="s-1618 Kanit-400 c-3a">Data Science</li>
                        <li className="s-1618 Kanit-400 c-3a">
                          Agile Framework
                        </li>
                        <li className="s-1618 Kanit-400 c-3a">
                          Project Management
                        </li>
                        <li className="s-1618 Kanit-400 c-3a">
                          Soft Skills 4.0
                        </li>
                      </ul>
                    </div>
                  </Fade>
                </div>
                <Fade cascade left delay={150}>
                  <div className="flex column center width-100 mb-100">
                    <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                      <img src={journey2} className="flex m-auto wd-74 hg-74" />
                    </div>
                    <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                      CAPSTONE PROJECT
                    </p>
                    <p className="s-1618 Kanit-400 c-3a align-left cl_">
                      Talents will be given a certain project relating to data
                      science
                    </p>
                  </div>
                </Fade>
                <div className="none">
                  <Fade cascade right delay={200}>
                    <div className="flex column center width-100 mb-100">
                      <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                        <img
                          src={journey5}
                          className="flex m-auto wd-74 hg-74"
                        />
                      </div>
                      <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                        PROJECT DEMO
                      </p>
                      <p className="s-1618 Kanit-400 c-3a align-left">
                        Talents will present their project in front of our
                        hiring partners which can be tailored to the talent’s
                        industry preferences
                      </p>
                    </div>
                  </Fade>
                </div>
                <Fade cascade left delay={250}>
                  <div className="flex column center width-100 ">
                    <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                      <img src={journey3} className="flex m-auto wd-74 hg-74" />
                    </div>
                    <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                      GRADUATION
                    </p>
                    <p className="s-1618 Kanit-400 c-3a align-left cl_">
                      Graduation will be held after the talents sucessfully
                      passed all the phases in Data Science Rockstar program
                    </p>
                  </div>
                </Fade>
                <div className="none">
                  <Fade cascade right delay={270}>
                    <div className="flex column center width-100 ">
                      <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                        <img
                          src={journey6}
                          className="flex m-auto wd-74 hg-74"
                        />
                      </div>
                      <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                        PLACEMENT
                      </p>
                      <p className="s-1618 Kanit-400 c-3a align-left">
                        Armed with our hiring partners, our team will help you
                        find your dream job!
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex mt-45">
                <div className="line- bg-09 wd-6 hg-962 mt-19" />
                <div className="flex column ml--19">
                  <Fade>
                    <img src={checklist_or} className="flex wd-38 hg-38" />
                  </Fade>
                  <Fade delay={100}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-94"
                    />
                  </Fade>
                  <Fade delay={150}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-200"
                    />
                  </Fade>
                  <Fade delay={200}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-166"
                    />
                  </Fade>
                  <Fade delay={250}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-110"
                    />
                  </Fade>
                  <Fade delay={300}>
                    <img
                      src={checklist_or}
                      className="flex wd-38 hg-38 mt-202"
                    />
                  </Fade>
                </div>
              </div>
              <div className="flex column wd-250 alignCenter mt-132">
                <Fade cascade right delay={100}>
                  <div className="flex column alignCenter width-100 mb-100">
                    <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                      <img src={journey4} className="flex m-auto wd-74 hg-74" />
                    </div>
                    <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                      TRAINING
                    </p>
                    <p className="s-1618 Kanit-400 c-3a align-left">
                      Talents will deepen their skill and understanding about:
                    </p>
                    <ul className="flex column align-left pad-inline-20">
                      <li className="s-1618 Kanit-400 c-3a">Data Science</li>
                      <li className="s-1618 Kanit-400 c-3a">Agile Framework</li>
                      <li className="s-1618 Kanit-400 c-3a">
                        Project Management
                      </li>
                      <li className="s-1618 Kanit-400 c-3a">Soft Skills 4.0</li>
                    </ul>
                  </div>
                </Fade>
                <Fade cascade right delay={200}>
                  <div className="flex column center width-100 mb-100">
                    <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                      <img src={journey5} className="flex m-auto wd-74 hg-74" />
                    </div>
                    <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                      PROJECT DEMO
                    </p>
                    <p className="s-1618 Kanit-400 c-3a align-center">
                      Talents will present their project in front of our hiring
                      partners which can be tailored to the talent’s industry
                      preferences
                    </p>
                  </div>
                </Fade>
                <Fade cascade right delay={270}>
                  <div className="flex column center width-100 ">
                    <div className="flex wd-128 hg-128 mb-24 br-100 bg-f3 shd-14">
                      <img src={journey6} className="flex m-auto wd-74 hg-74" />
                    </div>
                    <p className="mb-24 c-ff br-24 p-1024 bg-09 Kanit-500 s-20_">
                      PLACEMENT
                    </p>
                    <p className="s-1618 Kanit-400 c-3a align-center">
                      Armed with our hiring partners, our team will help you
                      find your dream job!
                    </p>
                  </div>
                </Fade>
              </div>
            </div>{" "}
          </div>
          <div className="flex column width-100 alignCenter bg-ec hg-622 mb-100">
            <div className="flex column m-auto wd-1000 alignCenter">
              <p className="Kanit-500 c-09 s-3654 center width-100 wrap flex mb-50">
                Minimum &nbsp;{" "}
                <p className="Kanit-500 c-09 s-36_ align-center text-un">
                  2 years of working experience{" "}
                </p>
                &nbsp; in &nbsp;
                <p className="Kanit w-600 c-09 s-3654 align-center stroke_">
                  IT fields {"&"} Non-IT{" "}
                </p>
                &nbsp; educational background needed
              </p>
              <div className="flex-mob wd-webkit width-100 bg-ff br-30 p-48 shd-6 space-between align-left f- alignCenter">
                <div className="flex column wd-360">
                  <Fade bottom cascade>
                    <p className="Kanit w-600 s-40_ width-100 block wrap mb-14">
                      Data Science Rockstar{" "}
                      <span className="Kanit w-600 s-40_ c-12">Bootcamp</span>
                    </p>
                  </Fade>
                  <Fade bottom delay={200}>
                    <p className="Kanit-500 s-20 c-09 br-25 bd-09 mb6 p-420 f- wd-fit-content ds-">
                      Class Starts in October 2022
                    </p>
                  </Fade>
                  <Fade delay={200} bottom>
                    <p className="Kanit-300 c-29 s-20 v-72 f-">
                      160 Training Hours in 12 Weeks
                    </p>
                  </Fade>
                </div>
                <Fade bottom cascade>
                  <div className="flex column wd-472">
                    <div className="flex mb-10">
                      <img src={checklist_or} className="flex wd-24 hg-24" />
                      <p className="ml8 s-1628 c-29 Kanit-300">
                        3 Meetings in a weeks at 19:30 - 21:30
                      </p>
                    </div>
                    <div className="flex mb-10">
                      <img src={checklist_or} className="flex wd-24 hg-24" />
                      <p className="ml8 s-1628 c-29 Kanit-300">
                        2 Meetings on Saturday in a month at 09:00 - 16:00
                      </p>
                    </div>
                    <div className="flex mb-10">
                      <img src={checklist_or} className="flex wd-24 hg-24" />
                      <p className="ml8 s-1628 c-29 Kanit-300">
                        Hybrid Training Method : Online, Offline and Video
                        Learning
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <p className="flex c-09 Kanit-400 s-48 mb-48 n">
            Flexible{" "}
            <p className="Kanit w-700 contents_ c-12 s-48 stroke_">
              &nbsp;Payment{" "}
            </p>
          </p>
          <Fade bottom duration={800}>
            <div className="bg-ff wd-1000 br-30 shd-6 mb-48">
              <div className="flex column wd-webkit p-48 alignCenter m-">
                <p className="Kanit w-600 s-64">Rp25.000.000</p>
                <Fade left cascade>
                  <p className="c-ff s-2628 p-1024 br-24 shd-4 mb-48 bg-09">
                    12 Weeks
                  </p>
                </Fade>
                <Fade delay={350}>
                  <p className="Kanit-500 s-24 c-29 mb-24 q">
                    3 Meetings/week @3 Hours | 18:30 - 21:30 | Full Days
                    Meetings*
                  </p>
                </Fade>
                <Fade bottom cascade duration={900}>
                  <div className="width-100 flex column align-left">
                    <div className="flex mb-10 align-left">
                      <img src={checklist_or} className="flex wd-24 hg-24" />
                      <p className="s-1628 Kanit-400 c-29 ml8">
                        You'll get a 5% discount if you get a job placement
                        through our Karya Talents team!
                      </p>
                    </div>
                    <div className="flex align-left">
                      <img src={checklist_or} className="flex wd-24 hg-24" />
                      <p className="s-1628 Kanit-400 c-29 ml8">
                        You'll Get another 5% discount if your attendance
                        percentage and assignment grades during the training are
                        excellent!
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <img
                src={accent}
                className="accent wd-147 flex contain absolute left hg-151"
              />
              <img
                src={accent}
                className="accent wd-147 flex contain absolute right hg-151"
              />
            </div>
          </Fade>
          <p className="Kanit-500 s-32 c-09 mb-24 w-8">
            Get numbers of flexible financing offer
          </p>
          <div className="flex space-between wrap wd-806 mb-100">
            <Fade right>
              <div className="flex column wd-126 center">
                <img src={fin1} className="flex hg-78 wd-fit-content mb-24" />
                <p className="c-09 s-20_ Kanit-500 mb4">
                  Full Advances Payment
                </p>
                <p className="Kanit-300 s-1420 c-09">(With 5% Discount)</p>
              </div>
            </Fade>
            <Fade right delay={100}>
              <div className="flex column wd-126 center">
                <img src={fin4} className="flex hg-78 wd-fit-content mb-24" />
                <p className="c-09 s-20_ Kanit-500 mb4">Partial Payment 3X</p>
                <p className="Kanit-300 s-1420 c-09"></p>
              </div>
            </Fade>
            <Fade right delay={300}>
              <div className="flex column wd-126 center">
                <img src={fin3} className="flex hg-78 wd-fit-content mb-24" />
                <p className="c-09 s-20_ Kanit-500 mb4">
                  Income Share Agreement (ISA)
                </p>
                <p className="Kanit-300 s-1420 c-09"></p>
              </div>
            </Fade>
            <Fade right delay={400}>
              <div className="flex column wd-126 center">
                <img src={fin2} className="flex hg-78 wd-fit-content mb-24" />
                <p className="c-09 s-20_ Kanit-500 mb4">Paylater*</p>
                <p className="Kanit-300 s-1420 c-09"></p>
              </div>
            </Fade>
          </div>
          <div
            className="flex column width-100 hg-460 mb-100 "
            style={{ backgroundImage: `url(${dsr_bg5})` }}
          >
            <div className="flex-mob wd-1248 m-auto space-between m-17">
              <div className="flex column wd-712 align-left">
                <Fade bottom>
                  <p className="Kanit-400 flex wrap s-48_ mb-24 width-100 c-ff">
                    Ready To Take Your
                    <p className="Kanit w-700  s-48_ stroke_ contents_">
                      {" "}
                      Data Science{" "}
                    </p>
                    Career To The Next Level?
                  </p>
                </Fade>
                <Fade bottom delay={200} duration={1200}>
                  <div className="flex column wd-webkit">
                    <p className="Kanit-400 s-2436 c-ff">
                      Become Data Scientist Professional effortlessly in 12
                      weeks. Join us and you will be the Right Fit for your
                      dream company!
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="flex column wd-202 center ">
                <Fade bottom delay={300} cascade>
                  <div className="flex wd-webkit">
                    <p
                      className="Kanit w-600 s-20 c-ff bg-09 br-26 mb-14 p-1024 wd-fit-content pointer re- multi"
                      onClick={(e) => this.register(e)}
                    >
                      REGISTER TODAY
                    </p>
                  </div>
                </Fade>
                <Fade bottom delay={500}>
                  <p className="Kanit-500 s-1827 c-ff">
                    {" "}
                    Limited to 25 seats only!
                  </p>
                </Fade>
              </div>
            </div>
          </div>
          <div
            className="flex column wd-1240 mb-100 br-30 hg-550"
            style={{ backgroundImage: `url(${dsr_bg6})` }}
          >
            <div className="flex column wd-530 align-left m-auto-161">
              <img src={dsr_ico_bg4} className="flex mb-32 wd-fit-content" />
              <Fade delay={200}>
                <p className="Kanit w-600 s-48_ c-ff mb-32 flex wrap">
                  Looking for{" "}
                  <p className="Kanit w-600 s-48_ c-ff  contents_">
                    {" "}
                    Professional Data Scientists?{" "}
                  </p>
                </p>
              </Fade>
              <Fade delay={400}>
                <p className="Kanit-400 s-2436 c-ff mb-48">
                  Be Our Hiring Partners! Our Data Science Rockstar Graduates
                  Are Ready to Rock Your Organizations!
                </p>
              </Fade>
              <a
                href="https://forms.office.com/pages/responsepage.aspx?id=3PRlozmRjkia5q6VYspEkVchnlCLsPtNntnylH1nwH1UMVZWUVBPT1pDS1gzWUJCMTBTSklHTks4Vi4u"
                target="_blank"
                className="be-hr c-09 Kanit w-600 s-20 bg-a3 br-26 pointer p-1024 wd-fit-content"
              >
                BECOME HIRING PARTNERS
              </a>
            </div>
          </div>

          <div className="flex width-100 center">
            <Fade bottom>
              <p className="c-d87 s-48 Kanit-400">List of Multimatics’</p>
            </Fade>
          </div>
          <Fade bottom delay={250}>
            <p className="s-48 flex center width-100 c-09 Kanit w-600 mb-48 w-8 _c a multi">
              Data Science Training Clients
            </p>
          </Fade>
          <img src={dsr_multi} className="wd-1240 flex mb-136" />
          <div className="block width-100  border-dash" />
          <div className="flex width-100 center column mt--38 mb-48">
            <Fade bottom delay={100}>
              <p className="s-48 Kanit w-600 c-09  bg-ff p-048">About Us</p>
            </Fade>
          </div>
          <div className="flex-mob space-between wd-1240 ab_ mb-100">
            <Fade left duration={800} delay={200}>
              <div className="flex column wd-600 shd-dsr br-30 bg-ff">
                <div className="flex column wd-webkit p-32 alignCenter">
                  <img src={about1} className="wd-406 hg-180 mb4" />
                  <p className="Kanit-400 s-1624 c-29 align-just">
                    Karya Talents customized recruitment solution will help your
                    company find “The Right Fit” Talents. We assess and match
                    each talent according to your organization's needs to ensure
                    they did not only have the particular skills and
                    competencies to perform the job, but also possess great
                    self-awareness that will help your company hire contributors
                    who are highly engaged and productive.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade right duration={800} delay={200}>
              <div className="flex column wd-600 shd-dsr br-30 bg-ff">
                <div className="flex column wd-webkit p-32 alignCenter">
                  <img src={about2} className="wd-406 hg-180 mb4" />
                  <p className="Kanit-400 s-1624 c-29 align-just">
                    Multimatics is the most growing company focusing on Digital
                    Innovation Strategy. We offer Consultancy, Training and
                    Certification services through experienced and certified
                    consultants and instructors. We come with a mission to
                    deliver greater impact by Turning Knowledge into
                    performance.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="flex width-100 bg-e6 alignCenter p-48">
            <div className="flex wd-1192 m-auto">
              <p className="Kanit-300 s-1624 c-29 align-just">
                How we go the extra mile to ensure your companies are hiring the
                right person is through these processes. We assess the talents
                to ensure they have the necessary skills, competencies,
                emotional intelligence, and growth mindset to perform the job.
                We use a unique algorithm based on skills, experience, values,
                interest, and personality to match the best fit talents
                according to your organization’s needs. Not only that, we will
                provide insights on each talent through a candidate profile
                report and interview summary about their skills, competencies,
                personality, work values, so that both the companies and talents
                will have a great self-awareness of the talents.
              </p>
            </div>
          </div>
          <div className="flex width-100 Hp-22 column m">
            <img
              src={apply}
              className="fixed flex wd-145 hg-42 dsr-app pointer"
              onClick={(e) => (window.location.href = " /registerdsr")}
            />
            <WhatsAppWidget
              phoneNumber="6287717888800"
              companyName="Karya Talents"
            />
            <div className="flex">
              <div className="sticky br-30 flex column wd-fit-content p-10 space-between">
                <a
                  href="https://www.linkedin.com/company/karya-talents"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM8.2 13.3V24H11.6V13.3H8.2ZM8 9.9C8 11 8.8 11.8 9.9 11.8C11 11.8 11.8 11 11.8 9.9C11.8 8.8 11 8 9.9 8C8.9 8 8 8.8 8 9.9ZM20.6 24H23.8V17.4C23.8 14.1 21.8 13 19.9 13C18.2 13 17 14.1 16.7 14.8V13.3H13.5V24H16.9V18.3C16.9 16.8 17.9 16 18.9 16C19.9 16 20.6 16.5 20.6 18.2V24Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://instagram.com/karyatalents?igshid=odlswb4q0a06"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4 9.2H12.6C11.8 9.3 11.4 9.4 11.1 9.5C10.7 9.6 10.4 9.8 10.1 10.1C9.86261 10.3374 9.75045 10.5748 9.61489 10.8617C9.57916 10.9373 9.5417 11.0166 9.5 11.1C9.48453 11.1464 9.46667 11.1952 9.44752 11.2475C9.34291 11.5333 9.2 11.9238 9.2 12.6V19.4C9.3 20.2 9.4 20.6 9.5 20.9C9.6 21.3 9.8 21.6 10.1 21.9C10.3374 22.1374 10.5748 22.2495 10.8617 22.3851C10.9374 22.4209 11.0165 22.4583 11.1 22.5C11.1464 22.5155 11.1952 22.5333 11.2475 22.5525C11.5333 22.6571 11.9238 22.8 12.6 22.8H19.4C20.2 22.7 20.6 22.6 20.9 22.5C21.3 22.4 21.6 22.2 21.9 21.9C22.1374 21.6626 22.2495 21.4252 22.3851 21.1383C22.4209 21.0626 22.4583 20.9835 22.5 20.9C22.5155 20.8536 22.5333 20.8048 22.5525 20.7525C22.6571 20.4667 22.8 20.0762 22.8 19.4V12.6C22.7 11.8 22.6 11.4 22.5 11.1C22.4 10.7 22.2 10.4 21.9 10.1C21.6626 9.86261 21.4252 9.75045 21.1383 9.61488C21.0627 9.57918 20.9833 9.54167 20.9 9.5C20.8536 9.48453 20.8048 9.46666 20.7525 9.44752C20.4667 9.3429 20.0762 9.2 19.4 9.2ZM16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM21.4 11.6C21.4 12.1523 20.9523 12.6 20.4 12.6C19.8477 12.6 19.4 12.1523 19.4 11.6C19.4 11.0477 19.8477 10.6 20.4 10.6C20.9523 10.6 21.4 11.0477 21.4 11.6Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM12.6 7.7H19.4C20.3 7.8 20.9 7.9 21.4 8.1C22 8.4 22.4 8.6 22.9 9.1C23.4 9.6 23.7 10.1 23.9 10.6C24.1 11.1 24.3 11.7 24.3 12.6V19.4C24.2 20.3 24.1 20.9 23.9 21.4C23.6 22 23.4 22.4 22.9 22.9C22.4 23.4 21.9 23.7 21.4 23.9C20.9 24.1 20.3 24.3 19.4 24.3H12.6C11.7 24.2 11.1 24.1 10.6 23.9C10 23.6 9.6 23.4 9.1 22.9C8.6 22.4 8.3 21.9 8.1 21.4C7.9 20.9 7.7 20.3 7.7 19.4V12.6C7.8 11.7 7.9 11.1 8.1 10.6C8.4 10 8.6 9.6 9.1 9.1C9.6 8.6 10.1 8.3 10.6 8.1C11.1 7.9 11.7 7.7 12.6 7.7Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://web.facebook.com/KT300CLUB/?_rdc=1&_rdr"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16 8C20.4 8 24 11.6 24 16C24 20 21.1 23.4 17.1 24V18.3H19L19.4 16H17.2V14.5C17.2 13.9 17.5 13.3 18.5 13.3H19.5V11.3C19.5 11.3 18.6 11.1 17.7 11.1C15.9 11.1 14.7 12.2 14.7 14.2V16H12.7V18.3H14.7V23.9C10.9 23.3 8 20 8 16C8 11.6 11.6 8 16 8Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCAXhjK2OivKsLeYPVNMsLHA"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M18.6 16L14.4 13.6V18.4L18.6 16Z" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM22.2 10.7C22.9 10.9 23.4 11.4 23.6 12.1C24 13.4 24 16 24 16C24 16 24 18.6 23.7 19.9C23.5 20.6 23 21.1 22.3 21.3C21 21.6 16 21.6 16 21.6C16 21.6 10.9 21.6 9.7 21.3C9 21.1 8.5 20.6 8.3 19.9C8 18.6 8 16 8 16C8 16 8 13.4 8.2 12.1C8.4 11.4 8.90001 10.9 9.60001 10.7C10.9 10.4 15.9 10.4 15.9 10.4C15.9 10.4 21 10.4 22.2 10.7Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <ScrollToTop/> */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Homepage_DSR;
