import {GET_DOCUMENT, GET_DOCUMENT_ID, PUT_DOCUMENT, DELETE_DOCUMENT, POST_DOCUMENT, GET_DOCUMENT_PENDUKUNG } from "../config/constants";
  const initialState = {
    document:[],
    documentId:[],
    deleteDocument:[],
    postDocument:[],
    putDocument:[],
    pendukung:[],
    isLoading: false,
    error: false,
  };
  
  export const getDocumentRed = (state = initialState, action) => {
    switch (action.type) { 
      case `${GET_DOCUMENT}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_DOCUMENT}_FULFILLED`:
        return {
          ...state,
          document: action.payload.data,
          isLoading: false,
        };
      case `${GET_DOCUMENT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const getDocumentIdRed = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_DOCUMENT_ID}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_DOCUMENT_ID}_FULFILLED`:
        return {
          ...state,
          documentId: action.payload.data,
          isLoading: false,
        };
      case `${GET_DOCUMENT_ID}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const documentPendukung = (state = initialState, action) => {
    switch (action.type) {
      case `${GET_DOCUMENT_PENDUKUNG}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${GET_DOCUMENT_PENDUKUNG}_FULFILLED`:
        return {
          ...state,
          pendukung: action.payload.data,
          isLoading: false,
        };
      case `${GET_DOCUMENT_PENDUKUNG}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const postDocumentRed = (state = initialState, action) => {
    switch (action.type) {
      case `${POST_DOCUMENT}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${POST_DOCUMENT}_FULFILLED`:
        return {
          ...state,
          postDocument: action.payload.data,
          isLoading: false,
        };
      case `${POST_DOCUMENT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const putDocumentRed = (state = initialState, action) => {
    switch (action.type) {
      case `${PUT_DOCUMENT}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${PUT_DOCUMENT}_FULFILLED`:
        return {
          ...state,
          putDocument: action.payload.data,
          isLoading: false,
        };
      case `${PUT_DOCUMENT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };

  export const delDocumentRed = (state = initialState, action) => {
    switch (action.type) {
      case `${DELETE_DOCUMENT}_PENDING`:
        return {
          ...state,
          isLoading: true,
        };
      case `${DELETE_DOCUMENT}_FULFILLED`:
        return {
          ...state,
          deleteDocument: action.payload.data,
          isLoading: false,
        };
      case `${DELETE_DOCUMENT}_REJECTED`:
        return {
          ...state,
          error: true,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  
  