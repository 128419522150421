import React from 'react';
import Burger from './Burger';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import karya from '../images/logo-KT-color.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Nav = styled.nav`
    height: 81px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;

    @media (min-width: 1441px) {
        width: 80%;
        margin-left: 10%;
    }

    @media (min-width: 889px) and (max-width:1440px) {
        width: 85%;
        margin-left: 7%;
    }
`;

export const Talents = <div className="flex width-100 new_req_header hg-33 bg-36 fixed top-0 z-i99 " >
                            <h1>Talent placement service Indonesia</h1>
                        </div>


const NavBar = (props) => {
  return (
    <div className="flex width-100 column fixed  z-i99 top-0 bg-ff"  >
        {/* {Talents} */}
        <div className="header-home unset" >
        <Nav>
            <div>
                <NavLink to="/">
                    <LazyLoadImage effect="blur" loading="lazy" alt="" className="left21" src={karya}   id="left"  />
                </NavLink>
            </div>
            <Burger  />
        </Nav>
        </div>
    </div>
  )
}


export default NavBar