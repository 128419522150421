function checkToken() {
    if(localStorage.getItem('token')) {
        sessionStorage.setItem('token', localStorage.getItem('token'))
    }
}

function checkTime() {
    var timeSession = sessionStorage.getItem("setupTime");
    if (new Date().getTime() - timeSession > 60 * 30 * 1000) {
      sessionStorage.clear();
      sessionStorage.removeItem("setupTime");
      window.location.reload()
    }
}

function removeSessionLogout() {
    localStorage.removeItem('setupTime')
    localStorage.removeItem('token')
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("ref")
    sessionStorage.removeItem("report")
    sessionStorage.removeItem("kuesionerconfirmed")
    sessionStorage.removeItem("rating-vip");
    sessionStorage.removeItem("rating-personality");
    sessionStorage.removeItem("rating-interest");
    sessionStorage.removeItem("rating-values");
    sessionStorage.removeItem("rating-program");
    sessionStorage.setItem("log-user","false")
    localStorage.setItem('logout', true)
    setTimeout(() => {
        window.location.href="/";
    }, 1000);
    // localStorage.clear()
    // sessionStorage.clear()
    // window.location.href="/"
}

module.exports = { checkToken, checkTime, removeSessionLogout }