import dataReport from "../components/DataReport.json"
import React, { Component } from 'react';
import green from "../images/report_check_green.svg"
import report_typical from "../images/report_typical.svg"
import report_struggle from "../images/report_struggle.svg"
import axios from "axios";
import {API,user_dummy} from '../_redux/helper'
import { LazyLoadImage } from 'react-lazy-load-image-component';


const numb = 9
export let res=[]
export let nconscientious
export let ndirecting
export let ninteracting
export let nsupportive
export let ncategory
export let report = '';
export let qweasd 
export var testing
export let label

export let date_report
export let dataDimensionOne
export let dataDimensionTwo
export let dataDimensionThree
export let dataDimensionFour

export let def_directing
export let def_conscientious
export let def_interacting
export let def_supportive
export let status_data
export let get_data = false
var jwt = require("jsonwebtoken");
var token1 = sessionStorage.getItem("token");
var decode1 = jwt.decode(token1);

let user_id = ""
if (window.location.pathname.split("/").length< 2 && (decode1=="" || decode1== null || decode1==undefined)){
  user_id = user_dummy
}else{
  user_id = window.location.pathname.split("/")[2]
}

let user_id_params =""
 /* eslint eqeqeq: 0 */
if (decode1=="" || decode1== null || decode1==undefined){
    user_id_params=parseInt(user_id)
}else{
    user_id_params=decode1["user_id"];
}

// console.log(this.props.match.params.id,"masuk bosss");
// const user_id_params = 121

if (numb > 0){
    testing = axios
    .get(`${API}/api/survey/findsubmission/${user_id_params}`)
    .then(response => {
        if (response.data === "Data Not Found"){
           status_data = false
           }
       get_data = true
       res = response.data.analysis
       nconscientious = response.data.analysis.metrics.conscientious
       ndirecting = response.data.analysis.metrics.directing
       ninteracting = response.data.analysis.metrics.interacting
       nsupportive = response.data.analysis.metrics.supportive
       ncategory = response.data.analysis.modules.PIDProfile.match
       def_directing = response.data.analysis.modules.PIDProfile.profile.metrics.directing
       def_conscientious = response.data.analysis.modules.PIDProfile.profile.metrics.conscientious
       def_interacting = response.data.analysis.modules.PIDProfile.profile.metrics.interacting
       def_supportive = response.data.analysis.modules.PIDProfile.profile.metrics.supportive
       label = response.data.analysis.modules.PIDProfile.profile.label
       date_report = response.data.updatedAt
       report = ncategory ? dataReport.profile[ncategory].single : ""
    //    if ((response.data.ratting==null || response.data.ratting=="") && response.data.ratting_status == "Active"){
    //      sessionStorage.setItem("rating-personality",true)
    //   }else{
    //     sessionStorage.setItem("rating-personality",false)
    //   }



       if (ndirecting < 40){
        dataDimensionOne = <div className="width-100 flex column">
                                   <h5 className="openSans">{dataReport.components.metrics.singleDirecting.low.desc}</h5>
                                      <div className="flex width-100 columnMobile">
                                          <div className="dimension-three-left">
                                              <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                  <h6>{dataReport.components.metrics.singleDirecting.low.strengths.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.strengths[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.strengths[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.strengths[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.strengths[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.strengths[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.strengths[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.strengths[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="dimension-three-right">
                                          <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                  <h6>{dataReport.components.metrics.singleDirecting.low.struggles.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.struggles[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.struggles[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.struggles[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.struggles[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.struggles[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.struggles[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleDirecting.low.struggles[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                              </div>
      }else if (ndirecting >= 40 && ndirecting <= 60){
          dataDimensionOne = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleDirecting.medium[1]}</h5>
                                  <div className="width-100 dekstop">
                                      <div className="flex width-100">
                                          <div className="width-48" style={{marginRight:"2%"}}>
                                              <p className="openSans align-left">{dataReport.components.metrics.singleDirecting.medium[2]}</p>
                                          </div>
                                          <div className="width-48" style={{marginLeft:"2%"}}>
                                              <p className="openSans align-left">{dataReport.components.metrics.singleDirecting.medium[3]}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="width-100 mobile">
                                      <div className="flex width-100 column">
                                          <div className="width-100" >
                                              <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleDirecting.medium[2]}</p>
                                          </div>
                                          <div className="width-100" >
                                              <p className="openSans align-left">{dataReport.components.metrics.singleDirecting.medium[3]}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
      }else{
          dataDimensionOne = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleDirecting.high.desc}</h5>
                                  <div className="flex width-100 columnMobile">
                                      <div className="dimension-three-left">
                                          <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                              <h6>{dataReport.components.metrics.singleDirecting.high.strengths.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.strengths[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.strengths[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.strengths[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.strengths[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.strengths[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.strengths[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.strengths[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="dimension-three-right">
                                      <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                              <h6>{dataReport.components.metrics.singleDirecting.high.struggles.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.struggles[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.struggles[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.struggles[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.struggles[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.struggles[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.struggles[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleDirecting.high.struggles[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                          </div>
      }
      if (ninteracting < 40){
        dataDimensionTwo = <div className="width-100 flex column">
                                   <h5 className="openSans">{dataReport.components.metrics.singleInteracting.low.desc}</h5>
                                      <div className="flex width-100 columnMobile">
                                          <div className="dimension-three-left">
                                              <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                  <h6>{dataReport.components.metrics.singleInteracting.low.strengths.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.strengths[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.strengths[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.strengths[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.strengths[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.strengths[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.strengths[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.strengths[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="dimension-three-right">
                                          <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                  <h6>{dataReport.components.metrics.singleInteracting.low.struggles.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.struggles[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.struggles[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.struggles[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.struggles[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.struggles[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.struggles[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleInteracting.low.struggles[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                              </div>
      }else if (ninteracting >= 40 && ninteracting <= 60){
          dataDimensionTwo = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleInteracting.medium[1]}</h5>
                                  <div className="width-100 dekstop">
                                    <div className="flex width-100">
                                        <div className="width-48 " style={{marginRight:"2%"}}>
                                            <p className="openSans align-left">{dataReport.components.metrics.singleInteracting.medium[2]}</p>
                                        </div>
                                        <div className="width-48" style={{marginLeft:"2%"}}>
                                            <p className="openSans align-left">{dataReport.components.metrics.singleInteracting.medium[3]}</p>
                                        </div>
                                    </div>
                                    </div>
                                  <div className="width-100 mobile">
                                    <div className="flex width-100 column">
                                        <div className="width-100" >
                                            <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleInteracting.medium[2]}</p>
                                        </div>
                                        <div className="width-100" >
                                            <p className="openSans align-left">{dataReport.components.metrics.singleInteracting.medium[3]}</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
      }else{
          dataDimensionTwo = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleInteracting.high.desc}</h5>
                                  <div className="flex width-100 columnMobile">
                                      <div className="dimension-three-left">
                                          <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                              <h6>{dataReport.components.metrics.singleInteracting.high.strengths.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.strengths[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.strengths[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.strengths[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.strengths[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.strengths[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.strengths[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.strengths[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="dimension-three-right">
                                      <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                              <h6>{dataReport.components.metrics.singleInteracting.high.struggles.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.struggles[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.struggles[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.struggles[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.struggles[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.struggles[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.struggles[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleInteracting.high.struggles[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                          </div>
      }


      if (nsupportive < 40){
        dataDimensionThree = <div className="width-100 flex column">
                                   <h5 className="openSans">{dataReport.components.metrics.singleSupportive.low.desc}</h5>
                                      <div className="flex width-100 columnMobile">
                                          <div className="dimension-three-left">
                                              <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                  <h6>{dataReport.components.metrics.singleSupportive.low.strengths.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.strengths[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.strengths[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.strengths[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.strengths[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.strengths[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.strengths[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.strengths[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="dimension-three-right">
                                          <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                  <h6>{dataReport.components.metrics.singleSupportive.low.struggles.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.struggles[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.struggles[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.struggles[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.struggles[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.struggles[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.struggles[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleSupportive.low.struggles[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                              </div>
      }else if (nsupportive >= 40 && nsupportive <= 60){
          dataDimensionThree = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleSupportive.medium[1]}</h5>
                                  <div className="width-100 dekstop">
                                  <div className="flex width-100">
                                      <div className="width-48 flex column" style={{marginRight:"2%"}}>
                                          <p className="openSans align-left">{dataReport.components.metrics.singleSupportive.medium[2]}</p>
                                      </div>
                                      <div className="width-48" style={{marginLeft:"2%"}}>
                                          <p className="openSans align-left">{dataReport.components.metrics.singleSupportive.medium[3]}</p>
                                      </div>
                                  </div>
                                  </div>
                                  <div className="width-100 mobile">
                                    <div className="flex width-100 column">
                                        <div className="width-100" >
                                            <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleSupportive.medium[2]}</p>
                                        </div>
                                        <div className="width-100" >
                                            <p className="openSans align-left">{dataReport.components.metrics.singleSupportive.medium[3]}</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
      }else{
          dataDimensionThree = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleSupportive.high.desc}</h5>
                                  <div className="flex width-100 columnMobile">
                                      <div className="dimension-three-left">
                                          <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                              <h6>{dataReport.components.metrics.singleSupportive.high.strengths.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.strengths[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.strengths[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.strengths[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.strengths[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.strengths[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.strengths[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.strengths[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="dimension-three-right">
                                      <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                              <h6>{dataReport.components.metrics.singleSupportive.high.struggles.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.struggles[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.struggles[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.struggles[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.struggles[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.struggles[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.struggles[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleSupportive.high.struggles[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                          </div>
      }


      if (nconscientious < 40){
        dataDimensionFour = <div className="width-100 flex column">
                                   <h5 className="openSans">{dataReport.components.metrics.singleConscientious.low.desc}</h5>
                                      <div className="flex width-100 columnMobile">
                                          <div className="dimension-three-left">
                                              <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                                  <h6>{dataReport.components.metrics.singleConscientious.low.strengths.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.strengths[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.strengths[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.strengths[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.strengths[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.strengths[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.strengths[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.strengths[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="dimension-three-right">
                                          <div className="flex width-100">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                                  <h6>{dataReport.components.metrics.singleConscientious.low.struggles.title}</h6>
                                              </div>
                                              <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.struggles[1]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.struggles[2]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.struggles[3]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.struggles[4]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.struggles[5]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.struggles[6]}</p>
                                                  </div>
                                                  <div className="flexCheck">
                                                      <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                      <p>{dataReport.components.metrics.singleConscientious.low.struggles[7]}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                              </div>
      }else if (nconscientious >= 40 && nconscientious <= 60){
          dataDimensionFour = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleConscientious.medium[1]}</h5>
                                  <div className="width-100 dekstop">
                                  <div className="flex width-100">
                                      <div className="width-48 flex column" style={{marginRight:"2%"}}>
                                          <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[2]}</p>
                                          <p className="openSans align-left">{dataReport.components.metrics.singleConscientious.medium[3]}</p>
                                      </div>
                                      <div className="width-48 flex column" style={{marginLeft:"2%"}}>
                                          <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[4]}</p>
                                          <p className="openSans align-left">{dataReport.components.metrics.singleConscientious.medium[5]}</p>
                                      </div>
                                  </div>
                                  </div>
                                  <div className="width-100 mobile">
                                    <div className="flex width-100 column">
                                        <div className="width-100" >
                                            <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[2]}</p>
                                        </div>
                                        <div className="width-100" >
                                            <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[3]}</p>
                                        </div>
                                        <div className="width-100" >
                                            <p className="openSans align-left" style={{marginBottom:"24px"}}>{dataReport.components.metrics.singleConscientious.medium[4]}</p>
                                        </div>
                                        <div className="width-100" >
                                            <p className="openSans align-left">{dataReport.components.metrics.singleConscientious.medium[5]}</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
      }else{
          dataDimensionFour = <div className="width-100 flex column">
                                  <h5 className="openSans">{dataReport.components.metrics.singleConscientious.high.desc}</h5>
                                  <div className="flex width-100 columnMobile">
                                      <div className="dimension-three-left">
                                          <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_typical}/>
                                              <h6>{dataReport.components.metrics.singleConscientious.high.strengths.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.strengths[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.strengths[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.strengths[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.strengths[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.strengths[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.strengths[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.strengths[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="dimension-three-right">
                                      <div className="flex width-100">
                                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={report_struggle}/>
                                              <h6>{dataReport.components.metrics.singleConscientious.high.struggles.title}</h6>
                                          </div>
                                          <div className="flex width-100 column" style={{marginTop:"19px"}}>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.struggles[1]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.struggles[2]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.struggles[3]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.struggles[4]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.struggles[5]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.struggles[6]}</p>
                                              </div>
                                              <div className="flexCheck">
                                                  <LazyLoadImage effect="blur" loading="lazy" alt="" src={green} />
                                                  <p>{dataReport.components.metrics.singleConscientious.high.struggles[7]}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                          </div>
      }

    })
    .catch(err => {
    })
}



export  const name1 = sessionStorage.getItem("name")
const nama = '' + name1
export  const name = nama.split(" ")[0]

export  let fileReport = ncategory



        //   EXAMPLE

        //   let text = "__first_name__'s persistence and goal-orientation makes them a natural for team leadership as well as group cooperation. Because __first_name__ has such a strong need to achieve goals with excellence, a manager may need to coach them on how to soften direct feedback to subordinates and how to use positive reinforcement whenever possible. However, since __first_name__ has great ability to get along with people and include them in the outcome, __first_name__ will respond well to goals and will be an excellent staff member to lead major organizational initiatives."
        //     result = []
        //     if(text.indexOf("__first_name__") !== -1 ) {
        //     let splited = text.split('__first_name__')
        //     splited.map(x => {
        //         if(x == '') {
        //         result.push('nuri')
        //         } else {
        //         result.push(x)
        //         }
        //     })
        //     console.log(result.join(''))
        //     } 