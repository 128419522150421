import React, { Component } from "react";
import { connect } from "react-redux";
import { getJob } from "../_actions/jobs";
import Rating from "@material-ui/lab/Rating";
import Frame from "../images/Frame.png";
import Loading from "../components/Loading";
import karya from "../images/logoBaru.png";
import bag from "../images/Vector.png";
import checklist from "../images/icons/checklist.svg";
import nav_top from "../images/icons/nav-top.svg";
import ExpandCollapse from "react-expand-collapse";
import ScrollToTop from "../components/ScrollToTop";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { API } from "../_redux/helper";
import ReactTooltip from "react-tooltip";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPixel from 'react-facebook-pixel';


class JobRecommendation extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
    };
    this.goBack = this.goBack.bind(this); //for go back (kembali) button
  }

  componentDidMount() {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
    this.setState({ user_id: user_id });
    this.props.getJob(user_id);
    window.scrollTo(0, 0)
    ReactPixel.track(window.location.pathname);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleToken = () => {
    var jwt = require("jsonwebtoken");
    var token1 = sessionStorage.getItem("token");
    var decode1 = jwt.decode(token1);
    var user_id = decode1["user_id"];
  };

  goBack() {
    this.props.history.goBack();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { data, isLoading, error } = this.props.job1;

    // timeOut for session
    var timeSession = sessionStorage.getItem("setupTime");
    if (new Date().getTime() - timeSession > 24 * 60 * 60 * 1000) {
      sessionStorage.clear();
      sessionStorage.removeItem("setupTime");
      window.location.href = "/";
    }

    if (error) {
      return (
        <div className="message-question-load">
          <h3>Ups... Please, refresh this page!</h3>
        </div>
      );
    }

    if (isLoading) {
      return (
        <React.Fragment>
          <Loading />
        </React.Fragment>
      );
    }

    return (
      <div>
        <div className="edu">
          <div className="box-wrapper orange">
            <div className="wrapper">
              <div className="content">
                <div className="page-container job-recomended  width-100">
                  <div className="background-top width-100"></div>

                  <div className="page-container-outer">
                    <div className="box-logo width-100 dekstop">
                      {/* <Link to="/"> */}
                      <div className="box-logo-img">
                        <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya} />
                      </div>
                      {/* </Link> */}
                    </div>
                    <div className="mobile-information width-100">
                      <div className="box-logo width-100">
                        <div className="box-logo-img">
                          <LazyLoadImage effect="blur" loading="lazy" alt="" src={karya} />
                        </div>
                      </div>
                    </div>

                    <div className="page-container-inner width-100">
                      <div className="box-edu-container width-100">
                        <div className="box-edu-description mobile expandmobile">
                          <ExpandCollapse
                            previewHeight="126px"
                            expandText="wew"
                            ellipsisText=""
                          >
                            <div className="edu-desc-title width-100">
                              <div className="box-img-inner ">
                                <div className="box-img done">
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist jb-rc" src={checklist} />
                                </div>
                              </div>
                              <div className="box-img-inner active">
                                <div className="box-img done">
                                  <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist jb-rc" src={checklist} />
                                </div>
                              </div>
                              <div className="box-img-inner">
                                <div className="box-img">
                                  <LazyLoadImage effect="blur"
                                    className="checklist"
                                    src={bag}
                                    style={{
                                      position: "absolute",
                                      margin: "32% 2%",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="edu-desc-content width-100">
                              <div className="box-image">
                                <div className="background-img"></div>
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={Frame} />
                              </div>

                              <h5>Job Recommendation</h5>
                              <p className="rekom-desc">
                                Based on your work interest assessment result,
                                here are the jobs that we think will fit you the
                                most. The higher the star rating in the job, the
                                higher the degree of fit between that particular
                                job and your work interests.
                              </p>
                            </div>
                            <div className="edu-desc-footer">
                              <Link to="/AssesmentReport">
                                <button
                                  type="button"
                                  className="btn btn-link"
                                  onClick={this.goBack}
                                >
                                  Back
                                </button>
                              </Link>
                              <Link to="/NewDashboard">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={ReactGA.pageview(
                                    window.location.pathname +
                                      window.location.search
                                  )}
                                >
                                  Dashboard
                                </button>
                              </Link>
                            </div>
                            <div className="edu-desc-nav width-100">
                              <div className="edu-desc-nav-img">
                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={nav_top} />
                              </div>
                            </div>
                          </ExpandCollapse>
                        </div>

                        <div className="box-edu-description questions-desktop">
                          <div className="edu-desc-title width-100">
                            <div className="box-img-inner ">
                              <div className="box-img done">
                                <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist jb-rc" src={checklist} />
                              </div>
                            </div>
                            <div className="box-img-inner active">
                              <div className="box-img done">
                                <LazyLoadImage effect="blur" loading="lazy" alt="" className="checklist jb-rc" src={checklist} />
                              </div>
                            </div>
                            <div className="box-img-inner">
                              <div className="box-img">
                                <LazyLoadImage effect="blur"
                                  className="checklist"
                                  src={bag}
                                  style={{
                                    position: "absolute",
                                    margin: "32% 2%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="edu-desc-content width-100">
                            <div className="box-image">
                              <div className="background-img"></div>
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={Frame} />
                            </div>

                            <h5>Job Recommendation</h5>
                            <p className="rekom-desc">
                              Based on your work interest assessment result,
                              here are the jobs that we think will fit you the
                              most. The higher the star rating in the job, the
                              higher the degree of fit between that particular
                              job and your work interests.
                            </p>
                          </div>
                          <div className="edu-desc-footer">
                            <Link to="/AssesmentReport">
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={this.goBack}
                              >
                                Back
                              </button>
                            </Link>
                            <Link to="/NewDashboard">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={ReactGA.pageview(
                                  window.location.pathname +
                                    window.location.search
                                )}
                              >
                                Dashboard
                              </button>
                            </Link>
                          </div>
                          <div className="edu-desc-nav width-100">
                            <div className="edu-desc-nav-img">
                              <LazyLoadImage effect="blur" loading="lazy" alt="" src={nav_top} />
                            </div>
                          </div>
                        </div>

                        <div className="box-edu-content ">
                          <div className="box-assessment-title width-100">
                            <h4 className=" width-100">
                              Job Recommendation For You
                            </h4>
                          </div>
                          <div className="box-horizontal-line width-100">
                            <div className="horizontal-line width-100"></div>
                          </div>
                          <div className="box-recomended-list-work width-100">
                            <div className="recomended-list-work-inner width-100">
                              {data.map((item, i) => {
                                if (i <= 2) {
                                  return (
                                    <div key={i} className="box-list-work">
                                      <div className="box-list-work-inner width-100">
                                        <div className="list-work-image width-100">
                                          {/* <a href={item.link_photo}  > */}
                                          <LazyLoadImage effect="blur"
                                            src={`${API}/File/Job/${item.photo}`}
                                            title={item.link_photo}
                                            data-for="svgTooltip2"
                                            cx="10"
                                            cy="25"
                                            r="22"
                                            fill="#fff"
                                            stroke="#000"
                                            strokeWidth="4"
                                          />
                                          {/* </a> */}
                                          <ReactTooltip
                                            id="svgTooltip2"
                                            place="bottom"
                                            type="light"
                                          />
                                          {/* <div className="box-rating width-100">
                                                                        <div className="box-rating-inner width-100">
                                                                            <div className="box-rating-category">
                                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iconProspek} />
                                                                            </div>
                                                                            <div className="box-rating-category">
                                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iconButuh} />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                          <div
                                            className="middle"
                                            style={{ display: "none" }}
                                          >
                                            <a
                                              href={item.link_photo}
                                               
                                            >
                                              <div className="textImage">
                                                {item.link_photo}
                                              </div>
                                            </a>
                                          </div>
                                        </div>

                                        <div className="list-work-description width-100">
                                          <h5>{item.original_job_title} </h5>
                                          <div className="list-work-trend width-100">
                                            <Rating
                                              name="read-only"
                                              value={item.star}
                                              readOnly
                                            />
                                          </div>
                                          <Router>
                                            <div className="box-link width-100">
                                              <Link
                                                 
                                                to={`/DetailSummary/${item.job_id}`}
                                              >
                                                {" "}
                                                <a
                                                  href={`/DetailSummary/${item.job_id}`}
                                                   
                                                  onClick={ReactGA.pageview(
                                                    window.location.pathname +
                                                      window.location.search
                                                  )}
                                                >
                                                  See job detail
                                                </a>
                                              </Link>
                                            </div>
                                          </Router>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })}

                              {data.map((item, i) => {
                                if (i >= 3) {
                                  return (
                                    <div key={i} className="box-list-work">
                                      <div className="box-list-work-inner width-100">
                                        <div className="list-work-image width-100">
                                          {/* <Tooltip content={item.link_photo} direction="down-start"> */}
                                          {/* <a href={item.link_photo}  > */}
                                          <LazyLoadImage effect="blur"
                                            src={`${API}/File/Job/${item.photo}`}
                                            title={item.link_photo}
                                            data-for="svgTooltip2"
                                            cx="10"
                                            cy="25"
                                            r="22"
                                            fill="#fff"
                                            stroke="#000"
                                            strokeWidth="4"
                                          />
                                          <ReactTooltip
                                            id="svgTooltip2"
                                            place="bottom"
                                            type="light"
                                          />
                                          {/* </a> */}
                                          {/* </Tooltip> */}
                                          <div
                                            className="middle"
                                            style={{ display: "none" }}
                                          >
                                            <a
                                              href={item.link_photo}
                                               
                                            >
                                              <div className="textImage">
                                                {item.link_photo}
                                              </div>
                                            </a>
                                          </div>
                                          <div className="box-rating width-100">
                                            {/* <div className="box-rating-inner width-100">
                                                                            <div className="box-rating-category">
                                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iconProspek} />
                                                                            </div>
                                                                            <div className="box-rating-category">
                                                                                <LazyLoadImage effect="blur" loading="lazy" alt="" src={iconButuh} />
                                                                            </div>
                                                                        </div> */}
                                          </div>
                                        </div>
                                        <div className="list-work-description width-100">
                                          <h5>{item.original_job_title} </h5>
                                          <div className="list-work-trend width-100">
                                            <Rating
                                              name="read-only"
                                              value={item.star}
                                              readOnly
                                            />
                                          </div>
                                          <div className="box-link width-100">
                                            <Link
                                               
                                              rel="noopener noreferrer"
                                              to={`/DetailSummary/${item.job_id}`}
                                            >
                                              <a
                                                href={`/DetailSummary/${item.job_id}`}
                                                 
                                              >
                                                See job detail
                                              </a>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user_id: state.user_id,
    job1: state.job,
    /*untuk put*/
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJob: (user_id) => {
      dispatch(getJob(user_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobRecommendation);
