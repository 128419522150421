import React, { Component } from 'react';
import { connect } from "react-redux";
import { Twitter, Facebook,  Whatsapp ,Linkedin, Telegram} from 'react-social-sharing'
import { getProfileId } from "../_actions/user";



class componentName extends Component {
    constructor() {
        super();
        this.state = {
          user_id: "",
        }
    }

    componentDidMount(){
        var jwt = require("jsonwebtoken");
        var token1 = sessionStorage.getItem("token");
        var decode1 = jwt.decode(token1);
        var user_id = decode1['user_id']
        this.setState({user_id:user_id})
        this.props.getProfileId(user_id);
    }

    render() {
    const { profile } = this.props.profile1;

        return (
            <div>
                
                 <Twitter link={`https://karyatalents.com/Linktoshare/${profile.id}`}  />
                 {/* <LazyLoadImage effect="blur" loading="lazy" alt="" src={fb} style={{width:"200px"}} HTMLfor="fb"> </img> */}
                 <Facebook link={`https://karyatalents.com/Linktoshare/${profile.id}`}/>
                
                 {/* <Instagram link="https://github.com" /> */}
                 <Whatsapp link={`https://karyatalents.com/Linktoshare/${profile.id}`} />
                 <Linkedin link={`https://karyatalents.com/Linktoshare/${profile.id}`} />
                 <Telegram link={`https://karyatalents.com/Linktoshare/${profile.id}`} />
                 
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user_id: state.user_id,
      profile1: state.profileId,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getProfileId: (user_id) => {
        dispatch(getProfileId(user_id));
      },
     
  
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(componentName);
  