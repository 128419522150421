import React, { Component } from 'react';
import {phone,mail,cv,cv_green, people, salary, skill, work_exp, education, certificate, project,industry,
    art_certification,art_personal, art_education,art_project,art_salary,art_skills,art_work_exp, pen} from "../../_redux/svg"
import {priceSplitter} from "../../_redux/function"
import Skeleton from '@mui/material/Skeleton';

class Salary_Benefit extends Component {
    constructor(){
        super()
        this.state={
            visible:false,
            user_id:""
        }
    }


    render() {
        // const {visible} = this.state
        const {profile, stateName, cityName} = this.props
        const {expectedId,lastId} = this.props
        let visible = true
        const list = ["lastest_monthly_salary","expected_monthly_salary"]
        let list_value =[]
        list.map(item=>{
            if (profile[item]!== null){
                list_value.push("n")
            }
        })
        if (list_value.length > 0 || expectedId.length > 0 || lastId.length > 0){
            visible = false
        }

        const status = profile.length !== 0 && profile.lastest_monthly_salary && profile.expected_monthly_salary && expectedId!==undefined && lastId!==undefined && stateName !==""

        return (
            <div className="h-gap-50 bg-ff br-10 shd-input -set benf">
                <div className='flex column h-100  mb-24'>
                    <div className='flex p-1814 bb-ea space-between'>
                        <div className='flex '>
                        <div className='flex m-auto-00'>
                            {salary}
                        </div>
                        <p className='ml-14 DM-Sans c-29 s-20 '>Salary {"&"} Benefit</p>
                        </div>
                        {!visible &&
                        <div className='flex pointer m-auto0' onClick={(e)=>{this.props.handlePopup("salary_")
                        document.getElementById("sal1").scrollIntoView(0,0)}}>
                            {pen}
                            <p className='DM-Sans s-14 c-36 ml6'>Edit</p>
                        </div>}
                    </div>
                    <div className='flex column width-100 '>
                        {visible &&
                        <div className='flex width-100 blank-data-main  center hg-100 pb-2'>
                            <div className='flex m-2429'>
                                {art_salary}
                            </div>
                            <div className='flex column ml-29  wd-375 center mb-48 mr-10'>
                                <p className='c-29 DM-Sans s-14 mt-48 mb-48 tx-c'>Add your Salary {"&"} Benefit so that your profile can be matched to more suitable opportunities.</p>
                                <div className='bg-36 p-1014 br-20 sm-m-2 button-w-main wd-fit-content pointer' onClick={(e) => this.props.handlePopup("salary_")}>
                                    <p className='s-14 c-ff DM-Sans ' >Add Salary {`&`} Benefit</p>
                                </div>
                            </div>
                        </div>}
                        {!visible &&
                        <div className='flex p-14 align-left'>
                            <div className='flex width-100 wrap align-left'>
                                <div className='flex column  mr-24 mb-24' style={{width: '40%'}}>
                                    <p className='DM-Sans s-14 c-72 mb2'>Latest Salary</p>
                                    {status ?
                                    <p className='DM-Sans s-14 c-29'>IDR {priceSplitter(profile.lastest_monthly_salary*1)} </p>
                                    :<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                </div>
                                <div className='flex column mb-24' style={{width: '50%'}}>
                                    <p className='DM-Sans s-14 c-72 mb2'>Expected Salary</p>
                                    {status ?
                                    <p className='DM-Sans s-14 c-29'>IDR {priceSplitter(profile.expected_monthly_salary*1)}</p>
                                    :<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                </div>
                                <div className='flex column f-width mr-24 mb-24 ' style={{width: '40%'}}>
                                    <p className='DM-Sans s-14 c-72 mb2'>Latest Benefit</p>
                                    { status? lastId.map((item,i)=>(
                                    <li className='DM-Sans s-14 c-29'>{item.latest_benefit} </li>
                                    )):<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                </div>
                                <div className='flex column  mb-24' style={{width: '50%'}}>
                                    <p className='DM-Sans s-14 c-72 mb2'>Expected Benefit</p>
                                    {status ? expectedId.map((item,i)=>(
                                    <li className='DM-Sans s-14 c-29'>{item.expected_benefit} </li>
                                    )):<Skeleton variant="text" sx={{ fontSize: '20px' }} />}
                                </div>
                            </div>
                        </div>
                            }
                    </div>
                </div>
            </div>
        );
    }
}
  
  export default Salary_Benefit;